/**
 *
 * FPLTrackingNumberInput
 *
 */

import _ from 'lodash'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { NVInput, NVSelect } from '@nv/react-commons/src/Components'
import { cutPrefix } from 'components/TrackingNumberInput/utils'

const Option = NVSelect.Option

const FPLTrackingNumberInput = ({ id, placeholder, value, onChange, prefix, prefixes, setSelectedPrefix }) => {
  const [inputValue, setInputValue] = useState(value)

  const renderPrefix = () => {
    return (
      prefix && (
        <NVSelect
          defaultValue={prefix}
          onChange={p => {
            setSelectedPrefix(p)
            onChange(`${p}${inputValue}`)
          }}
          disabled={_.isEmpty(prefixes)}
        >
          {prefixes.map(p => (
            <Option key={p} value={p}>
              {p}
            </Option>
          ))}
        </NVSelect>
      )
    )
  }

  const handleChange = e => {
    const _value = e.target.value
    setInputValue(_value)

    // Send Prefix with value
    onChange(`${prefix ?? ''}${_value}`.trim())
  }

  return (
    <NVInput
      data-testId='trackingId'
      id={id || null}
      value={cutPrefix(prefix, inputValue)}
      placeholder={placeholder}
      addonBefore={renderPrefix()}
      onChange={handleChange}
    />
  )
}

FPLTrackingNumberInput.propTypes = {
  id: PropTypes.any,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  prefixes: PropTypes.array,
  setSelectedPrefix: PropTypes.func
}

export { FPLTrackingNumberInput }
