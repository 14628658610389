/**
 *
 * PanelHeader
 *
 */

import { Icon, Row } from '@nv/react-commons/src/Components'
import { Text } from '../Text'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Colors, Fonts } from 'themes'

const StyledWrapper = styled.div`
  margin-left: 16px;
  line-height: 32px;
`
const StyledText = styled(Text).attrs({
  color: Colors.warmGrey,
  size: 12
})`
  ${Fonts.style.bold};
`

class PanelHeader extends React.Component {
  render () {
    const { title, icon } = this.props
    return (
      <>
        <Row type='flex' align='middle'>
          <Icon gap={1} type='medium' icon={icon} />
          <StyledWrapper>
            <StyledText allCaps id={title} />
          </StyledWrapper>
        </Row>
      </>
    )
  }
}

PanelHeader.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired
}

export { PanelHeader }
