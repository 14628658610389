/**
 *
 * PickupAddressAutoComplete
 *
 */

import { faMapMarkerAlt } from '@fa-pro-regular/faMapMarkerAlt'
import { Icon, Spin } from '@nv/react-commons/src/Components'
import { ApiHelper } from '@nv/react-commons/src/Services'
import { AddressUtils, SelectorUtils } from '@nv/react-commons/src/Utils'
import { AutoComplete } from 'components/AutoComplete'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { addressingApi } from 'services/api'
import { StyledAutoComplete } from './styles'
import { Colors } from 'themes'

const { Option } = AutoComplete
const { selector } = SelectorUtils

export function PickupAddressAutoComplete ({
  showMapOption,
  hide,
  onSelect,
  autocompleteAddressResults,
  renderResult,
  intl,
  country,
  autocompleteAddressClear,
  autocompleteAddressRequest,
  value,
  onChange,
  className,
  size,
  getPlace,
  clearPlace,
  place
}) {
  const [selectedAddressDescription, setSelectedAddressDescription] = useState('')
  const isLoading = !!place?.loading

  useEffect(() => {
    return () => {
      autocompleteAddressClear()
    }
  }, [autocompleteAddressClear])

  useEffect(() => {
    const hasError = place?.error
    const data = place?.data

    if (hasError) {
      clearPlace()
      setSelectedAddressDescription('')
      return
    }

    if (data && Object.keys(data).length && selectedAddressDescription) {
      const latitude = parseFloat(AddressUtils.getLatitude(data))
      const longitude = parseFloat(AddressUtils.getLongitude(data))
      const postcode = AddressUtils.getPostcode(data)
      onSelect({
        postcode,
        latitude,
        longitude,
        country: AddressUtils.getCountry(data),
        address1: selectedAddressDescription,
        keyword: selectedAddressDescription
      })
      clearPlace()
      setSelectedAddressDescription('')
    }
  }, [place, selectedAddressDescription])

  const filterOption = (inputValue, option) => {
    if (option.key === 'map') {
      return true
    } else if (inputValue.toUpperCase) {
      return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }
  }

  const handleSelect = key => {
    if (key === 'map') {
      onSelect({ key })
    } else {
      const selectedAddress = autocompleteAddressResults.find(result => result.description === key)
      if (!selectedAddress) {
        onSelect({ key })
        return
      }

      getPlace(selectedAddress.placeId)
      setSelectedAddressDescription(selectedAddress.description)
    }
  }

  const controlledProps = onChange
    ? {
        value,
        onChange
      }
    : {}
  return (
    <div className={className}>
      <Spin spinning={isLoading}>
        <StyledAutoComplete
          hide={hide}
          placeholder='search_an_address'
          filterOption={filterOption}
          onSearch={keyword => autocompleteAddressRequest(keyword, country)}
          onSelect={handleSelect}
          data-analyticsid='searchNewOCPickupAddress'
          size={size}
          {...controlledProps}
        >
          {autocompleteAddressResults.map(renderResult)}
          {showMapOption && (
            <Option key='map' style={{ background: Colors.whiteTree }}>
              <Icon icon={faMapMarkerAlt} color={Colors.nvPriRed} />
              {intl.formatMessage({ id: 'select_from_map' })}
            </Option>
          )}
        </StyledAutoComplete>
      </Spin>
    </div>
  )
}

PickupAddressAutoComplete.propTypes = {
  hide: PropTypes.bool,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  autocompleteAddressResults: PropTypes.array,
  renderResult: PropTypes.func,
  intl: intlShape,
  showMapOption: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  country: PropTypes.string,
  autocompleteAddressClear: PropTypes.func,
  autocompleteAddressRequest: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.string,
  getPlace: PropTypes.func,
  clearPlace: PropTypes.func,
  place: PropTypes.object
}

PickupAddressAutoComplete.defaultProps = {
  autocompleteAddressResults: [],
  showMapOption: false,
  renderResult: result => {
    return <Option key={result.description}>{result.description}</Option>
  }
}

const AUTOCOMPLETE_DEBOUNCE = 200

const mapStateToProps = createStructuredSelector({
  autocompleteAddressResults: selector('entity', 'getAutocompleteAddressPredictions', 'data', 'predictions')(),
  place: selector('entity', 'getPlace')()
})
function mapDispatchToProps (dispatch) {
  return {
    autocompleteAddressClear: () => dispatch(ApiHelper.creators.clear('getAutocompleteAddressPredictions')),
    autocompleteAddressRequest: debounce(
      (keyword, country) =>
        dispatch(
          ApiHelper.creators.request(
            'getAutocompleteAddressPredictions',
            addressingApi.getAutocompleteAddressPredictions,
            [keyword, country]
          )
        ),
      AUTOCOMPLETE_DEBOUNCE
    ),
    getPlace: placeId => dispatch(ApiHelper.creators.request('getPlace', addressingApi.getPlace, [placeId])),
    clearPlace: () => dispatch(ApiHelper.creators.clear('getPlace'))
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(injectIntl, withConnect)(PickupAddressAutoComplete)
