import { camelCase, pick, isEqual } from 'lodash'
import { CountryUtils } from '@nv/react-commons/src/Utils'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { ADDRESS_LEVEL_NAMES } from 'constants/address'
import featureKeys from 'featureHub/featureKeys'
import { defaultFlags } from 'featureHub/featureFlagsDefaults'
import { Order, PickupAddress } from 'types'

const pickAddressComponent = (address: PickupAddress): Partial<PickupAddress> => {
  return pick(address, 'address1', 'contact', 'email', 'name', 'postcode')
}

export const isSameAddress = (address1: PickupAddress, address2: PickupAddress) => {
  return isEqual(pickAddressComponent(address1), pickAddressComponent(address2))
}

export const getAddressTranslationKeyMap = (country = '') => {
  switch (country && country.toLowerCase()) {
    case COUNTRIES.TH:
    case COUNTRIES.ID:
      return {
        1: 'province',
        2: 'district',
        3: 'subdistrict'
      }
    case COUNTRIES.VN:
      return {
        1: 'province',
        2: 'district',
        3: 'ward'
      }
    case COUNTRIES.MY:
      return {
        1: 'state',
        2: 'district'
      }
    case COUNTRIES.PH:
      return {
        1: 'province',
        2: 'municipality',
        3: 'barangay'
      }
    default:
      return {}
  }
}

export const getPickupAddressDivisionFieldName = (level: keyof typeof ADDRESS_LEVEL_NAMES) => {
  return ADDRESS_LEVEL_NAMES[level]
}

export const getDeliveryAddressDivisionFieldName = ({
  country = '',
  level
}: {
  country: string
  level: keyof typeof ADDRESS_LEVEL_NAMES
}): string => {
  // neighborhood is spelled differently in react-commons than pickup address
  return camelCase(CountryUtils.getAddressInfo(country)[level === 3 ? 'neighborhood' : ADDRESS_LEVEL_NAMES[level]])
}

export const getPostcodeType = (country = '') => {
  switch (country && country.toLowerCase()) {
    case COUNTRIES.TH:
    case COUNTRIES.SG:
    case COUNTRIES.MY:
      return 'text'
    default:
      return 'none'
  }
}

export const hasL1 = (country = '') => {
  switch (country && country.toLowerCase()) {
    case COUNTRIES.SG:
      return false
    default:
      return true
  }
}

export const hasL2 = (country = '', isPickup) => {
  switch (country && country.toLowerCase()) {
    case COUNTRIES.SG:
      return false
    case COUNTRIES.PH:
      if (isPickup) {
        return false
      } else {
        return true
      }
    default:
      return true
  }
}

export const hasL3 = (country = '', isPickup) => {
  switch (country && country.toLowerCase()) {
    case COUNTRIES.SG:
    case COUNTRIES.MY:
      return false
    case COUNTRIES.PH:
      if (isPickup) {
        return false
      } else {
        return true
      }
    default:
      return true
  }
}

export const getNumberOfSupportedLevels = (country = '', isPickup = false) => {
  let levels = 0
  if (hasL1(country)) {
    levels++
  }
  if (hasL2(country, isPickup)) {
    levels++
  }
  if (hasL3(country, isPickup)) {
    levels++
  }
  return levels
}

export const shouldUseGranularAddressFields = ({
  flags = {},
  country = ''
}: {
  flags: Partial<typeof defaultFlags>
  country: string
}) => {
  if (!flags?.[featureKeys.GRANULAR_ADDRESS_FIELDS]) {
    return false
  }

  switch (country && country.toLowerCase()) {
    case COUNTRIES.MM:
    case COUNTRIES.MA:
      return false
    default:
      return true
  }
}

export const getSupportedLanguage = (country = '') => {
  switch (country && country.toLowerCase()) {
    case COUNTRIES.VN:
      return 'vi'
    case COUNTRIES.TH:
      return 'th'
    case COUNTRIES.ID:
      return 'id'
    default:
      return 'en'
  }
}

export const mapPickupAddressToOrderFields = ({ country = '', pickupAddress = {} as PickupAddress }) => {
  const addressInfo = CountryUtils.getAddressInfo(country)
  return {
    ...pickupAddress,
    ...(Boolean(addressInfo.region) &&
      Boolean(pickupAddress.region) && { [camelCase(addressInfo.region)]: pickupAddress.region || '' }),
    ...(Boolean(addressInfo.locality) &&
      Boolean(pickupAddress.locality) && { [camelCase(addressInfo.locality)]: pickupAddress.locality || '' }),
    ...(Boolean(addressInfo.neighborhood) &&
      Boolean(pickupAddress.neighbourhood) && {
        [camelCase(addressInfo.neighborhood)]: pickupAddress.neighbourhood || ''
      })
  }
}

// get to address 2 property without appended divisions
export const getAddress2Value = (order: Order) => {
  const appendedDivisionsRegExp = new RegExp(
    [' ', order?.toDistrict, order?.toCity, order?.toState]
      .filter(Boolean)
      .map(str => str.trim())
      .join(' ?, '),
    'g'
  )

  return order?.toAddress2?.replace(appendedDivisionsRegExp, '')
}

// get from address 2 property without appended divisions
export const getFromAddress2Value = (order: Order) => {
  const appendedDivisionsRegExp = new RegExp(
    [' ', order?.fromDistrict, order?.fromCity, order?.fromState]
      .filter(Boolean)
      .map(str => str.trim())
      .join(' ?, '),
    'g'
  )

  return order?.fromAddress2?.replace(appendedDivisionsRegExp, '')
}
