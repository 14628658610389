import React from 'react'
import { Col, Row } from '@nv/react-commons/src/Components'
import { media } from '@nv/react-commons/src/Themes/Media'
import { Card } from 'components/Card'
import { Link } from 'components/Link'
import styled, { css } from 'styled-components'
import { Colors, Fonts } from 'themes'

export const StyledCard = styled(({ background, ...props }) => <Card {...props} />)`
  && {
    border: none;
    color: ${Colors.greyishBrown};
    ${props =>
      !!props.background &&
      css`
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('${props.background.mobile}');
        background-position: center;
        ${media.min.tablet`
          background-size: contain;
          background-image: url('${props.background.desktop.left}'), url('${props.background.desktop.center}'),
            url('${props.background.desktop.right}');
          background-position: left, calc(100% - 130px), right;
        `};
      `};
  }
`
export const StyledRow = styled(Row).attrs({
  type: 'flex',
  align: 'middle',
  style: {
    marginLeft: 0,
    marginRight: 0
  }
})`
  justify-content: space-between;
  && {
    ${media.max.lgTablet`
      align-items: flex-start;
    `};

    ${media.max.tablet`
        flex-direction: column;
        align-items: stretch;
    `};
  }
`
export const ContentRow = styled(({ background, ...props }) => <Row {...props} />).attrs({
  type: 'flex'
})`
  border-color: red;
  justify-content: space-between;
  flex: 1;
  ${media.min.tablet`
    padding-left: 24px;
  `};
  ${media.max.tablet`
    padding-top: 32px;
  `};
  && {
    flex-wrap: wrap;

    ${props =>
      props.background
        ? css`
            ${media.min.tablet`
      align-items: center;
    `};
            ${media.max.tablet`
      flex-direction: column;
    `};
          `
        : css`
            ${media.min.lgTablet`
      align-items: center;
    `};
            ${media.max.lgTablet`
      flex-direction: column;
    `};
          `};
  }
`
export const TitleCol = styled(Col)`
  && {
    font-size: 16px;
    line-height: 1.5;
    ${Fonts.style.bold};
    color: ${Colors.greyishBrown};
    ${media.max.tablet`
      margin-top: 12px;
      margin-bottom: 8px;
    `};
    ${media.max.desktop`
      margin-left:2px;
    `};
    ${props =>
      !!props.background &&
      css`
        ${media.min.tablet`
          margin-right: 150px;
        `};
      `};
  }
`
export const ContentCol = styled(Col)`
  && {
    font-size: 15px;
    line-height: 1.43;
    color: ${Colors.warmGrey};
    ${media.max.tablet`
      margin-bottom: 10px;
    `};
    ${media.max.desktop`
      margin-left:2px;
    `};
    a {
      color: inherit;
      &:hover {
        color: ${Colors.overlayDark};
      }
    }
    p {
      margin-bottom: 0;
    }
  }
`
export const LinkCol = styled(Col)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`

export const LogoCol = styled(Col)`
  && {
    * + * {
      margin-left: 16px;
    }
    align-items: center;
    display: flex;
    flex-direction: row;
    ${media.max.tablet`
    justify-content: center;
  `}
  }
`

export const StyledContainer = styled(({ background, ...props }) => <div {...props} />)`
  flex: 1;
  ${props =>
    props.background
      ? css`
          ${media.min.tablet`
    padding-left: 50px;
    padding-right: 180px;
  `};
        `
      : css`
          ${media.min.tablet`
    padding-left: 30px;
    padding-right: 24px;
  `};
        `};
`

export const StyledLink = styled(Link)`
  && {
    color: inherit;
    font-size: 15px;
    &:hover {
      color: ${Colors.overlayDark};
    }
  }
`

export const StyledIllustration = styled.img`
  width: 70px;
  height: 70px;
`
