/**
 *
 * SalesChannelsLink
 *
 */

import { SALES_CHANNELS_BY_COUNTRY } from 'components/SalesChannelsLink/constants'
import React from 'react'
import { injectIntl, intlShape } from 'react-intl'
import PropTypes from 'prop-types'
import _ from 'lodash'

const IndividualLink = ({ contactInfo, href, isEmail, intl }) => {
  const emailSubject = encodeURIComponent(intl.formatMessage({ id: 'account_upgrade_enabling_regular_deliveries' }))
  const formattedHref = isEmail ? `${href}?Subject=${emailSubject}` : href
  return (
    <a key={contactInfo} href={formattedHref} target='_blank' rel='noopener noreferrer'>
      {contactInfo}
    </a>
  )
}
IndividualLink.propTypes = {
  contactInfo: PropTypes.string,
  href: PropTypes.string,
  intl: intlShape,
  isEmail: PropTypes.bool
}

const SalesChannelsLink = ({ country, intl }) => {
  const salesChannels = SALES_CHANNELS_BY_COUNTRY[country] || []
  return _.isEmpty(salesChannels)
    ? null
    : salesChannels
      .map(props => <IndividualLink key={props.contactInfo} {...props} intl={intl} />)
      .reduce((prev, curr) => [prev, ' / ', curr])
}
SalesChannelsLink.propTypes = {
  country: PropTypes.string,
  intl: intlShape
}

const _SalesChannelsLink = injectIntl(SalesChannelsLink)

export { _SalesChannelsLink as SalesChannelsLink }
