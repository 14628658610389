/**
 *
 * ActionsStep
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button } from '../Button'
import { T } from '@nv/react-commons/src/Components'

const StyledCancelButton = styled(Button).attrs({
  size: 'medium'
})`
  && {
    min-width: 120px;
    width: auto;
    margin-right: 8px;
  }
`

const StyledSaveButton = styled(StyledCancelButton).attrs({
  type: 'primary'
})``

const ActionsStep = ({ dataId, onSave, onCancel, loading, saveLabel, cancelLabel }) => (
  <>
    <StyledSaveButton onClick={onSave} loading={loading} data-analyticsid={`save ${dataId}`}>
      {!loading && (saveLabel || <T id='save' />)}
    </StyledSaveButton>
    <StyledCancelButton hideAnimation onClick={onCancel} data-analyticsid={`exit ${dataId}`}>
      {cancelLabel || <T id='cancel' />}
    </StyledCancelButton>
  </>
)

ActionsStep.propTypes = {
  dataId: PropTypes.string,
  loading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export { ActionsStep }
