export const DASH_ERRORS = {
  PRICE_NOT_MATCH: 160000,
  INVALID_PHONE_NUMBER: 160001,
  INVALID_INPUT: 160002,
  INSUFFICIENT_BALANCE: 135025,
  BAD_REQUEST: 150003,
  WRONG_CREDENTIALS: 150008,
  FORBIDDEN: 150005,
  TICKET_ID_EXPIRED: 150006
} as const

export const ERROR_CODE_TO_MESSAGE = {
  150000: 'internal_server_error',
  150001: 'not_found',
  150002: 'entity_not_found',
  150003: 'bad_request',
  150004: 'request_timeout',
  150005: 'forbidden',
  150006: 'entity_exists',
  150007: 'token_expired',
  150008: 'unauthorized',
  150009: 'insufficient_permission',
  150010: 'api_error',
  160001: 'invalid_phone_number'
} as const

export const ERROR_STATUS_CODES = {
  TOO_MANY_REQUESTS: 429
} as const
