// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { OUTSIDE_ASIA_COUNTRY_CODES } from './constants'
import { Map } from 'containers/FPLMap/Loadable'
import PickupAddressAutoComplete from 'containers/FPLOrderCreate/PickupAddressAutoComplete'
import { Button } from 'components/Button'
import { ResponsiveModal } from 'components/ResponsiveModal'
import { Col, NVInput, T } from '@nv/react-commons/src/Components'
import { CountryUtils, ValidatorUtils } from '@nv/react-commons/src/Utils'
import { StyledForm, StyledFormItem, StyledRow } from 'containers/FPLOrderCreate/styles'
import { useIntl } from 'hooks/common'
import { EditAddressProps } from './types'
import { RedAsterisk } from 'containers/FPLRegistration/styles'

const MAP_ZOOM_DEFAULT = 17

const StyledButton = styled(Button).attrs({
  type: 'primary',
  size: 'small'
})`
  padding: 10px;
`

const FullBodyResponsiveModal = styled(ResponsiveModal)`
  && {
    .ant-modal-body {
      padding: 0;
    }
  }
`

const EditAddress = ({ form, address, loading, country, onPickMap }: EditAddressProps) => {
  const intl = useIntl()
  const [mapModalVisible, setMapModalVisible] = useState(false)
  const [mapState, setMapState] = useState({
    zoom: MAP_ZOOM_DEFAULT,
    lat: undefined,
    lng: undefined
  })

  const isRequiredCity = useMemo(() => {
    return (
      country === COUNTRIES.MY.toUpperCase() ||
      country === COUNTRIES.PH.toUpperCase() ||
      country === COUNTRIES.ID.toUpperCase() ||
      country === COUNTRIES.VN.toUpperCase()
    )
  }, [country])

  const renderAddressByCountry = useMemo(
    () => () => {
      const commonRules = [
        {
          required: true,
          message: intl.formatMessage({ id: 'address_required' })
        },
        {
          max: 255,
          message: intl.formatMessage({ id: 'address_restriction' })
        }
      ]
      if (country === OUTSIDE_ASIA_COUNTRY_CODES.CN) {
        return (
          <StyledFormItem
            label={
              <>
                <T id='address' /> <RedAsterisk />
              </>
            }
          >
            {form.getFieldDecorator('keyword', {
              initialValue: address?.address1,
              rules: commonRules
            })(<NVInput />)}
          </StyledFormItem>
        )
      }
      return (
        <StyledFormItem
          label={
            <>
              <T id='address' /> <RedAsterisk />
            </>
          }
        >
          {form.getFieldDecorator('keyword', {
            initialValue: address?.address1,
            rules: [
              ...commonRules,
              {
                validator: addressValidator,
                message: intl.formatMessage({ id: 'please_select_address_from_autocomplete_or_map' })
              }
            ]
          })(
            <PickupAddressAutoComplete
              hide={mapModalVisible}
              filterOption={filterOption}
              onSelect={handleSelect}
              country={country}
              showMapOption
            />
          )}
        </StyledFormItem>
      )
    },
    [address?.address1, country, form, intl, mapModalVisible]
  )

  const openMap = () => setMapModalVisible(true)

  const handleCloseMap = () => {
    setMapValue()
    setMapModalVisible(false)
  }

  const handleMove = newMapState => {
    setMapState(newMapState)
  }

  const handleJump = newMapState => {
    setMapState(newMapState)
  }

  const setMapValue = (keyword = address?.address1) => form.setFieldsValue({ keyword })

  const handleSelect = ({ key, ...selectedAddress }) => {
    if (key === 'map') {
      setMapValue()
      openMap()
    } else {
      form.setFieldsValue(selectedAddress)
    }
  }

  const filterOption = (inputValue, option) => {
    if (option.key === 'map') {
      return true
    } else if (inputValue.toUpperCase) {
      return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }
  }

  const handlePickMap = () => {
    const { lat, lng } = mapState
    onPickMap(lat, lng)
    setMapValue()
    handleCloseMap()
  }

  const addressValidator = (rule, value, callback) => {
    if (!form.getFieldValue('country')) {
      callback('country is invalid')
    } else {
      callback()
    }
  }

  return (
    <StyledForm>
      <FullBodyResponsiveModal
        zIndex={1059}
        maskClosable={false}
        title={<T id='select_from_map' />}
        visible={mapModalVisible}
        onCancel={handleCloseMap}
        footer={
          <StyledButton loading={loading} onClick={handlePickMap} data-analyticsid='createOCSavePickupAddressFromMap'>
            <T id='select' />
          </StyledButton>
        }
      >
        <Map {...mapState} country={country} onMove={handleMove} onJump={handleJump} />
      </FullBodyResponsiveModal>
      <StyledRow>
        <Col xs={24} sm={20}>
          {renderAddressByCountry()}
        </Col>
        <Col xs={24} sm={4}>
          <StyledFormItem
            label={
              <>
                <T id='postcode' />
                {(country.toLowerCase() === COUNTRIES.SG ||
                  country.toLowerCase() === COUNTRIES.MY ||
                  country.toLowerCase() === COUNTRIES.TH) && <RedAsterisk />}
              </>
            }
          >
            {form.getFieldDecorator('postcode', {
              initialValue: address?.postcode,
              rules: [
                {
                  required:
                    country.toLowerCase() === COUNTRIES.SG ||
                    country.toLowerCase() === COUNTRIES.MY ||
                    country.toLowerCase() === COUNTRIES.TH,
                  message: intl.formatMessage(
                    { id: 'international_postcode_is_required_for_specific_country' },
                    { country: country.toUpperCase() }
                  )
                },
                {
                  len: country === OUTSIDE_ASIA_COUNTRY_CODES.CN ? 6 : CountryUtils.getPostcodeInfo(country).length,
                  message: intl.formatMessage(
                    { id: 'postcode_restriction' },
                    { x: country === OUTSIDE_ASIA_COUNTRY_CODES.CN ? 6 : CountryUtils.getPostcodeInfo(country).length }
                  )
                }
              ]
            })(<NVInput />)}
          </StyledFormItem>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col xs={24} sm={8}>
          <StyledFormItem
            label={
              <>
                <T id='city' />
                {isRequiredCity && <RedAsterisk />}
              </>
            }
          >
            {form.getFieldDecorator('city', {
              initialValue: address?.city,
              rules: [
                {
                  required: isRequiredCity,
                  message: intl.formatMessage({ id: 'types.zone.city.required' }, { country: country.toUpperCase() })
                },
                {
                  max: 255,
                  message: intl.formatMessage({ id: 'international_city_less_than_255_characters' })
                }
              ]
            })(<NVInput />)}
          </StyledFormItem>
        </Col>
        <Col xs={24} sm={16}>
          <StyledFormItem label={<T id='building_floor_unit' />}>
            {form.getFieldDecorator('address2', {
              initialValue: address?.address2,
              rules: [
                {
                  max: 255,
                  message: intl.formatMessage({ id: 'address_restriction' })
                }
              ]
            })(<NVInput placeholder='optional' />)}
          </StyledFormItem>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col xs={24} sm={8}>
          <StyledFormItem
            label={
              <>
                <T id='contact_person' />
                <RedAsterisk />
              </>
            }
          >
            {form.getFieldDecorator('name', {
              initialValue: address?.name,
              rules: [
                {
                  required: true,
                  message: `${intl.formatMessage({ id: 'name' })} ${intl.formatMessage({ id: 'is_required' })}`
                },
                {
                  max: 255,
                  message: `${intl.formatMessage({ id: 'international_name_less_than_255_characters' })}`
                }
              ]
            })(<NVInput />)}
          </StyledFormItem>
        </Col>
        <Col xs={24} sm={8}>
          <StyledFormItem
            label={
              <>
                <T id='phone' />
                <RedAsterisk />
              </>
            }
          >
            {form.getFieldDecorator('contact', {
              initialValue: address?.contact,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'international_phone_number_is_required' })
                },
                {
                  max: 32,
                  message: intl.formatMessage({
                    id: 'phone_number_restriction'
                  })
                },
                {
                  validator: ValidatorUtils.phoneNumber,
                  message: `${intl.formatMessage({ id: 'phone' })} ${intl.formatMessage({
                    id: 'must_be_the_valid_number_format'
                  })}`
                }
              ]
            })(<NVInput />)}
          </StyledFormItem>
        </Col>
        <Col xs={24} sm={8}>
          <StyledFormItem label={<T id='email' />}>
            {form.getFieldDecorator('email', {
              initialValue: address?.email,
              rules: [
                {
                  type: 'email',
                  message: intl.formatMessage({ id: 'international_invalid_email_format' })
                }
              ]
            })(<NVInput />)}
          </StyledFormItem>
        </Col>
      </StyledRow>
      {Object.keys(COUNTRIES).includes(country) && (
        <>
          <StyledFormItem style={{ display: 'none' }}>
            {form.getFieldDecorator('latitude', {
              initialValue: address?.latitude
            })(<NVInput type='hidden' />)}
          </StyledFormItem>
          <StyledFormItem style={{ display: 'none' }}>
            {form.getFieldDecorator('longitude', {
              initialValue: address?.longitude
            })(<NVInput type='hidden' />)}
          </StyledFormItem>
          <StyledFormItem style={{ display: 'none' }}>
            {form.getFieldDecorator('country', {
              initialValue: address?.country,
              rules: [
                {
                  required: true
                }
              ]
            })(<NVInput type='hidden' />)}
          </StyledFormItem>
        </>
      )}
    </StyledForm>
  )
}

export { EditAddress }
