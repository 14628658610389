import React from 'react'
import styled from 'styled-components'
import { Link as RRLink } from 'react-router-dom'

import { Icon } from '@nv/react-commons/src/Components'
import { Colors, Fonts } from 'themes'
import { NVLinkProps } from './Link.interface'

const StyledLink = styled<any>(RRLink)`
  ${Fonts.style.bold};
  color: ${props => props.color || Colors.greyishBrown};
  font-size: ${props => props.size || 13}px;
  line-height: 2.5;
  &:hover {
    text-decoration: underline;
    color: ${props => props.hoverColor || Colors.nvPriRed};
  }
`
const StyledA = StyledLink.withComponent('a')
const StyledLeftIcon = styled(Icon).attrs({
  gap: 8
})`
  color: inherit;
`
const StyledRightIcon = styled(Icon)`
  margin-right: 0;
  margin-left: 8px;
  color: inherit;
`

const Link = ({ leftIcon, rightIcon, children, ...props }: NVLinkProps) => {
  const L = props.to ? StyledLink : StyledA
  return (
    <L {...props}>
      {leftIcon && <StyledLeftIcon icon={leftIcon} />}
      {children}
      {rightIcon && <StyledRightIcon icon={rightIcon} />}
    </L>
  )
}

export { Link }
