import { createTransform } from 'redux-persist-immutable'

function customBlacklist (state, key) {
  switch (key) {
    case 'orderHistory':
      return state.remove('flash')
    case 'global':
      return state
        .removeIn(['user', 'error'])
        .removeIn(['user', 'loading'])
        .removeIn(['user', 'loginRequested'])
        .removeIn(['order'])
        .removeIn(['orderCreate', 'error'])
        .removeIn(['orderCreate', 'pricing'])
        .removeIn(['ocManual'])
        .removeIn(['pickup', 'cancelPickupLoading'])
        .remove('message')
    case 'entity':
      return state
        .removeIn(['alerts'])
        .removeIn(['history'])
        .removeIn(['batches'])
        .removeIn(['batchErrors'])
        .removeIn(['clientCredentials'])
        .removeIn(['createClientCredentials'])
        .removeIn(['webhooks'])
        .removeIn(['createWebhook'])
        .removeIn(['deleteWebhook'])
        .removeIn(['updateWebhook'])
        .removeIn(['sendOtp'])
        .removeIn(['verifyOtpCode'])
        .removeIn(['zendeskForm'])
        .removeIn(['createTopupRequest'])
        .removeIn(['prepaidTermsAccepted'])
        .removeIn(['forgotPassword'])
        .removeIn(['signUpPro'])
        .removeIn(['oAuth2ClientName'])
        .removeIn(['transactionHistory'])
    default:
      return state
  }
}

const CONFIG = {
  version: '10',
  storeConfig: {
    key: 'root',
    blacklist: ['startup'],
    transforms: [createTransform(customBlacklist, customBlacklist)]
  }
}

export default CONFIG
