import React from 'react'
import styled from 'styled-components'
import { Colors, Fonts, media, Metrics } from 'themes'
import { Col, NVCard, Row } from '@nv/react-commons/src/Components'
import { SecondaryDivider } from 'components/SecondaryDivider'
import { Panel } from 'components/Panel'

export const StyledContainer = styled.div`
  position: relative;
  overflow: auto;
`

export const StyledBanner = styled(({ isCompleted, ...rest }) => <div {...rest} />)`
  position: absolute;
  height: 192px;
  width: 100%;
  background-color: ${props => (props.isCompleted ? Colors.nvPriRed : Colors.greyishBrown)};
`
export const StyledWrapper = styled.div`
  position: relative;
  ${media.min.desktop`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 32px;
`};
  ${media.max.desktop`margin: 0 32px;`};
  ${media.max.tablet`margin: 0;`};
`
export const StyledStaticCard = styled(NVCard).attrs({
  type: 'flex'
})`
  border-radius: 5px;
  border: none;
  margin: 16px 0;
  ${media.max.tablet`border-radius: 0;`} .ant-card-body {
    padding: 16px 24px;
  }
`
export const StyledTrackingID = styled(Row)`
  ${Fonts.style.bold};
  font-size: 18px;
  color: ${Colors.black};
  line-height: 1.6;
`
export const StyledOrderDetails = styled(Row)`
  font-size: 14px;
  color: ${Colors.pinkishGrey};
  line-height: 1.6;
`
export const StyledStaticCardDivider = styled(SecondaryDivider)`
  margin: 8px 0;
  position: relative;
  z-index: 1;
`
export const StyledParagraph = styled.p`
  font-size: ${props => (props.inherit ? 'inherit' : `${props.fontSize}px` || 'initial')};
  margin: 0;
  text-transform: ${props => (props.upperCase ? 'uppercase' : 'initial')};
  font-weight: 400;
`
export const StyledCardTitle = styled.div`
  ${Fonts.style.bold};
  color: ${Colors.greyishBrown};
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin: 32px 0 8px;
  ${media.max.tablet`
    justify-content: center;
    margin: 32px 0 16px;
  `};
`
export const StyledCardSubtitle = styled(Row)`
  ${Fonts.style.bold};
  color: ${Colors.warmGrey};
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 8px;
  border-top: solid 1px ${Colors.solitude};
  padding-top: 16px;
`
export const StyledPanel = styled(Panel).attrs({
  fullWidth: true
})``
export const StyledRow = styled(Row).attrs({
  type: 'flex',
  gutter: 24,
  align: 'middle',
  justify: 'space-between'
})`
  flex-wrap: nowrap;
  ${media.max.tablet`flex-direction: column;`};
`
export const StyledDetailsRow = styled(StyledRow)`
  flex-wrap: wrap;
  align-items: flex-start !important;
`
export const StyledCol = styled(Col).attrs({
  sm: 8,
  xs: 24
})``
export const Affix = styled.div`
  z-index: ${props => (props.isDesktop ? 200 : 50)};
  position: sticky;
  top: 0;
`
export const StyledAffixedBanner = styled(StyledBanner)`
  position: static;
  height: ${Metrics.headerHeight}px;
`
export const FixedRow = styled(Row)`
  ${media.max.tablet`
  flex-wrap: wrap;
`};
  ${media.min.tablet`
  flex-wrap: nowrap;
`};
`
export const StyledTitle = styled.div`
  margin-bottom: 4px;
  ${Fonts.style.bold};
`

export const StyledCreateAtDate = styled.span`
  margin-left: 28px;
`
export const StyledCreateAtText = styled.span`
  font-size: 15px;
`
export const StyledImage = styled.img`
  max-height: 84px;
  cursor: pointer;
`
export const StyledImageArea = styled.img`
  max-height: 650px;
`
export const StyledDetailWrapper = styled.div`
  word-break: break-word;
  font-size: 13px;
`

export const CellNumberStyled = styled.div`
  width: ${props => props.width || 100}px;
  text-align: ${props => props.position || 'left'};
`
