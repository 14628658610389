import styled from 'styled-components'
import { Select } from 'antd'
import { Text } from 'components/Text'
import { Button } from 'components/Button'
import { Spin, Row } from '@nv/react-commons/src/Components'
import { Colors } from 'themes'
import { Link } from 'components/Link'

const Option = Select.Option

export const StyledImgFlag = styled.img`
  width: 24px;
  height: 16px;
  margin-right: 10px;
  margin-left: 12px;
`

export const RedAsterisk = styled.span`
  &&:after {
    content: ' *';
    color: red;
  }
`

export const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selection {
    min-height: 56px;
    display: flex;
    align-items: center;
    border: ${props => (props.border === 'none' ? 'none' : '1px solid #d9d9d9')};
    .ant-select-selection__rendered {
      min-height: inherit;
      display: flex;
      align-items: center;
      margin-left: 12px;

      ul {
        padding-bottom: 10px;
        max-height: 325px;

        .ant-select-search__field {
          margin-top: ${props => props.top}px;
        }
      }

      .ant-select-selection__choice {
        display: flex;
        align-items: center;
        height: 36px;
        margin-top: 0;
        margin-top: 10px;
        margin-right: 10px;
      }
    }
  }

  .ant-select-dropdown-menu-item {
    min-height: 36px;
    border-bottom: 1px solid #d9d9d9;
  }

  .ant-select-arrow {
    &&& {
      top: auto;
    }
    margin-top: 0;
  }

  .ant-select-disabled .ant-select-selection {
    &&& {
      background: white;
    }
  }

  &.ant-select-disabled {
    &&& {
      background: white;
      .ant-select-selection {
        background: white;
        .ant-select-selection__choice {
          opacity: 0.8;
          background: white;
        }
      }
    }
  }

  .ant-select-dropdown-menu-item-group {
    .ant-select-dropdown-menu-item-group-title {
      height: 100%;
      padding: 0;
      line-height: 23px;
    }
  }

  .ant-select-dropdown {
    ul {
      max-height: 525px;
    }
  }

  &&& {
    &&.unsupported {
      .ant-select-selection__choice {
        background: ${Colors.lightGrey};
        border-color: ${Colors.lightGreyRed};
      }
    }
  }
`

export const ContainerDropdown = styled.div`
  border: 1px solid ${props => props.borderColor};
  border-radius: 4px;
`

export const StyledVisibleSelect = styled(StyledSelect)`
  min-height: 100%;
  top: 0;

  &&& {
    .ant-select-selection {
      box-shadow: none;
    }
  }
`

export const StyledCountrySelectionContainer = styled.div`
  width: 100%;
`

export const StyledCard = styled.div`
  border-radius: 16px;
  background: white;
  margin: 0 auto;
  border: 1px solid ${Colors.N100};
  ${props => props.padding && `padding: ${props.padding};`}
`

export const StyledLabel = styled(Text)`
  margin-bottom: 10px;
`

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ background }) => {
    return `
      ${background && `background-color: ${background}`}
  `
  }}
`

export const RegistrationContainer = styled.div`
  width: 703px;
  margin: 0 auto;
`

export const StyledButtonWrapper = styled.div`
  width: 340px;
`

export const StyledCenterCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  width: 702px;
  border-radius: 16px;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
`

export const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const InsideWrapper = styled.div`
  overflow: auto;
`

export const StyleSpin = styled(Spin)`
  margin: 4px 0;
`

export const OptionDevider = styled.div`
  background: #d9d9d9;
  font-size: 14px;
  padding: 7px 12px;
`

export const StyledText = styled(Text)`
  ${props => {
    return `
    padding-left: ${props.left}px;
    padding-top: ${props.top}px;
    color: ${props.color};
    font-size: ${props.size}px;
    `
  }}
`

export const LightDevider = styled.div`
  width: 96%;
  height: 2px;
  border: 1px dashed ${Colors.lightGrey};
  margin: 8px 24px 0 12px;
`

export const StyledSubStepWrapper = styled.div`
  padding: 16px 30px;

  &&& {
    div {
      padding-left: 0;
    }
  }
`

export const HBorder = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.bg ?? Colors.balanceBg};
`

export const PreviewWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const CountryOption = styled.div`
  border: 1px solid ${Colors.lightGreyRed};
  border-radius: 4px;
  padding: 7px 12px;
  margin-right: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`

export const StyledBlockPosition = styled.div`
  text-align: ${props => props.align};
`

export const StyledRow = styled(Row)`
  padding-top: 16px;
`

export const StyledRateCard = styled.div`
  width: 100%;
  padding: 0 30px 30px;
`

export const StyledPreviewWrapper = styled.div`
  padding: 20px 14px;
`

export const StyledLink = styled(Link)`
  &&& {
    color: ${Colors.cherryRed};
  }
`

export const StyledOption = styled(Option)`
  display: flex;
  align-items: center;
`
