// Map react-intl & Lokalise locale to Ninja Van locale
import Config from 'configs'

export const getNVLocale = (locale, country) => {
  switch (locale) {
    case 'en':
      switch (country) {
        case 'ph':
          return 'en-PH'
        case 'my':
          return 'en-MY'
        default:
          return 'en-SG'
      }
    default:
      return locale
  }
}

// Map Ninja Van locale to react-intl & Lokalise locale
export const getLocale = locale => {
  return locale.startsWith('en') ? 'en' : locale
}

// Infer the locale when the User-Agent is coming from our website
export const getLocaleFromReferrer = () => {
  const referrer = document.referrer
  const regex = /www(?:-..)?\.(?:ninjavan|ninjaxpress)\.co\/(\w\w-\w\w)/
  const match = referrer.match(regex)
  if (match) {
    return match[1]
  }
  return null
}

export const getLocaleFromNavigator = () => {
  if (Config.ENVIRONMENT === 'cd') {
    return 'fr-MA'
  } else {
    return navigator.language
  }
}

export const getLanguagePrefix = (languageCode = '') => languageCode.substring(0, 2)
