import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt'
import { DatePicker, NVInput, T } from '@nv/react-commons/src/Components'
import { CountryUtils, ValidatorUtils } from '@nv/react-commons/src/Utils'
import { DeliveryInstructionsFormItem } from 'components/DeliveryInstructionsFormItem'
import { DeliveryTimeFormItem } from 'components/DeliveryTimeFormItem'
import { DeliveryTypeFormItem } from 'components/DeliveryTypeFormItem'
import { FIELDS } from 'components/OCForm/constants'
import { SubCountryDropdown } from 'components/SubCountryDropdown'
import { TrackingNumberFormItem } from 'components/TrackingNumberFormItem'
import { getDefaultServiceLevel } from 'containers/DeliveryType/utils'
import { ParcelDescriptionFormItem } from '../ParcelDescriptionFormItem'
import { DangerousGoodFormItem } from '../DangerousGoodFormItem'
import { validateEastMalaysiaPostcode } from 'utils/validate'
import { Checkbox, Tooltip } from 'antd/lib'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fa-pro-regular/faInfoCircle'
import { OrderDeliveryAddressDetails } from '../OCDeliveryDetails'
import { getDeliveryAddressDivisionFieldName } from 'utils/address'
import { StyledFormItem, StyledErrorMessage, StyledIcon, StyledTextArea } from './styles'
import { OC_SERVICE_TYPES } from 'containers/Base/constants'
import { DEFAULT_TIMESLOT_ID } from 'constants/deliveryTimeslots'
import { mapBool } from 'utils/csvMappingFields'
import { requestedPieceTrackingNumbersValidator } from 'utils/csvMappingFields.helpers'
import { isB2BBundleOrder } from 'utils/createOrderPayload'
import { TemperatureControlFormItem } from 'components/TemperatureControlFormItem/index'

const compareToPickupDate = readyDatetime => (rule, value, callback) => {
  let error
  const end = moment(value)
  if (end.isValid()) {
    const now = moment()
    const momentVal = moment(readyDatetime)
    const start = momentVal.isValid() ? momentVal : now
    if (end.isBefore(start) || end.isAfter(now.add(1, 'years'))) {
      error = 'invalid_delivery_date'
    }
  }
  callback(error)
}

function disabledDate (date) {
  if (!date) {
    return false
  }
  return date.weekday() === 0 || date.isBefore(moment())
}

function positiveNumberValidator (intl) {
  return {
    validator: ValidatorUtils.number(intl.formatMessage({ id: 'invalid_number' }), value => value < 0)
  }
}

function weightValidator (intl) {
  return {
    validator: ValidatorUtils.number(intl.formatMessage({ id: 'invalid_number' }), value => value <= 0)
  }
}

function weightMaxValidator (country, intl, isB2BBundle) {
  const maxWeight = 50
  return (
    country.toLowerCase() === 'vn' &&
    !isB2BBundle && {
      validator: ValidatorUtils.number(
        intl.formatMessage({ id: 'invalid_weight' }, { x: maxWeight }),
        value => value > maxWeight
      )
    }
  )
}

function cartonMaxValidator (intl) {
  const maxCarton = 999
  return {
    validator: ValidatorUtils.number(
      intl.formatMessage({ id: 'invalid_number_of_carton' }, { x: maxCarton }),
      value => value > maxCarton
    )
  }
}

function cartonMinValidator (intl) {
  return {
    validator: ValidatorUtils.number(intl.formatMessage({ id: 'min_number_of_carton' }), value => value < 1)
  }
}

function requestedPieceTidsValidator (intl, getFieldValue) {
  return (_, value, callback) => {
    const cartons = getFieldValue(FIELDS.CARTON_NUMBER)
    const errMsg = requestedPieceTrackingNumbersValidator(value, cartons)
    if (errMsg !== null) {
      callback(intl.formatMessage({ id: errMsg }))
    } else {
      callback()
    }
  }
}

function maxInsuredValueValidator (country, intl, isB2BBundle) {
  const maxInsuredValue = 20000000
  return (
    country.toLowerCase() === 'vn' &&
    !isB2BBundle && {
      validator: ValidatorUtils.number(
        intl.formatMessage({ id: 'invalid_insured_value' }, { x: maxInsuredValue }),
        value => value > maxInsuredValue
      )
    }
  )
}

export const CustomerName = ({ form: { getFieldDecorator }, intl }) => (
  <StyledFormItem label={<T id='customer_name' />}>
    {getFieldDecorator(FIELDS.NAME, {
      rules: [
        {
          required: true,
          message: intl.formatMessage({
            id: 'customer_name_required'
          })
        },
        {
          max: 255,
          message: intl.formatMessage({
            id: 'customer_name_restriction'
          })
        }
      ]
    })(<NVInput />)}
  </StyledFormItem>
)
CustomerName.propTypes = {
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export const CustomerContact = ({ form: { getFieldDecorator }, intl, isBulkUpload }) => {
  const rules = [
    {
      required: true,
      message: intl.formatMessage({
        id: 'customer_contact_required'
      })
    },
    {
      max: 32,
      message: intl.formatMessage({
        id: 'customer_contact_restriction'
      })
    }
  ]
  if (!isBulkUpload) {
    rules.push({
      validator: ValidatorUtils.phoneNumber,
      message: intl.formatMessage({
        id: 'customer_contact_format'
      })
    })
  }

  return (
    <StyledFormItem label={<T id='customer_contact' />}>
      {getFieldDecorator(FIELDS.PHONE_NUMBER, { rules })(<NVInput />)}
    </StyledFormItem>
  )
}
CustomerContact.propTypes = {
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isBulkUpload: PropTypes.bool
}

export const CustomerEmail = ({ form: { getFieldDecorator }, intl }) => (
  <StyledFormItem label={<T id='customer_email' />}>
    {getFieldDecorator(FIELDS.EMAIL, {
      rules: [
        {
          type: 'email',
          message: intl.formatMessage({ id: 'invalid_email' })
        }
      ]
    })(<NVInput placeholder={intl.formatMessage({ id: 'optional' })} />)}
  </StyledFormItem>
)
CustomerEmail.propTypes = {
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export const DeliveryType = ({ form, ocSettings }) => (
  <DeliveryTypeFormItem
    form={form}
    formItem={StyledFormItem}
    ocSettings={ocSettings}
    initialValue={getDefaultServiceLevel(ocSettings?.availableServiceLevels)}
  />
)
DeliveryType.propTypes = {
  form: PropTypes.object.isRequired,
  ocSettings: PropTypes.object
}

export const DeliveryDate = ({ form: { getFieldDecorator }, intl, readyDatetime }) => (
  <StyledFormItem label={<T id='delivery_date' />}>
    {getFieldDecorator(FIELDS.DELIVERY_START_DATE, {
      initialValue: moment(readyDatetime).add(1, 'days'),
      rules: [
        {
          validator: compareToPickupDate(readyDatetime),
          message: intl.formatMessage({ id: 'invalid_delivery_date' })
        }
      ]
    })(
      <DatePicker
        data-testid='delivery-date'
        suffixIcon={<StyledIcon icon={faCalendarAlt} />}
        pickerWidth='100%'
        disabledDate={disabledDate}
        allowClear={false}
      />
    )}
  </StyledFormItem>
)
DeliveryDate.propTypes = {
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  readyDatetime: PropTypes.object
}

export const DeliveryTime = ({ form, ocSettings, country }) => (
  <DeliveryTimeFormItem
    form={form}
    formItem={StyledFormItem}
    country={country}
    initialValue={DEFAULT_TIMESLOT_ID}
    allowedDeliveryTimeslots={ocSettings?.allowedDeliveryTimeslots}
  />
)
DeliveryTime.propTypes = {
  form: PropTypes.object.isRequired,
  country: PropTypes.string
}

export const CustomerAddress = ({ form: { getFieldDecorator }, intl }) => {
  return (
    <StyledFormItem
      label={
        <span>
          <T id='customer_address' />
          <InfoTooltip tooltipTextId='customer_address_tooltip' />
        </span>
      }
    >
      {getFieldDecorator(FIELDS.ADDRESS1, {
        rules: [
          {
            required: true,
            message: intl.formatMessage({
              id: 'customer_address_required'
            })
          },
          {
            max: 255,
            message: intl.formatMessage({
              id: 'customer_address_restriction'
            })
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}
CustomerAddress.propTypes = {
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export const Postcode = ({ form: { getFieldDecorator, getFieldValue }, intl, country, isNinjaPackOrder }) => {
  const postcodeInfo = CountryUtils.getPostcodeInfo(country)

  const validateEastMalaysia = (rule, value, callback) => {
    let error
    if (validateEastMalaysiaPostcode({ country, isNinjaPackOrder, value })) error = 'Invalid post code'
    else {
      document.getElementById('post_code_restriction').style.display = 'none'
    }
    callback(error)
  }

  const renderPostCodeInvalidMessage = () => {
    const postCodeInput = getFieldValue('postcode')
    if (validateEastMalaysiaPostcode({ country, isNinjaPackOrder, value: postCodeInput })) {
      return (
        <StyledErrorMessage id='postcode_east_malaysia_retry_id'>
          <T id='postcode_east_malaysia_restriction' />
        </StyledErrorMessage>
      )
    }
  }
  const rules = [
    {
      required: postcodeInfo.required,
      message: intl.formatMessage({ id: 'postcode_required' })
    }
  ]
  if (postcodeInfo.length > 0) {
    rules.push({
      len: postcodeInfo.length,
      message: intl.formatMessage({ id: 'postcode_restriction' }, { x: postcodeInfo.length })
    })

    rules.push({
      validator: validateEastMalaysia,
      message: 'postcode_east_malaysia_retry_fixed'
    })
  }
  return (
    <StyledFormItem help={renderPostCodeInvalidMessage()} label={<T id='postcode' />}>
      {getFieldDecorator(FIELDS.POSTCODE, { rules })(<NVInput />)}
    </StyledFormItem>
  )
}
Postcode.propTypes = {
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  country: PropTypes.string,
  isNinjaPackOrder: PropTypes.bool
}

export const CartonsNumber = ({ form: { getFieldDecorator }, intl }) => {
  return (
    <StyledFormItem label={<T id='no_of_cartons' />}>
      {getFieldDecorator(FIELDS.CARTON_NUMBER, {
        rules: [
          {
            required: true,
            message: intl.formatMessage({ id: 'number_of_carton_required' })
          },
          cartonMaxValidator(intl),
          cartonMinValidator(intl)
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}
CartonsNumber.propTypes = {
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export const RequestedPieceTIDs = ({ form: { getFieldDecorator, getFieldValue }, intl }) => {
  return (
    <StyledFormItem label={<T id='requested_piece_tracking_ids' />}>
      {getFieldDecorator(FIELDS.REQUESTED_PIECE_TRACKING_NUMBERS, {
        rules: [{ validator: requestedPieceTidsValidator(intl, getFieldValue) }]
      })(
        <StyledTextArea
          placeholder={intl.formatMessage({
            id: 'requested_piece_tracking_ids_placeholder'
          })}
          autoSize={{ minRows: 3 }}
        />
      )}
    </StyledFormItem>
  )
}
RequestedPieceTIDs.propTypes = {
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export const ShipperOrderNumber = ({ form: { getFieldDecorator }, intl }) => {
  return (
    <StyledFormItem label={<T id='shipper_order_number_field' />}>
      {getFieldDecorator(FIELDS.SHIPPER_ORDER_NUMBER)(<NVInput placeholder={intl.formatMessage({ id: 'optional' })} />)}
    </StyledFormItem>
  )
}
ShipperOrderNumber.propTypes = {
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export const RDOorGRN = ({ form: { getFieldDecorator }, intl }) => {
  return (
    <StyledFormItem label={<T id='rdo_or_grn_required' />}>
      {getFieldDecorator(FIELDS.RDO_REQUIRED, {
        getValueProps: value => ({ checked: mapBool(value) })
      })(<Checkbox>{intl.formatMessage({ id: 'rdo_required' })}</Checkbox>)}
      {getFieldDecorator(FIELDS.GRN_REQUIRED, {
        getValueProps: value => ({ checked: mapBool(value) })
      })(<Checkbox>{intl.formatMessage({ id: 'grn_required' })}</Checkbox>)}
    </StyledFormItem>
  )
}

RDOorGRN.propTypes = {
  form: PropTypes.object,
  intl: PropTypes.object
}

export const Weight = ({
  isRegularOC,
  order,
  ocSettings,
  form: { getFieldDecorator },
  isCorpAWBOrder,
  intl,
  country
}) => {
  const isB2BBundle = order ? isB2BBundleOrder(order) : false
  const isCountryMY = country.toLowerCase() === 'my'
  const optionalWeightServiceTypes = [
    OC_SERVICE_TYPES.DOCUMENT,
    OC_SERVICE_TYPES.B2B_BUNDLE,
    OC_SERVICE_TYPES.CORPORATE_B2B_BUNDLE
  ]

  const required =
    (isCountryMY && ocSettings.allowUseAccountBalance) ||
    (isRegularOC && !optionalWeightServiceTypes.includes(order?.serviceType)) ||
    isCorpAWBOrder
  return (
    <StyledFormItem label={<T id='weight_kg' />}>
      {getFieldDecorator(FIELDS.WEIGHT, {
        rules: [
          weightValidator(intl),
          weightMaxValidator(country, intl, isB2BBundle),
          {
            required,
            message: intl.formatMessage({
              id: 'weight_required'
            })
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}
Weight.propTypes = {
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
  ocSettings: PropTypes.object,
  isRegularOC: PropTypes.bool,
  isCorpAWBOrder: PropTypes.bool,
  order: PropTypes.object
}

export const COD = ({ form: { getFieldDecorator }, intl, country }) => (
  <StyledFormItem
    label={
      <span>
        <T id='cod_x' values={{ x: CountryUtils.getCurrencySymbol(country) }} />
        {country.toUpperCase() === COUNTRIES.SG.toUpperCase() && <InfoTooltip tooltipTextId='cod_tooltip.sg' />}
      </span>
    }
  >
    {getFieldDecorator(FIELDS.COD, { rules: [positiveNumberValidator(intl)] })(
      <NVInput placeholder={intl.formatMessage({ id: 'optional' })} />
    )}
  </StyledFormItem>
)
COD.propTypes = {
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  country: PropTypes.string
}

export const InsuredValue = ({ form: { getFieldDecorator }, intl, country, order }) => {
  const isB2BBundle = order ? isB2BBundleOrder(order) : false
  return (
    <StyledFormItem
      label={
        <span>
          <T id='insured_value_x' values={{ x: CountryUtils.getCurrencySymbol(country) }} />
          <InfoTooltip tooltipTextId={`insured_value_tooltip.${country?.toLowerCase()}`} />
        </span>
      }
    >
      {getFieldDecorator(FIELDS.INSURED_VALUE, {
        rules: [positiveNumberValidator(intl), maxInsuredValueValidator(country, intl, isB2BBundle)]
      })(<NVInput placeholder={intl.formatMessage({ id: 'optional' })} />)}
    </StyledFormItem>
  )
}

InsuredValue.propTypes = {
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
  order: PropTypes.object
}

export const TrackingNumber = ({
  form,
  ocSettings,
  masterOcSettings,
  validationData,
  isValidationLoading,
  title,
  isNinjaPackOrder,
  isCorpAWBOrder,
  order
}) => {
  const calculatedTitle = title || (order?.isMpsForm ? 'requested_mps_tracking_id' : null)
  return (
    <TrackingNumberFormItem
      validationData={validationData}
      isValidationLoading={isValidationLoading}
      ocSettings={ocSettings}
      masterOcSettings={masterOcSettings}
      form={form}
      formItem={StyledFormItem}
      trackingId={order?.requestedTrackingNumber ?? ''}
      title={calculatedTitle}
      isNinjaPackOrder={isNinjaPackOrder}
      isCorpAWBOrder={isCorpAWBOrder}
    />
  )
}
TrackingNumber.propTypes = {
  form: PropTypes.object.isRequired,
  ocSettings: PropTypes.object,
  order: PropTypes.object,
  validationData: PropTypes.any,
  isValidationLoading: PropTypes.object,
  title: PropTypes.string,
  isNinjaPackOrder: PropTypes.bool,
  isCorpAWBOrder: PropTypes.bool
}

export const DeliveryInstructions = ({ form }) => <DeliveryInstructionsFormItem form={form} formItem={StyledFormItem} />
DeliveryInstructions.propTypes = {
  form: PropTypes.object.isRequired
}

export const ParcelDescription = ({ form, country }) => (
  <ParcelDescriptionFormItem form={form} formItem={StyledFormItem} country={country} />
)
ParcelDescription.propTypes = {
  form: PropTypes.object.isRequired,
  country: PropTypes.string
}

export const DangerousGood = ({ form }) => <DangerousGoodFormItem form={form} formItem={StyledFormItem} />
DangerousGood.propTypes = {
  form: PropTypes.object.isRequired
}

export const TemperatureControl = ({ form, disabled, initialValue, ocSettings }) => (
  <TemperatureControlFormItem
    form={form}
    formItem={StyledFormItem}
    disabled={disabled}
    initialValue={initialValue}
    ocSettings={ocSettings}
  />
)
TemperatureControl.propTypes = {
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  initialValue: PropTypes.string
}
TemperatureControl.defaultProps = {
  disabled: false,
  initialValue: ''
}

// to be deprecated soon
export const SubCountryGroupedInputs = ({ form: { getFieldDecorator, setFieldsValue }, zones, onOrderChange }) => (
  <SubCountryDropdown
    rules={[{ required: true }]}
    zones={zones}
    formItemContainer={StyledFormItem}
    getFieldDecorator={getFieldDecorator}
    setFieldsValue={setFieldsValue}
    onChange={deliveryZone => {
      onOrderChange({ values: { deliveryZone } })
    }}
  />
)
SubCountryGroupedInputs.propTypes = {
  form: PropTypes.object.isRequired,
  zones: PropTypes.array,
  onOrderChange: PropTypes.func
}

// new address divisions which should replace SubCountryGroupedInputs
export const AddressDeliveryDetailsGroupedInputs = ({ form, country, intl, order }) => (
  <OrderDeliveryAddressDetails
    country={country}
    form={form}
    intl={intl}
    source='ocForm'
    formItemContainer={StyledFormItem}
    deliveryAddressInitialValue={{
      postcode: order?.postcode,
      region: order?.[getDeliveryAddressDivisionFieldName({ country, level: 1 })],
      locality: order?.[getDeliveryAddressDivisionFieldName({ country, level: 2 })],
      neighbourhood: order?.[getDeliveryAddressDivisionFieldName({ country, level: 3 })],
      deliveryInstructions: order?.instructions
    }}
  />
)

AddressDeliveryDetailsGroupedInputs.propTypes = {
  form: PropTypes.object.isRequired,
  country: PropTypes.string,
  order: PropTypes.object
}

export const InfoTooltip = ({ tooltipTextId }) => {
  return (
    <Tooltip title={<T id={tooltipTextId} values={{ br: <br /> }} />} placement='bottom'>
      <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 10 }} />
    </Tooltip>
  )
}
