// Design System
// Neutral
const N700 = '#242628'
const N600 = '#494D50'
const N500 = '#6E7378'
const N400 = '#8C9399'
const N300 = '#AAAEB3'
const N200 = '#BFC4C9'
const N100 = '#E6EAED'
const N050 = '#F2F5F7'
const N025 = '#F7F8FA'
const N000 = '#FFFFFF'
const P600 = '#C2002F'
const P500 = '#E50239'
const P100 = '#DC5065'

// Alerts
// Red
const R300 = '#E86161'
const R200 = '#FFD8D8'
const R100 = '#FFF1F0'

// Yellow
const Y300 = '#FFC667'
const Y200 = '#FFE58F'
const Y100 = '#FFFBE6'

// Green
const G300 = '#02A181'
const G200 = '#AFEFDB'
const G100 = '#ECF9F5'

export const WheelsColors = {
  N700,
  N600,
  N500,
  N400,
  N300,
  N200,
  N100,
  N050,
  N025,
  N000,
  P600,
  P500,
  P100,
  R300,
  R200,
  R100,
  Y300,
  Y200,
  Y100,
  G300,
  G200,
  G100
}
