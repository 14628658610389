import { useEffect } from 'react'
import { useQueryDetail } from 'containers/FPLOrderCreate/useQueryDetail'
import { getPartnerDetail } from 'services/api/fplApi'
import { NOT_FOUND_CODE } from 'containers/FPLErrorPage/constants'

const useMarketingContent = (navigate, location, routeName, extendedProps = {}) => {
  const { error, detail, loading, updateQuery, user, ...rest } = useQueryDetail(getPartnerDetail)

  useEffect(() => {
    if (error?.code === NOT_FOUND_CODE && !location?.state?.showMarketing) {
      navigate(routeName, {
        replace: true,
        state: { showMarketing: true, noSider: true, showSearch: false, ...extendedProps }
      })

      return
    }

    if (error && location?.state?.showMarketing) {
      navigate(routeName, {
        replace: true,
        state: { showMarketing: false, noSider: false, showSearch: false, ...extendedProps }
      })
    }
  }, [])

  return {
    ...rest,
    loading,
    detail,
    error,
    updateQuery,
    user
  }
}

export { useMarketingContent }
