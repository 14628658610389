/**
 *
 * Modal
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { NVModal } from '@nv/react-commons/src/Components'

const StyledModal = styled(NVModal).attrs({
  footer: null
})``

class Modal extends React.PureComponent {
  render () {
    return <StyledModal {...this.props}>{this.props.children}</StyledModal>
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['basic', 'info', 'form'])
}

Modal.defaultProps = {
  children: 'Modal',
  type: 'basic'
}

export { Modal }
