import SGFlag from './flag/sg.svg'
import MYFlag from './flag/my.svg'
import CNFlag from './flag/china.svg'
import IDFlag from './flag/id.svg'
import MMFlag from './flag/mm.svg'
import PHFlag from './flag/ph.svg'
import THFlag from './flag/th.svg'
import VNFlag from './flag/vn.svg'
import BNFlag from './flag/bn.svg'
import LAFlag from './flag/la.svg'
import KHFlag from './flag/kh.svg'
import TLFlag from './flag/tl.svg'
import Config from 'configs'

export const FLAGS_BY_COUNTRY = {
  SG: SGFlag,
  MY: MYFlag,
  ID: IDFlag,
  PH: PHFlag,
  TH: THFlag,
  VN: VNFlag,
  MM: MMFlag,
  CN: CNFlag,
  BN: BNFlag,
  LA: LAFlag,
  KH: KHFlag,
  TL: TLFlag
}

export const API_RESPONSE_STATUS = {
  SUCCESS: 200,
  NOT_FOUND: 404,
  INTERNAL_ERROR: 500,
  NOT_IMPLEMENTED: 501
}

export const OPTION_PADDING = {
  SM: 4,
  LG: 14
}

export const ASIAN_COUNTRIES = {
  SG: 'Singapore',
  MY: 'Malaysia',
  ID: 'Indonesia',
  PH: 'Philippines',
  TH: 'Thailand',
  VN: 'Vietnam',
  MM: 'Myanmar',
  KH: 'Campuchia',
  LA: 'Laos',
  BN: 'Brunei',
  TL: 'Timor-Leste'
}

export const NUMBER_OF_PARCEL_OPTIONS_BY_KEY = {
  sm: {
    code: 'sm',
    label: 'international_1_to_10_parcels',
    range: {
      min: 1,
      max: 10
    }
  },
  md: {
    code: 'md',
    label: 'international_11_to_30_parcels',
    range: {
      min: 11,
      max: 30
    }
  },
  lg: {
    code: 'lg',
    label: 'international_31_to_70_parcels',
    range: {
      min: 31,
      max: 70
    }
  },
  xl: {
    code: 'xl',
    label: 'international_more_than_71_parcels',
    range: {
      min: 71
    }
  }
}

export const PARCEL_WEIGHT_OPTIONS_BY_KEY = {
  sm: {
    code: 'sm',
    label: 'international_a_half_to_3_kg',
    range: {
      min: 0.5,
      max: 3
    }
  },
  md: {
    code: 'md',
    label: 'international_half_of_3_to_15_kg',
    range: {
      min: 3.5,
      max: 15
    }
  },
  lg: {
    code: 'lg',
    label: 'international_half_of_15_to_50_kg',
    range: {
      min: 15.5,
      max: 30
    }
  }
}

export const FORM_FIELD = {
  ORIGIN: 'from',
  DESTINATION: 'to',
  QUANTITY: 'quantity',
  WEIGHT: 'weight'
}

export const ACTION_MODE = {
  CREATE: 1,
  EDIT: 2,
  DONE: 3
}
