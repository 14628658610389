import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'hooks/common'
import { ErrorDescription } from './ErrorDescription'
import { StyledAlert } from './styles'

const NoServicesErrorAlert = ({ message }) => {
  const intl = useIntl()
  return (
    <StyledAlert
      type='warning'
      message={message || intl.formatMessage({ id: 'cross_border_profile_no_service' })}
      description={<ErrorDescription />}
      showIcon
    />
  )
}

NoServicesErrorAlert.propTypes = {
  message: PropTypes.string
}

const InactivatedProfileErrorAlert = ({ message }) => {
  const intl = useIntl()
  return (
    <StyledAlert
      type='warning'
      message={message || intl.formatMessage({ id: 'cross_border_profile_not_activated' })}
      description={<ErrorDescription />}
      showIcon
    />
  )
}

InactivatedProfileErrorAlert.propTypes = {
  message: PropTypes.string
}

export { NoServicesErrorAlert, InactivatedProfileErrorAlert }
