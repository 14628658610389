import { NINJA_URL_SIGNUP_DATA_STORAGE_KEY } from 'containers/SignUpProfile/SignUpProfile.constants'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useStorage } from './useStorage'
import { isEmpty, pickBy } from 'lodash'

export const useNinjaURLParams = () => {
  const [searchParams] = useSearchParams()
  const params = {
    encryptedData: searchParams.get('bp_e'),
    encryptedIv: searchParams.get('bp_iv'),
    leadGenChannel: searchParams.get('utm_medium'),
    leadSource: searchParams.get('utm_source'),
    leadSourceDetails: searchParams.get('utm_campaign'),
    leadKeyword: searchParams.get('utm_term'),
    leadContent: searchParams.get('utm_content'),
    pageUrl: searchParams.get('site')
  }
  const [, setNinjaUrlSignupData] = useStorage(NINJA_URL_SIGNUP_DATA_STORAGE_KEY, {}, 'local')
  useEffect(() => {
    const nonEmptyParams = pickBy(params, Boolean)
    if (!isEmpty(nonEmptyParams)) {
      setNinjaUrlSignupData(nonEmptyParams)
    }
  }, [])
}
