/**
 *
 * AkiraRouteModal
 *
 */
import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { Modal } from '@nv/react-akira'

import { AkiraRouteModalProps } from './AkiraRouteModal.interface'

export const AkiraRouteModal = ({ path = 'modal', onClose, ...modalProps }: AkiraRouteModalProps) => {
  const navigate = useNavigate()

  const handleOnClose = () => {
    if (typeof onClose === 'function') {
      onClose()
    }
    navigate(-1)
  }

  return (
    <Routes>
      <Route path={path} element={<Modal {...modalProps} visible onClose={handleOnClose} />} />
    </Routes>
  )
}
