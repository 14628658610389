import { COUNTRIES } from '@nv/react-commons/src/Constants'

export const getIcNumberMaxLengthByCountry = country => {
  if (!country) return []
  switch (country.toLowerCase()) {
    case COUNTRIES.MY:
      return [12]
    case COUNTRIES.ID:
      return [16]
    case COUNTRIES.VN:
      return [9, 12]
    case COUNTRIES.SG:
    case COUNTRIES.PH:
    case COUNTRIES.TH:
    case COUNTRIES.MM:
    case COUNTRIES.MA:
      return [13]
    default:
      return []
  }
}

export const getIdentityVerificationGForm = locale => {
  const lang = locale?.split('-')?.[0]?.toLowerCase()
  switch (lang) {
    case 'vi':
      return 'https://forms.gle/S1Edpbe7jDmnX99X6'
    default:
      return 'https://forms.gle/uURPGzbsxpQS8pa98'
  }
}
