import { getPickupAddressDivisionFieldName, getDeliveryAddressDivisionFieldName } from 'utils/address'
import { AddressSource } from './AddressDivisions.interface'

export const isDeliveryUnsupportedIncluded = (source: AddressSource) => {
  if (source === 'delivery') return false
  if (source === 'pickup') return true
}

export const isPickupUnsupportedIncluded = (source: AddressSource) => {
  if (source === 'delivery') return true
  if (source === 'pickup') return false
}

export const getAddressDivisionFieldName = (level: 1 | 2 | 3, source: string, country: string) => {
  return source === 'pickup'
    ? getPickupAddressDivisionFieldName(level)
    : getDeliveryAddressDivisionFieldName({ country, level })
}
