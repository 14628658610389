import PropTypes from 'prop-types'
import React, { createContext, useEffect, useState } from 'react'

export const SideNavContext = createContext()

export const SideNavProvider = ({ children }) => {
  const [showSideNav, setShowSideNav] = useState(true)
  useEffect(() => {
    if (showSideNav) {
      document.body.classList.add('noScroll')
    } else {
      document.body.classList.remove('noScroll')
    }
  }, [showSideNav])
  return <SideNavContext.Provider value={[showSideNav, setShowSideNav]}>{children}</SideNavContext.Provider>
}

SideNavProvider.propTypes = {
  children: PropTypes.node
}
