import { CountryUtils, CsvUtils, ExcelUtils } from '@nv/react-commons/src/Utils'
import { ALLOWED_COUNTRY, COUNTRY_LABEL, ERROR_CODE_MAPPING, RULE_TYPES } from '../FPLOrderCreate/constants'
import { PICKUP_TYPES } from '../PickupType/constants'
import {
  B2B_INSTRUCTION_DATA,
  CCType,
  B2C_FIELD_KEYS,
  FLOAT_NUMBER_AND_GREATER_THAN_0,
  FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000,
  INTEGER_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000,
  MMCC_B2B_EXAMPLE_1,
  MMCC_KEYS,
  MMCC_B2B_EXAMPLE_2,
  B2B_FIELD_KEYS,
  B2C_INSTRUCTION_DATA,
  MMCC_ROW_EXAMPLE_1,
  MMCC_ROW_EXAMPLE_2,
  MMCC_ROW_EXAMPLE_3,
  MMCC_ROW_EXAMPLE_4,
  MMCC_ROW_EXAMPLE_5,
  MMCC_ROW_EXAMPLE_6,
  MMCC_ROW_EXAMPLE_7,
  MMCC_ROW_EXAMPLE_8,
  MMCC_ROW_EXAMPLE_9,
  MMCC_ROW_EXAMPLE_10,
  MMCC_KEYS_TRANSLATOR_FROM_CODE_TO_FRIENTLY_NAME,
  MMCC_KEYS_TRANSLATOR_FROM_FRIENDLY_NAME_TO_CODE,
  RECIPIENT_COMPARATION_KEYS
} from './constants'
import { endsWith, groupBy, isEmpty, map, round, toNumber, uniq, uniqBy } from 'lodash'
import {
  MappedDataKeys,
  RowValueObject,
  Service,
  ValidationProps,
  UploadedBoxOrder,
  Dimension,
  B2BBoxDetail,
  B2CBoxDetail
} from './types'
import { isValidHeader } from '../FPLOrderCreate/dataUtils'
import { COLUMN_TRUNCATION_MAP, ERRORS_TRANSLATION_KEYS, MMCC_VALIDATION_RULES } from './validationRules'
import { DELIVERY_TIME_PARAMS } from 'containers/FPLOrderRequestList/constants'
import { UPLOAD_CSV_OPTION } from 'containers/FPLAllOrders/constants'
import { formatNumber } from 'containers/FPLParcelDetail/dataUtils'

export const getCountryLabel = (country: string) => {
  return CountryUtils.getCountryNames(country) || COUNTRY_LABEL[country]
}

export const getPickupType = (service: Service) => {
  return !ALLOWED_COUNTRY[service.origin_country?.toUpperCase()] ? PICKUP_TYPES.NO : PICKUP_TYPES.SCHEDULED
}

export const generateTemplate = (ccType: CCType, locale: string, intl) => {
  const isB2C = ccType === CCType.B2C
  const instructionFields = isB2C ? B2C_INSTRUCTION_DATA : B2B_INSTRUCTION_DATA
  const headerFields = Object.keys(instructionFields).map(key => {
    if (MMCC_KEYS_TRANSLATOR_FROM_CODE_TO_FRIENTLY_NAME[locale]) {
      return MMCC_KEYS_TRANSLATOR_FROM_CODE_TO_FRIENTLY_NAME[locale][key]
    }
    return MMCC_KEYS_TRANSLATOR_FROM_CODE_TO_FRIENTLY_NAME['en'][key]
  })
  const translatorInstructions = Object.values(instructionFields).map(key => intl.formatMessage({ id: key }))
  const exampleData = isB2C
    ? [
      MMCC_ROW_EXAMPLE_1,
      MMCC_ROW_EXAMPLE_2,
      MMCC_ROW_EXAMPLE_3,
      MMCC_ROW_EXAMPLE_4,
      MMCC_ROW_EXAMPLE_5,
      MMCC_ROW_EXAMPLE_6,
      MMCC_ROW_EXAMPLE_7,
      MMCC_ROW_EXAMPLE_8,
      MMCC_ROW_EXAMPLE_9,
      MMCC_ROW_EXAMPLE_10
    ]
    : [MMCC_B2B_EXAMPLE_1, MMCC_B2B_EXAMPLE_2]

  const dataFields = [translatorInstructions, ...exampleData.map(example => Object.values(example))]
  return {
    headerFields: [...headerFields],
    dataFields
  }
}

const NUMBER_OF_USELESS_LINE = 2
const MAX_UPLOAD_ORDER_REQUEST = 10000

export const verifyFileExtensionAndTemplate = async (file, ccType, locale) => {
  const fileName = file?.name
  let results = { data: [], errors: [] }
  const errorMsg = []
  if (endsWith(fileName, '.csv')) {
    results = await CsvUtils.parse(file, UPLOAD_CSV_OPTION)
  }
  if (endsWith(fileName, '.xlsx') || endsWith(fileName, '.xls')) {
    results.data = await ExcelUtils.parseFile(file)
  }
  if (results.errors && results.errors.length !== 0) {
    errorMsg.push({ id: 'international_advanced_search_broken_file' })
  }
  if (results.data) {
    const headerFields = (results.data[0] || []).map(key => {
      if (MMCC_KEYS_TRANSLATOR_FROM_FRIENDLY_NAME_TO_CODE[locale]) {
        return MMCC_KEYS_TRANSLATOR_FROM_FRIENDLY_NAME_TO_CODE[locale][key]
      }
      return MMCC_KEYS_TRANSLATOR_FROM_FRIENDLY_NAME_TO_CODE['en'][key]
    })
    const isWrongTemplate = verifyTemplate(headerFields, ccType)
    if (isWrongTemplate) {
      errorMsg.push({ id: 'international_order_creation_wrong_template' })
    }

    if (results.data.length <= NUMBER_OF_USELESS_LINE) {
      errorMsg.push({ id: 'international_empty_order' })
    }

    if (results.data.length - NUMBER_OF_USELESS_LINE > MAX_UPLOAD_ORDER_REQUEST) {
      errorMsg.push({
        id: 'international_mmcc_maximum_request_row',
        values: { x: formatNumber(MAX_UPLOAD_ORDER_REQUEST) }
      })
    }
    results.data[0] = headerFields
  }
  return {
    fileData: results,
    errorMsg,
    fileName
  }
}

const verifyTemplate = (headerFields, ccType) => {
  const parcelHeaderKeys = Object.keys(isB2CBoxes(ccType) ? B2C_INSTRUCTION_DATA : B2B_INSTRUCTION_DATA)
  const isValidHeaderLength = headerFields.length === parcelHeaderKeys.length
  const isValidHeaderName = isValidHeader(headerFields, parcelHeaderKeys)
  return !isValidHeaderLength || !isValidHeaderName
}

const groupRows = (allOrdersByKey, groupField) => {
  return Object.keys(allOrdersByKey).reduce((results, indexKey) => {
    const fieldValue = allOrdersByKey[indexKey][groupField].value || indexKey
    results[fieldValue] = results[fieldValue] || []
    results[fieldValue].push(indexKey)
    return results
  }, {})
}

const truncateValue = (data, fieldsToTruncate) => {
  return data.map(item => {
    return Object.keys(item).reduce((acc, key) => {
      const trimmedValue = item[key].trim()
      acc[key] =
        fieldsToTruncate[key] && trimmedValue.length > fieldsToTruncate[key]
          ? trimmedValue.substring(0, fieldsToTruncate[key])
          : trimmedValue
      return acc
    }, {})
  })
}

const compareIndices = (data, keys: string[], boxId: string) => {
  keys.forEach((key) => {
    const values = Object.values(data).map(row => row[key]?.value);
    if (uniq(values).length !== 1) {
      Object.values(data).forEach(d => {
        d[key].errors.push({ key: 'mismatch_recipient_address', values: [{ x: boxId }] })
      })
    }
  })
  return data
}

const areAllRowsValid = (rows) => {
  return Object.values(rows).every(row =>
    Object.values(row).every(field => !field.errors.length)
  )
}

const processB2CRows = (allRowsByIndex: { [key: string]: RowValueObject }) => {
  const groupsByBoxId = groupRows(allRowsByIndex, MMCC_KEYS.BOX_ID);
  const validItemisedOrders = {},
    invalidItemisedOrders = {},
    invalidRows = {},
    validRows = {};
  let numberOfValidItemsInValidOrders = 0,
    numberOfValidItemsInInvalidOrders = 0,
    numberOfInvalidItemsInInvalidOrders = 0;

  Object.keys(groupsByBoxId).forEach(boxId => {
    let oneGroup = groupsByBoxId[boxId].reduce((prevRow, currentRowIdx) => {
      return {
        ...prevRow,
        [currentRowIdx]: allRowsByIndex[currentRowIdx]
      };
    }, {});

    let isValid = areAllRowsValid(oneGroup)
    if (isValid) {
      oneGroup = compareIndices(oneGroup, RECIPIENT_COMPARATION_KEYS, boxId)
      isValid = areAllRowsValid(oneGroup);
    }

    if (isValid) {
      validItemisedOrders[boxId] = oneGroup;
      numberOfValidItemsInValidOrders += Object.keys(oneGroup).length;
      Object.keys(oneGroup).forEach(rowIndex => {
        validRows[rowIndex] = oneGroup[rowIndex];
      })
    } else {
      Object.keys(oneGroup).forEach(rowIndex => {
        if (isValid) {
          numberOfValidItemsInInvalidOrders += 1;
          validRows[rowIndex] = oneGroup[rowIndex];
        } else {
          numberOfInvalidItemsInInvalidOrders += 1;
          invalidRows[rowIndex] = oneGroup[rowIndex];
        }
      });
      invalidItemisedOrders[boxId] = oneGroup;
    }
  });

  return {
    validRows,
    invalidRows,
    validItemisedOrders,
    invalidItemisedOrders,
    numberOfValidItemsInValidOrders,
    numberOfValidItemsInInvalidOrders,
    numberOfInvalidItemsInInvalidOrders
  }
}

const mapRowData = (rows, constantKeys) => {
  return rows.slice(NUMBER_OF_USELESS_LINE).map(row => {
    const rowData: Partial<MappedDataKeys> = {}
    constantKeys.forEach((columnKey: string, index: number) => {
      rowData[columnKey] = row[index] || ''
    })
    return rowData
  })
}

const truncateMappedData = (mappedData: any[]) => {
  return truncateValue(mappedData, COLUMN_TRUNCATION_MAP)
}

const validateRequiredField = (
  columnValue: any,
  isRequired: boolean,
  columnKey: string,
  ccType: CCType,
  fieldErrors: { key: string, values?: [] }[]
) => {
  const isEmptyValue = !columnValue && columnValue !== 0
  if (isRequired && isEmptyValue) {
    if (isB2CBoxes(ccType) || (isB2BBoxes(ccType) && columnKey !== MMCC_KEYS.BOX_ID)) {
      fieldErrors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.REQUIRED])
    }
  }
}

const applyValidationRules = (
  rules: any[],
  columnValue: any,
  columnKey: string,
  country: string,
  fieldErrors: { key: string, values?: [] }[]
) => {
  rules.map(rule => {
    const { type, validator } = rule
    switch (type) {
      case RULE_TYPES.MIN_LENGTH:
        if (columnValue?.length <= validator) {
          fieldErrors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
      case RULE_TYPES.MAX_LENGTH:
        if (columnValue?.length >= validator) {
          fieldErrors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
      case RULE_TYPES.EQUAL_MAX_LENGTH:
        if (columnValue?.length > validator) {
          fieldErrors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
      case RULE_TYPES.REGEX:
        if (columnKey === MMCC_KEYS.RECIPIENT_POSTCODE && validator[country]) {
          const countrySpecificValidator = validator[country]
          if (!countrySpecificValidator?.test(columnValue)) {
            fieldErrors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
          }
        } else if (!validator.test(columnValue)) {
          fieldErrors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
      case RULE_TYPES.EQUAL_MIN:
        if (columnValue === '' || toNumber(columnValue) <= validator) {
          fieldErrors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
      case RULE_TYPES.MAX:
        if (columnValue === '' || toNumber(columnValue) >= validator) {
          fieldErrors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
    }
  })
}

const validateRow = (row: any, country: string, ccType: CCType): RowValueObject => {
  const rowValueObject: RowValueObject = {}
  Object.keys(row).forEach(columnKey => {
    const { required, requiredByCountry, rules } = MMCC_VALIDATION_RULES[columnKey]
    const columnValue = row[columnKey]
    const fieldErrors: { key: string, values?: [] }[] = []

    const isRequired = required || (requiredByCountry && requiredByCountry.includes(country))

    validateRequiredField(columnValue, isRequired, columnKey, ccType, fieldErrors)
    if (isRequired || columnValue) {
      applyValidationRules(rules, columnValue, columnKey, country, fieldErrors)
    }

    rowValueObject[columnKey] = {
      value: columnValue,
      errors: uniqBy(fieldErrors, error => error.key)
    }
  })

  return rowValueObject
}

export const validateUploadedData = ({ rows, ccType, country }: ValidationProps) => {
  const constantKeys = isB2CBoxes(ccType) ? B2C_FIELD_KEYS : B2B_FIELD_KEYS
  const mappedData = mapRowData(rows, constantKeys)
  const mmccOrderMapped = truncateMappedData(mappedData)

  const allRowsByKey: { [key: string]: RowValueObject } = {},
    validRows: { [key: string]: RowValueObject } = {},
    invalidRows: { [key: string]: RowValueObject } = {}

  mmccOrderMapped.forEach((row, rowIndex) => {
    const rowValueObject = validateRow(row, country, ccType)

    const firstRowError = Object.values(rowValueObject).find(rowField => rowField.errors.length > 0)
    if (firstRowError) {
      invalidRows[rowIndex + NUMBER_OF_USELESS_LINE] = rowValueObject
    } else {
      validRows[rowIndex + NUMBER_OF_USELESS_LINE] = rowValueObject
    }
    allRowsByKey[rowIndex + NUMBER_OF_USELESS_LINE] = rowValueObject
  })

  let result = {
    allOrders: allRowsByKey,
    validRows: validRows,
    invalidRows: invalidRows,
    invalidItemisedOrders: invalidRows,
    validItemisedOrders: validRows,
    numberOfValidItemsInValidOrders: null,
    numberOfValidItemsInInvalidOrders: null,
    numberOfInvalidItemsInInvalidOrders: null
  }

  if (isB2CBoxes(ccType)) {
    const {
      validRows,
      invalidRows,
      validItemisedOrders,
      invalidItemisedOrders,
      numberOfValidItemsInValidOrders,
      numberOfValidItemsInInvalidOrders,
      numberOfInvalidItemsInInvalidOrders
    } = processB2CRows(allRowsByKey)
    result = {
      ...result,
      validRows,
      invalidRows,
      invalidItemisedOrders,
      validItemisedOrders,
      numberOfValidItemsInValidOrders,
      numberOfValidItemsInInvalidOrders,
      numberOfInvalidItemsInInvalidOrders
    }
  }

  return result
}

export const makeFriendlyError2DArray = (data, intl) => {
  return map(data, row =>
    map(row, item => {
      const formattedErrors = map(item.errors, error => {
        const values = (error.values || []).reduce((err, current) => {
          return { ...err, ...current };
        }, {});
        return intl.formatMessage({ id: error.key }, values)
      })
      return {
        value: item.value,
        errors: formattedErrors
      }
    })
  )
}

export const buildBoxesForB2COrder = (orders: UploadedBoxOrder, currency: string, country: string): B2CBoxDetail[] => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Object.values(orders).map((box, index) => {
    const parcelGroupsByTID = groupBy(box, item => item[MMCC_KEYS.PARCEL_TRACKING_ID]?.value)
    const parcelList = Object.keys(parcelGroupsByTID).map(tid => {
      const items = parcelGroupsByTID[tid].map(parcel => {
        return {
          quantity: toNumber(parcel[MMCC_KEYS.ITEM_QUANTITY].value),
          description: parcel[MMCC_KEYS.ITEM_DESCRIPTION].value,
          value: toNumber(parcel[MMCC_KEYS.ITEM_VALUE].value)
        }
      })
      return {
        tracking_id: tid,
        to: {
          name: parcelGroupsByTID[tid][0][MMCC_KEYS.TO_NAME].value,
          address: parcelGroupsByTID[tid][0][MMCC_KEYS.TO_ADDRESS].value,
          contact_number: parcelGroupsByTID[tid][0][MMCC_KEYS.TO_CONTACT_NUMBER].value
        },
        items
      }
    })
    const firstBox = Object.values(parcelGroupsByTID)[0]
    const dimensions: Dimension = {}
    if (firstBox[0][MMCC_KEYS.BOX_LENGTH].value) {
      dimensions.length = toNumber(firstBox[0][MMCC_KEYS.BOX_LENGTH].value)
    }
    if (firstBox[0][MMCC_KEYS.BOX_WIDTH].value) {
      dimensions.width = toNumber(firstBox[0][MMCC_KEYS.BOX_WIDTH].value)
    }
    if (firstBox[0][MMCC_KEYS.BOX_HEIGHT].value) {
      dimensions.height = toNumber(firstBox[0][MMCC_KEYS.BOX_HEIGHT].value)
    }
    return {
      batch_source_no: index + 1,
      ...(firstBox[0][MMCC_KEYS.BOX_WEIGHT].value && {
        weight: toNumber(firstBox[0][MMCC_KEYS.BOX_WEIGHT].value)
      }),
      ...(!isEmpty(dimensions) && {
        dimensions
      }),
      goods_description: firstBox[0][MMCC_KEYS.GOODS_DESCRIPTION].value,
      quantity: toNumber(firstBox[0][MMCC_KEYS.NO_OF_PARCELS].value),
      customs_currency: currency?.toUpperCase(),
      source_order_id: firstBox[0][MMCC_KEYS.BOX_ID].value,
      parcels: parcelList,
      to: {
        name: firstBox[0][MMCC_KEYS.RECIPIENT_NAME]?.value,
        address_line1: firstBox[0][MMCC_KEYS.RECIPIENT_ADDRESS]?.value,
        country_code: country,
        contact_number: firstBox[0][MMCC_KEYS.RECIPIENT_PHONE_NUMBER]?.value,
        ...(firstBox[0][MMCC_KEYS.RECIPIENT_CITY]?.value && {
          city: firstBox[0][MMCC_KEYS.RECIPIENT_CITY].value
        }),
        ...(firstBox[0][MMCC_KEYS.RECIPIENT_POSTCODE]?.value && {
          post_code: firstBox[0][MMCC_KEYS.RECIPIENT_POSTCODE].value
        })
      }
    }
  })
}

export const buildBoxesForB2BOrder = (
  orders: UploadedBoxOrder,
  currency: string,
  toCountryCode: string
): B2BBoxDetail[] => {
  return Object.values(orders).map(box => {
    const dimensions: Dimension = {}
    if (box[MMCC_KEYS.BOX_LENGTH]?.value) {
      dimensions.length = toNumber(box[MMCC_KEYS.BOX_LENGTH].value)
    }
    if (box[MMCC_KEYS.BOX_WIDTH]?.value) {
      dimensions.width = toNumber(box[MMCC_KEYS.BOX_WIDTH].value)
    }
    if (box[MMCC_KEYS.BOX_HEIGHT]?.value) {
      dimensions.height = toNumber(box[MMCC_KEYS.BOX_HEIGHT].value)
    }
    return {
      parcel_details: {
        customs_currency: currency.toUpperCase(),
        ...(!isEmpty(dimensions) && {
          dimensions
        }),
        value: toNumber(box[MMCC_KEYS.GOODS_VALUE]?.value),
        ...(box[MMCC_KEYS.BOX_WEIGHT]?.value && {
          weight: toNumber(box[MMCC_KEYS.BOX_WEIGHT].value)
        }),
        customs_description: box[MMCC_KEYS.GOODS_DESCRIPTION]?.value,
        quantity: toNumber(box[MMCC_KEYS.QUANTITY]?.value)
      },
      ...(box[MMCC_KEYS.BOX_ID]?.value && {
        source_order_id: box[MMCC_KEYS.BOX_ID].value
      }),
      to: {
        name: box[MMCC_KEYS.RECIPIENT_NAME]?.value,
        address_line1: box[MMCC_KEYS.RECIPIENT_ADDRESS]?.value,
        contact_number: box[MMCC_KEYS.RECIPIENT_PHONE_NUMBER]?.value,
        country_code: toCountryCode,
        ...(box[MMCC_KEYS.RECIPIENT_CITY]?.value && {
          city: box[MMCC_KEYS.RECIPIENT_CITY].value
        }),
        ...(box[MMCC_KEYS.RECIPIENT_POSTCODE]?.value && {
          post_code: box[MMCC_KEYS.RECIPIENT_POSTCODE].value
        })
      }
    }
  })
}

export const aggregateBoxFromUploadFile = (boxOrders, ccType) => {
  if (!boxOrders) {
    return {
      totalBox: 0,
      totalGoodsValue: 0
    }
  }
  let totalGoodsValue = 0
  if (isB2CBoxes(ccType)) {
    boxOrders.forEach(box => {
      let totalInABox = 0
      box.parcels.forEach(parcel => {
        totalInABox += parcel.items.reduce((res, obj) => res + obj.quantity * obj.value, 0)
      })
      totalGoodsValue += totalInABox
    })
  } else {
    totalGoodsValue += boxOrders.reduce((res, obj) => res + obj.parcel_details.value, 0)
  }
  return {
    totalBox: boxOrders.length,
    totalGoodsValue: totalGoodsValue
  }
}

export const transferServiceDetailInfo = ({
  senderAddress,
  pickupType,
  pickupAddress,
  timeSlot,
  ocMethod,
  selectedService,
  commercialFiles,
  deliveryFiles
}) => {
  const { origin_country, destination_country, code } = selectedService
  const from = {
    name: senderAddress.name,
    address_line1: senderAddress.address1,
    post_code: senderAddress.postcode,
    country_code: origin_country,
    contact_number: senderAddress.contact,
    ...(senderAddress.city && {
      city: senderAddress.city
    }),
    ...(senderAddress?.email && {
      contact_email: senderAddress.email
    }),
    ...(senderAddress?.address2 && {
      address_line2: senderAddress.address2
    })
  }

  let pickup = null
  const slot = DELIVERY_TIME_PARAMS[+timeSlot?.timeWindow]
  if (pickupType === PICKUP_TYPES.SCHEDULED) {
    pickup = {
      pickup_date: timeSlot.pickupDate,
      pickup_instructions: timeSlot.comments,
      pickup_timeslot: {
        start_time: slot && slot.split('-')[0],
        end_time: slot && slot.split('-')[1]
      },
      pickup_address: {
        name: pickupAddress.name,
        address_line1: pickupAddress.address1,
        post_code: pickupAddress.postcode,
        country_code: origin_country,
        contact_number: pickupAddress.contact,
        ...(pickupAddress.city && {
          city: pickupAddress.city
        }),
        city: pickupAddress.city,
        ...(pickupAddress?.email && {
          contact_email: pickupAddress.email
        }),
        ...(pickupAddress?.address2 && {
          address_line2: pickupAddress.address2
        })
      }
    }
  }

  let documents = null
  if (commercialFiles.length) {
    documents = {
      ...documents,
      commercial_invoices: commercialFiles.map(com => ({
        uri: com.data.file_uri,
        name: com.fileName
      }))
    }
  }
  if (deliveryFiles.length) {
    documents = {
      ...documents,
      delivery_orders: deliveryFiles.map(com => ({
        uri: com.data.file_uri,
        name: com.fileName
      }))
    }
  }

  return {
    source: ocMethod,
    service_code: code,
    from,
    ...(pickup && {
      pickup
    }),
    ...(documents && {
      documents
    })
  }
}

export const buildB2CBoxRequests = ({
  boxOrders,
  senderAddress,
  pickupType,
  pickupAddress,
  timeSlot,
  ocMethod,
  selectedService,
  commercialFiles = [],
  deliveryFiles = []
}) => {
  const { service_code, from, pickup, documents } = transferServiceDetailInfo({
    senderAddress,
    pickupType,
    pickupAddress,
    timeSlot,
    ocMethod,
    selectedService,
    commercialFiles,
    deliveryFiles
  })

  return {
    source: ocMethod,
    service_code,
    from,
    ...(pickup && {
      pickup
    }),
    boxes: boxOrders,
    ...(documents && {
      documents
    })
  }
}

export const buildBoxesForB2BOrders = (orders, currency) => {
  return orders.map(order => {
    const dimensions: Dimension = {}
    if (order.length) {
      dimensions.length = toNumber(order.length)
    }
    if (order.width) {
      dimensions.width = toNumber(order.width)
    }
    if (order.height) {
      dimensions.height = toNumber(order.height)
    }
    return {
      parcel_details: {
        customs_currency: currency.toUpperCase(),
        ...(!isEmpty(dimensions) && {
          dimensions
        }),
        value: toNumber(order.goodsValue),
        ...(order.weight && {
          weight: toNumber(order.weight)
        }),
        customs_description: order.goodsDescription,
        quantity: toNumber(order.quantity)
      },
      ...(order.boxId && {
        source_order_id: order.boxId
      })
    }
  })
}

export const aggregateBoxFromUploadFileFromKeyboard = boxOrders => {
  if (!boxOrders) {
    return {
      totalBox: 0,
      totalGoodsValue: 0
    }
  }
  const totalGoodsValue = boxOrders.reduce((total, order) => total + order.parcel_details.value, 0)
  return {
    totalBox: boxOrders.length,
    totalGoodsValue: totalGoodsValue
  }
}

export const getFormRules = intl => {
  return {
    boxId: [
      {
        min: 1,
        message: intl.formatMessage({ id: 'international_invalid_shipper_box_id' })
      },
      {
        max: 255,
        message: intl.formatMessage({ id: 'international_invalid_shipper_box_id' })
      }
    ],
    goodsDescription: [
      {
        required: true,
        message: intl.formatMessage({ id: 'international_goods_description_is_required' })
      },
      {
        min: 1,
        message: intl.formatMessage({ id: 'international_goods_description_is_invalid' })
      },
      {
        max: 255,
        message: intl.formatMessage({ id: 'international_goods_description_is_invalid' })
      }
    ],
    quantity: [
      {
        required: true,
        message: intl.formatMessage({ id: 'international_no_of_parcels_is_required' })
      },
      {
        pattern: FLOAT_NUMBER_AND_GREATER_THAN_0,
        message: intl.formatMessage({ id: 'international_no_of_parcels_is_invalid' })
      }
    ],
    goodsValue: [
      {
        required: true,
        message: intl.formatMessage({ id: 'international_goods_value_is_required' })
      },
      {
        pattern: FLOAT_NUMBER_AND_GREATER_THAN_0,
        message: intl.formatMessage({ id: 'international_goods_value_is_invalid' })
      }
    ],
    length: [
      {
        pattern: INTEGER_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000,
        message: intl.formatMessage({ id: 'international_invalid_box_length' })
      }
    ],
    width: [
      {
        pattern: INTEGER_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000,
        message: intl.formatMessage({ id: 'international_invalid_box_width' })
      }
    ],
    height: [
      {
        pattern: INTEGER_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000,
        message: intl.formatMessage({ id: 'international_invalid_box_height' })
      }
    ],
    weight: [
      {
        pattern: FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000,
        message: intl.formatMessage({ id: 'international_box_weight_is_invalid' })
      }
    ]
  }
}

export const buildB2BBoxRequests = ({
  boxOrders,
  senderAddress,
  pickupType,
  pickupAddress,
  timeSlot,
  ocMethod,
  selectedService,
  commercialFiles = [],
  deliveryFiles = []
}) => {
  const { service_code, from, pickup, documents } = transferServiceDetailInfo({
    senderAddress,
    pickupType,
    pickupAddress,
    timeSlot,
    ocMethod,
    selectedService,
    commercialFiles,
    deliveryFiles
  })

  const requests = boxOrders.map(d => {
    return {
      source: ocMethod,
      service_code,
      from,
      ...(pickup && {
        pickup
      }),
      parcel_details: d.parcel_details,
      to: d.to,
      ...(d.source_order_id && {
        source_order_id: d.source_order_id
      })
    }
  })
  return {
    order_requests: requests,
    ...(documents && { documents })
  }
}

export const checkValidSupportedFile = (file, fileIndex) => {
  const errors = []
  if (fileIndex > 1) {
    errors.push('max_file_upload')
  }
  const fileSizeInByMB = file.size / 1024 / 1024
  if (fileSizeInByMB > 50) {
    errors.push('upload_exceed_file_size')
  }
  const isPdf = endsWith(file.name, '.pdf')
  const isCsv = endsWith(file.name, '.csv')
  const isXls = endsWith(file.name, '.xls')
  const isXlsx = endsWith(file.name, '.xlsx')
  const isJpg = endsWith(file.name, '.jpg')
  const isPng = endsWith(file.name, '.png')
  if (!isPdf && !isCsv && !isXls && !isXlsx && !isJpg && !isPng) {
    errors.push('unsupported_file_upload')
  }
  return errors
}

export const calculateFileInfo = data => {
  const totalSizeMB = round(data.reduce((sum, file) => sum + file.fileSize, 0) / 1024 / 1024, 2)
  const tatalSizeKB = round(data.reduce((sum, file) => sum + file.fileSize, 0) / 1024, 2)
  return {
    size: totalSizeMB > 0.01 ? totalSizeMB : tatalSizeKB,
    totalFiles: data.length,
    unit: totalSizeMB > 0.01 ? 'MB' : 'KB'
  }
}

export const getMMCCKeyTabs = isItemised => ({
  VALID_ORDER: {
    LABEL: isItemised ? 'international_valid_items' : 'international_valid_boxes',
    KEY: 'VALID_ORDERS'
  },
  INVALID_ORDER: {
    LABEL: isItemised ? 'international_invalid_items' : 'international_invalid_boxex',
    KEY: 'INVALID_ORDER'
  },
  ALL_ORDERS: {
    LABEL: isItemised ? 'international_total_items' : 'international_total_boxes',
    KEY: 'ALL_ORDERS'
  }
})

export const isB2CBoxes = ccType => ccType === CCType.B2C

export const isB2BBoxes = ccType => ccType === CCType.B2B
