/**
 *
 * HeaderLogo
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { Link } from 'react-router-dom'
import { ROUTES } from 'containers/Base/constants'
import { media, Metrics } from 'themes'

const StyledLogoLink = styled(({ collapsed, ...rest }) => <Link {...rest} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    width: auto;
  }
  ${media.min.tablet`min-width: ${props => (props.collapsed ? 0 : Metrics.siderWidth)}px;`};
`
const LogoLink = StyledLogoLink
const LogoDiv = StyledLogoLink.withComponent('div')

class HeaderLogo extends React.PureComponent {
  render () {
    const {
      collapsed,
      onClick,
      to,
      theme: { HeaderLogo: ConcreateHeaderLogo }
    } = this.props
    const props = {
      children: <ConcreateHeaderLogo collapsed={collapsed} />,
      'data-analyticsid': 'dashboard',
      collapsed
    }
    return onClick ? <LogoDiv onClick={onClick} {...props} /> : <LogoLink to={to} {...props} />
  }
}

HeaderLogo.propTypes = {
  children: PropTypes.node,
  collapsed: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
  theme: PropTypes.object
}

HeaderLogo.defaultProps = {
  to: ROUTES.HOME
}

const _HeaderLogo = withTheme(HeaderLogo)
export { _HeaderLogo as HeaderLogo }
