import { range } from 'lodash'
import React, { useContext, useMemo } from 'react'
import {
  getAddressTranslationKeyMap,
  getDeliveryAddressDivisionFieldName,
  getPickupAddressDivisionFieldName
} from 'utils/address'
import { NVSelect, Spin, T } from '@nv/react-commons/src/Components'
import { DivisionProps } from './AddressDivisions.interface'
import { ADDRESS_LEVEL_NAMES } from 'constants/address'
import { AddressDivisionsContext } from './AddressDivisions.context'

const { Option } = NVSelect

export const Division = ({ level, ...props }: DivisionProps) => {
  const {
    source,
    form: { getFieldDecorator, setFieldsValue },
    country,
    divisions,
    getDivisions,
    clearDivisions,
    selectedDivisionsInitialValue,
    getPreviousSelectedLevels,
    FormItemWrapper,
    intl
  } = useContext(AddressDivisionsContext)

  const options = useMemo(() => {
    const divisionsList = divisions[`l${level}`]
    const divisionsListToDisplay = divisionsList?.data?.data.map(
      division => division[`${ADDRESS_LEVEL_NAMES[level]}Display`]
    )
    return Array.from(new Set(divisionsListToDisplay))
  }, [divisions, level])

  const initialValue = selectedDivisionsInitialValue[ADDRESS_LEVEL_NAMES[level]]

  return (
    <FormItemWrapper label={<T id={getAddressTranslationKeyMap(country)[level]} prefix='types.zone' />} {...props}>
      {getFieldDecorator(
        source === 'pickup'
          ? getPickupAddressDivisionFieldName(level)
          : getDeliveryAddressDivisionFieldName({ country, level }),
        {
          initialValue: initialValue,
          rules: [
            {
              required: props.allowEmpty ? !!initialValue : true,
              message: intl.formatMessage({
                id: 'types.zone.' + getAddressTranslationKeyMap(country)[level] + '.required'
              })
            }
          ]
        }
      )(
        <NVSelect
          disabled={level !== 1 && !getPreviousSelectedLevels(level)[ADDRESS_LEVEL_NAMES[level - 1]]}
          onDropdownVisibleChange={() => {
            if (!divisions[`l${level}`]?.data) {
              getDivisions({
                level,
                country,
                source,
                ...getPreviousSelectedLevels(level)
              })
            }
          }}
          onSelect={() => {
            // clear next levels data and form fields values
            range(level + 1, 4).forEach((nextLevel: keyof typeof ADDRESS_LEVEL_NAMES) => {
              setFieldsValue({
                [source === 'pickup'
                  ? getPickupAddressDivisionFieldName(nextLevel)
                  : getDeliveryAddressDivisionFieldName({ country, level: nextLevel })]: ''
              })
            })
            clearDivisions(level + 1, source)
          }}
          notFoundContent={divisions[`l${level}`]?.loading ? <Spin size='small' /> : null}
          showSearch
          data-testid={`division-${level}`}
        >
          {options.map(option => (
            <Option data-testid={`division-${level}-option`} key={option} value={option}>
              {option}
            </Option>
          ))}
        </NVSelect>
      )}
    </FormItemWrapper>
  )
}
