import React from 'react'
import styled from 'styled-components'
import RctDropzone from 'react-dropzone'
import { Vspace } from 'components/Vspace'
import { Colors } from 'themes'
import CryptoJS from 'crypto-js'
import { checkValidSupportedFile } from './utils'
import { uploadFilesToStorage, uploadInvoice } from 'services/api/fplApi'
import { message } from 'antd'
import { useIntl } from 'hooks/common'

const activeClassName = 'dropzone-active'
const hoverStyle = `
   border: dashed 2px ${Colors.secondary};
 `
const StyledDropzone = styled(({ ...rest }) => <RctDropzone {...rest} />).attrs({
  activeClassName
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ height, unitHeight }) => `${height || 80}${unitHeight || 'px'}`};
  background-color: ${props => props.backgroundColor || Colors.aliceBlue};
  border: dashed 1.5px ${props => props.borderColor || Colors.paleGrey};
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer !important')};
  transition: all 450ms cubic - bezier(0.23, 1, 0.32, 1);
  ${props => props.dragging && hoverStyle};

  &.${activeClassName} {
    background-color: rgba(247, 247, 247, 1);
    ${hoverStyle};
  }
`

interface DocumentDropZoneProps {
  hint: JSX.Element
  height: number
  multiple: boolean
  disabled: boolean
  errors: string[]
  names: string[]
  noneBorder?: boolean
  onDrop: (uploadedResult: any[], errors: string[], fileNames: string[]) => void
  onLoading: (loading: boolean) => void
}

export const DocumentDropZone = ({
  hint,
  onDrop,
  height,
  multiple,
  disabled,
  errors,
  names,
  noneBorder,
  onLoading
}: DocumentDropZoneProps) => {
  const intl = useIntl()
  const makeRequest = async (data, file) => {
    const res = await uploadInvoice({
      name: data.name,
      type: data.type,
      size: data.size,
      content_md5: data.md5
    })

    if (res.problem === 'NETWORK_ERROR') {
      return null
    }

    if (res?.ok) {
      await uploadFilesToStorage(res.data.data.upload_url, file.slice(), res.data.data.upload_headers)
      return {
        data: res.data.data,
        fileName: data.name,
        fileSize: data.size
      }
    }
    return null
  }

  const handleDrop = acceptedFiles => {
    onLoading(true)
    let fileErrors = []
    const fileNames = []

    acceptedFiles.forEach((file, index: number) => {
      fileErrors = [...fileErrors, ...checkValidSupportedFile(file, index)]
      fileNames.push(file.name)
    })

    if (!fileErrors.length) {
      const uploadPromises = acceptedFiles.map(file => {
        return new Promise(resolve => {
          const reader = new FileReader()
          reader.onloadend = () => {
            const binaryStr = reader.result
            const hash = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(binaryStr))
            const md5 = hash.toString(CryptoJS.enc.Base64)

            const blobToBase64 = blob => {
              return new Promise(blobResolve => {
                const b64reader = new FileReader()
                b64reader.onloadend = () => {
                  blobResolve(b64reader.result)
                }
                b64reader.readAsDataURL(blob)
              })
            }

            blobToBase64(file.slice(0, 261)).then(() => {
              resolve(
                makeRequest(
                  {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    md5: md5
                  },
                  file
                )
              )
            })
          }
          reader.readAsBinaryString(file)
        })
      })

      Promise.all(uploadPromises)
        .then(results => {
          const filteredResults = results.filter(result => result !== null)
          const uploadErrors = !filteredResults.length ? [...fileErrors, 'failed_by_network_issue'] : fileErrors
          onDrop(filteredResults, uploadErrors, fileNames)
          onLoading(false)
        })
        .catch(() => {
          onDrop([], ['failed_by_network_issue'], fileNames)
          onLoading(false)
        })
        .finally(() => {
          onLoading(false)
        })
    } else {
      onDrop([], fileErrors, fileNames)
      onLoading(false)
    }
  }

  let borderColor = Colors.paleGrey
  if (errors.length && names.length) {
    borderColor = Colors.negativeBalance
  }
  if (!errors.length && names.length) {
    borderColor = Colors.mediumGreen
  }
  if (noneBorder) {
    borderColor = 'white'
  }

  return (
    <StyledDropzone
      multiple={multiple || false}
      onDrop={handleDrop}
      height={height}
      borderColor={borderColor}
      disabled={disabled}
      backgroundColor={noneBorder}
    >
      {hint}
      <Vspace height={4} />
    </StyledDropzone>
  )
}
