import { ApiResponse } from 'apisauce'
import { mapValues } from 'lodash'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { createApi } from 'services/utils'
import { BusinessHoursResponse, CaseCreationResponse } from './types'

const sfiApi = createApi({
  service: 'sfi'
})

const sfiApiNoTransform = createApi({
  service: 'sfi',
  transform: false
})

const sfiApiCountry = mapValues(COUNTRIES, country => createApi({ country, service: 'sfi' }))

const submitDelayedDeliveryHelpdeskForm = (data, country) =>
  sfiApiCountry[country.toUpperCase()]?.post(`1.0/dd-cases`, data)

const generateUploadUrl = data => sfiApi.post('/1.0/files/generate-upload-url', data)

const submitHelpdeskForm = payload => {
  return sfiApiNoTransform.post<CaseCreationResponse>('1.0/salesforce-proxy/dash-ss-case', payload)
}

const getBusinessHours = ({ country }: { country: string }): Promise<ApiResponse<BusinessHoursResponse>> => {
  return sfiApiCountry[country.toUpperCase()]?.get(`/1.0/business-hours`)
}

export default {
  generateUploadUrl,
  submitHelpdeskForm,
  getBusinessHours,
  submitDelayedDeliveryHelpdeskForm
}
