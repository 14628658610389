import { message } from '@nv/react-commons/src/Components'
import { ApiHelper, NvApi } from '@nv/react-commons/src/Services'
import { SelectorUtils } from '@nv/react-commons/src/Utils'
import { intl } from 'containers/IntlGlobalProvider'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import dashApi from 'services/api/dashApi'
import systemConfig from '../configs/systemConfig'
import { selectAuthClientName } from '../containers/Base/selectors'
import { useMemoSelector, useMonitor, usePrevious } from './common'
import aaaApi from 'services/api/aaaApi'
import { mixpanelTrackSignUpProDetails } from '../components/Mixpanel/helpers'
import { DASH_ERRORS } from 'constants/errors'
import { trackEvent } from '../utils/GoogleAnalytics/GAEventTracker'
import { INVALID_CONTACT_US, PRO_REGISTER } from '../utils/GoogleAnalytics/events'
import countryUtils from '@nv/react-commons/src/Utils/CountryUtils'

const { PASSWORD_COUNTRIES } = systemConfig
const { selector } = SelectorUtils

export const useLiteProSwitcher = (initialIsLite, form) => {
  const [isLite, setLite] = useState(initialIsLite)
  const selectLite = e => {
    setLite(e.target.value)
    form.resetFields()
  }
  const shouldIncludePasswordForLite = () => {
    if (isLite) {
      return _.includes(PASSWORD_COUNTRIES, form.getFieldValue('country'))
    }
  }
  const handleCountryChange = value => {
    if (!isLite) {
      form.resetFields()
      form.setFieldsValue({ country: value, countryCode: value })
    }
  }
  const country = form.getFieldValue('country')

  return { country, handleCountryChange, shouldIncludePasswordForLite, selectLite, isLite }
}

export const useSignUpLite = ({ getFieldValue, setFields, shouldIncludePasswordForLite }) => {
  const signUpError = useMemoSelector(selector('global', 'user', 'error'))
  const signUpLoading = useMemoSelector(selector('global', 'user', 'loading'))
  const sendOtpLoading = useMemoSelector(selector('entity', 'sendOtp', 'loading'))
  const sendOtpError = useMemoSelector(selector('global', 'sendOtp', 'error'))

  const prevSignUpLoading = usePrevious(signUpLoading)
  const prevSendOtpLoading = usePrevious(sendOtpLoading)

  useEffect(() => {
    const handleError = error => {
      const usernameKey = shouldIncludePasswordForLite() ? 'email' : 'number'
      if (error.code === 150006) {
        setFields({
          [usernameKey]: {
            errors: [new Error(intl.formatMessage({ id: `types.error.${usernameKey}_exist` }))],
            value: getFieldValue(usernameKey)
          }
        })
      }
      if (error.code === DASH_ERRORS.WRONG_CREDENTIALS) {
        setFields({
          email: {
            errors: [new Error(intl.formatMessage({ id: 'types.error.wrong_username_or_password' }))],
            value: getFieldValue('email')
          }
        })
      }
      if (error.code === DASH_ERRORS.FORBIDDEN && error.message.includes('mobile shipper trying to login to web api')) {
        message.error(
          intl.formatMessage({
            id: 'types.error.forbidden_cross_platform_login'
          })
        )
      }
      if (error.code === DASH_ERRORS.INVALID_INPUT) {
        message.error(intl.formatMessage({ id: 'types.error.invalid_input' }), 5)
      }
    }
    if (!signUpLoading && prevSignUpLoading && signUpError) {
      handleError(signUpError)
    } else if (!sendOtpLoading && prevSendOtpLoading && sendOtpError) {
      handleError(sendOtpError)
    }
  }, [
    getFieldValue,
    prevSendOtpLoading,
    prevSignUpLoading,
    sendOtpError,
    sendOtpLoading,
    setFields,
    shouldIncludePasswordForLite,
    signUpError,
    signUpLoading
  ])
  return signUpError || sendOtpError
}

export const useSignUpPro = ({ doOnSuccess }) => {
  const signUpProError = useMemoSelector(selector('entity', 'signUpPro', 'error'))
  const signUpProLoading = useMemoSelector(selector('entity', 'signUpPro', 'loading'))
  const signUpProResponse = useMemoSelector(selector('entity', 'signUpPro', 'data'))
  const prevLoading = usePrevious(signUpProLoading)

  useEffect(() => {
    if (!signUpProLoading && prevLoading && signUpProError) {
      trackEvent(INVALID_CONTACT_US)
      message.error(
        intl.formatMessage({
          id: signUpProError.code === DASH_ERRORS.INVALID_INPUT ? 'types.error.invalid_input' : 'error'
        }),
        5
      )
    } else if (!signUpProLoading && prevLoading) {
      doOnSuccess()
      mixpanelTrackSignUpProDetails(signUpProResponse)
      trackEvent({
        action: PRO_REGISTER.action + countryUtils.getCountryNames(signUpProResponse?.country),
        label: PRO_REGISTER.label
      })
    }
  }, [doOnSuccess, prevLoading, signUpProError, signUpProLoading])
  return signUpProError
}

export const useOtpLogin = ({ onSuccess, purpose }) => {
  // have to set phoneNumber to state because sign up form dismounts when OTP form mounts
  const [phoneNumber, setPhoneNumber] = useState('')
  const [otpSent, setOtpSent] = useState(false)
  const verifyOtpCodeLoading = useMemoSelector(selector('entity', 'verifyOtpCode', 'loading'))
  const sendOtpLoading = useMemoSelector(selector('entity', 'sendOtp', 'loading'))
  const dispatch = useDispatch()

  useMonitor('sendOtp', {
    customErrors: { 160001: 'invalid_phone_number', 150011: 'wait_before_resending' },
    successMessage: 'otp_sent',
    onSuccess: () => setOtpSent(true)
  })

  useMonitor('verifyOtpCode', {
    customErrors: { 150003: 'invalid_otp_code' },
    onSuccess: otpAuthToken => {
      NvApi.setAuthToken(otpAuthToken)
      onSuccess()
    },
    successIndicator: 'authToken'
  })

  const sendOtpRequest = useCallback(
    p => dispatch(ApiHelper.creators.request('sendOtp', dashApi.sendOtp, [purpose, p])),
    [dispatch, purpose]
  )
  const verifyOtpCodeRequest = useCallback(
    (p, code) =>
      dispatch(ApiHelper.creators.request('verifyOtpCode', dashApi.verifyOtpCode, [{ phoneNumber: p, code }])),
    [dispatch]
  )
  return {
    phoneNumber,
    setPhoneNumber,
    otpSent,
    sendOtpRequest,
    verifyOtpCodeRequest,
    verifyOtpCodeLoading,
    sendOtpLoading
  }
}

export const useGetAuthClientName = clientId => {
  const authClientName = useMemoSelector(selectAuthClientName)
  const dispatch = useDispatch()

  useEffect(() => {
    const getClientName = async () => {
      try {
        const oAuth2ClientName = await aaaApi.getAuthClientName(clientId)
        if (oAuth2ClientName.ok) {
          dispatch(ApiHelper.creators.set('oAuth2ClientName', oAuth2ClientName.data))
        } else {
          message.error(intl.formatMessage({ id: 'error_auth_client_name' }), 10)
        }
      } catch {
        message.error(intl.formatMessage({ id: 'error_auth_client_name' }), 10)
      }
    }

    if (!authClientName && clientId) {
      getClientName()
    }
  }, [clientId, dispatch, authClientName])

  return authClientName
}
