import _ from 'lodash'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { createApi } from 'services/utils'

const driverApis = _.mapValues(COUNTRIES, country => createApi({ country, service: 'driver' }))

const getFailureReason = (country, id) => driverApis[country].get(`1.0/failure-reasons/${id}`)
const getFailureReasons = (country, params) => driverApis[country].get('1.0/failure-reasons', params)

export default {
  axiosInstance: driverApis.axiosInstance,
  getFailureReason,
  getFailureReasons
}
