import React from 'react'
import PropTypes from 'prop-types'

const ErrorDescription = ({ content }) => {
  return <>{content || <span>Please contact your customer success agent or account manager.</span>}</>
}

ErrorDescription.propTypes = {
  content: PropTypes.node
}

export { ErrorDescription }
