import { CDAuthBannerLogo, NVAuthBannerLogo } from 'components/AuthForm/SystemSpecific'
import { CDIcon } from 'components/CDIcon'
import { NVIcon } from 'components/NVIcon'
import { CDGoodJobLogo, NVGoodJobLogo } from 'components/OCSuccess/SystemSpecific'
import Config from 'configs'
import { Images } from 'images'
import React from 'react'
import Colors from './Colors'

function getTheme (env) {
  switch (env) {
    case 'cd':
      return {
        primaryColor: Colors.cerulean,
        layoutSiderBackground: Colors.shipGrey,
        layoutHeaderBackground: Colors.shipGrey,
        HeaderLogo: props => <CDIcon {...props} height={40} />,
        AuthBannerLogo: CDAuthBannerLogo,
        OCSuccessGoodJobLogo: CDGoodJobLogo,
        clientErrorImg: Images.cd4XX,
        notFoundImg: Images.cdNotFound,
        ryoMagnifyingGlassImg: Images.ryoMagnifyingGlass,
        switchAccImg: Images.cdSwitchAccount,
        mascotImg: Images.cdTruck
      }
    default:
      return {
        primaryColor: Colors.nvPriRed,
        layoutSiderBackground: Colors.menu,
        layoutHeaderBackground: Colors.menu,
        HeaderLogo: props => <NVIcon {...props} height={30} />,
        AuthBannerLogo: NVAuthBannerLogo,
        OCSuccessGoodJobLogo: NVGoodJobLogo,
        clientErrorImg: Images.ryo4XX,
        notFoundImg: Images.ryoNotFound,
        ryoMagnifyingGlassImg: Images.ryoMagnifyingGlass,
        switchAccImg: Images.ryoWorking,
        mascotImg: Images.runningNinja,
        mascotInternational: Images.ryoInternational
      }
  }
}

const env = Config.ENVIRONMENT

export default getTheme(env)
