import { useEffect } from 'react'

const useBeforeUnload = handler => {
  useEffect(() => {
    const handlerFn = () => {
      if (handler && typeof handler === 'function') {
        handler()
      }
    }
    window.addEventListener('beforeunload', handlerFn)

    return () => {
      window.removeEventListener('beforeunload', handlerFn)
    }
  }, [handler])
}

export default useBeforeUnload
