export const RESHIP_RETURN_OPTIONS_ID = {
  SELF_PICKUP: 'return_parcels.drawer_return_to_me_self_pickup',
  MY_ADDRESS_DESTINATION_COUNTRY: 'return_parcels.drawer_return_to_me_my_address_destination_country',
  MY_ADDRESS_ORIGIN_COUNTRY: 'return_parcels.drawer_return_to_me_my_address_origin_country'
}

export const RESHIP_RETURN_OPTIONS = {
  SELF_PICKUP: 'SELF_PICKUP',
  MY_ADDRESS_DESTINATION_COUNTRY: 'MY_ADDRESS_DESTINATION_COUNTRY',
  MY_ADDRESS_ORIGIN_COUNTRY: 'MY_ADDRESS_ORIGIN_COUNTRY'
}

export const DRAWER_PAGE = {
  FIRST: 1,
  SECOND: 2
}

export const FILE_TYPE = {
  CSV: 'csv',
  EXCEL: 'xlx'
}

export const HEIGHT_DROPZONE = {
  XLG: 100,
  LG: 70,
  MD: 65,
  SM: 60,
  XSM: 55
}
