import apisauce from 'apisauce'
import { DataUtils } from '@nv/react-commons/src/Utils'

import Config from 'configs'
import { getSafeUrl } from 'services/utils'

const pudoApi = apisauce.create({
  baseURL: getSafeUrl(Config.BASE_URL),
  timeout: 10000
})

pudoApi.addResponseTransform(response => {
  response.data = DataUtils.toCamelCase(response.data)
})

const getPudos = (country, location) => {
  let locationParam = ''
  if (location) {
    locationParam = `&longitude=${location.lng}&latitude=${location.lat}`
  }
  return pudoApi.get(`${country ? country.toLowerCase() : 'sg'}/dp/2.0/dps?allow_shipper_send=true${locationParam}`)
}

export default {
  axiosInstance: pudoApi.axiosInstance,
  getPudos
}
