/**
 *
 * Dropzone
 *
 */

import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import RctDropzone from 'react-dropzone'
import { Icon, message, Row } from '@nv/react-commons/src/Components'
import { Text } from '../Text'
import { Vspace } from '../Vspace'
import { Colors } from 'themes'
import { faTrash } from '@fa-pro-solid/faTrash'
import { injectIntl, intlShape } from 'react-intl'
import { compose } from 'redux'

const activeClassName = 'dropzone-active'
const hoverStyle = `
  border: dashed 2px ${Colors.secondary};
`
const StyledDropzone = styled(({ dragging, ...rest }) => <RctDropzone {...rest} />).attrs({
  activeClassName
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${props => props.height || 80}px;
  background-color: ${Colors.aliceBlue};
  border: dashed 2px ${Colors.paleGrey};
  border-radius: 4px;
  cursor: pointer;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  ${props => props.dragging && hoverStyle};

  &.${activeClassName} {
    background-color: rgba(247, 247, 247, 1);
    ${hoverStyle};
  }
`
const StyledButton = styled.div`
  z-index: 10;
`
const StyledIcon = styled(Icon)`
  margin-left: 16px;
  margin-right: 0;
`
const StyledText = styled(Text)`
  text-decoration: underline;
`
const validFileTypes = [
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
]

class Dropzone extends React.PureComponent {
  checkValidFile = file => {
    const filename = file.name
    const checkFileExtension =
      _.endsWith(filename, '.xlsx') || _.endsWith(filename, '.csv') || _.endsWith(filename, '.xls')
    return _.includes(validFileTypes, file.type) || checkFileExtension
  }

  handleDrop = files => {
    const { intl, onDrop } = this.props
    const file = files[0]
    if (this.checkValidFile(file)) {
      onDrop(file)
    } else {
      message.error(intl.formatMessage({ id: 'wrong_file_type' }))
    }
  }

  handleClear = e => {
    e.stopPropagation()
    this.props.onClear()
  }

  render () {
    const { file, hint, prompt, ...rest } = this.props
    const name = file?.name
    return (
      <StyledDropzone {..._.omit(rest, ['intl', 'onClear'])} multiple={false} onDrop={this.handleDrop}>
        {hint}
        <Vspace height={4} />
        {!name && (
          <>
            <Text id='drag_drop' size={15} color={Colors.pinkishGrey} />
            <StyledText id='browse_files' size={15} color={Colors.pinkishGrey} />
          </>
        )}
        {name && (
          <Row type='flex' justify='center' style={{ width: '100%' }}>
            <Text id='selected_file_x' values={{ x: name }} bold size={13} maxWidth={150} />
            <StyledButton onClick={this.handleClear}>
              <StyledIcon icon={faTrash} />
            </StyledButton>
          </Row>
        )}
        {prompt}
      </StyledDropzone>
    )
  }
}

Dropzone.propTypes = {
  file: PropTypes.object,
  hint: PropTypes.node,
  intl: intlShape,
  onClear: PropTypes.func,
  onDrop: PropTypes.func.isRequired,
  prompt: PropTypes.node
}

Dropzone.defaultProps = {}

const _Dropzone = compose(injectIntl)(Dropzone)
export default _Dropzone
export { _Dropzone as Dropzone }
