import { COUNTRIES } from '@nv/react-commons/src/Constants'
import * as Yup from 'yup'
import Config from 'configs'
import { ORDER } from 'containers/Base/constants'

export const ERROR_TOAST_DURATION = 5000

// countries that requested for optimisation changes on specific shipper webforms
export const WEBFORM_CHANGES_COUNTRIES: string[] = [COUNTRIES.ID]
export const INVOICE_DISPUTE_ENABLED_COUNTRIES: string[] = [COUNTRIES.ID]

export enum EditDeliveryInstructionsOption {
  yes = 'yes',
  no = 'no'
}

export const EditDeliveryInstructionsOptions = [
  {
    value: EditDeliveryInstructionsOption.yes,
    title: 'yes'
  },
  {
    value: EditDeliveryInstructionsOption.no,
    title: 'no'
  }
]

export enum ISSUE_NAMES {
  DAMAGED = 'Damaged',
  DD_BULK = 'DD-Bulk',
  DELIVERY = 'Delivery',
  FINANCE = 'Finance',
  INVOICE_DISPUTE = 'Invoice dispute',
  LOST = 'Lost',
  LOST_PARTIAL = 'Lost - Missing / Swapped Items',
  PAYMENTS = 'Payments',
  PICKUP = 'Pickup',
  PRINTING = 'Printing',
  REGISTRATION = 'Registration',
  SHIPPER_ACCOUNT_RELATED = 'Shipper account related'
}

export const ISSUES_TYPES_OPTIONS = {
  invoiceDispute: { value: ISSUE_NAMES.INVOICE_DISPUTE, text: 'shipper_helpdesk.form.invoicedispute' },
  damaged: { value: ISSUE_NAMES.DAMAGED, text: 'shipper_helpdesk.form.damaged' },
  delivery: { value: ISSUE_NAMES.DELIVERY, text: 'shipper_helpdesk.form.delivery' },
  finance: { value: ISSUE_NAMES.FINANCE, text: 'shipper_helpdesk.form.finance' },
  lost: { value: ISSUE_NAMES.LOST, text: 'shipper_helpdesk.form.lost' },
  lostPartial: { value: ISSUE_NAMES.LOST_PARTIAL, text: 'shipper_helpdesk.form.lostpartial' },
  payments: { value: ISSUE_NAMES.PAYMENTS, text: 'shipper_helpdesk.form.payments' },
  pickup: { value: ISSUE_NAMES.PICKUP, text: 'shipper_helpdesk.form.pickup' },
  printing: { value: ISSUE_NAMES.PRINTING, text: 'shipper_helpdesk.form.printing' },
  registration: { value: ISSUE_NAMES.REGISTRATION, text: 'shipper_helpdesk.form.registration' },
  shipperAccountRelated: {
    value: ISSUE_NAMES.SHIPPER_ACCOUNT_RELATED,
    text: 'shipper_helpdesk.form.shipperaccountrelated'
  },
  ddBulk: { value: ISSUE_NAMES.DD_BULK, text: 'shipper_helpdesk.form.ddbulk' }
} as const

const commonIssueTypes = [
  ISSUES_TYPES_OPTIONS.damaged,
  ISSUES_TYPES_OPTIONS.lost,
  ISSUES_TYPES_OPTIONS.delivery,
  ISSUES_TYPES_OPTIONS.finance,
  ISSUES_TYPES_OPTIONS.pickup,
  ISSUES_TYPES_OPTIONS.registration,
  ISSUES_TYPES_OPTIONS.shipperAccountRelated,
  ISSUES_TYPES_OPTIONS.ddBulk
] as const

export const ISSUES_TYPES_BY_COUNTRY = {
  [COUNTRIES.MY]: [...commonIssueTypes, ISSUES_TYPES_OPTIONS.invoiceDispute],
  [COUNTRIES.ID]: [
    ISSUES_TYPES_OPTIONS.delivery,
    ISSUES_TYPES_OPTIONS.ddBulk,
    ISSUES_TYPES_OPTIONS.pickup,
    ISSUES_TYPES_OPTIONS.damaged,
    ISSUES_TYPES_OPTIONS.lost,
    ISSUES_TYPES_OPTIONS.lostPartial,
    ISSUES_TYPES_OPTIONS.invoiceDispute,
    ISSUES_TYPES_OPTIONS.finance,
    ISSUES_TYPES_OPTIONS.shipperAccountRelated
  ],
  [COUNTRIES.PH]: [...commonIssueTypes, ISSUES_TYPES_OPTIONS.invoiceDispute],
  [COUNTRIES.SG]: commonIssueTypes,
  [COUNTRIES.VN]: [...commonIssueTypes, ISSUES_TYPES_OPTIONS.payments, ISSUES_TYPES_OPTIONS.printing],
  [COUNTRIES.TH]: [...commonIssueTypes, ISSUES_TYPES_OPTIONS.payments, ISSUES_TYPES_OPTIONS.printing]
} as const

// TID: Any combination of letters, numbers, and hyphens. Hyphens should only appear in between other characters
export const VALID_TID_PATTERN = /^(?!-)(?!.*-$)[a-zA-Z0-9-]{1,30}$/
export const VALID_BILLING_WEIGHT_PATTERN = /^(?!0*(\.0+)?$)(\d+|\d*\.\d{0,2})$/
export const MAX_SINGLE_FILE_SIZE = 5
export const MAX_VIDEO_FILE_SIZE = 25
export const MAX_INVOICE_DISPUTE_FILE_SIZE = 3
export const MAX_CUMULATIVE_FILE_SIZE = 25
export const SAMPLE_INVOICE_LINK = `${Config.WEBSITE_URL}/files/invoice-dispute/SampleInvoice.png`
export const BILLING_WEIGHT = 'Billing Weight'
export const SUPPORTED_FILE_TYPES = '.jpeg, .jpg, .png, .gif, .pdf, .mp4, .avi, .txt, .csv'
export const SUPPORTED_VIDEO_FILE_TYPES = '.gif, .mp4, .avi'

export const disputingReasonFromPricingServiceMap = {
  weight: 'Billing Weight',
  'fees/zone': 'Zone',
  'fees/rate': 'Rate Card',
  'fees/cod': 'COD Fee',
  'fees/promo': 'Pricing Promo',
  others: 'Others'
} as const

export const SUBJECT_TYPES = {
  DELIVERY_UNKNOWN: 'shipper_helpdesk.form.delivery.parcel_unknown',
  DELIVERY_PROBLEM: 'shipper_helpdesk.form.delivery.parcel_problem',
  PARCEL_MISSING: 'shipper_helpdesk.form.delivery.parcel_missing',
  DELIVERY_DELAYED: 'shipper_helpdesk.form.delivery.parcel_delayed',
  OTHER_ISSUES: 'shipper_helpdesk.form.delivery.other_issues'
}

export const DASH_DD_AUTOMATION = 'Dash DD Automation'
export const VALIDATION_ERR = 'VALIDATION_ERR'
export const DASH_WEB_FORM = 'Dash web form'
export const DASH_FORM_BYPASS = 'Dash Form Bypass'

export const VALIDATION_MESSAGE_ORDER_STATUS = {
  [ORDER.GRANULAR_STATUS.COMPLETED]: 'Parcel is Completed',
  [ORDER.GRANULAR_STATUS.CANCELLED]: 'Parcel is Cancelled',
  [ORDER.GRANULAR_STATUS.PENDING_PICKUP]: 'Parcel is Pending Pickup',
  [ORDER.GRANULAR_STATUS.RETURNED_TO_SENDER]: 'Parcel is Returned to Sender'
}
export const VALIDATION_MESSAGE_NOT_FOUND = 'Not found'

export const DELIVERY_SUBJECT_OPTIONS = Object.values(SUBJECT_TYPES).map(type => ({
  value: type,
  text: type
}))

export const DELIVERY_SUBJECT_OPTIONS_LABELS = {
  'shipper_helpdesk.form.delivery.parcel_unknown': "I don't know where my parcel is",
  'shipper_helpdesk.form.delivery.parcel_problem': "There's something wrong with my parcel",
  'shipper_helpdesk.form.delivery.parcel_missing': 'My parcel is missing',
  'shipper_helpdesk.form.delivery.parcel_delayed': 'I want to maximize delivery',
  'shipper_helpdesk.form.delivery.other_issues': 'Others'
}

export const STATUS_SUCCESSFUL = 'Successful'
export const STATUS_ERROR = 'Error'
export const DD_ERROR_PREFIX = 'DD Error: '
export const DD_ERROR_TID_NOT_FOUND = 'Tracking ID not found'
export const DD_ERROR_GENERAL = 'General'

export const DESCRIPTION_MAPPING = {
  familyReceivedPackage: 'Did a family member at your address receive your package for you?',
  shouldHaveReceived: 'What is the item you should have received?',
  haveReceived: 'What is the item you have received?'
}

const baseSchema = Yup.object({
  contactName: Yup.string()
    .trim()
    .required('shipper_helpdesk.form.error.required'),
  shipperEmail: Yup.string()
    .trim()
    .email('email_address_invalid')
    .required('shipper_helpdesk.form.error.required'),
  subject: Yup.string()
    .trim()
    .required('shipper_helpdesk.form.error.required'),
  description: Yup.string()
    .trim()
    .required('shipper_helpdesk.form.error.required')
})

export const INVOICE_DISPUTE_SCHEMA = baseSchema.shape({
  invoiceId: Yup.string()
    .trim()
    .required('shipper_helpdesk.form.error.required'),
  disputing: Yup.string().required('shipper_helpdesk.form.error.required'),
  trackingId: Yup.string()
    .when('disputing', {
      is: 'onetid',
      then: schema => schema.required('shipper_helpdesk.form.error.required')
    })
    .matches(VALID_TID_PATTERN, 'shipper_helpdesk.error.invalidtrackingid'),
  disputeReason: Yup.array()
    .when('disputing', {
      is: 'onetid',
      then: schema => schema.min(1, 'shipper_helpdesk.form.error.select_dispute_reason')
    })
    .default([]),
  billingWeight: Yup.string()
    .test('billingWeightRequired', 'shipper_helpdesk.form.error.required', function (value) {
      const conditionArray = this.parent.disputeReason || []

      if (conditionArray.includes(BILLING_WEIGHT)) {
        return value?.length > 0
      }

      return true
    })
    .matches(VALID_BILLING_WEIGHT_PATTERN, 'shipper_helpdesk.error.billingweight'),
  attachments: Yup.array().when('disputing', {
    is: 'morethanonetid',
    then: schema => schema.min(1, 'shipper_helpdesk.form.error.required')
  })
})

export const DAMAGED_SCHEMA = baseSchema.shape({
  trackingId: Yup.string()
    .trim()
    .required('shipper_helpdesk.form.error.required')
    .matches(VALID_TID_PATTERN, 'shipper_helpdesk.error.invalidtrackingid'),
  attachments: Yup.array().min(1, 'shipper_helpdesk.form.error.required')
})

export const GENERIC_FORM_SCHEMA = baseSchema.shape({
  trackingId: Yup.string().matches(VALID_TID_PATTERN, 'shipper_helpdesk.error.invalidtrackingid'),
  attachments: Yup.array().min(0)
})

export const DELIVERY_SCHEMA = GENERIC_FORM_SCHEMA.shape({
  subject: Yup.object({
    value: Yup.string().required('shipper_helpdesk.form.error.required')
  }),
  trackingId: Yup.string().when('subject.value', {
    is: SUBJECT_TYPES.DELIVERY_DELAYED,
    then: schema =>
      schema
        .trim()
        .required('shipper_helpdesk.form.error.required')
        .matches(VALID_TID_PATTERN, 'shipper_helpdesk.error.invalidtrackingid'),
    otherwise: schema => schema.optional().matches(VALID_TID_PATTERN, 'shipper_helpdesk.error.invalidtrackingid')
  }),
  description: Yup.string()
    .trim()
    .when('subject.value', {
      is: SUBJECT_TYPES.DELIVERY_DELAYED,
      then: schema => schema.optional(),
      otherwise: schema => schema.required('shipper_helpdesk.form.error.required')
    }),
  editDeliveryInstructions: Yup.string()
    .trim()
    .when(['subject.value', 'selectedEditDeliveryInstructionsOption'], {
      is: (subjectValue, selectedOption) =>
        subjectValue === SUBJECT_TYPES.DELIVERY_DELAYED && selectedOption === EditDeliveryInstructionsOption.yes,
      then: schema => schema.required('shipper_helpdesk.form.error.required'),
      otherwise: schema => schema.optional()
    })
})

export const LOST_SCHEMA = GENERIC_FORM_SCHEMA.shape({
  trackingId: Yup.string()
    .trim()
    .required('shipper_helpdesk.form.error.required')
    .matches(VALID_TID_PATTERN, 'shipper_helpdesk.error.invalidtrackingid'),
  additionalDescription: Yup.object().when('isWebformChangesEnabled', {
    is: true,
    then: () =>
      Yup.object().shape({
        familyReceivedPackage: Yup.string().required('shipper_helpdesk.form.error.required')
      }),
    otherwise: () =>
      Yup.object().shape({
        familyReceivedPackage: Yup.string().optional()
      })
  })
})

export const LOST_PARTIAL_SCHEMA = GENERIC_FORM_SCHEMA.shape({
  trackingId: Yup.string()
    .trim()
    .required('shipper_helpdesk.form.error.required')
    .matches(VALID_TID_PATTERN, 'shipper_helpdesk.error.invalidtrackingid'),
  attachments: Yup.array().min(1, 'shipper_helpdesk.form.error.required'),
  additionalDescription: Yup.object({
    shouldHaveReceived: Yup.string().required('shipper_helpdesk.form.error.required'),
    haveReceived: Yup.string().required('shipper_helpdesk.form.error.required')
  })
})

export const DD_BULK_SCHEMA = baseSchema.shape({
  attachments: Yup.array().min(1, 'shipper_helpdesk.form.error.required')
})

export const PICKUP_SCHEMA = baseSchema.shape({
  trackingId: Yup.string()
    .trim()
    .required('shipper_helpdesk.form.error.required')
    .matches(VALID_TID_PATTERN, 'shipper_helpdesk.error.invalidtrackingid')
})

export const VALIDATION_SCHEMAS = {
  [ISSUE_NAMES.INVOICE_DISPUTE]: INVOICE_DISPUTE_SCHEMA,
  [ISSUE_NAMES.DAMAGED]: DAMAGED_SCHEMA,
  [ISSUE_NAMES.LOST]: LOST_SCHEMA,
  [ISSUE_NAMES.LOST_PARTIAL]: LOST_PARTIAL_SCHEMA,
  [ISSUE_NAMES.DELIVERY]: GENERIC_FORM_SCHEMA,
  [ISSUE_NAMES.FINANCE]: GENERIC_FORM_SCHEMA,
  [ISSUE_NAMES.PAYMENTS]: GENERIC_FORM_SCHEMA,
  [ISSUE_NAMES.PRINTING]: GENERIC_FORM_SCHEMA,
  [ISSUE_NAMES.PICKUP]: PICKUP_SCHEMA,
  [ISSUE_NAMES.REGISTRATION]: GENERIC_FORM_SCHEMA,
  [ISSUE_NAMES.SHIPPER_ACCOUNT_RELATED]: GENERIC_FORM_SCHEMA,
  [ISSUE_NAMES.DD_BULK]: DD_BULK_SCHEMA
} as const
