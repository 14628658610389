import { OptionItem } from '@nv/react-akira'

export enum SIGNUP_METHOD {
  EMAIL = 'email',
  PHONE = 'phone'
}

export const SIGNUP_METHOD_OPTIONS: OptionItem<SIGNUP_METHOD>[] = [
  { value: SIGNUP_METHOD.EMAIL, text: 'email_address' },
  { value: SIGNUP_METHOD.PHONE, text: 'phone_number' }
]

export const SIGN_UP_DATA_LOCAL_STORAGE_KEY = 'signUpData'
export const SIGN_UP_METHOD_LOCAL_STORAGE_KEY = 'signUpMethod'
export const FROM_LOGIN_QUERY_PARAM = 'fromLogin'
export const COUNTRY_QUERY_PARAM = 'country'
