export const validateMinLength = (trackingSettings, intl) => (rule, value, callback) => {
  // skip min length validation for optional fields when there is not value
  if (value?.length < trackingSettings.min) {
    callback(intl.formatMessage({ id: 'requested_tracking_min' }, { x: trackingSettings.min }))
  } else {
    callback()
  }
}

export const validateMaxLength = (trackingSettings, intl, isNinjaPackOrder, isCorpAWBOrder) => (
  rule,
  value,
  callback
) => {
  // skip max length validation for optional fields when there is not value
  const isNotRequiredAndEmptyValue = !trackingSettings.required && !value
  if (isNinjaPackOrder || isCorpAWBOrder || isNotRequiredAndEmptyValue) {
    callback()
  } else if (value?.length > trackingSettings.max) {
    callback(intl.formatMessage({ id: 'requested_tracking_id_max' }, { x: trackingSettings.max }))
  } else {
    callback()
  }
}
