/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import { addLocaleData } from 'react-intl'
import enLocaleData from 'react-intl/locale-data/en'
import idLocaleData from 'react-intl/locale-data/id'
import myLocaleData from 'react-intl/locale-data/ms'
import viLocaleData from 'react-intl/locale-data/vi'
import thLocaleData from 'react-intl/locale-data/th'
import zhLocaleData from 'react-intl/locale-data/zh'
import frLocaleData from 'react-intl/locale-data/fr'
import myBurmeseLocaleData from 'react-intl/locale-data/my'

import enTranslationMessages from './translations/en.json'

addLocaleData(enLocaleData)
addLocaleData(idLocaleData)
addLocaleData(myLocaleData)
addLocaleData(viLocaleData)
addLocaleData(thLocaleData)
addLocaleData(zhLocaleData)
addLocaleData(frLocaleData)
addLocaleData(myBurmeseLocaleData)

export const appLocales = ['en', 'vi', 'zh-CN', 'id', 'th', 'fr-MA', 'my']

export const translationMessages = new Proxy(
  {},
  {
    get: (self, k) => getMessages(k)
  }
)

export function getMessages (locale) {
  const regx = /^(\w{2})([-_](\w{2}))?$/
  const match = locale.match(regx)

  if (!match) {
    throw new Error('Unknown locale: ' + locale)
  }

  const lang = match[1]
  let country = match[3]
  if (country) {
    country = country.toUpperCase()
  }

  const fallbacks = []
  // Exact match
  try {
    fallbacks.push(require(`./translations/${lang}_${country}.json`))
  } catch (e) {}

  // Fallback to the same language
  // Language fallback should not contain any country specific value
  try {
    fallbacks.push(require(`./translations/${lang}.json`))
  } catch (e) {}

  // Fallback to apply country wide defaults
  // Any country level default should go here.
  // Note the language is EN, so best put none-language-specific stuff, e.g. url, email
  try {
    fallbacks.push(require(`./translations/en_${country}.json`))
  } catch (e) {}

  // Fallback to most general default
  if (lang !== 'en') {
    fallbacks.push(enTranslationMessages)
  }

  const messages = {}
  for (const fallback of fallbacks) {
    for (const key in fallback) {
      // Take the first none falsy match
      messages[key] = messages[key] || fallback[key]
    }
  }

  return messages
}
