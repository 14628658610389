/**
 *
 * Hspace
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledHspace = styled.div`
  width: ${props => props.width}px;
  min-width: ${props => props.width}px;
`

const Hspace = props => <StyledHspace {...props} />

Hspace.propTypes = {
  width: PropTypes.number.isRequired
}

Hspace.defaultProps = {}

export { Hspace }
