import { useEffect } from 'react'
import { GeneralEvents, PRODUCT_NAME } from './constants'
import { useLocation } from 'react-router-dom'
import { mpActions } from './utils'
import { useSelector } from 'react-redux'
import { selectShipper } from 'containers/Base/selectors'

export const useMixpanelGeneralPropertiesTracker = () => {
  const { pathname } = useLocation()
  const pageName = pathname?.slice(1)
  const isInternational = pathname?.includes('international')
  const shipper = useSelector(selectShipper())

  const { id: shipperId, legacyId: legacyShipperId } = shipper || {}

  useEffect(() => {
    mpActions.track(GeneralEvents.VISITED_PAGE.name)
    mpActions.register({
      [GeneralEvents.VISITED_PAGE.props.PRODUCT]: isInternational ? PRODUCT_NAME.XB_WEB : PRODUCT_NAME.DASH_WEB,
      [GeneralEvents.VISITED_PAGE.props.PAGE_NAME]: pageName,
      [GeneralEvents.VISITED_PAGE.props.PAGE_VARIANT]: '-',
      [GeneralEvents.VISITED_PAGE.props.TRAFFIC_SOURCE_URL]: window.location.href,
      [GeneralEvents.VISITED_PAGE.props.SHIPPER_ID]: shipperId,
      [GeneralEvents.VISITED_PAGE.props.LEGACY_SHIPPER_ID]: legacyShipperId
    })
    mpActions.people.set({
      [GeneralEvents.VISITED_PAGE.props.SHIPPER_ID]: shipperId,
      [GeneralEvents.VISITED_PAGE.props.LEGACY_SHIPPER_ID]: legacyShipperId
    })
  }, [isInternational, pageName, shipperId, legacyShipperId])
}
