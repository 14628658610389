import { ShareIcon } from '@nv/react-akira'
import { Text } from 'components/Text'
import styled from 'styled-components'

export const DraggerInfoText = styled(Text)`
  color: var(--akira-grey-2);
`

export const StyledShareIcon = styled(ShareIcon)`
  margin-bottom: 8px;
`

export const DraggerWrapper = styled.div`
  width: 100%;
  &&& {
    .ant-upload-drag-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 80px;
      width: 100%;
    }

    .ant-upload.ant-upload-drag {
      border: none;
      border-radius: 4px;
      background-color: var(--akira-grey-9);
    }

    br {
      margin: 0;
    }
  }
`

export const FileNameWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 4px;
  margin: 8px 0;
`

export const FileNameText = styled(Text)`
  color: inherit;
  font-size: 12px;
`
