/* eslint-disable max-len */
import { COUNTRIES } from '@nv/react-commons/src/Constants/Country'

export const PREPAID_TERMS_URL = {
  [COUNTRIES.SG]:
    'https://docs.google.com/document/d/e/2PACX-1vQ1zToeh_x760yhT7YJEZdYzO0R0xE8miZygs6Ry6Kdx--WVWziGDLJqIW6F46HU7txfRmVOpl4Myb_/pub?embedded=true',
  [COUNTRIES.MY]:
    'https://docs.google.com/document/d/e/2PACX-1vSzq2EoZWn49kf2jyOxS1OqD6AUtHgbBNan7yfQsgXGgswlSkPEmC8aiNH7Mj8jykW2flYtf18d2WeN/pub?embedded=true',
  [COUNTRIES.ID]:
    'https://docs.google.com/document/d/e/2PACX-1vThjUFLf0-Vd8Br_23zH-clHnS0gGrZhAdywimAkkxFaqO0Xn4ZumJ_RABNWV-ZJrQB0BhfAlzzp3Vd/pub?embedded=true',
  [COUNTRIES.TH]:
    'https://docs.google.com/document/d/e/2PACX-1vR3yFRe4PXvMywO47ae69OGROpOp9b1kn6K_nP9oQa4bZb2CrzhBH-3mqh4vAuzqCB-6P5mk8rPNkyp/pub?embedded=true',
  [COUNTRIES.VN]:
    'https://docs.google.com/document/d/e/2PACX-1vRV2-yXiZmEk7wAH1z_0CEO7oTSKoTBvlgWCe4cQ-jsZFYsvSu6Brt49hCDz-N6UsGSuP_I8K3ecih8/pub?embedded=true',
  [COUNTRIES.PH]:
    'https://docs.google.com/document/d/e/2PACX-1vRJ2msZPDHOHbnnianIx9PiJkrKFR3VGAcvCCZlDZp746e87YOkjb_tXnVF-kfwoajM-uTSbooK6EAT/pub?embedded=true',
  [COUNTRIES.MA]:
    'https://docs.google.com/document/d/e/2PACX-1vQU0dSvM4ju733ZMd6slWJAkY33Iav5qe7-7lzHRJJhg4-K_5OgId1yPLYvNNAwaqhBKvd7mcLbleJs/pub?embedded=true'
}
