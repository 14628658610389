import { GA_EVENT_NAME, POST_LOGIN_LABEL } from './events'

export const trackEvent = (variables, country) => {
  const { category = 'dash', action, label, isPostLogin } = variables
  const gaEvents = {
    event: GA_EVENT_NAME.INTERACTIONS,
    eventCategory: category,
    eventAction: isPostLogin && country ? `${POST_LOGIN_LABEL}_${country}` : action,
    eventLabel: label
  }

  if (window.dataLayer) {
    window.dataLayer.push(gaEvents)
  }
}
