import Button from 'components/Button'
import { Modal } from 'components/Modal'
import { Text } from 'components/Text'
import styled from 'styled-components'
import { Colors, Fonts } from 'themes'

const StyledModal = styled(Modal).attrs({
  width: 464
})`
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const StyledIcon = styled.img`
  height: 59px;
  width: 103px;
  margin-top: 8px;
  margin-bottom: 27px;
`

const StyledDescription = styled(Text).attrs({
  color: Colors.darkGrey
})`
  text-align: center;
`

const StyledTitle = styled(StyledDescription).attrs({
  size: 18
})`
  ${Fonts.style.bold};
`

const StyledButton = styled(Button).attrs({
  type: 'primary'
})`
  &&.ant-btn-primary {
    width: 304px;
    height: 35px;
    font-size: 14px;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 20px;
  }
`

export { StyledModal, StyledIcon, StyledTitle, StyledDescription, StyledButton }
