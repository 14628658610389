import _ from 'lodash'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { createApi } from 'services/utils'

const orderApis = _.mapValues(COUNTRIES, country =>
  createApi({
    country,
    service: 'order'
  })
)

const validateNinjaPackTrackingId = (country, trackingId) => {
  return orderApis[country].get(`/ninja-pack/tracking-id/${trackingId}/status`)
}

const validateCorporateManualAWBTrackingId = (country, trackingId) => {
  return orderApis[country].get(`/tracking-id/${trackingId}/corporate-awb`)
}

export default {
  axiosInstances: _.mapValues(orderApis, cli => cli.axiosInstance),
  validateNinjaPackTrackingId,
  validateCorporateManualAWBTrackingId
}
