import { DEFAULT_LOCALE } from 'containers/Base/constants'
import { selectIsAuthenticated, selectCountry } from 'containers/Base/selectors'
import { createSelector } from 'reselect'
import { getLocaleFromNavigator, getLocaleFromReferrer, getLanguagePrefix } from 'utils/locale'
import { appLocales } from 'i18n'

const selectLanguage = state => {
  return state.get('language')
}

const makeSelectLocale = () =>
  createSelector(
    selectLanguage,
    selectIsAuthenticated(),
    selectCountry(),
    (languageState, isAuthenticated, country) => {
      if (isAuthenticated) {
        const lang = getLanguagePrefix(languageState.get('locale'))
        if (country) {
          return `${lang}_${country}`
        } else {
          return lang
        }
      } else {
        const inferredLocale = getLocaleFromReferrer() || getLocaleFromNavigator() || DEFAULT_LOCALE
        return appLocales.map(getLanguagePrefix).includes(getLanguagePrefix(inferredLocale))
          ? inferredLocale
          : DEFAULT_LOCALE
      }
    }
  )

export { selectLanguage, makeSelectLocale }
