import jsonFeatureFlag from 'featureHub/jsonFeatureFlag'
import { useFlag } from 'featureHub/useFlag'
import { useIntl } from 'hooks/common'
import { useStorage } from './useStorage'

import moment from 'moment'
import { InjectedIntl } from 'react-intl'

export type MaintenanceDuration = {
  showScheduledMaintenanceWarning: boolean
  scheduleStart: string
  scheduleEnd: string
  displayStart: string
}

export const useMaintenanceWarning = () => {
  const maintenanceDurationFlag = useFlag(jsonFeatureFlag.MAINTENANCE_TIME)
  const intl = useIntl()
  const maintenanceString = getMaintenanceTimeString(intl, maintenanceDurationFlag)
  const [alertIsClosed, setAlertIsClosed] = useStorage('timedAlertIsClosed', false, 'session')
  const handleClose = () => {
    setAlertIsClosed(true)
  }

  return { maintenanceString, alertIsClosed, handleClose }
}

const getMaintenanceTimeString = (intl: InjectedIntl, maintenanceDuration?: MaintenanceDuration) => {
  if (!maintenanceDuration?.showScheduledMaintenanceWarning) {
    return null
  }

  const { scheduleStart, scheduleEnd, displayStart } = maintenanceDuration

  if (!scheduleStart || !scheduleEnd || !displayStart) {
    return null
  }

  const dateTimeDisplayStart = moment(displayStart)?.local()
  const dateTimeStart = moment(scheduleStart)?.local()
  const dateTimeEnd = moment(scheduleEnd)?.local()
  if (!dateTimeStart || !dateTimeEnd || !dateTimeDisplayStart) {
    return null
  }

  const now = moment()
  const isInMaintenanceTime = now.isAfter(dateTimeDisplayStart) && now.isBefore(dateTimeEnd)

  if (!isInMaintenanceTime) {
    return null
  }

  const dateFormat = 'DD MMMM'
  const timeFormat = 'HH:mm'
  const startDate = dateTimeStart.format(dateFormat)
  const startTime = dateTimeStart.format(timeFormat)
  const endDate = dateTimeEnd.format(dateFormat)
  const endTime = dateTimeEnd.format(timeFormat)

  if (dateTimeStart.isSame(dateTimeEnd, 'day')) {
    return intl.formatMessage({ id: 'maintenance_same_day' }, { date: startDate, startTime, endTime })
  } else {
    return intl.formatMessage({ id: 'maintenance_diff_days' }, { startDate, startTime, endDate, endTime })
  }
}
