/**
 *
 * ErrorBoundary
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { RewriteFrames } from '@sentry/integrations'
import { ErrorPage } from '../ErrorPage'
import { useSentryUser } from './ErrorBoundary.hooks'

const { ENVIRONMENT, SENTRY_DSN, RELEASE_VERSION } = __CONFIG__

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing(), new RewriteFrames()],
  /** https://docs.sentry.io/platforms/javascript/configuration/sampling/#sampling-transaction-events
   * If you’re not sure what rate to choose, start with a low value and gradually increase it as you learn
   * more about your traffic patterns and volume.
   */
  tracesSampleRate: 0.1,
  environment: ENVIRONMENT,
  release: RELEASE_VERSION
})

export const ErrorBoundary = ({ children }) => {
  useSentryUser()
  return <Sentry.ErrorBoundary fallback={<ErrorPage type={ErrorPage.BOUNDARY} />}>{children}</Sentry.ErrorBoundary>
}

ErrorBoundary.propTypes = {
  children: PropTypes.node
}

ErrorBoundary.defaultProps = {
  children: 'ErrorBoundary'
}
