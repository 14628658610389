/**
 *
 * Input
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { NVInput } from '@nv/react-commons/src/Components'

export const Input = ({ placeholder, type, ...props }) => {
  const InputEl = type === 'password' ? NVInput.Password : NVInput
  if (placeholder) {
    return (
      <FormattedMessage {...props} id={placeholder}>
        {message => <InputEl data-lokalise data-key={placeholder} placeholder={message} {...props} />}
      </FormattedMessage>
    )
  } else {
    return <InputEl {...props} />
  }
}

Input.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string
}
