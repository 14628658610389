import { Button } from '@nv/react-akira'
import { RouteModal } from 'components/RouteModal'
import { useNavigateWithState, useToast } from 'hooks'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Colors } from 'themes'
import { getModalPath } from 'utils/getModalPath'
import { T } from '@nv/react-commons/src/Components'
import { Text } from 'components/Text'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ROUTES } from 'containers/Base/constants'
import { Form, Formik } from 'formik'
import { SubmitButton } from 'components/SubmitButton'
import { TextInputField } from 'components/TextInputField'
import { dashApi } from 'services/api'
import { ApiHelper } from '@nv/react-commons/src/Services'
import { selectShipper } from 'containers/Base/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { intl } from 'containers/IntlGlobalProvider'
import {
  ACCOUNT_DETAILS_MODEL,
  ACCOUNT_DETAILS_SCHEMA,
  ADD_ACCOUNT_DETAILS_PATH
} from './AddAccountDetailsModal.constants'
import { ACCOUNT_UPDATE_SUCCESS_PATH } from 'containers/AccountUpdateSuccessModal/AccountUpdateSuccessModal.constants'

export const AddAccountDetailsModal = () => {
  const navigate = useNavigateWithState()
  const dispatch = useDispatch()
  const { showError } = useToast()
  const location = useLocation()
  const shipper = useSelector(selectShipper())

  const onCancel = async () => {
    navigate(-1)
  }

  const onSubmit = async values => {
    const { name, contact, email } = shipper
    const sanitizedValues = ACCOUNT_DETAILS_SCHEMA.cast(values)

    const payload = {
      name,
      contactNo: contact,
      email,
      storeName: name,
      ...sanitizedValues
    }

    const response = await dashApi.setup(payload)
    if (!response.ok) {
      const code = response?.data?.error?.code
      showError({ code, customErrors: { [code]: response?.data?.error?.message } })
      return
    }
    dispatch(ApiHelper.creators.request('metadata', dashApi.getMetadata))
    navigate(`${ROUTES.HOME}/${ACCOUNT_UPDATE_SUCCESS_PATH}`, { replace: true })
  }

  return (
    <StyledModal path={getModalPath(location, ADD_ACCOUNT_DETAILS_PATH)} title={<T id='add_account_details.title' />}>
      <Formik initialValues={ACCOUNT_DETAILS_MODEL} validationSchema={ACCOUNT_DETAILS_SCHEMA} onSubmit={onSubmit}>
        <FormWrapper>
          <StyledDescription>
            <T id='add_account_details.description' />
          </StyledDescription>

          <TextInputField
            name='taxIdentificationNumber'
            label={
              <span className='text-8'>
                <FormattedMessage id='setup_account.tax_identification_number' />
              </span>
            }
            helperText={
              <span className='text-8'>
                <FormattedMessage id='add_account_details.mandatory_for_registered_businesses' />
              </span>
            }
            maxLength={100}
          />
          <br />
          <TextInputField
            name='salesAndServiceTaxNumber'
            label={
              <span className='text-8'>
                <FormattedMessage id='setup_account.sst_number' />
              </span>
            }
            helperText={
              <span className='text-8'>
                <FormattedMessage id='add_account_details.mandatory_for_registered_businesses' />
              </span>
            }
            maxLength={100}
          />

          <p className='text-6 mt-6 mb-5'>{intl.formatMessage({ id: 'billing_address' })}</p>

          <TextInputField
            name='billingName'
            label={
              <span className='text-8'>
                <FormattedMessage id='name' />
              </span>
            }
            maxLength={255}
          />
          <TextInputField
            name='billingStreet'
            label={
              <span className='text-8'>
                <FormattedMessage id='street' />
              </span>
            }
            maxLength={255}
          />
          <TextInputField
            name='billingPostalCode'
            label={
              <span className='text-8'>
                <FormattedMessage id='postal_code' />
              </span>
            }
            maxLength={10}
          />
          <TextInputField
            name='billingCity'
            label={
              <span className='text-8'>
                <FormattedMessage id='city' />
              </span>
            }
            maxLength={255}
          />
          <TextInputField
            name='billingState'
            label={
              <span className='text-8'>
                <FormattedMessage id='state_province' />
              </span>
            }
            maxLength={255}
          />

          <StyledRow>
            <StyledButton variant='default' styleType='secondary' size='lg' loading={false} onClick={onCancel}>
              <FormattedMessage id='cancel' />
            </StyledButton>
            <StyledConfirmButton>
              <FormattedMessage id='submit' />
            </StyledConfirmButton>
          </StyledRow>
        </FormWrapper>
      </Formik>
    </StyledModal>
  )
}

const StyledModal = styled(RouteModal)`
  && {
    .ant-modal-content {
      border-radius: 10px;
      max-width: 420px;
      margin: auto;

      > div {
        background-color: unset;
      }
    }

    .ant-modal-title {
      border-radius: 10px;
    }

    .ant-upload-text {
      font-size: 14px !important;
    }

    .ant-upload-hint {
      font-size: 12px !important;
    }
  }
`

const StyledDescription = styled(Text).attrs({
  color: Colors.balanceTxtBold
})`
  text-align: left;
  white-space: pre-line;
  margin-bottom: 20px;
`

const StyledButton = styled(Button)`
  width: 100px;
  align-self: flex-end;
  margin-right: 10px !important;
`

const StyledRow = styled.div`
  display: flex;
  justify-content: flex-end;
`
const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: var(--gutter);
`

const StyledConfirmButton = styled(SubmitButton)`
  width: 100px;
  align-self: flex-end;
  margin-top: 30px !important;
`
