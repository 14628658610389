import { CountryUtils } from '@nv/react-commons/src/Utils'

/**
 *
 * SubCountryDropdown
 *
 */
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { Col, NVSelect, T } from '@nv/react-commons/src/Components'
import { COUNTRIES } from '@nv/react-commons/src/Constants'

const { Option } = NVSelect

const zoneFilters = {
  l1Name: () => true,
  l2Name: (zone, value) => zone.l1Name === value.l1Name,
  l3Name: (zone, value) => zone.l2Name === value.l2Name
}

const REGEX = /l([1-3]*)Name/

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

function getPrevLevel (level) {
  const pos = parseInt(level.match(REGEX)[1])
  return !_.isNaN(pos) && pos > 1 ? `l${pos - 1}Name` : null
}

function getNextLevels (level) {
  const pos = parseInt(level.match(REGEX)[1])
  const nextLevel = _.isNumber(pos) && pos < 3 ? `l${pos + 1}Name` : null
  if (nextLevel) {
    return [nextLevel, ...getNextLevels(nextLevel)]
  } else {
    return []
  }
}

class SubCountryDropdown extends React.Component {
  state = {
    value: this.props.value || {}
  }

  static zoneToFieldName = country => {
    const { region: l1Name, locality: l2Name, neighborhood: l3Name } = CountryUtils.getAddressInfo(country)
    return {
      l1Name,
      l2Name,
      l3Name
    }
  }

  static getTranslationMap = country => {
    switch (country && country.toLowerCase()) {
      case COUNTRIES.TH:
      case COUNTRIES.PH:
        return {
          l1Name: 'province'
        }
      case COUNTRIES.MY:
        return {
          l1Name: 'state'
        }
      case COUNTRIES.VN:
        return {
          l1Name: 'province',
          l2Name: 'district'
        }
      case COUNTRIES.ID:
        return {
          l1Name: 'province',
          l2Name: 'city',
          l3Name: 'subdistrict'
        }
      case COUNTRIES.MA:
        return {
          l2Name: 'city'
        }
      default:
        return {}
    }
  }

  static getZoneLevel = country => {
    switch (country && country.toLowerCase()) {
      case COUNTRIES.MY:
      case COUNTRIES.PH:
      case COUNTRIES.TH:
        return ['l1Name']
      case COUNTRIES.VN:
        return ['l1Name', 'l2Name']
      case COUNTRIES.ID:
        return ['l1Name', 'l2Name', 'l3Name']
      case COUNTRIES.MA:
        return ['l2Name']
      default:
        return []
    }
  }

  isDisabled = (country, level) => {
    if (country && country.toLowerCase() === COUNTRIES.MA) {
      return false
    }
    const { value } = this.state
    const prevLevel = getPrevLevel(level)
    return !!prevLevel && !value[prevLevel]
  }

  onChangeLevel = (level, data) => {
    const { zones, onChange, setFieldsValue } = this.props
    const country = zones?.[0]?.country
    const zone = _.find(zones, { [level]: data })
    const nextLevels = getNextLevels(level)
    const resetFields = nextLevels.reduce(
      (res, nextLevel) => ({ ...res, [SubCountryDropdown.zoneToFieldName(country)[nextLevel]]: null }),
      {}
    )
    this.setState(
      {
        value: { ..._.omit(this.state.value, nextLevels), [level]: data },
        location: { latitude: zone.latitude, longitude: zone.longitude }
      },
      () => setFieldsValue(resetFields)
    )
    if (_.isFunction(onChange)) {
      onChange({ latitude: zone.latitude, longitude: zone.longitude })
    }
  }

  isMA () {
    const country = this.props.zones?.[0]?.country
    return country && country.toLowerCase() === COUNTRIES.MA
  }

  render () {
    const { formItemContainer: Container, getFieldDecorator, zones, rules } = this.props
    const { value } = this.state
    const country = zones?.[0]?.country
    return SubCountryDropdown.getZoneLevel(country).map((level, index) => (
      <Col key={level} lg={8} span={24}>
        <Container label={<T id={SubCountryDropdown.getTranslationMap(country)[level]} prefix='types.zone' />}>
          {getFieldDecorator(SubCountryDropdown.zoneToFieldName(country)[level], { rules })(
            <NVSelect
              key={level}
              disabled={this.isDisabled(country, level)}
              onChange={data => this.onChangeLevel(level, data)}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              optionFilterProp='children'
              showSearch
              data-testid={`zone-level-${index + 1}`}
            >
              {_.uniqBy(
                _.filter(zones, z => this.isMA() || zoneFilters[level](z, value)),
                level
              )
                .sort((a, b) => collator.compare(a[level], b[level]))
                .map(zone => (
                  <Option key={zone[level]} value={zone[level]}>
                    {zone[level]}
                  </Option>
                ))}
            </NVSelect>
          )}
        </Container>
      </Col>
    ))
  }
}

SubCountryDropdown.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  zones: PropTypes.array,
  rules: PropTypes.array,
  setFieldsValue: PropTypes.func,
  formItemContainer: PropTypes.any,
  getFieldDecorator: PropTypes.func
}

SubCountryDropdown.defaultProps = {
  zones: [],
  rules: []
}

export { SubCountryDropdown }
