import React from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import hoistNonReactStatic from 'hoist-non-react-statics'

function withHoistStatin (CustomComponent, Component) {
  return hoistNonReactStatic(CustomComponent, Component)
}

export function withNavigate (Component) {
  return withHoistStatin(props => <Component {...props} navigate={useNavigate()} />, Component)
}

export function withLocation (Component) {
  return withHoistStatin(props => <Component {...props} location={useLocation()} />, Component)
}

export function withParams (Component) {
  return withHoistStatin(props => <Component {...props} params={useParams()} />, Component)
}
