import { COUNTRIES } from '@nv/react-commons/src/Constants'
import Config from 'configs'

export const LOYALTY_HOSTNAMES = {
  [COUNTRIES.SG]: Config.LOYALTY_HOSTNAME_SG,
  [COUNTRIES.MY]: Config.LOYALTY_HOSTNAME_MY,
  [COUNTRIES.PH]: Config.LOYALTY_HOSTNAME_PH,
  [COUNTRIES.VN]: Config.LOYALTY_HOSTNAME_VN,
  [COUNTRIES.ID]: Config.LOYALTY_HOSTNAME_ID,
  [COUNTRIES.TH]: Config.LOYALTY_HOSTNAME_TH
}
