import { T } from '@nv/react-commons/src/Components'
import React, { useState } from 'react'
import { useStorage } from 'hooks/useStorage'
import { Images } from 'images'
import { StyledModal, StyledIcon, StyledTitle, StyledDescription, StyledButton } from './styles'

export const FEATURE_TOUR_STORAGE_KEY = 'ninjachatShowFeatureTour'

export const NinjaChatWidgetFeatureTourModal = () => {
  const [showFeatureTour, setShowFeatureTour] = useStorage(FEATURE_TOUR_STORAGE_KEY, true)
  const [showModal, setShowModal] = useState(showFeatureTour)

  return (
    <StyledModal
      visible={showModal}
      onCancel={() => {
        setShowFeatureTour(false)
        setShowModal(false)
      }}
      centered
    >
      <StyledIcon src={Images.ninjachatFeatureTourChat} alt='NinjaChat' />
      <StyledTitle id='ninjachat.feature_tour_modal.title' />
      <StyledDescription id='ninjachat.feature_tour_modal.description' />
      <StyledButton
        onClick={() => {
          setShowFeatureTour(false)
          setShowModal(false)
        }}
      >
        <T id='ninjachat.feature_tour_modal.close' />
      </StyledButton>
    </StyledModal>
  )
}
