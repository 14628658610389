export enum COD_DATE_TYPES {
  CREATED_DATE = 'ORDER_CREATED',
  COMPLETED_DATE = 'ORDER_COMPLETED'
}

export enum REPORT_TYPE {
  codReconciliation = 'codReconciliation',
  deliveryFailure = 'deliveryFailure',
  deliveryFailureByCSV = 'deliveryFailureByCSV',
  deliveryStatus = 'deliveryStatus'
}

export enum REPORT_FILTER_TYPE {
  created = 'created',
  completedDate = 'completedDate',
  batch = 'batch',
  csv = 'csv'
}

export enum REPORT_FILTER_FIELD {
  batchId = 'batchId',
  serviceTypes = 'serviceTypes',
  parcelStatus = 'parcelStatus',
  orderCreatedDateRange = 'orderCreatedDate',
  orderCompletedDateRange = 'orderCompletedDate',
  file = 'file'
}

export enum REPORT_SCHEDULES_TYPE {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}
