import { INTERNAL_STATUS } from 'containers/FPLParcelDetail/constants'

export const BULK_LIMIT = 100

export const SEARCH_METHODS = {
  BULK_UPLOAD: 'BULK_UPLOAD',
  KEYBOARD_INPUT: 'KEYBOARD_INPUT'
}

export const SEARCH_METHODS_OPTIONS = {
  [SEARCH_METHODS.BULK_UPLOAD]: 'Upload file',
  [SEARCH_METHODS.KEYBOARD_INPUT]: 'Keyboard input'
}

export const MAXIMUM_FILE_SIZE = 2500000

export const UPLOAD_CSV_OPTION = { delimiter: ',', newline: '\r\n' }

export const ACCEPT_FILE_TYPES =
  // eslint-disable-next-line max-len
  '.csv, text/csv, application/vnd.ms-excel, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx'

export const MAXIMUM_TOTAL_ORDERS = 50000
export const DOWNLOAD_ORDER_FIELDS = {
  'Tracking ID': 'tracking_id',
  Type: 'type',
  'From Country': 'fromCountry',
  'Sender Name': 'from_name',
  'To Country': 'toCountry',
  'Recipient Name': 'to_name',
  'Recipient Contact': 'to_contact_number',
  'Cash on Delivery': 'cod',
  'Customs Description': 'metadata.customs_description',
  Status: 'status'
}
export const DATE_RANGE_TYPES = {
  TODAY: 'TODAY',
  SEVEN_DAYS: 'SEVEN_DAYS',
  THIRTY_DAYS: 'THIRTY_DAYS',
  OTHERS: 'OTHERS',
  DATE_RANGE: 'DATE_RANGE',
  OTHERS_YESTERDAY: 'OTHERS_YESTERDAY',
  OTHERS_THIS_WEEK: 'OTHERS_THIS_WEEK',
  OTHERS_LAST_WEEK: 'OTHERS_LAST_WEEK',
  OTHERS_THIS_MONTH: 'OTHERS_THIS_MONTH',
  OTHERS_LAST_MONTH: 'OTHERS_LAST_MONTH',
  OTHERS_LAST_SIX_MONTHS: 'OTHERS_LAST_SIX_MONTHS'
}

export const DATE_FORMAT = 'YYYY/MM/DD'

export const FILTER_STATUS = {
  ORDER_CREATED: 1,
  PENDING_PICKUP: 2,
  IN_TRANSIT: 3,
  COMPLETE: 4,
  PICKUP_FAIL: 5,
  DELIVERY_FAIL: 6,
  CANCELED: 7,
  ON_HOLD: 8,
  RTS_FULFILLMENT: 9
}
export const FILTER_STATUS_VALUES = Object.values(FILTER_STATUS)

export const FILTER_STATUS_LABEL = {
  [FILTER_STATUS.ORDER_CREATED]: 'types.event.create_order',
  [FILTER_STATUS.PENDING_PICKUP]: 'types.granular_status.pending_pickup',
  [FILTER_STATUS.IN_TRANSIT]: 'types.event.details.added_to_shipment',
  [FILTER_STATUS.COMPLETE]: 'types.granular_status.completed',
  [FILTER_STATUS.PICKUP_FAIL]: 'types.granular_status.pickup_fail',
  [FILTER_STATUS.DELIVERY_FAIL]: 'types.order.status.delivery_fail',
  [FILTER_STATUS.CANCELED]: 'types.order.status.cancelled',
  [FILTER_STATUS.ON_HOLD]: 'types.order.status.on_hold',
  [FILTER_STATUS.RTS_FULFILLMENT]: 'types.order.status.rts_fulfillment'
}

export const REQUEST_FILTER_STATUS = {
  [FILTER_STATUS.ORDER_CREATED]: [INTERNAL_STATUS.STAGING],
  [FILTER_STATUS.PENDING_PICKUP]: [INTERNAL_STATUS.PENDING_PICKUP],
  [FILTER_STATUS.IN_TRANSIT]: [
    INTERNAL_STATUS.VAN_EN_ROUTE_TO_PICKUP,
    INTERNAL_STATUS.EN_ROUTE_TO_SORTING_HUB,
    INTERNAL_STATUS.ARRIVED_AT_SORTING_HUB,
    INTERNAL_STATUS.ARRIVED_AT_ORIGIN_HUB,
    INTERNAL_STATUS.ON_VEHICLE_FOR_DELIVERY,
    INTERNAL_STATUS.ARRIVED_AT_DISTRIBUTION_POINT,
    INTERNAL_STATUS.PENDING_PICKUP_AT_DISTRIBUTION_POINT,
    INTERNAL_STATUS.EN_ROUTE_TO_ORIGIN_FACILITY,
    INTERNAL_STATUS.ARRIVED_AT_ORIGIN_FACILITY,
    INTERNAL_STATUS.PROCESSED_AT_ORIGIN_FACILITY,
    INTERNAL_STATUS.HANDED_OVER_TO_LINEHAUL,
    INTERNAL_STATUS.LINEHAUL_SCHEDULED,
    INTERNAL_STATUS.EXPORT_CLEARED,
    INTERNAL_STATUS.LINEHAUL_DEPARTED,
    INTERNAL_STATUS.LINEHAUL_ARRIVED,
    INTERNAL_STATUS.CUSTOMS_CLEARED,
    INTERNAL_STATUS.CUSTOMS_HELD,
    INTERNAL_STATUS.HANDED_OVER_TO_LAST_MILE,
    INTERNAL_STATUS.IMPORT_STARTED,
    INTERNAL_STATUS.EXPORT_STARTED,
    INTERNAL_STATUS.PARCEL_EXCEPTION,
    INTERNAL_STATUS.CUSTOMS_EXCEPTION,
    INTERNAL_STATUS.PARCEL_DISPOSED,
    INTERNAL_STATUS.PARCEL_LOST,
    INTERNAL_STATUS.PARCEL_DAMAGED,
    INTERNAL_STATUS.CUSTOMS_CONFISCATED
  ],
  [FILTER_STATUS.COMPLETE]: [
    INTERNAL_STATUS.COMPLETED,
    INTERNAL_STATUS.RETURNED_TO_SENDER,
    INTERNAL_STATUS.RTS_AT_ORIGIN_FACILITY,
    INTERNAL_STATUS.RTS_TRIGGERED
  ],
  [FILTER_STATUS.PICKUP_FAIL]: [INTERNAL_STATUS.PICKUP_FAIL],
  [FILTER_STATUS.DELIVERY_FAIL]: [INTERNAL_STATUS.PENDING_RESCHEDULE, INTERNAL_STATUS.FIRST_ATTEMPT_DELIVERY_FAIL],
  [FILTER_STATUS.CANCELED]: [INTERNAL_STATUS.CANCELLED],
  [FILTER_STATUS.ON_HOLD]: [INTERNAL_STATUS.ON_HOLD],
  [FILTER_STATUS.RTS_FULFILLMENT]: [
    INTERNAL_STATUS.RETURNED_TO_XB_WAREHOUSE,
    INTERNAL_STATUS.FULFILLMENT_REQUEST_SUBMITTED,
    INTERNAL_STATUS.FULFILLMENT_PACKED
  ]
}

export const FULFILLMENT_STATUS = [
  INTERNAL_STATUS.RETURNED_TO_XB_WAREHOUSE,
  INTERNAL_STATUS.FULFILLMENT_REQUEST_SUBMITTED,
  INTERNAL_STATUS.FULFILLMENT_PACKED,
  INTERNAL_STATUS.COMPLETED,
  INTERNAL_STATUS.RETURNED_TO_SENDER
]

export const FULFILLMENT_METADATA = {
  DISPOSING_REQUESTED: 'Disposing requested',
  RESHIPPING_REQUESTED: 'Reshipping requested',
  RELABELLING_REQUESTED: 'Relabelling requested',
  PACKAGE_DISPOSED: 'Package disposed',
  PACKAGE_RESHIPPED_SELF_PICKUP: 'Package reshipped, Self-Pickup',
  PACKAGE_RESHIPPED_RETURN_TO_ORIGIN_COUNTRY_WAREHOUSE: 'Package reshipped, Return to Origin Country Warehouse',
  PACKAGE_RELABELLED: 'Package relabelled'
}

export const FULFILLMENT_STATUS_LABEL = {
  [INTERNAL_STATUS.FULFILLMENT_REQUEST_SUBMITTED]: {
    [FULFILLMENT_METADATA.DISPOSING_REQUESTED]:
      'types.international_status.fulfillment_requested_for_disposing.with_type',
    [FULFILLMENT_METADATA.RESHIPPING_REQUESTED]:
      'types.international_status.fulfillment_requested_for_reshipping.with_type',
    [FULFILLMENT_METADATA.RELABELLING_REQUESTED]:
      'types.international_status.fulfillment_requested_for_relabelling.with_type'
  },
  [INTERNAL_STATUS.COMPLETED]: {
    [FULFILLMENT_METADATA.PACKAGE_RELABELLED]: 'types.international_status.parcel_successfully_relabelled.with_type'
  },
  [INTERNAL_STATUS.RETURNED_TO_SENDER]: {
    [FULFILLMENT_METADATA.PACKAGE_DISPOSED]: 'types.international_status.parcel_successfully_disposal.with_type',
    [FULFILLMENT_METADATA.PACKAGE_RESHIPPED_RETURN_TO_ORIGIN_COUNTRY_WAREHOUSE]:
      'types.international_status.parcel_successfully_returned_to_sender.with_type',
    [FULFILLMENT_METADATA.PACKAGE_RESHIPPED_SELF_PICKUP]:
      'types.international_status.parcel_successfully_collected_by_shipper.with_type'
  }
}
