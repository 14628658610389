require('antd/lib/alert/style')

import Alert from 'antd/lib/alert'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Colors } from 'themes'

const INFO = 'info'
const ERROR = 'error'
const WARNING = 'warning'
const SUCCESS = 'success'

const COLORS = {
  [ERROR]: {
    background: Colors.R100,
    border: Colors.R300,
    icon: Colors.R300,
    text: Colors.N600
  },
  [INFO]: {
    background: Colors.N050,
    border: Colors.N400,
    icon: Colors.N400,
    text: Colors.N600
  },
  [SUCCESS]: {
    background: Colors.G100,
    border: Colors.G300,
    icon: Colors.G300,
    text: Colors.N600
  },
  [WARNING]: {
    background: Colors.Y100,
    border: Colors.Y300,
    icon: Colors.Y300,
    text: Colors.N600
  }
}

const getColor = (type, attr) => COLORS[type || [INFO]][attr]

const StyledBanner = styled(Alert)`
  && {
    border: 1px solid ${props => getColor(props.type, 'border')};
    background-color: ${props => getColor(props.type, 'background')};
    color: ${props => getColor(props.type, 'text')};
    ${props =>
      props.banner &&
      `
      border-radius: 0;
      border-top-width: 0.125rem;
    `};
    ${props =>
      !props.banner &&
      `
      border-radius: 0.313rem;
    `};
    border-style: solid;
    .ant-alert {
      line-height: 1.2;
    }
    .ant-alert-message {
      font-size: 0.875rem;
    }

    i.ant-alert-icon {
      font-size: 1rem;
      height: 1rem;
      color: ${props => getColor(props.type, 'icon')};
    }
    .ant-alert-close-icon {
      font-size: 0.875rem;
      stroke: ${Colors.N600};
      stroke-width: 30;
    }
    .anticon {
      left: 0.75rem;
    }
    .ant-alert-message ::selection {
      background: ${props => getColor(props.type, 'border')};
    }
  }
`

/**
 * #### Import
 * `import Banner from '@nv/react-commons/src/Components/Banner'`
 *
 * #### Usage
 * `<Banner />`
 */

export const WheelsBanner = props => {
  return <StyledBanner {...props} />
}

WheelsBanner.propTypes = {
  banner: PropTypes.bool,
  closable: PropTypes.bool,
  icon: PropTypes.node,
  message: PropTypes.oneOf([PropTypes.node, PropTypes.string]),
  onClose: PropTypes.func,
  showIcon: PropTypes.bool,
  type: PropTypes.string
}

WheelsBanner.defaultProps = {
  banner: false,
  showIcon: false,
  type: 'info'
}
