/**
 *
 * NotificationContent
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon, Row } from '@nv/react-commons/src/Components'
import { Text } from '../Text'
import { Colors } from 'themes'

import { faEnvelope } from '@fa-pro-light/faEnvelope'
import { faPlus } from '@fa-pro-light/faPlus'
import { faMobileAndroidAlt } from '@fa-pro-light/faMobileAndroidAlt'
import { faTruckLoading } from '@fa-pro-light/faTruckLoading'
import { faBoxCheck } from '@fa-pro-light/faBoxCheck'
import { faTruckRamp } from '@fa-pro-light/faTruckRamp'
import { faClock } from '@fa-pro-light/faClock'
import { faUndo } from '@fa-pro-light/faUndo'

const StyledText = styled(Text)`
  line-height: 24px;
`
const StyledDescText = styled(Text)`
  color: ${Colors.pinkishGrey};
  max-width: 400px;
`
const StyledIcon = styled(Icon).attrs({
  gap: 6
})`
  color: ${Colors.warmGrey};
`

const StyledRow = styled(Row)`
  && {
    line-height: 24px;
  }
`
class NotificationContent extends React.PureComponent {
  static TRANSIT_SHIPPER = 'transit_shipper'
  static COMPLETED_SHIPPER = 'completed_shipper'
  static DAILY_DELIVERY_FAIL_SHIPPER = 'daily_cross_border_rts_parcels'
  static TRANSIT_CUSTOMER = 'transit_customer'
  static PICKUP_FAIL_CUSTOMER = 'pickup_fail_customer'
  static COMPLETED_CUSTOMER = 'completed_customer'
  static DELIVERY_FAIL_CUSTOMER = 'delivery_fail_customer'

  static typeToContent = {
    [NotificationContent.TRANSIT_SHIPPER]: { icon: faTruckLoading, showDesc: false },
    [NotificationContent.TRANSIT_CUSTOMER]: { icon: faTruckLoading },
    [NotificationContent.DAILY_DELIVERY_FAIL_SHIPPER]: { icon: faUndo, showDesc: false },
    [NotificationContent.COMPLETED_SHIPPER]: { icon: faBoxCheck, showDesc: false },
    [NotificationContent.COMPLETED_CUSTOMER]: { icon: faBoxCheck },
    [NotificationContent.PICKUP_FAIL_CUSTOMER]: { icon: faTruckRamp, showDesc: false },
    [NotificationContent.DELIVERY_FAIL_CUSTOMER]: { icon: faClock }
  }

  renderMedia = () => {
    switch (this.props.type) {
      case NotificationContent.PICKUP_FAIL_CUSTOMER:
      case NotificationContent.DELIVERY_FAIL_CUSTOMER:
        return this.renderEmailSMS()
      default:
        return this.renderEmail()
    }
  }

  renderEmailSMS = () => (
    <StyledRow type='flex' align='middle'>
      <StyledIcon icon={faEnvelope} />
      <StyledIcon icon={faPlus} />
      <StyledIcon icon={faMobileAndroidAlt} />
      <Text type='italic' id='email_and_sms' />
    </StyledRow>
  )

  renderEmail = () => (
    <StyledRow type='flex' align='middle'>
      <StyledIcon icon={faEnvelope} />
      <Text type='italic' id='email' />
    </StyledRow>
  )

  render () {
    const { type } = this.props
    const prefix = `types.notifications.${type}`
    const showDesc = NotificationContent.typeToContent[type]?.showDesc ?? true
    return (
      <>
        <StyledText type='bold' prefix={prefix} id='title' />
        {showDesc && <StyledDescText prefix={prefix} id='desc' />}
        {this.renderMedia()}
      </>
    )
  }
}

NotificationContent.propTypes = {
  type: PropTypes.oneOf([
    NotificationContent.TRANSIT_SHIPPER,
    NotificationContent.COMPLETED_SHIPPER,
    NotificationContent.TRANSIT_CUSTOMER,
    NotificationContent.PICKUP_FAIL_CUSTOMER,
    NotificationContent.COMPLETED_CUSTOMER,
    NotificationContent.DELIVERY_FAIL_CUSTOMER,
    NotificationContent.DAILY_DELIVERY_FAIL_SHIPPER
  ])
}

NotificationContent.defaultProps = {
  children: 'NotificationContent'
}

export { NotificationContent }
