import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { ApplicationStyles } from 'themes'
import { StyledBackgroundImage } from 'containers/HomeHelpdesk/HomeHelpdesk.styled'
import { Images } from 'images'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'

export const ShipperSupportPublicForm = () => {
  return (
    <>
      <StyledBackgroundImage src={Images.helpdeskBackground} />
      <GlobalStyle />
      <Container>
        <Outlet />
      </Container>
    </>
  )
}

export const Container = styled.div`
  width: 70%;
  margin: 0 auto;
  height: 100%;
`

export const GlobalStyle = createGlobalStyle`
${ApplicationStyles.container};
  .ant-layout-content {
    height: 100%;
    min-height: 100%;
    width: 100%;
}
`
