import React from 'react'
import { CountrySelectFieldProps } from './CountrySelectField.interface'
import { SelectField } from 'components/SelectField'
import { SS_COUNTRY_OPTIONS_LOGIN, SS_COUNTRY_OPTIONS_SIGN_UP } from './CountrySelectField.constants'

export const CountrySelectField = (props: CountrySelectFieldProps) => {
  return (
    <SelectField
      {...props}
      by='value'
      options={props.isLogin ? SS_COUNTRY_OPTIONS_LOGIN : SS_COUNTRY_OPTIONS_SIGN_UP}
    />
  )
}
