import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Form, Formik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { parsePhoneNumber } from 'awesome-phonenumber'
import { COUNTRY_NAMES } from '@nv/react-commons/src/Constants'
import { Button } from '@nv/react-akira'
import { useIntl } from 'hooks'
import { DASH_ERRORS } from 'constants/errors'
import { CountrySelectField } from 'components/CountrySelectField'
import { TextInputField } from 'components/TextInputField'
import { SubmitButton } from 'components/SubmitButton'
import { UPDATE_PHONE_NUMBER, UPDATE_PHONE_NUMBER_SCHEMA, VERIFY_PHONE_NUMBER_OTP } from './VerifyPhoneNumber.consts'
import { VerifyPhoneNumberProvider, useVerifyPhoneNumber } from './VerifyPhoneNumber.context'
import { PhoneNumberWrapper, UpdatePhoneNumberAkiraModalWrapper } from './VerifyPhoneNumber.styled'
import { OTPForm } from './VerifyPhoneNumber.OTPForm'
import { getModalPath } from 'utils/getModalPath'

const UpdatePhoneNumberModal = () => {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const {
    step,
    setStep,
    userCountryCode,
    handleOnUpdatePhoneNumber,
    isLoading,
    modalTitleLokaliseKeyByStep
  } = useVerifyPhoneNumber()

  const handleOnBack = () => {
    navigate(-1)
  }

  const renderModalContent = () => {
    switch (step) {
      case UPDATE_PHONE_NUMBER:
        return (
          <Formik
            initialValues={{
              countryCode: { value: userCountryCode, text: COUNTRY_NAMES[userCountryCode] },
              phoneNumber: ''
            }}
            validationSchema={UPDATE_PHONE_NUMBER_SCHEMA}
            onSubmit={async (values, { setErrors }) => {
              const pn = parsePhoneNumber(values.phoneNumber, { regionCode: values.countryCode?.value })
              const errorCode = await handleOnUpdatePhoneNumber?.(pn)
              if (errorCode === DASH_ERRORS.BAD_REQUEST) {
                setErrors({ phoneNumber: 'auth.change_phone_number.phone_exists' })
              }
            }}
          >
            <Form>
              <p className='text-7 mb-5'>{intl.formatMessage({ id: 'auth.change_phone_number.description' })}</p>
              <PhoneNumberWrapper>
                <CountrySelectField isLogin name='countryCode' label={<FormattedMessage id='country_code' />} />
                <TextInputField name='phoneNumber' label={<FormattedMessage id='phone_number' />} />
              </PhoneNumberWrapper>
              <div className='flex gap-2 justify-end mt-5'>
                <Button
                  type='button'
                  onClick={handleOnBack}
                  styleType='secondary'
                  disabled={isLoading}
                  variant='default'
                  size='lg'
                >
                  {intl.formatMessage({ id: 'back' })}
                </Button>
                <SubmitButton>{intl.formatMessage({ id: 'next' })}</SubmitButton>
              </div>
            </Form>
          </Formik>
        )
      case VERIFY_PHONE_NUMBER_OTP:
        return <OTPForm onBack={() => setStep(UPDATE_PHONE_NUMBER)} />
      default:
        return null
    }
  }

  return (
    <UpdatePhoneNumberAkiraModalWrapper
      path={getModalPath(location, UPDATE_PHONE_NUMBER)}
      header={intl.formatMessage({ id: modalTitleLokaliseKeyByStep })}
      width='xs'
      closable={step !== VERIFY_PHONE_NUMBER_OTP}
    >
      {renderModalContent()}
    </UpdatePhoneNumberAkiraModalWrapper>
  )
}

const UpdatePhoneNumberModalWrapper = () => (
  <VerifyPhoneNumberProvider initialStep={UPDATE_PHONE_NUMBER}>
    <UpdatePhoneNumberModal />
  </VerifyPhoneNumberProvider>
)

export { UpdatePhoneNumberModalWrapper as UpdatePhoneNumberModal }
