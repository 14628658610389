import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button } from '@nv/react-akira'
import { useIntl } from 'hooks'
import { Images } from 'images'
import { selectUser } from 'containers/Base/selectors'
import { ROUTES } from 'containers/Base/constants'
import {
  PHONE_NUMBER_EXIST,
  UPDATE_PHONE_NUMBER,
  VERIFY_PHONE_NUMBER,
  VERIFY_PHONE_NUMBER_OTP
} from './VerifyPhoneNumber.consts'
import { VerifyPhoneNumberProvider, useVerifyPhoneNumber } from './VerifyPhoneNumber.context'
import { AkiraRouteModalWrapper } from './VerifyPhoneNumber.styled'
import { OTPForm } from './VerifyPhoneNumber.OTPForm'
import { getModalPath } from 'utils/getModalPath'

const VerifyPhoneNumberModal = () => {
  const intl = useIntl()
  const location = useLocation()
  const {
    currentPhoneNumber,
    step,
    handleSendOTP,
    navigateToUpdatePhoneNumberForm,
    resetForm,
    isAuthenticated,
    isLoading,
    modalTitleLokaliseKeyByStep,
    verifiedPhone
  } = useVerifyPhoneNumber()

  const renderModalContent = () => {
    if (!currentPhoneNumber.typeIsMobile) {
      return <Navigate to={`${ROUTES.HOME}/${UPDATE_PHONE_NUMBER}`} replace />
    }
    switch (step) {
      case VERIFY_PHONE_NUMBER:
        return (
          <>
            <img className='m-auto mb-5' width={100} height={120} src={Images.ryoAnnounce} />
            <p className='text-7 mb-5'>
              {intl.formatMessage({ id: 'auth.verify_phone.description' }, { phone: currentPhoneNumber.number.e164 })}
            </p>
            <div className='flex gap-2 justify-end'>
              <Button
                styleType='secondary'
                variant='default'
                size='lg'
                disabled={isLoading}
                onClick={navigateToUpdatePhoneNumberForm}
                className='bg-white'
              >
                {intl.formatMessage({ id: 'auth.verify_phone.change_phone_number' })}
              </Button>
              <Button
                styleType='primary'
                variant='grey'
                size='lg'
                loading={isLoading}
                disabled={isLoading}
                onClick={handleSendOTP}
              >
                {intl.formatMessage({ id: 'auth.verify_phone.verify_with_code' })}
              </Button>
            </div>
          </>
        )
      case VERIFY_PHONE_NUMBER_OTP:
        return <OTPForm onBack={resetForm} />
      case PHONE_NUMBER_EXIST:
        return (
          <>
            <p className='text-7 mb-5'>
              {intl.formatMessage({ id: 'auth.change_phone_number.phone_exists.description' })}
            </p>
            <div className='flex justify-end'>
              <Button
                styleType='primary'
                variant='grey'
                size='lg'
                disabled={isLoading}
                onClick={navigateToUpdatePhoneNumberForm}
              >
                {intl.formatMessage({ id: 'auth.verify_phone.change_phone_number' })}
              </Button>
            </div>
          </>
        )
      default:
        return null
    }
  }

  if (!isAuthenticated || verifiedPhone) {
    return null
  }

  return (
    <AkiraRouteModalWrapper
      path={getModalPath(location, VERIFY_PHONE_NUMBER)}
      header={intl.formatMessage({ id: modalTitleLokaliseKeyByStep })}
      width='xs'
      closable={step !== VERIFY_PHONE_NUMBER_OTP}
      onClose={resetForm}
    >
      {renderModalContent()}
    </AkiraRouteModalWrapper>
  )
}

const VerifyPhoneNumberModalWrapper = () => {
  const user = useSelector(selectUser())
  return (
    <VerifyPhoneNumberProvider key={user.contactNo}>
      <VerifyPhoneNumberModal />
    </VerifyPhoneNumberProvider>
  )
}

export { VerifyPhoneNumberModalWrapper as VerifyPhoneNumberModal }
