export { sizes, media } from '@nv/react-commons/src/Themes'

// TODO: import these from akira package if possible

export const AKIRA_BREAKPOINTS = {
  tabletMin: 550,
  laptopMin: 1100,
  desktopMin: 1500
}

export const AKIRA_QUERIES = {
  tabletAndUp: `(min-width: ${AKIRA_BREAKPOINTS.tabletMin}px)`,
  laptopAndUp: `(min-width: ${AKIRA_BREAKPOINTS.laptopMin}px)`,
  desktopAndUp: `(min-width: ${AKIRA_BREAKPOINTS.desktopMin}px)`
}
