import React, { useMemo, useState } from 'react'
import {
  CustomLink,
  StyledButton,
  StyledCenterText,
  StyledCustomCard,
  StyledFlexBlock,
  TextCenteredDiv
} from 'containers/FPLOrderCreate/styles'
import { Spin } from '@nv/react-commons/src/Components'
import { Text } from 'components/Text'
import { Vspace } from 'components/Vspace'
import { CircleIcon } from 'components/CircleIcon'
import { faCloudUpload } from '@fa-pro-light/faCloudUpload'
import { T } from '@nv/react-commons/src/Components'
import { Colors } from 'themes'
import { SaveAndContinue } from 'containers/FPLOrderCreate/SaveAndContinue'
import { MMCC_OC_STEP, SUPPORTED_DOCUMENT_STATUS } from './constants'
import { Hspace } from 'components/Hspace'
import { PreviewStepInfo } from './PreviewStepInfo'
import { DocumentDropZone } from './DocumentDropZone'
import { useIntl } from 'hooks/common'
import { ErrorMessage, InlineText, StyledIcon, TextWrapper } from 'containers/FPLAllOrders/styles'
import { faCheck } from '@fa-pro-solid/faCheck'
import { faTimes } from '@fa-pro-regular/faTimes'
import { SpacedBetweenFlex } from './styles'
import { faTrashAlt } from '@fa-pro-light/faTrashAlt'
import { CustomLinkWrapper } from 'components/ReturnParcelFulfillmentDrawer/styles'
import { faUpload } from '@fa-pro-light/faUpload'
import { calculateFileInfo } from './utils'
import { uniq } from 'lodash'

const SupportedDocument = ({
  fileType,
  status,
  uploadedFiles,
  errors,
  fileNames,
  onSave,
  onSkip,
  onEditStep,
  onReset,
  onNext
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const intl = useIntl()

  const fileInfo = useMemo(() => calculateFileInfo(uploadedFiles), [uploadedFiles])

  const removeFile = () => {
    onReset()
  }

  const handleSaveData = () => {
    onNext(fileInfo)
  }

  const handleLoading = loading => {
    setIsLoading(loading)
  }

  const renderErrorMessage = useMemo(() => {
    if (status === SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD) return null
    const firstThreeFileNames = fileNames.slice(0, 3)
    return (
      <SpacedBetweenFlex>
        <div>
          <Vspace height={16} />
          <InlineText>
            <StyledIcon
              icon={errors.length === 0 ? faCheck : faTimes}
              success={errors.length === 0 ? 'success' : null}
            />
            {fileNames.length > 3
              ? `${firstThreeFileNames.join(', ')} and +${fileNames.length - firstThreeFileNames.length} more`
              : fileNames.join(', ')}
          </InlineText>
          <TextWrapper>
            {errors.map(msg => (
              <ErrorMessage key={msg}>{msg}</ErrorMessage>
            ))}
          </TextWrapper>
        </div>
        {status === SUPPORTED_DOCUMENT_STATUS.UPLOADED_SUCCESS && (
          <div>
            <Vspace height={16} />
            <CustomLink leftIcon={faTrashAlt} to='#' onClick={removeFile}>
              <T id='international_delete_file' />
            </CustomLink>
          </div>
        )}
      </SpacedBetweenFlex>
    )
  }, [errors, fileNames, removeFile, status])

  const handleUploadFile = (supportedFiles, supportedFileErrors, suportedFileNames) => {
    const supportedErrorFormatted = supportedFileErrors.map(err => intl.formatMessage({ id: err }))
    onSave(supportedFiles, uniq(supportedErrorFormatted), suportedFileNames)
  }

  const renderHint = () => {
    if (isLoading) {
      return <Spin style={{ margin: '4px 0', backgroud: 'red' }} />
    }
    if (status === SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD) {
      return (
        <>
          <CircleIcon icon={faCloudUpload} size='big' type='success' gap={1} />
          <Vspace height={16} />
          <StyledCenterText size={16} id='click_and_drop_file' />
        </>
      )
    }
    if (status === SUPPORTED_DOCUMENT_STATUS.UPLOADED_SUCCESS) {
      return (
        <>
          <CircleIcon icon={faCheck} size='big' type='success' gap={1} />
          <Vspace height={16} />
          <StyledCenterText size={16} id='uploaded_successfully' color={Colors.mediumGreen} />
        </>
      )
    }
    return (
      <>
        <CircleIcon icon={faTimes} size='big' type='error' gap={1} />
        <Vspace height={16} />
        <StyledCenterText size={16} id='uploaded_unsuccessfully' color={Colors.negativeBalance} />
      </>
    )
  }

  const renderContent = () => {
    if (status === SUPPORTED_DOCUMENT_STATUS.IN_PREVIEW) {
      return (
        <PreviewStepInfo
          quantity={fileInfo?.totalFiles || 0}
          unit={fileInfo?.unit}
          totalValue={fileInfo?.size || 0}
          translatedKeys={{
            titleKey: 'total_files',
            totalPluralKey: 'plural_file',
            totalSingularKey: 'singular_file',
            totalValueKey: 'total_files',
            creationMethodKey: 'international_creation_method'
          }}
          icon={faCloudUpload}
          onEditStep={onEditStep}
        />
      )
    }
    return (
      <>
        <StyledCustomCard hoverable={false}>
          <TextCenteredDiv>
            {!fileNames.length ? (
              <Text
                id={fileType === MMCC_OC_STEP.COMMERCIAL_INVOICE ? 'commercial_invoice' : 'delivery_order'}
                type='bold'
                size={16}
              />
            ) : (
              <Text id='international_upload_result' type='bold' size={16} />
            )}
          </TextCenteredDiv>
          <Vspace height={10} />
          {status !== SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD && (
            <>
              <CustomLinkWrapper>
                <DocumentDropZone
                  height={20}
                  onDrop={handleUploadFile}
                  multiple
                  hint={
                    <CustomLink leftIcon={faUpload} to='#'>
                      <T id='international_replace_file' />
                    </CustomLink>
                  }
                  disabled={false}
                  errors={errors}
                  names={fileNames}
                  noneBorder
                  onLoading={handleLoading}
                />
              </CustomLinkWrapper>
              <Vspace height={16} />
            </>
          )}
          <DocumentDropZone
            height={200}
            multiple
            onDrop={handleUploadFile}
            hint={renderHint()}
            disabled={status === SUPPORTED_DOCUMENT_STATUS.UPLOADED_SUCCESS}
            errors={errors}
            names={fileNames}
            onLoading={handleLoading}
          />
          <>
            {renderErrorMessage}
            <Vspace height={16} />
            <Text color={Colors.darkGreyBlue} id='support_document_note' />
            <Text color={Colors.darkGreyBlue} id='document_file_type' />
            <Text color={Colors.darkGreyBlue} id='max_file_allowed' />
            <Text color={Colors.darkGreyBlue} id='each_file_size_smaller_than_50' />
          </>
        </StyledCustomCard>
        <StyledFlexBlock>
          <SaveAndContinue disabled={status !== SUPPORTED_DOCUMENT_STATUS.UPLOADED_SUCCESS} onSave={handleSaveData} />
          <Hspace width={12} />
          <StyledButton
            onClick={onSkip}
            type='default'
            disabled={status === SUPPORTED_DOCUMENT_STATUS.UPLOADED_SUCCESS}
          >
            <T
              id={
                fileType === MMCC_OC_STEP.COMMERCIAL_INVOICE
                  ? 'skip_upload_commercial_invoice_order'
                  : 'skip_upload_delivery_order'
              }
              allCaps
            />
          </StyledButton>
        </StyledFlexBlock>
      </>
    )
  }

  return <>{renderContent()}</>
}

export { SupportedDocument }
