import React from 'react'
import PropTypes from 'prop-types'

import { T } from '@nv/react-commons/src/Components'
import { StyledFlexBlock, StyledButton } from './styles'

const saveAndContinueTestId = '4pl_oc_save_and_continue'

const SaveAndContinue = ({ onSave, disabled }) => {
  return (
    <StyledFlexBlock>
      <StyledButton disabled={disabled} data-testid={saveAndContinueTestId} onClick={onSave}>
        <T id='save_and_continue' allCaps />
      </StyledButton>
    </StyledFlexBlock>
  )
}

SaveAndContinue.propTypes = {
  onSave: PropTypes.func,
  disabled: PropTypes.bool
}

SaveAndContinue.defaultProps = {
  onSave: () => {},
  disabled: false
}

export { SaveAndContinue, saveAndContinueTestId }
