/**
 *
 * Asynchronously loads the component for Mapping
 *
 */

import { PageLoading } from 'components/Loading'
import React from 'react'
import loadable from 'utils/loadable'

export default loadable({
  loader: () => import('./index' /* webpackChunkName: "Mapping" */),
  loading: <PageLoading />
})
