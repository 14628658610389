export const TRACKING_TYPES = {
  PREFIX_LESS: 'prefixless',
  FIXED: 'fixed',
  DYNAMIC: 'dynamic',
  MULTI_DYNAMIC: 'multidynamic',
  MULTI_FIXED: 'multifixed'
}

export const DEFAULT_SETTINGS = {
  required: false
}

export const TRACKING_TYPES_SETTINGS = {
  [TRACKING_TYPES.PREFIX_LESS]: {
    required: true,
    min: 9,
    max: 24
  },
  [TRACKING_TYPES.FIXED]: {
    required: false,
    min: 1,
    max: 9
  },
  [TRACKING_TYPES.DYNAMIC]: {
    required: false,
    min: 6,
    max: 24
  },
  [TRACKING_TYPES.MULTI_DYNAMIC]: {
    required: true,
    min: 6,
    max: 24
  },
  [TRACKING_TYPES.MULTI_FIXED]: {
    required: true,
    min: 1,
    max: 9
  }
}
