/**
 *
 * Text
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { T, Tooltip } from '@nv/react-commons/src/Components'
import { StyleUtils } from '@nv/react-commons/src/Utils'
import { Colors, Fonts } from 'themes'
import _ from 'lodash'

const types = {
  light: Fonts.style.light,
  bold: Fonts.style.bold,
  regular: Fonts.style.regular,
  italic: Fonts.style.italic
}

const StyledText = styled(({ size, color, type, maxWidth, inline, right, center, ...props }) => <div {...props} />)`
  font-size: ${props => props.size || 14}px;
  color: ${props => props.color || Colors.greyishBrown};
  ${props => types[props.type] || types.regular};
  ${props => props.maxWidth && StyleUtils.truncate(props.maxWidth)};
  ${props => props.inline && 'display: inline;'};
  ${props => props.right && 'text-align: right;'};
  ${props => props.center && 'text-align: center;'};
  br {
    content: '';
    margin: 1em;
    display: block;
    fontsize: 24%;
  }
`

// TODO: add good props interface to Text component
const Text = ({ allCaps, id, values, children, prefix, showTooltip, ...props }: Record<any, any>) => {
  const Component = (
    <StyledText {...props}>
      {id ? <T id={id} values={values} allCaps={allCaps} prefix={prefix} /> : children}
    </StyledText>
  )
  return (props.maxWidth || showTooltip) && showTooltip ? <Tooltip title={children}>{Component}</Tooltip> : Component
}

Text.propTypes = {
  allCaps: PropTypes.bool,
  id: PropTypes.string,
  values: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  prefix: PropTypes.string,
  showTooltip: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
  type: PropTypes.oneOf(['light', 'bold', 'regular', 'italic']),
  maxWidth: PropTypes.number,
  inline: PropTypes.bool,
  right: PropTypes.bool,
  center: PropTypes.bool
}

Text.defaultProps = {
  type: 'regular'
}

/*
  According to Ninja Dashboard's Typography guidelines
 */
const StyledH1 = styled(Text).attrs({
  type: 'bold',
  size: 28
})``
const StyledH2 = styled(Text).attrs({
  type: 'bold',
  size: 24
})``
const StyledH3 = styled(Text).attrs({
  type: 'bold',
  size: 18
})``
// Corresponds to "Create order subsection"
const StyledH4 = styled(Text).attrs({
  type: 'bold',
  size: 16
})``
// Corresponds to sub sub sections
const StyledH5 = styled(Text).attrs({
  type: 'bold',
  size: 12,
  color: Colors.pinkishGrey
})``
// Corresponds to Mobile body text
const StyledBigP = styled(Text).attrs({
  size: 15
})``
// Corresponds to Desktop body text
const StyledSmallP = styled(Text).attrs({
  size: 13
})``
// Corresponds to Disclaimer text
const StyledLabel = styled(Text).attrs({
  size: 12,
  color: Colors.pinkishGrey
})``

const H1 = props => <StyledH1 {...props} />
const H2 = props => <StyledH2 {...props} />
const H3 = props => <StyledH3 {...props} />
const H4 = props => <StyledH4 {...props} />
const H5 = props => <StyledH5 {...props} />
const BigP = props => <StyledBigP {...props} />
const SmallP = props => <StyledSmallP {...props} />
const Label = props => <StyledLabel {...props} />

const customTextPropTypes = _.omit(Text.propTypes, 'size')

H1.propTypes = customTextPropTypes
H2.propTypes = customTextPropTypes
H3.propTypes = customTextPropTypes
H4.propTypes = customTextPropTypes
H5.propTypes = customTextPropTypes
BigP.propTypes = customTextPropTypes
SmallP.propTypes = customTextPropTypes
Label.propTypes = customTextPropTypes

export { Text, H1, H2, H3, H4, H5, BigP, SmallP, Label }
