import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { OCHeader } from 'components/OCHeader'
import { BASE_ROUTES, ROUTES } from 'containers/Base/constants'
import { withSize } from 'components/BreakpointListener'
import { compose } from 'redux'
import { flatMap, map } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { T } from '@nv/react-commons/src/Components'
import { getLanes, createOnboardingPartners, getPartnerDetail } from 'services/api/fplApi'
import { Text } from 'components/Text'
import { RegistrationContainer, InsideWrapper, StyleSpin, StyledButton } from './styles'
import { Vspace } from 'components/Vspace'
import { CenterText } from 'components/CenterText'
import { createStructuredSelector } from 'reselect'
import { selectCountry, selectIsSS, selectShipper } from 'containers/Base/selectors'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { RegistrationSuccess } from './RegistrationSuccess'
import FPLErrorPage from 'containers/FPLErrorPage'
import { API_RESPONSE_STATUS, NUMBER_OF_PARCEL_OPTIONS_BY_KEY, PARCEL_WEIGHT_OPTIONS_BY_KEY } from './constants'
import {
  mpTrackSelectedLanesOnboarding,
  mpTrackExitOnboarding,
  mpTrackOpenedOnboarding,
  mpTrackFirstOnboardedDate
} from 'containers/FPLMixpanel/helpers'
import { PAGE_NAMES } from 'containers/FPLMixpanel/constants'
import { buildFullErrorInfo } from 'containers/FPLErrorPage/dataUtils'
import { getSupportAndUnsportedCountries } from './dataUtils'
import { OnboardingForm } from './OnboardingForm'
import { useIntl } from 'hooks/common'
import { StyledImage } from 'containers/FPLParcelDetail/styles'
import { Images } from 'images'
import Config from 'configs'

const initialState = {
  selectedFromCountries: [],
  selectedToCountries: [],
  allToCountries: [],
  allFromCountries: [],
  supportedToCountries: [],
  unsupportedToCountries: [],
  isSignedTerm: false,
  showSuccessPage: false,
  isLanesLoading: false,
  errorCode: null,
  isCreatingPartner: false,
  partnerLoading: false,
  partnerStatus: null,
  partner: null,
  errors: [],
  selectedSupportedToCountries: [],
  selectedUnsupportedToCountries: [],
  selectedQuantity: null,
  selectedWeight: null,
  canStartShipping: false,
  estimationByCountry: {}
}

const FPLRegistration = ({ shipper, isSelfServe, shipperCountry }) => {
  const [state, setState] = useState(initialState)
  const navigate = useNavigate()

  const {
    showSuccessPage,
    isLanesLoading,
    allToCountries,
    allFromCountries,
    selectedFromCountries,
    selectedToCountries,
    showErrorPage,
    isCreatingPartner,
    partnerLoading,
    partnerStatus,
    partner,
    errors,
    supportedToCountries,
    unsupportedToCountries,
    selectedSupportedToCountries,
    selectedUnsupportedToCountries,
    selectedQuantity,
    selectedWeight,
    estimationByCountry
  } = state

  const intl = useIntl()

  const isSelfServiceShipper = useMemo(() => {
    if (!isSelfServe) return false
    const selfServeCountries = Config.FPL_AVAILABLE_SELF_SERVE_COUNTRIES?.split(/,\s*/) || []
    return !selfServeCountries.includes(shipperCountry) && isSelfServe
  }, [isSelfServe, shipperCountry])

  const getPartner = useCallback(async () => {
    const response = await getPartnerDetail(shipper.id)
    let stateData = { partnerLoading: false, partnerStatus: response.status }
    if (response.ok && response.status === API_RESPONSE_STATUS.SUCCESS) {
      stateData = { ...stateData, partner: response.data.data }
    }

    if (!response.ok && response.status !== API_RESPONSE_STATUS.NOT_FOUND) {
      const { apiUrl, message, status } = buildFullErrorInfo(response)
      stateData = {
        ...stateData,
        errors: [
          {
            errorApi: apiUrl,
            errorStatus: status,
            errorMessage: message
          }
        ]
      }
    }
    setState(prevState => ({ ...prevState, ...stateData }))
  }, [shipper.id])

  useEffect(() => {
    if (shipper?.id && !isSelfServiceShipper) {
      setState(prevState => ({ ...prevState, partnerLoading: true }))
      getPartner()
    }
  }, [getPartner, shipper?.id, isSelfServiceShipper])

  useEffect(() => {
    if (partnerStatus === API_RESPONSE_STATUS.NOT_FOUND) {
      mpTrackOpenedOnboarding()
    }
    if (partnerStatus === API_RESPONSE_STATUS.SUCCESS && partner) {
      navigate(ROUTES.FPL_HOME)
    }
  }, [partnerStatus, partner])

  const getOnboardingLanes = async originCountry => {
    setState(prevState => ({ ...prevState, isLanesLoading: true }))
    const originCountries = [originCountry]
    const weights = []
    Object.values(PARCEL_WEIGHT_OPTIONS_BY_KEY).forEach(parcel => {
      weights.push(parcel.range.min)
      weights.push(parcel.range.max)
    })
    const queryParams = {
      origin: originCountries,
      weight: weights
    }
    const response = await getLanes(queryParams)
    let newState = { isLanesLoading: false }
    if (response.ok) {
      const {
        supportedCountries,
        unsupportedCountries,
        estimationByCountry: estimations
      } = getSupportAndUnsportedCountries(response.data.data, originCountries)
      newState = {
        ...newState,
        allToCountries: [...supportedCountries, ...unsupportedCountries],
        supportedToCountries: supportedCountries,
        unsupportedToCountries: unsupportedCountries,
        estimationByCountry: estimations
      }
    } else {
      if (response.status === API_RESPONSE_STATUS.NOT_IMPLEMENTED) {
        navigate(BASE_ROUTES.HOME)
      } else {
        const { apiUrl, message, status } = buildFullErrorInfo(response)
        newState = {
          ...newState,
          showErrorPage: true,
          errors: [
            {
              errorApi: apiUrl,
              errorStatus: status,
              errorMessage: message
            }
          ]
        }
      }
    }
    setState(prevState => ({ ...prevState, ...newState }))
  }

  useEffect(() => {
    if (shipper?.country && !isSelfServiceShipper) {
      getOnboardingLanes(shipper.country)
      setState(prevState => ({
        ...prevState,
        selectedFromCountries: [shipper.country],
        allFromCountries: [shipper.country]
      }))
    }
  }, [shipper?.country, isSelfServiceShipper])

  const handleOnLeaveHeader = () => {
    mpTrackExitOnboarding()
    navigate(ROUTES.HOME)
  }

  const handleSaveForm = data => {
    const {
      supportedToCountries: activeSupportedToCountries,
      unsupportedToCountries: activeUnsupportedToCountries,
      weight,
      quantity,
      signedTerm
    } = data
    setState(prevState => ({
      ...prevState,
      selectedSupportedToCountries: activeSupportedToCountries,
      selectedUnsupportedToCountries: activeUnsupportedToCountries,
      selectedToCountries: [...activeSupportedToCountries, ...activeUnsupportedToCountries],
      selectedWeight: weight,
      selectedQuantity: quantity,
      isSignedTerm: signedTerm
    }))
  }

  const handleCreatePartner = async () => {
    setState(prevState => ({ ...prevState, isCreatingPartner: true }))
    const mpSupportedLanes = flatMap(selectedFromCountries, origin =>
      map(selectedSupportedToCountries, destination => `${origin} - ${destination}`)
    ).join(', ')
    const mpUnsupportedLanes = flatMap(selectedFromCountries, origin =>
      map(selectedUnsupportedToCountries, destination => `${origin} - ${destination}`)
    ).join(', ')
    const mpWeightOfParcel = intl.formatMessage({ id: PARCEL_WEIGHT_OPTIONS_BY_KEY[selectedWeight].label })
    const numberOfParcel = intl.formatMessage({ id: NUMBER_OF_PARCEL_OPTIONS_BY_KEY[selectedQuantity].label })
    mpTrackSelectedLanesOnboarding({
      supportedLanes: mpSupportedLanes,
      unsupportedLanes: mpUnsupportedLanes,
      parcelPerDay: numberOfParcel,
      weightPerParcel: mpWeightOfParcel
    })

    const dataRequest = {
      shipper_remote_id: shipper.id,
      destinations: selectedToCountries
    }
    const response = await createOnboardingPartners(dataRequest)
    let newState = { isCreatingPartner: false }
    if (response.ok && response.data.data.id) {
      newState.showSuccessPage = true
      mpTrackFirstOnboardedDate()
    } else {
      if (response.status === API_RESPONSE_STATUS.NOT_IMPLEMENTED) {
        navigate(BASE_ROUTES.HOME)
      } else {
        const { apiUrl, message, status } = buildFullErrorInfo(response)
        newState = {
          ...newState,
          showErrorPage: true,
          errors: [
            {
              errorApi: apiUrl,
              errorStatus: status,
              errorMessage: message
            }
          ]
        }
      }
    }
    setState(prevState => ({ ...prevState, ...newState }))
  }

  const goToHomePage = () => {
    navigate(ROUTES.HOME)
  }

  if (isSelfServiceShipper) {
    return (
      <>
        <OCHeader
          title='international_register_shipping'
          exitTitle='international_exist_register_international_service'
          onLeave={handleOnLeaveHeader}
        />
        <InsideWrapper
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column'
          }}
        >
          <StyledImage src={Images.ryoSorry} style={{ maxHeight: 120, marginBottom: 20 }} />
          <Text id='international_unavailable_for_lite_account' size='md' />
          <StyledButton size='medium' type='primary' style={{ width: 200, marginTop: 20 }} onClick={goToHomePage}>
            <T id='shipper_helpdesk.success.cta' allCaps />
          </StyledButton>
        </InsideWrapper>
      </>
    )
  }

  if (isLanesLoading || partnerLoading) {
    return <StyleSpin />
  }

  if (!shipper || showErrorPage || partnerStatus >= API_RESPONSE_STATUS.INTERNAL_ERROR) {
    return <FPLErrorPage pageName={PAGE_NAMES[ROUTES.FPL_SERVICE_REGISTRATION]} errors={errors} />
  }

  return (
    <>
      <OCHeader
        title='international_register_shipping'
        exitTitle='international_exist_register_international_service'
        onLeave={handleOnLeaveHeader}
      />
      <InsideWrapper>
        {!showSuccessPage ? (
          <RegistrationContainer>
            <Vspace height={16} />
            <CenterText>
              <Text size={24}>
                <T id='international_registration_thankout_line1' />
              </Text>
              <Text size={24}>
                <T id='international_registration_thankout_line2' />
              </Text>
            </CenterText>
            <Vspace height={32} />

            <OnboardingForm
              onSaveForm={handleSaveForm}
              onCreatePartner={handleCreatePartner}
              allToCountries={allToCountries}
              allFromCountries={allFromCountries}
              selectedToCountries={selectedToCountries}
              selectedFromCountries={selectedFromCountries}
              supportedToCountries={supportedToCountries}
              unsupportedToCountries={unsupportedToCountries}
              activeSupportedToCountries={selectedSupportedToCountries}
              activeUnsupportedToCountries={selectedUnsupportedToCountries}
              activeWeight={selectedWeight}
              activeQuantity={selectedQuantity}
              isLoading={isCreatingPartner}
              estimationByCountry={estimationByCountry}
            />

            <Vspace height={28} />
          </RegistrationContainer>
        ) : (
          <RegistrationSuccess />
        )}
      </InsideWrapper>
    </>
  )
}

FPLRegistration.propTypes = {
  shipper: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  shipper: selectShipper(),
  isSelfServe: selectIsSS(),
  shipperCountry: selectCountry()
})

const withConnect = connect(mapStateToProps, null)

const RegistrationInternational = compose(withSize, withConnect)(FPLRegistration)

export { RegistrationInternational as FPLRegistration }
