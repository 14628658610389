export const getLayoutContent = () => document.getElementsByClassName('ant-layout-content')[0]

export const copyToClipboard = value => {
  const input = document.createElement('input')
  input.value = value
  document.body.appendChild(input)
  input.focus()
  input.select()
  document.execCommand('copy')
  document.body.removeChild(input)
}
