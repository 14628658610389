import styled from 'styled-components'
import { MainWrapper } from 'components/MainWrapper'
import { Text } from 'components/Text'
import { Fonts } from '@nv/react-commons/src/Themes'
import { Colors } from 'themes'
import { Modal } from 'components/Modal'
import { Radio } from 'antd'
import { RadioGroup } from 'components/RadioGroup'
import { Form, NVInput, Dropdown, Menu, Icon, Col, Row, DatePicker } from '@nv/react-commons/src/Components'
import { Link } from 'components/Link'
import { Button } from 'components/Button'
import { StyledDropdownButton } from 'containers/FPLOrderRequestList/OrderRequestTable'

const { RangePicker } = DatePicker

export const TableHeaderText = styled(Text).attrs({
  type: 'bold',
  inline: true,
  size: 13
})``

export const TableWrapper = styled.div`
  flex: 1;
`
export const TableContainer = styled(MainWrapper)`
  margin: 0;
  padding: 0;
  min-width: 100%;
`
export const StyledRow = styled(Row)`
  padding: 0 24px;
  margin: 24px 0;
`
export const StyledText = styled(Text)`
  ${({ fontSize, fontWeight }) => {
    return `
      font-size: ${fontSize || 16}px;
      ${fontWeight ? `font-weight: ${fontWeight};` : `${Fonts.style.regular};`}
    `
  }}
`

export const ModalWrapper = styled(Modal)`
  .ant-modal-footer {
    background-color: ${Colors.whiteTree};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const GroupButtonWrapper = styled(RadioGroup)`
  width: 100%;
  display: flex !important;
`

export const SearchZone = styled.div`
  padding-top: 20px;
`

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const FormItemFooter = styled(Form.Item)`
  width: 100%;
`

export const TextAreaWrapper = styled.div`
  height: 200px;
`

export const StyledTextArea = styled(NVInput.TextArea)`
  &&.ant-input {
    height: 100%;
    ${({ error }) => {
      if (error) {
        return `
          box-shadow: 0 0 6px 0 ${Colors.salmon};
          &&:focus, &&:hover {
            box-shadow: 0 0 6px 0 ${Colors.salmon};
          }`
      }
    }}
`

export const OptionButton = styled(Radio.Button)`
  ${({ fullWidth }) => {
    if (fullWidth) {
      return 'width: 100%;'
    }
  }}

  ${({ isCustom }) => {
    if (isCustom) {
      return `
        &.ant-radio-button-wrapper {
          height: 40px;
          display: flex;
          align-items: center;
        }
      `
    }
  }}

  ${({ lastChild }) => {
    if (lastChild) {
      return `
        &.ant-radio-button-wrapper:first-child {
          border-right: 1px solid #d9d9d9;
          border-radius: 0 4px 4px 0;
          border-left: none;
        }
      `
    }
  }}

  text-align: center;

  &:hover {
    color: ${Colors.darkGrey};
  }

  &&.ant-radio-button-wrapper-checked {
    background: ${Colors.greyishBrownTwo};
    color: ${Colors.white};
    border-color: ${Colors.greyishBrownTwo} !important;
    box-shadow: -1px 0 0 0 ${Colors.greyishBrownTwo};

    &:hover {
      border-color: ${Colors.greyishBrownTwo} !important;
      color: ${Colors.white};
      box-shadow: -1px 0 0 0 ${Colors.greyishBrownTwo};
    }
  }
`
export const StyledAccountDropdown = styled(Dropdown)`
  && {
    color: ${props => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const StyledMenuItem = styled(Menu.Item)`
  min-height: 56px;
`
export const StyledMenuItemLabel = styled.span`
  max-width: 128px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const SearchInputContainer = styled.div`
  width: 300px;
  position: relative;
  margin-right: 10px;

  .ant-input-affix-wrapper {
    &:hover {
      border-color: ${Colors.lightPeriwinkle} !important;

      .ant-input {
        background: white;
        border: 1px solid ${Colors.lightPeriwinkle} !important;
        height: 40px;

        &:hover {
          border-color: ${Colors.lightPeriwinkle} !important;
        }

        &:focus: {
          box-shadow: 0 0 6px 0 rgb(71 199 154 / 50%);
        }
      }
    }
  }

  .ant-input-suffix {
    right: 22px !important;
  }

  > span {
    &:hover {
      border-color: ${Colors.lightPeriwinkle} !important;
    }

    .ant-input {
      height: 40px;
      background: white;
      border: 1px solid ${Colors.lightPeriwinkle};

      &:hover {
        border-color: ${Colors.lightPeriwinkle} !important;
      }

      &:focus: {
        box-shadow: 0 0 6px 0 rgb(71 199 154 / 50%);
      }
    }
  }
`
export const AdvancedSearchDropdownContainer = styled.div``

export const LeftText = styled.div`
  text-align: start;
`

export const StyledLink = styled(Link)`
  color: ${Colors.cherryRed};
  text-decoration: underline;
`

export const ErrorMessage = styled.div`
  color: ${Colors.salmon};
  font-size: 12px;
`

export const TextWrapper = styled.div`
  ${({ paddingTop, paddingRight }) => {
    if (paddingTop) {
      return `padding-top: ${paddingTop}px`
    }
    if (paddingRight) {
      return `padding-right: ${paddingRight}px`
    }
  }}
`

export const StyledIcon = styled(Icon).attrs(({ success }) => ({
  color: !success ? Colors.salmon : Colors.emerald
}))`
  margin-right: 8px;
`

export const InlineText = styled.div`
  display: flex;
  align-items: center;
`

export const StyledCol = styled(Col)`
  height: 32px;
`

export const VerticalCenter = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

export const DescriptionStyled = styled(Text)`
  white-space: pre-line;
  color: ${Colors.pinkishGrey};
  line-height: 18px;
  font-size: 12px;
`

export const CustomIcon = styled(Icon)`
  ${({ marginleft, fontSize }) => {
    return `
      margin-left: ${marginleft || 0}px;
      font-size: ${fontSize || 14}px;
    `
  }}
`

export const StyledBackButton = styled(Button)`
  margin: 0 5px;
  text-transform: none !important;
`

export const LeftBlockWrapper = styled.div`
  display: flex;
  flex: 2 1 0%;
`

export const DateRangeContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledDateRangeDropdown = styled(RangePicker)`
  && {
    width: 242px;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 46px;

    #rangePickerCalendarContainer {
      .ant-calendar-input-wrap {
        height: 0;
        padding: 0;
        border: none;
      }
    }
  }
`

export const StyledButton = styled.div`
  && {
    height: 40px;
    position: relative;
    text-transform: capitalize;
    letter-spacing: normal;
    ${Fonts.style.regular};

    .ant-calendar-input-wrap {
      height: 0;
      padding: 0;
      border: none;
      display: none;
    }

    .ant-calendar-range-middle {
      display: none;
    }
  }
`

export const StyledPosIcon = styled(Icon)`
  position: absolute;
  cursor: pointer;
  ${({ top, right }) => {
    return `
     ${top && `top: ${top}px;`}
     ${right && `right: ${right}px;`}
    `
  }}
`

export const StyledStatusDropdown = styled(StyledDropdownButton)`
  min-height: 40px;
  border-color: #d9d9d9 !important;
  border-radius: 4px !important;
  font-size: 14px !important;
`

export const MenuMultipleSelectStyled = styled(Menu)`
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%) !important;

  .ant-menu-item {
    height: 36px !important;
    line-height: 36px !important;
  }

  .ant-menu-item-selected {
    background-color: #fff !important;
  }
`
