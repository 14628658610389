/**
 *
 * ErrorPage
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import styled, { withTheme } from 'styled-components'
import { T } from '@nv/react-commons/src/Components'

import { Button } from '../Button'
import { CenterText } from '../CenterText'
import { Vspace } from '../Vspace'
import { Colors } from 'themes'
import { ROUTES } from 'containers/Base/constants'
import { Images } from 'images'
import { withNavigate } from '../../containers/RouterHOCs'

const StyledError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledImg = styled.img`
  width: 180px;
  max-height: 150px;
  object-fit: scale-down;
`

class ErrorPage extends React.PureComponent {
  static PAGE_404 = 'PAGE_404'
  static ORDER_404 = 'ORDER_404'
  static SWITCH_ACCOUNT = 'SWITCH_ACCOUNT'
  static BOUNDARY = 'BOUNDARY'
  static NO_ADDRESSES = 'NO_ADDRESSES'
  static NO_ORDERS = 'NO_ORDERS'
  static SCHEDULE_REPORTS_404 = 'SCHEDULE_REPORTS_404'
  static NO_API_ORDERS = 'NO_API_ORDERS'
  static NO_RETURN_PARCELS = 'NO_RETURN_PARCELS'

  getContent = () => {
    const {
      theme: { clientErrorImg, notFoundImg, switchAccImg, ryoMagnifyingGlassImg }
    } = this.props
    const mapContent = {
      [ErrorPage.PAGE_404]: {
        image: clientErrorImg,
        title: 'take_deep_breath',
        caption: 'page_not_found',
        label: 'go_to_dashboard',
        cta: () => this.props.navigate(ROUTES.HOME)
      },
      [ErrorPage.ORDER_404]: {
        image: notFoundImg,
        title: 'order_not_found',
        caption: 'try_searching_again',
        label: 'go_to_tracking'
      },
      [ErrorPage.SCHEDULE_REPORTS_404]: {
        image: ryoMagnifyingGlassImg,
        title: 'reports.no_scheduled_reports',
        caption: 'reports.no_scheduled_reports_description',
        label: 'reports_generate_report_header'
      },
      [ErrorPage.SWITCH_ACCOUNT]: {
        image: switchAccImg,
        title: 'switch_account_to_view_order',
        caption: 'order_found_need_switch_account',
        label: 'switch_account'
      },
      [ErrorPage.BOUNDARY]: {
        image: clientErrorImg,
        title: 'take_deep_breath',
        caption: 'there_was_a_problem',
        label: 'refresh_page',
        cta: () => window.location.reload()
      },
      [ErrorPage.NO_ADDRESSES]: {
        image: notFoundImg,
        title: 'no_address_yet',
        caption: 'time_to_create_one',
        label: 'go_to_address'
      },
      [ErrorPage.NO_ORDERS]: {
        image: notFoundImg,
        title: 'no_orders_yet',
        caption: 'time_to_create_one',
        label: 'go_to_order_create'
      },
      [ErrorPage.NO_API_ORDERS]: {
        image: notFoundImg,
        title: 'no_orders_yet',
        caption: 'no_relevant_results',
        label: ''
      },
      [ErrorPage.NO_RETURN_PARCELS]: {
        image: Images.ryoNoReturnParcels,
        title: 'return_parcels.no_return_parcels',
        caption: '',
        label: ''
      }
    }

    return mapContent[this.props.type]
  }

  render () {
    const { action, ...props } = this.props
    const { image, title, caption, label, cta } = this.getContent()
    return (
      <StyledError {...props}>
        <StyledImg src={image} />
        <Vspace height={32} />
        <CenterText size={18} type='bold'>
          <T id={title} />
        </CenterText>
        <Vspace height={16} />
        <CenterText>
          <T id={caption} />
        </CenterText>
        <Vspace height={40} />
        {(action || cta) && (
          <Button type='primary' size='medium' onClick={action || cta}>
            <CenterText size={12} type='bold' color={Colors.white}>
              <T id={label} allCaps />
            </CenterText>
          </Button>
        )}
      </StyledError>
    )
  }
}

ErrorPage.propTypes = {
  type: PropTypes.string.isRequired,
  action: PropTypes.func,
  navigate: PropTypes.func,
  theme: PropTypes.object
}

ErrorPage.defaultProps = {
  type: ErrorPage.PAGE_404
}

const _ErrorPage = compose(withNavigate, withTheme)(ErrorPage)
export default _ErrorPage
export { _ErrorPage as ErrorPage }
