/**
 *
 * ResponsiveModal
 *
 */

import { media } from '@nv/react-commons/src/Themes/Media'
import { Modal } from 'components/Modal'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ResponsiveModal = styled(Modal)`
  &&& {
    ${media.max.tablet`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    height: 100%;
    margin: 0;
    padding-bottom: 0;
    max-width: 100%;
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      .ant-modal-body {
        flex: 1;
      }
    }`};
  }
`

ResponsiveModal.propTypes = {
  children: PropTypes.node
}

ResponsiveModal.defaultProps = {
  children: 'ResponsiveModal'
}

export { ResponsiveModal }
