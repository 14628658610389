import React from 'react'
import styled from 'styled-components'
import { T } from '@nv/react-commons/src/Components'

import { Alert } from 'components/Alert'
import { Colors } from 'themes'
import { useFlag } from 'featureHub/useFlag'
import featureKeys from 'featureHub/featureKeys'

import { getMaxDimensions } from './OCForm.helpers'
import { DIMENSIONS_EXCEED_LIMIT_ERROR } from './constants'

// Displays a validation error message when the user enters custom dimensions that are too large
export const DimensionsAlert = ({ form: { getFieldError }, country }) => {
  const shouldAllowCustomiseDimensions = useFlag(featureKeys.MERGE_LITE_AND_MOBILE_FEATURES)
  if (!shouldAllowCustomiseDimensions) {
    return null
  }

  const errors = getFieldError('width') || getFieldError('height') || getFieldError('length')
  if (!errors || !errors.includes(DIMENSIONS_EXCEED_LIMIT_ERROR)) {
    return null
  }

  const msg = (
    <MessageWrapper>
      <TitleWrapper>
        <T id='pacel_dimensions_exceed_limit' />
      </TitleWrapper>
      <DescriptionWrapper>
        <T id='pacel_dimensions_max_limit' values={{ max: getMaxDimensions(country) }} />
      </DescriptionWrapper>
    </MessageWrapper>
  )

  return (
    <Wrapper>
      <Alert type='error' message={msg} closable={false} showIcon />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 16px;
`

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: ${Colors.darkGreyBlue};
`

const TitleWrapper = styled.div`
  font-size: 14px;
`

const DescriptionWrapper = styled.div`
  font-size: 12px;
`
