import { useCallback, useState } from 'react'

/**
 * useStorage
 * Adopted from https://usehooks.com/useLocalStorage/
 */
export const useStorage = <T = any>(key: string, initialValue: T, storageType: 'local' | 'session' = 'local') => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window[storageType === 'local' ? 'localStorage' : 'sessionStorage'].getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value
        // Save state
        setStoredValue(valueToStore)
        // Save to local storage
        window[storageType === 'local' ? 'localStorage' : 'sessionStorage'].setItem(key, JSON.stringify(valueToStore))
      } catch (error) {
        // A more advanced implementation would handle the error case
      }
    },
    [key, storageType, storedValue]
  )

  return [storedValue, setValue] as const
}
