import React, { useState } from 'react'
import { fns, NOTICE_MODAL_WIDTH, MessageModal } from './shared'
import { Button } from 'components/Button'
import { Text } from 'components/Text'
import { MessageCreators } from 'containers/Base/redux'
import T from '@nv/react-commons/src/Components/T'
import { dashApi } from 'services/api'
import { useReduxHelpers } from './hooks'
import { momentUTCWithTimezone } from 'utils/time'
import { ROUTES } from '../Base/constants'
import styled from 'styled-components'
import { media } from '../../themes'
import PropTypes from 'prop-types'

const StyledButton = styled(Button)`
  ${media.max.mobile`
      margin-bottom: 16px;
      margin-left: auto;
      margin-rigth: auto;
      width: 100%;
    `};
`

export function ContractNoticeModal (props) {
  const { shipperName, message, shipperId } = props
  const [visible, setVisible] = useState(true)
  const [intl, dispatch, country] = useReduxHelpers()

  function ack () {
    setVisible(false)
    dashApi.sendMessageAck({
      type: message.type,
      version: message.version
    })
    dispatch(MessageCreators.ackMessage(shipperId, fns.getMessageKey(message)))
  }

  const openTC = () => {
    const win = window.open(ROUTES.TERMS_AND_CONDITIONS, '_blank')
    win.focus()
  }

  const contractStartDate = momentUTCWithTimezone(message?.contractStartDate, country).format('YYYY-MM-DD')

  return (
    <MessageModal
      width={NOTICE_MODAL_WIDTH}
      title={<T id='contract_notice_title' prefix='message_management' />}
      onCancel={() => setVisible(false)}
      footer={
        <>
          <StyledButton onClick={openTC} type='default'>
            <T id='notice_read_more' prefix='message_management' />
          </StyledButton>
          <StyledButton onClick={ack} type='primary'>
            <T id='notice_ack' prefix='message_management' />
          </StyledButton>
        </>
      }
      visible={visible}
    >
      <Text id='contract_notice_header' size={18} prefix='message_management' />
      <br />
      <Text
        id='contract_notice_body'
        prefix='message_management'
        style={{ whiteSpace: 'pre-wrap' }}
        values={{
          /* eslint-disable camelcase */
          shipper_name: shipperName,
          contract_start_date: contractStartDate,
          contract_link: (
            <a target='_blank' rel='noopener noreferrer' href={message?.contractLink}>
              <T id='here' />
            </a>
          ),
          support_email: (
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={'mailto:' + intl.formatMessage({ id: 'email.support-email' })}
            >
              <T id='email.support-email' />
            </a>
          )
          /* eslint-enable camelcase */
        }}
      />
    </MessageModal>
  )
}

ContractNoticeModal.propTypes = {
  message: PropTypes.any,
  shipperName: PropTypes.string,
  shipperId: PropTypes.number
}
