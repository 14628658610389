// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { faBox } from '@fa-pro-light/faBox'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { faExclamationTriangle } from '@fa-pro-light/faExclamationTriangle'
import { Col, T } from '@nv/react-commons/src/Components'
import { CircleIcon } from 'components/CircleIcon'
import { Text } from 'components/Text'
import { Vspace } from 'components/Vspace'
import PropTypes from 'prop-types'
import React from 'react'
import { Colors } from 'themes'
import {
  Spacing,
  StyledSection,
  StyledSectionRow,
  StyledSectionSummary,
  StyledTitle
} from 'containers/FPLOrderBatch/styles'
import { ORDER_BATCH_STATUS } from 'containers/FPLOrderBatch/constants'

const OrderSummary = ({
  orderBatchStatus,
  totalCreated,
  totalNeededActions,
  totalOrders,
  totalProcessing,
  orderRequestList
}) => {
  const serviceCode = orderRequestList[0]?.request_body?.service_code ?? ''

  const renderTitleSummary = () => {
    return (
      <StyledSection>
        <StyledTitle color={Colors.greyishBrown} size={18} type='bold' id='order_summary' />
      </StyledSection>
    )
  }

  const renderProcessing = () => {
    if (totalProcessing === 0) return null

    return (
      <StyledSectionRow>
        <Vspace height={68} />
        <Col>
          <Text color={Colors.darkGrey} size={12} type='bold'>
            <T allCaps id='Processing' />
          </Text>
          <Vspace height={4} />
          <Text size={24} type='bold'>
            {totalProcessing}
          </Text>
          <Vspace height={4} />
        </Col>
      </StyledSectionRow>
    )
  }

  return (
    <>
      {renderTitleSummary()}
      <StyledSectionSummary>
        {orderBatchStatus !== ORDER_BATCH_STATUS.ALL_CREATED && (
          <>
            <StyledSectionRow>
              <CircleIcon icon={faBox} />
              <Spacing />
              <Col>
                <Text color={Colors.darkGrey} size={12} type='bold'>
                  <T allCaps id='total_created' />
                </Text>
                <Vspace height={4} />
                <Text size={24} type='bold'>
                  {totalCreated}
                </Text>
                <Vspace height={4} />
              </Col>
            </StyledSectionRow>

            <StyledSectionRow>
              <CircleIcon icon={faExclamationTriangle} />
              <Spacing />
              <Col>
                <Text color={Colors.darkGrey} size={12} type='bold'>
                  <T allCaps id='action_needed' />
                </Text>
                <Vspace height={4} />
                <Text size={24} type='bold'>
                  {totalNeededActions}
                </Text>
                <Vspace height={4} />
              </Col>
            </StyledSectionRow>

            {renderProcessing()}
          </>
        )}
        {orderBatchStatus === ORDER_BATCH_STATUS.ALL_CREATED && (
          <>
            <StyledSectionRow>
              <CircleIcon icon={faBox} />
              <Spacing />
              <Col>
                <Text color={Colors.darkGrey} size={14} type='bold'>
                  <T allCaps id='total_orders' />
                </Text>
                <Vspace height={4} />
                <Text size={24} type='bold'>
                  {totalOrders}
                </Text>
                <Vspace height={4} />
                <Text size={12} color={Colors.pinkishGrey} type='bold'>
                  {totalOrders} {serviceCode}
                </Text>
              </Col>
            </StyledSectionRow>
          </>
        )}
        <StyledSectionRow />
      </StyledSectionSummary>
    </>
  )
}

OrderSummary.propTypes = {
  totalCreated: PropTypes.number,
  totalNeededActions: PropTypes.number,
  totalOrders: PropTypes.number,
  totalCodOrders: PropTypes.number,
  totalCodAmount: PropTypes.number,
  orderBatchStatus: PropTypes.number,
  totalProcessing: PropTypes.number,
  orderRequestList: PropTypes.array
}

export { OrderSummary }
