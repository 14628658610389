import React, { useEffect, useMemo, useState } from 'react'
import { T, NVSteps } from '@nv/react-commons/src/Components'
import { Text } from 'components/Text'
import { StyledCard, HBorder, RedAsterisk, StyledSubStepWrapper } from './styles'
import { Vspace } from 'components/Vspace'
import { StepCard } from './StepCard'
import { ParcelSelection } from './ParcelSelection'
import { ACTION_MODE, FORM_FIELD, NUMBER_OF_PARCEL_OPTIONS_BY_KEY, PARCEL_WEIGHT_OPTIONS_BY_KEY } from './constants'
import PropTypes from 'prop-types'
import { Colors } from 'themes'
import { OCSubStep } from 'components/OCSubStep'
import { RateCard } from './RateCard'
import { transformEstimationRates } from './dataUtils'

const { Step } = NVSteps

const initialState = {
  selectedParcelQuantity: null,
  selectedParcelWeight: null,
  editingField: null
}

export const EstimationCard = ({
  mode,
  onSaveEstimation,
  activeQuantity,
  activeWeight,
  onChangeMode,
  showRate,
  estimationByCountry,
  activeSupportedToCountries,
  activeSupportedFromCountries
}) => {
  const [state, setState] = useState(initialState)
  const { selectedParcelQuantity, selectedParcelWeight, editingField } = state

  const estimationRates = useMemo(() => {
    if (showRate) {
      return transformEstimationRates({ estimationByCountry, activeSupportedToCountries, weight: activeWeight })
    }
    return []
  }, [activeSupportedToCountries, estimationByCountry, activeWeight, showRate])

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      selectedParcelQuantity: activeQuantity,
      selectedParcelWeight: activeWeight
    }))
  }, [activeQuantity, activeWeight])

  const handleSaveSelectedParcelProperty = (value, type) => {
    const newState = {}
    if (type === FORM_FIELD.WEIGHT) {
      newState.selectedParcelWeight = value
    } else {
      newState.selectedParcelQuantity = value
    }
    setState(prevState => ({
      ...prevState,
      ...newState
    }))

    if (mode === ACTION_MODE.CREATE) {
      onSaveEstimation(type, value)
    }
  }

  const handleSaveActiveParcelProperty = type => {
    setState(prevState => ({
      ...prevState,
      editingField: null
    }))
    onChangeMode(ACTION_MODE.DONE)
    onSaveEstimation(type, editingField === FORM_FIELD.WEIGHT ? selectedParcelWeight : selectedParcelQuantity)
  }

  const handleCancelActiveParcelProperty = () => {
    setState(prevState => ({
      ...prevState,
      selectedParcelWeight: activeWeight,
      selectedParcelQuantity: activeQuantity,
      editingField: null
    }))
    onChangeMode(ACTION_MODE.DONE)
  }

  const renderPreviewParcelProperty = type => {
    const label =
      type === FORM_FIELD.WEIGHT
        ? PARCEL_WEIGHT_OPTIONS_BY_KEY[selectedParcelWeight]?.label
        : NUMBER_OF_PARCEL_OPTIONS_BY_KEY[selectedParcelQuantity]?.label
    return (
      <>
        <Text color={Colors.lightGreyBalance} size={12}>
          <T
            id={
              type === FORM_FIELD.WEIGHT
                ? 'international_weight_of_parcel_label'
                : 'international_quantity_of_parcel_label'
            }
          />
          <RedAsterisk />
        </Text>
        <Vspace height={8} />
        <Text color={Colors.balanceTxtBold} size={14} type='bold'>
          {<T id={label} />}
        </Text>
      </>
    )
  }

  const renderActiveParcelOptions = type => {
    if (type === FORM_FIELD.WEIGHT) {
      return (
        <ParcelSelection
          title='international_weight_of_parcel_label'
          selectedOption={selectedParcelWeight}
          options={Object.values(PARCEL_WEIGHT_OPTIONS_BY_KEY)}
          onSave={value => handleSaveSelectedParcelProperty(value, FORM_FIELD.WEIGHT)}
        />
      )
    }
    return (
      <ParcelSelection
        title='international_quantity_of_parcel_label'
        selectedOption={selectedParcelQuantity}
        options={Object.values(NUMBER_OF_PARCEL_OPTIONS_BY_KEY)}
        onSave={value => handleSaveSelectedParcelProperty(value, FORM_FIELD.QUANTITY)}
      />
    )
  }

  const handleEditProperty = type => {
    setState(prevState => ({ ...prevState, editingField: type }))
    onChangeMode(ACTION_MODE.EDIT)
  }

  const renderEstimationContent = () => {
    if (mode === ACTION_MODE.CREATE) {
      return (
        <StyledSubStepWrapper>
          {renderActiveParcelOptions(FORM_FIELD.QUANTITY)}
          <Vspace height={24} />
          {renderActiveParcelOptions(FORM_FIELD.WEIGHT)}
        </StyledSubStepWrapper>
      )
    }
    return (
      <Step
        description={
          <>
            <StepCard
              renderActive={() => renderActiveParcelOptions(FORM_FIELD.QUANTITY)}
              renderPreview={() => renderPreviewParcelProperty(FORM_FIELD.QUANTITY)}
              status={editingField === FORM_FIELD.QUANTITY ? OCSubStep.OPEN : OCSubStep.CLOSE}
              onSave={() => handleSaveActiveParcelProperty(FORM_FIELD.QUANTITY)}
              onEdit={() => handleEditProperty(FORM_FIELD.QUANTITY)}
              onCancel={handleCancelActiveParcelProperty}
            />
            <HBorder />
            <StepCard
              renderActive={() => renderActiveParcelOptions(FORM_FIELD.WEIGHT)}
              renderPreview={() => renderPreviewParcelProperty(FORM_FIELD.WEIGHT)}
              status={editingField === FORM_FIELD.WEIGHT ? OCSubStep.OPEN : OCSubStep.CLOSE}
              onSave={() => handleSaveActiveParcelProperty(FORM_FIELD.WEIGHT)}
              onEdit={() => handleEditProperty(FORM_FIELD.WEIGHT)}
              onCancel={handleCancelActiveParcelProperty}
            />
          </>
        }
      ></Step>
    )
  }

  return (
    <>
      <Text size={14} type='bold'>
        <T id='international_estimatiion_label' />
      </Text>
      <Vspace height={8} />
      <StyledCard>
        {renderEstimationContent()}
        {!!(showRate && estimationRates.length) && (
          <>
            <HBorder bg={Colors.balanceBgDark} />
            <RateCard
              estimationRates={estimationRates}
              activeSupportedToCountries={activeSupportedToCountries}
              activeSupportedFromCountries={activeSupportedFromCountries}
            />
          </>
        )}
      </StyledCard>
    </>
  )
}

EstimationCard.propTypes = {
  mode: PropTypes.instanceOf(ACTION_MODE),
  activeQuantity: PropTypes.string,
  activeWeight: PropTypes.string,
  onSaveEstimation: PropTypes.func,
  onChangeMode: PropTypes.func,
  showRate: PropTypes.bool,
  estimationByCountry: PropTypes.object,
  activeSupportedFromCountries: PropTypes.array,
  activeSupportedToCountries: PropTypes.array
}

EstimationCard.defaultProps = {}
