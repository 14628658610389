/**
 *
 * Vspace
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledVspace = styled.div`
  height: ${props => props.height}px;
  min-height: ${props => props.height}px;
`

const Vspace = props => <StyledVspace {...props} />

Vspace.propTypes = {
  height: PropTypes.number.isRequired
}

Vspace.defaultProps = {}

export { Vspace }
