import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'

const { Types, Creators } = createActions(
  {
    signUpRequest: ['data'],
    signUpSuccess: ['data'],
    signUpFailure: ['error'],
    signUpSetupPending: [],
    clearSignUpSetupPending: [],
    loginRequest: ['data', 'options'],
    loginWithToken: ['data'],
    loginSuccess: ['data'],
    loginFailure: ['error'],
    clearError: [],
    forgotPasswordRequest: ['data', 'option'],
    forgotPasswordSuccess: ['data'],
    forgotPasswordFailure: ['error'],
    resetPasswordPrecheck: ['data'],
    resetPasswordRequest: ['data'],
    resetPasswordSuccess: [],
    resetPasswordFailure: ['error'],
    automaticLogin: [],
    getUserRequest: [],
    getUserSuccess: ['data'],
    getUserFailure: ['error'],
    authSuccess: ['data'],
    authFailure: ['error'],
    authRequest: ['data'],
    logout: [],
    updateLocale: ['locale', 'showNotification'],
    updateEmail: ['email'],
    updateContactNo: ['contactNo'],
    getLinkShippers: ['linkShippers'],
    getUnlinkShippers: ['data'],
    setUnlinkShipperResult: ['payload'],
    clearUnlinkShipperResult: [],
    getSubShipper: ['externalRef'],
    getSubShipperSuccess: ['data'],
    getSubShipperFailure: ['error'],
    updateViewAsExternalRefValue: ['externalRef']
  },
  { prefix: 'Base/user/' }
)

export const userCreators = Creators
export const userTypes = Types

const data = {}
const loading = false
const error = null
const autoLogin = false
const linkShippers = {}
const loginRequested = false

const INITIAL_STATE = fromJS({
  data,
  loading,
  error,
  autoLogin,
  linkShippers,
  loginRequested
})

const request = state => state.merge({ loading: true, error, loginRequested: true })
const success = (state, { data: _data }) => state.merge({ data: _data, loading, error })
const successKeepToken = (state, { data: _data }) => {
  const { accessToken, expireIn } = state.get('data').toJS()
  return state.merge({ data: { accessToken, expireIn, ..._data }, loading, error })
}
const successReplaceTokenOnly = (state, { data: _data }) => {
  const { accessToken, expireIn, ...rest } = state.get('data').toJS()
  return state.merge({ data: { ...rest, ..._data }, loading, error })
}
const requestForgotPassword = state => {
  const d = state.get('data').toJS()
  return state.merge({ data: { ...d, forgotPasswordSent: false }, loading: true, error })
}
const successForgotPassword = (state, { data: _data }) => {
  const d = state.get('data').toJS()
  return state.merge({
    data: { ...d, forgotPasswordSent: true, forgotPasswordData: _data, loading, error }
  })
}
const automaticLogin = state => state.merge({ autoLogin: true })
const failure = (state, { error: _error }) => state.merge({ loading, error: _error })
const clearError = state => state.merge({ loading, error })
const logout = state => state.merge(INITIAL_STATE)
const updateLocale = (state, { locale }) => state.mergeDeep({ data: { locale } })
const updateEmail = (state, { email }) => state.mergeDeep({ data: { email } })
const updateContactNo = (state, { contactNo }) => state.mergeDeep({ data: { contactNo } })
const getLinkShippers = (state, { linkShippers: _linkShippers }) =>
  _linkShippers === undefined ? state : state.merge({ linkShippers: _linkShippers })

const getUnlinkShippers = (state, { data: _data }) => state.mergeDeep({ data: _data, loading, error })
const setUnlinkShipperResult = (state, { payload }) => state.merge({ unlinkShipperResult: { data: payload } })
const clearUnlinkShipperResult = state => state.delete('unlinkShipperResult')
const getSubShipperSuccess = (state, { data: _data }) => state.merge({ subShipper: _data, subShipperError: '' })
const getSubShipperFailure = (state, { error: _error }) => state.merge({ subShipperError: _error })
const updateViewAsExternalRefValue = (state, { externalRef }) => state.merge({ externalRef })

const signUpSetupPending = state => state.merge({ setupPending: true })
const clearSignUpSetupPending = state => state.delete('setupPending')

export const userReducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_UP_REQUEST]: request,
  [Types.SIGN_UP_SUCCESS]: success,
  [Types.SIGN_UP_FAILURE]: failure,
  [Types.LOGIN_REQUEST]: request,
  [Types.LOGIN_SUCCESS]: success,
  [Types.LOGIN_FAILURE]: failure,
  [Types.CLEAR_ERROR]: clearError,
  [Types.FORGOT_PASSWORD_REQUEST]: requestForgotPassword,
  [Types.FORGOT_PASSWORD_SUCCESS]: successForgotPassword,
  [Types.FORGOT_PASSWORD_FAILURE]: failure,
  [Types.RESET_PASSWORD_FAILURE]: failure,
  [Types.AUTOMATIC_LOGIN]: automaticLogin,
  [Types.GET_USER_SUCCESS]: successKeepToken,
  [Types.GET_USER_FAILURE]: failure,
  [Types.AUTH_REQUEST]: request,
  [Types.AUTH_SUCCESS]: successReplaceTokenOnly,
  [Types.AUTH_FAILURE]: failure,
  [Types.LOGOUT]: logout,
  [Types.UPDATE_LOCALE]: updateLocale,
  [Types.UPDATE_EMAIL]: updateEmail,
  [Types.UPDATE_CONTACT_NO]: updateContactNo,
  [Types.GET_LINK_SHIPPERS]: getLinkShippers,
  [Types.GET_UNLINK_SHIPPERS]: getUnlinkShippers,
  [Types.SET_UNLINK_SHIPPER_RESULT]: setUnlinkShipperResult,
  [Types.CLEAR_UNLINK_SHIPPER_RESULT]: clearUnlinkShipperResult,
  [Types.GET_SUB_SHIPPER_SUCCESS]: getSubShipperSuccess,
  [Types.GET_SUB_SHIPPER_FAILURE]: getSubShipperFailure,
  [Types.UPDATE_VIEW_AS_EXTERNAL_REF_VALUE]: updateViewAsExternalRefValue,
  [Types.SIGN_UP_SETUP_PENDING]: signUpSetupPending,
  [Types.CLEAR_SIGN_UP_SETUP_PENDING]: clearSignUpSetupPending
})
