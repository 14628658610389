import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'

const { Types, Creators } = createActions(
  {
    searchRequest: ['key', 'data', 'route', 'navigate', 'reset'],
    setOrdersViewType: ['ordersViewType'],
    clear: []
  },
  { prefix: 'ConnectedSearch/' }
)

export const searchCreators = Creators
export const searchTypes = Types

const INITIAL_STATE = fromJS({
  key: '',
  data: '',
  route: '',
  ordersViewType: 'parentOrders'
})

export const searchReducer = createReducer(INITIAL_STATE, {
  [Types.SEARCH_REQUEST]: (state, {key, data, route, navigate}) => state.merge({key, data, route, navigate}),
  [Types.SET_ORDERS_VIEW_TYPE]: (state, {ordersViewType}) => state.merge({ordersViewType}),
  [Types.CLEAR]: state => state.merge({key: '', data: '', route: ''})
})
