/**
 *
 * Alert
 *
 */

import { Alert } from '@nv/react-commons/src/Components'
import React from 'react'
import styled from 'styled-components'

const StyledAlert = styled(props => <Alert {...props} />)`
  &&& {
    .ant-alert-close-icon {
      top: 8px;
    }
  }
`

export { StyledAlert as Alert }
