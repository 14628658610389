import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import { ConfigProvider } from 'antd'
import { getAntdLocale } from '../../utils/antd'

const _ConfigProvider = props => {
  const intl = props.intl
  const locale = getAntdLocale(intl.locale)

  return <ConfigProvider locale={locale}>{props.children}</ConfigProvider>
}

_ConfigProvider.propTypes = {
  intl: intlShape.isRequired,
  children: PropTypes.node
}

export default injectIntl(_ConfigProvider)
