import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { selectShipper } from 'containers/Base/selectors'

export const useSentryUser = () => {
  const { id: shipperId } = useSelector(selectShipper()) || {}

  useEffect(() => {
    if (shipperId) {
      Sentry.setUser({ id: shipperId.toString() })
    } else {
      Sentry.setUser(null)
    }
  }, [shipperId])
}
