import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import styled, { withTheme } from 'styled-components'
import { StyledBannerRow, StyledTitle, StyledTitleCol, StyledButtonCol, StyledOrderActionButton } from './styles'
import { Col, T, message } from '@nv/react-commons/src/Components'
import { DEBOUNCE_CLICK, ORDER_BATCH_STATUS } from './constants'
import { Colors } from 'themes'
import { RyoFix, RyoHooray, RyoSorry } from './SystemSpecific'
import { PRINT_AWB_MODAL } from 'containers/FPLOrderRequestList/constants'
import { useIntl } from 'hooks/common'
import debounce from 'lodash/debounce'

const Divider = styled.div`
  background-color: ${props => props.color || Colors.solitude};
  height: 1px;
  width: 100%;
`

const OrderBatchCard = ({
  theme: { clientErrorImg, OCSuccessGoodJobLogo },
  showPrintModal,
  orderBatchStatus,
  handleFixing,
  totalProcessing,
  totalOrders,
  totalCreated
}) => {
  const intl = useIntl()
  const validatePrintAwb = () => {
    if (totalOrders === 0) {
      const msg = intl.formatMessage({ id: PRINT_AWB_MODAL.NO_ITEMS_MESSAGE })
      message.error(msg)
      return false
    }

    if (totalOrders > PRINT_AWB_MODAL.LIMIT_ROWS) {
      const msg = intl.formatMessage({ id: PRINT_AWB_MODAL.LIMIT_ROWS_MESSAGE })
      message.error(msg)
      return false
    }

    return true
  }

  const handlePrint = debounce(() => {
    if (validatePrintAwb()) {
      showPrintModal()
    }
  }, DEBOUNCE_CLICK)

  const renderTitleAlert = (idTitle, idContent) => {
    return (
      <>
        <StyledTitle size={24} id={idTitle} />
        <StyledTitle size={16} id={idContent} type='regular' />
      </>
    )
  }

  const renderFixingBtn = () => {
    return (
      <StyledButtonCol>
        <StyledOrderActionButton onClick={handleFixing}>
          <T allCaps id='order_batch_title_button_fixing' />
        </StyledOrderActionButton>
      </StyledButtonCol>
    )
  }

  const renderAllCreatedTitle = () => {
    return (
      <>
        <StyledBannerRow>
          <Col>
            <RyoHooray />
          </Col>
          <StyledTitleCol>{renderTitleAlert('hip_hip_hooray', 'all_orders_created')}</StyledTitleCol>
          <StyledButtonCol>
            <StyledOrderActionButton data-analyticsid='4plPrintAWBs' onClick={handlePrint}>
              <T allCaps id='print_airway_bills' />
            </StyledOrderActionButton>
          </StyledButtonCol>
        </StyledBannerRow>
        <Divider />
      </>
    )
  }

  const renderPartialCreatedCard = () => {
    return (
      <StyledBannerRow>
        <Col>
          <RyoFix />
        </Col>
        {totalProcessing ? (
          <StyledTitleCol>
            <StyledTitle size={24} id='order_batch_processing' />
            <StyledTitle size={16} type='regular'>
              {`${totalCreated}/${totalOrders} (${Math.floor(100 - (totalProcessing / totalOrders) * 100)}%)`}
            </StyledTitle>
          </StyledTitleCol>
        ) : (
          <StyledTitleCol>{renderTitleAlert('order_batch_fixing_title', 'order_batch_fixing_subtitle')}</StyledTitleCol>
        )}
        {!totalProcessing && renderFixingBtn()}
      </StyledBannerRow>
    )
  }

  const renderNoCreatedCard = () => {
    return (
      <StyledBannerRow>
        <Col>
          <RyoSorry />
        </Col>
        <StyledTitleCol>
          {renderTitleAlert('order_batch_all_fail_title', 'order_batch_all_fail_content')}
        </StyledTitleCol>
        {!totalProcessing && renderFixingBtn()}
      </StyledBannerRow>
    )
  }

  if (orderBatchStatus === ORDER_BATCH_STATUS.ALL_CREATED) {
    return renderAllCreatedTitle()
  } else if (orderBatchStatus === ORDER_BATCH_STATUS.PARTIAL_CREATED) {
    return renderPartialCreatedCard()
  } else {
    return renderNoCreatedCard()
  }
}

OrderBatchCard.propTypes = {
  theme: PropTypes.object,
  orderBatchStatus: PropTypes.number,
  totalOrders: PropTypes.number,
  totalProcessing: PropTypes.number,
  totalCreated: PropTypes.number,
  showPrintModal: PropTypes.func,
  handleFixing: PropTypes.func
}

OrderBatchCard.defaultProps = {
  totalProcessing: 0,
  totalCreated: 0
}

const _OrderBatchCard = compose(withTheme)(OrderBatchCard)
export { _OrderBatchCard as OrderBatchCard }
