/**
 *
 * Asynchronously loads the component for ErrorPage
 *
 */

import React from 'react'
import loadable from 'utils/loadable'
import { Loading } from '../Loading'

export const ErrorPage = loadable({
  loader: () => import('./index' /* webpackChunkName: "ErrorPage" */),
  loading: <Loading />
})
