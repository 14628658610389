import { ISO_DATE_FORMAT } from '@nv/react-commons/src/Constants'
import { fromJS } from 'immutable'
import { includes } from 'lodash'
import moment from 'moment'
import { createActions, createReducer } from 'reduxsauce'
import { ORDER } from 'containers/Base/constants'
const { STATUS, GRANULAR_STATUS } = ORDER

const { Creators, Types } = createActions(
  {
    saveFilter: ['data'],
    clearFilter: [],
    saveFilterAndClearOthers: ['data'],

    searchRequest: ['reset', 'size', 'fetchAll'],
    searchRequestInProgress: ['reset'],
    searchRequestSuccess: ['searchResults', 'total'],
    searchRequestFailure: ['error'],
    clearResults: [],
    filterResults: ['ids']
  },
  { prefix: 'Base/orderSearch/' }
)

export const orderSearchCreators = Creators
export const orderSearchTypes = Types

const INITIAL_FILTER_STATE = {
  filter: {
    bulkUploadId: '',
    createdAt: {
      endValue: moment()
        .utc()
        .endOf('day')
        .format(ISO_DATE_FORMAT),
      startValue: moment()
        .utc()
        .add(-1, 'months')
        .startOf('day')
        .format(ISO_DATE_FORMAT)
    },
    fileUpload: { name: '', trackingIds: [] },
    granularStatus: { checkedList: [] },
    stampId: ''
  }
}
const INITIAL_RESULTS_STATE = {
  results: {
    error: null,
    loading: undefined,
    loadingMore: undefined,
    data: {}
  }
}

const INITIAL_STATE = fromJS({
  ...INITIAL_FILTER_STATE,
  ...INITIAL_RESULTS_STATE
})

const saveFilter = (state, { data }) => state.mergeIn(['filter'], data)
const clearFilter = state => state.merge(INITIAL_FILTER_STATE)

const searchRequestInProgress = (state, { reset = true }) => {
  if (reset) {
    return state.mergeIn(['results'], { error: null, loading: true })
  } else {
    return state.mergeIn(['results'], { error: null, loading: true, loadingMore: true })
  }
}
const searchRequestSuccess = (state, { searchResults, total }) => {
  const orders = state.getIn(['results', 'data', 'orders'])
  const newOrders = orders ? orders.concat(searchResults) : searchResults
  return state.mergeDeepIn(['results'], {
    data: { orders: newOrders, total },
    error: null,
    loading: false,
    loadingMore: false
  })
}
const searchRequestFailure = (state, { error }) =>
  state.mergeIn(['results'], { error, loading: false, loadingMore: false })

const clearResults = state => state.mergeIn(['results'], { data: {} })

const filterResults = (state, { ids }) => {
  const orders = state.getIn(['results', 'data', 'orders'])

  const newOrders = orders.map(order => {
    if (includes(ids, order.getIn(['order', 'id']))) {
      return order.mergeIn(['order'], {
        granularStatus: GRANULAR_STATUS.CANCELLED,
        status: STATUS.CANCELLED
      })
    }
    return order
  })

  return state.mergeIn(['results'], { data: { orders: newOrders, total: newOrders.size } })
}

export const orderSearchReducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_FILTER]: saveFilter,
  [Types.CLEAR_FILTER]: clearFilter,
  [Types.SEARCH_REQUEST_IN_PROGRESS]: searchRequestInProgress,
  [Types.SEARCH_REQUEST_SUCCESS]: searchRequestSuccess,
  [Types.SEARCH_REQUEST_FAILURE]: searchRequestFailure,
  [Types.CLEAR_RESULTS]: clearResults,
  [Types.FILTER_RESULTS]: filterResults
})
