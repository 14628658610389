/**
 *
 * BaseHeader
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Layout } from '@nv/react-commons/src/Components'
import { Colors, Metrics } from 'themes'
import { Shadows } from '@nv/react-commons/src/Themes'

const StyledBaseHeader = styled(({ animationType, ...props }) => <Layout.Header {...props} />)`
  && {
    height: ${Metrics.headerHeight}px;
    display: flex;
    justify-content: flex-start;
    background-color: ${Colors.white};
    color: ${Colors.warmGrey};
    padding-left: 0;
    padding-right: 0;
    box-shadow: ${Shadows.light};
    position: sticky;
    width: 100%;
    z-index: 101;
    top: 0;
    align-items: center;
    left: 0;
  }
`

StyledBaseHeader.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node
}

export { StyledBaseHeader as BaseHeader }
