import React from 'react'
import styled from 'styled-components'
import NinjaScooter from 'images/ninja-scooter.png'
import NinjaScooter2x from 'images/ninja-scooter@2x.png'
import NinjaScooter3x from 'images/ninja-scooter@3x.png'
import { PropsWithClassname } from 'types'
import { FormattedMessage } from 'react-intl'
import { OutIcon } from '@nv/react-akira'
import { useMatch } from 'react-router-dom'
import { ROUTES } from 'containers/Base/constants'
import { openWebsiteContactUsLink } from 'utils/auth'

export const AuthHero = ({ className }: PropsWithClassname) => {
  const isSignUp = useMatch(`${ROUTES.UNIFIED_SIGNUP}/*`)

  return (
    <Wrapper className={className}>
      <Heading>
        <FormattedMessage id='ninja_van' />
      </Heading>
      <SubHeading>
        <FormattedMessage id='ninja_van_hero_description' />
      </SubHeading>
      {isSignUp && (
        <HelpText>
          <Link
            onClick={e => {
              e.preventDefault()
              openWebsiteContactUsLink()
            }}
            target='_blank'
          >
            <FormattedMessage id='having_parcel_issues_help_text' />{' '}
            <IconWrapper>
              <OutIcon />
            </IconWrapper>
          </Link>
        </HelpText>
      )}
      <HeroImage
        src={NinjaScooter}
        srcSet={`${NinjaScooter} 1x, ${NinjaScooter2x} 2x, ${NinjaScooter3x} 3x`}
        alt='ninja van mascot, Ryo'
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  z-index: 1;
  color: #fff;
`

const Heading = styled.h1`
  && {
    margin-bottom: 12px;
    font-size: 32px;
  }
`

const SubHeading = styled.h2`
  && {
    max-width: 400px;
    font-size: 24px;
  }
`

const HelpText = styled.div`
  margin-top: 44px;
  max-width: 236px;
  font-size: 14px;
  line-height: 22px;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`

const Link = styled.a`
  color: currentColor;

  &:hover {
    color: currentColor;
  }
`

const IconWrapper = styled.span`
  & > * {
    width: 16px;
    height: 16px;
    display: inline;
  }
`

const HeroImage = styled.img`
  width: 380px;
  height: 322px;
  margin-top: 48px;
  margin-left: auto;
  transform: translateX(80px);
  user-select: none;
`

export const Hero = styled(AuthHero)``
