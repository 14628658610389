import { media } from '@nv/react-commons/src/Themes/Media'
import styled from 'styled-components'
import { Images } from 'images'

export const NVAuthBannerLogo = styled.img.attrs({ src: Images.runningNinja })`
  align-self: flex-end;
  position: absolute;
  max-width: 120px;
  max-height: 120px;
  bottom: 0;
  right: 0;
  ${media.max.tablet`
    margin: 0;
    max-width: 78px;
    max-height: 78px;
  `};
`

export const CDAuthBannerLogo = styled.img.attrs({ src: Images.cdWhite })`
  align-self: flex-end;
  position: absolute;
  max-width: 120px;
  max-height: 120px;
  bottom: 20px;
  right: 20px;
`
