import * as Yup from 'yup'

export const ADD_ACCOUNT_DETAILS_PATH = 'add-account-details'

export const ACCOUNT_DETAILS_MODEL = {
  taxIdentificationNumber: '',
  salesAndServiceTaxNumber: '',
  billingName: '',
  billingStreet: '',
  billingPostalCode: '',
  billingCity: '',
  billingState: ''
}

export const ACCOUNT_DETAILS_SCHEMA = Yup.object({
  taxIdentificationNumber: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9-]*$/, 'tax_number_pattern'),
  salesAndServiceTaxNumber: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9-]*$/, 'tax_number_pattern'),
  billingName: Yup.string()
    .trim()
    .required('billing_name_required'),
  billingStreet: Yup.string()
    .trim()
    .required('billing_street_required'),
  billingPostalCode: Yup.string()
    .trim()
    .required('billing_postal_code_required')
    .matches(/^[0-9]*$/, 'numeric_only'),
  billingCity: Yup.string()
    .trim()
    .required('billing_city_required'),
  billingState: Yup.string()
    .trim()
    .required('billing_state_province_required')
})
