/* eslint-disable max-len */
import PropTypes from 'prop-types'
import React from 'react'

export const CDIcon = ({ width, height, collapsed }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width}
      height={height}
      viewBox='0 0 96 30'
    >
      <defs>
        <linearGradient id='linear-gradient' x1='0.1' y1='19.49' x2='10.08' y2='11.12' gradientUnits='userSpaceOnUse'>
          <stop offset='0' stopColor='#0060a2' />
          <stop offset='0.36' stopColor='#007abe' />
          <stop offset='0.76' stopColor='#0091d6' />
          <stop offset='1' stopColor='#009adf' />
        </linearGradient>
      </defs>
      <title>Chrono Diali</title>
      <g id='Diali' style={collapsed ? { transform: 'translateX(36%) scale(1.2)' } : undefined}>
        <path
          className='cls-3'
          fill='url(#linear-gradient)'
          d='M13.69,10.25a.35.35,0,0,1-.33,0l-.53-.31a.43.43,0,0,1-.11-.12h0a.41.41,0,0,1-.05-.17l0-.62a.36.36,0,0,1,.16-.29L19.21,5l-9-4.9A.83.83,0,0,0,9.86,0a.84.84,0,0,0-.37.08L.5,5l10.08,5.87a.34.34,0,0,1,.16.28v8.56a.35.35,0,0,1-.17.28l-.54.3a.33.33,0,0,1-.17,0h0a.33.33,0,0,1-.17,0L9.14,20A.33.33,0,0,1,9,19.69V12.06a.29.29,0,0,0-.09-.15L0,6.74V16.87a.81.81,0,0,0,.36.62L9.5,22.88a.71.71,0,0,0,.36.09.7.7,0,0,0,.35-.09l9.15-5.39a.83.83,0,0,0,.35-.62V6.74Z'
        />
        {!collapsed && (
          <g>
            <path
              className='cls-2'
              fill='#ffffff'
              d='M32.61,9.2a4.24,4.24,0,0,0-2.36-.61A4.17,4.17,0,0,0,25.87,13a4.09,4.09,0,0,0,4.39,4.32,4.61,4.61,0,0,0,2.43-.62v-2.1a3.43,3.43,0,0,1-2.11.74,2.28,2.28,0,0,1-2.46-2.38,2.21,2.21,0,0,1,2.37-2.35,3.22,3.22,0,0,1,2.12.76Z'
            />
            <path
              className='cls-2'
              fill='#ffffff'
              d='M33.82,17.08h2.24V10.84a4.6,4.6,0,0,1,1.56-.24A1.28,1.28,0,0,1,39.09,12v5.07h2.26V11.86c0-2.45-1.44-3.27-3.24-3.27a6,6,0,0,0-2,.35V5H33.82Z'
            />
            <path
              className='cls-2'
              fill='#ffffff'
              d='M46.2,8.59c-2.16,0-3.56.8-3.56,3.27v5.22h2.25V12a1.29,1.29,0,0,1,1.48-1.41,5.25,5.25,0,0,1,1.56.22V8.73A11.84,11.84,0,0,0,46.2,8.59Z'
            />
            <path
              className='cls-2'
              fill='#ffffff'
              d='M52.14,8.58a4.18,4.18,0,0,0-4.34,4.35,4.35,4.35,0,0,0,8.7,0A4.2,4.2,0,0,0,52.14,8.58Zm0,6.75a2.13,2.13,0,0,1-2.09-2.4,2.11,2.11,0,1,1,4.18,0A2.13,2.13,0,0,1,52.14,15.33Z'
            />
            <path
              className='cls-2'
              fill='#ffffff'
              d='M57.54,17.08h2.24V10.84a5.25,5.25,0,0,1,1.56-.24A1.29,1.29,0,0,1,62.82,12v5.07h2.25V11.86c0-2.47-1.4-3.27-3.56-3.27a12.27,12.27,0,0,0-4,.72Z'
            />
            <path
              className='cls-2'
              fill='#ffffff'
              d='M70.34,8.58A4.19,4.19,0,0,0,66,12.93a4.35,4.35,0,0,0,8.7,0A4.2,4.2,0,0,0,70.34,8.58Zm0,6.75a2.13,2.13,0,0,1-2.08-2.4,2.11,2.11,0,1,1,4.17,0A2.13,2.13,0,0,1,70.34,15.33Z'
            />
            <path
              className='cls-4'
              fill='#009adf'
              d='M79.3,14.94v2.58a2.5,2.5,0,0,0-1-.18,2.77,2.77,0,0,0-2.93,2.93c0,1.88,1.27,2.91,3.2,2.91a9.83,9.83,0,0,0,2.28-.26v-8Zm0,6.89a4,4,0,0,1-.73.06,1.56,1.56,0,0,1-1.73-1.67,1.43,1.43,0,0,1,1.53-1.55,2.08,2.08,0,0,1,.93.18Z'
            />
            <rect className='cls-4' fill='#009adf' x='81.66' y='17.35' width='1.5' height='5.71' />
            <path
              className='cls-4'
              fill='#009adf'
              d='M89.27,23.17V17.88A5.84,5.84,0,0,0,87,17.34c-1.93,0-3.21,1-3.21,2.91a2.77,2.77,0,0,0,2.93,2.93A2.55,2.55,0,0,0,87.77,23v.17Zm-1.5-1.49a2.27,2.27,0,0,1-.93.17,1.42,1.42,0,0,1-1.53-1.55A1.56,1.56,0,0,1,87,18.63a1.53,1.53,0,0,1,.73.22Z'
            />
            <rect className='cls-4' fill='#009adf' x='90.13' y='14.94' width='1.5' height='8.13' />
            <rect className='cls-4' fill='#009adf' x='92.49' y='17.35' width='1.5' height='5.71' />
            <circle className='cls-4' fill='#009adf' cx='82.41' cy='15.69' r='0.75' />
            <circle className='cls-4' fill='#009adf' cx='93.24' cy='15.69' r='0.75' />
          </g>
        )}
      </g>
    </svg>
  )
}

CDIcon.propTypes = {
  collapsed: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number
}

CDIcon.defaultProps = {
  collapsed: false,
  height: 30,
  width: '100%'
}
