import React, { useEffect, useState } from 'react'
import mixpanel from 'mixpanel-browser'
import PropTypes from 'prop-types'
import { useMixpanelGeneralPropertiesTracker } from './hooks'
import Config from 'configs'
import { selectShipper } from 'containers/Base/selectors'
import { useSelector } from 'react-redux'

const mixpanelToken = Config.MIXPANEL_TOKEN

export const MixpanelInitializer = ({ children }) => {
  const [initialized, setInitialized] = useState(false)
  const shipper = useSelector(selectShipper())

  const { id: shipperId } = shipper || {}

  useEffect(() => {
    // initilize mixpanel
    if (mixpanelToken) {
      mixpanel.init(mixpanelToken)
      // if user is already logged in, identify
      if (shipperId) {
        mixpanel.identify(shipperId)
      }
      setInitialized(true)
    }
  }, [shipperId])

  return initialized ? <MixpanelGeneralEventsWrapper>{children}</MixpanelGeneralEventsWrapper> : children
}

MixpanelInitializer.propTypes = {
  children: PropTypes.node
}

export const MixpanelGeneralEventsWrapper = ({ children }) => {
  useMixpanelGeneralPropertiesTracker()
  return children
}
