import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { T } from '@nv/react-commons/src/Components'
import { CenterContainer, StyledButton, StyledButtonGroup, StyledButtonWrapper } from './styles'
import { Vspace } from 'components/Vspace'
import { ServiceDetailCard } from './ServiceDetailCard'
import { EstimationCard } from './EstimationCard'
import { ACTION_MODE } from './constants'
import { Checkbox } from 'antd'
import { Text } from 'components/Text'
import Config from 'configs'
import { Colors } from 'themes'
import { ConfirmButtonGroup } from './ConfirmButtonGroup'

const initialState = {
  serviceDetailMode: ACTION_MODE.CREATE,
  estimationMode: ACTION_MODE.CREATE,
  confirmShowRate: false,
  formData: {
    signedTerm: false,
    supportedToCountries: [],
    unsupportedToCountries: [],
    weight: null,
    quantity: null
  }
}

export const OnboardingForm = ({
  onSaveForm,
  allFromCountries,
  selectedFromCountries,
  allToCountries,
  selectedToCountries,
  supportedToCountries,
  unsupportedToCountries,
  activeSupportedToCountries,
  activeUnsupportedToCountries,
  activeWeight,
  activeQuantity,
  onCreatePartner,
  isLoading,
  estimationByCountry
}) => {
  const [state, setState] = useState(initialState)
  const { estimationMode, serviceDetailMode, formData, confirmShowRate } = state

  const disabledNextButton = useMemo(() => {
    return !selectedFromCountries.length || !activeSupportedToCountries.length
  }, [selectedFromCountries, activeSupportedToCountries])

  const disabledShowEstimationButton = useMemo(() => {
    return disabledNextButton || !activeWeight || !activeQuantity
  }, [activeQuantity, activeWeight, disabledNextButton])

  const showRate = useMemo(() => {
    return !disabledShowEstimationButton && serviceDetailMode === ACTION_MODE.DONE && confirmShowRate
  }, [disabledShowEstimationButton, serviceDetailMode, confirmShowRate])

  const updateFormData = updatedServiceData => {
    onSaveForm(updatedServiceData)
  }

  const handleSaveServiceDetailForm = (type, data) => {
    const updatedServiceData = {
      ...formData,
      supportedToCountries: data.selectedSupportedToCountries,
      unsupportedToCountries: data.selectedUnsupportedToCountries
    }
    setState(prevState => ({
      ...prevState,
      formData: updatedServiceData
    }))

    if (serviceDetailMode !== ACTION_MODE.DONE) {
      setState(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          estimationMode: ACTION_MODE.CREATE,
          serviceDetailMode: ACTION_MODE.DONE
        }
      }))
      updateFormData(updatedServiceData)
    }
  }

  const handleNext = () => {
    updateFormData(formData)
    setState(prevState => ({
      ...prevState,
      estimationMode: ACTION_MODE.CREATE,
      serviceDetailMode: ACTION_MODE.DONE
    }))
  }

  const handleShowEstimationRate = () => {
    setState(prevState => ({
      ...prevState,
      estimationMode: ACTION_MODE.DONE,
      serviceDetailMode: ACTION_MODE.DONE,
      confirmShowRate: true
    }))
    onSaveForm(formData)
  }

  const handleChangeMode = (newMode, serviceType) => {
    if (serviceType === 'service') {
      setState(prevState => ({ ...prevState, serviceDetailMode: newMode }))
    } else {
      setState(prevState => ({ ...prevState, estimationMode: newMode }))
    }
  }

  const handleSaveEstimation = (type, data) => {
    setState(prevState => ({ ...prevState, formData: { ...prevState.formData, [type]: data } }))
    onSaveForm({ ...formData, [type]: data })
  }

  const handleSignedTermAndCondition = e => {
    const isSigned = e.target.checked
    setState(prevState => ({
      ...prevState,
      formData: { ...prevState.formData, signedTerm: isSigned }
    }))
    onSaveForm({ ...formData, signedTerm: isSigned })
  }

  const checkDisabledConfirmButton = () => {
    return (
      !selectedFromCountries.length ||
      !activeSupportedToCountries.length ||
      !activeWeight ||
      !activeQuantity ||
      !formData.signedTerm
    )
  }

  return (
    <>
      <ServiceDetailCard
        onSave={handleSaveServiceDetailForm}
        origin={{
          allCountries: allFromCountries,
          selectedCountries: selectedFromCountries
        }}
        destination={{
          allCountries: allToCountries,
          selectedCountries: selectedToCountries,
          supportedCountries: supportedToCountries,
          unsupportedCountries: unsupportedToCountries,
          activeSupportedCountries: activeSupportedToCountries,
          activeUnsupportedCountries: activeUnsupportedToCountries
        }}
        onChangeMode={newMode => handleChangeMode(newMode, 'service')}
        mode={serviceDetailMode}
      />

      {serviceDetailMode !== ACTION_MODE.CREATE && activeSupportedToCountries.length ? (
        <>
          <Vspace height={24} />
          <EstimationCard
            onEditting={handleChangeMode}
            mode={estimationMode}
            activeWeight={activeWeight}
            activeQuantity={activeQuantity}
            onSaveEstimation={handleSaveEstimation}
            onChangeMode={newMode => handleChangeMode(newMode, 'estimation')}
            showRate={showRate}
            estimationByCountry={estimationByCountry}
            activeSupportedFromCountries={selectedFromCountries}
            activeSupportedToCountries={activeSupportedToCountries}
          />
        </>
      ) : null}

      <Vspace height={16} />

      <StyledButtonGroup>
        <StyledButtonWrapper>
          {serviceDetailMode === ACTION_MODE.CREATE && (
            <CenterContainer>
              <StyledButton onClick={handleNext} type='primary' size='medium' disabled={disabledNextButton}>
                <T id='next' />
              </StyledButton>
            </CenterContainer>
          )}
          {serviceDetailMode === ACTION_MODE.DONE && estimationMode !== ACTION_MODE.DONE && (
            <CenterContainer>
              <StyledButton
                onClick={handleShowEstimationRate}
                type='primary'
                size='medium'
                disabled={disabledShowEstimationButton}
              >
                <T id='international_show_estimation' />
              </StyledButton>
            </CenterContainer>
          )}
        </StyledButtonWrapper>
      </StyledButtonGroup>

      {confirmShowRate && (
        <CenterContainer>
          <Checkbox onChange={handleSignedTermAndCondition}>
            <span>
              <Text inline size={14}>
                <T id='international_agree_registration' />
              </Text>
              &nbsp;
              <a href={Config.FPL_TERM_AND_CONDITION_LINK} target='_blank' rel='noopener noreferrer'>
                <T id='international_terms_and_conditions' color={Colors.cherryRed} />
              </a>
              &nbsp;
              <Text inline size={14}>
                <T id='international_agree_send_email' />
              </Text>
            </span>
          </Checkbox>
        </CenterContainer>
      )}

      {confirmShowRate && (
        <>
          <Vspace height={24} />
          <CenterContainer>
            <ConfirmButtonGroup
              onStartSettingPartner={onCreatePartner}
              disabled={checkDisabledConfirmButton()}
              isLoading={isLoading}
            />
          </CenterContainer>
        </>
      )}
    </>
  )
}

OnboardingForm.propTypes = {
  onSaveForm: PropTypes.func,
  onCreatePartner: PropTypes.func,
  defaultOriginCountry: PropTypes.string,
  allToCountries: PropTypes.array,
  allFromCountries: PropTypes.array,
  selectedToCountries: PropTypes.array,
  selectedFromCountries: PropTypes.array,
  supportedToCountries: PropTypes.array,
  unsupportedToCountries: PropTypes.array,
  activeSupportedToCountries: PropTypes.array,
  activeUnsupportedToCountries: PropTypes.array,
  activeWeight: PropTypes.string,
  activeQuantity: PropTypes.string,
  isLoading: PropTypes.bool,
  estimationByCountry: PropTypes.object
}

OnboardingForm.defaultProps = {
  supportedToCountries: [],
  unsupportedToCountries: []
}
