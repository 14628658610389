/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
export const TEMPLATE_1002 = `^XA
^CI28^LH80,0
^FWB

^FX Customer Info
^FX Receipient Name
^FO0,0
^FB1200,10,12,C
^CFA,28
^FD{{recipient_name}}^FS

^FX Address 1
^FO40,0
^FB1200,10,12,C
^CFA,28
^FD{{address1}}^FS

^FX Address 2
^FO80,0
^FB1200,10,12,C
^CFA,28
^FD{{address2}}^FS

^FX Postcode
^FO120,0
^FB1200,10,12,C
^CFA,28
^FD{{postcode}}^FS

^FX Contact
^FO160,0
^FB1200,10,12,C
^CFA,28
^FD{{contact}}^FS

^FX Shipper Info
^FO280,0
^FB780,10,10
^CFA,28
^FD SHIPPER: {{shipper_name}}^FS 

^FX Cod Information
^FO310,0
^FB780,10,10
^CFA,28
^FDCOD: {{cod}}^FS

^FX Parcel Description
^FO340,0
^FB780,10,10
^CFA,28
^FDPARCEL DESCRIPTION:^FS

^FO370,0
^FB780,5,10
^CFA,28 ^FD{{parcel_description[limit:204]}}^FS

^FX QR Code Tracking ID
^FO230,800
^BQN,2,10
^FDQA,{{barcode}}
^FS

^FX End of QR Code Tracking ID 
^FX Tracking ID Text
^FO{{barcode_offset_x}},{{barcode_offset_y}}
^CFA,28
^FD{{tid}}^FS

^FX Ninjavan Logo
^FO560,200
^GFA,1608,1608,8,,:::::::::K03IFE,K07IFE,K0JFE,:J01JFE,:J01FC,J01F8,J01F,K0F,K0F8,K078,K07F,J01JFE,::::K0JFE,,:::L0IFE,K03IFE,K07IFE,K0JFE,:J01JFE,J01F9C3C,J01F1C1C,J01F1E1E,::J01F1E1F,J01F1F3E,K0F8FFE,:K0F0FFE,K0607FC,M03F8,N0E,K08,J01E,J01FC,J01FF,J01FFE,J01IF8,K0JF,K07IFC,L0IFE,L01FFE,M03FE,M01FE,M07FE,L07FFE,K03IFE,K0JF8,J01IFE,J01IF,J01FFC,J01FE,J01F8,J01C,,::L03FFE,K01IFE,K07IFE,K0JFE,:J01JFE,J01F9C78,J01F1C3C,J01F1C1E,J01F1E1E,::J01F1F3E,K0F8FFE,::K0707FC,K0203F8,M01E,,::001F0LF,003F0LFC,003F8LFE,:003F0MF,001F0MF,I0CL07F,P01F,::,K01IFE,K07IFE,K0JFE,:J01JFE,:J01F8,J01F,:K0F,K0F8,K078,K03IFC,K0JFE,:::::,:::001E0JFE,003F0JFE,003F8JFE,:003F0JFE,001F0JFE,I0E,,:::K01IFE,K07IFE,K0JFE,:J01JFE,:J01F8,J01F,:K0F,K0F8,K078,K03IFC,K0JFE,:::::,::::I01F,I0IF,001E07C,0038F1FI07FC,00673CF8007FC,00EE1E7E00FFE,00DC4F3F019FF,019C6FBFC11FF,01BCEF9FF10FF8,01BC0FDFFC0FF8,01BC0FDIF87FC,01BC0FDIFC7FC,01BC0FDFFE0C18,01BC4F9FF8081,01BC6F9FE1983,01DCEFBF80902,00CE0F3F00F06,00E61E7C007FC,0073F8FI07F8,003803E,001F1F8,I07FE,,::::::::::^FS
^FWN

^XZ`

export const TID_OFFSET_Y_9_10 = 815
export const TID_OFFSET_Y_11_12 = 800
export const TID_OFFSET_Y_13_14 = 790
export const TID_OFFSET_Y_15_16 = 780
export const TID_OFFSET_Y_OTHER = 770
export const TID_OFFSET_X_9_12 = 460
export const TID_OFFSET_X_13_15 = 480
export const TID_OFFSET_X_OTHER = 500
