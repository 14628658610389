/**
 *
 * RouteModal
 *
 */

import _ from 'lodash'
import React from 'react'
import { Modal } from '../Modal'
import { Routes, Route, useNavigate } from 'react-router-dom'

export const RouteModal = props => {
  const { onCancel } = props
  const navigate = useNavigate()
  const handleCancel = () => {
    if (_.isFunction(onCancel)) {
      onCancel()
      return
    }
    navigate(-1)
  }

  const renderModal = () => {
    return <Modal {...props} visible onCancel={handleCancel} />
  }

  return (
    <Routes>
      <Route path={props.path} element={renderModal()} />
    </Routes>
  )
}

RouteModal.defaultProps = {
  path: 'modal'
}
