/**
 *
 * AddressText
 *
 */

import { T } from '@nv/react-commons/src/Components'
import { Tag } from 'antd'
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Preview } from '../Preview'

const AddressText = ({ name, isMilkRun, contact, address1, address2 }) => (
  <Preview
    title={
      <>
        {_.compact([name, contact]).join(', ')}
        {isMilkRun && (
          <Tag style={{ marginLeft: 8 }}>
            <T id='milk_run' />
          </Tag>
        )}
      </>
    }
    description={_.compact([address2, address1]).join(' ')}
  />
)

AddressText.propTypes = {
  isMilkRun: PropTypes.bool,
  name: PropTypes.string,
  contact: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string
}

export { AddressText }
