import React, { useContext, useEffect, useMemo, useState } from 'react'
import { T, Tooltip } from '@nv/react-commons/src/Components'
import { Button } from 'components/Button'
import { faArrowLeft } from '@fa-pro-light/faArrowLeft'
import { useIntl } from 'hooks/common'
import {
  CREATION_METHODS,
  CURRENY_COUNTRY_LABELS,
  GOODS_CURRENCY_DATA,
  INPUT_METHODS,
  ORDER_CREATE_SOURCE
} from '../FPLOrderCreate/constants'
import { HeaderOrderStep, LeftBlock, StyledButton, StyledFlexBlock } from '../FPLOrderCreate/styles'
import { StyledCard } from '../KontentBanner/styles'
import { Hspace } from '../../components/Hspace'
import { BulkUploadZone } from './BulkUploadZone'
import { aggregateBoxFromUploadFile, isB2BBoxes, validateUploadedData } from './utils'
import { FileUploadProps } from './types'
import { UploadResult } from './UploadResult'
import { ReviewOrdersModal } from './ReviewOrderModal'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { FILE_UPLOAD_STATUS, MMCC_OC_STEP, CCType } from './constants'
import { PreviewStepInfo } from './PreviewStepInfo'
import { faBoxes } from '@fa-pro-light/faBoxes'
import { mpTrackAddedIntlOrderDetail, mpTrackUploadFile } from 'containers/FPLMixpanel/helpers'
import { flatMap, uniq } from 'lodash'
import { MP_ORDER_TYPE, TRACK_ADDED_INTL_ORDER_DETAIL } from 'containers/FPLMixpanel/constants'

const initialState = {
  visibleViewDetail: false,
  numberValidItemOfValidOrder: 0,
  numberValidItemOfInvalidOrder: 0,
  numberInvalidItemOfInvalidOrder: 0
}

const FileUpload = ({ destinationCountry, onSaveOrders, onProcessOrders, ccType }: FileUploadProps) => {
  const [state, setState] = useState(initialState)
  const { visibleViewDetail } = state
  const intl = useIntl()
  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const {
    fileUploadStatus,
    boxOrders,
    allOrders,
    validOrders,
    invalidOrders,
    validRows,
    invalidRows,
    fileName,
    numberOfValidItemsInValidOrders,
    numberOfInvalidItemsInInvalidOrders,
    numberOfValidItemsInInvalidOrders
  } = ocState

  const currency = useMemo(() => {
    return CURRENY_COUNTRY_LABELS[destinationCountry] || GOODS_CURRENCY_DATA.USD
  }, [destinationCountry])

  const correctOrders = useMemo(() => {
    if (isB2BBoxes(ccType)) {
      return validRows ? Object.values(validRows) : []
    }
    return validOrders ? Object.values(validOrders) : []
  }, [validOrders, validRows, ccType])

  const isNoValidOrder = useMemo(() => !correctOrders.length, [correctOrders])

  const errorOrders = useMemo(() => {
    if (isB2BBoxes(ccType)) {
      return invalidRows ? Object.values(invalidRows) : []
    }
    return invalidOrders ? Object.values(invalidOrders) : []
  }, [invalidOrders, invalidRows, ccType])

  const totalValuesInPreviewOrder = useMemo(() => {
    const { totalBox, totalGoodsValue } = aggregateBoxFromUploadFile(boxOrders, ccType)
    return {
      totalBox,
      totalGoodsValue
    }
  }, [boxOrders, ccType])

  useEffect(() => {
    setState(prevState => ({ ...prevState, isUploadResult: false }))
  }, [destinationCountry])

  const trackUploadedResult = (validOrdersInSummary, invalidOrdersInSummary, errorMessages) => {
    mpTrackUploadFile({
      result: 'Success',
      numberOfValidOrders: validOrdersInSummary,
      numberOfInvalidOrders: invalidOrdersInSummary,
      totalOrders: validOrdersInSummary + invalidOrdersInSummary,
      errorMessage: errorMessages.map(id => intl.formatMessage({ id })).join(', '),
      orderType: ccType === CCType.B2B ? MP_ORDER_TYPE.MMCC_B2B : MP_ORDER_TYPE.MMCC_B2C
    })
  }

  const handleCheckOrders = data => {
    const {
      allOrders: aOrders,
      validItemisedOrders: vOrders,
      invalidItemisedOrders: inOrders,
      invalidRows: inRows,
      validRows: vRows,
      numberOfValidItemsInValidOrders: noOfValidItemsInValidOrders,
      numberOfInvalidItemsInInvalidOrders: noOfInvalidItemsInInvalidOrders,
      numberOfValidItemsInInvalidOrders: noOfValidItemsInInvalidOrders
    } = validateUploadedData({
      rows: data.data,
      ccType,
      country: destinationCountry
    })
    const allErrors = uniq(flatMap(inOrders, orderRow => flatMap(orderRow, 'errors')).filter(Boolean))

    trackUploadedResult(Object.keys(vOrders).length, Object.keys(inOrders).length, allErrors)

    const uploadedFileName = data.filename
    onProcessOrders(
      {
        allOrders: aOrders,
        validRows: vRows,
        invalidRows: inRows,
        validItemisedOrders: vOrders,
        invalidItemisedOrders: inOrders,
        numberOfValidItemsInValidOrders: noOfValidItemsInValidOrders,
        numberOfInvalidItemsInInvalidOrders: noOfInvalidItemsInInvalidOrders,
        numberOfValidItemsInInvalidOrders: noOfValidItemsInInvalidOrders
      },
      uploadedFileName
    )
    updateOCState({ fileUploadStatus: FILE_UPLOAD_STATUS.UPLOADED })
  }

  const handleVisibleViewDetail = () => {
    if (!errorOrders.length) return

    setState(prevState => ({ ...prevState, visibleViewDetail: !prevState.visibleViewDetail }))
  }

  const handleClickBackButton = () => {
    updateOCState({ fileUploadStatus: FILE_UPLOAD_STATUS.READY_TO_UPLOAD })
  }

  const handleClickReplaceFile = () => {
    updateOCState({
      fileUploadStatus: FILE_UPLOAD_STATUS.READY_TO_UPLOAD,
      currentStep: MMCC_OC_STEP.ORDERS,
      boxOrders: []
    })
  }

  const handleSaveValidOrders = () => {
    if (isNoValidOrder) return
    onSaveOrders()
    updateOCState({
      fileUploadStatus: FILE_UPLOAD_STATUS.IN_PREVIEW,
      currentStep: MMCC_OC_STEP.COMMERCIAL_INVOICE
    })
    const { PROPS } = TRACK_ADDED_INTL_ORDER_DETAIL
    mpTrackAddedIntlOrderDetail({
      [PROPS.METHOD]: CREATION_METHODS[ORDER_CREATE_SOURCE.CSV],
      [PROPS.NUMBER_OF_VALID_ORDER]: correctOrders.length,
      [PROPS.NUMBER_OF_INVALID_ORDER]: errorOrders.length,
      [PROPS.TOTAL_ORDERS]: correctOrders.length + errorOrders.length,
      [PROPS.ORDER_TYPE]: isB2BBoxes(ccType) ? MP_ORDER_TYPE.MMCC_B2B : MP_ORDER_TYPE.MMCC_B2C
    })
  }

  const handleVisibleModal = () => {
    setState(prevState => ({ ...prevState, visibleViewDetail: !prevState.visibleViewDetail }))
  }

  const handleEditStep = () => {
    updateOCState({ fileUploadStatus: FILE_UPLOAD_STATUS.UPLOADED })
  }

  const uploadedData = useMemo(
    () => ({
      allOrders,
      invalidOrders: invalidRows,
      validOrders: validRows
    }),
    [allOrders, invalidRows, validRows]
  )

  const renderContinueWithValidOrders = () => {
    const compProps = {
      disabled: isNoValidOrder,
      onClick: handleSaveValidOrders
    }

    if (isNoValidOrder) {
      return (
        <Tooltip title={intl.formatMessage({ id: 'international_enable_continue_message' })} placement='topRight'>
          <StyledFlexBlock className='mt-4'>
            <StyledButton {...compProps}>
              <T id='international_continue_with_valid_orders' values={{ x: correctOrders.length }} allCaps />
              &nbsp;
              <T id={correctOrders.length > 1 ? 'boxes' : 'box'} allCaps />
            </StyledButton>
          </StyledFlexBlock>
        </Tooltip>
      )
    }
    return (
      <StyledFlexBlock className='mt-4'>
        <StyledButton {...compProps}>
          <T id='international_continue_with_valid_orders' values={{ x: correctOrders.length }} allCaps />
          &nbsp;
          <T id={correctOrders.length > 1 ? 'boxes' : 'box'} allCaps />
        </StyledButton>
      </StyledFlexBlock>
    )
  }

  return (
    <>
      {fileUploadStatus === FILE_UPLOAD_STATUS.READY_TO_UPLOAD && (
        <StyledCard data-testid='upload' hoverable={false}>
          <HeaderOrderStep>
            <T id='upload_file' />
          </HeaderOrderStep>
          <BulkUploadZone ccType={ccType} onSave={handleCheckOrders} />
        </StyledCard>
      )}
      {fileUploadStatus === FILE_UPLOAD_STATUS.UPLOADED && (
        <StyledCard data-testid='upload' hoverable={false}>
          <HeaderOrderStep>
            <T id='international_upload_result' />
          </HeaderOrderStep>
          <LeftBlock>
            <Tooltip title={intl.formatMessage({ id: 'international_reupload_message' })}>
              <Button size='small' icon={faArrowLeft} onClick={handleClickBackButton}>
                <T id='upload_file' allCaps />
              </Button>
            </Tooltip>
          </LeftBlock>
          <Hspace width={16} />
          <UploadResult
            validOrders={correctOrders}
            invalidOrders={errorOrders}
            numberOfValidItemsInValidOrders={numberOfValidItemsInValidOrders}
            numberOfValidItemsInInvalidOrders={numberOfValidItemsInInvalidOrders}
            numberOfInvalidItemsInInvalidOrders={numberOfInvalidItemsInInvalidOrders}
            fileName={fileName}
            ccType={ccType}
            onVisibleViewDetail={handleVisibleViewDetail}
            onReUpload={handleClickReplaceFile}
          />
        </StyledCard>
      )}
      {fileUploadStatus === FILE_UPLOAD_STATUS.UPLOADED && renderContinueWithValidOrders()}
      {visibleViewDetail && (
        <ReviewOrdersModal dataSource={uploadedData} onClose={handleVisibleModal} ccType={ccType} />
      )}
      {fileUploadStatus === FILE_UPLOAD_STATUS.IN_PREVIEW && (
        <PreviewStepInfo
          quantity={totalValuesInPreviewOrder.totalBox}
          unit={currency}
          creationMethod={INPUT_METHODS.FILE}
          totalValue={totalValuesInPreviewOrder.totalGoodsValue}
          translatedKeys={{
            titleKey: 'international_total_boxes',
            totalPluralKey: 'boxes',
            totalSingularKey: 'box',
            totalValueKey: 'total_goods_value',
            creationMethodKey: 'international_creation_method'
          }}
          icon={faBoxes}
          onEditStep={handleEditStep}
        />
      )}
    </>
  )
}

export { FileUpload }
