import { createApi } from 'services/utils'
import _ from 'lodash'
import { COUNTRIES } from '@nv/react-commons/src/Constants/Country'
import { OrdersAwbPayload, BatchAwbPayload } from './types'

const waybillApi = _.mapValues(COUNTRIES, country => createApi({ country, service: 'waybill' }))

const generateAWBByTrackingIDs = (
  country: string,
  { trackingIds, templateType, hideShipperDetails, page, hideCod }: OrdersAwbPayload
) => {
  const awbPayload = {
    trackingIds,
    templateType,
    hideShipperDetails,
    page,
    hideCod
  }
  if (hideCod) {
    awbPayload.hideCod = hideCod
  }
  return waybillApi[country].post('/external/waybills', awbPayload)
}

const generateAWBByBatchId = (
  country: string,
  { batchId, templateType, hideShipperDetails, page }: BatchAwbPayload
) => {
  return waybillApi[country].post(`external/batches/${batchId}/waybills`, {
    templateType,
    hideShipperDetails,
    page
  })
}

const getAWB = (country: string, taskId: string) => waybillApi[country].get(`/external/waybills/${taskId}`)

export default {
  waybillApi,
  generateAWBByTrackingIDs,
  generateAWBByBatchId,
  getAWB
}
