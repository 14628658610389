import _ from 'lodash'

const SHIPPER_ID = 'Shipper Id'
const LEGACY_SHIPPER_ID = 'Legacy Shipper Id'
const SALES_CHANNEL = 'Sales Channel'

export const Source = {
  PAYMENT: 'Payments Screen'
}

export const EntryPoint = {
  HOMEPAGE: 'Homepage',
  SIDEBAR: 'Sidebar'
}

export const INPUT_TYPE = {
  BULK: 'Bulk',
  MANUAL: 'Manual'
}

export const GeneralEvents = {
  VISITED_PAGE: {
    name: 'Visited Page',
    props: {
      PRODUCT: 'Product',
      PAGE_NAME: 'Page Name',
      PAGE_VARIANT: 'Page Variant',
      TRAFFIC_SOURCE_URL: 'Traffic Source URL',
      DEVICE: 'Device',
      APP_VERSION: 'App Version',
      SHIPPER_ID,
      LEGACY_SHIPPER_ID
    }
  },
  OPENED_APP_FOR_FIRST_TIME: {
    name: 'Opened App for the First Time',
    props: {
      FIRST_OPEN: 'First Open'
    }
  },
  LOGGED_IN: {
    name: 'Logged In',
    props: {
      LOGIN_METHOD: 'Login Method',
      SALES_CHANNEL,
      APP_VERSION: 'App Version',
      SHIPPER_ID,
      LEGACY_SHIPPER_ID
    }
  },
  LOGGED_OUT: { name: 'Logged Out', props: { LOG_OUT: 'Log Out' } },
  OPENED_LIVE_CHAT: { name: 'Opened Live Chat', props: { OPEN_LIVE_CHAT: 'Open Live Chat' } }
}

export const AccountCreationEvents = {
  ADDED_SIGNUP_DETAILS: {
    name: 'Added Sign Up Details',
    props: {
      SHIPPER_ID,
      LEGACY_SHIPPER_ID,
      EMAIL_ADDRESS: 'Email Address',
      COUNTRY: 'Country',
      SHIPPER_CONTACT_NUMBER: 'Shipper Contact Number',
      SHIPPER_BUSINESS_NAME: 'Shipper Business Name',
      PASSWORD_SET: 'Password Set',
      METHOD: 'method'
    }
  },
  ADDED_PRO_SIGNUP_DETAILS: {
    name: 'Added Pro Sign Up Details',
    props: {
      SHIPPER_ID,
      EMAIL_ADDRESS: 'Email Address',
      COUNTRY: 'Country',
      SHIPPER_CONTACT_NUMBER: 'Shipper Contact Number',
      SHIPPER_BUSINESS_NAME: 'Shipper Business Name',
      MONTHLY_VOLUME: 'Monthly volume',
      SALES_CHANNEL,
      INDUSTRY: 'industry'
    }
  },
  RESET_VERIFICATION_EMAIL: {
    name: 'Reset Verification Email',
    props: {
      NUMBER_OF_RESEND_VERIFICATION_EMAIL_ATTEMPTS: '# of Resend Verification Email Attempts'
    }
  },
  COMPLETED_ACCOUNT_CREATION: {
    name: 'Completed Account Creation',
    props: {
      ACCOUNT_CREATED_DATE: 'Account Created Date'
    }
  },
  COMPLETED_TERMS_AND_CONDITIONS: {
    name: 'Completed Terms and Conditions',
    props: {
      TERMS_AND_CONDITIONS: 'Terms and Conditions'
    }
  },
  SUBMITTED_SIGNUP: {
    name: 'Submitted Signup',
    props: {
      METHOD: 'method'
    }
  },
  ADDED_REFERRER_NUMBER: {
    name: 'Added referrer number',
    props: {
      REFERRER_NUMBER: 'Referrer number'
    }
  },
  ADDED_BUSINESS_DETAILS: {
    name: 'Added Business Details',
    props: {
      SHIPPER_NAME: 'Shipper Name',
      SHIPPER_CONTACT_NUMBER: 'Shipper Contact Number',
      SHIPPER_BUSINESS_NAME: 'Shipper Business Name',
      ADDED_BUSINESS_DETAILS_DATE: 'Added Business Details Date'
    }
  },
  ADDED_KYC: {
    name: 'Added KYC on Web',
    props: {
      ADDED_KYC_DATE: 'Added KYC on Web Date'
    }
  },
  FIRST_SIGNUP_PAGE_VISIT: {
    name: 'First Sign Up Page Visit'
  }
}

export const BankAccountEvents = {
  ADDED_BANK_ACCOUNT_DETAILS: {
    name: 'Added Bank Account Details'
  }
}

export const ScheduledReportEvents = {
  DELETE_SCHEDULED_REPORT: {
    name: 'Delete Scheduled Report'
  },
  GENERATE_NEW_REPORT: {
    name: 'Scheduled New Report',
    props: {
      REPORT_TYPE: 'Report Type',
      ORDER_STATUS: 'Order Status Filter Selected',
      SERVICE_LEVEL: 'Service Level Filter Selected',
      PARCEL_STATUS: 'Parcel Status Filter Selected',
      FREQUENCY: 'Frequency of Scheduled Report',
      DAY_SELECTED: 'Day Selected',
      NO_EMAIL_ADDRESSES: 'Number of Email Addresses'
    }
  }
}

const commonOrderCreationProps = {
  PARCEL_WEIGHT: 'Parcel Weight',
  PARCEL_SIZE: 'Parcel Size',
  INSURANCE_FEE: 'Insurance Fee',
  PARCEL_DESCRIPTION: 'Parcel Description',
  SHIPPING_FEE: 'Shipping Fee',
  RECIPIENTS_DETAILS: 'Recipients Details',
  RECIPIENTS_EMAIL_ADDRESS: "Recipient's Email Address",
  INSTRUCTIONS_FOR_DRIVER: 'Instructions for Driver',
  COD_AMOUNT: 'COD Amount',
  COD_HANDLING_FEE: 'COD Handling Fee',
  PICK_UP_OR_DROP_OFF_LOCATION: 'Pickup or Drop Off Location',
  PICKUP_DATE: 'Pickup Date',
  DROP_OFF_LOCATION: 'Drop Off Location',
  SUFFICIENT_BALANCE: 'Sufficient Balance'
}

export const REPORTS_EXTRA_FILTERS_PROPS_NAMES = {
  SERVICE_LEVEL_FILTER: 'Service Level Filter Selected',
  PARCEL_STATUS_FILTER: 'Parcel Status Filter Selected'
}

export const REPORTS_EXTRA_FILTERS_KEYS = {
  SERVICE_LEVEL: 'service_level',
  GRANULAR_STATUS: 'granular_status'
}

export const SERVICE_LEVEL_FILTER_TEXT = {
  STANDARD: 'Standard',
  EXPRESS: 'Express',
  NEXTDAY: 'Next Day',
  SAMEDAY: 'Same Day'
}

export const volumeVehicleMappings = {
  'Less than 3 Parcels': 'Motorcycle',
  'Less than 10 Parcels': 'Car Boot',
  'Half-Van Load': 'Half Van',
  'Full-Van Load': 'Full Van',
  'Larger than Van Load': 'More than 1 Van'
}

export const pickupTypesMappings = {
  SCHEDULED: 'Scheduled Pickup',
  DROP_OFF: 'Drop Off',
  NO: 'No Pickup Needed'
}

export const OrderCreationEvents = {
  STARTED_ORDER_CREATION: {
    name: 'Started Order Creation',
    props: {
      NUMBER_OF_ORDER_CREATION_ATTEMPTS: '# of Order Creation Attempts',
      TYPE: "Order's Type",
      ORIGINATION: 'Origination'
    }
  },

  STARTED_BULK_ORDER_CREATION: {
    name: 'Start Bulk Order Creation',
    props: {
      NUMBER_OF_BULK_ORDER_CREATION_ATTEMPTS: '# of Bulk Order Creation Attempts'
    }
  },

  UPLOADED_BULK_ORDER_DATA: {
    name: 'Uploaded Bulk Order Data',
    props: {
      NUMBER_OF_BULK_ORDER_UPLOAD_ATTEMPTS: '# of Bulk Order Upload Attempts'
    }
  },

  SAVED_BULK_ORDER_DATA: {
    name: 'Saved Bulk Order Data',
    props: {
      NUMBER_OF_SAVED_BULK_ORDER_UPLOAD_ATTEMPTS: '# of Saved Bulk Order Upload Attempts'
    }
  },

  SELECTED_PICKUP_SELECTION: {
    name: 'Selected Pickup Options',
    props: {
      PICKUP_TYPE: 'Pickup Type',
      PICKUP_ADDRESS: 'Pickup Address',
      RETURN_ADDRESS: 'Return Address',
      PICKUP_DATE: 'Pickup Date',
      PICKUP_TIME: 'Pickup Time',
      VEHICLE_TYPE: 'Vehicle Type',
      INSTRUCTIONS_FOR_DRIVER: 'Instructions for Driver'
    }
  },

  PRICE_ESTIMATED_TOGGLE: {
    name: 'Price Estimated Toggle',
    props: {
      PRICE_ESTIMATE_TOGGLE_ENABLED: 'Price Estimate Toggle Enabled'
    }
  },

  ADDED_ORDER_DETAILS: {
    name: 'Added Order Details',
    props: {
      INPUT_TYPE: 'Input Type',
      RECIPIENT_DETAILS: 'Recipients Details',
      RECIPIENT_EMAIL_ADDRESS: "Recipient's Email Address",
      INSTRUCTIONS_FOR_DRIVER: 'Instructions for Driver',
      PARCEL_WEIGHT: 'Parcel Weight',
      PARCEL_SIZE: 'Parcel Size',
      REQUESTED_TRACKING_ID: 'Requested Tracking ID',
      INSURANCE_FEE: 'Insurance Fee',
      PARCEL_DESCRIPTION: 'Parcel Description',
      COD_AMOUNT: 'COD Amount',
      DELIVERY_TYPE: 'Delivery Type',
      NUMBER_OF_CARTONS_REQUIRED: '# of cartons Required',
      NUMBER_OF_RDO_REQUIRED: '# of RDO Required',
      NUMBER_OF_GRN_REQUIRED: '# of GRN Required',
      SHIPPER_ORDER_NUMBER: 'Shipper order number'
    }
  },

  REVIEWED_PRICE: {
    name: 'Reviewed Price',
    props: {
      SHIPPING_FEE: 'Shipping Fee',
      SUFFICIENT_BALANCE: 'Sufficient Balance'
    }
  },

  COMPLETED_ORDER_CREATION: {
    name: 'Completed Order Creation',
    props: {
      ...commonOrderCreationProps,
      NUMBER_OF_ORDER_CREATED: '# of Order Created',
      NUMBER_OF_INSURANCE_ADDED: '# of Insurance Added',
      NUMBER_OF_PARCEL_DESCRIPTION_ADDED: '# of Parcel Description Added',
      LIFETIME_VALUE: 'Lifetime Value',
      LAST_COMPLETED_ORDER_DATE: 'Last Completed Order Date',
      INPUT_TYPE: 'Input Type',
      NUMBER_OF_MPS_ORDER_CREATED: '# of MPS Orders Created',
      NUMBER_OF_CARTONS_REQUIRED: '# of cartons Required',
      NUMBER_OF_RDO_REQUIRED: '# of RDO Required',
      NUMBER_OF_GRN_REQUIRED: '# of GRN Required'
    }
  },

  ABANDONED_ORDER_CREATION: {
    name: 'Abandoned Order Creation',
    props: {
      ...commonOrderCreationProps,
      NUMBER_OF_ORDER_ABANDONED: '# of Order Abandoned',
      NUMBER_OF_PICKUP_DETAILS_ADDED: '# of Pickup Options Details Added During Abandonment',
      NUMBER_OF_ORDER_DETAILS_ADDED: '# of Order Details Added During Abandonment',
      NUMBER_OF_REVIEWED_PRICE_ADDED: '# of Reviewed Price Details Added During Abandonment',
      LAST_ABANDONED_ORDER_DETAILS: 'Last Abandoned Order Date',
      INPUT_TYPE: 'Input Type',
      REQUESTED_TRACKING_ID: 'Requested Tracking ID'
    }
  }
}

export const accountSettingsEvents = {
  VIEWED_ACCOUNT_SETTINGS: {
    name: 'Viewed Account Settings',
    props: {
      NUM_OF_VIEW_ACCOUNT_SETTINGS: '# of View Account Settings'
    }
  },
  CHANGED_LANGUAGE: {
    name: 'Changed Language',
    props: {
      NUM_OF_LANGUAGE_CHANGE: '# of Language Change',
      LANGUAGE_CHANGED: 'Language Changed'
    }
  },
  CHANGED_PASSWORD: {
    name: 'Changed Password',
    props: {
      NUM_OF_PASSWORD_CHANGED: '# of Password Changed'
    }
  }
}

export const ReturnOrderCreationEvents = {
  STARTED_ORDER_CREATION: {
    name: 'Started Return Order Creation',
    props: {
      NUMBER_OF_ORDER_CREATION_ATTEMPTS: '# of Return Order Creation Attempts',
      TYPE: "Order's Type",
      ORIGINATION: 'Origination'
    }
  },
  SELECTED_PICKUP_SELECTION: {
    name: 'Selected Return Pickup From Customer',
    props: {
      ...OrderCreationEvents.SELECTED_PICKUP_SELECTION.props
    }
  },
  SELECTED_RETURN_TO_SHIPPER_DETAILS: {
    name: 'Selected Return to Shipper Details',
    props: {
      RETURN_ADDRESS: 'Return Address',
      DELIVERY_TYPE: 'Delivery Type',
      DELIVER_TIME: 'Delivery Time',
      INSTRUCTIONS_FOR_DRIVER: 'Instructions for Driver'
    }
  },
  ADDED_ORDER_DETAILS: {
    name: 'Added Return Order Details',
    props: {
      PARCEL_WEIGHT: 'Parcel Weight',
      PARCEL_SIZE: 'Parcel Size',
      REQUESTED_TRACKING_ID: 'Requested Tracking ID',
      INSURANCE_FEE: 'Insurance Fee',
      PARCEL_DESCRIPTION: 'Parcel Description'
    }
  },
  COMPLETED_ORDER_CREATION: {
    name: 'Completed Return Order Creation',
    props: {
      ...commonOrderCreationProps,
      NUMBER_OF_ORDER_CREATED: '# of Return Order Created',
      NUMBER_OF_INSURANCE_ADDED: '# of Return Insurance Added',
      NUMBER_OF_PARCEL_DESCRIPTION_ADDED: '# of Order Description Added',
      LAST_COMPLETED_ORDER_DATE: 'Last Completed Return Order Date',
      NUMBER_OF_MPS_ORDER_CREATED: '# of MPS Orders Created',
      NUMBER_OF_CARTONS_REQUIRED: '# of cartons Required',
      NUMBER_OF_RDO_REQUIRED: '# of RDO Required',
      NUMBER_OF_GRN_REQUIRED: '# of GRN Required'
    }
  },
  ABANDONED_ORDER_CREATION: {
    name: 'Abandoned Return Order Creation',
    props: {
      ...commonOrderCreationProps,
      NUMBER_OF_ORDER_ABANDONED: '# of Return Order Abandoned',
      NUMBER_OF_PICKUP_DETAILS_ADDED: '# of Return Pickup From Customer Details Added During Abandonment',
      NUMBER_OF_ORDER_DETAILS_ADDED: '# of Return Order Details Added During Abandonment',
      NUMBER_OF_SHIPPER_DETAILS_ADDED: '# of Return To Shipper Details Added During Abandonment',
      LAST_ABANDONED_ORDER_DETAILS: 'Last Abandoned Return Order Date',
      REQUESTED_TRACKING_ID: 'Requested Tracking ID'
    }
  }
}

export const deliveryTypeMapping = {
  STANDARD: 'Standard',
  EXPRESS: 'Express',
  NEXTDAY: 'Next Day',
  SAMEDAY: 'Same Day'
}

export const OrderCreationNinjaPackEvents = {
  STARTED_NP_ORDER_CREATION: {
    name: 'Started Ninja Pack Creation',
    props: {
      NUM_OF_NP_CREATION_ATTEMPTS: '# of Ninja Pack Creation Attempts',
      ORDER_TYPE: 'Order Type'
    }
  },
  SELECETD_NP_PICKUP_OPTIONS: {
    name: 'Selected Ninja Pack Pickup Options',
    props: {
      PICKUP_TYPE: 'Pickup Type',
      RETURN_ADDRESS: 'Return Address'
    }
  },
  ADDED_NP_DETAILS: {
    name: 'Added Ninja Pack Details',
    props: {
      INPUT_TYPE: 'Input Type',
      RECIPIENTS_DETAILS: 'Recipients Details',
      RECIPIENTS_EMAIL_ADDRESS: "Recipient's Email Address",
      INSTRUCTIONS_FOR_DRIVER: 'Instructions for Driver',
      PARCEL_DESCRIPTION: 'Parcel Description',
      NP_ID: 'Ninja Pack ID',
      COD_AMOUNT: 'COD Amount'
    }
  },
  COMPLETED_NP_CREATION: {
    name: 'Completed Ninja Pack Creation',
    props: {
      NUM_OF_NP_CREATED: '# of Ninja Packs Created',
      NUM_OF_NP_ORDER_DESCRIPTION_ADDED: '# of Ninja Pack Order Description Added',
      LAST_COMPLETED_NP_ORDER_DATE: 'Last Completed Ninja Pack Order Date'
    }
  },
  ABANDONED_NP_CREATION: {
    name: 'Abandoned Ninja Pack Creation',
    props: {
      NUM_OF_NP_ORDERS_ABANDONED: '# of Ninja Packs Order Abandoned',
      NUM_OF_NP_PICKUP_OPTIONS_DETAILS_ADDED_DURING_ABANDONMENT:
        '# of Ninja Pack Pickup Options Details Added During Abandonment',
      NUM_OF_NP_DETAILS_ADDED_DURING_ABANDONMENT: '# of Ninja Pack Details Added During Abandonment',
      PICKUP_TYPE: 'Pickup Type',
      RETURN_ADDRESS: 'Return Address',
      INPUT_TYPE: 'Input Type',
      DELIVERY_TYPE: 'Delivery Type',
      RECIPIENTS_DETAILS: 'Recipients Details',
      RECIPIENTS_EMAIL_ADDRESS: "Recipient's Email Address",
      INSTRUCTIONS_FOR_DRIVER: 'Instructions for Driver',
      PARCEL_DESCRIPTION: 'Parcel Description',
      NP_ID: 'Ninja Pack ID',
      COD_AMOUNT: 'COD Amount',
      LAST_ABANDONED_NP_ORDER_DATE: 'Last Abandoned Ninja Pack Order Date'
    }
  }
}

export const notificationsSettingsEvents = {
  VIEWED_NOTIFICATIONS_SETTINGS: {
    name: 'Viewed Notifications Settings',
    props: {
      NUM_OF_VIEW_NOTIFICATIONS_SETTINGS: '# of View Notifications Settings'
    }
  },
  CONFIGURED_NOTIFICATIONS: {
    name: 'Configured Notifications',
    props: {
      NUM_OF_NOTIFICATION_CONFIGURED: '# of Notification Configuration',
      TYPE_OF_NOTIFICATION_TOGGLED: 'Type of Notification Toggled',
      FY_PARCEL_PICKUP_SUCCESS: 'For You - Successfully pick up parcels from you',
      FY_DELIVER_SUCCESS: 'For You - Successfully deliver to your customer',
      FYC_PICKUP_SUCCESS: 'For Your Customers - Successfully pick up parcels from you',
      FYC_FAILED_PICKUP: 'For Your Customers - Failed to pick up parcels from you',
      FYC_DELIVER_SUCCESS: 'For Your Customers - Successfully deliver a parcel to them',
      FYC_RESCHEDULE_DELIVERY_AFTER_FAILED: 'For Your Customers - To reschedule a delivery after a delivery failed'
    }
  }
}

export const billingSettingsEvents = {
  VIEWED_BILLING_SETTINGS: {
    name: 'Viewed Billing Settings',
    props: {
      NUM_OF_VIEW_BILLING_SETTINGS: '# of View Billing Settings'
    }
  },
  TOPPED_UP_WALLET: {
    name: 'Topped Up Wallet',
    props: {
      NUM_OF_TOP_UPS_TO_WALLET: '# of Top Ups to Wallet',
      AMMOUNT_TOPPED_UP: 'Amount Topped Up'
    }
  },
  VIEWED_BILLING_HISTORY: {
    name: 'Viewed Billing History',
    props: {
      NUM_OF_VIEW_BILLING_HISTORY: '# of View Billing History'
    }
  },
  DOWNLOADED_TRANSACTION_REPORT: {
    name: 'Downloaded Transaction Report',
    props: {
      NUM_OF_DOWNLOADED_TRANSACTION_REPORTS: '# of Downloaded Transaction Reports'
    }
  },
  SENT_INVOICE_DETAILS: {
    name: 'Sent invoice details',
    props: {
      NUM_OF_SENT_INVOICE_DETAILS: '# of sent invoice details'
    }
  }
}

export const walletEvents = {
  VIEWED_WALLET: {
    name: 'Wallet',
    props: {
      NUM_OF_USERS_VIEWED_NV_WALLETS: '# of users who view NV wallets'
    }
  },

  WITHDRAWAL_REQUEST: {
    name: 'Withdrawal Request',
    props: {
      NUM_OF_WITHDRAWAL_REQUEST_MADE: '# of Withdrawal Request Made'
    }
  },

  VIEWED_WITHDRAWAL: {
    name: 'Viewed Withdrawal',
    props: {
      NUM_OF_VIEW_WITHDRAWAL_HISTORY: '# of view for Withdrawal History'
    }
  },

  VIEWED_STATEMENTS: {
    name: 'Viewed Statements',
    props: {
      NUM_OF_VIEWS_NV_WALLET_STATEMENTS: '# of views for NV Wallet Statements'
    }
  }
}

export const myAddressSettingsEvents = {
  VIEWED_PICKUP_ADDRESSES_SETTINGS: {
    name: 'Viewed Pickup Addresses Settings',
    props: {
      NUM_OF_VIEW_ADDRESSES_SETTINGS: '# of View Addresses Settings'
    }
  },
  ADDED_PICKUP_ADDRESS: {
    name: 'Added Pickup Address',
    props: {
      NUM_OF_ADDED_ADDRESSES: '# of Added Addresses'
    }
  },
  EDITED_PICKUP_ADDRESS: {
    name: 'Edited Pickup Address',
    props: {
      NUM_OF_LOCATION_EDITS: '# of Location Edits',
      EDITED_FIELD: 'Edited Field'
    }
  },
  DELETED_PICKUP_ADDRESS: {
    name: 'Deleted Pickup Address',
    props: {
      NUM_OF_REMOVED_LOCATION: '# of Removed Locations'
    }
  },
  TOGGLED_SHOW_ADDRESS_ON_AIRWAY_BILL: {
    name: 'Toggled Show Address on Airway Bill',
    props: {
      SHOW_ADDRESS_ON_AIRWAY_BILL_ENABLED: 'Show Address on Airway Bill Enabled'
    }
  }
}
export const orderCreateSettingsEvents = {
  VIEWED_ORDER_CREATE_SETTINGS: {
    name: 'Viewed Order Create Settings',
    props: {
      NUM_OF_VIEW_ORDER_CREATE_SETTINGS: '# of View Order Create Settings'
    }
  },
  ADDED_NEW_TEMPLATE: {
    name: 'Added New Template',
    props: {
      NUM_OF_ADDED_TEMPLATES: '# of Added Templates'
    }
  },
  EDITED_EXISTING_TEMPLATE: {
    name: 'Edited Existing Template',
    props: {
      NUM_OF_EDITED_TEMPLATE: '# of Templates Edits',
      EDITED_FIELD: 'Edited Field'
    }
  },
  DELETED_EXISTING_TEMPLATE: {
    name: 'Deleted Existing Template',
    props: {
      NUM_OF_REMOVED_TEMPLATES: '# of Removed Templates'
    }
  }
}
export const ITSettingsEvents = {
  VIEWED_IT_SETTINGS: {
    name: 'Viewed IT Settings',
    props: {
      NUM_OF_VIEW_IT_SETTINGS: '# of View IT Settings'
    }
  },
  SELECTED_PRINTER: {
    name: 'Selected Printer',
    props: {
      NUM_OF_PRINTER_SELECTED: '# of Printer Selected',
      PRINTER_NAME: 'Printer Name'
    }
  },
  DOWNLOADED_PRINTER_APP: {
    name: 'Downloaded Printer App',
    props: {
      NUM_OF_DOWNLOADED_PRINTER_APP: '# of Downloaded Printer App'
    }
  }
}

export const ManageOrdersEvents = {
  VIEWED_ORDER: {
    name: 'Viewed Order',
    props: {
      NUMBER_OF_VIEW_ORDER_DETAILS: '# of View Order Details'
    }
  },

  VIEWED_ALL_ORDERS: {
    name: 'Viewed All Orders',
    props: {
      NUMBER_OF_VIEW_ORDERS: '# of View Orders'
    }
  },

  EDITED_ORDERS: {
    name: 'Edited Orders',
    props: {
      NUMBER_OF_ORDERS_EDITED: '# of Orders Edited',
      EDITED_FIELD: 'Edited Field'
    }
  },

  CANCELLED_ORDER: {
    name: 'Cancelled Order',
    props: {
      NUMBER_OF_ORDERS_CANCELLED: '# of Orders Cancelled',
      BEFORE_AFTER_PICKUP: 'Before or After Pickup'
    }
  },

  SUBMITTED_EDITED_COD: {
    name: 'Submitted Edited COD',
    props: {
      ERROR_RETURNED: 'Error Returned'
    }
  },

  EDITED_COD_AMOUNT: {
    name: 'Edited COD amount',
    props: {
      PARCEL_STATUS: 'Parcel Status',
      NUMBER_OF_ATTEMPTS: '# of Edited COD Attempts'
    }
  },

  VIEWED_COD_REVISION_LOG: {
    name: 'Viewed COD Revision Log',
    props: {
      NUMBER_OF_REVISIONS: '# of Revisions'
    }
  }
}

export const ReportsEvents = {
  PRINTED_DELIVERY_STATUS_REPORT: {
    name: 'Printed Delivery Status Report',
    props: {
      NUMBER: '# of Delivery Status Report Sent',
      TYPE: 'Type of Delivery Status Report',
      SERVICE_LEVEL_FILTER: REPORTS_EXTRA_FILTERS_PROPS_NAMES.SERVICE_LEVEL_FILTER,
      PARCEL_STATUS_FILTER: REPORTS_EXTRA_FILTERS_PROPS_NAMES.PARCEL_STATUS_FILTER
    }
  },

  PRINTED_DELIVERY_FAILURE_REPORT: {
    name: 'Printed Delivery Failure Report',
    props: {
      NUMBER: '# of Delivery Failure Report Sent',
      TYPE: 'Type of Delivery Failure Report',
      SERVICE_LEVEL_FILTER: REPORTS_EXTRA_FILTERS_PROPS_NAMES.SERVICE_LEVEL_FILTER,
      PARCEL_STATUS_FILTER: REPORTS_EXTRA_FILTERS_PROPS_NAMES.PARCEL_STATUS_FILTER
    }
  },

  PRINTED_COD_RECONCILIATION_REPORT: {
    name: 'Printed COD Reconciliation Report',
    props: {
      NUMBER: '# of COD Reconciliation Report Sent',
      TYPE: 'Type of COD Reconciliation Report',
      SERVICE_LEVEL_FILTER: REPORTS_EXTRA_FILTERS_PROPS_NAMES.SERVICE_LEVEL_FILTER
    }
  }
}

export const ManagePickups = {
  VIEWED_PICKUP: {
    name: 'Viewed Pickup',
    props: {
      NUMBER_OF_VIEWED_PICKUPS: '# of View Pickups'
    }
  },

  CANCELLED_PICKUP: {
    name: 'Cancelled Pickup',
    props: {
      NUMBER_OF_CANCELLED_PICKUPS: '# of Cancelled Pickups'
    }
  }
}

export const DashboardTour = {
  TOUR_STARTED: {
    name: 'Started Dashboard Tour',
    props: {
      NUMBER_OF_TOURS: '# of Dashboard Tours'
    }
  }
}

export const PrintingEvent = {
  PRINTED_AIRWAY_BILL: {
    name: 'Printed Airway Bill',
    props: {
      NUMBER_OF_ATTEMPTS: '# of Airway Bill Prints Attempts',
      STATUS: 'Printed Airway Bill Status',
      FORMAT: 'Airway Bill Print Format'
    }
  }
}

export const SearchOrders = {
  SEARCHED_BASIC_ORDER: {
    name: 'Searched Order By Basic Search',
    props: {
      CHARACTER_LENGTH: 'Search Character Length',
      NUMBER_OF_SEARCH_RESULT: '# of Search Results',
      NUMBER_OF_SEARCHES: '# of Searches'
    }
  },

  ADVANCE_SEARCH: {
    name: 'Search Order By Advanced Search',
    props: {
      SEARCH_TYPE: 'Search Type',
      PARCEL_STATUS: 'Parcel Statuses Selected',
      SEARCH_PERIOD: 'Search Period',
      NUMBER_OF_SEARCH_RESULT: '# of Search Results',
      NUMBER_OF_SEARCHES: '# of Searches'
    }
  }
}

export const InternationalOrderEvents = {
  USER_SAVES_DETAILS_FOR_INTR_ORDER: {
    name: 'User saves order details for international order',
    props: {
      INTERNATIONAL_ORDER: 'International Order'
    }
  },
  COMPLETED_INTR_ORDER_CREATION: {
    name: 'Completed International Order Creation',
    props: {
      NUM_OF_INTR_ORDER_CREATED: '# of International Order Created',
      NUM_OF_INTER_PARCEL_CREATED: '# of International Parcel Created'
    }
  }
}

export const DisproEvents = {
  CLOSED_DISPRO_POP_UP: {
    name: 'Closed dispro pop-up',
    props: {
      ORIGINATION: 'Origination'
    }
  },
  VIEWED_DISPRO_T_AND_C: {
    name: 'Viewed Dispro T&Cs'
  }
}

export const PaymentsEvents = {
  VIEWED_PAYMENTS: {
    name: 'Viewed Payment',
    props: {
      NUMBER_OF_VIEW_PAYMENTS: '# of View Payment',
      ENTRY_POINT: 'Entry Point'
    }
  },
  VIEWED_PAYMENTS_FAQ: {
    name: 'Viewed Payments FAQ',
    props: {
      SOURCE: 'Source'
    }
  },
  VIEWED_COD_FAQ: {
    name: 'Viewed COD FAQ',
    props: {
      SOURCE: 'Source'
    }
  },
  VIEWED_ORDERS_WITHIN_COD_COLLECTED: {
    name: 'Viewed Orders Within COD Collected'
  },
  VIEWED_ORDERS_WITHIN_FEES: {
    name: 'Viewed Orders Within Fees'
  }
}

export const HelpdeskEvents = {
  VIEWED_HELPDESK: {
    name: 'Viewed Helpdesk'
  },
  VIEWED_FAQS: {
    name: 'Viewed FAQs'
  }
}

export const ShipperSupportEvents = {
  SELECTED_ISSUE_TYPE: {
    name: 'Selected issue type',
    props: {
      ISSUE_TYPE: 'Issue type'
    }
  },
  SELECTED_SUB_ISSUE: {
    name: 'Selected sub-issue',
    props: {
      SUB_ISSUE_TYPE: 'Sub issue type'
    }
  },
  CLICKED_SUBMIT_HELPDESK_TICKET: {
    name: 'Clicked Submit Helpdesk Ticket',
    props: {
      STATUS: 'Status'
    }
  },
  EDITED_HELPDESK_DELIVERY_INSTRUCTIONS: {
    name: 'Edited Helpdesk Delivery Instructions'
  }
}

export const orderTypeFilter = {
  SELECTED_ORDER_TYPE: {
    name: 'Filtered for order type',
    props: {
      ORDER_TYPE: 'Order Type Filter Selected'
    }
  }
}

export const deliveryReportsTypesMappings = {
  dateRange: 'By Order Create Date',
  completedDateRange: 'By Order Completed Date',
  batchId: 'By Batch ID'
}

export const advancedSearchTypesMappings = {
  /* eslint-disable camelcase */
  batch_id: 'Batch ID',
  file_upload: 'File Upload',
  stamp_id: 'Stamp ID'
  /* eslint-enable camelcase */
}

// To guarantee appropriate typecasting across all Mixpanel reports, use the date format YYYY-MM-DDTHH:MM:SS
export const DATE_FORMAT = 'YYYY-MM-DDTHH:MM:SS'

export const getMappedEditedField = changedFields => {
  const edited = new Set()
  _.forEach(changedFields, field => {
    switch (field) {
      case 'name':
      case 'email':
      case 'phone_number':
        edited.add('Contact')
        break
      case 'deliveryInstructions':
        edited.add('Delivery Instructions')
        break
      default:
        edited.add('Address')
    }
  })

  return [...edited].join(',')
}

export const PRODUCT_NAME = {
  DASH_WEB: 'DASH Web',
  XB_WEB: 'XB Web'
}
