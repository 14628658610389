/* eslint-disable camelcase */
import React from 'react'
import styled from 'styled-components'
import { T } from '@nv/react-commons/src/Components'
import Colors from '@nv/react-commons/src/Themes/Colors'
import { Text } from 'components/Text'
import { useLocation } from 'react-router-dom'
import { RouteModal } from 'components/RouteModal'
import { ORDER_TYPE_MODAL_PATH } from 'containers/OrderTypeModal/constants'
import { Button } from '@nv/react-akira'
import { Images } from 'images'
import { ROUTES } from 'containers/Base/constants'
import { getModalPath } from 'utils/getModalPath'
import { useNavigateWithState } from 'hooks'
import { DESTINATION_ORDER_TYPE } from 'constants/orderCreate'
import { ACCOUNT_UPDATE_SUCCESS_PATH } from './AccountUpdateSuccessModal.constants'

export const AccountUpdateSuccessModal = () => {
  const navigate = useNavigateWithState()
  const location = useLocation()

  const onProceed = () => {
    const destinationOrderType = location.state?.[DESTINATION_ORDER_TYPE]
    if (destinationOrderType) {
      navigate(destinationOrderType, { replace: true })
      return
    }
    navigate(`${ROUTES.HOME}/${ORDER_TYPE_MODAL_PATH}`, { replace: true })
  }

  return (
    <StyledModal
      path={getModalPath(location, ACCOUNT_UPDATE_SUCCESS_PATH)}
      title={<T id='account_update_success.title' />}
    >
      <StyledImg width={100} height={120} src={Images.ryoSuccess} />
      <StyledDescription>
        <T id='account_update_success.description' />
      </StyledDescription>
      <StyledConfirmButton
        variant='grey'
        styleType='primary'
        type='submit'
        size='lg'
        loading={false}
        onClick={onProceed}
      >
        <T id='account_update_success.proceed_button' />
      </StyledConfirmButton>
    </StyledModal>
  )
}

const StyledModal = styled(RouteModal)`
  && {
    .ant-modal-content {
      border-radius: 10px;
      max-width: 420px;
      margin: auto;

      > div {
        background-color: unset;
      }
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
    }

    .ant-modal-title {
      border-radius: 10px;
    }
  }
`

const StyledDescription = styled(Text).attrs({
  color: Colors.balanceTxtBold
})`
  text-align: left;
  white-space: pre-line;
  margin-bottom: 20px;
`

const StyledConfirmButton = styled(Button)`
  width: 220px !important;
  height: 40px !important;
  align-self: flex-end !important;
  margin-top: 10px !important;
  border-radius: 20px !important;
`

const StyledImg = styled.img`
  align-self: center;
  margin-bottom: 25px;
`
