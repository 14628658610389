import featureFlags from './featureKeys'
import jsonFeatureFlag from './jsonFeatureFlag'

export const defaultFlags = {
  [featureFlags.SHOULD_DISPLAY_API_AND_WEBHOOK]: true,
  [featureFlags.COD_STATS]: false,
  [featureFlags.OLD_COD_STATS]: true,
  [featureFlags.REMITTANCE_HISTORY]: false,
  [featureFlags.VIEW_COD_REPORT]: true,
  [featureFlags.EBER_LOYALTY_SSO]: true,
  [featureFlags.GRANULAR_ADDRESS_FIELDS]: false,
  [featureFlags.BANK_ACCOUNT_DETAILS]: false,
  [featureFlags.REPORT_SCHEDULES]: false,
  [featureFlags.MERGE_LITE_AND_MOBILE_FEATURES]: false,
  [featureFlags.ENABLE_EKYC]: false,
  [featureFlags.DISABLE_OC_FOR_EKYC_INCOMPLETE]: false,
  [featureFlags.ENABLE_HELPDESK]: false,
  [featureFlags.DISABLED_SAME_DAY_PICKUP]: false,
  [featureFlags.ENABLE_HELPDESK_INVOICE_DISPUTE]: false,
  [featureFlags.SMART_PASTE_ADDRESS]: false,
  [featureFlags.NINJA_BUDDIES_REFERRAL]: false,
  [featureFlags.HIDE_YOU_TRANSFERRED]: false,
  [featureFlags.REFERENCE_PERIOD]: false,
  [featureFlags.BOUNCED_RETRY_PAYMENTS]: false,
  [featureFlags.REFERRED_MOBILE_NUMBER]: false,
  [featureFlags.AUTOMATE_DELAYED_DELIVERY_REQUESTS]: false,
  [featureFlags.SALESFORCE_CHAT]: false,
  [featureFlags.VIEW_COD_AND_FEES]: false,
  [featureFlags.PORTRAIT_WAYBILL]: false
}

export const defaultJsonFlags = {
  [jsonFeatureFlag.PAYMENT_GATEWAY]: {
    sg: 'PayPal',
    my: 'iPay88'
  },
  [jsonFeatureFlag.MAINTENANCE_TIME]: {
    showScheduledMaintenanceWarning: false,
    scheduleStart: '',
    scheduleEnd: '',
    displayStart: ''
  }
}
