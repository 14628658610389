import { Text } from 'components/Text'
import styled from 'styled-components'
import Button from 'components/Button'

export const StyledDescription = styled(Text).attrs({
  size: 15
})`
  width: 100%;
  margin-bottom: 40px;
`

export const ButtonsWrapper = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
  }
`

export const ButtonWrapper = styled(Button)`
  && {
    margin-left: 8px;
  }
`
