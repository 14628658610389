import { Text } from 'components/Text'
import styled from 'styled-components'
import { AkiraReset } from 'themes'
import { Colors } from 'themes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 12px;
  z-index: 999;
`

export const DummyButton = styled.button`
  border-radius: 8px 8px 0 0;
  margin: 0;
  padding: 0 19px;
  height: 46px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
  background: #222222;
  font-size: 14px;
  color: #fff;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`

export const StyText = styled(Text)`
  display: block;
`

export const NoteBox = styled.div`
  ${AkiraReset}
  box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.5);
  right: 15px;
  position: fixed;
  padding: 24px;
  bottom: 60px;
  color: ${Colors.balanceTxtBold};
  background-color: #fff;
  max-width: 443px;
`
export const Title = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Icon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${Colors.black};
  font-weight: normal;
`

export const ChatIcon = styled(FontAwesomeIcon)`
  color: ${Colors.white};
  font-weight: normal;
  margin-right: 19px;
`
