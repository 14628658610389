import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { T } from '@nv/react-commons/src/Components'
import Colors from '@nv/react-commons/src/Themes/Colors'
import { Button } from '@nv/react-akira'
import { Images } from 'images'
import { Text } from 'components/Text'
import { RouteModal } from 'components/RouteModal'
import { ROUTES } from 'containers/Base/constants'
import {
  selectCountry,
  selectIsFullKyc,
  selectMissingEInvoiceDetails,
  selectUser,
  selectVerifiedPhone
} from 'containers/Base/selectors'
import { VERIFY_PHONE_NUMBER } from 'containers/VerifyPhoneNumber/VerifyPhoneNumber.consts'
import { getModalPath } from 'utils/getModalPath'
import { useNavigateWithState } from 'hooks'

import featureKeys from 'featureHub/featureKeys'
import { useFlag } from 'featureHub/useFlag'
import { SETUP_ACCOUNT_PATH } from './SetupAccount.constants'
import { EKYC_MODAL_PATH } from 'containers/EKYC/EKYCModal.constants'
import { intl } from 'containers/IntlGlobalProvider'
import { ParsedPhoneNumber, parsePhoneNumber } from 'awesome-phonenumber'
import { ADD_ACCOUNT_DETAILS_PATH } from 'containers/AddAccountDetailsModal/AddAccountDetailsModal.constants'

export const SetupAccountModal = () => {
  const navigate = useNavigateWithState()
  const location = useLocation()
  const verifiedPhone = useSelector(selectVerifiedPhone())
  const isFullKyc = useSelector(selectIsFullKyc())
  const enableEKYC = useFlag(featureKeys.ENABLE_EKYC)
  const user = useSelector(selectUser())
  const userCountryCode = useSelector(selectCountry())?.toUpperCase?.()
  const [currentPhoneNumber, _] = useState<ParsedPhoneNumber>(
    parsePhoneNumber(user?.contactNo, { regionCode: userCountryCode })
  )
  const missingEInvoiceDetails = useSelector(selectMissingEInvoiceDetails())

  const onPressMaybeLater = () => {
    navigate(-1)
  }

  const onPressGetStarted = async () => {
    if (verifiedPhone === false) {
      navigate(`${ROUTES.HOME}/${VERIFY_PHONE_NUMBER}`, { replace: true })
    } else if (!isFullKyc && enableEKYC) {
      navigate(`${ROUTES.HOME}/${EKYC_MODAL_PATH}`, { replace: true })
    } else {
      navigate(`${ROUTES.HOME}/${ADD_ACCOUNT_DETAILS_PATH}`, { replace: true })
    }
  }

  return (
    <StyledModal path={getModalPath(location, SETUP_ACCOUNT_PATH)} title={<T id='setup_account.title' />}>
      <StyledImg width={100} height={120} src={Images.ryoAnnounce} />
      <StyledDescription>
        <T id='setup_account.description' />
      </StyledDescription>
      <StyledDescription>
        <p className='text-6 mb-0'>{intl.formatMessage({ id: 'setup_account.what_you_might_need' })}:</p>
      </StyledDescription>
      <StyledUl>
        {!verifiedPhone && (
          <StyledLi>
            {intl.formatMessage({ id: 'setup_account.phone_number' })} <b>{currentPhoneNumber?.number?.e164}</b>
          </StyledLi>
        )}
        {!isFullKyc && enableEKYC && <StyledLi>{intl.formatMessage({ id: 'setup_account.id_card' })}</StyledLi>}
        {missingEInvoiceDetails && <StyledLi>{intl.formatMessage({ id: 'billing_address' })}</StyledLi>}
        {missingEInvoiceDetails && (
          <StyledLi>
            {intl.formatMessage({ id: 'setup_account.tax_identification_number' })}{' '}
            <span className='text-8 text-grey-4'>
              ({intl.formatMessage({ id: 'setup_account.for_registered_businesses_only' })})
            </span>
          </StyledLi>
        )}
        {missingEInvoiceDetails && (
          <StyledLi>
            {intl.formatMessage({ id: 'setup_account.sst_number' })}{' '}
            <span className='text-8 text-grey-4'>
              ({intl.formatMessage({ id: 'setup_account.for_registered_businesses_only' })})
            </span>
          </StyledLi>
        )}
      </StyledUl>
      <StyledRow>
        <StyledButton variant='default' styleType='secondary' size='lg' loading={false} onClick={onPressMaybeLater}>
          <FormattedMessage id='maybe_later_button' />
        </StyledButton>
        <Button variant='grey' styleType='primary' type='submit' size='lg' loading={false} onClick={onPressGetStarted}>
          <FormattedMessage id='get_started_button' />
        </Button>
      </StyledRow>
    </StyledModal>
  )
}

const StyledModal = styled(RouteModal)`
  && {
    .ant-modal-content {
      border-radius: 10px;
      max-width: 420px;
      margin: auto;

      > div {
        background-color: unset;
      }
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
    }

    .ant-modal-title {
      border-radius: 10px;
    }
  }
`

const StyledDescription = styled(Text).attrs({
  color: Colors.balanceTxtBold
})`
  margin-top: var(--mb, 20px);
  margin-bottom: var(--mb, 10px);
  font-size: var(--fz, 14px);
  text-align: left;
  white-space: pre-line;
`

const StyledButton = styled(Button)`
  margin-right: 10px !important;
`

const StyledImg = styled.img`
  align-self: center;
`

const StyledRow = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledUl = styled.ul`
  margin-left: 16px;
  list-style-type: disc;
  color: ${Colors.greyishBrown};
  margin-bottom: 30px;
`

const StyledLi = styled.li`
  margin-top: 10px;
`
