/**
 *
 * IconButton
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon } from '@nv/react-commons/src/Components'
import { Colors } from 'themes'

const StyledIcon = styled(Icon)`
  margin: 0 8px;
  cursor: pointer;
  transition: all 0.5s;
  :hover {
    color: ${Colors.nvPriRed};
  }
`

const IconButton = props => <StyledIcon {...props} />

IconButton.propTypes = {
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.string
}

IconButton.defaultProps = {
  color: Colors.warmGrey
}

export { IconButton }
