import { Col, Row, T } from '@nv/react-commons/src/Components'
import { Shadows } from '@nv/react-commons/src/Themes'
import { Button } from 'components/Button'
import { Modal } from 'components/Modal'
import { Text } from 'components/Text'
import { Link } from 'components/Link'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { Colors } from 'themes'
import { getBlobUrl } from './printPdf'
import { createLabels } from 'services/api/fplApi'
import { PrintFormatModal } from './PrintFormatModal'
import { PRINTED_STATUS, SOURCE_PRINT_MODAL } from './constants'
import { mpTrackPrintedAirwayBill } from 'containers/FPLMixpanel/helpers'
import { PAGE_NAMES } from 'containers/FPLMixpanel/constants'
import { ROUTES } from 'containers/Base/constants'

const AwbModal = styled(Modal)`
  .ant-modal-footer {
    background-color: ${Colors.whiteTree};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const BillCountText = styled(Text)`
  padding: 10px;
`

const PrintButton = styled(Button)`
  && {
    margin-left: auto;
    box-shadow: ${Shadows.regular};
    min-width: 96px;
    height: 36px;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
`
const FileInfoCol = styled(Col)`
  margin-right: auto;
  padding-left: 15px;
`

const PDFSymbol = styled.div`
  border-radius: 5px;
  background-color: ${Colors.alto};
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ChangeBillFormatButton = styled(Link)`
  padding: 10px;
`

export const AwbPrinterModalV1 = ({ batchId, onCancel, totalOrderCount, trackingIds, visible, source, orders }) => {
  const initState = {
    fileInfo: { files: [undefined], batchId, trackingIds },
    billsPerPage: null,
    isLabeled: false
  }
  const [state, setState] = useState(initState)
  const { fileInfo, billsPerPage, isLabeled } = state

  const getPDFFile = async () => {
    const res = await createLabels({
      parcel_ids: trackingIds,
      page_layout: billsPerPage === 4 ? 3 : billsPerPage
    })
    setState(prevState => ({
      ...prevState,
      fileInfo: { ...prevState.fileInfo, files: [res.data] },
      isLabeled: true
    }))
  }

  useEffect(() => {
    if (billsPerPage && billsPerPage > 0 && visible && !isLabeled) {
      getPDFFile()
    }
  }, [billsPerPage, trackingIds, visible])

  useEffect(() => {
    if (!visible) {
      setState(prevState => ({ ...prevState, billsPerPage: null, isLabeled: false }))
    }
  }, [visible])

  const handleSelectPrintOpt = otp => {
    setState(prevState => ({
      ...prevState,
      billsPerPage: otp,
      isLabeled: false
    }))
  }

  const handleChangeAwbFormat = () => {
    setState(prevState => ({
      ...prevState,
      billsPerPage: null
    }))
  }

  const handleClickPrint = useCallback((msSaveOrOpenBlob, blob) => {
    msSaveOrOpenBlob(blob, 'awb.pdf')
  }, [])

  const handleNavigate = useCallback(() => {
    mpTrackPrintedAirwayBill({
      status: PRINTED_STATUS.SUCCESS,
      source,
      printedOrder: totalOrderCount,
      pageName: PAGE_NAMES[ROUTES.FPL_OC_REQUEST_BY_BATCH]
    })
  }, [source, totalOrderCount])

  const getPrintProps = blob => {
    const msSaveOrOpenBlob = window.navigator && window.navigator.msSaveOrOpenBlob

    return {
      onClick: msSaveOrOpenBlob ? handleClickPrint(msSaveOrOpenBlob, blob) : handleNavigate,
      href: msSaveOrOpenBlob ? undefined : getBlobUrl(blob),
      target: '_blank'
    }
  }

  const perPage = useMemo(() => {
    const add = totalOrderCount % billsPerPage >= 1 ? 1 : 0
    const base = totalOrderCount / (billsPerPage || 1) >= 1 ? Math.floor(totalOrderCount / billsPerPage) : 0
    return base + add
  }, [totalOrderCount, billsPerPage])

  const renderPrintButtons = blob => {
    switch (blob) {
      case 'failed':
        return (
          <PrintButton type='secondary'>
            <T allCaps id='failed_status' />
          </PrintButton>
        )
      case undefined:
      case null:
        return (
          <PrintButton type='secondary'>
            <T allCaps id='loading' />
          </PrintButton>
        )
      default:
        return (
          <PrintButton {...getPrintProps(blob)}>
            <T allCaps id='print' />
          </PrintButton>
        )
    }
  }

  const renderAwbPrintRows = () => {
    return fileInfo.files.map((file, index) => (
      <Fragment key={index}>
        <Row align='middle' justify='space-between' type='flex'>
          <PDFSymbol>
            <Text color={Colors.white} size={14} type='bold'>
              .PDF
            </Text>
          </PDFSymbol>
          <FileInfoCol>
            <Text
              color={Colors.greyishBrown}
              id='awb_pdf_file_name'
              size={13}
              type='bold'
              values={{
                batchId: fileInfo?.batchId,
                first: index + 1,
                last: totalOrderCount
              }}
            />
            <Text
              color={Colors.pinkishGrey}
              id='x_orders_y_pages'
              size={12}
              values={{ x: totalOrderCount, y: perPage }}
            />
          </FileInfoCol>
          {renderPrintButtons(fileInfo.files[0])}
        </Row>
      </Fragment>
    ))
  }

  return (
    <>
      <PrintFormatModal
        visible={visible && !billsPerPage}
        onCancel={onCancel}
        onSelectAwbFormat={handleSelectPrintOpt}
        orders={orders}
      />
      <AwbModal
        footer={
          <>
            <BillCountText color={Colors.warmGrey}>
              <T id='x_bills_per_page' values={{ x: billsPerPage }} />
            </BillCountText>
            <ChangeBillFormatButton
              color={Colors.pinkishGrey}
              data-analyticsid='4plChangeAWBFormat'
              key='change_bill_format'
              onClick={handleChangeAwbFormat}
            >
              <T id='change_bill_format' />
            </ChangeBillFormatButton>
          </>
        }
        onCancel={onCancel}
        title={<T id='print_airway_bills' />}
        visible={visible && !!billsPerPage}
      >
        {renderAwbPrintRows()}
      </AwbModal>
    </>
  )
}

AwbPrinterModalV1.propTypes = {
  batchId: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  totalOrderCount: PropTypes.number,
  trackingIds: PropTypes.arrayOf(PropTypes.number),
  visible: PropTypes.bool,
  source: PropTypes.string,
  orders: PropTypes.array
}

AwbPrinterModalV1.defaultProps = {
  visible: false,
  source: SOURCE_PRINT_MODAL.LIST,
  orders: []
}
