/**
 *
 * PickupWarningAlert
 *
 */

import React from 'react'
import styled from 'styled-components'
import { Link } from '../Link'
import { Alert, T } from '@nv/react-commons/src/Components'
import { Colors } from 'themes'
import PropTypes from 'prop-types'

const StyledAlert = styled(Alert)`
  && {
    flex: 1;
    color: ${Colors.warmGrey};
    padding-top: 12px;
    i.ant-alert-icon {
      top: 21px;
    }
  }
`
class PickupWarningAlert extends React.PureComponent {
  renderMaxPickupAlert = () => (
    <StyledAlert
      type='warning'
      showIcon
      message={
        <>
          <T id='max_warning' />{' '}
          <Link onClick={this.props.onSetSelectedDate}>
            <T id='here' />
          </Link>{' '}
          <T id='view_or_choose' />
        </>
      }
    />
  )

  renderExistingPickupsAlert = () => (
    <StyledAlert
      showIcon
      message={
        <>
          <T id='existing_pickup_warning' />{' '}
          <Link onClick={this.props.onSetSelectedDate}>
            <T id='here' />
          </Link>{' '}
          <T id='view_existing_pickups' />
        </>
      }
    />
  )

  render () {
    return this.props.isMax ? this.renderMaxPickupAlert() : this.renderExistingPickupsAlert()
  }
}

PickupWarningAlert.propTypes = {
  onSetSelectedDate: PropTypes.func,
  isMax: PropTypes.bool
}

export { PickupWarningAlert }
