/**
 *
 * Asynchronously loads the component for ForgotPasswordForm
 *
 */

import React from 'react'
import { Loading } from 'components/Loading'
import loadable from 'utils/loadable'

export default loadable({
  loader: () => import('./index'),
  loading: <Loading />
})
