import { css } from 'styled-components'

const light = css`
  font-family: 'Lato', sans-serif;
  font-weight: 300;
`
const regular = css`
  font-family: 'Lato', sans-serif;
  font-weight: 400;
`
const bold = css`
  font-family: 'Lato', sans-serif;
  font-weight: 700;
`
const italic = css`
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-style: italic;
`

export const style = {
  light,
  regular,
  bold,
  italic
}
