import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { T, NVSteps } from '@nv/react-commons/src/Components'
import { Text } from 'components/Text'
import { CountrySelection } from './CountrySelection'
import { StyledCard, HBorder, StyledImgFlag, CountryOption, PreviewWrapper } from './styles'
import { Vspace } from 'components/Vspace'
import { useIntl } from 'hooks/common'
import { ACTION_MODE, ASIAN_COUNTRIES, FLAGS_BY_COUNTRY, FORM_FIELD } from './constants'
import { StepCard } from './StepCard'
import { faShippingTimed } from '@fa-pro-light/faShippingTimed'
import { OCSubStep } from 'components/OCSubStep'

const { Step } = NVSteps

const initialState = {
  selectedToSupportedCountries: [],
  selectedToUnsupportedCountries: []
}

export const ServiceDetailCard = ({ onSave, onChangeMode, mode, origin, destination }) => {
  const intl = useIntl()
  const [state, setState] = useState(initialState)
  const { selectedToSupportedCountries, selectedToUnsupportedCountries } = state

  const handleSaveForm = data => {
    const { selectedSupportedCountries, selectedUnsupportedCountries } = data
    setState({
      selectedToSupportedCountries: selectedSupportedCountries,
      selectedToUnsupportedCountries: selectedUnsupportedCountries
    })
    if (mode === ACTION_MODE.CREATE) {
      const params = {
        selectedSupportedToCountries: selectedSupportedCountries,
        selectedUnsupportedToCountries: selectedUnsupportedCountries
      }
      onSave(FORM_FIELD.DESTINATION, params)
    }
  }

  const handleSaveToCountry = () => {
    const params = {
      selectedSupportedToCountries: selectedToSupportedCountries,
      selectedUnsupportedToCountries: selectedToUnsupportedCountries
    }
    onSave(FORM_FIELD.DESTINATION, params)
    onChangeMode(ACTION_MODE.DONE)
  }

  const handleCancelToCountry = () => {
    setState({
      selectedToSupportedCountries: selectedToSupportedCountries,
      selectedToUnsupportedCountries: selectedToUnsupportedCountries
    })
    onChangeMode(ACTION_MODE.DONE)
  }

  const handleEdit = () => {
    setState({
      selectedToSupportedCountries: destination.activeSupportedCountries,
      selectedToUnsupportedCountries: destination.activeUnsupportedCountries
    })
    onChangeMode(ACTION_MODE.EDIT)
  }

  const renderActive = country => {
    if (country === FORM_FIELD.DESTINATION) {
      return (
        <CountrySelection
          countries={destination.allCountries}
          label='international_ship_to_country'
          isRequiredField
          selectedCountries={[...selectedToSupportedCountries, ...selectedToUnsupportedCountries]}
          supportedCountries={destination.supportedCountries}
          unsupportedCountries={destination.unsupportedCountries}
          selectedSupportedCountries={selectedToSupportedCountries}
          selectedUnsupportedCountries={selectedToUnsupportedCountries}
          isInGroup
          onSelectCountry={handleSaveForm}
        />
      )
    }
    return (
      <CountrySelection
        countries={origin.allCountries}
        selectedCountries={origin.selectedCountries}
        defaultCountries={origin.selectedCountries}
        label='international_ship_from_country'
        isRequiredField
        disabled
        isInGroup={false}
      />
    )
  }

  const renderPreview = type => {
    const countryOptions = type === FORM_FIELD.ORIGIN ? origin.selectedCountries : destination.selectedCountries
    return (
      <PreviewWrapper>
        {countryOptions.map(countryCode => {
          return (
            <CountryOption key={countryCode}>
              <StyledImgFlag src={FLAGS_BY_COUNTRY[countryCode]} alt={`${countryCode} flag`} />
              <span>{ASIAN_COUNTRIES[countryCode]}</span>
            </CountryOption>
          )
        })}
      </PreviewWrapper>
    )
  }

  const renderServiceContent = () => {
    if (mode === ACTION_MODE.CREATE) {
      return (
        <StyledCard padding='24px 32px'>
          {renderActive(FORM_FIELD.ORIGIN)}
          <Vspace height={24} />
          {renderActive(FORM_FIELD.DESTINATION)}
        </StyledCard>
      )
    }

    return (
      <Step
        description={
          <StyledCard>
            <StepCard
              title={intl.formatMessage({ id: 'international_from_label' })}
              renderActive={() => renderActive(FORM_FIELD.ORIGIN)}
              renderPreview={() => renderPreview(FORM_FIELD.ORIGIN)}
              disabledEdit
              icon={faShippingTimed}
            />
            <HBorder />
            <StepCard
              title={intl.formatMessage({ id: 'international_to_label' })}
              renderActive={() => renderActive(FORM_FIELD.DESTINATION)}
              renderPreview={() => renderPreview(FORM_FIELD.DESTINATION)}
              onSave={handleSaveToCountry}
              onCancel={handleCancelToCountry}
              icon={faShippingTimed}
              onEdit={handleEdit}
              status={mode === ACTION_MODE.EDIT ? OCSubStep.OPEN : OCSubStep.CLOSE}
              hideSave={mode === ACTION_MODE.EDIT && !selectedToSupportedCountries.length}
            />
          </StyledCard>
        }
      ></Step>
    )
  }

  return (
    <>
      <Text size={14} type='bold'>
        <T id='service_details' />
      </Text>
      <Vspace height={8} />
      {renderServiceContent()}
    </>
  )
}

ServiceDetailCard.propTypes = {
  onSave: PropTypes.func,
  mode: PropTypes.instanceOf(ACTION_MODE),
  onChangeMode: PropTypes.func,
  origin: PropTypes.shape({
    allCountries: PropTypes.array,
    selectedCountries: PropTypes.array
  }),
  destination: PropTypes.shape({
    allCountries: PropTypes.array,
    selectedCountries: PropTypes.array,
    supportedCountries: PropTypes.array,
    unsupportedCountries: PropTypes.array,
    activeSupportedCountries: PropTypes.array,
    activeUnsupportedCountries: PropTypes.array
  })
}

ServiceDetailCard.defaultProps = {
  mode: ACTION_MODE.CREATE,
  origin: {
    allCountries: [],
    selectedCountries: []
  },
  destination: {
    allCountries: [],
    selectedCountries: [],
    supportedCountries: [],
    unsupportedCountries: [],
    activeSupportedCountries: [],
    activeUnsupportedCountries: []
  }
}
