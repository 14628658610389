import React from 'react'
import { Text } from 'components/Text'
import { ErrorMessage, TextWrapper, StyledIcon, InlineText } from 'containers/FPLAllOrders/styles'
import { faCheck } from '@fa-pro-solid/faCheck'
import { faTimes } from '@fa-pro-regular/faTimes'
import PropTypes from 'prop-types'
import { Colors } from 'themes'

export const InlineErrorMessage = ({ fileName, errorMessages, isLoading, successMessage }) => {
  return (
    <TextWrapper paddingTop={8}>
      {fileName && !isLoading && (
        <InlineText>
          <StyledIcon
            icon={errorMessages.length === 0 ? faCheck : faTimes}
            success={errorMessages.length === 0 ? 'success' : null}
          />
          <Text>{fileName}</Text>
        </InlineText>
      )}
      {errorMessages.length !== 0 && !isLoading && (
        <TextWrapper paddingTop={8}>
          {errorMessages.map((msg, idx) => (
            <ErrorMessage key={idx}>{msg}</ErrorMessage>
          ))}
        </TextWrapper>
      )}
      {successMessage && !errorMessages.length && !isLoading && (
        <Text color={Colors.positiveBalance} size={12}>
          {successMessage}
        </Text>
      )}
    </TextWrapper>
  )
}

InlineErrorMessage.propTypes = {
  fileName: PropTypes.string,
  errorMessages: PropTypes.array,
  isLoading: PropTypes.bool,
  successMessage: PropTypes.string
}

InlineErrorMessage.defaultProps = {
  fileName: '',
  errorMessages: [],
  isLoading: false,
  successMessage: null
}
