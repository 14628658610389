import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Dropdown, T } from '@nv/react-commons/src/Components'
import { Button } from 'components/Button'
import { faList } from '@fa-pro-regular/faList'
import { withSize } from 'components/BreakpointListener'

const MobileButton = styled(Button).attrs({
  size: 'small'
})`
  && {
    text-align: right;
    border: none;
    padding: 0;
  }
`

const DesktopActionsButton = ({ disabled, overlay, ...props }) => {
  return (
    <Dropdown
      disabled={disabled}
      overlay={overlay}
      trigger={['click']}
      placement='bottomRight'
      getPopupContainer={() => document.body}
    >
      <Button {...props} icon={faList} rightIcon='dropdown'>
        <T id='actions' />
      </Button>
    </Dropdown>
  )
}
DesktopActionsButton.propTypes = {
  overlay: PropTypes.node,
  disabled: PropTypes.bool
}

const MobileActionsButton = ({ disabled, overlay, ...props }) => {
  return (
    <Dropdown disabled={disabled} overlay={overlay} trigger={['click']} placement='bottomRight'>
      <MobileButton {...props} rightIcon='ellipsis'>
        <T id='actions' />
      </MobileButton>
    </Dropdown>
  )
}
MobileActionsButton.propTypes = {
  overlay: PropTypes.node,
  disabled: PropTypes.bool
}

const ActionsButton = props => {
  const { isDesktop } = props
  return isDesktop ? <DesktopActionsButton {...props} /> : <MobileActionsButton {...props} />
}

ActionsButton.propTypes = {
  overlay: PropTypes.node,
  isDesktop: PropTypes.bool,
  disabled: PropTypes.bool
}

ActionsButton.defaultProps = {
  overlay: 'ActionsButton'
}

const _ActionsButton = withSize(ActionsButton)
export { _ActionsButton as ActionsButton, DesktopActionsButton }
