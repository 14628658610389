const CDN_URL = 'https://storage.ninjavan.co/dash/frontend'
const ONBOARDING_URL = `${CDN_URL}/onboarding`

const Images = {
  /**
   * Flag icons source: https://icons8.com/icon/pack/flags/color--png
   */
  flags: {
    id: require('./flags/id.png'),
    mm: require('./flags/mm.png'),
    my: require('./flags/my.png'),
    ph: require('./flags/ph.png'),
    sg: require('./flags/sg.png'),
    th: require('./flags/th.png'),
    vn: require('./flags/vn.png'),
    ma: require('./flags/ma.png')
  },
  onboarding: {
    accounts: `${ONBOARDING_URL}/accounts.png`,
    appAndEnd: `${ONBOARDING_URL}/app-and-end.png`,
    createOrders: `${ONBOARDING_URL}/create-orders.png`,
    leftNav: `${ONBOARDING_URL}/left-nav.png`,
    newPickups: `${ONBOARDING_URL}/new-pickups.png`,
    notified: `${ONBOARDING_URL}/notified.png`,
    trackOrders: `${ONBOARDING_URL}/track-orders.png`,
    welcome: `${ONBOARDING_URL}/welcome.png`,
    welcomeMobile: `${ONBOARDING_URL}/welcome-mobile.png`
  },
  lateNinja: require('./late-ninja.png'),
  ninjadash: require('./ninjadash-logo.png'),
  ninjadashBlackBg: require('./ninjadash-logo-black-bg.png'),
  ninjadashCollapsed: require('./ninjadash-collapsed.png'),
  ryoNotFound: require('./ryo-not-found.png'),
  ryoMagnifyingGlass: require('./ryo-magnifying-glass.png'),
  poweredByIPay88: require('./powered-by-ipay88.png'),
  pudoBox: require('./pudo-box.png'),
  pudoBoxSelected: require('./pudo-box-selected.png'),
  pudoPoint: require('./pudo-point.png'),
  pudoPointSelected: require('./pudo-point-selected.png'),
  runningNinja: require('./running-ninja.png'),
  ryo4XX: require('./ryo-4XX.png'),
  ryoJumping: require('./ryo-jumping.png'),
  ryoWorking: require('./ryo-working.png'),
  appstore: require('./appstore.png'),
  googleplay: require('./googleplay.png'),
  cdWhite: require('./chrono-diali-white.png'),
  cdTruck: require('./chrono-diali-truck.svg'),
  thumbsUp: require('./thumbs-up.svg'),
  cd4XX: require('./chrono-diali-4XX.svg'),
  cdNotFound: require('./chrono-diali-not-found.svg'),
  cdSwitchAccount: require('./chrono-diali-switch-account.svg'),
  grabExpressLogo: require('./grab-express-motorbike.png'),
  grabExpress: require('./grab-express.png'),
  ninjachatFeatureTourChat: require('./ninjachat-feature-tour-chat.png'),
  ryoInternational: require('./ryo-international.png'),
  internationalShipping: require('./international_shipping.png'),
  editPopup: require('./edit_popup.png'),
  parcelEvents: require('./parcel_events.png'),
  itemisedParcel: require('./itemised_parcel.png'),
  trackingPage: require('./tracking_page.png'),
  uploadCsv: require('./upload_csv.png'),
  ryoCash: require('./ryo-cash.png'),
  ryoHooray: require('./ryo-hooray.png'),
  ryoFix: require('./ryo-fix.png'),
  ryoSorry: require('./ryo-sorry.png'),
  ryoNoReturnParcels: require('./ryo-no-return-parcels.png'),
  ryoHappy: require('./ryo-happy.png'),
  ryoSuccess: require('./ryo-success.png'),
  ryoAnnounce: require('./ryo-announce.png'),
  ryoCheckList: require('./ryo-check-list.png'),
  ryoHelp: require('./ryo-help.png'),
  ryoSupport: require('./ryo-support.png'),
  ryoLooking: require('./ryo-looking.png'),
  modalBackground: require('./modal-background.svg'),
  helpdeskBackground: require('./helpdesk-background.png')
}

export { Images }
