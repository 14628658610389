/**
 *
 * RequireAuth
 * A component that renders inside Route component,
 * if not authenticated the user will be redirected to login,
 * if authenticated rended children.
 *
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import { ROUTES } from 'containers/Base/constants'
import { useSelector } from 'react-redux'
import { selectIsAuthenticated } from '../Base/selectors'
import { useStorage } from 'hooks/useStorage'
import { REDIRECT_URL_STORAGE_KEY } from 'components/NavigationRoutes'

const RequireAuth = ({ children, isOauth }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated())
  const location = useLocation()
  const [, setRedirectUrl] = useStorage(REDIRECT_URL_STORAGE_KEY)

  useEffect(() => {
    if (!isAuthenticated) {
      setRedirectUrl(location.pathname + location.search)
    } else {
      setRedirectUrl('')
    }
  }, [isAuthenticated, setRedirectUrl, location.pathname, location.search])
  const redirectTo = isOauth ? `${ROUTES.OAUTH_LOGIN}${location.search}` : `${ROUTES.LOGIN_V2}`
  return isAuthenticated ? children : <Navigate to={redirectTo} replace />
}

RequireAuth.propTypes = {
  children: PropTypes.any,
  isOauth: PropTypes.bool
}

export { RequireAuth }
