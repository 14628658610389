import { ROUTES } from 'containers/Base/constants'
import { RESHIP_RETURN_OPTIONS } from 'components/ReturnParcelFulfillmentDrawer/constant'

export const DATE_FORMAT = 'YYYY-MM-DDTHH:MM:SS'
export const TRACK_VISITED_INTERNATIONAL_HOME = {
  NAME: 'Visited International Page',
  PROPS: {
    PARTNER_ID: 'Partner ID',
    SHIPPER_ID: 'Shipper ID',
    INSTANCE: 'Shipper Account Country',
    PAGE_NAME: 'Page Name',
    XB_FULFILLMENT_STORAGE: 'XB Fulfillment Storage'
  },
  PEOPLE: {
    FIRST_LOGIN_DATE: 'First Login Date',
    FIRST_ONBOARD_DATE: 'First Onboarded To XB Date'
  }
}

export const TRACK_VIEWED_ORDER = {
  NAME: 'Viewed International Order',
  PROPS: {
    PAGE_NAME: 'Page Name'
  }
}

export const TRACK_VIEWED_ALL_ORDER = {
  NAME: 'Viewed International All Order',
  PROPS: {
    PAGE_NAME: 'Page Name'
  }
}

export const TRACK_FIXED_ORDER = {
  NAME: 'Fixed International Order',
  PROPS: {
    ERROR_MESSAGE: 'Error Message'
  }
}

export const TRACK_DOWLOADED_INVALID_ORDER = {
  NAME: 'Downloaded International Invalid Order',
  PROPS: {
    PAGE_NAME: 'Page Name'
  }
}

export const TRACK_REUPLOADED_ORDER = {
  NAME: 'Reuploaded International Order',
  PROPS: {
    PAGE_NAME: 'Page Name'
  }
}

export const TRACK_PRINTED_AIRWAY_BILL = {
  NAME: 'Printed International Airway Bill',
  PROPS: {
    PRINTED_AIRWAY_BILL_STATUS: 'Printed Airway Bill Status',
    SOURCE: 'Source',
    NUMBER_OF_PRINTED_ORDER: 'Number Of Printed Order',
    PAGE_NAME: 'Page Name'
  }
}

export const TRACK_DELETED_INVALID_ORDER = {
  NAME: 'Deleted International Invalid Order',
  PEOPLE: {
    NUMBER_OF_ORDERS_DELETED: 'Number Of Orders Deleted'
  }
}

export const TRACK_TRACKED_ORDER_ID = {
  NAME: 'Tracked International Order ID',
  PEOPLE: {
    NUMBER_OF_SEARCHES: 'Number Of Searches'
  },
  PROPS: {
    PAGE_NAME: 'Page Name'
  }
}

export const TRACK_STARTED_INTL_ORDER_CREATION = {
  NAME: 'Started International Order Creation',
  PROPS: {
    SOURCE: 'XB Dash',
    PAGE_NAME: 'Page Name',
    ORDER_TYPE: 'Order Type'
  },
  PEOPLE: {
    NUMBER_OF_OC_ATTEMPTS: 'Number Of Order Creation Attempts'
  }
}

export const TRACK_ADDED_INTL_SERVICE_DETAIL = {
  NAME: 'Added International Service Detail',
  PROPS: {
    SERVICE_TYPE: 'Service Type',
    PICKUP_OPTION: 'Pickup Option',
    PAGE_NAME: 'Page Name',
    DEFAULT_RETURN_ADDRESS: 'Default Return Address',
    CUSTOMS_CURRENCY: 'Customs Currency',
    PICKUP_ADDRESS: 'Pickup Address',
    TIME_SLOT: 'Time slot',
    NUMBER_OF_ERRORS: '# of Errors',
    ELEMENT_SHOW_ERROR: 'Element Show Error',
    ERROR_MESSAGE: 'Error Message',
    ORDER_TYPE: 'Order Type'
  }
}

export const TRACK_CONFIRMED_THE_ORDER = {
  NAME: 'Confirmed International Order Creation',
  PROPS: {
    NUMBER_OF_VALID_ORDER: 'Number Of Valid Order',
    NUMBER_OF_INVALID_ORDER: 'Number Of Invalid Order',
    TOTAL_ORDERS: 'Total Orders',
    ODER_COD_AMOUNT: "Order's COD Amount",
    INPUT_METHOD: 'Input Method',
    SERVICE_TYPE: 'Service Type',
    PAGE_NAME: 'Page Name',
    ORDER_TYPE: 'Order Type'
  },
  PEOPLE: {
    LAST_OF_CREATED: 'Last Order Created',
    LT_CREATED_ORDER: 'Lifetime Created Order'
  }
}

export const TRACK_PERFORMANCE_OC = {
  NAME: 'Loaded XB Batch Detail Successfully',
  PROPS: {
    API_LOADING_TIME: 'API Loading Time',
    UI_RENDERING_TIME: 'UI Rendering Time',
    ORDER_TYPE: 'Order Type'
  }
}

export const TRACK_ABANDONED_ORDER_CREATION = {
  NAME: 'Abandoned International Order Creation',
  PROPS: {
    NUMBER_OF_ORDER_ABANDONED: 'Number Of Order Abandoned',
    PAGE_NAME: 'Page Name',
    ORDER_TYPE: 'Order Type'
  },
  PEOPLE: {
    LIFETIME_ABANDONED_ORDER: 'Lifetime Abandoned Order',
    LAST_ABANDONED_ORDER_DATE: 'Last Abandoned Order Date'
  }
}

export const TRACK_DOWLOADED_ALL_ORDERS = {
  NAME: 'Downloaded International Order',
  PROPS: {
    NUMBER_DOWNLOADED_ORDERS: 'Number Of Order Downloads'
  }
}

export const TRACK_SEARCH_SINGLE_ORDER = {
  NAME: 'Searched International Order',
  PEOPLE: {
    NUMBER_OF_SEARCHES: 'Number Of Searches'
  }
}

export const TRACK_FILTERED_INTERNATIONAL_ORDER = {
  NAME: 'Filtered International Order',
  PROPS: {
    STATUS_GROUP: 'Status Group',
    DATE_RANGE: 'Date Range'
  }
}

export const TRACK_ADVANCED_SEARCH_ORDER = {
  NAME: 'Advanced Search International Order',
  PROPS: {
    SEARCH_METHOD: 'Search Method',
    NUMBER_OF_SEARCHES: 'Number Of Searches'
  }
}

export const TRACK_CLICKED_TO_REPLACE_FILE = {
  NAME: 'Clicked To Replace File'
}

export const TRACK_CLICKED_TO_REUPLOAD_FILE = {
  NAME: 'Clicked To Re-Upload File'
}

export const TRACK_CLICKED_TO_VIEW_DETAIL_TO_CORRECT_ERROR = {
  NAME: 'Clicked To View Detail To Correct Error'
}

export const TRACK_CLICKED_ADD_ANOTHER = {
  NAME: 'Clicked Add Another'
}

export const TRACK_ADDED_INTL_ORDER_DETAIL = {
  NAME: 'Added International Order Detail',
  PROPS: {
    METHOD: 'Method',
    NUMBER_OF_INVALID_ORDER: 'Number Of Invalid Order',
    NUMBER_OF_VALID_ORDER: 'Number Of Valid Order',
    TOTAL_ORDERS: 'Total Orders',
    ITEMISED_OR_NORMAL: 'Itemised Or Normal',
    ORDER_TYPE: 'Order Type'
  }
}

export const TRACK_CLICKED_FIX_ORDER = {
  NAME: 'Clicked Fix Order',
  PROPS: {
    PAGE_NAME: 'Page Name'
  },
  PEOPLE: {
    LT_INVALID_ORDER_CREATED: 'Lifetime Invalid Order Created'
  }
}

export const PAGE_NAMES = {
  [ROUTES.FPL_OC]: 'E2E Order Creation',
  [ROUTES.FPL_OC_REQUEST_BY_BATCH]: 'Order Request List',
  [ROUTES.FPL_OC_HISTORY]: 'Order History',
  [ROUTES.FPL_PARCEL_DETAIL]: 'Order Detail',
  [ROUTES.FPL_HOME]: 'Home',
  [ROUTES.FPL_TRACKING]: 'Order Tracking',
  [ROUTES.FPL_TRACKING_SEARCH]: 'Order Tracking Search',
  [ROUTES.FPL_ORDER_BATCH]: 'Batch Orders',
  [ROUTES.FPL_ORDERS]: 'All Orders',
  [ROUTES.FPL_SERVICE_REGISTRATION]: 'Registration Service',
  [ROUTES.FPL_MMCC_ORDER_CREATION]: 'MMCC Order Creation'
}

export const TRUTHY_FALSY = {
  YES: 'Yes',
  NO: 'No'
}

export const TRACK_CLICKED_XB_RETURN_PARCEL = {
  NAME: 'Clicked XB Return Parcel',
  PROPS: {
    INSTANCE: 'Instance'
  }
}

export const TRACK_DISPOSE_XB_RETURN_PARCEL = {
  NAME: 'Disposed XB Return Parcel',
  PROPS: {
    NUMBER_OF_SELECTED_PARCELS: 'Number of Selected Parcels'
  },
  PEOPLE: {
    LT_DISPOSE_PARCEL: 'Lifetime Dispose Parcel'
  }
}

export const TRACK_RETURNED_TO_ME_XB_RETURN_PARCEL = {
  NAME: 'Returned to me XB Return Parcel',
  PROPS: {
    NUMBER_OF_SELECTED_PARCELS: 'Number of Selected Parcels',
    RETURN_OPTIONS: 'Return Options'
  },
  PEOPLE: {
    LT_RETURN_TO_ME_PARCEL: 'Lifetime Return To Me Parcel'
  }
}
export const TRACK_RELABELED_XB_RETURN_PARCEL = {
  NAME: 'Relabeled XB Return Parcel',
  PROPS: {
    NUMBER_OF_SELECTED_PARCELS: 'Number of Selected Parcels',
    METHOD: 'Method',
    RELABEL_RESULTS: 'Relabel Results',
    UI_RENDERING_TIME: 'UI Rendering Time',
    LT_RELABELED_PARCEL: 'Lifetime Relabeled Parcel'
  },
  PEOPLE: {
    LT_RELABELED_PARCEL: 'Lifetime Relabeled Parcel'
  }
}

export const TRACK_ADDED_BULK_RELABELED_ORDER_DETAIL = {
  NAME: 'Added Bulk Relabeled Order Detail',
  PROPS: {
    NUMBER_OF_VALID_ORDER: 'Number Of Valid Order',
    NUMBER_OF_INVALID_ORDER: 'Number Of Invalid Order'
  }
}

export const TRACK_CLICKED_SUBMITTED_FOR_FULFILLMENT = {
  NAME: 'Clicked Submitted For Fulfillment'
}

export const TRACK_CLICKED_AUTO_DISPOSED = {
  NAME: 'Clicked Auto-Disposed'
}

export const TRACK_LOADED_ERROR_PAGE = {
  NAME: 'Loaded Error Page',
  PROPS: {
    PREVIOUS_PAGE: 'Previous Page',
    API_CAUSES_ERROR: 'API Causes Error',
    ERROR_MESSAGE: 'Error Message',
    ERROR_STATUS: 'Error Status'
  }
}

export const RETURN_OPTIONS = {
  [RESHIP_RETURN_OPTIONS.SELF_PICKUP]: 'Self-Pickup',
  [RESHIP_RETURN_OPTIONS.MY_ADDRESS_ORIGIN_COUNTRY]: 'Origin Address',
  [RESHIP_RETURN_OPTIONS.MY_ADDRESS_DESTINATION_COUNTRY]: 'Destination Address'
}
export const CREATION_SOURCE = {
  DASH: 'Dash',
  XB_DASH: 'XB Dash'
}

export const TRACK_BULK_UPLOAD_FILE = {
  NAME: 'Displayed Result For Bulk Upload',
  PROPS: {
    UPLOAD_RESULT: 'Upload Results',
    NUMBER_OF_VALID_ORDER: 'Number Of Valid Order',
    NUMBER_OF_INVALID_ORDER: 'Number Of Invalid Order',
    TOTAL_ORDERS: 'Total Orders',
    ERROR_MESSAGE: 'Error Message',
    ORDER_TYPE: 'Order Type'
  }
}

export const OPENED_ONBOARDING = {
  NAME: 'Opened XB Onboarding',
  PROPS: {
    SOURCE: 'Source'
  }
}

export const SELECT_LANES_ONBOARDING = {
  NAME: 'Selected XB Lanes',
  PROPS: {
    LANE: 'Lane',
    UNSUPPORTED_LANE: 'Unsupported Lane',
    WEIGHT_PER_PARCEL: 'Weight Per Parcel',
    PARCEL_PER_DAY: 'Parcel Per Day'
  }
}

export const DOWNLOAD_RESTRICTION_ONBOARDING = {
  NAME: 'Downloaded XB Restrictions List'
}

export const EXIT_INTERNATIONAL_ONBOARDING = {
  NAME: 'Exit XB Onboarding'
}

export const SHOW_ONBOARDING_RATE_CARD = {
  NAME: 'Showed XB Rate Card'
}

export const MP_ORDER_TYPE = {
  E2E: 'E2E',
  MMCC_B2B: 'MMCC B2B',
  MMCC_B2C: 'MMCC B2C'
}

export const ADDED_CI_FILE = {
  NAME: 'Added CI File',
  PROPS: {
    FILE_TYPE: 'File Type',
    FILE_SIZE: 'File Size',
    NUMBER_OF_FILES: 'Number Of Files',
    ORDER_TYPE: 'Order Type'
  }
}

export const SKIP_CI_FILE = {
  NAME: 'Skipped CI File',
  PROPS: {
    ORDER_TYPE: 'Order Type'
  }
}

export const ADDED_DO_FILE = {
  NAME: 'Added DO File',
  PROPS: {
    FILE_TYPE: 'File Type',
    FILE_SIZE: 'File Size',
    NUMBER_OF_FILES: 'Number Of Files',
    ORDER_TYPE: 'Order Type'
  }
}

export const SKIP_DO_FILE = {
  NAME: 'Skipped DO File',
  PROPS: {
    ORDER_TYPE: 'Order Type'
  }
}
