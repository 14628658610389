import { Col, Row } from '@nv/react-commons/src/Components'
import { Button } from 'components/Button'
import { Text } from 'components/Text'
import styled from 'styled-components'
import { Colors, media } from 'themes'

export const StyledButton = styled(Button)`
  && {
    width: 100%;
  }
`

export const StyledBannerRow = styled(Row).attrs({
  type: 'flex'
})`
  && {
    flex-wrap: nowrap;
    align-items: center;
    padding: 0 24px;
    overflow: hidden;
    background-color: ${Colors.nvPriRed};
    ${media.max.desktop`padding: 0 8px;`};
    ${media.max.tablet`
    flex-direction: column;
    padding: 0;
  `};
    ${media.min.tablet`
    `};
  }
`

export const StyledTitleCol = styled(Col)`
  && {
    margin-left: 20px;
    margin-right: 20px;
  }
`

export const StyledTitle = styled(Text).attrs({
  type: 'bold'
})`
  color: ${props => props.color || Colors.white};
  ${media.max.tablet`
    text-align: center;
  `};
`

export const StyledButtonCol = styled(Col)`
  && {
    ${media.min.tablet`margin: 0 0 0 auto;
    max-width: 225px;
    `};
    ${media.max.tablet`
    padding: 0 16px;
    margin: 0 0 32px;
  `};
  }
`

export const StyledOrderActionButton = styled(Button)`
  && {
    width: 100%;
    ${media.min.lgTablet`
      max-width: 200px;
    `};
    ${media.max.tablet`margin: 16px auto 0;`};
  }
`

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: ${Colors.white};
  padding: 16px;

  ${media.min.tablet`
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 40px;
  `};

  ${media.max.tablet`
    width: auto;
    margin: 40px auto 16px;
  `};
`

export const StyledSectionSummary = styled(StyledSection)`
  padding-bottom: 0;
`

export const StyledSectionRow = styled(Row).attrs({
  type: 'flex'
})`
  && {
    flex-wrap: nowrap;
    flex: 1;
    padding: 16px 0px;
    ${media.min.tablet`
      && {
        flex-direction: column;
        padding: 16px 20px;
        &:first-child {
          padding-left: 0px;
        }
        &:last-child {
          padding-right: 0px;
        }
      }
    `};
  }
`

export const Spacing = styled.div`
  height: 4px;
  min-height: 4px;

  ${media.min.tablet`
    && {
      height: 20px;
      min-height: 20px;
    }
  `};
`

export const BatchWrapper = styled.div`
  margin-top: 20px;
  overflow: auto;
`
