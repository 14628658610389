import { createContext } from 'react'
import { MMCCOrderCreationState, Service } from './types'
import { CCType } from './constants'

interface MMCCOrderCreationContextType {
  ocState: MMCCOrderCreationState
  updateService: (newService: Service) => void
  updateCCType: (ccType: CCType) => void
  updateOCState: (newState: Partial<MMCCOrderCreationState>) => void
}

export const MMCCOrderCreationContext = createContext<MMCCOrderCreationContextType>({
  ocState: null,
  updateService: () => null,
  updateCCType: () => null,
  updateOCState: () => null
})
