/**
 *
 * MappingNameModal
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'
import { Form, T } from '@nv/react-commons/src/Components'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { RouteModal } from 'components/RouteModal'
import { withNavigate } from '../RouterHOCs'

const FormItem = Form.Item

export class MappingNameModal extends React.Component {
  static PATH = 'name'

  handleSubmit = e => {
    e.preventDefault()
    const { navigate, form, onSubmit } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values)
        navigate(-1)
      }
    })
  }

  render () {
    const {
      title,
      name,
      form: { getFieldDecorator },
      intl
    } = this.props
    const max = 40
    return (
      <RouteModal
        type='form'
        path={MappingNameModal.PATH}
        title={<T id={title} />}
        footer={
          <FormItem>
            <Button
              type='primary'
              size='small'
              onClick={this.handleSubmit}
              data-analyticsid='submitNewCSVMappingTemplateName'
            >
              <T id='save' />
            </Button>
          </FormItem>
        }
      >
        <Form onSubmit={this.handleSubmit}>
          <FormItem label={<T id='name_of_mapping_template' />}>
            {getFieldDecorator('name', {
              initialValue: name,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'mapping_name_required' })
                },
                {
                  max,
                  message: intl.formatMessage({ id: 'mapping_name_max_length' }, { max })
                }
              ]
            })(<Input placeholder='my_shop' />)}
          </FormItem>
        </Form>
      </RouteModal>
    )
  }
}

MappingNameModal.propTypes = {
  form: PropTypes.object,
  navigate: PropTypes.func,
  intl: PropTypes.object,
  name: PropTypes.string,
  onSubmit: PropTypes.func,
  title: PropTypes.string.isRequired
}

const _MappingNameModal = compose(injectIntl, withNavigate, Form.create())(MappingNameModal)

_MappingNameModal.PATH = MappingNameModal.PATH

export default _MappingNameModal
