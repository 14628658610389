import { CountryUtils } from '@nv/react-commons/src/Utils'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { getEmailDomain } from './getEmailDomain'

export const regexp = {
  // eslint-disable-next-line max-len
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  listDelimiter: /[,;]\s*|\s+/
}

/**
 * The regex used is the same as https://github.com/yiminghe/async-validator that antd is using
 * Authoritative source for email regex: https://emailregex.com/
 */
export function validateEmail (email) {
  return regexp.email.test(email)
}

export function validateEmailAndDomain (email, allowedDomains) {
  let error = ''

  const isValid = validateEmail(email)
  if (!isValid) {
    error = 'invalid_email'
  }

  if (!error) {
    const domain = getEmailDomain(email)
    if (allowedDomains?.length > 0 && !allowedDomains.includes(domain)) {
      error = 'email_supported_domain_error'
    }
  }

  return { error }
}

export function validateEastMalaysiaPostcode ({ country, isNinjaPackOrder, value }) {
  if (country.toUpperCase() !== COUNTRIES.MY.toUpperCase()) return false
  const postcodeInfo = CountryUtils.getPostcodeInfo(country)
  if (isNinjaPackOrder && value !== undefined && value.toString().length === postcodeInfo.length && !isNaN(value)) {
    const val = +value
    if ((val >= 87000 && val <= 87033) || (val >= 88000 && val <= 91309) || (val >= 93000 && val <= 98859)) {
      return true
    }
  }
  return false
}

export function validatePassword (rule, value, callback) {
  let error
  if (value && !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(value)) {
    error = `${rule.field} is invalid`
  }
  callback(error)
}

export const validateCODAmount = (value, prevAmount, country, creditTerm) => {
  let error
  const minValue = 33000
  if (value > prevAmount) {
    error = { message: { id: 'cod_amount_lower_than_prev' } }
  } else if (country?.toLowerCase() === COUNTRIES.VN && creditTerm == 0 && value < minValue) {
    error = {
      message: { id: 'cod_amount_more_or_equal_x' },
      values: { x: `${minValue} ${CountryUtils.getCurrencyCode(country)}` }
    }
  } else if (value < 0) {
    error = { message: { id: 'cod_amount_more_than_x' }, values: { x: 0 } }
  }
  return error
}
