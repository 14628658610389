import Config from 'configs'
import { getDomainSuffix } from './getDomainSuffix'

type UrlConfig = {
  country?: string
  service: string
}

export const getBaseDomain = () => {
  let baseUrl = Config.BASE_URL
  // If current domain suffix is 'cn', make sure TLD of base url is also 'cn'
  if (getDomainSuffix(window.location.host) === 'cn') {
    baseUrl = Config.BASE_URL.replace(`.${getDomainSuffix(new URL(Config.BASE_URL).host)}`, '.cn')
  }
  return `${baseUrl}`
}

export const getBaseUrl = ({ country = 'global', service }: UrlConfig): string | undefined => {
  if (Config.IS_LOCAL) {
    return Config.BASE_URL
  }

  // If current domain suffix is 'cn', make sure TLD of base url is also 'cn'
  const baseUrl = getBaseDomain()
  return `${baseUrl}/${country}/${service}/`
}
