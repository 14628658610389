import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useField } from 'formik'
import { CopyIcon, TrashCanIcon, CrossCircleFilledIcon, ExclamationCircleIcon, Link } from '@nv/react-akira'
import { DraggableUploadFieldProps } from './DraggableUploadField.interface'
import clsx from 'clsx'
import { UploadFile } from 'antd/lib/upload/interface'
import { Upload } from 'antd'
import {
  DraggerInfoText,
  DraggerWrapper,
  FileNameText,
  FileNameWrapper,
  StyledShareIcon
} from './DraggableUploadField.styles'
import { useIntl } from 'hooks/common'
const { Dragger } = Upload

const MB_SIZE = 1000000

export const DraggableUploadField = ({
  label,
  name,
  secondaryLabel,
  accept,
  multiple = true,
  totalSizeInMB = 25,
  fileSizeInMB = 5,
  uploadHint
}: DraggableUploadFieldProps) => {
  const intl = useIntl()
  const [field, meta, { setValue, setError }] = useField<UploadFile[]>({
    name,
    validate: updatedFiles => {
      let totalSize = 0
      updatedFiles?.forEach(file => {
        totalSize += file.size
      })

      if (totalSize > totalSizeInMB * MB_SIZE) {
        return intl.formatMessage({ id: 'shipper_helpdesk.form.error.file_exceed_max_size' })
      }
    }
  })

  const validateAndUpdateFiles = updatedFiles => {
    let totalSize = 0

    updatedFiles?.forEach(file => {
      const fileSize = file.size
      totalSize += fileSize
      if (fileSize > fileSizeInMB * MB_SIZE) {
        file.error = intl.formatMessage(
          { id: 'shipper_helpdesk.form.error.file_exceed_each_file_size' },
          { fileSizeInMB }
        )
      }
    })
    if (totalSize > totalSizeInMB * MB_SIZE) {
      setValue(updatedFiles, false)
      setError(intl.formatMessage({ id: 'shipper_helpdesk.form.error.file_exceed_max_size' }))
    } else {
      setValue(updatedFiles)
    }
  }

  const onChangeFile = info => {
    const updatedFiles = multiple ? info.fileList : [info.fileList.pop()]
    validateAndUpdateFiles(updatedFiles)
  }

  const onRemoveFile = removedFile => {
    const updatedFiles = meta.value.filter(file => file.uid !== removedFile.uid)
    validateAndUpdateFiles(updatedFiles)
  }

  return (
    <DraggerWrapper>
      <div className={`text-8 flex items-center justify-between pb-2 text-grey-2`}>
        <label className='text-inherit' htmlFor={name}>
          {label}
        </label>
        {!!secondaryLabel && <label className='text-inherit'>{secondaryLabel}</label>}
      </div>
      <Dragger
        {...field}
        id={name}
        name={name}
        multiple={multiple}
        accept={accept}
        beforeUpload={() => false} //  Prevents the auto upload functionality
        onChange={info => onChangeFile(info)}
        fileList={meta.value} // This is needed to sync ant component and formik states
        showUploadList={false}
      >
        <StyledShareIcon />
        <DraggerInfoText size={14} id='ic_upload_title' />
      </Dragger>
      {!!uploadHint && <FileNameText className='mt-2'>{uploadHint}</FileNameText>}
      {meta.value?.map(file => {
        return (
          <FileNameWrapper key={file.uid} className={clsx(file.error ? 'text-error' : 'text-grey-2')}>
            {file.error ? <CrossCircleFilledIcon /> : <CopyIcon />}
            <div>
              <FileNameText>{file.name}</FileNameText>
              {!!file.error && <FileNameText>{file.error}</FileNameText>}
            </div>
            <TrashCanIcon className='ml-auto cursor-pointer' onClick={() => onRemoveFile(file)} />
          </FileNameWrapper>
        )
      })}
      {meta.error && (
        <div className='text-8 mt-1 flex items-center gap-1 text-error'>
          <ExclamationCircleIcon />
          <FormattedMessage id={meta.error} />
        </div>
      )}
    </DraggerWrapper>
  )
}
