import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useField } from 'formik'
import { TextField, PasswordField, TextFieldProps } from '@nv/react-akira'
import { FieldWrapper } from '../FieldWrapper'
import styled from 'styled-components'

export const TextInputField = ({ name, ...props }: TextFieldProps) => {
  const [field, meta, { setValue }] = useField<string>(name)
  const Component = props.type === 'password' ? PasswordField : TextField
  return (
    <FieldWrapper>
      <TextFieldWrapper>
        <Component
          {...field}
          {...props}
          errorMessage={meta.touched && meta.error && <FormattedMessage id={meta.error} />}
          onChange={setValue}
        />
      </TextFieldWrapper>
    </FieldWrapper>
  )
}

const TextFieldWrapper = styled.div`
  & {
    // to override the outline removal in global styles
    // needed to indicate focus on reveal password button
    button:focus {
      outline: auto;
    }
  }
`
