import React, { useContext, useState, useEffect } from 'react'
import { compose } from 'redux'
import { useIntl } from 'hooks/common'
import { Form, NVModal } from '@nv/react-commons/src/Components'
import { OCSubStep } from 'components/OCSubStep'
import { Preview } from 'components/Preview'
import { CCTypeSelectionProps } from './types'
import { faUser } from '@fa-pro-light/faUser'
import {
  StyledRadio,
  StyledRadioGroup,
  StyledServiceCode,
  StyledServiceDescription
} from 'containers/FPLOrderCreate/styles'
import { CCType, TRANSLATION_KEY_BY_CC_TYPE } from './constants'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'

const CCTypeSelectionForm = ({ status, onCancel, onEdit, onSave }: CCTypeSelectionProps) => {
  const intl = useIntl()
  const { ocState, updateCCType } = useContext(MMCCOrderCreationContext)

  const [ccType, setCCType] = useState<CCType>(ocState.selectedCCType)

  useEffect(() => {
    if (ocState.selectedCCType != ccType) {
      setCCType(ocState.selectedCCType)
    }
  }, [ocState.selectedCCType])

  const save = () => {
    const modalProps = {
      title: intl.formatMessage({ id: 'warning' }),
      content: intl.formatMessage({ id: 'international_reselect_cc_type_content' }),
      okText: intl.formatMessage({ id: 'ok' }),
      cancelText: intl.formatMessage({ id: 'cancel' }),
      onOk () {
        updateCCType(ccType)
        onSave()
      }
    }
    NVModal.confirm(modalProps)
  }

  const cancel = () => {
    setCCType(ocState.selectedCCType)
    onCancel()
  }

  return (
    <OCSubStep
      status={status}
      title={intl.formatMessage({ id: 'cc_type_label' })}
      icon={faUser}
      renderActive={() => {
        return (
          <>
            <Preview title={intl.formatMessage({ id: 'choose_cc_type' })} />
            {
              <StyledRadioGroup
                onChange={e => setCCType(e.target.value)}
                value={ccType}
                label={intl.formatMessage({ id: TRANSLATION_KEY_BY_CC_TYPE[ccType] })}
              >
                <StyledRadio key={CCType.B2B} value={CCType.B2B}>
                  {intl.formatMessage({ id: TRANSLATION_KEY_BY_CC_TYPE[CCType.B2B] })}
                </StyledRadio>
                <StyledRadio key={CCType.B2C} value={CCType.B2C}>
                  {intl.formatMessage({ id: TRANSLATION_KEY_BY_CC_TYPE[CCType.B2C] })}
                </StyledRadio>
              </StyledRadioGroup>
            }
          </>
        )
      }}
      renderPreview={() => {
        return (
          <StyledServiceCode>
            <StyledServiceDescription>
              {intl.formatMessage({ id: TRANSLATION_KEY_BY_CC_TYPE[ocState.selectedCCType] })}
            </StyledServiceDescription>
          </StyledServiceCode>
        )
      }}
      onSave={save}
      onEdit={onEdit}
      onCancel={cancel}
    />
  )
}

const CCTypeSelection = compose(Form.create())(React.memo(CCTypeSelectionForm))

export { CCTypeSelection }
