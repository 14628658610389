/**
 *
 * TemperatureControlFormItem
 *
 */

import React from 'react'
import { Form } from '@nv/react-commons/src/Components'
import { FIELDS } from 'components/OCForm/constants'
import {
  OptionDescription,
  OptionTitle,
  OptionWrapper,
  StyledRadio,
  StyleRadioGroup
} from './TemperatureControlFormItem.styles'
import { useIntl } from 'hooks/common'
import { TemperatureControlFormItemProps } from './TemperatureControlFormItem.interface'

export const TemperatureControlFormItem = ({
  form: { getFieldDecorator },
  disabled,
  formItem: FormItem = Form.Item,
  initialValue,
  ocSettings
}: TemperatureControlFormItemProps) => {
  // hooks
  const intl = useIntl()

  // variables
  const enabledTemperatureRanges = (ocSettings?.enabledTemperatureRanges || []) as string[]
  // filter the enabled temperature ranges for 'COOL' (currently not supported).
  const filteredEnabledTemperatureRanges = enabledTemperatureRanges.filter(d => d !== 'COOL')

  return (
    <FormItem label={intl.formatMessage({ id: 'oc.temperature_control_required' })} isInlineBlockTitle>
      {getFieldDecorator(FIELDS.TEMPERATURE_RANGE, {
        initialValue: initialValue,
        rules: []
      })(
        <StyleRadioGroup orientation='vertical' disabled={disabled}>
          <StyledRadio value=''>
            <OptionWrapper>
              <OptionTitle>{intl.formatMessage({ id: 'oc.temperature_range.ambient.title' })}</OptionTitle>
              <OptionDescription>{intl.formatMessage({ id: 'oc.temperature_range.ambient.desc' })}</OptionDescription>
            </OptionWrapper>
          </StyledRadio>

          {filteredEnabledTemperatureRanges.map((tr, i) => (
            <StyledRadio key={i} value={tr}>
              <OptionWrapper>
                <OptionTitle>
                  {intl.formatMessage({ id: `oc.temperature_range.${tr.toLowerCase()}.title` })}
                </OptionTitle>
                <OptionDescription>
                  {intl.formatMessage({ id: `oc.temperature_range.${tr.toLowerCase()}.desc` })}
                </OptionDescription>
              </OptionWrapper>
            </StyledRadio>
          ))}
        </StyleRadioGroup>
      )}
    </FormItem>
  )
}
