import { GRANULAR_STATUS, GRANULAR_STATUS_TEXT } from '@nv/react-commons/src/Constants'
import { SUPPORTED_SERVICE_LEVELS } from 'containers/DeliveryType/constants'
import { COD_DATE_TYPES, REPORT_SCHEDULES_TYPE, REPORT_TYPE } from './ReportsV3.enums'

export const REPORT_SCHEDULES_EXPECTED_TIME = '9:00 AM'

export const reportSchedulesFrequencyType = {
  [REPORT_SCHEDULES_TYPE.DAILY]: 'types.frequency.daily',
  [REPORT_SCHEDULES_TYPE.WEEKLY]: 'types.frequency.weekly',
  [REPORT_SCHEDULES_TYPE.MONTHLY]: 'types.frequency.monthly'
}

export const MAP_SCHEDULES_REPORT_TYPE = {
  [REPORT_TYPE.codReconciliation]: 126,
  [REPORT_TYPE.deliveryStatus]: 113,
  [REPORT_TYPE.deliveryFailure]: 112,
  [REPORT_TYPE.deliveryFailureByCSV]: 125
}

export const MAP_REPORT_TYPE_FORMAT = {
  [REPORT_TYPE.deliveryStatus]: 'xlsx',
  [REPORT_TYPE.deliveryFailure]: 'csv'
}

export const FREQUENCY_VALUE_RANGE = [1, 28]

export const GRANULAR_STATUS_KEY_VALUE = Object.keys(GRANULAR_STATUS).map(key => ({
  value: GRANULAR_STATUS[key],
  text: GRANULAR_STATUS_TEXT[key]
}))

export const SUPPORTED_SERVICE_LEVELS_KEY_VALUE = Object.keys(SUPPORTED_SERVICE_LEVELS).map(key => ({
  value: SUPPORTED_SERVICE_LEVELS[key],
  text: SUPPORTED_SERVICE_LEVELS[key]
}))

export const GENERATE_REPORT_TEMPLATE_EXAMPLE = {
  'Tracking IDs': 'NVSGTESTN000000001'
} as const

// Use for event tracking
export const MAP_REPORT_TYPE_TRACKING_NAME = {
  [REPORT_TYPE.codReconciliation]: 'COD Reconciliation Report',
  [REPORT_TYPE.deliveryStatus]: 'Delivery Status Report',
  [REPORT_TYPE.deliveryFailure]: 'Delivery Failure Report',
  [REPORT_TYPE.deliveryFailureByCSV]: 'Delivery Failure By CSV Report'
}

export const MAP_COD_DATE_TYPES_TRACKING_NAME = {
  [COD_DATE_TYPES.CREATED_DATE]: 'Order Created Date',
  [COD_DATE_TYPES.COMPLETED_DATE]: 'Order Completed Date'
}

export const MAP_REPORT_SCHEDULES_TYPE_TRACKING_NAME = {
  [REPORT_SCHEDULES_TYPE.DAILY]: 'Daily',
  [REPORT_SCHEDULES_TYPE.WEEKLY]: 'Weekly',
  [REPORT_SCHEDULES_TYPE.MONTHLY]: 'Monthly'
}

export const MAP_DAY_OF_WEEK_TRACKING_NAME = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday'
}
