import { COUNTRIES } from '@nv/react-commons/src/Constants'

export const NINJA_PACK_URL = {
  [COUNTRIES.SG]: 'https://packs.ninjavan.co/',
  [COUNTRIES.MY]: 'https://my-packs.ninjavan.co/',
  [COUNTRIES.PH]:
    // eslint-disable-next-line max-len
    'https://ninjavan.jotform.com/241503428027045?utm_medium=referral&utm_source=website&utm_campaign=ph-ninjavandashboard&utm_term=new%20order'
}

export const getNinjaPacksLink = (country, shipperId) => {
  const countryLower = country.toLowerCase()

  let link = NINJA_PACK_URL[countryLower]

  if (link === undefined) {
    return undefined
  }

  if (shipperId !== undefined && countryLower !== COUNTRIES.SG && countryLower !== COUNTRIES.MY) {
    link += `?referrer_source=dash&referrer_id=${shipperId}`
  }

  return link
}
