/**
 *
 * DeleteAccountModal
 *
 */

import React, { useContext } from 'react'
import { RouteModal } from 'components/RouteModal'
import { T } from '@nv/react-commons/src/Components'
import { useNavigate } from 'react-router-dom'
import { useNvFetch } from 'hooks'
import { dashApi } from 'services/api'
import { ToastContext } from '@nv/react-akira'
import { useIntl } from 'hooks/common'
import { ACCOUNT_DELETION_PATH } from './DeleteAccountModal.constants'
import { ButtonWrapper, ButtonsWrapper, StyledDescription } from './DeleteAccountModal.styled'
import { ROUTES } from 'containers/Base/constants'
import { useDispatch } from 'react-redux'
import { userCreators } from 'containers/Base/redux'

export const DeleteAccountModal = () => {
  const navigate = useNavigate()
  const { fetch: deleteUser, loading } = useNvFetch(dashApi.deleteUser)
  const { addToast } = useContext(ToastContext)
  const intl = useIntl()
  const dispatch = useDispatch()

  const handleConfirm = async () => {
    const response = await deleteUser()

    if (!response?.ok) {
      addToast({
        label: intl.formatMessage({ id: 'message_management.contract_error' }),
        type: 'error',
        appearanceTime: 5000
      })
      return
    }

    dispatch(userCreators.getUserRequest())
    navigate(ROUTES.DELETE_ACCOUNT_SUCCESS, { replace: true })
  }

  const goBack = () => navigate(ROUTES.ACCOUNT)

  return (
    <RouteModal type='form' title={<T id='request_to_delete_account' />} path={ACCOUNT_DELETION_PATH} onCancel={goBack}>
      <StyledDescription>
        <T id='request_to_delete_account_description' />
      </StyledDescription>
      <ButtonsWrapper>
        <ButtonWrapper size='medium' onClick={goBack}>
          <T id='dont_delete' />
        </ButtonWrapper>
        <ButtonWrapper
          type='primary'
          size='medium'
          disabled={loading}
          loading={loading}
          onClick={handleConfirm}
          data-testid='delete-account-button'
        >
          <T id='delete' />
        </ButtonWrapper>
      </ButtonsWrapper>
    </RouteModal>
  )
}
