
import { useEffect, useState, useCallback } from 'react'
import { dashApi } from 'services/api'
import { calculateResponseTime } from 'containers/FPLOrderCreate/utils'

const initState = {
  loading: true,
  detail: null,
  error: null,
  user: null,
  fullRes: null
}

const useQueryDetail = (api) => {
  const { startCounting, getTime, reset } = calculateResponseTime()
  const [state, setState] = useState(initState)
  const [reload, setReload] = useState(false)
  const { loading, detail, error, user, fullRes } = state

  const fetchData = useCallback(async () => {
    if (!api) return

    startCounting()
    const resUser = await dashApi.getUser()

    let newState
    if (resUser.ok) {
      const defaultShipperId = resUser.data.defaultShipperId
      const res = await api(defaultShipperId)
      newState = {
        user: resUser.data,
        loading: false,
        detail: res?.data?.data,
        error: res?.data?.error,
        fullRes: res
      }
    } else {
      newState = {
        loading: false,
        error: resUser?.data?.error || {}
      }
    }

    setState(prevState => ({
      ...prevState,
      ...newState
    }))
  }, [api])

  useEffect(() => {
    fetchData()
  }, [fetchData, reload])

  const updateQuery = useCallback(() => {
    reset()
    setReload(prevState => !prevState)
  }, [])

  const getResponseTime = () => {
    return getTime()
  }

  return {
    loading,
    detail,
    updateQuery,
    error,
    user,
    fullRes,
    getResponseTime
  }
}

export { useQueryDetail }
