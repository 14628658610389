import React, { useEffect, useState } from 'react'
import { ChatIcon, DummyButton, Wrapper, Icon, StyText, Title, NoteBox } from './SalesforceChatWarning.styled'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { faCommentDots } from '@fortawesome/pro-solid-svg-icons'
import { Vspace } from 'components/Vspace'
import { FormattedMessage } from 'react-intl'

export const SalesforceChatWarning = ({ getBusinessHours, isAgentAvailable }) => {
  const [modalVisible, setModalVisible] = useState(false)
  useEffect(() => {
    if (modalVisible) {
      getBusinessHours()
    }
  }, [modalVisible, getBusinessHours])

  return (
    <Wrapper>
      <DummyButton
        onClick={() => {
          setModalVisible(visible => !visible)
        }}
      >
        <ChatIcon icon={faCommentDots} />
        <FormattedMessage
          id={isAgentAvailable ? 'salesforce_chat.title_agent_available' : 'salesforce_chat.title_offline'}
        />
      </DummyButton>
      {modalVisible && (
        <NoteBox>
          <Title>
            <StyText type='bold' size={16}>
              <FormattedMessage id='salesforce_chat_warning.title' />
            </StyText>
            <button
              onClick={() => {
                setModalVisible(false)
              }}
            >
              <Icon icon={faTimes} />
            </button>
          </Title>
          <Vspace height={20} />
          <StyText size={14}>
            <FormattedMessage id='salesforce_chat_warning.outside_operational_hours' />
            <Vspace height={20} />
            <FormattedMessage id='salesforce_chat_warning.unfortunately' />
            <Vspace height={20} />
            <FormattedMessage id='salesforce_chat_warning.please_return' />
          </StyText>
          <Vspace height={10} />
        </NoteBox>
      )}
    </Wrapper>
  )
}
