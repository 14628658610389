import React from 'react'
import { OptionItem, Select } from '@nv/react-akira'
import { intl as intlTranslator } from 'containers/IntlGlobalProvider'

export const mapOptionsArrayToSelectItem = (options: OptionItem[]) => {
  return (
    <Select.OptionsWrapper>
      {options.map(option => {
        return <Select.Item key={option.value} text={option.text} value={option} />
      })}
    </Select.OptionsWrapper>
  )
}

export const getTranslatedOptionItems = <T = string,>({
  options,
  intl
}: {
  options: OptionItem<T>[]
  intl: typeof intlTranslator
}) => {
  return options.map(option => {
    return { ...option, text: intl.formatMessage({ id: option.text }) as string }
  })
}
