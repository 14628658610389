import { useCallback } from 'react'
import { useNavigate, useLocation, NavigateOptions, To } from 'react-router-dom'

export const useNavigateWithState = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const navigateWithState = useCallback(
    (to: To | number, options: NavigateOptions = {}) => {
      if (typeof to === 'number') {
        navigate(to)
        return
      }
      navigate(to, { ...options, state: { ...location.state, ...options.state } })
    },
    [navigate, location]
  )

  return navigateWithState
}
