import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { T } from '@nv/react-commons/src/Components'
import { ROUTES } from 'containers/Base/constants'
import { getPartnerDetail } from 'services/api/fplApi'
import { API_RESPONSE_STATUS } from 'containers/FPLRegistration/constants'
import { Vspace } from 'components/Vspace'
import { Colors } from 'themes'
import { CustomUnderlineLink, StyledTextPadding } from './styles'

export const InternationalShippingLink = ({ user, left }) => {
  const [pageRoute, setPageRoute] = useState(null)

  const getPartner = async () => {
    const response = await getPartnerDetail(user.defaultShipperId)
    if (response.ok && response.status === API_RESPONSE_STATUS.SUCCESS) {
      setPageRoute(ROUTES.FPL_OC)
    } else {
      setPageRoute(ROUTES.FPL_SERVICE_REGISTRATION)
    }
  }

  useEffect(() => {
    if (user.defaultShipperId) {
      getPartner()
    }
  }, [user.defaultShipperId])

  return (
    <>
      <Vspace height={8} />
      <StyledTextPadding size={12} color={Colors.cherryRed} left={left}>
        <T id='inavailable_option' />
        &nbsp;
        <CustomUnderlineLink href={pageRoute}>
          <T id='international_order_creation_label' />
        </CustomUnderlineLink>
      </StyledTextPadding>
    </>
  )
}

InternationalShippingLink.propTypes = {
  user: PropTypes.object.isRequired,
  left: PropTypes.number
}
