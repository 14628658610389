import { css } from 'styled-components'
import { media, sizes } from './media'
import Metrics from './Metrics'

const ApplicationStyles = {
  container: css`
    width: 100%;
    ${media.min.tablet`
      padding: 30px 120px;
      margin-left: auto;
      margin-right: auto;
      max-width: ${props => (props.maxWidth ? '100%' : `${Metrics.maxWidth}px`)};
      ${props =>
    props.withOwnHeader &&
        `
        top: ${Metrics.headerHeight}px;
        left: 0;
        right: 0`};
     `};
    ${media.max.tablet`
      padding: 15px 24px;
     `};
    @media (min-width: ${sizes.tablet}px) and (max-width: ${sizes.lgTablet}px) {
      padding-left: 40px;
      padding-right: 40px;
    }
  `
}

export default ApplicationStyles
