import styled from 'styled-components'
import { Images } from 'images'
import { AkiraRouteModal } from 'components/AkiraRouteModal'

export const AkiraRouteModalWrapper = styled(AkiraRouteModal)`
  &&::before {
    content: '';
    background-image: url(${Images.modalBackground});
    background-size: cover;
    background-position: top;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.6;
    z-index: -1;
  }
`

export const UpdatePhoneNumberAkiraModalWrapper = styled(AkiraRouteModal)`
  && {
    overflow: visible;
  }
`

export const PhoneNumberWrapper = styled.div`
  button {
    border-style: inherit;
  }

  display: flex;
  gap: 16px;

  & > *:first-child {
    flex: 1;
  }

  & > *:nth-child(2) {
    flex: 2;
  }
`
