/**
 *
 * OCForm
 *
 */

import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { SIZE_OPTS } from 'components/OCForm/constants'
import {
  COD,
  CustomerAddress,
  CustomerContact,
  CustomerEmail,
  CustomerName,
  DeliveryDate,
  DeliveryInstructions,
  DeliveryTime,
  DeliveryType,
  InsuredValue,
  Postcode,
  TrackingNumber,
  Weight,
  SubCountryGroupedInputs,
  ParcelDescription,
  DangerousGood,
  AddressDeliveryDetailsGroupedInputs,
  CartonsNumber,
  RDOorGRN,
  RequestedPieceTIDs,
  ShipperOrderNumber,
  TemperatureControl
} from 'components/OCForm/Field'
import { EstimatedSize } from 'components/OCForm/OCForm.EstimatedSize'
import { DimensionsAlert } from 'components/OCForm/OCForm.DimensionsAlert'
import { getDefaultServiceLevel } from 'containers/DeliveryType/utils'
import _ from 'lodash'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { withSize } from 'components/BreakpointListener'
import { injectIntl } from 'react-intl'
import { Col, Icon, Form, Row } from '@nv/react-commons/src/Components'
import { Colors, media } from 'themes'
import { Text } from 'components/Text'
import { Vspace } from 'components/Vspace'
import { faUserCircle } from '@fa-pro-light/faUserCircle'
import { faTruck } from '@fa-pro-light/faTruck'
import { faPlusCircle } from '@fa-pro-light/faPlusCircle'
import moment from 'moment'
import { shouldUseGranularAddressFields } from 'utils/address'
import { withFlags } from 'featureHub/withFlags'
import featureKeys from 'featureHub/featureKeys'
import { Dimensions } from './OCForm.Dimensions'
import { hasDimensionSize, isB2BBundleOrder } from './OCForm.helpers'
import { DEFAULT_TIMESLOT_ID } from 'constants/deliveryTimeslots'
import { OC_SERVICE_TYPES } from 'containers/Base/constants'

const StyledRow = styled(Row)`
  ${media.min.tablet`
    padding-left: 24px;
    padding-right: 16px;
  `};
  ${media.max.tablet`
    padding: 0 16px;
  `};
`
const StyledSectionHeader = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid ${Colors.paleGrey};
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 0 6px;
`
const StyledText = styled(Text).attrs({
  allCaps: true,
  size: 12,
  type: 'bold',
  color: Colors.greyishBrown
})`
  letter-spacing: 0.7px;
`

// used for spacing
const EmptyComponent = styled.div``

const CustomerDetailsHeader = () => (
  <StyledSectionHeader>
    <Icon type='medium' icon={faUserCircle} color={Colors.greyishBrown} gap={8} />
    <StyledText id='customer_details' />
  </StyledSectionHeader>
)
const DeliveryDetailsHeader = () => (
  <StyledSectionHeader>
    <Icon type='medium' icon={faTruck} color={Colors.greyishBrown} gap={8} />
    <StyledText id='delivery_details' />
  </StyledSectionHeader>
)
const AdditionalDetailsHeader = () => (
  <StyledSectionHeader>
    <Icon type='medium' icon={faPlusCircle} color={Colors.greyishBrown} gap={8} />
    <StyledText id='additional_details' />
  </StyledSectionHeader>
)

const DEFAULT_SIZE = '0'

class OCForm extends React.Component {
  id = 0
  formFieldProps = [
    'form',
    'intl',
    'isDesktop',
    'country',
    'ocSettings',
    'masterOcSettings',
    'isRegularOC',
    'isBulkUpload',
    'zones',
    'onOrderChange',
    'order',
    'readyDatetime'
  ]

  static SIZE_OPTS = SIZE_OPTS

  componentDidMount () {
    const { form } = this.props
    const { getFieldValue, setFieldsValue } = form
    const size = getFieldValue('size') || DEFAULT_SIZE
    const deliveryType = getFieldValue('deliveryType') || this.getDefaultDeliveryType()
    setFieldsValue({
      size,
      deliveryType,
      deliveryTimeslot: getFieldValue('deliveryTimeslot') || DEFAULT_TIMESLOT_ID
    })
    this.props.onCapture?.(form)
  }

  getDefaultDeliveryType = () => {
    return getDefaultServiceLevel(this.props.ocSettings?.availableServiceLevels)
  }

  renderDesktopField = (config, key) => {
    const fieldProps = _.pick(this.props, this.formFieldProps)
    const [Component, lg] = config
    return lg ? (
      <Col lg={lg} span={lg && 24} key={key}>
        <Component {...fieldProps} />
      </Col>
    ) : (
      <Component {...fieldProps} key={key} />
    )
  }

  renderDesktopForm = fields => {
    return (
      <>
        <Vspace height={8} />
        <StyledRow type='flex'>{fields.map(this.renderDesktopField)}</StyledRow>
      </>
    )
  }

  renderRegularDesktop = () => {
    const { country, isBulkUpload, isCodSupported, isEnabledTemperatureRanges } = this.props
    let fields = [[DimensionsAlert, 24]]
    /* eslint-disable */
    // prettier-ignore
    if (!isBulkUpload) {
      fields.push(
        [CustomerName, 6], [CustomerContact, 6], [CustomerEmail, 6], [DeliveryType, 6]
      )
      if (country && country.toLowerCase() === COUNTRIES.MA) {
        fields.push(
          [DeliveryTime, 6], [CustomerAddress, 18],
          [Postcode, 6],
          [SubCountryGroupedInputs, 18],
        )
      } else {
        fields.push(
          [DeliveryTime, 6], [CustomerAddress, 15], [Postcode, 3]
        )
      }
    } else {
      // isBulkUpload means Edit Error Page
      fields.push(
        [CustomerName, 8], [CustomerContact, 8], [CustomerEmail, 8],
        [DeliveryType, 8], [DeliveryTime, 8], [DeliveryDate, 8],
        [CustomerAddress, 16],
      )
      if (country && country.toLowerCase() === COUNTRIES.MA) {
        fields.push([Fragment, 8], [Postcode, 8], [SubCountryGroupedInputs, 16])
      } else {
        fields.push([Postcode, 8])
      }

    }
    if (country && country.toLowerCase() !== COUNTRIES.MA) {
      fields.push([SubCountryGroupedInputs])
    }

    fields.push([EmptyComponent, 24])

    if (isCodSupported) {
      if (hasDimensionSize(country)) {
        fields.push([Weight, 6], [Dimensions, 12], [EmptyComponent, 6])
      } else {
        fields.push([EstimatedSize, 6], [Weight, 6])
      }
      fields.push(
        [COD, 6],
        [InsuredValue, 6],
        [TrackingNumber, 12],
        [DeliveryInstructions, 12],
        [ParcelDescription, 12]
      )
    } else {
      if (hasDimensionSize(country)) {
        fields.push([Weight, 6], [Dimensions, 12], [EmptyComponent, 6])
      } else {
        fields.push([EstimatedSize, 8], [Weight, 8])
      }
      fields.push([InsuredValue, 8], [TrackingNumber, 12], [DeliveryInstructions, 12], [ParcelDescription, 12])
    }
    if (isEnabledTemperatureRanges) {
      fields.push([TemperatureControl, 6])
    }
    fields.push([DangerousGood, 6])

    /* eslint-enable */
    return this.renderDesktopForm(fields)
  }

  renderRegularDesktopV2 = () => {
    const {
      isBulkUpload,
      isCodSupported,
      country,
      isB2BBundleSupported,
      form: { getFieldValue },
      isEnabledTemperatureRanges
    } = this.props
    const serviceType = getFieldValue('serviceType')
    const isMpsForm = isB2BBundleOrder(serviceType, isB2BBundleSupported)

    let fields = [[DimensionsAlert, 24]]
    if (!isBulkUpload) {
      fields.push([CustomerName, 6], [CustomerContact, 6], [CustomerEmail, 6])
      fields.push([DeliveryType, 6])
      fields.push([DeliveryTime, 6])
      if (isMpsForm) {
        fields.push([CartonsNumber, 6])
      }
      fields.push([ShipperOrderNumber, 6])
      if (
        isB2BBundleSupported &&
        (serviceType === OC_SERVICE_TYPES.B2B_BUNDLE || serviceType === OC_SERVICE_TYPES.CORPORATE_B2B_BUNDLE)
      ) {
        fields.push([RDOorGRN, 12])
      }
      fields.push([AddressDeliveryDetailsGroupedInputs, 24])
    } else {
      // isBulkUpload means Edit Error Page
      fields.push([CustomerName, 8], [CustomerContact, 8], [CustomerEmail, 8])
      fields.push([DeliveryType, 8])
      fields.push([DeliveryTime, 8], [DeliveryDate, 8])
      if (isMpsForm) {
        fields.push([CartonsNumber, 6])
      }
      fields.push([ShipperOrderNumber, 6])
      if (
        isB2BBundleSupported &&
        (serviceType === OC_SERVICE_TYPES.B2B_BUNDLE || serviceType === OC_SERVICE_TYPES.CORPORATE_B2B_BUNDLE)
      ) {
        fields.push([RDOorGRN, 12])
      }
      fields.push([AddressDeliveryDetailsGroupedInputs, 24])
    }

    // prettier-ignore
    if (isCodSupported) {
      if (hasDimensionSize(country) && !isMpsForm) {
        fields.push([Weight, 6], [Dimensions, 12], [EmptyComponent, 6])
      } else {
        fields.push([EstimatedSize, 6], [Weight, 6])
      }
      fields.push(
        [COD, 6], [InsuredValue, 6],
        [TrackingNumber, 12], [EmptyComponent, 12]
      )
    } else {
      if (hasDimensionSize(country) && !isMpsForm) {
        fields.push([Weight, 6], [Dimensions, 12], [EmptyComponent, 6])
      } else {
        fields.push([EstimatedSize, 8], [Weight, 8])
      }
      fields.push(
        [InsuredValue, 8],
        [TrackingNumber, 12], [EmptyComponent, 12]
      )
    }
    if (isMpsForm) {
      fields.push([RequestedPieceTIDs, 12])
    }
    fields.push([ParcelDescription, 12])
    if (isEnabledTemperatureRanges) {
      fields.push([TemperatureControl, 6])
    }
    fields.push([DangerousGood, 6])

    return this.renderDesktopForm(fields)
  }

  renderMobileField = config => {
    const fieldProps = _.pick(this.props, this.formFieldProps)
    const [Component] = config
    return (
      <>
        <StyledRow>
          <Component {...fieldProps} />
        </StyledRow>
      </>
    )
  }

  renderRegularMobile = () => {
    const { isCodSupported, country, isEnabledTemperatureRanges } = this.props
    const fields = [
      [CustomerDetailsHeader],
      [TrackingNumber],
      [CustomerName],
      [CustomerContact],
      [CustomerEmail],
      [CustomerAddress],
      [Postcode],
      [SubCountryGroupedInputs],
      [DeliveryDetailsHeader],
      [DimensionsAlert],
      [DeliveryType],
      [DeliveryTime]
    ]

    if (hasDimensionSize(country)) {
      fields.push([Weight], [Dimensions], [AdditionalDetailsHeader])
    } else {
      fields.push([EstimatedSize], [AdditionalDetailsHeader], [Weight])
    }
    if (isCodSupported) {
      fields.push([COD])
    }
    // prettier-ignore
    fields.push(
      [InsuredValue],
      [DeliveryInstructions],
      [ParcelDescription],
    )
    if (isEnabledTemperatureRanges) {
      fields.push([TemperatureControl])
    }
    fields.push([DangerousGood])
    return <>{fields.map(this.renderMobileField)}</>
  }

  renderRegularMobileV2 = () => {
    const {
      isCodSupported,
      country,
      isB2BBundleSupported,
      form: { getFieldValue },
      isEnabledTemperatureRanges
    } = this.props
    const serviceType = getFieldValue('serviceType')
    const isMpsForm = isB2BBundleOrder(serviceType, isB2BBundleSupported)
    const fields = [
      [CustomerDetailsHeader],
      [TrackingNumber],
      [CustomerName],
      [CustomerContact],
      [CustomerEmail],
      [AddressDeliveryDetailsGroupedInputs],
      [DeliveryDetailsHeader],
      [DimensionsAlert]
    ]
    fields.push([DeliveryType])
    fields.push([DeliveryTime])
    if (isMpsForm) {
      fields.push([CartonsNumber])
    }
    fields.push([ShipperOrderNumber])
    if (
      isB2BBundleSupported &&
      (serviceType === OC_SERVICE_TYPES.B2B_BUNDLE || serviceType === OC_SERVICE_TYPES.CORPORATE_B2B_BUNDLE)
    ) {
      fields.push([RDOorGRN])
    }
    if (hasDimensionSize(country)) {
      fields.push([Weight], [Dimensions], [AdditionalDetailsHeader])
    } else {
      fields.push([EstimatedSize], [AdditionalDetailsHeader], [Weight])
    }
    if (isCodSupported) {
      fields.push([COD])
    }
    fields.push([InsuredValue])
    if (isMpsForm) {
      fields.push([RequestedPieceTIDs])
    }
    fields.push([ParcelDescription])
    if (isEnabledTemperatureRanges) {
      fields.push([TemperatureControl])
    }
    fields.push([DangerousGood])
    return <>{fields.map(this.renderMobileField)}</>
  }

  renderReturnDesktop = () => {
    const { country } = this.props
    let fields = [[DimensionsAlert, 24]]
    if (hasDimensionSize(country)) {
      fields.push([Weight, 6], [Dimensions, 12], [EmptyComponent, 6])
    } else {
      fields.push([EstimatedSize, 8], [Weight, 8])
    }
    /* eslint-disable */
    // prettier-ignore
    fields.push([InsuredValue, 8],
      [TrackingNumber, 16], [ParcelDescription, 12], [DangerousGood, 12])
    /* eslint-enable */
    return this.renderDesktopForm(fields)
  }

  renderReturnMobile = () => {
    const { country } = this.props
    const fields = [[CustomerDetailsHeader], [TrackingNumber], [DeliveryDetailsHeader], [DimensionsAlert]]
    if (hasDimensionSize(country)) {
      fields.push([Weight], [Dimensions], [AdditionalDetailsHeader])
    } else {
      fields.push([EstimatedSize], [AdditionalDetailsHeader], [Weight])
    }
    fields.push([InsuredValue], [ParcelDescription], [DangerousGood])
    return <>{fields.map(this.renderMobileField)}</>
  }

  renderReturnOrder () {
    const { isDesktop } = this.props
    return (
      <>
        {isDesktop && this.renderReturnDesktop()}
        {!isDesktop && this.renderReturnMobile()}
      </>
    )
  }

  renderRegularOrder () {
    const { isDesktop, country, flags } = this.props
    if (shouldUseGranularAddressFields({ country, flags })) {
      return (
        <>
          {isDesktop && this.renderRegularDesktopV2()}
          {!isDesktop && this.renderRegularMobileV2()}
        </>
      )
    }
    return (
      <>
        {isDesktop && this.renderRegularDesktop()}
        {!isDesktop && this.renderRegularMobile()}
      </>
    )
  }

  render () {
    const { isReturnOrder } = this.props

    return <Form small>{isReturnOrder ? this.renderReturnOrder() : this.renderRegularOrder()}</Form>
  }
}

OCForm.propTypes = {
  country: PropTypes.string,
  form: PropTypes.object,
  isBulkUpload: PropTypes.bool,
  isDesktop: PropTypes.any,
  isReturnOrder: PropTypes.any,
  ocSettings: PropTypes.object,
  masterOcSettings: PropTypes.object,
  isRegularOC: PropTypes.bool,
  zones: PropTypes.array,
  isCodSupported: PropTypes.bool,
  readyDatetime: PropTypes.string,
  isEnabledTemperatureRanges: PropTypes.bool
}

OCForm.defaultProps = {}

const INITIAL_STATE = {
  address1: '',
  cod: null,
  deliveryTimeslot: null,
  deliveryType: null,
  email: '',
  groupedItem: [0],
  instructions: '',
  insuredValue: null,
  name: '',
  phoneNumber: '',
  postcode: '',
  requestedTrackingNumber: '',
  size: null,
  weight: null,
  isMpsForm: false
}
const _OCForm = compose(
  Form.create({
    mapPropsToFields: props => {
      const { isB2BBundleSupported, order } = props
      const serviceType = order?.serviceType
      const isMpsForm = isB2BBundleOrder(serviceType, isB2BBundleSupported)
      return {
        ..._.mapValues(INITIAL_STATE, value => Form.createFormField({ value })),
        ..._.mapValues(props.order, (value, key) => {
          let data = value
          if (key === 'deliveryStartDate') {
            const momentVal = moment(value)
            data = momentVal.isValid() ? momentVal : null
          }
          return Form.createFormField({ value: data })
        }),
        isMpsForm: Form.createFormField({ value: isMpsForm })
      }
    },
    onValuesChange: (props, values) => {
      const { isB2BBundleSupported, order } = props
      const serviceType = order?.serviceType
      const isMpsForm = isB2BBundleOrder(serviceType, isB2BBundleSupported)
      const transformedValues = {
        ..._.mapValues(values, (value, key) => {
          if (key === 'deliveryStartDate') {
            return value.format('YYYY-MM-DD')
          }
          return value
        }),
        isMpsForm
      }
      props.onOrderChange({ values: transformedValues })
    }
  }),
  injectIntl,
  withSize,
  withFlags([featureKeys.GRANULAR_ADDRESS_FIELDS])
)(OCForm)
_OCForm.SIZE_OPTS = OCForm.SIZE_OPTS

export { _OCForm as OCForm, StyledRow, SIZE_OPTS, DEFAULT_SIZE }
