/**
 * Create the store with dynamic reducers
 */

import { applyMiddleware, compose, createStore } from 'redux'
import { fromJS } from 'immutable'
import { autoRehydrate } from 'redux-persist-immutable'
import { REHYDRATE } from 'redux-persist/constants'
import createSagaMiddleware from 'redux-saga'
import createActionBuffer from 'redux-action-buffer'
import * as Sentry from '@sentry/react'
import createReducer from './reducers'
import { updateReducers } from 'services/rehydrationService'

const sagaMiddleware = createSagaMiddleware({
  onError: async error => {
    Sentry.captureException(error)
  }
})

export default function configureStore (initialState = {}) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, createActionBuffer(REHYDRATE)]

  const enhancers = [applyMiddleware(...middlewares), autoRehydrate()]

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    __DEV__ && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose
  /* eslint-enable */

  const store = createStore(createReducer(), fromJS(initialState), composeEnhancers(...enhancers))
  updateReducers(store)

  // Extensions
  store.runSaga = sagaMiddleware.run
  store.injectedReducers = {} // Reducer registry
  store.injectedSagas = {} // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers))
    })
  }

  return store
}
