import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { PropsWithChildren } from 'types'
import { Hero } from './AuthLayout.Hero'
import { LogoDesktop, LogoMobile } from './AuthLayout.Logo'
import { Colors, AKIRA_QUERIES } from 'themes'
import NVBackgroundImage from 'images/ninjadash-background.svg'
import { AkiraReset } from 'themes'
import { MobileBanner } from './AuthLayout.MobileBanner'
import { useMatch } from 'react-router-dom'
import { ROUTES } from 'containers/Base/constants'

export const AuthLayout = ({ children }: PropsWithChildren) => {
  const isSignUp = useMatch(`${ROUTES.UNIFIED_SIGNUP}/*`)

  return (
    <>
      <GlobalStyle />
      <Layout>
        <LogoDesktop />
        <Wrapper>
          <LogoMobile />
          <MobileBanner />
          <Hero />
          <ContentWrapper style={{ '--alignment': isSignUp ? 'center' : 'baseline' } as React.CSSProperties}>
            {children}
          </ContentWrapper>
        </Wrapper>
      </Layout>
    </>
  )
}

// reset global styles clashing with akira in this screen
const GlobalStyle = createGlobalStyle`
  body {
    background-color: white !important;

    @media ${AKIRA_QUERIES.laptopAndUp} {
      background: linear-gradient(to right, ${Colors.akiraRed2} calc(50vw), white calc(50vw)) !important;
    }
  }

  .ant-layout-content {
    width: 100% !important;
  }
`

const Layout = styled.div`
  --padding: 24px;

  min-height: 100vh;
  padding: var(--padding);
  position: relative;
  isolation: isolate;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 72px;

  line-height: 1.6;
  color: ${Colors.balanceTxtBold};
  background-color: white;

  ${LogoDesktop} {
    display: none;
  }

  @media ${AKIRA_QUERIES.laptopAndUp} {
    --padding: 40px;

    ${LogoDesktop} {
      display: block;
      align-self: start;
      margin-bottom: auto;
    }

    background: linear-gradient(to right, ${Colors.akiraRed2} calc(50vw), white calc(50vw));
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(50%);
      height: 100%;
      background: url(${NVBackgroundImage});
      background-size: cover;
      mix-blend-mode: multiply;
      pointer-events: none;
    }
  }

  ${AkiraReset}
`

const Wrapper = styled.main`
  --max-content-width: 400px;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  ${LogoMobile} {
    width: 100%;
    max-width: var(--max-content-width);
  }

  ${Hero} {
    display: none;
  }

  @media ${AKIRA_QUERIES.laptopAndUp} {
    max-width: 1100px;
    margin-bottom: auto;
    flex-direction: row;
    align-items: baseline;
    gap: 80px;

    ${LogoMobile} {
      display: none;
    }

    ${Hero} {
      display: block;
    }
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  & > * {
    width: 100%;
    max-width: var(--max-content-width);
  }

  @media ${AKIRA_QUERIES.laptopAndUp} {
    justify-content: flex-end;
    align-self: var(--alignment);
  }
`
