import React, { useEffect } from 'react'
import { dashApi } from 'services/api'
import { MESSAGE_CONTRACT, MESSAGE_CONTRACT_NOTICE } from './shared'
import { ContractNoticeModal } from './ContractNoticeModal'
import { ContractModal } from './ContractModal'
import { useFirstMessage } from './hooks'
import PropTypes from 'prop-types'

const UserMessage = ({ shipperId, shipperName }) => {
  const [firstMessage, setMessages] = useFirstMessage(shipperId)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(
    () => {
      async function loadMessages () {
        const resp = await dashApi.getMessages()
        if (resp.ok) {
          setMessages(resp.data)
        } else {
          setMessages(null)
        }
      }
      loadMessages()
    },
    [shipperId]
  )

  if (!firstMessage) {
    return null
  }

  switch (firstMessage?.type) {
    case MESSAGE_CONTRACT_NOTICE:
      return <ContractNoticeModal message={firstMessage} shipperId={shipperId} shipperName={shipperName} />
    case MESSAGE_CONTRACT:
      return <ContractModal message={firstMessage} shipperId={shipperId} shipperName={shipperName} />
    default:
      return null
  }
}

UserMessage.propTypes = {
  shipperId: PropTypes.number,
  shipperName: PropTypes.string
}

export default UserMessage
