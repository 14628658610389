/**
 *
 * Divider
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Colors } from 'themes'

const StyledDivider = styled.div`
  background-color: ${props => props.color || Colors.solitude};
  ${props => props.direction !== 'vertical' && `height: ${props.thickness}px`};
  ${props => props.height && `height: ${props.height}px`};
  width: ${props => (props.direction === 'vertical' ? `${props.thickness}px` : '100%')};
`

const Divider = props => <StyledDivider {...props} />

Divider.propTypes = {
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
  thickness: PropTypes.number
}

Divider.defaultProps = {
  direction: 'horizontal',
  thickness: 1
}

export { Divider }
