import styled from 'styled-components'
import { WheelsBanner } from './WheelsBanner'

export const StyledAlert = styled(WheelsBanner)`
  && {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    .ant-alert-close-icon {
      top: 3px;
    }
  }
`
