import styled from 'styled-components'
import { Icon, Spin } from '@nv/react-commons/src/Components'

import { Text } from 'components/Text'
import { Button } from 'components/Button'
import { Colors } from 'themes'
import { Col, Row } from 'antd'
import { DropZone } from 'containers/FPLAllOrders/DropZone'

export const StyledSpin = styled(Spin)`
  && {
    display: block;
  }
`

export const StyledWrapper = styled.div`
  height: 100%;
`

export const StyledFlex = styled.div`
  display: flex;
`

export const StyledText = styled(Text)`
  && {
    font-size: 14px;
    line-height: 18px;
  }
`

export const StyledReturnOptionDescription = styled(Text)`
  && {
    margin-top: 4px;
    font-size: 14px;
    color: ${Colors.disabled};
  }
`

export const StyledRequestDetailsWrapper = styled.div`
  width: 100%;
  padding: 8px 16px;
  background-color: ${Colors.aliceBlue};
  border: 1px solid ${Colors.paleGrey};
  border-top: 3px solid ${Colors.paleGrey};
  border-radius: 4px;
`

export const StyledPaymentDetailsBox = styled(StyledRequestDetailsWrapper)`
  && {
    display: flex;
    padding: 16px 16px 24px 16px;
  }
`

export const StyledPaymentDetailsIcon = styled(Icon)`
  && {
    color: ${Colors.disabled};
    width: 24px;
    height: 24px;
  }
`

export const StyledRadioGroupWrapper = styled.div`
  .ant-radio-group {
    width: 100%;
  }
  .ant-radio-wrapper {
    display: flex;
    white-space: normal;
    line-height: 16px;
  }
  .ant-radio-disabled + span {
    color: ${Colors.greyishBrown};
  }
`

export const StyledActionsWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 20px 16px 20px;
`

export const StyledButton = styled(Button)`
  margin-left: 8px;
`

export const StyledDot = styled(Text)`
  && {
    line-height: 18px;
    margin-right: 8px;
  }
`

export const StyledDetailLabel = styled(StyledText).attrs({
  type: 'bold'
})`
  && {
    flex: 0.8;
    color: ${Colors.pinkishGrey};
  }
`

export const StyledDetailValue = styled(StyledText)`
  && {
    flex: 2;
  }
`

export const StyledDisclaimerWrapper = styled.div`
  padding: 12px 16px;
  border: 1px solid ${Colors.paleGrey};
  border-radius: 4px;
`

export const StyledImage = styled.img`
  height: 150px;
  object-fit: cover;
`

export const StyledResultPanelWrapper = styled.div`
  margin: 0 77px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const RelabelWrapper = styled.div``

export const RelabelCard = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  height: 100%;
`

export const ListItem = styled.ul`
  list-style-type: decimal;
  padding: 0 24px;
  li {
    padding-top: 12px;
  }
`

export const ListWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const HeaderList = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-weight: bold;
  background-color: #f8f8f8;
  padding: 6px 12px;
`

export const HeaderBody = styled.div`
  max-height: calc(100% - 82px);
  height: calc(100% - 82px);
  overflow: auto;
  padding: 6px 12px;
  background-color: white;
`

export const FooterList = styled(Button)`
  &&& {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    svg {
      margin-right: 0;
    }
    text-transform: none;
  }
  width: 100%;
`

export const CustomLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  a {
    margin: 0;
  }
`

export const RowFixedSize = styled(Row)`
  &&& {
    height: calc(100vh - 378px);
  }
`

export const ColFixedSize = styled(Col)`
  &&& {
    height: 100%;
  }
`

export const BlockFixedSize = styled.div`
  height: calc(100% - 16px);
`

export const RelabelDropZone = styled(DropZone)`
  &&& {
    height: 65%;
  }
`

export const RelativeWrapper = styled.div`
  position: relative;
  height: 100%;
`

export const LeftZone = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

export const RightZone = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: ${props => props.height};
  display: flex;
  align-items: flex-end;
`
