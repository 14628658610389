import React from 'react'
import PropTypes from 'prop-types'
import Config from 'configs'
import { CreateInternationalOrderButtonV2 } from './CreateInternationalOrderButtonV2'
import { CreateInternationalOrderButtonV1 } from './CreateInternationalOrderButtonV1'

export const CreateInternationalOrderButton = ({ shipperId }) => {
  if (Config.FPL_SHIPPER_ONBOARDING && JSON.parse(Config.FPL_SHIPPER_ONBOARDING)) {
    return <CreateInternationalOrderButtonV2 shipperId={shipperId} />
  }
  return <CreateInternationalOrderButtonV1 shipperId={shipperId} />
}

CreateInternationalOrderButton.propTypes = {
  shipperId: PropTypes.number
}
