import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import { RedAsterisk, StyledLabel, StyledSelect } from './styles'
import { T, Radio } from '@nv/react-commons/src/Components'
import { Text } from 'components/Text'
import { Colors } from 'themes'

const Option = Select.Option

export const ParcelSelection = ({ selectedOption, options, onSave, title }) => {
  const optionsRenderer = useMemo(() => {
    return options.filter(option => option.code !== selectedOption)
  }, [options, selectedOption])

  const handleChange = value => {
    onSave(value)
  }

  const renderOption = option => {
    return (
      <Option key={option.code} value={option.code}>
        <Radio value={option.code}>
          <T id={option.label} />
        </Radio>
      </Option>
    )
  }

  const renderSelectedValue = value => {
    const optionSelected = options.find(option => option.code === value)
    return (
      <Text>
        <T id={optionSelected?.label} />
      </Text>
    )
  }

  return (
    <>
      {title && (
        <StyledLabel size={12}>
          <span>
            <Text color={Colors.lightGreyBalance} inline size={12}>
              <T id={title} />
            </Text>
            <RedAsterisk />
          </span>
        </StyledLabel>
      )}
      <StyledSelect
        value={renderSelectedValue(selectedOption)}
        onChange={handleChange}
        getPopupContainer={trigger => trigger}
        showArrow
        data-testid={title}
      >
        {optionsRenderer.map(option => renderOption(option))}
      </StyledSelect>
    </>
  )
}

ParcelSelection.propTypes = {
  selectedOption: PropTypes.string,
  options: PropTypes.array.isRequired,
  onSave: PropTypes.func,
  title: PropTypes.string
}

ParcelSelection.defaultProps = {
  options: []
}
