/**
 *
 * SelectionFormItem
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'hooks/common'
import { Form, NVSelect, T } from '@nv/react-commons/src/Components'
import { Colors } from '@nv/react-commons/src/Themes'
import { Text } from 'components/Text'

const { Option } = NVSelect

const SelectionFormItem = ({
  formLabel,
  fieldName,
  textName,
  required,
  initialValue,
  formItem: FormItem,
  form: { getFieldDecorator },
  menus,
  ...props
}) => {
  const intl = useIntl()
  return (
    <FormItem label={formLabel}>
      {getFieldDecorator(fieldName, {
        initialValue,
        rules: [
          {
            required,
            message: `${textName} ${intl.formatMessage({ id: 'is_required' })}`
          }
        ]
      })(
        <NVSelect {...props}>
          {menus.map(({ key, label, ...item }) => (
            <Option key={key} value={key}>
              <T id={label} />
              {item.desc && (
                <Text size={12} colors={Colors.grey}>
                  {intl.formatMessage({ id: item.desc })}
                </Text>
              )}
            </Option>
          ))}
        </NVSelect>
      )}
    </FormItem>
  )
}

SelectionFormItem.propTypes = {
  formLabel: PropTypes.string,
  fieldName: PropTypes.string,
  textName: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  form: PropTypes.object,
  formItem: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  initialValue: PropTypes.string,
  menus: PropTypes.array,
  style: PropTypes.object
}

SelectionFormItem.defaultProps = {
  disabled: false,
  formItem: Form.Item
}

export { SelectionFormItem }
