import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Spin, TextField } from '@nv/react-akira'
import { useIntl } from 'hooks'
import { useVerifyPhoneNumber } from './VerifyPhoneNumber.context'

interface OTPFormProps {
  onBack?: () => void
}

export const OTPForm = (props: OTPFormProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {
    currentPhoneNumber,
    remainingTime,
    handleOnInputOtp,
    handleSendOTP,
    isLoading,
    verificationError
  } = useVerifyPhoneNumber()

  const handleOnBack = () => {
    if (typeof props.onBack === 'function') {
      return props.onBack()
    }
    navigate(-1)
  }

  return (
    <form>
      <p className='text-7 mb-5 text-left'>
        {intl.formatMessage({ id: 'auth.verify_phone.enter_otp' }, { phone: currentPhoneNumber?.number?.e164 })}
      </p>
      <TextField
        onChange={handleOnInputOtp}
        name='otp'
        disabled={isLoading}
        suffix={isLoading && <Spin />}
        label={intl.formatMessage({ id: 'auth.six_digit_code' })}
        errorMessage={verificationError && intl.formatMessage({ id: verificationError })}
      />
      <div className='flex gap-2 justify-end mt-5'>
        <Button
          disabled={isLoading}
          type='button'
          onClick={handleOnBack}
          styleType='secondary'
          variant='default'
          size='lg'
          className='bg-white'
        >
          {intl.formatMessage({ id: 'back' })}
        </Button>
        <Button
          type='button'
          styleType='primary'
          variant='grey'
          size='lg'
          onClick={handleSendOTP}
          disabled={Boolean(remainingTime) || isLoading}
          loading={isLoading}
        >
          {intl.formatMessage({ id: 'auth.resend_sms' })}
        </Button>
      </div>
      {Boolean(remainingTime) && (
        <p className='text-8 text-right mt-3'>
          {intl.formatMessage({ id: 'auth.verify_phone.resend_code_after_x_seconds' }, { remainingTime })}
        </p>
      )}
    </form>
  )
}
