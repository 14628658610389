import React from 'react'
import PropTypes from 'prop-types'
import { T, Icon } from '@nv/react-commons/src/Components'
import { Link } from 'components/Link'
import { StyledButton, StyledButtonGroup, StyledButtonWrapper } from './styles'
import { faDownload } from '@fa-pro-light/faDownload'
import { CenterText } from 'components/CenterText'
import { Colors } from 'themes'
import { ROUTES } from 'containers/Base/constants'
import { Vspace } from 'components/Vspace'
import Config from 'configs'
import { mpTrackDownloadRestrictionList, mpTrackExitOnboarding } from 'containers/FPLMixpanel/helpers'

export const ConfirmButtonGroup = ({ onStartSettingPartner, disabled, isLoading }) => {
  const handleNext = () => {
    onStartSettingPartner()
  }

  const trackDownloadRestriction = () => {
    mpTrackDownloadRestrictionList()
  }

  const handleTrackExit = () => {
    mpTrackExitOnboarding()
  }

  return (
    <StyledButtonGroup>
      <StyledButtonWrapper>
        <StyledButton
          onClick={handleNext}
          type='primary'
          size='medium'
          disabled={disabled}
          loading={isLoading}
          data-testid='international-onboarding-confirm-button'
        >
          <T id='international_start_shipping' />
        </StyledButton>
      </StyledButtonWrapper>
      <Vspace height={20} />
      <StyledButtonWrapper>
        <a
          href={Config.FPL_REGISTRATION_EXTERNAL_LINK}
          target='_blank'
          rel='noopener noreferrer'
          onClick={trackDownloadRestriction}
        >
          <StyledButton size='medium'>
            <CenterText size={12} type='bold' color={Colors.balanceTxtBold}>
              <Icon icon={faDownload} />
              <T id='international_download_list_restriction' allCaps />
            </CenterText>
          </StyledButton>
        </a>
      </StyledButtonWrapper>
      <Link to={ROUTES.HOME} onClick={handleTrackExit}>
        <T id='international_register_later' allCaps />
      </Link>
    </StyledButtonGroup>
  )
}

ConfirmButtonGroup.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onStartSettingPartner: PropTypes.func
}
