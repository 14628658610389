import React from 'react'
import { ToastContext, ToastProps } from '@nv/react-akira'
import { ERROR_CODE_TO_MESSAGE } from 'constants/errors'
import { useContext } from 'react'
import { useIntl } from './common'

export const useToast = (defaultOptions: ToastProps = { label: '', position: 'topRight', appearanceTime: 5000 }) => {
  const { addToast } = useContext(ToastContext)
  const intl = useIntl()

  const show = (options: ToastProps = {} as ToastProps) => {
    addToast({ ...defaultOptions, ...options })
  }

  const showSuccess = (options: ToastProps = {} as ToastProps) => {
    addToast({
      ...defaultOptions,
      type: 'success',
      ...options
    })
  }

  const showError = ({
    message,
    code,
    customErrors = {},
    options = {} as ToastProps
  }: {
    message?: string
    code?: number
    customErrors?: Record<number, string>
    options?: ToastProps
  }) => {
    let errorMessage: string

    if (message) {
      errorMessage = message
    } else if (typeof code === 'number') {
      const errorMessageSuffix = ERROR_CODE_TO_MESSAGE[code]
      const errorCodeMessage = errorMessageSuffix ? `types.error.${errorMessageSuffix}` : 'error'
      errorMessage = customErrors[code] || errorCodeMessage
    }

    errorMessage ||= 'error'

    addToast({
      ...defaultOptions,
      label: intl.formatMessage({ id: errorMessage }),
      type: 'error',
      ...options
    })
  }

  return { show, showSuccess, showError }
}

export function withToast (WrappedComponent) {
  return function ToastComponent (props) {
    const toast = useToast()
    return <WrappedComponent {...toast} {...props} />
  }
}
