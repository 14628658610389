import { batch } from 'react-redux'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { NvApi } from '@nv/react-commons/src/Services'
import countryUtils from '@nv/react-commons/src/Utils/CountryUtils'
import {
  mixpanelTrackLogIn,
  mixpanelTrackSignUpDetails,
  mixpanelTrackSignUpSubmitted
} from 'components/Mixpanel/helpers'
import Config from 'configs'
import { DEFAULT_LOCALE, ROUTES } from 'containers/Base/constants'
import { shipperCreators, userCreators } from 'containers/Base/redux'
import { mpTrackFirstLoginDate } from 'containers/FPLMixpanel/helpers'
import { languageCreators } from 'containers/LanguageProvider/redux'
import { mapShippers, mapSubShippers } from 'containers/LoginFormV1/saga'
import {
  COUNTRY_QUERY_PARAM,
  FROM_LOGIN_QUERY_PARAM,
  SIGN_UP_DATA_LOCAL_STORAGE_KEY,
  SIGNUP_METHOD
} from 'containers/SignUpWrapper/SignUpWrapper.constants'
import { SignUpDataType } from 'containers/SignUpWrapper/SignUpWrapper.interface'
import { SALES_CHANNEL } from 'enums/saleschannel'
import { dashApi } from 'services/api'
import { INVALID_LOGIN, INVALID_REGISTER, LITE_REGISTER, LOGIN } from 'utils/GoogleAnalytics/events'
import { trackEvent } from 'utils/GoogleAnalytics/GAEventTracker'
import { getLocale } from 'utils/locale'
import { getTermsOfUseLink, getWebsiteContactUsLink } from 'utils/website'
import { loginArgs, registerArgs, VerifyUsernameEmailArgs, VerifyUsernamePhoneArgs } from './interface'

export const login = async ({ dispatch, email, phone, navigate }: loginArgs) => {
  if ((email && phone) || (!email && !phone)) {
    throw new Error('Please provide either email or phone, but not both.')
  }

  const loginResponse = await dashApi.loginV2(email || phone)

  const user = loginResponse.data
  let shippers = mapShippers(user)
  let allowFulfillmentService = false

  if (!loginResponse.ok) {
    trackEvent(INVALID_LOGIN)
    return loginResponse
  }

  if (isEmpty(shippers)) {
    return { ok: false }
  }

  NvApi.setAuthToken(user.accessToken)
  const ocSettings = await dashApi.getSettings('order_create', user.defaultShipperId)
  const fulfillmentSettings = await dashApi.getSettings('cross_border', user.defaultShipperId)

  let getSubShippersResponse
  let isCorporateHQ
  if (ocSettings.ok) {
    isCorporateHQ = get(ocSettings, 'data.orderCreate.isCorporateHq')
    const isMarketplace = get(ocSettings, 'data.orderCreate.isMarketplace')
    if (isCorporateHQ || isMarketplace) {
      getSubShippersResponse = await dashApi.getSubShippers(user.defaultShipperId)
    }
  }

  if (user.masterShipper) {
    const corporateBranchResponse = await dashApi.getShipperBranch(user.masterShipper.id, user.defaultShipperId)
    if (corporateBranchResponse?.ok) {
      shippers[user.defaultShipperId].externalRef = corporateBranchResponse.data.externalRef
    }
    const countResp = await dashApi.getShippersCount(user.masterShipper.id)
    if (countResp?.ok) {
      const count = countResp?.data?.data ?? {}
      dispatch(shipperCreators.getSubShippersCount(count))
    }
  }
  if (getSubShippersResponse?.ok) {
    const subShippers = mapSubShippers(getSubShippersResponse, isCorporateHQ)
    shippers = merge(shippers, subShippers)
  }

  if (fulfillmentSettings?.ok) {
    allowFulfillmentService = fulfillmentSettings.data?.crossBorder?.allowFulfillmentService
  }

  dispatch(shipperCreators.getShipperSuccess(shippers))
  const locale = user?.locale || DEFAULT_LOCALE
  NvApi.setLanguage(locale)
  dispatch(languageCreators.changeLocale(getLocale(locale)))
  dispatch(shipperCreators.selectShipper(user.defaultShipperId))

  const { salesChannel, liaisonName, liaisonContact } = user.shippers[0]

  const isSS = salesChannel === SALES_CHANNEL.SelfServe
  const hasCompletedSetupStep = !isEmpty(liaisonName) && !isEmpty(liaisonContact) && liaisonName !== liaisonContact

  if (isSS && !hasCompletedSetupStep) {
    dispatch(userCreators.signUpSetupPending())
    navigate(
      `${ROUTES.SIGNUP_PROFILE}?${FROM_LOGIN_QUERY_PARAM}=true&${COUNTRY_QUERY_PARAM}=${user?.shippers?.[0]?.country}`
    )
  } else {
    sessionStorage.clear()
    dispatch(userCreators.clearSignUpSetupPending())
    mixpanelTrackLogIn(email ? 'Email' : 'Phone Number', user)
    mpTrackFirstLoginDate(allowFulfillmentService)
    trackEvent(LOGIN)
  }
  dispatch(userCreators.loginSuccess(omit(user, 'shippers')))
  return { ok: true }
}

export const register = async ({ dispatch, payload }: registerArgs) => {
  const response = await dashApi.registerV2(payload)

  const user = response.data
  const shippers = mapShippers(user)

  mixpanelTrackSignUpSubmitted(payload.email ? 'email' : 'phone')
  mixpanelTrackSignUpDetails(user)

  if (!response.ok) {
    trackEvent(INVALID_REGISTER)
    return response
  }

  trackEvent({
    action: LITE_REGISTER.action + countryUtils.getCountryNames(user?.shippers[0]?.country),
    label: LITE_REGISTER.label
  })

  NvApi.setAuthToken(user.accessToken)
  const locale = user?.locale || DEFAULT_LOCALE
  NvApi.setLanguage(locale)

  batch(() => {
    dispatch(userCreators.signUpSetupPending())
    dispatch(shipperCreators.getShipperSuccess(shippers))
    dispatch(languageCreators.changeLocale(getLocale(locale)))
    dispatch(shipperCreators.selectShipper(user.defaultShipperId))
    dispatch(userCreators.signUpSuccess(omit(user, 'shippers')))
  })

  return { ok: true }
}

export const verifyUsernameEmail = async ({ country, email }: VerifyUsernameEmailArgs) => {
  return await dashApi.verifyUsername(
    {
      locale: country,
      systemId: country,
      callbackURL: `${Config.WEB_BASE_URL.replace('\r', '')}/v2/signup/password?country=${country}`,
      email: email,
      withOtp: true
    },
    SIGNUP_METHOD.EMAIL
  )
}

export const verifyUsernamePhone = async ({ country, contact }: VerifyUsernamePhoneArgs) => {
  return await dashApi.verifyUsername(
    {
      locale: country,
      systemId: country,
      contact,
      withotp: true
    },
    SIGNUP_METHOD.PHONE
  )
}

export const openTermsOfUseLink = () => {
  let link = getTermsOfUseLink(COUNTRIES.SG)
  try {
    const signUpData: SignUpDataType = JSON.parse(sessionStorage.getItem(SIGN_UP_DATA_LOCAL_STORAGE_KEY))
    if (signUpData.country) {
      link = getTermsOfUseLink(signUpData.country?.toLowerCase())
    }
  } catch (e) {}
  window.open(link, '_blank').opener = null
}

export const openWebsiteContactUsLink = () => {
  let link = getWebsiteContactUsLink(COUNTRIES.SG)
  try {
    const signUpData: SignUpDataType = JSON.parse(sessionStorage.getItem(SIGN_UP_DATA_LOCAL_STORAGE_KEY))
    if (signUpData.country) {
      link = getWebsiteContactUsLink(signUpData.country?.toLowerCase())
    }
  } catch (e) {}
  window.open(link, '_blank').opener = null
}
