import { createGlobalStyle } from 'styled-components'

export const ChatStyles = createGlobalStyle`
  .embeddedServiceSidebar {
    .sidebarHeader {
      h2 {
        color: #ffffff;
      }
    }
  }
  .embeddedServiceSidebarFormField:has(.Email) {
    display: none;
  }
  .embeddedServiceSidebarFormField:has(.Global_ID__c) {
    display: none;
  }
  .embeddedServiceSidebarFormField:has(.Shipper_ID__c) {
    display: none;
  }
  .embeddedServiceSidebarFormField:has(.Shipper_Name__c) {
    display: none;
  }
  .embeddedServiceSidebarFormField:has(.Country__c) {
    display: none;
  }
  .embeddedServiceSidebarFormField:has(.Case_Origin__c) {
    display: none;
  }
`

export const HideChatStyles = createGlobalStyle`
  .embeddedServiceHelpButton {
    display: none !important;
  }
  .embeddedServiceSidebar {
    display: none !important;
  }
`
