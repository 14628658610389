import React, { useCallback, useEffect, useState } from 'react'
import { EdgeFeatureHubConfig, Readyness } from 'featurehub-javascript-client-sdk'
import { useSelector } from 'react-redux'
import Config from 'configs'
import { selectCountry, selectShipper } from 'containers/Base/selectors'
import { mapShipperCountryToFeatureHubCountry } from './utils'
import FeatureFlagKeys from './featureKeys'
import jsonFeatureFlag from './jsonFeatureFlag'
import { defaultFlags, defaultJsonFlags } from './featureFlagsDefaults'
import { FeatureHubContext } from './context'
import { FeatureHubProviderProps } from './FeatureHubProvider.interface'

let fhConfig: EdgeFeatureHubConfig
if (Config.FH_EDGE_URL && Config.FH_API_KEY) fhConfig = new EdgeFeatureHubConfig(Config.FH_EDGE_URL, Config.FH_API_KEY)

export const FeatureHubProvider = ({
  children,
  defaults = { ...defaultFlags, ...defaultJsonFlags }
}: FeatureHubProviderProps) => {
  const shipperCountry = useSelector(selectCountry())
  const shipper = useSelector(selectShipper())
  const shipperId = shipper?.id
  const shipperSalesChannel = shipper?.salesChannel
  const [flags, setFlags] = useState<any>(defaults)
  const [preInitFlags, setPreInitFlagsFlags] = useState<any>(defaults)
  const [loading, setLoading] = useState(true)

  const initializeFeatureHub = useCallback(async () => {
    setLoading(true)

    try {
      const fhClientContext = fhConfig.newContext()

      const isShipperInitialized = Number.isFinite(shipperId)
      if (isShipperInitialized) {
        fhClientContext.country(mapShipperCountryToFeatureHubCountry(shipperCountry))
        fhClientContext.attribute_value('shipperId', shipperId.toString())
        fhClientContext.attribute_value('salesChannel', shipperSalesChannel)
        setPreInitFlagsFlags(null)
      } else {
        fhClientContext.clear()
      }

      const fhClient = await fhClientContext.build()

      return fhConfig.addReadynessListener(readyness => {
        if (readyness === Readyness.Ready) {
          const fhFlags = {} as any
          for (const featureFlag of Object.values(FeatureFlagKeys)) {
            fhFlags[featureFlag] = fhClient.getFlag(featureFlag) ?? defaultFlags[featureFlag]
          }
          for (const featureFlag of Object.values(jsonFeatureFlag)) {
            fhFlags[featureFlag] = fhClient.getJson(featureFlag) ?? defaultJsonFlags[featureFlag]
          }
          if (isShipperInitialized) {
            setFlags(fhFlags)
          } else {
            setPreInitFlagsFlags(fhFlags)
          }
          setLoading(false)
          fhClient.close()
          return
        }

        if (readyness === Readyness.Failed) {
          // eslint-disable-next-line no-console
          console.error('failed to connect to feature hub')
          setLoading(false)
          fhClient.close()
        }
      })
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('could not connect to feature hub', err)
    }

    setLoading(false)
  }, [shipperCountry, shipperId, shipperSalesChannel])

  useEffect(() => {
    let listenerHandle: number | undefined
    const init = async () => {
      listenerHandle = await initializeFeatureHub()
    }
    init()

    return () => {
      if (listenerHandle) {
        fhConfig.removeReadinessListener(listenerHandle)
      }
    }
  }, [initializeFeatureHub])

  return <FeatureHubContext.Provider value={{ loading, flags, preInitFlags }}>{children}</FeatureHubContext.Provider>
}
