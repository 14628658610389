import React, { useCallback } from 'react'
import styled, { withTheme } from 'styled-components'
import { compose } from 'redux'
import { Images } from 'images'
import { Colors, Fonts } from 'themes'
import { Row, T, Col } from '@nv/react-commons/src/Components'
import { Text } from 'components/Text'
import { Button } from 'components/Button'
import { ContentCarousel } from './ContentCarousel'
import { PPT_GUIDE, GG_FORM_LINK } from './constants'

const StyledImgContainer = styled.div`
  position: relative;
  background-image: url(${props => props.bgImg});
  background-color: ${Colors.N000};
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  overflow: auto;
`
const GreetingText = styled(Text)`
  ${Fonts.style.bold};
  color: ${Colors.nvDarkRed};
  font-size: ${props => props.size || 40}px;
`
const StyledContainer = styled.div`
  padding: 32px 64px;
`
const Description = styled(Text)`
  && {
    font-size: 16px;
    margin-bottom: 24px;
  }
`
const StyledButton = styled(Button)`
  && {
    width: 100%;
  }
`
const StyledContentCarousel = styled.div`
  margin-top: 64px;
  margin-bottom: 120px;
`
const StyledImg = styled.img`
  width: 100%;
  height: auto;
`
const DummyCarousel = [
  {
    title: 'international_marketing.1st.title',
    desc: 'international_marketing.1st.desc',
    img: <StyledImg src={Images.parcelEvents} />
  },
  {
    title: 'international_marketing.2nd.title',
    desc: 'international_marketing.2nd.desc',
    img: <StyledImg src={Images.trackingPage} />
  },
  {
    title: 'international_marketing.3rd.title',
    desc: 'international_marketing.3rd.desc',
    img: <StyledImg src={Images.editPopup} />
  },
  {
    title: 'international_marketing.4th.title',
    desc: 'international_marketing.4th.desc',
    img: <StyledImg src={Images.uploadCsv} />
  },
  {
    title: 'international_marketing.5th.title',
    desc: 'international_marketing.5th.desc',
    img: <StyledImg src={Images.itemisedParcel} />
  }
]

const FPLMarketingPage = () => {
  const handleClickJoinBeta = useCallback(() => {
    window.open(GG_FORM_LINK, '_blank')
  }, [])

  const handleClickDownloadPdf = useCallback(() => {
    window.open(PPT_GUIDE, '_blank')
  }, [])

  return (
    <StyledImgContainer bgImg={Images.internationalShipping}>
      <StyledContainer>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 18 }}>
            <GreetingText id='greetings' />
            <GreetingText id='internal_marketing_greetings' size={24} />
            <StyledContentCarousel>
              <ContentCarousel list={DummyCarousel} />
            </StyledContentCarousel>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Description id='internal_marketing_first_desc' />
            <Description id='internal_marketing_second_desc' />
            <Row type='flex' gutter={[12, 12]} justify='start'>
              <Col xl={{ span: 8 }} xs={{ span: 24 }}>
                <StyledButton type='primary' data-analyticsid='4plJoinBetaProgram' onClick={handleClickJoinBeta}>
                  <T id='opt_me_in' allCaps />
                </StyledButton>
              </Col>
              <Col xl={{ span: 8 }} xs={{ span: 24 }}>
                <StyledButton type='secondary' data-analyticsid='4plDownloadBrochure' onClick={handleClickDownloadPdf}>
                  <T id='view_guide' allCaps />
                </StyledButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledContainer>
    </StyledImgContainer>
  )
}

export default compose(withTheme)(FPLMarketingPage)
