import * as Yup from 'yup'
import { parsePhoneNumber } from 'awesome-phonenumber'
import { COUNTRY_CODES } from '@nv/react-commons/src/Constants'
import { SIGNUP_METHOD } from '../SignUpWrapper/SignUpWrapper.constants'

export const SIGN_UP_PROFILE_SCHEMA = Yup.object({
  name: Yup.string().required('your_name_required'),
  storeName: Yup.string().required('name_of_your_store_required'),
  email: Yup.string()
    .trim()
    .email('email_address_invalid')
    .when('method', {
      is: SIGNUP_METHOD.PHONE,
      then: schema => schema.required('email_address_required'),
      otherwise: schema => schema.optional()
    }),
  phoneNumber: Yup.string()
    .trim()
    .when('method', {
      is: SIGNUP_METHOD.EMAIL,
      then: schema => schema.required('phone_number_required'),
      otherwise: schema => schema.optional()
    })
    .test('valid-phone-number', 'phone_number_format', function test (value) {
      if (this.parent.method !== SIGNUP_METHOD.EMAIL) {
        return true
      }
      const country = this.parent.countryCode?.value
      const pn = parsePhoneNumber(value, { regionCode: country })
      return pn.valid && pn.countryCode === parseInt(COUNTRY_CODES[country.toUpperCase()].replace('+', ''))
    }),
  referredPhoneNumber: Yup.string()
    .trim()
    .test('valid-phone-number', 'phone_number_format', function test (value) {
      if (!value) {
        return true
      }
      const country = this.parent.referredCountryCode?.value
      const pn = parsePhoneNumber(value, { regionCode: country })
      return pn.valid && pn.countryCode === parseInt(COUNTRY_CODES[country.toUpperCase()].replace('+', ''))
    })
})

export const NINJA_URL_SIGNUP_DATA_STORAGE_KEY = 'ninjaURLSignUpData'
