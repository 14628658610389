import React from 'react'
import styled from 'styled-components'
import { Icon, T } from '@nv/react-commons/src/Components'
import { faClock } from '@fa-pro-regular/faClock'
import Colors from 'themes/Colors'
import { Select } from '../Select'
import { DeliveryTimeslotSelectorProps } from './DeliveryTimeslotSelector.interface'
import { getKey } from './DeliveryTimeslotSelector.helpers'

export const DeliveryTimeslotSelector = ({
  disabled = false,
  timeslots = [],
  size = 'default',
  width = '100%',
  onChange,
  value
}: DeliveryTimeslotSelectorProps) => {
  return (
    <StyledSelect
      disabled={disabled}
      size={size}
      style={{ width }}
      onChange={onChange}
      value={value}
      showSearch={false}
      placeholder={<T id='pick_time' />}
      suffixIcon={<StyledIcon icon={faClock} size={size} />}
    >
      {timeslots.map(({ id, range }) => (
        <Select.Option key={id} value={id}>
          <T id={`delivery_time.value_${getKey(range)}`} />
        </Select.Option>
      ))}
    </StyledSelect>
  )
}

const StyledSelect = styled(Select)`
  && {
    .ant-select-arrow::before {
      display: none;
    }
  }
`

const StyledIcon = styled(Icon).attrs({
  gap: 10
})`
  color: ${Colors.warmGrey};
  margin-right: 0;
`
