/**
 *
 * OCHeader
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { T, Icon } from '@nv/react-commons/src/Components'
import { BaseHeader } from '../BaseHeader'
import { Button } from '../Button'
import { Default } from '../Media'
import { Modal } from '../Modal'
import { HeaderLogo } from '../HeaderLogo'
import { Text } from '../Text'
import { Vspace } from '../Vspace'
import { Colors } from 'themes'
import { ROUTES } from 'containers/Base/constants'
import { faTimes } from '@fa-pro-regular/faTimes'
import { withSize } from 'components/BreakpointListener'
import { faChevronLeft } from '@fa-pro-regular/faChevronLeft'
import { compose } from 'redux'
import { withNavigate, withLocation } from '../../containers/RouterHOCs'

const StyledButton = styled(Button).attrs({
  size: 'small'
})``
const StyledBackIcon = styled(Icon)`
  margin-left: 0px;
  margin-right: 16px;
`
const StyHeader = styled(BaseHeader)`
  && {
    padding-left: 16px;
    padding-right: 16px;
    background-color: ${props => props.theme.layoutHeaderBackground};
    position: sticky;
  }
`
const StyIcon = styled(Icon)`
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;
`
const StyText = styled(Text)`
  text-align: center;
  display: block;
`

class OCHeader extends React.Component {
  state = { modalVisible: false }

  getCurrent = () => {
    const { location } = this.props
    return parseInt(location.pathname.charAt(location.pathname.lastIndexOf('/') + 1)) - 1 || 0
  }

  isFirstStep = () => this.getCurrent() === 0
  canPrev = () => !this.isFirstStep()
  handlePrev = () => {
    const { navigate, location } = this.props
    if (this.canPrev()) {
      if (location.pathname === ROUTES.OC_3) {
        navigate(ROUTES.OC_SUMMARY)
        return
      }
      navigate(this.getCurrent(), { state: { from: location } })
    }
  }

  handleOpenModal = () => this.setState({ modalVisible: true })
  handleCloseModal = () => this.setState({ modalVisible: false })
  handleLeave = () => {
    const { navigate, onLeave } = this.props
    this.handleCloseModal()
    if (onLeave) {
      onLeave()
    } else {
      navigate(ROUTES.HOME)
    }
  }

  render () {
    const { modalVisible } = this.state
    const title = this.props.title
    const { style, isDesktop } = this.props
    return (
      <StyHeader style={style}>
        <Default>
          <HeaderLogo onClick={this.handleOpenModal} />
        </Default>
        {!isDesktop && this.canPrev() && (
          <StyledBackIcon type='medium' icon={faChevronLeft} color={Colors.white} onClick={this.handlePrev} />
        )}
        <StyText size={18} type='bold' color='white'>
          <T id={title} />
        </StyText>
        <StyIcon
          type='medium'
          onClick={() => this.setState({ modalVisible: true })}
          icon={faTimes}
          color={Colors.white}
        />
        <Modal
          type='info'
          title={<T id={this.props.exitTitle || 'exit_oc'} />}
          visible={modalVisible}
          onCancel={this.handleCloseModal}
          footer={
            <>
              <StyledButton onClick={this.handleCloseModal} data-analyticsid={`exit ${title}`}>
                <T id='dont_leave' />
              </StyledButton>
              <StyledButton type='primary' onClick={this.handleLeave} data-analyticsid={`returnTo ${title}`}>
                <T id='leave' />
              </StyledButton>
            </>
          }
        >
          <StyText type='bold' size={18}>
            <T id='leave_now' />
          </StyText>
          <Vspace height={16} />
          <StyText>
            <T id='leave_reminder' />
          </StyText>
          <Vspace height={24} />
        </Modal>
      </StyHeader>
    )
  }
}

OCHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onLeave: PropTypes.func,
  style: PropTypes.object,
  isDesktop: PropTypes.bool,
  navigate: PropTypes.func,
  location: PropTypes.object,
  exitTitle: PropTypes.string
}

const _OCHeader = compose(withNavigate, withLocation, withSize)(OCHeader)
export { _OCHeader as OCHeader }
