/**
 *
 * Asynchronously loads the component for FPLBatchHistory
 *
 */

import React from 'react'
import loadable from 'utils/loadable'
import { PageLoading } from 'components/Loading'

export default loadable({
  loader: () => import('./index' /* webpackChunkName: "FPLBatchHistory" */),
  loading: <PageLoading />
})
