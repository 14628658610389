import React from 'react'
import PropTypes from 'prop-types'

import Config from 'configs'

import { SOURCE_PRINT_MODAL } from './constants'
import { AwbPrinterModalV1 } from './AwbPrinterModalV1'
import { AwbPrinterModalV2 } from './AwbPrinterModalV2'
import { TYPE } from 'containers/FPLParcelDetail/constants'

export const PrintAwbModal = props => {
  if (Config.FPL_SHOWING_NEW_AWB_VERSION === 'true' && props.parcelType === TYPE.PARCEL) {
    return <AwbPrinterModalV2 {...props} />
  }
  return <AwbPrinterModalV1 {...props} trackingIds={props.parcelIds} />
}

PrintAwbModal.propTypes = {
  batchId: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  totalOrderCount: PropTypes.number,
  trackingIds: PropTypes.arrayOf(PropTypes.string),
  parcelIds: PropTypes.arrayOf(PropTypes.number),
  visible: PropTypes.bool,
  source: PropTypes.string,
  orders: PropTypes.array,
  countryVendor: PropTypes.string,
  parcelType: PropTypes.number
}

PrintAwbModal.defaultProps = {
  visible: false,
  source: SOURCE_PRINT_MODAL.LIST,
  orders: []
}
