import * as Yup from 'yup'
import { COUNTRIES } from '@nv/react-commons/src/Constants/Country'
import { getIcNumberMaxLengthByCountry } from './EKYCModal.helpers'

export const EKYC_MODAL_RESUBMIT_PATH = 'identity-resubmit'
export const EKYC_MODAL_ORDER_CREATION_DISABLED = 'order-creation-disabled'
export const EKYC_MODAL_PATH = 'identity-upload'

export const EKYC_ACCEPTED_FILE_TYPES = 'image/*'

export const EKYC_UPLOAD_MODEL = {
  icNumber: '',
  file: []
}

export const EKYC_UPLOAD_SCHEMA = (country, intl) => {
  const icNumberLength = getIcNumberMaxLengthByCountry(country)
  const isTH = typeof country === 'string' && country?.toLowerCase() === COUNTRIES.TH

  return Yup.object({
    icNumber: Yup.string()
      .trim()
      .required('ic_number_required')
      .matches(isTH ? /^[0-9]*$/ : /^[a-zA-Z0-9]*$/, isTH ? 'ic_number_numeric' : 'ic_number_alphanumeric')
      .test(
        'len',
        intl.formatMessage(
          { id: 'ic_number_length' },
          { length: icNumberLength.join(` ${intl.formatMessage({ id: 'or' })} `) }
        ),
        val => icNumberLength.includes(val.length)
      ),
    file: Yup.array().min(1, 'ic_image_required')
  })
}
