import NvApi from '@nv/react-commons/src/Services/NvApi'
import { formatQueryString } from 'utils/queryString'
import { apiResponseTransformer } from '@nv/react-commons/src/Hcaptcha'
import moment from 'moment'
import { ISO_DATE_FORMAT } from '@nv/react-commons/src/Constants'
import { createApi } from 'services/utils'
import axios from 'axios'

const fplApi = createApi({ country: 'global', service: '3pl', transform: false })
const dashApi = createApi({ country: 'global', service: 'dash', timeout: 30000 })
dashApi.addResponseTransform(apiResponseTransformer)
dashApi.addResponseTransform(handleRateLimitTransformer)

function handleRateLimitTransformer (resp) {
  if (resp.status === 429) {
    resp.data.error = { code: 150011 }
  }
}

export const getFPLApiInstance = () => fplApi
/**
 * get partner detail by global shipper id
 * @param {String} id Global Shipper Id
 */
export const getPartnerDetail = id => {
  return fplApi.post(`v1/dash/shippers/${id}/partner`)
}

/**
 * post 4pl bulk oc by orders
 * @param {Object} data request orders
 */
export const batchOC = data => {
  return fplApi.post('v1/dash/batch-orders', data)
}

/**
 * get 4pl order request list
 * @param {Object} data request data support: { batch_id, status, start, limit }
 */
export const getOrderRequestList = data => {
  const url = `v1/dash/order-requests${formatQueryString(data)}`
  return fplApi.get(url)
}

/**
 * soft delete 4pl order request
 * @param {String} id Order Request Id
 */
export const rollbackOrderRequest = id => {
  return fplApi.delete(`v1/dash/order-requests/${id}`)
}

/**
 * get 4pl order batch list
 * @param {Object} data request data support: { start, limit }
 */
export const getBatchList = data => {
  const url = `v1/dash/batch-orders${formatQueryString(data)}`
  return fplApi.get(url)
}

/**
 * update 4pl order request
 * @param {Object} data request
 */
export const updateOrderRequest = ({ id, ...otherParams }) => {
  const url = `v1/dash/order-requests/${id}`
  return fplApi.put(url, otherParams)
}

/**
 * get 4pl parcel detail
 * @param {Object} data request data support: { parcel_id }
 */
export const getParcelDetail = data => {
  const url = `v1/dash/orders/${data}`
  return fplApi.get(url)
}

/**
 * rollback 4pl parcel
 * @param {Object} data request data support: { parcel_id }
 */
export const rollbackParcel = data => {
  const url = `v1/dash/orders/${data}`
  return fplApi.delete(url)
}

/**
 * search 4pl parcels
 * @param {Object} data request data support: { tracking_ids }
 */
export const searchParcels = data => {
  const url = '/v1/dash/orders/search'
  return fplApi.post(url, data)
}

/**
 * post 4pl lables in pdf
 * @param {Object} data request orders
 */
export const createLabels = data => {
  const url = '/v1/dash/labels'
  return fplApi.post(url, data, { responseType: 'blob' })
}

/**
 * get 4pl saved address
 * @param {Object} data request data support: { countryCode, paging: { start, limit } }
 */
export const getPullAddresses = data => {
  const url = `/v1/dash/addresses/${data.countryCode}${formatQueryString(data.paging)}`
  return fplApi.get(url)
}

export const getListReservation = (addressId, shipperId) => {
  const query = {
    address_id: addressId,
    start_latestdate: moment()
      .utc()
      .format(ISO_DATE_FORMAT),
    status_values: 0
  }
  return dashApi.get(`1.0/reservations${formatQueryString(query)}`, null, {
    headers: { [NvApi.SHIPPER_HEADER]: shipperId }
  })
}

/**
 * Get number of order that shipper created
 * @param {Object} data request orders data support: { tracking_ids, start_time, end_time }
 */
export const getNumberOfOrders = data => {
  const url = '/v1/dash/orders/count'

  return fplApi.post(url, data)
}

/**
 * Get detail parcels with pagination
 * @param {Object} data request orders data support: { tracking_ids, cursor, limit, start_time, end_time }
 */
export const getDetailBulkParcels = data => {
  const url = '/v2/dash/orders/search'

  return fplApi.post(url, data)
}

/**
 * Get download all orders
 * @param {Object} data request orders data support: { tracking_ids, cursor, limit, start_time, end_time }
 */
export const getDownloadAllOrder = data => {
  const url = '/v2/dash/orders/download'

  return fplApi.post(url, data)
}

/**
 * Relabel parcels detail
 * @param {Object} data request data support: { tracking_ids }
 */
export const relabelParcelsDetail = data => {
  const url = '/v1/dash/orders/get-relabel-details'
  return fplApi.post(url, data)
}
/**
 * Get pod image
 * @param {number} parcelId
 */
export const fetchPodImage = parcelId => {
  const url = `/v1/dash/orders/get-pods/${parcelId}`
  return fplApi.get(url)
}

/**
 * Get onboarding lanes
 * @param {Array{string}} countryCodes
 */
export const getLanes = params => {
  const queryString = formatQueryString(params)
  const url = `/api/v1/dash/onboarding/lanes${queryString}`
  return fplApi.get(url)
}

/**
 * create onboarding partners
 * @param {object} data request data support: { shipper_remote_id, destinations }
 */
export const createOnboardingPartners = data => {
  const url = '/api/v1/dash/onboarding/partners'
  return fplApi.post(url, data)
}

/**
 * get high values for countries
 */
export const getHighValues = () => {
  const url = '/v1/dash/configs/parcel'
  return fplApi.get(url)
}

/**
 * post 4pl bulk create mmcc orders
 * @param {BatchB2BRequest} data request orders
 */
export const batchOCMMCCB2B = data => {
  return fplApi.post('v1/dash/batch-orders/mmcc', data)
}

/**
 * post 4pl bulk mmcc B2C orders
 * @param {Object} data request box orders
 */
export const batchB2COrders = data => {
  return fplApi.post('v1/dash/batch-orders/mmcc', data)
}

/**
 * post 4pl invoice to support supporting documents
 * @param {Object} data request file content
 */
export const uploadInvoice = data => {
  return fplApi.post('v1/dash/invoices', data)
}

/**
 * post supporting file to storage
 * @param {Object} data request file data
 */
export const uploadFilesToStorage = (url, fileSlice, header) => {
  return axios.put(url, fileSlice, {
    headers: header
  })
}

export const getDocsByBatchOrderRequestId = batchId => {
  const url = `v1/dash/batches/${batchId}/documents`
  return fplApi.get(url)
}

export const getDocsByParcelId = parcelId => {
  const url = `v1/dash/parcels/${parcelId}/documents`
  return fplApi.get(url)
}
