import { COUNTRIES } from '@nv/react-commons/src/Constants'

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount'
export const DAEMON = '@@saga-injector/daemon'
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount'
export const CONTACT_US_URL = 'https://ninjavan.helpjuice.com/'
export const PICKUP_APPOINTMENT_SEARCH_LIMIT = 500
export const DASH_WEB_SUPPORT_PAGE_REPORTS =
  'https://sites.google.com/ninjavan.co/ninjadashboard/step-by-step-guides/generate-reports'

// TODO Delete when the PMs provide the list of bank accounts for SG and TH
export const SUPPORTED_BANK_DETAILS_COUNTRIES = [COUNTRIES.MY, COUNTRIES.ID, COUNTRIES.VN, COUNTRIES.TH]

export const MOBILE_CUSTOM_SCHEME = 'ninjabizapp'

export const MAX_WEIGHTS = {
  [COUNTRIES.VN]: 50
}

export const MAX_INSURED_VALUES = {
  [COUNTRIES.VN]: 20000000
}

export const MAX_NO_OF_CARTONS = 999
export const VALID_DOCUMENTS_REQURED_VALUE = ['true', 'false']
