import React from 'react'
import { OCSubStep } from 'components/OCSubStep'

import PropTypes from 'prop-types'
import { StyledSubStepWrapper } from './styles'

export const StepCard = ({
  disabledEdit,
  title,
  icon,
  renderActive,
  renderPreview,
  onSave,
  onCancel,
  status,
  onEdit,
  hideSave
}) => {
  const handleSave = () => {
    onSave()
  }

  const handleCancel = () => {
    onCancel()
  }

  const handleEdit = () => {
    onEdit()
  }

  const params = {
    title,
    status: status || OCSubStep.CLOSE,
    renderActive,
    renderPreview,
    onSave: handleSave,
    onCancel: handleCancel,
    hideSave
  }

  if (icon) {
    params.icon = icon
  }

  if (!disabledEdit) {
    params.onEdit = handleEdit
  }

  return (
    <StyledSubStepWrapper>
      <OCSubStep {...params} />
    </StyledSubStepWrapper>
  )
}

StepCard.propTypes = {
  title: PropTypes.string,
  disabledEdit: PropTypes.bool,
  renderActive: PropTypes.func,
  renderPreview: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
  status: PropTypes.string,
  hideSave: PropTypes.bool
}
