import React, { useContext, useMemo } from 'react'
import { MMCC_OC_STEP, SUPPORTED_DOCUMENT_STATUS } from './constants'
import { SupportedDocument } from './SupportedDocument'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { isB2BBoxes } from './utils'
import { MP_ORDER_TYPE } from 'containers/FPLMixpanel/constants'
import { mpTrackAddedDOFile, mpTrackSkipDOFile } from 'containers/FPLMixpanel/helpers'

const DeliveryOrderStep = () => {
  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const { deliveryStatus, deliveryFiles, deliveryErrors, deliveryFileNames, selectedCCType } = ocState

  const mpCustomsClearanceType = useMemo(
    () => (isB2BBoxes(selectedCCType) ? MP_ORDER_TYPE.MMCC_B2B : MP_ORDER_TYPE.MMCC_B2C),
    []
  )

  const handleSkip = () => {
    updateOCState({
      deliveryStatus: SUPPORTED_DOCUMENT_STATUS.IN_PREVIEW,
      currentStep: MMCC_OC_STEP.REVIEW,
      deliveryFiles: [],
      deliveryErrors: [],
      deliveryFileNames: []
    })
    mpTrackSkipDOFile({ orderType: mpCustomsClearanceType })
  }

  const editStep = () => {
    updateOCState({
      deliveryStatus: deliveryFiles.length
        ? SUPPORTED_DOCUMENT_STATUS.UPLOADED_SUCCESS
        : SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD
    })
  }

  const trackAddedDeliveryUploadedFiles = (fileNames: string[], fileInfo) => {
    const extensions = fileNames.map(name => {
      const ext = name.split('.').pop()
      return ext ? ext.toUpperCase() : ''
    })
    mpTrackAddedDOFile({
      fileType: extensions.join(', '),
      fileSize: `${fileInfo.size}${fileInfo.unit}`,
      numberOfFiles: fileInfo.totalFiles,
      orderType: mpCustomsClearanceType
    })
  }

  const saveFiles = (uploadedResult, fileErrors, fileNames) => {
    updateOCState({
      deliveryFiles: uploadedResult,
      deliveryErrors: fileErrors,
      deliveryFileNames: fileNames,
      deliveryStatus: fileErrors.length
        ? SUPPORTED_DOCUMENT_STATUS.UPLOADED_FAIL
        : SUPPORTED_DOCUMENT_STATUS.UPLOADED_SUCCESS
    })
  }

  const goToNextStep = fileInfo => {
    updateOCState({
      currentStep: MMCC_OC_STEP.REVIEW,
      deliveryStatus: SUPPORTED_DOCUMENT_STATUS.IN_PREVIEW
    })
    trackAddedDeliveryUploadedFiles(deliveryFileNames, fileInfo)
  }

  const handleReset = () => {
    updateOCState({
      deliveryFiles: [],
      deliveryStatus: SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD,
      deliveryErrors: [],
      deliveryFileNames: []
    })
  }

  return (
    <SupportedDocument
      fileType={MMCC_OC_STEP.DELIVERY_ORDER}
      status={deliveryStatus}
      errors={deliveryErrors}
      fileNames={deliveryFileNames}
      uploadedFiles={deliveryFiles}
      onEditStep={editStep}
      onSave={saveFiles}
      onSkip={handleSkip}
      onNext={goToNextStep}
      onReset={handleReset}
    />
  )
}

export { DeliveryOrderStep }
