import React from 'react'
import { useFlags } from './useFlag'

export const withFlags = flagsKeys => Component => {
  // eslint-disable-next-line react/display-name
  return props => {
    const statuses = useFlags(flagsKeys)
    return <Component flags={statuses} {...props} />
  }
}
