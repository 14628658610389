import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'

const { Types, Creators } = createActions(
  {
    startupSuccess: []
  },
  { prefix: 'Base/startup/' }
)

export const startupCreators = Creators
export const startupTypes = Types

const INITIAL_STATE = fromJS({
  done: false
})

export const startupReducer = createReducer(INITIAL_STATE, {
  [Types.STARTUP_SUCCESS]: state => state.merge({ done: true })
})
