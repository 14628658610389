import 'moment/locale/th'
import 'moment/locale/vi'
import 'moment/locale/id'
import 'moment/locale/fr'
import 'moment/locale/zh-cn'
import { getLanguagePrefix } from './locale'

const supportedLocales = ['th_TH', 'vi_VN', 'en_US', 'fr_FR', 'id_ID', 'zh_CN']

export const getAntdLocale = localeStr => {
  const lang = getLanguagePrefix(localeStr)
  const locale = supportedLocales.find(l => l.substring(0, 2) === lang) || 'en_US'
  return require(`antd/es/locale/${locale}.js`).default
}
