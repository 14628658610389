import { media } from '@nv/react-commons/src/Themes/Media'
import { Images } from 'images'
import styled, { css } from 'styled-components'

const sharedCss = css`
  margin: 0 16px;
  transform: translateY(24px);
  ${media.max.desktop`margin: 0`};
  ${media.max.tablet`
    transform: none;
    margin: 24px 0 8px;
    width: 103px;
  `};
`

export const RyoHooray = styled.img.attrs({ src: Images.ryoHooray, width: 140 })`
  ${sharedCss}
`
export const RyoFix = styled.img.attrs({ src: Images.ryoFix, width: 140 })`
  ${sharedCss}
`
export const RyoSorry = styled.img.attrs({ src: Images.ryoSorry, width: 140 })`
  ${sharedCss}
`
