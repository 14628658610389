/**
 *
 * MappingItem
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DragSource } from 'react-dnd'
import { Icon, T, Tooltip } from '@nv/react-commons/src/Components'
import { faEllipsisV } from '@fa-pro-regular/faEllipsisV'

import { Text } from '../Text'
import { Colors, Shadows } from 'themes'

const WIDTH = 176

const itemSource = {
  beginDrag ({ id }) {
    return { id }
  },

  endDrag ({ id, put }, monitor) {
    const item = monitor.getItem()
    const dropResult = monitor.getDropResult()

    if (dropResult) {
      put(item.id, dropResult.id, dropResult.item)
    } else {
      put(item.id, -1)
    }
  },

  canDrag ({ editing }) {
    return editing
  }
}

function collect (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

const StyledMappingItem = styled.div`
  width: ${WIDTH}px;
  height: 40px;
  margin: 8px 0;
  padding: 8px;
  border: solid 1px ${Colors.alto};
  border-left: solid 4px ${props => (props.compulsory ? Colors.emerald : Colors.apricot)};
  border-radius: 3px;
  background-color: ${Colors.white};
  line-height: 1.1;
  cursor: ${props => (props.editing ? (props.isDragging ? 'grabbing' : 'grab') : 'auto')};
  visibility: ${props => (props.isDragging ? 'hidden' : 'visible')};
  box-shadow: ${Shadows.regular};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const StyledIcon = styled(Icon)`
  margin-right: 1px;
  color: ${Colors.alto};
`
const StyledIconSpacer = styled(StyledIcon)`
  margin-right: 8px;
`

class MappingItem extends React.Component {
  static HEADER = 'header'
  static WIDTH = WIDTH

  render () {
    const { id, connectDragSource, ...rest } = this.props
    return (
      <Tooltip title={<T id={id} />} placement='right' mouseEnterDelay={0.7}>
        <StyledMappingItem {...rest} id={id} ref={item => connectDragSource(item)}>
          <StyledIcon icon={faEllipsisV} />
          <StyledIconSpacer icon={faEllipsisV} />
          <Text inline type='bold' size={13}>
            <T id={id} />
          </Text>
        </StyledMappingItem>
      </Tooltip>
    )
  }
}

MappingItem.propTypes = {
  id: PropTypes.string.isRequired,
  put: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired
}

const _MappingItem = DragSource(MappingItem.HEADER, itemSource, collect)(MappingItem)

export { _MappingItem as MappingItem }
