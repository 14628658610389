import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ParsedPhoneNumber, parsePhoneNumber } from 'awesome-phonenumber'
import { dashApi } from 'services/api'
import { useInterval, useNavigateWithState } from 'hooks'
import {
  selectCountry,
  selectIsAuthenticated,
  selectIsEkycDone,
  selectIsFullKyc,
  selectMissingEInvoiceDetails,
  selectUser,
  selectVerifiedPhone
} from 'containers/Base/selectors'
import { ROUTES } from 'containers/Base/constants'
import { EKYC_MODAL_ORDER_CREATION_DISABLED, EKYC_MODAL_PATH } from 'containers/EKYC/EKYCModal.constants'
import { userCreators } from 'containers/Base/redux'
import { verifyUsernamePhone } from 'utils/auth'
import {
  PHONE_NUMBER_EXIST,
  RATE_LIMIT_SENT_OTP_SECONDS,
  UPDATE_PHONE_NUMBER,
  VERIFY_PHONE_NUMBER,
  VERIFY_PHONE_NUMBER_OTP
} from './VerifyPhoneNumber.consts'
import { useFlag } from 'featureHub/useFlag'
import featureKeys from 'featureHub/featureKeys'
import { DASH_ERRORS } from 'constants/errors'
import { ADD_ACCOUNT_DETAILS_PATH } from 'containers/AddAccountDetailsModal/AddAccountDetailsModal.constants'
import { ACCOUNT_UPDATE_SUCCESS_PATH } from 'containers/AccountUpdateSuccessModal/AccountUpdateSuccessModal.constants'

interface VerifyPhoneNumberProps {
  userCountryCode?: string
  currentPhoneNumber?: ParsedPhoneNumber
  step?: string
  setStep?: React.Dispatch<React.SetStateAction<string>>
  handleSendOTP?: (phoneNumber?: ParsedPhoneNumber) => void
  remainingTime?: number
  handleOnInputOtp?: (otp: string) => void
  navigateToUpdatePhoneNumberForm?: () => void
  handleOnUpdatePhoneNumber?: (phoneNumber: ParsedPhoneNumber) => any
  user?: any
  resetForm?: () => void
  isAuthenticated?: boolean
  isLoading?: boolean
  modalTitleLokaliseKeyByStep?: string
  verifiedPhone?: boolean
  verificationError?: string
}

const VerifyPhoneNumberContext = React.createContext<VerifyPhoneNumberProps>({})
function VerifyPhoneNumberProvider (props: { children: ReactNode; initialStep?: string }): JSX.Element {
  const navigate = useNavigateWithState()
  const location = useLocation()
  const dispatch = useDispatch()

  const user = useSelector(selectUser())
  const userCountryCode = useSelector(selectCountry())?.toUpperCase?.()
  const isAuthenticated = useSelector(selectIsAuthenticated())
  const verifiedPhone = useSelector(selectVerifiedPhone())
  const isFullKyc = useSelector(selectIsFullKyc())
  const enableEKYC = useFlag(featureKeys.ENABLE_EKYC)
  const disableOCForEKYCIncomplete = useFlag(featureKeys.DISABLE_OC_FOR_EKYC_INCOMPLETE)
  const isEkycDone = useSelector(selectIsEkycDone())
  const missingEInvoiceDetails = useSelector(selectMissingEInvoiceDetails())

  const [currentPhoneNumber, setCurrentPhoneNumber] = useState<ParsedPhoneNumber>(
    parsePhoneNumber(user.contactNo, { regionCode: userCountryCode })
  )
  const [step, setStep] = useState(props.initialStep || VERIFY_PHONE_NUMBER)
  const [otp, setOtp] = useState('')
  const [remainingTime, setRemainingTime] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [verificationError, setVerificationError] = useState('')

  const modalTitleLokaliseKeyByStep = useMemo(() => {
    switch (step) {
      case PHONE_NUMBER_EXIST:
        return 'auth.change_phone_number.phone_exists'
      case UPDATE_PHONE_NUMBER:
        return 'auth.change_phone_number'
      case VERIFY_PHONE_NUMBER:
      case VERIFY_PHONE_NUMBER_OTP:
      default:
        return 'auth.verify_phone'
    }
  }, [step])

  const navigateWithReplace = (path: string) => {
    navigate(`${ROUTES.HOME}/${path}`, { replace: true })
  }

  const resetForm = () => {
    setStep(props.initialStep || VERIFY_PHONE_NUMBER)
    setRemainingTime(0)
    setIsLoading(false)
  }

  const handleSendOTP = async (phoneNumber?: ParsedPhoneNumber) => {
    setIsLoading(true)
    setOtp('')
    const phone = phoneNumber.valid ? phoneNumber : currentPhoneNumber
    const errorCode = await handleVerifyUsernamePhone(phone)
    if (!errorCode) {
      handleNavigateToOTPForm()
      setIsLoading(false)
      return
    }
    if (step === VERIFY_PHONE_NUMBER) {
      setStep(PHONE_NUMBER_EXIST)
    }
    setIsLoading(false)
  }

  const handleNavigateToOTPForm = () => {
    setStep(VERIFY_PHONE_NUMBER_OTP)
    setRemainingTime(RATE_LIMIT_SENT_OTP_SECONDS)
  }

  const handleVerifyUsernamePhone = async (phoneNumber: ParsedPhoneNumber) => {
    const responseVerifyUsernamePhone = await verifyUsernamePhone({
      country: phoneNumber.regionCode,
      contact: phoneNumber.number.e164
    })

    if (!responseVerifyUsernamePhone.ok) {
      const code = responseVerifyUsernamePhone?.data?.error?.code
      return code || true
    }
    return null
  }

  const navigateToUpdatePhoneNumberForm = () => {
    resetForm()
    navigateWithReplace(UPDATE_PHONE_NUMBER)
  }

  const handleOnUpdatePhoneNumber = async (phoneNumber: ParsedPhoneNumber) => {
    setIsLoading(true)
    const errorCode = await handleVerifyUsernamePhone(phoneNumber)
    if (!errorCode) {
      setCurrentPhoneNumber(phoneNumber)
      handleNavigateToOTPForm()
    }
    setIsLoading(false)
    return errorCode
  }

  const handleVerifyOtp = async () => {
    setIsLoading(true)
    const response = await dashApi.addPhoneNumberIdentity(user.id, {
      email: null,
      password: null,
      contact: currentPhoneNumber.number.e164,
      system_id: userCountryCode,
      otp
    })
    if (!response.ok) {
      const code = response?.data?.error?.code
      if (code === DASH_ERRORS.WRONG_CREDENTIALS) {
        setVerificationError('auth.sign_up.verification_failed')
      }
      setIsLoading(false)
      return
    }
    dispatch(userCreators.getUserRequest())
    if (!isEkycDone && disableOCForEKYCIncomplete) {
      navigateWithReplace(EKYC_MODAL_ORDER_CREATION_DISABLED)
    } else if (!isFullKyc && enableEKYC) {
      navigateWithReplace(EKYC_MODAL_PATH)
    } else if (missingEInvoiceDetails) {
      navigateWithReplace(ADD_ACCOUNT_DETAILS_PATH)
    } else {
      navigateWithReplace(ACCOUNT_UPDATE_SUCCESS_PATH)
    }
    resetForm()
  }

  const handleOnInputOtp = (value: string) => {
    setOtp(value)
    setVerificationError('')
  }

  useInterval(
    () => {
      setRemainingTime(remainingTime - 1)
    },
    remainingTime > 0 ? 1000 : null
  )

  useEffect(() => {
    if (otp.length === 6) {
      handleVerifyOtp()
    }
  }, [otp])

  const value: VerifyPhoneNumberProps = {
    userCountryCode,
    currentPhoneNumber,
    step,
    setStep,
    handleSendOTP,
    remainingTime,
    handleOnInputOtp,
    navigateToUpdatePhoneNumberForm,
    handleOnUpdatePhoneNumber,
    user,
    resetForm,
    isAuthenticated,
    isLoading,
    modalTitleLokaliseKeyByStep,
    verifiedPhone,
    verificationError
  }
  return <VerifyPhoneNumberContext.Provider value={value}>{props.children}</VerifyPhoneNumberContext.Provider>
}

function useVerifyPhoneNumber (): VerifyPhoneNumberProps {
  const context = React.useContext(VerifyPhoneNumberContext)
  if (Object.keys(context).length === 0 && context.constructor === Object) {
    throw new Error('useVerifyPhoneNumber must be used within a VerifyPhoneNumberProvider')
  }
  return context
}

export { VerifyPhoneNumberProvider, useVerifyPhoneNumber }
