/**
 * The OrderUploadModal state selectors
 */
import { createSelector } from 'reselect'
import _ from 'lodash'
import { SelectorUtils } from '@nv/react-commons/src/Utils'

const { selector } = SelectorUtils

const NUM_ROW = 3
export const selectMapping = () =>
  createSelector(selector('global', 'orderCreate', 'upload', 'data')(), data => _.zip(..._.slice(data, 0, NUM_ROW)))

export const selectHeader = () =>
  createSelector(selector('global', 'orderCreate', 'upload', 'data')(), data => data && data[0])
