/**
 *
 * OrderStatusFilter
 *
 */

import { withSize } from 'components/BreakpointListener'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import { Checkbox, Col, Icon, Row, T } from '@nv/react-commons/src/Components'
import { Text } from '../Text'
import { Colors } from 'themes'
import { GRANULAR_STATUS as GS } from '@nv/react-commons/src/Constants'
import { Divider } from '../Divider'
import { Link } from '../Link'
import { Button } from '../Button'
import { Vspace } from '../Vspace'
import { faBox } from '@fa-pro-light/faBox'
import { faHandHoldingUsd } from '@fa-pro-light/faHandHoldingUsd'
import { faPlane } from '@fa-pro-light/faPlane'
import { faTh } from '@fa-pro-light/faTh'

const StyledCheckbox = styled(Checkbox)`
  && {
    margin-bottom: 8px;
  }
`
const VBlock = styled.div`
  display: flex;
  flex-direction: column;
`
const ButtonText = styled(Text).attrs({
  type: 'bold',
  size: 11
})`
  transition: all 0.3s;
`
const Footer = styled(Row)`
  && {
    background-color: ${Colors.whiteTree};
    margin: 0px -16px 0px -16px;
    padding: 8px 15px 8px 15px;
    border-radius: 0px 0px 4px 4px;
  }
`

const ApplyButton = styled(Button).attrs({
  size: 'medium'
})`
  && {
    margin-right: 8px;
  }
`
const CancelButton = styled(ApplyButton)`
  && {
    border: none;
    background-color: rgba(0, 0, 0, 0);
  }
`

function LabelIconText ({ id, icon }) {
  return (
    <Row type='flex' align='middle'>
      <Icon type='medium' icon={icon} color={Colors.greyishBrown} fixedWidth gap={8} />
      <Text type='bold' size={12} id={id} allCaps />
    </Row>
  )
}
LabelIconText.propTypes = {
  icon: PropTypes.object,
  id: PropTypes.string
}

class OrderStatusFilter extends React.Component {
  isIndeterminate = checkedList => {
    return !!checkedList.length && checkedList.length < OrderStatusFilter.STATUS.length
  }

  state = {
    checkedList: this.props.checkedList || [],
    indeterminate: this.isIndeterminate(this.props.checkedList || []),
    checkAll: (this.props.checkedList || []).length === OrderStatusFilter.STATUS.length
  }

  static GENERAL_STATUS = [
    // GS.STAGING,
    GS.CANCELLED,
    GS.PENDING_PICKUP,
    GS.VAN_EN_ROUTE_TO_PICKUP,
    GS.EN_ROUTE_TO_SORTING_HUB,
    GS.PICKUP_FAIL,
    GS.ARRIVED_AT_SORTING_HUB,
    GS.ON_HOLD,
    GS.ON_VEHICLE_FOR_DELIVERY,
    GS.PENDING_RESCHEDULE,
    GS.TRANSFERRED_TO_3PL,
    GS.RETURNED_TO_SENDER,
    GS.COMPLETED
  ]

  static COD = 'cod_parcels'
  static NON_COD = 'non_cod_parcels'
  static COD_STATUS = [OrderStatusFilter.COD, OrderStatusFilter.NON_COD]
  static DP_STATUS = [GS.PENDING_PICKUP_AT_DISTRIBUTION_POINT, GS.ARRIVED_AT_DISTRIBUTION_POINT]
  static INTERNATIONAL_STATUS = [
    GS.ARRIVED_AT_ORIGIN_HUB,
    GS.CROSS_BORDER_IN_TRANSIT,
    GS.CROSS_BORDER_CUSTOMS_CLEARED,
    GS.CROSS_BORDER_CUSTOMS_HELD
  ]

  static STATUS = [
    ...OrderStatusFilter.GENERAL_STATUS,
    ...OrderStatusFilter.DP_STATUS,
    ...OrderStatusFilter.COD_STATUS,
    ...OrderStatusFilter.INTERNATIONAL_STATUS
  ]

  renderStatusCheckBoxList (status) {
    return (
      <VBlock>
        {status.map(v => (
          <StyledCheckbox key={v} value={v}>
            <Text size={14} inline id={_.snakeCase(v)} />
          </StyledCheckbox>
        ))}
      </VBlock>
    )
  }

  renderActionButtons () {
    const { isDropdown } = this.props
    return (
      isDropdown && (
        <Footer type='flex' justify='end'>
          <CancelButton hideAnimation onClick={this.handleCancel}>
            <ButtonText color={Colors.warmGrey}>
              <T id='cancel' allCaps />
            </ButtonText>
          </CancelButton>
          <ApplyButton onClick={this.handleApply}>
            <ButtonText>
              <T id='apply' allCaps />
            </ButtonText>
          </ApplyButton>
        </Footer>
      )
    )
  }

  handleCheckOneChange = checkedList => {
    const newState = {
      checkedList,
      indeterminate: this.isIndeterminate(checkedList),
      checkAll: checkedList.length === OrderStatusFilter.STATUS.length
    }
    this.setState(newState)
    if (!this.props.isDropdown) {
      this.props.onChange(checkedList)
    }
  }

  handleCheckAllChange = e => {
    const checkedList = e.target.checked ? OrderStatusFilter.STATUS : []
    const newState = {
      checkedList,
      indeterminate: false,
      checkAll: e.target.checked
    }
    this.setState(newState)
    if (!this.props.isDropdown) {
      this.props.onChange(checkedList)
    }
  }

  handleApply = () => {
    const { onChange } = this.props
    onChange && onChange(this.state.checkedList)
  }

  handleCancel = () => {
    const { hideDropdown } = this.props
    hideDropdown()
  }

  handleReset = () => {
    const { onChange } = this.props
    this.setState({
      checkedList: [],
      indeterminate: false,
      checkAll: false
    })
    onChange && onChange([])
  }

  render () {
    const isDesktop = this.props
    const { checkedList, indeterminate, checkAll } = this.state
    const { GENERAL_STATUS, DP_STATUS, COD_STATUS, INTERNATIONAL_STATUS } = OrderStatusFilter
    return (
      <>
        <Row type='flex' align='middle' justify='space-between'>
          <Col>
            <Checkbox indeterminate={indeterminate} checked={checkAll} onChange={this.handleCheckAllChange}>
              <Text inline size={14} id='select_parcel_statuses' />
            </Checkbox>
          </Col>
          <Col>
            <Link onClick={this.handleReset} size={13} data-analyticsid='resetAdvancedSearchFilters'>
              <T id='reset_filters' />
            </Link>
          </Col>
        </Row>
        <Vspace height={8} />
        {isDesktop && <Divider color={Colors.solitude} />}
        <Vspace height={20} />
        <Checkbox.Group value={checkedList} onChange={this.handleCheckOneChange} style={{ width: '100%' }}>
          <Row gutter={16}>
            <Col sm={12} xs={24}>
              <LabelIconText id='general_statuses' icon={faBox} />
              <Vspace height={8} />
              <Divider color={Colors.solitude} />
              <Vspace height={16} />
              <Row type='flex'>
                <Col sm={12} xs={24}>
                  {this.renderStatusCheckBoxList(GENERAL_STATUS.slice(0, GENERAL_STATUS.length / 2 + 1))}
                </Col>
                <Col sm={12} xs={24}>
                  {this.renderStatusCheckBoxList(GENERAL_STATUS.slice(GENERAL_STATUS.length / 2 + 1))}
                </Col>
              </Row>
            </Col>
            {!isDesktop && <Vspace height={16} />}
            <Col sm={6} xs={24}>
              <LabelIconText id='cash_on_delivery' icon={faHandHoldingUsd} />
              <Vspace height={8} />
              <Divider color={Colors.solitude} />
              <Vspace height={16} />
              {this.renderStatusCheckBoxList(COD_STATUS)}
              <Vspace height={16} />
              <LabelIconText id='dist_points' icon={faTh} />
              <Vspace height={8} />
              <Divider color={Colors.solitude} />
              <Vspace height={16} />
              {this.renderStatusCheckBoxList(DP_STATUS)}
            </Col>
            {!isDesktop && <Vspace height={16} />}
            <Col sm={6} xs={24}>
              <LabelIconText id='international' icon={faPlane} />
              <Vspace height={8} />
              <Divider color={Colors.solitude} />
              <Vspace height={16} />
              {this.renderStatusCheckBoxList(INTERNATIONAL_STATUS)}
            </Col>
          </Row>
        </Checkbox.Group>
        {this.renderActionButtons()}
      </>
    )
  }
}

OrderStatusFilter.propTypes = {
  checkedList: PropTypes.array,
  hideDropdown: PropTypes.func,
  isDropdown: PropTypes.bool,
  onChange: PropTypes.func
}

const _OSFilter = withSize(OrderStatusFilter)
export { _OSFilter as OrderStatusFilter }

LabelIconText.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string
}
