import { NvSocket } from '@nv/react-commons/src/Services'
import Config from 'configs'

let socket

export const makeSocket = token => {
  if (!socket) {
    socket = NvSocket.create({ url: Config.IO_URL, token, isLegacy: true })
  }
  return socket
}

export const destroySocket = () => {
  socket && socket.close()
  socket = null
}

export const getSocket = () => socket
