/**
 *
 * AutoComplete
 *
 */

import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { AutoComplete as NVAutoComplete } from 'antd'

const AutoComplete = ({ placeholder, ...props }) => {
  return (
    <FormattedMessage {...props} id={placeholder}>
      {message => <NVAutoComplete data-lokalise data-key={placeholder} placeholder={message} {...props} />}
    </FormattedMessage>
  )
}

AutoComplete.Option = NVAutoComplete.Option
export { AutoComplete }

AutoComplete.propTypes = {
  placeholder: PropTypes.string
}
