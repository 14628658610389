/**
 *
 * Select
 *
 */

import styled from 'styled-components'
import { NVSelect } from '@nv/react-commons/src/Components'
import { Colors } from 'themes'

const StyledSelect = styled(NVSelect)`
  .ant-select-selection__placeholder {
    color: ${Colors.placeHolder};
  }
`
StyledSelect.Option = NVSelect.Option

export { StyledSelect as Select }
