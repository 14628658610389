import { OC_SERVICE_TYPES } from '../Base/constants'
import { ORDER_CREATE_TYPES } from '../OrderTypeModal/constants'

export const POLLING = {
  SHORT: { timeout: 30000, interval: 1000 },
  MEDIUM: { timeout: 60000, interval: 5000 },
  LONG: { timeout: 120000, interval: 10000 }
}

export const ORDER_CREATE_STATUS = {
  SUBMITTING: 'submitting',
  POLLING: 'polling',
  DONE: 'done',
  ERROR: 'error',
  TIMEOUT: 'timeout'
}

export const orderCreateServiceTypesMap = {
  [ORDER_CREATE_TYPES.REGULAR]: OC_SERVICE_TYPES.PARCEL,
  [ORDER_CREATE_TYPES.RETURN]: OC_SERVICE_TYPES.RETURN,
  [ORDER_CREATE_TYPES.NINJA_PACK]: OC_SERVICE_TYPES.NINJA_PACKS,
  [ORDER_CREATE_TYPES.CORPORATE_AWB]: OC_SERVICE_TYPES.CORPORATE_AWB
}

export const masterOrderCreateServiceTypesMap = {
  [ORDER_CREATE_TYPES.REGULAR]: [OC_SERVICE_TYPES.CORPORATE, OC_SERVICE_TYPES.MARKETPLACE],
  [ORDER_CREATE_TYPES.RETURN]: [OC_SERVICE_TYPES.CORPORATE_RETURN]
}

export const PICKUP_TYPES_OPTIONS_KEYS = {
  SCHEDULED: 'scheduled',
  ON_DEMAND: 'onDemand',
  DROP_OFF: 'dropOff',
  NO: 'no'
}

export const OCPickupOptions = {
  sg: {
    regular: {
      scheduled: true,
      dropOff: true,
      no: true
    },
    ninjaPack: {
      dropOff: true
    },
    corporateAwb: {
      dropOff: true
    },
    return: {
      scheduled: true
    }
  },
  my: {
    regular: {
      scheduled: true,
      dropOff: true,
      no: true
    },
    ninjaPack: {
      dropOff: true
    },
    corporateAwb: {},
    return: {
      scheduled: true,
      dropOff: true
    }
  },
  id: {
    regular: {
      scheduled: true,
      dropOff: true,
      no: true
    },
    ninjaPack: {
      scheduled: true,
      dropOff: true
    },
    corporateAwb: {},
    return: {
      scheduled: true
    }
  },
  th: {
    regular: {
      scheduled: true,
      dropOff: true,
      no: true
    },
    ninjaPack: {
      scheduled: true,
      dropOff: true
    },
    corporateAwb: {},
    return: {
      scheduled: true
    }
  },
  vn: {
    regular: {
      scheduled: true,
      no: true
    },
    ninjaPack: {
      scheduled: true
    },
    corporateAwb: {},
    return: {
      scheduled: true
    }
  },
  ph: {
    regular: {
      scheduled: true,
      dropOff: true,
      no: true
    },
    ninjaPack: {
      scheduled: true,
      dropOff: true
    },
    corporateAwb: {},
    return: {
      scheduled: true
    }
  },
  ma: {
    regular: {
      scheduled: true,
      dropOff: true,
      no: true
    },
    ninjaPack: {
      dropOff: true
    },
    corporateAwb: {},
    return: {
      scheduled: true
    }
  },
  mm: {
    regular: {
      scheduled: true,
      dropOff: true,
      no: true
    },
    ninjaPack: {},
    corporateAwb: {},
    return: {
      scheduled: true
    }
  }
}
