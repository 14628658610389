import { useSelector } from 'react-redux'
import { useFlag } from 'featureHub/useFlag'
import featureFlags from 'featureHub/featureKeys'
import { selectCountry } from 'containers/Base/selectors'
import { ISSUES_TYPES_BY_COUNTRY } from './ShipperSupport.constants'

export const useIsShipperSupportEnabled = () => {
  const isShipperSupportEnabled = useFlag(featureFlags.ENABLE_HELPDESK)
  const country = useSelector(selectCountry())
  return isShipperSupportEnabled && ISSUES_TYPES_BY_COUNTRY[country?.toLowerCase()]?.length > 0
}
