/**
 *
 * Card
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { NVCard } from '@nv/react-commons/src/Components'
import { Colors, media } from 'themes'
import { Animation } from '@nv/react-commons/src/Themes'

const StyledCard = styled(({ withBanner, withHeaderBackground, hasError, scaleOnHover, ...rest }) => (
  <NVCard {...rest} />
))`
  && {
    ${props => props.hasError && Animation.shaking};
    ${props =>
      props.scaleOnHover &&
      css`
        &:hover {
          transform: scale(1.03);
        }
      `};
    border: none;
    border-radius: 5px;
    ${props => props.onClick && 'cursor: pointer;'};
    ${props =>
      props.withHeaderBackground &&
      `.ant-card-head {
      border-bottom: 0;
      background: ${Colors.aliceBlue};
      ${media.max.tablet`
        padding: 0 16px;
      `};
    }`};
  }
`

const Card = props => <StyledCard {...props} />

Card.propTypes = {
  children: PropTypes.node,
  hasError: PropTypes.bool,
  scaleOnHover: PropTypes.bool
}

Card.defaultProps = {
  children: 'Card',
  hasError: false,
  scaleOnHover: false,
  hoverable: true
}

export { Card }
