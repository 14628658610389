/**
 *
 * VolumePicker
 *
 */

import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Col, Icon, Radio, Row, T } from '@nv/react-commons/src/Components'
import { faMotorcycle } from '@fa-pro-light/faMotorcycle'
import { faCar } from '@fa-pro-light/faCar'
import { faTruck } from '@fa-pro-light/faTruck'
import { faTruckContainer } from '@fa-pro-light/faTruckContainer'
import { media } from 'themes'
import { CenterText } from '../CenterText'

const { Button: RButton, Group: RGroup } = Radio

const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.max.tablet`width: 81px`};
`
const StyledRGroup = styled(props => <RGroup {...props} />)`
  && {
    display: inline-flex;
    flex-wrap: wrap;
    .ant-radio-button-wrapper {
      margin-right: 8px;
      margin-bottom: 8px;
      border-radius: 3px;
      height: 88px;
      ${media.min.tablet`
        padding: 20px 10px 12px 10px;
        width: 102px;`};
      ${media.max.tablet`
        padding-top: 15px;
        padding-left: 7px;
        width: 96px;
      `};
    }
    .ant-radio-button-wrapper:last-of-type {
      margin-right: 0;
    }
  }
`
const StyledIcon = styled(Icon).attrs({
  color: 'inherit'
})`
  margin-bottom: 4px;
  height: 22px;
  ${media.max.tablet`font-size: 20px`};
`
const StyledRow = styled(Row)`
  && {
    height: 22px;
    margin-bottom: 4px;
    > div:last-child > ${StyledIcon} {
      margin-right: 0;
    }
  }
`
const StyledCenterText = styled(CenterText)`
  line-height: 16px;
  color: inherit;
  font-size: 12px;
`

class VolumePicker extends React.PureComponent {
  static LESS_THAN_3_PARCELS = 'Less than 3 Parcels'
  static LESS_THAN_10_PARCELS = 'Less than 10 Parcels'
  static HALF_VAN = 'Half-Van Load'
  static FULL_VAN = 'Full-Van Load'
  static MORE_THAN_ONE_VAN = 'Larger than Van Load'

  static keyToProps = {
    [VolumePicker.LESS_THAN_3_PARCELS]: {
      label: 'motorcycle',
      icons: faMotorcycle
    },
    [VolumePicker.LESS_THAN_10_PARCELS]: { label: 'car_boot', icons: faCar },
    [VolumePicker.HALF_VAN]: { label: 'half_van', icons: faTruck },
    [VolumePicker.FULL_VAN]: { label: 'full_van', icons: faTruckContainer },
    [VolumePicker.MORE_THAN_ONE_VAN]: {
      label: 'more_than_a_van',
      icons: _.times(2, () => faTruckContainer)
    }
  }

  render () {
    return (
      <StyledRGroup {...this.props}>
        {_.map(VolumePicker.keyToProps, (v, k) => (
          <RButton key={k} value={k}>
            <StyledBlock>
              {_.isArray(v.icons) && (
                <StyledRow>
                  {v.icons.map((i, j) => (
                    <Col key={j} span={24 / v.icons.length}>
                      <StyledIcon icon={i} type='medium' gap={8} />
                    </Col>
                  ))}
                </StyledRow>
              )}
              {!_.isArray(v.icons) && <StyledIcon icon={v.icons} type='medium' gap={1} />}
              <StyledCenterText>
                <T id={v.label} />
              </StyledCenterText>
            </StyledBlock>
          </RButton>
        ))}
      </StyledRGroup>
    )
  }
}

VolumePicker.propTypes = {
  children: PropTypes.node
}

VolumePicker.defaultProps = {
  children: 'VolumePicker'
}

export { VolumePicker }
