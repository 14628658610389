/**
 *
 * DeliveryTimeFormItem
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { T, Form } from '@nv/react-commons/src/Components'
import { injectIntl } from 'react-intl'
import { FIELDS } from 'components/OCForm/constants'
import { getDeliveryTimeslots } from 'utils/getDeliveryTimeslots'
import { DeliveryTimeslotSelector } from 'components/DeliveryTimeslotSelector'
import { DEFAULT_TIMESLOT_ID } from 'constants/deliveryTimeslots'

class DeliveryTimeFormItem extends React.PureComponent {
  getTimeslots = () => {
    const {
      form: { getFieldValue },
      country,
      allowedDeliveryTimeslots
    } = this.props
    const deliveryType = getFieldValue(FIELDS.DELIVERY_TYPE)
    const timeslots = getDeliveryTimeslots(country, allowedDeliveryTimeslots, deliveryType)
    return timeslots
  }

  componentDidUpdate () {
    const {
      form: { setFieldsValue }
    } = this.props
    const deliveryTimeslot = this.props.form.getFieldValue(FIELDS.DELIVERY_TIMESLOT)
    const timeslots = this.getTimeslots()
    const isValidTimeslot = timeslots.some(timeslot => timeslot.id === deliveryTimeslot)
    if (!isValidTimeslot) {
      setFieldsValue({
        deliveryTimeslot: DEFAULT_TIMESLOT_ID
      })
    }
  }

  render () {
    const {
      formItem: FormItem,
      form: { getFieldDecorator },
      intl,
      disabled,
      initialValue
    } = this.props
    return (
      <FormItem label={<T id='delivery_time' />}>
        {getFieldDecorator(FIELDS.DELIVERY_TIMESLOT, {
          initialValue: initialValue,
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'delivery_time_required'
              })
            }
          ]
        })(<DeliveryTimeslotSelector disabled={disabled} timeslots={this.getTimeslots()} size='small' />)}
      </FormItem>
    )
  }
}

DeliveryTimeFormItem.propTypes = {
  form: PropTypes.object,
  formItem: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  country: PropTypes.string,
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  intl: PropTypes.object,
  allowedDeliveryTimeslots: PropTypes.arrayOf(PropTypes.string)
}

DeliveryTimeFormItem.defaultProps = {
  formItem: Form.Item
}

const _DeliveryTimeFormItem = injectIntl(DeliveryTimeFormItem)
export { _DeliveryTimeFormItem as DeliveryTimeFormItem }
