/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill'
import 'core-js/fn/array/flat-map'

// Import all the third party stuff
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import Immutable from 'immutable'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ToastProvider } from '@nv/react-akira'
import { AppThemes } from 'themes'
import './styles.css'
import 'sanitize.css/sanitize.css'
import '@nv/react-akira/dist/index.css'

// Import root app
import App from 'containers/Base'
import { DEFAULT_LOCALE } from 'containers/Base/constants'

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider'

// Load the favicon, the manifest.json file
import '!file-loader?name=[name].[ext]!./images/favicon.ico'
import '!file-loader?name=[name].[ext]!./images/chrono-diali-favicon.png'
import '!file-loader?name=[name].[ext]!./images/ninja-chrome-192x192.png'
import '!file-loader?name=[name].[ext]!./images/ninja-chrome-256x256.png'
import '!file-loader?name=[name].[ext]!./images/chrono-diali-loading-256x256.svg'
import '!file-loader?name=[name].[ext]!./images/running-ninja.png'
import '!file-loader?name=[name].[ext]!./images/ninja-apple-touch-icon.png'
import '!file-loader?name=[name].json!./manifest.notajson'
import '!file-loader?name=[name].[ext]!./__mocks__/mockServiceWorker.js'

// Load app site association
import '!file-loader?name=.well-known/[name]!./assets/appSiteAssociation/apple-app-site-association'
import '!file-loader?name=.well-known/[name].json!./assets/appSiteAssociation/assetlinks.notajson'

import configureStore from './configureStore'
import { getUrlParameter } from 'utils/url'

// Import i18n messages
import { translationMessages } from './i18n'

// ConfigProvider lets us pass configs (e.g locale) to ant-design components
import ConfigProvider from 'containers/ConfigProvider'

// Mixpanel Initializer
import { MixpanelInitializer } from 'components/Mixpanel'
import { FeatureHubProvider } from './featureHub/FeatureHubProvider'
import { SalesforceChat } from 'components/SalesforceChat'

// Create redux store with history
const initialState = Immutable.Map()
const store = configureStore(initialState)
const MOUNT_NODE = document.getElementById('app')
const msw = getUrlParameter('msw')

if (__DEV__ && msw) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker, loadCustomHandlers } = require('./__mocks__/browser')
  worker.start()
  loadCustomHandlers(msw)
} else {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      for (const registration of registrations) {
        // unregister service worker
        registration.unregister()
      }
    })
  }
}

const render = messages => {
  ReactDOM.render(
    <Provider store={store}>
      <FeatureHubProvider>
        <LanguageProvider messages={messages}>
          <>
            <ThemeProvider theme={AppThemes}>
              <ToastProvider>
                <ConfigProvider>
                  <Router>
                    <MixpanelInitializer>
                      <App />
                    </MixpanelInitializer>
                  </Router>
                </ConfigProvider>
              </ToastProvider>
            </ThemeProvider>
            <SalesforceChat />
          </>
        </LanguageProvider>
      </FeatureHubProvider>
    </Provider>,
    MOUNT_NODE
  )
}

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/Base'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    render(translationMessages)
  })
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'))
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err
    })
} else {
  render(translationMessages)
}

const translator = getUrlParameter('lokalise') === 'translator'
const locale = getUrlParameter('locale')

if (translator) {
  window.LOKALISE_CONFIG = {
    projectId: '260206915ab89fc00a4ec5.45429551',
    locale: locale || DEFAULT_LOCALE
  }

  const a = document.createElement('script')
  a.type = 'text/javascript'
  a.async = !0
  a.src = ['https://lokalise.co/live-js/script.min.js?', new Date().getTime()].join('')
  document.body.appendChild(a)
}
