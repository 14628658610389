import React from 'react'
import { ChatStyles, HideChatStyles } from './SalesforceChat.styled'
import { useSalesforceChat } from './SalesforceChat.hooks'
import { SalesforceChatWarning } from 'components/SalesforceChatWarning'

export const SalesforceChat = () => {
  const { isEnabled, isWithinBusinessHours, isAgentAvailable, getBusinessHours } = useSalesforceChat()

  if (!isEnabled) {
    return <HideChatStyles />
  }

  if (!isWithinBusinessHours) {
    return (
      <>
        <HideChatStyles />
        <SalesforceChatWarning isAgentAvailable={isAgentAvailable} getBusinessHours={getBusinessHours} />
      </>
    )
  }

  return <ChatStyles />
}
