import React from 'react'
import styled from 'styled-components'
import { NVInput, Form, T } from '@nv/react-commons/src/Components'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Colors, media } from 'themes'
import { Text } from 'components/Text'

import { DIMENSIONS_EXCEED_LIMIT_ERROR, FIELDS } from './constants'
import { getMaxDimensions } from './OCForm.helpers'

const parseValue = value => parseFloat(value) || 0

export const CustomDimensions = ({ form: { getFieldDecorator, setFieldsValue }, country, order }) => {
  const hideCustomDimensions = !order?.useCustomDimensions

  const closeCustomDimensions = () => {
    setFieldsValue({ useCustomDimensions: false })
  }

  const fieldOptions = {
    rules: [
      { required: true },
      {
        validator: (rule, value, callback) => {
          const { width, height, length } = order || {}
          const oldValue = parseValue(order?.[rule.field])
          const newValue = parseValue(value)
          const sum = newValue + parseValue(width) + parseValue(height) + parseValue(length) - oldValue

          let error: string | undefined
          if (sum > getMaxDimensions(country)) {
            error = DIMENSIONS_EXCEED_LIMIT_ERROR
          } else if (newValue <= 0) {
            error = 'invalid' // Non-empty value just to triggger a validation error. This message is not used
          }

          callback(error)
        }
      }
    ]
  }

  return (
    <Wrapper style={{ '--display': hideCustomDimensions ? 'none' : undefined } as React.CSSProperties}>
      <div>
        <FieldsLabel id='dimensions_cm' />
        <FieldsWrapper>
          <Form.Item label={<T id='width' />} help={false}>
            {getFieldDecorator(FIELDS.WIDTH, fieldOptions)(<NVInput type='number' min={1} />)}
          </Form.Item>

          <Form.Item label={<T id='height' />} help={false}>
            {getFieldDecorator(FIELDS.HEIGHT, fieldOptions)(<NVInput type='number' min={1} />)}
          </Form.Item>

          <Form.Item label={<T id='length' />} help={false}>
            {getFieldDecorator(FIELDS.LENGTH, fieldOptions)(<NVInput type='number' min={1} />)}
          </Form.Item>
        </FieldsWrapper>
      </div>

      <CloseButton type='button' onClick={closeCustomDimensions}>
        <Icon icon={faTimes} />
      </CloseButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: var(--display, flex);
  gap: 10px;
  margin-top: 8px;
  margin-bottom: 4px;
  position: relative;
  z-index: 100;

  ${media.min.lgTablet`
      margin-bottom: 20px;
  `}

  &::before {
    content: '';
    display: block;
    min-width: 4px;
    background-color: ${Colors.lightGrey};
  }
`

const FieldsLabel = styled(Text)`
  padding-top: 2px;
  font-size: 12px;
  font-weight: bold;
  color: ${Colors.darkGreyBlue};

  ${media.min.lgTablet`
    font-size: 11px;
    font-weight: 400;
  `}
`

const FieldsWrapper = styled.div`
  display: flex;
  gap: 8px;

  .ant-form-item {
    display: flex;
    flex-direction: column;
    margin: 0;
    max-width: 90px;

    ${media.min.lgTablet`
      max-width: 100px;
      width: 100px;
    `}
  }

  .ant-form-item-label {
    order: 1;
    margin-top: -4px;
    text-align: left;
    color: ${Colors.balanceTxtLight};
    & label {
      font-size: 12px !important;

      ${media.min.lgTablet`
        font-size: 11px !important;
     `}
    }
  }
`

const CloseButton = styled.button`
  margin-top: 30px;
  align-self: start;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: ${Colors.black};
`
