/**
 *
 * Preview
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Text } from '../Text'
import { Colors } from 'themes'

const StyledPreview = styled.div`
  display: inline-block;
  vertical-align: top;
`

const Title = styled(({ disabled, ...rest }) => <Text {...rest} />).attrs(props => ({
  type: props.disabled ? 'italic' : 'bold',
  color: props.disabled ? Colors.pinkishGrey : null,
  size: 14
}))``

const Description = styled(Text).attrs({
  color: Colors.pinkishGrey,
  size: 14
})`
  word-break: break-word;
  display: block;
  margin-top: 4px;
  max-width: 440px;
  min-width: 0;
`

const Preview = ({ title, description, disabled }) => (
  <StyledPreview>
    <Title disabled={disabled}>{title}</Title>
    {description && <Description disabled={disabled}>{description}</Description>}
  </StyledPreview>
)

Preview.propTypes = {
  title: PropTypes.node,
  description: PropTypes.node,
  disabled: PropTypes.bool
}

Preview.defaultProps = {
  title: 'Title',
  description: ''
}

export { Preview }
