import { mapValues } from 'lodash'
import { NvApi } from '@nv/react-commons/src/Services'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { ReportSchedulesRequest } from './types'

const shipperApis = mapValues(COUNTRIES, country => NvApi.create({ country, service: 'shipper' }).api)

const getReportSchedules = ({ country }) => {
  return shipperApis[country.toUpperCase()].get('/2.0/shippers/report-schedules')
}

export const deleteReportSchedules = ({ country, id }) => {
  return shipperApis[country.toUpperCase()].delete(`/2.0/shippers/report-schedules/${id}`)
}

export const editReportSchedules = ({ country, id, requestBody }) => {
  return shipperApis[country.toUpperCase()].put(`/2.0/shippers/report-schedules/${id}`, requestBody)
}

export const createReportSchedules = ({
  country,
  requestBody
}: {
  country: string
  requestBody: ReportSchedulesRequest
}) => {
  return shipperApis[country.toUpperCase()].post('/2.0/shippers/report-schedules', requestBody)
}

export default {
  axiosInstances: mapValues(shipperApis, cli => cli.axiosInstance),
  shipperApis,
  getReportSchedules,
  deleteReportSchedules,
  editReportSchedules,
  createReportSchedules
}
