import { fromJS } from 'immutable'
import { createActions, createReducer } from 'reduxsauce'

const { Creators, Types } = createActions(
  {
    request: ['data'],
    success: ['success', 'failure']
  },
  { prefix: 'Base/orderCancelation/' }
)

export const orderCancelationCreators = Creators
export const orderCancelationTypes = Types

const INITIAL_STATE = fromJS({
  failure: [],
  loading: false,
  success: []
})

const request = state => state.merge({ cancelation: { failure: [], loading: true, success: [] } })
const success = (state, { failure, success: _success }) => {
  return state.mergeDeep({ cancelation: { failure, loading: false, success: _success } })
}

export const orderCancelationReducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success
})
