/**
 *
 * MainWrapper
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ApplicationStyles } from 'themes'

const StyledMainWrapper = styled.div`
  ${ApplicationStyles.container};
  display: flex;
  flex: 1;
  flex-direction: column;
`

const MainWrapper = props => <StyledMainWrapper {...props} />

MainWrapper.propTypes = {
  children: PropTypes.node
}

MainWrapper.defaultProps = {}

export { MainWrapper }
