import React from 'react'
import styled from 'styled-components'
import { useMatch } from 'react-router-dom'
import { ROUTES } from 'containers/Base/constants'
import { AKIRA_QUERIES, Colors } from 'themes'
import NVBackgroundImage from 'images/ninjadash-background.svg'
import { OutIcon } from '@nv/react-akira'
import { FormattedMessage } from 'react-intl'
import NinjaScooter from 'images/ninja-scooter.png'
import NinjaScooter2x from 'images/ninja-scooter@2x.png'
import NinjaScooter3x from 'images/ninja-scooter@3x.png'
import { openWebsiteContactUsLink } from 'utils/auth'

export const MobileBanner = () => {
  const isSignUp = useMatch(`${ROUTES.UNIFIED_SIGNUP}/*`)

  if (!isSignUp) {
    return null
  }

  return (
    <Wrapper>
      <HelpWrapper>
        <Link
          onClick={e => {
            e.preventDefault()
            openWebsiteContactUsLink()
          }}
          target='_blank'
        >
          <FormattedMessage id='having_parcel_issues_help_text' />{' '}
          <IconWrapper>
            <OutIcon />
          </IconWrapper>
        </Link>

        <Image
          src={NinjaScooter}
          srcSet={`${NinjaScooter} 1x, ${NinjaScooter2x} 2x, ${NinjaScooter3x} 3x`}
          alt='ninja van mascot, Ryo'
        />
      </HelpWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-left: calc(-1 * var(--padding));
  margin-right: calc(-1 * var(--padding));
  margin-top: -22px;
  width: calc(100% + 2 * var(--padding));
  height: 80px;
  padding: 8px;
  background-image: url(${NVBackgroundImage});
  background-color: ${Colors.akiraRed3};
  background-blend-mode: multiply;
  background-size: cover;
  background-position: calc(50%) calc(50% + 30px);

  @media ${AKIRA_QUERIES.tabletAndUp} {
    height: 110px;
  }

  @media ${AKIRA_QUERIES.laptopAndUp} {
    display: none;
  }
`

const HelpWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 48px;

  font-size: 14px;
  line-height: 22px;
  color: #fff;

  &:hover {
    text-decoration: underline;
  }
`

const Link = styled.a`
  color: currentColor;
  max-width: 236px;

  &:hover {
    color: currentColor;
  }
`

const IconWrapper = styled.span`
  & > * {
    width: 16px;
    height: 16px;
    display: inline;
  }
`

const Image = styled.img`
  display: none;
  align-self: end;
  width: 90px;
  height: 83px;
  transform: translateY(12px);

  @media ${AKIRA_QUERIES.tabletAndUp} {
    display: block;
  }
`
