import React, { useEffect, useState } from 'react'
import { Button } from 'components/Button'
import { media } from '@nv/react-commons/src/Themes/Media'
import { Checkbox, message as messageAntd } from 'antd'
import { fns, NOTICE_MODAL_WIDTH, MessageModal } from './shared'
import { MessageCreators } from 'containers/Base/redux'
import { useReduxHelpers } from './hooks'
import { dashApi } from 'services/api'
import styled from 'styled-components'
import { Text } from 'components/Text'
import T from '@nv/react-commons/src/Components/T'
import { momentUTCWithTimezone } from 'utils/time'
import PropTypes from 'prop-types'

const AcceptCheckboxWrapper = styled.div`
  & {
    display: block;
    text-align: left;
    ${media.max.mobile`
      text-align: center;
    `} margin-bottom: 10px;
  }
`

export function ContractModal ({ shipperId, shipperName, message }) {
  const [intl, dispatch, country] = useReduxHelpers()
  const [visible, setVisible] = useState(true)
  const [checked, setChecked] = useState(false)
  const [sendingAck, setSendingAck] = useState(false)

  useEffect(
    () => {
      setVisible(true)
    },
    [message]
  )

  const text = (
    <T
      id='contract_checkbox_text'
      prefix='message_management'
      values={{
        terms_link: (
          <a target='_blank' rel='noopener noreferrer' href={message?.contractLink}>
            <T id='terms_and_conditions' />
          </a>
        )
      }}
    />
  )

  async function ack () {
    setSendingAck(true)
    const res = await dashApi.sendMessageAck({
      type: message.type,
      version: message.version
    })
    setSendingAck(false)
    if (res.ok) {
      setVisible(false)
      dispatch(MessageCreators.ackMessage(shipperId, fns.getMessageKey(message)))
      messageAntd.success(intl.formatMessage({ id: 'message_management.contract_accepted' }))
    } else {
      messageAntd.error(intl.formatMessage({ id: 'message_management.contract_error' }))
    }
  }
  const noticeStartDate = momentUTCWithTimezone(message?.noticeStartDate, country).format('YYYY-MM-DD')

  return (
    <MessageModal
      width={NOTICE_MODAL_WIDTH}
      closable={false}
      maskClosable={false}
      title={<T id='contract_notice_title' prefix='message_management' />}
      footer={
        <>
          <AcceptCheckboxWrapper>
            <Checkbox onChange={e => setChecked(e.target.checked)}>{text}</Checkbox>
          </AcceptCheckboxWrapper>
          <Button loading={sendingAck} disabled={!checked} onClick={ack} type='primary'>
            <T id='proceed_to_dashboard' />
          </Button>
        </>
      }
      visible={visible}
    >
      <Text id='contract_header' size={18} prefix='message_management' />
      <br />
      <Text
        id='contract_body'
        prefix='message_management'
        style={{ whiteSpace: 'pre-wrap' }}
        values={{
          shipper_name: shipperName,
          notice_date: noticeStartDate,
          contract_link: (
            <a target='_blank' rel='noopener noreferrer' href={message?.contractLink}>
              <T id='here' />
            </a>
          ),
          support_email: (
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={'mailto:' + intl.formatMessage({ id: 'email.support-email' })}
            >
              <T id='email.support-email' />
            </a>
          )
        }}
      />
    </MessageModal>
  )
}

ContractModal.propTypes = {
  message: PropTypes.any,
  shipperId: PropTypes.number,
  shipperName: PropTypes.string
}
/* eslint camelcase:0 */
