import { createApi } from 'services/utils'

const addressingApi = createApi({ country: 'global', service: 'addressing' })

// this API to be removed once we fully switch the new address behavior NDN-615/685
const getZones = country => addressingApi.get('dp-zones', { country })
const getAutocompleteAddressPredictions = (address, country) =>
  addressingApi.get('/3.1/autocomplete', { address, country })
const searchPostcode = postcode => addressingApi.get(`2.0/postcodes/${postcode}`)
const searchGeocodes = address => addressingApi.get('3.0/geocode', { address })
const getPlace = placeId => addressingApi.get('/3.0/address', { placeId })

export default {
  axiosInstance: addressingApi.axiosInstance,
  getAutocompleteAddressPredictions,
  getZones,
  searchPostcode,
  searchGeocodes,
  getPlace
}
