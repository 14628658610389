import React from 'react'
import { T } from '@nv/react-commons/src/Components'
import styled from 'styled-components'
import { Colors } from 'themes'
import PropTypes from 'prop-types'

const StyledSuccessMessage = styled.div`
  margin-top: 9px;
  color: ${Colors.emerald};
`
const StyledErrorMessage = styled.div`
  margin-top: 9px;
  color: ${Colors.salmon};
`
const StyledLoadingMessage = styled.div`
  margin-top: 9px;
  color: ${Colors.warning};
`

const TrackingNumberValidationMessage = ({
  isCorpAWBOrder,
  getFieldValue,
  getFieldError,
  isNinjaPackOrder,
  validationData,
  isValidationLoading
}) => {
  const currentTrackingIdInput = getFieldValue('requestedTrackingNumber')
  const err = getFieldError('requestedTrackingNumber')
  if (isNinjaPackOrder) {
    if (currentTrackingIdInput) {
      if (isValidationLoading) {
        return (
          <StyledLoadingMessage>
            <T id='loading' />
          </StyledLoadingMessage>)
      }
      return validationData ? (
        <StyledSuccessMessage>
          <T id='valid_ninja_pack_tracking_id' />
        </StyledSuccessMessage>
      ) : (
        <StyledErrorMessage>
          <T id='invalid_ninja_pack_tracking_id' />
        </StyledErrorMessage>
      )
    } else {
      return err ? (
        <StyledErrorMessage>
          <T id='empty_id' />
        </StyledErrorMessage>
      ) : (
        <></>
      )
    }
  }
  if (isCorpAWBOrder) {
    if (currentTrackingIdInput) {
      return validationData ? (
        <StyledSuccessMessage>
          <T id='valid_corp_awb_tracking_id' />
        </StyledSuccessMessage>
      ) : (
        <StyledErrorMessage>
          <T id='invalid_corp_awb_tracking_id' />
        </StyledErrorMessage>
      )
    } else {
      return err ? (
        <StyledErrorMessage>
          <T id='corp_awb_empty_id' />
        </StyledErrorMessage>
      ) : (
        <></>
      )
    }
  }
  if (err) {
    return err?.length >= 1 ? <StyledErrorMessage>{err[0]}</StyledErrorMessage> : <></>
  }
  return <></>
}

TrackingNumberValidationMessage.propTypes = {
  getFieldError: PropTypes.func,
  getFieldValue: PropTypes.func,
  validationData: PropTypes.any,
  isValidationLoading: PropTypes.object,
  isNinjaPackOrder: PropTypes.bool,
  isCorpAWBOrder: PropTypes.bool
}

export default TrackingNumberValidationMessage
