/**
 *
 * RadioGroup
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Radio } from '@nv/react-commons/src/Components'
import { Colors, media } from '@nv/react-commons/src/Themes'

const radioGroupStyle = {
  default: css`
    .ant-radio-wrapper {
      white-space: normal;
      margin: 24px 0 0 0;
      .ant-radio + span {
        vertical-align: top;
        display: inline-block;
      }
    }
    .ant-radio-inner {
      height: 16px;
      width: 16px;
      &:after {
        height: 8px;
        width: 8px;
      }
    }
    .ant-radio-checked .ant-radio-inner,
    .ant-radio-checked:after,
    .ant-radio-wrapper:hover .ant-radio .ant-radio-inner {
      height: 16px;
    }
  `,
  button: css`
    width: 100%;
    flex: 0 0 auto;
    ${media.min.tablet`
    && {
      width: 314px;
    }`};
    .ant-radio-button-wrapper {
      height: 32px;
      width: 50%;
      text-align: center;
      line-height: 32px;
      border-color: ${Colors.greyishBrown};
      &:hover {
        border-color: ${Colors.greyishBrown};
        color: inherit;
      }
      &.ant-radio-button-wrapper-checked {
        box-shadow: none;
        border-color: ${Colors.greyishBrown};
        background: ${Colors.greyishBrown};
        color: ${Colors.white};
      }
    }
  `
}
const StyledGroup = styled(Radio.Group)`
  && {
    ${({ type }) => radioGroupStyle[type]};
  }
`

const RadioGroup = props => <StyledGroup {...props} />

RadioGroup.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.any,
  type: PropTypes.oneOf(['default', 'button'])
}

RadioGroup.defaultProps = {
  children: 'RadioGroup',
  type: 'default'
}

export { RadioGroup }
