/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */
import { combineReducers } from 'redux-immutable'

import {
  fcmReducer,
  userReducer,
  shipperReducer,
  orderSearchReducer,
  orderCreateReducer,
  orderReturnReducer,
  orderCancelationReducer,
  printerReducer,
  pickupReducer,
  messageReducer
} from './redux'

const globalReducer = combineReducers({
  fcm: fcmReducer,
  user: userReducer,
  shipper: shipperReducer,
  orderSearch: orderSearchReducer,
  orderCreate: orderCreateReducer,
  orderReturn: orderReturnReducer,
  orderCancelation: orderCancelationReducer,
  printer: printerReducer,
  pickup: pickupReducer,
  message: messageReducer
})

export default globalReducer
