import mixpanel from 'mixpanel-browser'
import Config from 'configs'

const mpToken = Config.MIXPANEL_TOKEN

const wrapMP = fn => {
  try {
    if (mpToken) fn()
  } catch (e) {}
}

export const mpActions = {
  identify: id => {
    wrapMP(() => mixpanel.identify(id))
  },
  track: (name, props) => {
    wrapMP(() => mixpanel.track(name, props))
  },
  reset: () => {
    wrapMP(() => mixpanel.reset())
  },
  register: props => {
    wrapMP(() => mixpanel.register(props))
  },
  people: {
    set: props => {
      wrapMP(() => mixpanel.people.set(props))
    },
    increment: props => {
      wrapMP(() => mixpanel.people.increment(props))
    }
  }
}

export const mpTrackEventWithUserProperties = (eventName, props) => {
  mpActions.track(eventName)
  mpActions.people.set(props)
}

export const mpIncrementalUserProp = (eventName, increment, additionalProps) => {
  const isMultipleProps = Array.isArray(increment)
  const incrementalProps = isMultipleProps
    ? increment.reduce((acc, key) => ({ ...acc, [key]: 1 }), {})
    : { [increment]: 1 }

  mpActions.track(eventName, additionalProps)
  mpActions.people.increment(incrementalProps)
}

export const numOrDefault = (num, def = 0) => (num ? parseInt(num) : def)
export const strOrDefault = (str, def = '-') => str || def
export const boolOf = val => !!val
export const formatISODate = str => (boolOf(Date.parse(str)) ? new Date(str).toISOString() : undefined)
