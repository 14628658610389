import { Card } from 'components/Card'
import styled from 'styled-components'
import { Colors } from 'themes'
import { media } from 'themes'
import { AkiraReset } from 'themes'

export const StyledWrapper = styled.div`
  ${AkiraReset}
  && {
    height: 100%;
    margin-top: 30px;
    margin-left: 25px;
    margin-right: 25px;
    overflow-y: auto;
    ${media.min.mobile`
      margin-top: 40px;
      margin-left: 30px;
      margin-right: 30px;
    `};
  }
`

export const StyledCard = styled(Card)`
  display: flex;
  padding: 30px;
  max-width: 352px;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .ant-btn-link {
    color: ${Colors.akiraRed3};
    padding: 0;
    margin-top: 9px;
    font-weight: bold;
    text-align: start;
    width: fit-content;
    height: fit-content;
  }
`

export const StyledTicketSummaryCard = styled(StyledCard)`
  ${media.min.mobile`
    max-width: 724px;
    width: 100%;

    .ant-card-body {
      display: flex;
      flex-direction: row;
      gap: 40px;
    }
  `};

  .ant-card-body {
    gap: 20px;
  }
`

export const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px;
`

export const ContentCol = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`

export const FlexibleCardDiv = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 20px;
  & > div {
    display: flex;
    flex: 1;
  }
  flex-direction: column;
  ${media.min.mobile`
    flex-direction: row;
  `}
`

export const StyledImage = styled.img`
  width: 100%;
  height: 111px;
  object-fit: contain;
`

export const StyledTicketSummaryImage = styled(StyledImage)`
  ${media.min.mobile`
    width: 90px;
  `};
`

export const StyledBackgroundImage = styled.img`
  width: 100%;
  height: 35%;
  position: fixed;
  bottom: 0;
  object-fit: cover;
  ${media.min.mobile`
    object-fit: fill;
  `}
`
