/**
 *
 * ExistingRsvn
 *
 */

import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { Link } from '../Link'
import { Alert, T } from '@nv/react-commons/src/Components'
import { CountryUtils } from '@nv/react-commons/src/Utils'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { createStructuredSelector } from 'reselect'
import { selectIsSS } from 'containers/Base/selectors'
import { connect } from 'react-redux'
import { compose } from 'redux'

const StyledAlert = styled(Alert)`
  && {
    flex: 1;
  }
`

class ExistingRsvn extends React.PureComponent {
  getKey = t => {
    return t ? `${_.padStart(`${t[0]}`, 2, '0')}${_.padStart(`${t[1]}`, 2, '0')}` : undefined
  }

  render () {
    const { onUseExisting, onEditExisting, rsvns, timeslots, intl, country, isMax, isSS } = this.props
    const rsvn = rsvns[0].data
    const date = moment(rsvn.readyDatetime).format('DD MMM Y')
    const existingTimeslot = CountryUtils.getPickupTimeslotInfo(country)[parseInt(timeslots[0])].range
    const fullDayTimeslot = _.last(CountryUtils.getPickupTimeslotInfo(country)).range
    const time = intl.formatMessage({
      id: `delivery_time.value_${this.getKey(existingTimeslot)}`
    })
    const message =
      !isMax && !_.isEmpty(_.difference(existingTimeslot, fullDayTimeslot)) ? (
        <>
          <T id='existing_pickup' values={{ date, time }} />{' '}
          <Link onClick={() => onUseExisting(rsvn)} data-analyticsid='useExistingOCPickupTimeslot'>
            <T id='use_pickup' />
          </Link>{' '}
          <T id='or' />{' '}
          <Link onClick={() => onEditExisting(rsvn)} data-analyticsid='editExistingOCPickupTimeslot'>
            <T id='edit_the_pickup' />
          </Link>
        </>
      ) : (
        <>
          <T id={isSS ? 'existing_pickups_choose_another_date' : 'existing_pickups'} values={{ date }} />
        </>
      )

    return <StyledAlert showIcon type={isMax ? 'warning' : 'info'} message={message} />
  }
}

ExistingRsvn.propTypes = {
  country: PropTypes.string.isRequired,
  intl: PropTypes.object,
  isMax: PropTypes.bool.isRequired,
  onEditExisting: PropTypes.func.isRequired,
  onUseExisting: PropTypes.func.isRequired,
  rsvns: PropTypes.array.isRequired,
  timeslots: PropTypes.array.isRequired,
  isSS: PropTypes.bool
}

ExistingRsvn.defaultProps = {}

const mapStatetoProps = createStructuredSelector({
  isSS: selectIsSS()
})

const withConnect = connect(mapStatetoProps)

const _ExistingRsvn = compose(withConnect, injectIntl)(ExistingRsvn)
export { _ExistingRsvn as ExistingRsvn }
