import { useStorage } from 'hooks/useStorage'
import React from 'react'
import { connect } from 'react-redux'
import { StyledAlert } from './styles'
import { selectDashShipperType, selectIsPrepaidShipper, selectSalesChannel } from '../Base/selectors'
import { compose } from 'redux'
import { SelectorUtils } from '@nv/react-commons/src/Utils'
import { RichTextElement } from '@kentico/gatsby-kontent-components'
import { getAllEligibleItemsToShow } from 'utils/kontent'
import PropTypes from 'prop-types'

const { selector } = SelectorUtils

const useKontentAlert = (alert, isClosedKey, lastMessageKey) => {
  const [alertIsClosed, setAlertIsClosed] = useStorage(isClosedKey, false)
  const [alertLastMessage, setAlertLastMessage] = useStorage(lastMessageKey, '')

  const url = alert?.url?.value ?? ''
  const message = alert?.description?.value ?? ''
  const type = alert?.alertType?.value[0]?.codename ?? 'info'

  // If the message is changed, we treat it as a new Alert to show
  if (message && message !== alertLastMessage) {
    setAlertLastMessage(message)
    setAlertIsClosed(false)
  }

  const handleOpen = ev => {
    if (ev.target.tagName !== 'A') {
      global.window.open(url, '_blank')
    }
  }

  const handleClose = e => e.stopPropagation()
  const handleAfterClose = () => setAlertIsClosed(true)

  const showAlert = !alertIsClosed && message
  const renderedAlert = (
    <div onClick={handleOpen}>
      <StyledAlert
        afterClose={handleAfterClose}
        banner
        closable
        message={<RichTextElement value={message} />}
        onClose={handleClose}
        showIcon
        type={type}
      />
    </div>
  )

  return [showAlert, renderedAlert]
}

const AlertFullWidth = ({ alerts, isPrepaid, shipperType, salesChannel }) => {
  // TODO: remove alert2 when we have a better solution for the alert
  const [alert, alert2] = getAllEligibleItemsToShow(alerts, { isPrepaid, shipperType, salesChannel })

  const [showAlert, renderedAlert] = useKontentAlert(alert, 'alertIsClosed', 'alertLastMessage')
  const [showAlert2, renderedAlert2] = useKontentAlert(alert2, 'alert2IsClosed', 'alert2LastMessage')

  if (showAlert || showAlert2) {
    return (
      <>
        {showAlert && renderedAlert}
        {showAlert2 && renderedAlert2}
      </>
    )
  } else {
    return null
  }
}

AlertFullWidth.propTypes = {
  alerts: [],
  isPrepaid: PropTypes.bool,
  shipperType: PropTypes.string,
  salesChannel: PropTypes.string
}

const mapStateToProps = state => {
  return {
    alerts: selector('entity', 'alerts', 'data')()(state),
    isPrepaid: selectIsPrepaidShipper()(state),
    shipperType: selectDashShipperType()(state),
    salesChannel: selectSalesChannel()(state)
  }
}

const withConnect = connect(mapStateToProps)

const _AlertFullWidth = compose(withConnect)(AlertFullWidth)

export { _AlertFullWidth as AlertFullWidth }
