/**
 *
 * MiscDropdown
 *
 */

import { T } from '@nv/react-commons/src/Components'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Fonts } from 'themes'
import { Button } from '../Button'
import { Hspace } from '../Hspace'

const StyledButton = styled(Button).attrs({
  size: 'medium'
})`
  && {
    height: 30px;
    text-align: left;
    text-transform: capitalize;
    letter-spacing: normal;
    ${Fonts.style.regular};
  }
`

const MiscDropdown = ({ bulkUploadId, fileUpload, stampId }) => {
  const id =
    (bulkUploadId && 'batch_id_x') ||
    (stampId && 'stamp_id_x') ||
    (fileUpload?.name && 'file_x') ||
    (fileUpload?.trackingIds?.length && 'tracking_ids') ||
    null
  return (
    id && (
      <>
        <Hspace width={16} />
        <StyledButton disabled rightIcon='dropdown'>
          <T id={id} values={{ x: bulkUploadId || stampId || fileUpload.name }} />
        </StyledButton>
      </>
    )
  )
}

MiscDropdown.propTypes = {
  bulkUploadId: PropTypes.string,
  fileUpload: PropTypes.object,
  stampId: PropTypes.string
}

MiscDropdown.defaultProps = {
  children: 'MiscDropdown'
}

export { MiscDropdown }
