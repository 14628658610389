import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import {
  StyledImgFlag,
  RedAsterisk,
  StyledSelect,
  StyledLabel,
  StyledCountrySelectionContainer,
  OptionDevider,
  StyledVisibleSelect,
  ContainerDropdown,
  StyledText,
  LightDevider,
  StyledOption
} from './styles'
import { T, Row } from '@nv/react-commons/src/Components'
import { Text } from 'components/Text'
import { FLAGS_BY_COUNTRY, ASIAN_COUNTRIES } from './constants'
import { Colors } from 'themes'

const OptGroup = Select.OptGroup

const initialState = {
  visibleDropdown: false,
  visibleError: false
}

export const CountrySelection = ({
  countries,
  disabled,
  label,
  isRequiredField,
  onSelectCountry,
  defaultCountries,
  supportedCountries,
  unsupportedCountries,
  selectedSupportedCountries,
  selectedUnsupportedCountries,
  isInGroup
}) => {
  const selectRef = useRef()
  const [state, setState] = useState(initialState)
  const { visibleDropdown, visibleError } = state

  const handleSelectCountry = value => {
    setState(prevState => ({ ...prevState, visibleError: false }))
    let updatedCountries = {
      selectedSupportedCountries,
      selectedUnsupportedCountries
    }
    if (supportedCountries.includes(value)) {
      updatedCountries.selectedSupportedCountries = [...updatedCountries.selectedSupportedCountries, value]
    } else {
      updatedCountries.selectedUnsupportedCountries = [...updatedCountries.selectedUnsupportedCountries, value]
    }
    onSelectCountry(updatedCountries)
  }

  const handleSelectCountries = values => {
    onSelectCountry({
      selectedSupportedCountries: values,
      selectedUnsupportedCountries: []
    })
  }

  const handleDeselect = deselectedValue => {
    const countryKey = deselectedValue.key
    const newSelectedUnsupportedCountries = selectedUnsupportedCountries.filter(country => country !== countryKey)
    const newSelectedSupportedCountries = selectedSupportedCountries.filter(country => country !== countryKey)
    let newState = {
      selectedSupportedCountries: newSelectedSupportedCountries,
      selectedUnsupportedCountries: newSelectedUnsupportedCountries,
      visibleError: true
    }
    if (selectedSupportedCountries.filter(country => country !== countryKey).length) {
      newState = {
        ...newState,
        visibleError: false
      }
    }
    setState(prevState => ({
      ...prevState,
      ...newState
    }))
    onSelectCountry({
      selectedSupportedCountries: newSelectedSupportedCountries,
      selectedUnsupportedCountries: newSelectedUnsupportedCountries
    })
  }

  const renderCountryWithFlag = country => {
    return (
      <StyledOption key={country} value={country}>
        <StyledImgFlag src={FLAGS_BY_COUNTRY[country]} alt={`${country} flag`} />
        <span>{ASIAN_COUNTRIES[country]}</span>
      </StyledOption>
    )
  }

  const renderGroupOptions = () => {
    const groupOptions = [supportedCountries, unsupportedCountries]
    return groupOptions.map((countryGroup, index) => {
      const countryOptions = countryGroup.filter(
        countryCode => ![...selectedSupportedCountries, ...selectedUnsupportedCountries].includes(countryCode)
      )
      return (
        <OptGroup
          key={index}
          label={
            <OptionDevider>
              {index === 0 ? (
                <Text>
                  <T id='international_support_lane_description' />
                </Text>
              ) : (
                <>
                  <Text>
                    <T id='international_unsupport_lane_first_description' />
                  </Text>
                  <Text>
                    <T id='international_unsupport_lane_last_description' />
                  </Text>
                </>
              )}
            </OptionDevider>
          }
        >
          {countryOptions.map(country => renderCountryWithFlag(country))}
        </OptGroup>
      )
    })
  }

  const handleClick = () => {
    setState(prevState => ({ ...prevState, visibleDropdown: true }))
    selectRef.current.focus()
  }

  const handleBlur = () => {
    let newState = {
      visibleDropdown: false,
      visibleError: true
    }
    if (selectedSupportedCountries.length) {
      newState.visibleError = false
    }
    setState(prevState => ({ ...prevState, ...newState }))
  }

  const renderSelectedValues = options => {
    return options.map(country => {
      return (
        <Row key={country} type='flex' align='middle'>
          <StyledImgFlag src={FLAGS_BY_COUNTRY[country]} alt={`${country} flag`} />
          <span>{ASIAN_COUNTRIES[country]}</span>
        </Row>
      )
    })
  }

  const renderSingleDropdown = () => {
    return (
      <StyledSelect
        mode='multiple'
        onChange={handleSelectCountries}
        disabled={disabled}
        getPopupContainer={trigger => trigger}
        defaultValue={defaultCountries}
        showArrow
        data-testid={label}
      >
        {countries.map(country => renderCountryWithFlag(country))}
      </StyledSelect>
    )
  }

  const renderMultipleDropdown = () => {
    return (
      <>
        <ContainerDropdown onClick={handleClick} borderColor={visibleError ? Colors.cherryRed : Colors.lightGreyRed}>
          {(!!selectedSupportedCountries.length || !!selectedUnsupportedCountries.length) && (
            <StyledText top={12} left={12} color={Colors.darkGreyBlue} size={12}>
              <T id='international_support_lane_description' />
            </StyledText>
          )}
          <StyledVisibleSelect
            ref={selectRef}
            mode='multiple'
            value={renderSelectedValues(selectedSupportedCountries)}
            disabled={disabled}
            getPopupContainer={trigger => trigger}
            showArrow
            data-testid={label}
            border='none'
            open={visibleDropdown}
            onBlur={handleBlur}
            onSelect={handleSelectCountry}
            onDeselect={handleDeselect}
          >
            {renderGroupOptions()}
          </StyledVisibleSelect>
          {!!selectedUnsupportedCountries.length && (
            <div>
              <LightDevider />
              <StyledText top={12} left={12} color={Colors.darkGreyBlue} size={12}>
                <T id='international_unsupport_lane_first_description' />
              </StyledText>
              <StyledSelect
                mode='multiple'
                value={renderSelectedValues(selectedUnsupportedCountries)}
                border='none'
                open={false}
                onFocus={handleClick}
                onDeselect={handleDeselect}
                className='unsupported'
              >
                {renderGroupOptions()}
              </StyledSelect>
            </div>
          )}
        </ContainerDropdown>
        {visibleError && (
          <StyledText color={Colors.cherryRed} size={11} top={8}>
            <T id='international_selected_unsupported_country_message' />
          </StyledText>
        )}
      </>
    )
  }

  return (
    <StyledCountrySelectionContainer>
      {label && (
        <StyledLabel size={12}>
          <span>
            <Text color={Colors.lightGreyBalance} inline size={12}>
              <T id={label} />
            </Text>
            {isRequiredField && <RedAsterisk />}
          </span>
        </StyledLabel>
      )}
      {isInGroup ? renderMultipleDropdown() : renderSingleDropdown()}
    </StyledCountrySelectionContainer>
  )
}

CountrySelection.propTypes = {
  countries: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  isRequiredField: PropTypes.bool,
  defaultCountries: PropTypes.array,
  onSelectCountry: PropTypes.func,
  unsupportedCountries: PropTypes.array,
  supportedCountries: PropTypes.array,
  defaultSelectedSupportedCountries: PropTypes.array,
  defaultSelectedUnsupportedCountries: PropTypes.array,
  isInGroup: PropTypes.bool
}

CountrySelection.defaultProps = {
  countries: [],
  disabled: false,
  label: '',
  isRequiredField: false,
  defaultCountries: [],
  unsupportedCountries: [],
  supportedCountries: [],
  selectedSupportedCountries: [],
  selectedUnsupportedCountries: [],
  isInGroup: false
}
