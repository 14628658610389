export const SALES_CHANNELS_BY_COUNTRY = {
  SG: [
    {
      contactInfo: 'sg-sales@ninjavan.co',
      href: 'mailto:sg-sales@ninjavan.co',
      isEmail: true
    }
  ],
  MY: [
    {
      contactInfo: 'salesmy@ninjavan.co',
      href: 'mailto:salesmy@ninjavan.co',
      isEmail: true
    }
  ],
  ID: [
    {
      contactInfo: 'WA - 0811-9600-9450',
      href: 'https://wa.me/+6281196009450'
    },
    {
      contactInfo: 'LINE - @ninjaxpressid',
      href: 'https://line.me/R/ti/p/%40ninjaxpressid'
    }
  ],
  TH: [
    {
      contactInfo: 'LINE - @ninjapremium.th',
      href: 'https://line.me/R/ti/p/%40ninjapremium.th'
    }
  ],
  PH: [
    {
      contactInfo: 'salesph@ninjavan.co',
      href: 'mailto:salesph@ninjavan.co',
      isEmail: true
    }
  ],
  VN: [
    {
      contactInfo: 'salesvn@ninjavan.co',
      href: 'mailto:salesvn@ninjavan.co',
      isEmail: true
    }
  ],
  MM: [
    {
      contactInfo: 'support_mm@ninjavan.co',
      href: 'mailto:support_mm@ninjavan.co',
      isEmail: true
    }
  ]
}
