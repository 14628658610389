/**
 *
 * ParcelDescriptionFormItem
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Form, NVInput } from '@nv/react-commons/src/Components'
import { Colors } from 'themes'
import styled from 'styled-components'
import { Text } from '../Text'
import { selectPrinterSettings } from '../../containers/Base/selectors'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { FIELDS } from 'components/OCForm/constants'

const { TextArea } = NVInput
const Description = styled(Text).attrs({
  color: Colors.pinkishGrey
})`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
`
const StyledTextArea = styled(TextArea).attrs({})`
  margin-top: 0.5em !important;
`

function ParcelDescriptionFormItem (props) {
  const {
    initialValue,
    intl,
    formItem: FormItem,
    form: { getFieldDecorator },
    printerSettings = {},
    country
  } = props
  const { showParcelDescription = false } = printerSettings
  const isRequired = showParcelDescription || country?.toLowerCase() === COUNTRIES.VN
  return (
    <FormItem label={intl.formatMessage({ id: 'parcels_description' })}>
      {getFieldDecorator(FIELDS.PARCEL_DESCRIPTION, {
        initialValue: initialValue,
        rules: [
          {
            required: isRequired,
            message: intl.formatMessage({
              id: 'parcels_description_required'
            })
          }
        ]
      })(
        <StyledTextArea
          placeholder={intl.formatMessage({
            id: isRequired ? 'required_parcels_description_placeholder' : 'optional_parcels_description_placeholder'
          })}
          autoSize={{ minRows: 3 }}
        />
      )}
      <Description>{intl.formatMessage({ id: 'parcels_separate_description' })}</Description>
    </FormItem>
  )
}

ParcelDescriptionFormItem.propTypes = {
  form: PropTypes.object,
  formItem: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  initialValue: PropTypes.string,
  intl: PropTypes.object,
  printerSettings: PropTypes.object,
  country: PropTypes.string
}

ParcelDescriptionFormItem.defaultProps = {
  formItem: Form.Item
}

const mapStatetoProps = createStructuredSelector({
  printerSettings: selectPrinterSettings()
})

const withConnect = connect(mapStatetoProps)

const _ParcelDescriptionFormItem = compose(injectIntl, withConnect)(ParcelDescriptionFormItem)
export { _ParcelDescriptionFormItem as ParcelDescriptionFormItem }
