import { parsePhoneNumber } from 'awesome-phonenumber'
import * as Yup from 'yup'
import { COUNTRY_CODES } from '@nv/react-commons/src/Constants'

export const VERIFY_PHONE_NUMBER = 'verify-phone-number'
export const UPDATE_PHONE_NUMBER = 'update-phone-number'
export const VERIFY_PHONE_NUMBER_OTP = 'verify-phone-number-otp'
export const PHONE_NUMBER_EXIST = 'phone-number-exist'

export const RATE_LIMIT_SENT_OTP_SECONDS = 60

export const UPDATE_PHONE_NUMBER_SCHEMA = Yup.object({
  phoneNumber: Yup.string()
    .trim()
    .required('phone_number_required')
    .test('valid-phone-number', 'phone_number_format', function test (value) {
      const country = this.parent.countryCode?.value
      const pn = parsePhoneNumber(value, { regionCode: country })
      return pn.typeIsMobile && pn.countryCode === parseInt(COUNTRY_CODES[country.toUpperCase()].replace('+', ''))
    })
})
