import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'

const { Types, Creators } = createActions(
  {
    subscribeFcmRequest: [],
    unsubscribeFcmRequest: []
  },
  { prefix: 'Base/fcm/' }
)

export const fcmCreators = Creators
export const fcmTypes = Types

const FCM_INITIAL_STATE = fromJS({
  loading: false,
  error: null,
  hasPermission: false,
  permissionRequestSuccess: false,
  subscribedFcmTopics: []
})

export const fcmReducer = createReducer(FCM_INITIAL_STATE, {
  [Types.UNSUBSCRIBE_FCM_REQUEST]: state => state.merge(FCM_INITIAL_STATE)
})
