/**
 *
 * Tag
 *
 */

import { StyleUtils } from '@nv/react-commons/src/Utils'
import Colors from 'themes/Colors'
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { NVTag } from '@nv/react-commons/src/Components'

const typeToStyle = {
  normal: css`
    background-color: ${Colors.aliceBlue};
    border-color: ${Colors.paleGrey};
    &:hover {
      border-color: ${StyleUtils.rgba(Colors.pinkishGrey, 0.7)};
    }
  `,
  error: css`
    background-color: ${Colors.salmonLight};
    border-color: ${Colors.salmonCream};
    color: ${Colors.salmon};
    &:hover {
      border-color: ${StyleUtils.rgba(Colors.salmon, 0.7)};
    }
  `
}

const StyledTag = styled(NVTag)`
  && {
    ${props => typeToStyle[props.type]};
  }
`

const Tag = props => <StyledTag {...props} />

Tag.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['normal', 'error'])
}

Tag.defaultProps = {
  children: 'Tag',
  type: 'normal'
}

export { Tag }
