import _ from 'lodash'
import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'
import { CountryUtils } from '@nv/react-commons/src/Utils'
import { PREMIUM_SERVICE_LEVEL, STANDARD_SERVICE_LEVEL } from '@nv/react-commons/src/Constants'
import { getTimezone, momentUTCWithTimezone } from 'utils/time'
import featureFlags from 'featureHub/featureKeys'

const moment = extendMoment(Moment)

export const momentWithTimezone = (t, c) => {
  const tz = getTimezone(c)
  return moment(t)
    .set({ hour: 12, minute: 0 })
    .tz(tz)
}

export const getPickupTimeWindow = (start, end, country) => {
  const tz = getTimezone(country)

  const startTime = moment.utc(start).tz(tz)
  const hs = startTime.hours()

  const endTime = moment.utc(end).tz(tz)
  const he = endTime.hours()

  const index = _.findIndex(_.map(CountryUtils.getPickupTimeslotInfo(country), 'range'), { 0: hs, 1: he })
  return index !== -1 ? `${index}` : undefined
}

export const formatTime = (time, country) => {
  return momentUTCWithTimezone(time, country).format('HH:mm')
}
// Expecting time in HH:mm format
// return [hours, minutes]
export const parseTime = time => {
  return _.map(_.split(time, ':') || [0, 0], x => parseInt(x))
}

export const getIsPast = ({ date: xsDate, sameDayPickupCutoffTime: [hours, mins] }, { date, now = moment() }) =>
  moment(date || xsDate)
    .set({ hours, mins })
    .isBefore(now)
export const getIsAPickupAppointment = ({ reservations }, { id }) =>
  Boolean(reservations?.some(r => r.id === id && Boolean(r.isPickupAppointment)))
export const getIsPickupAppointmentEnabled = ({ isPickupAppointmentEnabled }) => !!isPickupAppointmentEnabled
export const getIsPremium = ({ isPremium }) => !!isPremium
export const getIsMilkRun = ({ isMilkRun }) => !!isMilkRun
export const getRsvns = (xs, e) => xs?.dateToRsvn?.[e.date || xs.date]
export const getLength = (xs, e) => getRsvns(xs, e)?.length
export const isEmpty = (xs, e) => !xs.dateToRsvn[e.date || xs.date]
export const isAvailable = (xs, e) => isEmpty(xs, e) || getLength(xs, e) < xs.dailyPickUpLimit
export const isFull = (xs, e) => getLength(xs, e) === xs.dailyPickUpLimit

const afterOneYear = (date, now) => date.isAfter(moment(now).add(1, 'years'))

const beforeCutOffTime = (date, cutOffTime, now) => date.set(cutOffTime).isBefore(moment(now))

const isDateOutOfRange = (date, cutOffTime, rsvn, now) =>
  (beforeCutOffTime(date, cutOffTime, now) && !rsvn) || afterOneYear(date, now)

export const isDisabledDateForPeak = (date, country) => {
  // ND-1195
  const now = moment()
  // effective in sg between Dec 12 ~ 19 inclusive
  if (country.toLowerCase() === 'sg' && now.month() === 11 && now.date() >= 12 && now.date() <= 19) {
    return now.isSame(date, 'day')
  }
  return false
}

export const isDateDisabled = (
  { country, dateToRsvn, sameDayPickupCutoffTime: [hours, minutes], allowPickUpSunday, blockedDatesMap, now, flags },
  { date: d }
) => {
  const date = moment.isMoment(d) ? d.format('YYYY-MM-DD') : d
  const disableSameDayPickup = flags?.[featureFlags.DISABLED_SAME_DAY_PICKUP]
  if (isDisabledDateForPeak(date, country)) {
    return true
  }
  const rsvns = getRsvns({ dateToRsvn }, { date })

  return (
    (disableSameDayPickup && moment(date).isSame(moment(now), 'day')) ||
    (!allowPickUpSunday && d.isoWeekday() === 7) ||
    (blockedDatesMap[date] && _.isEmpty(rsvns)) ||
    isDateOutOfRange(d, { hours, minutes }, rsvns, now)
  )
}

export const getAllValidPickupDates = (xs, { date, date2 }) =>
  (date && date2 ? Array.from(moment.range(date, date2).by('day')) : []) // Get all dates
    .filter(d => !isDateDisabled(xs, { date: d })) // Filter valid dates
    .map(d => d.format('YYYY-MM-DD')) // Map to string

const getPickupStartEndTime = (country, date, timeWindow, isPickupAppointmentEnabled) => {
  const timeslot = CountryUtils.getPickupTimeslotInfo(country)[parseInt(timeWindow)]
  if (!timeslot) {
    return
  }
  const [start, end] = timeslot.range
  const startTime = momentWithTimezone(date, country)
    .set({ hour: start, minute: 0 })
    .format()
  const endTime = momentWithTimezone(date, country)
    .set({ hour: end, minute: 0 })
    .format()

  return isPickupAppointmentEnabled
    ? {
        pickupTimeslot: {
          ready: startTime,
          latest: endTime
        }
      }
    : {
        pickupStartTime: startTime,
        pickupEndTime: endTime
      }
}

export const composePayload = (
  { addressId, country, isPremium, isPickupAppointmentEnabled, address },
  { date, timeWindow, comments, pickupVolume }
) => {
  if (!addressId) {
    addressId = address.id
  }
  const timeslot = getPickupStartEndTime(country, date, timeWindow, isPickupAppointmentEnabled)
  const serviceType = 'Scheduled'
  return isPickupAppointmentEnabled
    ? {
        ...timeslot,
        from: {
          addressId
        },
        pickupService: {
          type: serviceType,
          level: isPremium ? PREMIUM_SERVICE_LEVEL : STANDARD_SERVICE_LEVEL
        },
        pickupInstructions: comments,
        pickupApproxVolume: pickupVolume,
        priorityLevel: 0
      }
    : {
        ...timeslot,
        pickupInstruction: comments,
        reservationTypeValue: isPremium ? 4 : 0,
        pickupAddressId: addressId,
        pickupApproxVolume: pickupVolume,
        pickupServiceType: serviceType,
        pickupServiceLevel: isPremium ? PREMIUM_SERVICE_LEVEL : STANDARD_SERVICE_LEVEL
      }
}

const getRsvnTimeslotRange = rsvn =>
  moment.range(moment.utc(rsvn.readyTime, 'hh:mm:ss'), moment.utc(rsvn.latestTime, 'hh:mm:ss'))

const getPickupTimeslotRange = ({ country }, { timeWindow }, { start }) => {
  const { pickupStartTime, pickupEndTime } = getPickupStartEndTime(country, start, timeWindow)
  return moment.range(pickupStartTime, pickupEndTime)
}

const doesRsvnOverlap = (xs, e, rsvn) => {
  const rsvnTimeslot = getRsvnTimeslotRange(rsvn)
  const pickupTimeslot = getPickupTimeslotRange(xs, e, rsvnTimeslot)
  return rsvnTimeslot.overlaps(pickupTimeslot)
}

const isPickupNarrower = (xs, e, rsvn) => {
  const rsvnTimeslot = getRsvnTimeslotRange(rsvn)
  const pickupTimeslot = getPickupTimeslotRange(xs, e, rsvnTimeslot)
  const rsvnDuration = rsvnTimeslot.diff('hour')
  const pickupDuration = pickupTimeslot.diff('hour')
  return rsvnDuration === 13 && pickupDuration < 13
}

const getOverlappingRsvn = (xs, e) => _.find(getRsvns(xs, e), rsvn => doesRsvnOverlap(xs, e, rsvn))

const getNonOverlappingRsvns = (xs, e) => _.filter(getRsvns(xs, e), rsvn => !doesRsvnOverlap(xs, e, rsvn))

const getAction = (xs, e, action = true) => {
  const overlappingRsvn = getOverlappingRsvn(xs, e)
  if (overlappingRsvn) {
    if (isPickupNarrower(xs, e, overlappingRsvn)) {
      // Use the pickup, because it is the narrower timeslot
      return action ? 'update' : { id: overlappingRsvn.id, ...composePayload(xs, e) }
    } else {
      // Do nothing
      return null
    }
  } else if (isAvailable(xs, e)) {
    // Silently create new reservation
    return action ? 'create' : composePayload(xs, e)
  } else {
    // Display conflict resolution form
    return action ? 'resolve' : getNonOverlappingRsvns(xs, e)
  }
}

export const isConflicting = (xs, e) => _.includes(getDateToActions(xs, e), 'resolve')

export const getDateToActions = (xs, e) =>
  getAllValidPickupDates(xs, e).reduce((r, date) => ({ ...r, [date]: getAction(xs, { ...e, date }) }), {})

export const getDateToActionableRsvns = (xs, e) =>
  getAllValidPickupDates(xs, e).reduce((r, date) => ({ ...r, [date]: getAction(xs, { ...e, date }, false) }), {})

export const mergeDateToResolutions = (xs, e = {}) => {
  let { dateToResolutions } = xs
  if (_.isEmpty(dateToResolutions)) {
    const conflictingDates = _.keys(xs.dateToActionableRsvns).filter(key => _.isArray(xs.dateToActionableRsvns[key]))
    const dateToConflictingRsvns = conflictingDates.reduce(
      (r, date) => ({ ...r, [date]: xs.dateToActionableRsvns[date] }),
      {}
    )
    dateToResolutions = _.mapValues(dateToConflictingRsvns, rsvns =>
      rsvns.map(rsvn => getPickupTimeWindow(rsvn.readyDatetime, rsvn.latestDatetime, xs.country))
    )
  }
  let updatedResolutions = dateToResolutions[e.date]
  if (!_.isEmpty(e)) {
    updatedResolutions = updatedResolutions.map((timeWindow, key) =>
      key === e.key ? _.toString(e.timeWindow) : timeWindow
    )
  }
  return { ...dateToResolutions, [e.date]: updatedResolutions }
}

export const areResolutionsConflicting = xs => {
  if (!xs.dateToResolutions) {
    return false
  }

  const resolutions = _.values(xs.dateToResolutions)
  const fullDay = _.toString(CountryUtils.getPickupTimeslotInfo(xs.country).length - 1)
  const fullDayConflict = resolutions
    .map(timeWindows => timeWindows.length > 1 && _.includes(timeWindows, fullDay))
    .reduce((r, v) => r || v, false)
  if (fullDayConflict) {
    return true
  }

  const conflicts = resolutions
    .map(timeWindows => _.findIndex(_.values(_.countBy(timeWindows)), count => count > 1))
    .reduce((r, v) => r || v > -1, false)
  return conflicts
}

export const composePayloads = ({ dateToActionableRsvns, dateToResolutions, ...xs }, e) => {
  const dateToActRsvns = dateToActionableRsvns || getDateToActionableRsvns(xs, e)
  const actRsvns = _.values(dateToActRsvns).filter(value => !_.isNull(value) && !_.isArray(value))
  if (!dateToResolutions) {
    return actRsvns
  }
  const resolvedRsvns = _.flatMap(
    _.mapValues(dateToResolutions, (timeWindows, date) =>
      timeWindows.map((timeWindow, key) => {
        const rsvn = dateToActionableRsvns[date][key]
        const rsvnTimeWindow = getPickupTimeWindow(rsvn.readyDatetime, rsvn.latestDatetime, xs.country)
        return (
          timeWindow !== rsvnTimeWindow && {
            id: rsvn.id,
            ...composePayload(xs, { ...xs, date, timeWindow })
          }
        )
      })
    )
  ).filter(value => value)
  return [...actRsvns, ...resolvedRsvns]
}
