import { OptionItem } from '@nv/react-akira'
import { COUNTRIES, COUNTRY_NAMES } from '@nv/react-commons/src/Constants'
import systemConfig from 'configs/systemConfig'
import { mapCountriesToOptions } from './CountrySelectField.helpers'

const { NV_SIGN_UP_PRO_COUNTRIES, NV_SS_SUPPORTED_COUNTRIES_LOGIN, NV_SS_SUPPORTED_COUNTRIES_SIGN_UP } = systemConfig

export const SS_COUNTRY_DEFAULT_OPTION = {
  value: COUNTRIES.SG.toUpperCase() as keyof typeof COUNTRIES,
  text: COUNTRY_NAMES[COUNTRIES.SG.toUpperCase()]
}

export const SS_COUNTRY_OPTIONS_LOGIN: OptionItem<keyof typeof COUNTRIES>[] = mapCountriesToOptions(
  NV_SS_SUPPORTED_COUNTRIES_LOGIN
)

export const SS_COUNTRY_OPTIONS_SIGN_UP: OptionItem<keyof typeof COUNTRIES>[] = mapCountriesToOptions(
  NV_SS_SUPPORTED_COUNTRIES_SIGN_UP
)

export const FS_COUNTRY_OPTIONS: OptionItem<keyof typeof COUNTRIES>[] = mapCountriesToOptions(NV_SIGN_UP_PRO_COUNTRIES)
