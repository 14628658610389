import React from 'react'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { Button } from '@nv/react-akira'
import { SubmitButtonProps } from './SubmitButton.interface'

export const SubmitButton = styled(({ className, children }: SubmitButtonProps) => {
  const { isSubmitting } = useFormikContext()
  return (
    <Button
      className={className}
      type='submit'
      styleType='primary'
      variant='grey'
      size='lg'
      loading={isSubmitting}
      disabled={isSubmitting}
    >
      {children}
    </Button>
  )
})``
