/**
 *
 * FetchingMoreIndicator
 *
 */

import PropTypes from 'prop-types'
import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Colors, Shadows } from 'themes'

const moveUp = keyframes`
  from {
    display: none;
    top: 100%;
  }
  to {
    display: block;
    top: calc(100% - 48px);
  }
`

const moveDown = keyframes`
  from {
     display: block;
    top: calc(100% - 48px);
  }
  to {
    display: none;
    top: 100%;
  }
`
const animation = props => {
  const keyframe = props.loading ? moveUp : moveDown
  return css`
    ${keyframe} 500ms ease-in-out;
  `
}

const FetchingMoreIndicator = styled(({ loading, ...props }) => <div {...props} />)`
  width: 100%;
  position: absolute;
  padding: 15px 0;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: ${props => (props.loading ? 'calc(100% - 48px)' : '100%')};
  display: ${props => (props.loading ? 'block' : 'none')};
  background-color: ${Colors.white};
  box-shadow: ${Shadows.regular};
  border-top: solid 1px ${Colors.alto};
  animation: ${animation};
`

FetchingMoreIndicator.propTypes = {
  loading: PropTypes.bool
}

export { FetchingMoreIndicator }
