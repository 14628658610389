import React, { useContext, useState } from 'react'
import { compose } from 'redux'
import { useIntl } from 'hooks/common'
import { Form, NVModal } from '@nv/react-commons/src/Components'
import { OCSubStep } from 'components/OCSubStep'
import { SERVICE_TYPE_LABEL } from 'containers/FPLOrderCreate/constants'
import { Preview } from 'components/Preview'
import { Service, ServiceSelectionProps } from './types'
import {
  StyledRadio,
  StyledRadioGroup,
  StyledServiceCode,
  StyledServiceDescription
} from 'containers/FPLOrderCreate/styles'
import { faShippingTimed } from '@fa-pro-light/faShippingTimed'
import { getCountryLabel } from './utils'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'

interface State {
  currentService: Service
}

const ServiceSelectionForm = ({ status, onCancel, onEdit, onSave }: ServiceSelectionProps) => {
  const { ocState, updateService } = useContext(MMCCOrderCreationContext)
  const intl = useIntl()
  const [state, setState] = useState<State>({
    currentService: ocState.selectedService
  })

  const renderServiceItem = (service: Service, index: number) => {
    return (
      <StyledRadio key={service.id} value={service.code} data-testid={index}>
        <StyledServiceDescription>{getServiceDescription(service)}</StyledServiceDescription>
        <StyledServiceCode>{` (${service.code})`}</StyledServiceCode>
      </StyledRadio>
    )
  }

  const save = () => {
    const modalProps = {
      title: intl.formatMessage({ id: 'warning' }),
      content: intl.formatMessage({ id: 'international_reselect_service_code_content' }),
      okText: intl.formatMessage({ id: 'ok' }),
      cancelText: intl.formatMessage({ id: 'cancel' }),
      onOk () {
        updateService(state.currentService)
        onSave()
      }
    }

    NVModal.confirm(modalProps)
  }

  return (
    <OCSubStep
      status={status}
      title={intl.formatMessage({ id: 'service_type_label' })}
      icon={faShippingTimed}
      renderActive={() => {
        return (
          <>
            <Preview
              title={intl.formatMessage({ id: 'choose_service_type' })}
              description={ocState.services.length > 0 ? '' : intl.formatMessage({ id: 'no_service' })}
            />
            {ocState.services.length > 0 && (
              <StyledRadioGroup
                onChange={e => {
                  setState(prevState => ({
                    ...prevState,
                    currentService: ocState.services.find(item => item.code === e.target.value)
                  }))
                }}
                value={state.currentService.code}
              >
                {ocState.services.map(renderServiceItem)}
              </StyledRadioGroup>
            )}
          </>
        )
      }}
      renderPreview={() => {
        return (
          <>
            <StyledServiceDescription>{getServiceDescription(state.currentService)}</StyledServiceDescription>
            <StyledServiceCode>{` (${state.currentService.code})`}</StyledServiceCode>
          </>
        )
      }}
      onSave={save}
      onEdit={onEdit}
      onCancel={onCancel}
    />
  )
}

const getServiceDescription = (service: Service) => {
  const originCountry = getCountryLabel(service.origin_country)
  const destinationCountry = getCountryLabel(service.destination_country)
  const originSymbol = originCountry ? `${originCountry} → ` : ''
  return `${originSymbol}${destinationCountry} | ${SERVICE_TYPE_LABEL[service.type]} | ${service.description}`
}

const ServiceSelection = compose(Form.create())(React.memo(ServiceSelectionForm))

export { ServiceSelection }
