import React from 'react'
import { OCSubStep } from 'components/OCSubStep'
import { T } from '@nv/react-commons/src/Components'
import { useIntl } from 'hooks/common'
import { Hspace } from 'components/Hspace'
import { faEdit } from '@fa-pro-light/faEdit'

import { Description, FlexBlock, InlineBlock, SeperatedLine, StyledCard } from 'containers/FPLOrderCreate/styles'
import { INPUT_METHODS } from 'containers/FPLOrderCreate/constants'
import { StyledIconButton } from './styles'

interface PreviewStepInfoProps {
  quantity: number
  totalValue: number
  unit: string
  translatedKeys: {
    titleKey: string
    totalPluralKey: string
    totalSingularKey: string
    totalValueKey: string
    creationMethodKey: string
  }
  creationMethod?: string
  icon: JSX.Element
  onEditStep: () => void
}

export const PreviewStepInfo = ({
  quantity,
  totalValue,
  unit,
  translatedKeys,
  creationMethod,
  icon,
  onEditStep
}: PreviewStepInfoProps) => {
  const intl = useIntl()
  const { titleKey, totalPluralKey, totalSingularKey, totalValueKey, creationMethodKey } = translatedKeys
  return (
    <>
      <StyledCard center='center'>
        <FlexBlock>
          <OCSubStep
            status={OCSubStep.DISABLE}
            title={intl.formatMessage({ id: titleKey })}
            icon={icon}
            renderPreview={() => {
              return (
                <InlineBlock>
                  <InlineBlock>
                    {quantity}{' '}
                    {intl.formatMessage({ id: `${quantity > 1 ? totalPluralKey : totalSingularKey}` }).toLowerCase()}
                  </InlineBlock>
                  <Hspace width={16} />
                  <SeperatedLine />
                  <Hspace width={16} />
                  <InlineBlock>
                    <Description>
                      <T id={totalValueKey} />
                    </Description>
                    <Hspace width={4} />
                    <InlineBlock>
                      {totalValue?.toFixed(2)}
                      <Hspace width={3} />
                      {unit?.toUpperCase()}
                    </InlineBlock>
                  </InlineBlock>
                  {creationMethod && (
                    <>
                      <Hspace width={16} />
                      <SeperatedLine />
                      <Hspace width={16} />
                      <InlineBlock>
                        <Description>
                          <T id={creationMethodKey} />
                        </Description>
                        <Hspace width={3} />
                        <Description>
                          <T
                            id={
                              creationMethod === INPUT_METHODS.KEYBOARD
                                ? 'international_creation_method_by_keyboard'
                                : 'upload_file'
                            }
                          />
                        </Description>
                      </InlineBlock>
                    </>
                  )}
                </InlineBlock>
              )
            }}
          />
          <StyledIconButton icon={faEdit} onClick={onEditStep} data-analyticsid='fpleditOCSummary' />
        </FlexBlock>
      </StyledCard>
    </>
  )
}
