import React, { lazy, Suspense } from 'react'

export default ({ loader, loading }) => {
  const LazyComponent = lazy(loader)

  return props => (
    <Suspense fallback={loading}>
      <LazyComponent {...props} />
    </Suspense>
  )
}
