/**
 *
 * TimeSlotSelector
 *
 */

import { faClock } from '@fa-pro-regular/faClock'
import { Icon, T } from '@nv/react-commons/src/Components'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { CountryUtils } from '@nv/react-commons/src/Utils'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Colors } from 'themes'
import { Select } from '../Select'
import { Text } from '../Text'

const Option = Select.Option

const StyledSelect = styled(Select)`
  && {
    .ant-select-arrow::before {
      display: none;
    }
  }
`
const StyledText = styled(Text)`
  display: inline;
  color: ${Colors.pinkishGrey};
  margin-left: 16px;
`
const StyledIcon = styled(Icon).attrs({
  gap: 10
})`
  color: ${Colors.warmGrey};
  margin-right: 0;
`

const BUFFER_IN_MINS = { STANDARD: 180, PREMIUM: 90 }
const getBufferInMins = isPremium => BUFFER_IN_MINS[isPremium ? 'PREMIUM' : 'STANDARD']

class TimeSlotSelector extends React.Component {
  static DEFAULT_TIMESLOT = [9, 22]

  getKey = t => {
    return `${_.padStart(t?.[0], 2, '0')}${_.padStart(t?.[1], 2, '0')}`
  }

  hasSufficientBuffer = (t, isSameday, isPremium) => {
    const now = moment()
    const endTime = moment().set({ hour: t?.[1], minute: 0 })
    const bufferTime = getBufferInMins(isPremium)
    return !isSameday || endTime.diff(now, 'minutes') > bufferTime
  }

  isDisabled = (t, i, c) => {
    const {
      isSameday,
      initialValue,
      isEditing,
      rsvns,
      isPremium,
      timeslots = _.map(CountryUtils.getPickupTimeslotInfo(c), 'range'),
      noBlocking
    } = this.props
    if (noBlocking) {
      return false
    }
    const availableTimeslots = isEditing ? _.filter(rsvns, r => _.toString(r) !== initialValue) : rsvns
    const isInExistingRsvn =
      availableTimeslots &&
      availableTimeslots.length > 0 &&
      (availableTimeslots.indexOf(`${i}`) !== -1 || _.isEmpty(_.difference(t, _.last(timeslots))))
    return isInExistingRsvn || !this.hasSufficientBuffer(t, isSameday, isPremium)
  }

  getLastSelectionIfNeeded = () => {
    const {
      country,
      timeslots = CountryUtils.getPickupTimeslotInfo(country),
      value,
      isLastSelection,
      setFieldsValue
    } = this.props
    if (!setFieldsValue) {
      return
    }

    let val
    if (isLastSelection && !value) {
      const index = timeslots.findLastIndex((timeslot, i) => !this.isDisabled(timeslot.range, i, country))
      val = index !== -1 ? String(index) : undefined
    }

    if (val !== undefined) {
      setFieldsValue({
        [this.props.id]: val
      })
    }
  }

  componentDidMount () {
    this.getLastSelectionIfNeeded()
  }

  componentDidUpdate () {
    this.getLastSelectionIfNeeded()
  }

  render () {
    const {
      country,
      timeslots = CountryUtils.getPickupTimeslotInfo(country),
      size,
      width,
      onChange,
      rsvns,
      disabled,
      noBlocking,
      value
    } = this.props

    return (
      <StyledSelect
        disabled={disabled}
        size={size}
        style={{ width }}
        onChange={onChange}
        value={value}
        showSearch={false}
        placeholder={<T id='pick_time' />}
        suffixIcon={<StyledIcon icon={faClock} size={size} />}
      >
        {timeslots.map((timeslot, i) => {
          const exists = !noBlocking && rsvns && rsvns.indexOf(`${i}`) !== -1
          return (
            <Option key={i} value={`${i}`} disabled={this.isDisabled(timeslot.range, i, country)}>
              <T id={`delivery_time.value_${this.getKey(timeslot.range)}`} />
              {exists && (
                <StyledText type='italic'>
                  <T id='upcoming_pickup' />
                </StyledText>
              )}
            </Option>
          )
        })}
      </StyledSelect>
    )
  }
}

TimeSlotSelector.propTypes = {
  country: PropTypes.oneOf(Object.values(COUNTRIES)),
  disabled: PropTypes.bool,
  isEditing: PropTypes.bool,
  isPremium: PropTypes.bool,
  isSameday: PropTypes.bool,
  initialValue: PropTypes.string,
  noBlocking: PropTypes.bool,
  rsvns: PropTypes.array,
  timeslots: PropTypes.array,
  size: PropTypes.oneOf(['default', 'small', 'large']),
  width: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.any,
  isLastSelection: PropTypes.bool
}

TimeSlotSelector.defaultProps = {
  width: '100%'
}

export { BUFFER_IN_MINS, getBufferInMins, TimeSlotSelector }
