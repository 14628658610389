/**
 * Custom Hooks
 */

import { SelectorUtils } from '@nv/react-commons/src/Utils'
import { message } from '@nv/react-commons/src/Components'
import _ from 'lodash'
import { intl } from 'containers/IntlGlobalProvider'
import { useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { ERROR_CODE_TO_MESSAGE } from 'constants/errors'

const { selector } = SelectorUtils

export const useIntl = () => {
  return intl
}

export const useMemoSelector = makeSelect => {
  const select = useMemo(makeSelect, [])
  return useSelector(select)
}

export const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useMonitor = (
  monitor,
  {
    customErrors = {},
    onError,
    onSuccess,
    successIndicator = 'success',
    successMessage = 'success',
    successValues = {},
    showSuccessNotification = true,
    ignoreCustomErrors = {}
  }
) => {
  const { data, error, loading } = useMemoSelector(selector('entity', monitor)) || {}
  const prevLoading = usePrevious(loading)
  useEffect(() => {
    if (!loading && prevLoading) {
      const successEntity = data && (_.isFunction(successIndicator) ? successIndicator(data) : data?.[successIndicator])
      if (successEntity) {
        if (showSuccessNotification) {
          message.success(
            intl.formatMessage(
              { id: `types.success.${successMessage}` },
              _.isString(successValues) ? { [successValues]: data?.[successValues] } : successValues
            )
          )
        }
        if (_.isFunction(onSuccess)) {
          onSuccess(successEntity)
        }
      } else if (error) {
        // used to ignore custom error codes and display equivalent success message to them
        if (ignoreCustomErrors[error.code]) {
          message.success(
            intl.formatMessage(
              { id: `types.success.${ignoreCustomErrors[error.code]}` },
              _.isString(successValues) ? { [successValues]: data?.[successValues] } : successValues
            )
          )
        } else {
          const errorMessage = customErrors[error.code] || ERROR_CODE_TO_MESSAGE[error.code]
          if (errorMessage) {
            message.error(
              intl.formatMessage({
                id: `types.error.${errorMessage}`
              })
            )
          }
        }
        if (_.isFunction(onError)) {
          onError(error)
        }
      }
    }
  }, [
    loading,
    data,
    error,
    prevLoading,
    successIndicator,
    successMessage,
    successValues,
    onSuccess,
    customErrors,
    onError,
    showSuccessNotification
  ])
}
