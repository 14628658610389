import { fromJS } from 'immutable'
import { createActions, createReducer } from 'reduxsauce'
import { DEFAULT_LOCALE } from 'containers/Base/constants'

const { Types, Creators } = createActions(
  {
    changeLocale: ['locale', 'showNotification'],
    set: ['key', 'value']
  },
  { prefix: 'app/LanguageToggle/' }
)

export const languageTypes = Types
export const languageCreators = Creators

const INITIAL_STATE = fromJS({
  locale: DEFAULT_LOCALE,
  showNotification: false
})

export const languageReducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_LOCALE]: (state, { locale, showNotification }) =>
    state.merge({ locale, showNotification: !!showNotification }),
  [Types.SET]: (state, { key, value }) => state.merge({ [key]: value })
})
