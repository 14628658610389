import { omitBy, isNil } from 'lodash'
import { SalesChannel } from './types'
import { createApi } from 'services/utils'

const shipperAddressApi = createApi({ country: 'global', service: 'shipper-address' })

const getDivisions = ({
  includeDeliveryUnsupported,
  includePickupUnsupported,
  salesChannel = 'fs',
  ...data
}: {
  language: string
  country: string
  region: string
  locality: string
  includeDeliveryUnsupported: boolean
  includePickupUnsupported: boolean
  salesChannel?: SalesChannel
}) =>
  shipperAddressApi.get(
    '/1.0/divisions',
    omitBy(
      {
        'include-delivery-unsupported': includeDeliveryUnsupported,
        'include-pickup-unsupported': includePickupUnsupported,
        'sales-channel': salesChannel,
        ...data
      },
      isNil
    )
  )

const parseAddressDivisions = ({
  input,
  salesChannel,
  language,
  country
}: {
  input: string
  salesChannel?: SalesChannel
  language: string
  country: string
}) => {
  return shipperAddressApi.post('/1.0/divisions/parse', {
    input,
    salesChannel,
    language,
    country
  })
}

export default {
  getDivisions,
  parseAddressDivisions
}
