import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { T } from '@nv/react-commons/src/Components'
import Colors from '@nv/react-commons/src/Themes/Colors'
import { Button, Link } from '@nv/react-akira'
import { Images } from 'images'
import { Text } from 'components/Text'
import { RouteModal } from 'components/RouteModal'
import { ROUTES } from 'containers/Base/constants'
import { selectShipper, selectUser } from 'containers/Base/selectors'
import { EKYC_MODAL_ORDER_CREATION_DISABLED } from './EKYCModal.constants'
import { getHelpdeskLink } from 'utils/website'
import { useIsShipperSupportEnabled } from 'containers/ShipperSupport/ShipperSupport.hooks'
import { getModalPath } from 'utils/getModalPath'

import 'antd/lib/upload/style'
import { getIdentityVerificationGForm } from './EKYCModal.helpers'

export const EKYCOCDisabledModal = () => {
  const location = useLocation()
  const shipper = useSelector(selectShipper())
  const user = useSelector(selectUser())
  const isShipperSupportEnabled = useIsShipperSupportEnabled()
  const locale = useSelector(selectUser())?.locale || 'en'

  const onPressVerifyIdentity = async () => {
    window.open(getIdentityVerificationGForm(locale), '_blank', 'noopener,noreferrer')
  }

  return (
    <StyledModal
      visible
      path={getModalPath(location, EKYC_MODAL_ORDER_CREATION_DISABLED)}
      title={<T id='ekyc_incomplete.order_creation_disabled' />}
    >
      <StyledImg width={100} height={120} src={Images.ryoAnnounce} />
      <StyledDescription
        style={{
          '--mb': '10px'
        }}
      >
        <T id='ekyc_incomplete.verification_process_incomplete' />
      </StyledDescription>
      <StyledDescription
        style={{
          '--fz': '12px'
        }}
      >
        <T id='ekyc_incomplete.if_issue' />{' '}
        {isShipperSupportEnabled ? (
          <Link as={RouterLink} to={ROUTES.HELP_DESK}>
            <T id='ekyc_incomplete.customer_support' />
          </Link>
        ) : (
          <Link
            href={getHelpdeskLink({
              country: shipper?.country?.toLowerCase(),
              language: user?.locale?.split('-')[0],
              shipper
            })}
            target='_blank'
            rel='noreferrer noopener'
          >
            <T id='customer_support' />
          </Link>
        )}
        .
      </StyledDescription>
      <StyledRow>
        <Button
          variant='grey'
          styleType='primary'
          type='submit'
          size='lg'
          loading={false}
          onClick={onPressVerifyIdentity}
        >
          <FormattedMessage id='ekyc_incomplete.verify_identity' />
        </Button>
      </StyledRow>
    </StyledModal>
  )
}

const StyledModal = styled(RouteModal)`
  && {
    .ant-modal-content {
      border-radius: 10px;
      max-width: 420px;
      margin: auto;

      > div {
        background-color: unset;
      }
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
    }

    .ant-modal-title {
      border-radius: 10px;
    }
  }
`

const StyledDescription = styled(Text).attrs({
  color: Colors.balanceTxtBold
})`
  margin-bottom: var(--mb, 30px);
  font-size: var(--fz, 14px);
  text-align: left;
  white-space: pre-line;

  a {
    display: inline;
    font-size: inherit;
  }
`

const StyledImg = styled.img`
  align-self: center;
  margin-bottom: 25px;
`

const StyledRow = styled.div`
  display: flex;
  justify-content: flex-end;
`
