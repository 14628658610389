/**
 *
 * DeliveryTypeFormItem
 *
 */

import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { T, Form, NVSelect } from '@nv/react-commons/src/Components'
import { defaultServicesLevel } from 'containers/DeliveryType/constants'
import { FIELDS } from 'components/OCForm/constants'

const { Option } = NVSelect

class DeliveryTypeFormItem extends React.PureComponent {
  render () {
    const {
      initialValue,
      formItem: FormItem,
      form: { getFieldDecorator },
      ocSettings,
      disabled
    } = this.props
    const availableServiceLevels = ocSettings?.availableServiceLevels
    return (
      <FormItem label={<T id='delivery_type' />}>
        {getFieldDecorator(FIELDS.DELIVERY_TYPE, { initialValue })(
          <NVSelect disabled={disabled || _.size(availableServiceLevels) === 1}>
            {(availableServiceLevels || defaultServicesLevel).map((sl, i) => (
              <Option key={i} value={sl}>
                <T id={`services_level.${sl.toLowerCase()}_title`} />
              </Option>
            ))}
          </NVSelect>
        )}
      </FormItem>
    )
  }
}

DeliveryTypeFormItem.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object,
  formItem: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  initialValue: PropTypes.string,
  ocSettings: PropTypes.object
}

DeliveryTypeFormItem.defaultProps = {
  formItem: Form.Item
}

export { DeliveryTypeFormItem }
