export const DEFAULT_TIMESLOT_ID = '0900_2200'

export const DEFAULT_TIMESLOT_RANGE = [9, 22]

// Ensures hours (HH) are between 00-23 and minutes (MM) are between 00-59 for both start and end times.
export const TIMESLOT_REGEX = /^(0[0-9]|1[0-9]|2[0-3])([0-5][0-9])_(0[0-9]|1[0-9]|2[0-3])([0-5][0-9])$/

// Legacy Delivery Time Slot IDs Mapping
// This mapping is used to bridge the current shipper settings representation of delivery time slots
// to the expected integer values by the core APIs. The APIs (PUT orders/${orderId}/rts and
// POST orders/${orderId}/reschedule) currently expect an integer for the time window property
// in their request payloads. This is a temporary measure to accommodate older API designs.
export const DELIVERY_TIMESLOT_IDS = {
  '0900_1200': 0,
  '1200_1500': 1,
  '1500_1800': 2,
  '1800_2200': 3,
  '0900_1800': -2,
  '0900_2200': -1
} as const

export const ALL_DELIVERY_TIMESLOTS = Object.keys(DELIVERY_TIMESLOT_IDS)
