import React, { useEffect } from 'react'
import styled from 'styled-components'
import { NVInput, Form, T } from '@nv/react-commons/src/Components'

import { Colors, media } from 'themes'
import { Text } from 'components/Text'
import { ValidatorUtils } from '@nv/react-commons/src/Utils'

import { dimensionFields, DIMENSIONS_EXCEED_LIMIT_ERROR } from './constants'
import { getDefaultDimensions, getMaxDimensions } from './OCForm.helpers'
import { useIntl } from 'hooks/common'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { OC_SERVICE_TYPES } from 'containers/Base/constants'

const parseValue = value => parseFloat(value) || 0

export const Dimensions = ({ form: { getFieldDecorator, setFieldsValue }, country, order }) => {
  const intl = useIntl()
  const [exceedsLimitError, setExceedsLimitError] = React.useState(false)
  const isDocType = order?.serviceType === OC_SERVICE_TYPES.DOCUMENT

  useEffect(() => {
    dimensionFields.map((label, index) => setFieldsValue({ [label]: getDefaultDimensions(country)?.[index] }))
  }, [country, setFieldsValue])

  const fieldOptions = label => ({
    rules: [
      { required: true, message: intl.formatMessage({ id: 'x_is_required' }, { x: label }) },
      {
        validator: (rule, value, callback) => {
          const { width, height, length } = order || {}
          const oldValue = parseValue(order?.[rule.field])
          const newValue = parseValue(value)
          const sum = newValue + parseValue(width) + parseValue(height) + parseValue(length) - oldValue

          let error: string | undefined
          if (sum > getMaxDimensions(country)) {
            error = DIMENSIONS_EXCEED_LIMIT_ERROR
            setExceedsLimitError(true)
          } else {
            setExceedsLimitError(false)
          }
          callback(error)
        }
      },
      ValidatorUtils.number(
        intl.formatMessage({ id: 'parcel_dimension_exceeds_limit' }),
        value => country?.toLowerCase() === COUNTRIES.VN && value > 80
      ),
      ValidatorUtils.number(intl.formatMessage({ id: 'x_not_zero' }, { x: label }), value => value === 0),
      ValidatorUtils.number(
        intl.formatMessage({ id: 'invalid_x_value' }, { x: label?.toLowerCase() }),
        value => value < 0
      )
    ]
  })

  return (
    <div>
      <FieldsLabel id='dimensions_cm' />
      <FieldsWrapper>
        {dimensionFields.map((field, index) => (
          <Form.Item {...(exceedsLimitError ? { ...{ help: false } } : {})} key={index}>
            <>
              {getFieldDecorator(
                field,
                fieldOptions(intl.formatMessage({ id: field }))
              )(<NVInput type='number' min={1} disabled={isDocType} />)}
              <Label htmlFor={field}>
                <T id={field} />
              </Label>
            </>
          </Form.Item>
        ))}
      </FieldsWrapper>
    </div>
  )
}

const FieldsLabel = styled(Text)`
  padding-top: 2px;
  font-size: 12px;
  font-weight: bold;
  color: ${Colors.darkGreyBlue};
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;

  ${media.min.lgTablet`
    font-size: 11px;
    font-weight: 400;
  `}
`

const FieldsWrapper = styled.div`
  display: flex;
  gap: 8px;

  .ant-form-item {
    display: flex;
    flex-direction: column;
    margin: 0;
    max-width: 90px;

    ${media.min.lgTablet`
      max-width: 100px;
      width: 100px;
    `}
    ${media.max.tablet`
    max-width: 100px;
    width: 100px;
  `}
  }

  .ant-form-item-control {
    line-height: 25px;
    margin-top: 5px;
  }
`
const Label = styled.label`
  text-align: left;
  color: ${Colors.balanceTxtLight};
  font-size: 12px !important;
  line-height: 12px;
  margin-bottom: 10px ${media.min.lgTablet`
        font-size: 11px !important;
     `};
`
