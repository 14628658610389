import { media } from '@nv/react-commons/src/Themes/Media'
import { Images } from 'images'
import styled, { css } from 'styled-components'

const sharedLogoCss = css`
  margin: 0 16px;
  ${media.max.desktop`margin: 0`};
  ${media.max.tablet`
    transform: none;
    margin: 24px 0 8px;
    width: 103px;
  `};
`

export const NVGoodJobLogo = styled.img.attrs({ src: Images.ryoJumping, width: 140 })`
  transform: translateY(24px);
  ${sharedLogoCss};
`

export const CDGoodJobLogo = styled.img.attrs({ src: Images.thumbsUp, width: 100 })`
  margin: 0 16px;
  ${sharedLogoCss};
`
