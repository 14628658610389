/**
 *
 * MappingSlot
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import _ from 'lodash'
import { DropTarget } from 'react-dnd'
import { T } from '@nv/react-commons/src/Components'
import { StyleUtils } from '@nv/react-commons/src/Utils'

import { Text } from '../Text'
import { MappingItem } from '../MappingItem'
import { Colors } from 'themes'

const slotTarget = {
  drop ({ id, item }) {
    return { id, item }
  }
}

function collect (connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }
}

const ALPHA_HEADER_CAN_DROP = 0.5
const ALPHA_SLOT_FILLED = 1
const ALPHA_HOVER_OVERLAY = 0.06

const StyledMappingItemPlaceholder = styled.div`
  width: ${MappingItem.WIDTH}px;
  height: 40px;
  padding: 8px;
  border: dashed 1px ${Colors.alto};
  border-radius: 3px;
  text-align: center;
  ${props =>
    props.filled &&
    css`
      visibility: hidden;
    `} ${props =>
    props.canDrop &&
    css`
      background-color: ${StyleUtils.rgba(Colors.white, ALPHA_HEADER_CAN_DROP)};
    `};
`
const StyledMappingItem = styled(MappingItem)`
  position: absolute;
  top: 0;
  left: 0;
  ${props =>
    props.canDrop &&
    css`
      opacity: ${ALPHA_SLOT_FILLED};
    `};
`
const StyledMappingSlot = styled.div`
  position: relative;
  margin: 4px 8px;
  padding: 8px 0;
`
const StyledList = styled.div`
  width: ${MappingItem.WIDTH}px;
  margin-top: 8px;
  border-radius: 3px;
  border: solid 1px ${Colors.paleGrey};
  ${props =>
    props.canDrop &&
    props.filled &&
    css`
      opacity: ${ALPHA_SLOT_FILLED};
    `};
`
const StyledListItem = styled.div`
  width: 100%;
  height: 40px;
  padding: 12px;
  background-color: ${props => (props.isHeader ? Colors.whiteThree : Colors.white)};
  border: solid 1px ${Colors.whiteFour};
`
const StyledOverlay = styled.div`
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -8px;
  right: -8px;
  border-radius: 3px;
  background-color: ${StyleUtils.rgba(Colors.black, ALPHA_HOVER_OVERLAY)};
`
const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

class MappingSlot extends React.Component {
  render () {
    const {
      connectDropTarget,
      isOver,
      canDrop,
      data,
      includeHeader,
      item,
      compulsory,
      putItem,
      editing,
      ...rest
    } = this.props
    return (
      <StyledMappingSlot {...rest} ref={slot => connectDropTarget(slot)}>
        <StyledMappingItemPlaceholder canDrop={canDrop} filled={!!item}>
          <Text inline size={13} color={Colors.pinkishGrey}>
            <T id='drag_here' />
          </Text>
        </StyledMappingItemPlaceholder>
        <StyledList canDrop={canDrop} filled={!!item}>
          {_.map(data, (content, id) => (
            <StyledListItem key={id} isHeader={includeHeader && id === 0}>
              <StyledText size={13}>{content}</StyledText>
            </StyledListItem>
          ))}
        </StyledList>
        {item && (
          <StyledMappingItem id={item} compulsory={compulsory} put={putItem} canDrop={canDrop} editing={editing} />
        )}
        {isOver && <StyledOverlay />}
      </StyledMappingSlot>
    )
  }
}

MappingSlot.propTypes = {
  data: PropTypes.array,
  includeHeader: PropTypes.bool,
  compulsory: PropTypes.bool,
  id: PropTypes.number,
  item: PropTypes.string,
  putItem: PropTypes.func,
  editing: PropTypes.bool,
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool.isRequired
}

MappingSlot.defaultProps = {
  item: null
}

const _MappingSlot = DropTarget(MappingItem.HEADER, slotTarget, collect)(MappingSlot)

export { _MappingSlot as MappingSlot }
