/* eslint-disable max-len */
import React, { useMemo, useState } from 'react'
import { upperCase } from 'lodash'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useIntl } from 'hooks/common'
import { NVInput, NVSelect, T, Tooltip } from '@nv/react-commons/src/Components'
import { CountryUtils, ValidatorUtils } from '@nv/react-commons/src/Utils'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { ALLOWED_COUNTRY_STATE_PROVINCE, MAX_TID_LENGTH, MIN_TID_LENGTH } from 'containers/FPLOrderCreate/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fa-pro-solid/faInfoCircle'
import { HintText, InputGroupStyled, LineTooltip, StyledFormItem } from 'containers/FPLOrderRequestList/styles'
import { GST_KEYS, GST_OPTIONS, MAX_LEN_OF_PREFIX } from 'containers/FPLOrderRequestList/constants'
import { DEFAULT_SETTINGS, TRACKING_TYPES, TRACKING_TYPES_SETTINGS } from 'components/TrackingNumberFormItem/constants'
import { selectFPLTrackingType, selectOcSettings } from 'containers/Base/selectors'
import { FPLTrackingNumberInput } from 'components/FPLTrackingNumberInput'
import { useSelector } from 'react-redux'
import { validateMaxLength, validateMinLength } from 'components/FPLTrackingNumberInput/utils'

const Option = NVSelect.Option

const NUMBER_REGEX = /^\d+$/

const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]*$/

const { TextArea } = NVInput
const StyledTextArea = styled(TextArea)`
  && {
    padding: 4px 8px !important;
  }
`

const RedAsterisk = styled.span`
  &&:after {
    content: ' *';
    color: red;
  }
`

export function minNumberValidator (msg, min) {
  return {
    validator: ValidatorUtils.number(msg, value => value < min)
  }
}

const minAndEqualMinNumberValidator = (msg, min) => ({ validator: ValidatorUtils.number(msg, value => value <= min) })

export const RecipientName = ({ form: { getFieldDecorator } }) => {
  const intl = useIntl()
  return (
    <StyledFormItem
      label={
        <span>
          <T id='recipient_name' />
          <RedAsterisk />
        </span>
      }
    >
      {getFieldDecorator('name', {
        rules: [
          {
            required: true,
            message: `${intl.formatMessage({ id: 'international_recipient_name_is_required' })}`
          },
          {
            max: 255,
            message: `${intl.formatMessage({ id: 'international_name_less_than_255_characters' })}`
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}

RecipientName.propTypes = {
  form: PropTypes.object.isRequired
}

export const ContactNumber = ({ form: { getFieldDecorator, getFieldValue } }) => {
  const intl = useIntl()
  const required = !getFieldValue('email')
  return (
    <StyledFormItem
      label={
        <span>
          <T id='contact_number' />
          <RedAsterisk />
        </span>
      }
    >
      {getFieldDecorator('contactNumber', {
        rules: [
          {
            required,
            message: `${intl.formatMessage({ id: 'international_contact_number_is_required' })}`
          },
          {
            max: 32,
            message: `${intl.formatMessage({ id: 'international_conact_number_must_be_valid_format' })}`
          },
          {
            validator: ValidatorUtils.phoneNumber,
            message: `${intl.formatMessage({ id: 'international_conact_number_must_be_valid_format' })}`
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}

ContactNumber.propTypes = {
  form: PropTypes.object.isRequired
}

export const ToEmail = ({ form: { getFieldDecorator } }) => {
  const intl = useIntl()
  return (
    <StyledFormItem label='Contact Email'>
      {getFieldDecorator('email', {
        rules: [
          {
            type: 'email',
            message: intl.formatMessage({ id: 'international_invalid_email_format' })
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}

ToEmail.propTypes = {
  form: PropTypes.object.isRequired
}

export const ToAddress1 = ({ form: { getFieldDecorator } }) => {
  const intl = useIntl()
  return (
    <StyledFormItem
      label={
        <span>
          <T id='international_form_address_one' />
          <RedAsterisk />
        </span>
      }
    >
      {getFieldDecorator('addressLine1', {
        rules: [
          {
            required: true,
            message: `${intl.formatMessage({ id: 'international_address_is_required' })}`
          },
          {
            max: 255,
            message: `${intl.formatMessage({ id: 'international_address_less_than_255_characters' })}`
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}
ToAddress1.propTypes = {
  form: PropTypes.object.isRequired
}

export const City = ({ form: { getFieldDecorator }, country }) => {
  const intl = useIntl()
  const required = country && !(country.toLowerCase() === COUNTRIES.SG || country.toLowerCase() === COUNTRIES.TH)
  const requiredMess = `${intl.formatMessage({ id: 'international_city_is_required_all_except_sg_and_th' })}`
  return (
    <StyledFormItem
      label={
        <span>
          <T id='city' />
          {required && <RedAsterisk />}
        </span>
      }
    >
      {getFieldDecorator('city', {
        rules: [
          {
            required,
            message: requiredMess
          },
          {
            max: 255,
            message: `${intl.formatMessage({ id: 'international_city_less_than_255_characters' })}`
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}
City.propTypes = {
  form: PropTypes.object.isRequired,
  country: PropTypes.string
}

export const StateProvince = ({ form: { getFieldDecorator }, country }) => {
  const required = country && ALLOWED_COUNTRY_STATE_PROVINCE[upperCase(country)]
  const intl = useIntl()
  const requiredMess = intl.formatMessage({ id: 'international_required_all_except_SG_and_VN' })

  const stateLocalize = intl.formatMessage({ id: 'state' })
  const provinceLocalize = intl.formatMessage({ id: 'province' })
  const countryRequiredMess = intl.formatMessage(
    {
      id:
        country === COUNTRIES.MY
          ? 'international_state_is_required_for_country'
          : 'international_province_is_required_for_country'
    },
    { country }
  )

  return (
    <StyledFormItem
      label={
        <span>
          <span>
            {stateLocalize}/{provinceLocalize}
            {required && <RedAsterisk />}
          </span>
        </span>
      }
    >
      {getFieldDecorator('stateProvince', {
        rules: [
          {
            required,
            message: `${requiredMess}\n${countryRequiredMess}`
          },
          {
            max: 255,
            message: `${intl.formatMessage({ id: 'international_state_less_than_255_characters' })}`
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}
StateProvince.propTypes = {
  form: PropTypes.object.isRequired,
  country: PropTypes.string
}

export const PostCode = ({ form: { getFieldDecorator }, country }) => {
  const postcodeInfo = CountryUtils.getPostcodeInfo(country)
  const intl = useIntl()
  const isValidNum = intl.formatMessage({ id: 'invalid_number' })
  const requiredMess = intl.formatMessage({ id: 'international_postcode_is_required_only_for_country' })
  const postCodeRestriction = `${intl.formatMessage({ id: 'the_postcode_must_be_the_valid_postcode_format' })}`

  const isRequiredForSpecificCountry =
    country?.toLowerCase() === COUNTRIES.SG ||
    country?.toLowerCase() === COUNTRIES.MY ||
    country?.toLowerCase() === COUNTRIES.TH
  const rules = [minNumberValidator(`${isValidNum}`, 0)]
  if (isRequiredForSpecificCountry) {
    rules.push({
      required: true,
      message: `${requiredMess}`
    })
  }
  if (postcodeInfo.length > 0) {
    rules.push({
      len: postcodeInfo.length,
      message: postCodeRestriction
    })
  }

  return (
    <StyledFormItem
      label={
        <span>
          <T id='postcode' />
          {isRequiredForSpecificCountry && <RedAsterisk />}
        </span>
      }
    >
      {getFieldDecorator('postCode', { rules })(<NVInput />)}
    </StyledFormItem>
  )
}
PostCode.propTypes = {
  form: PropTypes.object.isRequired,
  country: PropTypes.string
}

export const CashOnDelivery = ({ form: { getFieldDecorator }, country }) => {
  const intl = useIntl()

  return (
    <StyledFormItem label={<T id='cod_x' values={{ x: CountryUtils.getCurrencySymbol(country) }} />}>
      {getFieldDecorator('cashOnDelivery', {
        rules: [minNumberValidator(`${intl.formatMessage({ id: 'the_number_must_be_a_number_greate_than_0' })}`, 0)]
      })(<NVInput />)}
    </StyledFormItem>
  )
}
CashOnDelivery.propTypes = {
  form: PropTypes.object.isRequired,
  country: PropTypes.string
}

export const RequestedTrackingID = ({ form: { getFieldDecorator }, ...rest }) => {
  const intl = useIntl()
  const ocSettings = useSelector(selectOcSettings())
  const isPrefixRequired = useMemo(() => ocSettings?.isCorporateBranch && ocSettings?.prefix, [ocSettings])
  const trackingType = useSelector(selectFPLTrackingType())
  const trackingSettings = TRACKING_TYPES_SETTINGS[trackingType] || DEFAULT_SETTINGS

  const [selectedPrefix, setSelectedPrefix] = useState(ocSettings?.prefix)

  const validTracking = intl.formatMessage(
    { id: 'international_new_requested_tid_invalid_message_new' },
    {
      min: MIN_TID_LENGTH,
      max: MAX_TID_LENGTH
    }
  )
  const trackingId = intl.formatMessage({ id: 'international_requested_tracking_id' })

  return (
    <StyledFormItem
      label={
        isPrefixRequired ? (
          <span>
            <T id='international_requested_tracking_id' />
            <RedAsterisk />
          </span>
        ) : (
          trackingId
        )
      }
    >
      {getFieldDecorator('requestedTrackingID', {
        rules: isPrefixRequired
          ? [
              {
                required: true,
                message: intl.formatMessage({ id: 'requested_tracking_id_required' })
              },
              {
                validator: validateMinLength(trackingSettings, intl)
              },
              {
                validator: validateMaxLength(trackingSettings, intl, false, false)
              }
            ]
          : [
              {
                min: MIN_TID_LENGTH,
                message: `${validTracking}`
              },
              {
                max: MAX_TID_LENGTH,
                message: `${validTracking}`
              }
            ]
      })(
        isPrefixRequired ? (
          <FPLTrackingNumberInput
            placeholder={isPrefixRequired ? '' : intl.formatMessage({ id: 'optional' })}
            {...rest}
            id='requestedTrackingNumber'
            prefix={selectedPrefix}
            setSelectedPrefix={setSelectedPrefix}
            prefixes={ocSettings?.prefixes || []}
          />
        ) : (
          <NVInput />
        )
      )}
    </StyledFormItem>
  )
}
RequestedTrackingID.propTypes = {
  form: PropTypes.object.isRequired
}

export const OriginalTrackingID = ({ form: { getFieldDecorator } }) => {
  const intl = useIntl()
  const originalTrackingId = intl.formatMessage({ id: 'original_tracking_id' })

  return (
    <StyledFormItem label={originalTrackingId}>
      {getFieldDecorator('sourceReferenceId', {
        rules: []
      })(<NVInput disabled={true} />)}
    </StyledFormItem>
  )
}
OriginalTrackingID.propTypes = {
  form: PropTypes.object.isRequired
}

export const RelabelRequestedTrackingID = ({ form: { getFieldDecorator }, validationRulesByTrackingType }) => {
  if (!validationRulesByTrackingType) {
    validationRulesByTrackingType = TRACKING_TYPES_SETTINGS[TRACKING_TYPES.DYNAMIC]
  }
  const { min, max, required } = validationRulesByTrackingType
  const minLength = min
  const maxLength = max + MAX_LEN_OF_PREFIX
  const intl = useIntl()
  const invalidTrackingIdMsg = intl.formatMessage(
    { id: 'new_requested_tracking_id_invalid_new' },
    { min: minLength, max: maxLength }
  )
  const newRequestedTrackingIdLabel = intl.formatMessage({ id: 'new_requested_tracking' })
  const requiredMess = intl.formatMessage({ id: 'new_requested_tracking_id_required' })

  return (
    <StyledFormItem label={newRequestedTrackingIdLabel}>
      {getFieldDecorator('requestedTrackingID', {
        rules: [
          {
            required,
            message: `${requiredMess}`
          },
          {
            min: minLength,
            message: `${invalidTrackingIdMsg}`
          },
          {
            max: maxLength,
            message: `${invalidTrackingIdMsg}`
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}
RelabelRequestedTrackingID.propTypes = {
  form: PropTypes.object.isRequired
}

export const SourceOrderID = ({ form: { getFieldDecorator } }) => {
  const intl = useIntl()
  const validTracking = intl.formatMessage({ id: 'international_reference_tracking_id_less_than_255_characters' })
  const trackingId = intl.formatMessage({ id: 'international_reference_tracking_id' })
  return (
    <StyledFormItem label={trackingId}>
      {getFieldDecorator('sourceOrderID', {
        rules: [
          {
            max: 255,
            message: `${validTracking}`
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}
SourceOrderID.propTypes = {
  form: PropTypes.object.isRequired
}

export const TaxNumber = ({ form: { getFieldDecorator }, country }) => {
  const required = country && (country.toLowerCase() === COUNTRIES.ID || country.toLowerCase() === COUNTRIES.VN)
  const intl = useIntl()

  return (
    <StyledFormItem
      label={
        <span>
          <T id='international_consignee_s_tax_number' />
          <RedAsterisk />
          <InfoTooltip tooltipTextId='international_tooltip_consignee_number' />
        </span>
      }
    >
      {getFieldDecorator('taxNumber', {
        rules: [
          {
            required,
            message: `${intl.formatMessage({ id: 'international_consignee_tax_number_is_required' })}`
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}
TaxNumber.propTypes = {
  form: PropTypes.object.isRequired,
  country: PropTypes.string
}

export const CustomsDescription = ({ form: { getFieldDecorator } }) => {
  const intl = useIntl()
  const requiredMess = intl.formatMessage({ id: 'international_goods_description_is_required' })
  const validDesc = intl.formatMessage({ id: 'the_description_must_be_maximum_1000_characters' })

  return (
    <StyledFormItem
      label={
        <span>
          <T id='international_goods_description' />
          <RedAsterisk />
          <InfoTooltip tooltipTextId='international_tooltip_customs_description' />
        </span>
      }
    >
      {getFieldDecorator('customsDescription', {
        rules: [
          {
            required: true,
            message: `${requiredMess}`
          },
          {
            max: 1000,
            message: `${validDesc}`
          }
        ]
      })(<StyledTextArea rows={1} />)}
    </StyledFormItem>
  )
}
CustomsDescription.propTypes = {
  form: PropTypes.object.isRequired
}

export const GoodsValue = ({ form: { getFieldDecorator }, country }) => {
  const intl = useIntl()
  const requiredMess = intl.formatMessage({ id: 'international_goods_value_is_required' })
  return (
    <StyledFormItem
      label={
        <span>
          <T id='items.unit_value' />
          <RedAsterisk />
          <InfoTooltip tooltipTextId='international_tooltip_goods_value' />
        </span>
      }
    >
      {getFieldDecorator('goodsValue', {
        rules: [
          {
            required: true,
            message: `${requiredMess}`
          },
          minNumberValidator(`${intl.formatMessage({ id: 'the_value_must_be_number_greate_than_1' })}`, 1)
        ]
      })(<NVInput />)}
      {country === COUNTRIES.SG.toUpperCase() && (
        <HintText>
          <T id='international_goods_value_description' />
        </HintText>
      )}
    </StyledFormItem>
  )
}
GoodsValue.propTypes = {
  form: PropTypes.object.isRequired,
  country: PropTypes.string
}

export const Quantity = ({ form: { getFieldDecorator } }) => {
  const intl = useIntl()
  const quantityValue = intl.formatMessage({ id: 'quantity' })
  const validQuantity = intl.formatMessage({ id: 'the_value_must_be_number_greate_than_1' })

  return (
    <StyledFormItem label={`${quantityValue}`}>
      {getFieldDecorator('quantityValue', {
        rules: [
          {
            pattern: NUMBER_REGEX,
            message: validQuantity
          }
        ],
        initialValue: '1'
      })(<NVInput />)}
    </StyledFormItem>
  )
}
Quantity.propTypes = {
  form: PropTypes.object.isRequired
}

export const DeliveryInstructions = ({ form: { getFieldDecorator } }) => {
  const intl = useIntl()
  const validInstructions = intl.formatMessage({ id: 'the_delivery_instructions_must_be_maximum_1000_characters' })
  const deliveryInstructions = intl.formatMessage({ id: 'delivery_instructions' })

  return (
    <StyledFormItem label={deliveryInstructions}>
      {getFieldDecorator('deliveryInstructions', {
        rules: [
          {
            max: 1000,
            message: validInstructions
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}

DeliveryInstructions.propTypes = {
  form: PropTypes.object.isRequired
}

export const InsuredValue = ({ form: { getFieldDecorator }, country }) => {
  const intl = useIntl()
  const insuredMessage = intl.formatMessage({ id: 'the_value_must_be_number_greate_than_0' })
  return (
    <StyledFormItem label={<T id='insured_value' values={{ x: CountryUtils.getCurrencySymbol(country) }} />}>
      {getFieldDecorator('insuredValue', {
        rules: [minAndEqualMinNumberValidator(insuredMessage, 0)]
      })(<NVInput />)}
    </StyledFormItem>
  )
}

InsuredValue.propTypes = {
  form: PropTypes.object.isRequired,
  country: PropTypes.string
}

export const ParcelWeight = ({ form: { getFieldDecorator }, isReturnOrder }) => {
  const intl = useIntl()
  const weightKg = intl.formatMessage({ id: 'parcel_weight_kg' })
  const weightMessage = intl.formatMessage({ id: 'the_value_must_be_number_greate_than_0' })

  return (
    <StyledFormItem label={weightKg}>
      {getFieldDecorator('weight', {
        rules: [minAndEqualMinNumberValidator(weightMessage, 0)]
      })(<NVInput disabled={isReturnOrder} />)}
    </StyledFormItem>
  )
}
ParcelWeight.propTypes = {
  form: PropTypes.object.isRequired,
  isReturnOrder: PropTypes.bool
}

export const GSTItem = ({ form }) => {
  const intl = useIntl()
  const invalidMessage = intl.formatMessage({ id: 'international_item_gst_number_is_alphabet' })
  const { getFieldDecorator, setFieldsValue } = form
  const [gstIncluded, setGstIncluded] = React.useState(GST_KEYS.NO)
  const DEFAULT_RULES = [
    {
      max: 255,
      message: invalidMessage
    },
    {
      pattern: ALPHANUMERIC_REGEX,
      message: invalidMessage
    }
  ]
  const [rules, setRules] = React.useState(DEFAULT_RULES)

  React.useEffect(() => {
    if (gstIncluded === GST_KEYS.YES) {
      setRules([
        {
          required: true,
          message: `${intl.formatMessage({ id: 'international_item_gst_number_is_required' })}`
        },
        ...DEFAULT_RULES
      ])
    } else {
      setRules(DEFAULT_RULES)
    }
    setFieldsValue({ gstIncluded })
  }, [gstIncluded])

  return (
    <>
      <StyledFormItem
        label={
          <span>
            <T id='gst_number' />
            {gstIncluded === GST_KEYS.YES && <RedAsterisk />}
            <InfoTooltip mesages={['gst_warning_message', 'gst_not_included_message', 'gst_included_message']} />
          </span>
        }
      >
        <InputGroupStyled compact>
          <NVSelect onChange={value => setGstIncluded(value)} value={gstIncluded}>
            {Object.values(GST_OPTIONS).map((item, i) => (
              <Option key={i} value={item.key}>
                <T id={item.label} />
              </Option>
            ))}
          </NVSelect>
          {getFieldDecorator('gstNumber', {
            rules
          })(<NVInput />)}
        </InputGroupStyled>
      </StyledFormItem>
      <StyledFormItem hidden>
        {getFieldDecorator('gstIncluded', {
          rules: []
        })(
          <NVSelect>
            {Object.values(GST_OPTIONS).map((item, i) => (
              <Option key={i} value={item.key}>
                <T id={item.label} />
              </Option>
            ))}
          </NVSelect>
        )}
      </StyledFormItem>
    </>
  )
}

GSTItem.propTypes = {
  form: PropTypes.object.isRequired
}

export const LVGItem = ({ form: { getFieldDecorator } }) => {
  const intl = useIntl()
  const lvgRegistration = intl.formatMessage({ id: 'lvg_registration_number' })
  const invalidMessage = intl.formatMessage({ id: 'international_item_lvg_number_is_alphabet' })

  return (
    <StyledFormItem label={lvgRegistration}>
      {getFieldDecorator('lvgNumber', {
        rules: [
          {
            max: 255,
            message: invalidMessage
          },
          {
            pattern: ALPHANUMERIC_REGEX,
            message: invalidMessage
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}

LVGItem.propTypes = {
  form: PropTypes.object.isRequired
}

export const InfoTooltip = ({ tooltipTextId, mesages }) => {
  const getTitle = () => {
    if (mesages?.length) {
      return mesages.map((message, index) => (
        <LineTooltip key={index}>
          <T id={message} />
        </LineTooltip>
      ))
    }
    return <T id={tooltipTextId} values={{ br: <br /> }} />
  }
  return (
    <Tooltip title={getTitle()} placement='top'>
      <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 10 }} />
    </Tooltip>
  )
}
InfoTooltip.propTypes = {
  tooltipTextId: PropTypes.string,
  mesages: PropTypes.array
}
