import { Radio } from '@nv/react-commons/src/Components'
import styled from 'styled-components'
import { Colors } from 'themes'

const RadioGroup = Radio.Group

export const StyleRadioGroup = styled(RadioGroup).attrs({})`
  && {
    display: inline-block;
    margin-top: 5px;
    width: 100%;
  }
`
export const StyledRadio = styled(Radio)`
  && {
    display: flex;
    gap: 5px;
    margin-bottom: 2px;

    span.ant-radio {
      max-height: 16px;
    }

    span:nth-of-type(2) {
      display: inline-block;
      width: 100%;
    }
  }
`
export const OptionWrapper = styled.div`
  margin-top: -2px;
  margin-bottom: 5px;
`
export const OptionTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: normal;
  width: 100%;
  word-break: break-word;
`
export const OptionDescription = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: normal;
  width: 100%;
  font-size: 12px;
  color: ${Colors.pinkishGrey};
  word-break: break-word;
`
