/**
 *
 * MappingTemplateModal
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { Radio, T } from '@nv/react-commons/src/Components'
import { SelectorUtils } from '@nv/react-commons/src/Utils'
import { ApiHelper } from '@nv/react-commons/src/Services'
import { RadioGroup } from 'components/RadioGroup'
import { RouteModal } from 'components/RouteModal'
import { Text } from 'components/Text'
import { OC_SERVICE_TYPES, ROUTES } from '../Base/constants'
import dashApi from 'services/api/dashApi'
import { Colors } from 'themes/index'
import { withNavigate, withLocation } from '../RouterHOCs'
import { InternationalShippingLink } from 'containers/FPLOrderCreate/InternationalShippingLink'

const { selector } = SelectorUtils

const StyledRadio = styled(Radio)`
  && {
    display: block;
    .ant-radio + span {
      padding: 0 16px;
    }
  }
`
const StyledText = styled(Text)`
  margin-left: 8px;
`

export class MappingTemplateModal extends React.Component {
  static PATH = 'change-template'

  handleChange = e => {
    const { navigate, location, mappings, getMappingRequest } = this.props
    const id = mappings[e.target.value].id
    getMappingRequest(id)
    navigate(`${ROUTES.MAPPING}/${id}${this.props.returnPath}`, { state: location.state })
  }

  render () {
    return (
      <RouteModal type='info' path={MappingTemplateModal.PATH} title={<T id='change_mapping_template' />}>
        <Text type='bold'>
          <T id='choose_mapping_template' />
        </Text>
        <RadioGroup onChange={this.handleChange}>
          {_.map(this.props.mappings, ({ name, type }, key) => (
            <>
              <StyledRadio key={key} value={key} disabled={type === OC_SERVICE_TYPES.INTERNATIONAL}>
                <Text inline size={13}>
                  {name}
                </Text>
                <StyledText inline size={13} type='italic' color={Colors.pinkishGrey}>
                  (<T id={_.snakeCase(type || '')} prefix='types.mapping' />)
                </StyledText>
              </StyledRadio>
              {type === OC_SERVICE_TYPES.INTERNATIONAL && (
                <InternationalShippingLink user={this.props.user} left={32} />
              )}
            </>
          ))}
        </RadioGroup>
      </RouteModal>
    )
  }
}

MappingTemplateModal.propTypes = {
  getMappingRequest: PropTypes.func.isRequired,
  mappings: PropTypes.array,
  navigate: PropTypes.func,
  location: PropTypes.object,
  user: PropTypes.object.isRequired,
  returnPath: PropTypes.string
}

const mapStateToProps = createStructuredSelector({
  mappings: selector('entity', 'mappings', 'data')(),
  user: selector('global', 'user', 'data')()
})

function mapDispatchToProps (dispatch) {
  return {
    getMappingRequest: id => dispatch(ApiHelper.creators.request('mapping', dashApi.getMapping, [id]))
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withNavigate, withLocation, withConnect)(MappingTemplateModal)
