import { persistStore } from 'redux-persist-immutable'
import reduxPersistConfig from 'configs/reduxPersistConfig'
import { getUrlParameter } from 'utils/url'

export const updateReducers = store => {
  const { version, storeConfig } = reduxPersistConfig
  const { localStorage } = window

  const localVersion = localStorage.getItem('version')
  const token = getUrlParameter('token')
  const shipperId = getUrlParameter('shipper_id')

  if (token && shipperId) {
    // Purge store if a new user logged in
    persistStore(store, storeConfig).purge()
  } else if (localVersion !== version) {
    // Purge store if there is a new version
    persistStore(store, storeConfig).purge()
    localStorage.setItem('version', version)
  } else {
    persistStore(store, storeConfig)
  }
}
