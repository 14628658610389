import PropTypes from 'prop-types'

export const PICKUP_TYPES = {
  SCHEDULED: 'SCHEDULED',
  ON_DEMAND: 'ON_DEMAND',
  DROP_OFF: 'DROP_OFF',
  NO: 'NO'
}

export const SERVER_PICKUP_TYPES = {
  ON_DEMAND: 'On-Demand'
}

export const pickupTypeShape = PropTypes.oneOf(Object.values(PICKUP_TYPES))
