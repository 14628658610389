import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useField, getIn } from 'formik'
import { OptionItem, Select } from '@nv/react-akira'
import { SelectFieldProps } from './SelectField.interface'
import { mapOptionsArrayToSelectItem } from 'utils/selectMappers'
import { FieldWrapper } from '../FieldWrapper'

export const SelectField = ({ name, options, ...props }: SelectFieldProps) => {
  const [field, meta, { setValue }] = useField<OptionItem>(name)
  return (
    <FieldWrapper>
      <Select
        {...field}
        {...props}
        // meta.error for the Select field can be an object or a string
        errorMessage={meta.touched && meta.error && <FormattedMessage id={getIn(meta.error, 'value', meta.error)} />}
        onChange={setValue}
      >
        {mapOptionsArrayToSelectItem(options)}
      </Select>
    </FieldWrapper>
  )
}
