export const serviceLevelToSlaDays = {
  NEXTDAY: 1,
  STANDARD: 3,
  SAMEDAY: 0,
  EXPRESS: 2
}
export const SUPPORTED_SERVICE_LEVELS = {
  STANDARD: 'STANDARD',
  EXPRESS: 'EXPRESS',
  NEXTDAY: 'NEXTDAY',
  SAMEDAY: 'SAMEDAY'
} as const

export const defaultServicesLevel = []
export const defaultServicesType = []
