import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Row, T, Tooltip } from '@nv/react-commons/src/Components'
import { StyledCustomCard } from 'containers/FPLOrderCreate/styles'
import { StyleConfirmdButton } from './styles'
import { useIntl } from 'hooks/common'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { buildB2BBoxRequests, buildB2CBoxRequests } from './utils'
import { batchB2COrders, batchOC } from 'services/api/fplApi'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'containers/Base/constants'
import { isEmpty } from 'lodash'
import { PICKUP_TYPES } from 'containers/PickupType/constants'
import { CCType, OCMethod, SUPPORTED_DOCUMENT_STATUS } from './constants'
import { message } from 'antd'
import { mpTrackConfirmTheOrder } from 'containers/FPLMixpanel/helpers'
import { MP_ORDER_TYPE, PAGE_NAMES } from 'containers/FPLMixpanel/constants'
import { CREATION_METHODS } from 'containers/FPLOrderCreate/constants'
import { calculateResponseTime } from 'containers/FPLOrderCreate/utils'

const initialState = {
  batchError: false,
  batchMessage: '',
  isLoading: false
}

const ReviewStep = () => {
  const intl = useIntl()
  const [state, setState] = useState(initialState)
  const { isLoading } = state
  const { ocState } = useContext(MMCCOrderCreationContext)

  const {
    boxOrders,
    senderAddress,
    pickupType,
    pickupAddress,
    recipientAddress,
    selectedService,
    timeSlot,
    boxOrdersFromKeyboard,
    commercialFiles,
    deliveryFiles,
    commercialStatus,
    deliveryStatus,
    selectedCCType,
    allOrders,
    invalidOrders
  } = ocState

  const navigate = useNavigate()
  const { startCounting, getTime } = calculateResponseTime()

  const isRequiredFieldsFilled = useMemo(() => {
    const isAddressFilled = !isEmpty(senderAddress)
    const isServiceFilled = !isEmpty(selectedService)
    const isBagsFilled = boxOrders.length > 0
    const isPickupInfoFilled = pickupType !== PICKUP_TYPES.SCHEDULED || (!isEmpty(timeSlot) && !isEmpty(pickupAddress))
    const isSupportingDocsFilled =
      commercialStatus !== SUPPORTED_DOCUMENT_STATUS.UPLOADED_FAIL &&
      deliveryStatus !== SUPPORTED_DOCUMENT_STATUS.UPLOADED_FAIL

    return isAddressFilled && isServiceFilled && isPickupInfoFilled && isBagsFilled && isSupportingDocsFilled
  }, [
    senderAddress,
    recipientAddress,
    selectedService,
    pickupType,
    boxOrdersFromKeyboard.length,
    boxOrders.length,
    timeSlot,
    pickupAddress,
    commercialStatus,
    deliveryStatus
  ])

  const handleResponse = useCallback(
    (res, orderType) => {
      const responseTime = getTime()
      if (res.ok) {
        const batchId = res?.data?.data?.batch_id
        navigate(`${ROUTES.FPL_MMCC_ORDER_BATCH}?id=${batchId}`, {
          state: {
            responseTime,
            orderType
          }
        })
      } else {
        let msg = res.data?.error?.message
        if (res.data?.error?.validation_errors) {
          const errorMessages = Object.values(res.data.error.validation_errors).join(', ')
          msg = `${msg}: ${errorMessages}`
        }
        setState(prevState => ({ ...prevState, batchError: true, batchMessage: msg, isLoading: false }))
        message.error(msg, 10)
      }
    },
    [navigate]
  )

  const trackConfirmOrder = (
    totalCreated: number,
    totalNeededActions: number,
    totalOrders: number,
    orderType: string
  ) => {
    const mpData = {
      totalCreated,
      totalNeededActions,
      totalOrders,
      inputMethod: CREATION_METHODS[OCMethod.UPLOAD],
      pageName: PAGE_NAMES[ROUTES.FPL_MMCC_ORDER_CREATION],
      orderType: orderType
    }
    mpTrackConfirmTheOrder(mpData)
  }

  const handleClickConfirm = useCallback(async () => {
    setState(prevState => ({ ...prevState, isLoading: true }))
    startCounting()
    if (selectedCCType === CCType.B2B) {
      const totalCreated = boxOrders.length
      const totalNeededActions = Object.keys(allOrders).length - boxOrders.length
      const totalOrders = Object.keys(allOrders).length
      trackConfirmOrder(totalCreated, totalNeededActions, totalOrders, MP_ORDER_TYPE.MMCC_B2B)
      const payload = buildB2BBoxRequests({
        boxOrders: boxOrders,
        senderAddress,
        pickupType,
        pickupAddress,
        timeSlot,
        ocMethod: OCMethod.UPLOAD,
        selectedService,
        commercialFiles,
        deliveryFiles
      })
      const res = await batchOC(payload)
      handleResponse(res, MP_ORDER_TYPE.MMCC_B2B)
    } else {
      trackConfirmOrder(
        boxOrders.length,
        Object.keys(invalidOrders).length,
        Object.keys(invalidOrders).length + boxOrders.length,
        MP_ORDER_TYPE.MMCC_B2C
      )
      const payload = buildB2CBoxRequests({
        boxOrders,
        senderAddress,
        pickupType,
        pickupAddress,
        timeSlot,
        ocMethod: OCMethod.UPLOAD,
        selectedService,
        commercialFiles,
        deliveryFiles
      })
      const res = await batchB2COrders(payload)
      handleResponse(res, MP_ORDER_TYPE.MMCC_B2C)
    }
  }, [
    boxOrdersFromKeyboard,
    senderAddress,
    recipientAddress,
    pickupType,
    pickupAddress,
    timeSlot,
    selectedService,
    commercialFiles,
    deliveryFiles,
    handleResponse,
    boxOrders,
    allOrders,
    invalidOrders,
    selectedCCType
  ])

  const renderConfirmButton = useCallback(() => {
    if (!isRequiredFieldsFilled) {
      return (
        <Tooltip
          title={intl.formatMessage({
            id: 'international_check_and_fill_required_fields'
          })}
        >
          <StyleConfirmdButton
            disabled
            type='primary'
            onClick={handleClickConfirm}
            loading={isLoading}
            data-analyticsid='create4PLOrders'
          >
            <T id='confirm' />
          </StyleConfirmdButton>
        </Tooltip>
      )
    }

    return (
      <StyleConfirmdButton
        type='primary'
        onClick={handleClickConfirm}
        loading={isLoading}
        data-analyticsid='createMMCCOrders'
      >
        <T id='confirm' />
      </StyleConfirmdButton>
    )
  }, [handleClickConfirm, intl, isRequiredFieldsFilled, isLoading])

  return (
    <>
      <StyledCustomCard hoverable={false}>
        <Row type='flex' justify='space-between' align='middle'>
          <T id='confirm_order' />
          {renderConfirmButton()}
        </Row>
      </StyledCustomCard>
    </>
  )
}

export { ReviewStep }
