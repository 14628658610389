/**
 *
 * DashTable
 *
 * Supply getNext function and fetchingMore bool as props to enable infinite scroll.
 * Set singleSelect to true to only select one at a time. Logic must be handled by parent component.
 * Selected row styling can be handled by overriding the rowStyle callback prop.
 *
 */

import { Row, Spin } from 'antd'
import _ from 'lodash'
import React from 'react'
import { AutoSizer, Column, SortDirection, Table } from 'react-virtualized'
import styled from 'styled-components'
/* http://appddeevvmeanderings.blogspot.com/2017/04/webpack-css-loader-style-loader-default.html */
/* eslint-disable import/no-webpack-loader-syntax */
import '!style-loader!css-loader!react-virtualized/styles.css'
import { faSortDown } from '@fa-pro-solid/faSortDown'
import { faSortUp } from '@fa-pro-solid/faSortUp'
import { Checkbox, Icon, NVCard } from '@nv/react-commons/src/Components'
import { FetchingMoreIndicator } from 'components/FetchingMoreIndicator'
import { Text } from 'components/Text'
import { Colors } from 'themes'
import PropTypes from 'prop-types'

const headerClassName = 'header'
const rowClassName = 'row'
const StyledTable = styled(Table).attrs({
  headerClassName,
  rowClassName
})`
  :focus {
    outline: none;
  }
  .${rowClassName} {
    background: white;
    border-bottom: solid 1px ${Colors.solitude};
    &[style] {
      padding: 0 16px !important;
    }
    &:hover {
      background: ${Colors.aliceBlue};
    }
    &:focus {
      outline: none;
    }
    &.ReactVirtualized__Table__row {
      ${props => props.onRowClick && 'cursor: pointer;'};
      &.disabled {
        cursor: not-allowed;
      }
    }
    &.disabled {
      background: ${Colors.lightPeriwinkle};
      & * {
        color: ${Colors.pinkishGrey};
      }
    }
  }
  .${headerClassName} {
    text-transform: none;
    display: flex;
    align-items: center;
  }
  .ReactVirtualized__Table__headerTruncatedText {
    display: block;
  }
`

const StyledSpin = styled(Spin)`
  padding-right: 10px;
`
const StyledCard = styled(NVCard)`
  && {
    border: none;
  }
`
const sortIconOffset = 10
const StyledIconUp = styled(Icon)`
  position: relative;
  bottom: ${sortIconOffset}px;
  margin-right: 0px;
  margin-left: 10px;
  cursor: pointer;
  color: ${props => (props.active ? Colors.nvPriRed : Colors.pinkishGrey)};
  &:hover,
  &:focus {
    color: ${Colors.grey};
  }
`
StyledIconUp.displayName = 'StyledIconUp'

const StyledIconDown = styled(StyledIconUp)`
  margin-left: -8.75px;
  bottom: 0px;
  top: ${sortIconOffset}px;
`
StyledIconDown.displayName = 'StyledIconDown'

export const SELECTED_INITIAL_STATE = { selectedIndices: {} }
const SORTING_STATE = { sortBy: null, sortDirection: SortDirection.ASC }
const INITIAL_STATE = {
  ...SELECTED_INITIAL_STATE,
  ...SORTING_STATE
}
class DashTable extends React.Component {
  state = { ...INITIAL_STATE, sortedData: this.props.dataSource }

  componentDidMount () {
    const { sortByState, sortDirection: sortDirectionState } = this.state
    const { sortBy = sortByState, sortDirection = sortDirectionState } = this.props
    this.onSort({ sortBy, sortDirection })
  }

  componentDidUpdate = prevProps => {
    const { dataSource } = this.props
    if (prevProps.dataSource !== dataSource) {
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({ sortedData: this.sortData(dataSource), ...SELECTED_INITIAL_STATE })
    }
  }

  handleScroll = ({ clientHeight, scrollHeight, scrollTop }) => {
    const { getNext } = this.props
    const maxScroll = scrollHeight - clientHeight
    if (getNext && Math.ceil(scrollTop) >= maxScroll) {
      getNext()
    }
  }

  resetSelected = () => {
    this.setState(SELECTED_INITIAL_STATE)
  }

  onRowSelect = result => {
    const { dataSource: data, rowSelection } = this.props
    const { selectedIndices } = this.state
    const newSelected = { ...selectedIndices, ...result }
    this.setState({ selectedIndices: newSelected })
    const selectedKeys = _.reduce(newSelected, (r, v, k) => (v ? [...r, k] : r), [])
    const selectedData = _.map(selectedKeys, k => data[k])
    _.isFunction(rowSelection.onChange) && rowSelection.onChange(selectedKeys, selectedData)
  }

  headerRenderer = ({ dataKey, disableSort, label, sortBy, sortDirection }) => {
    const activeUp = dataKey === sortBy && sortDirection === SortDirection.ASC ? 1 : undefined
    const activeDown = dataKey === sortBy && sortDirection === SortDirection.DESC ? 1 : undefined
    return (
      <>
        {label}
        {!disableSort && (
          <>
            <StyledIconUp
              active={activeUp}
              icon={faSortUp}
              onClick={e => {
                e.stopPropagation()
                this.onSort({ sortBy: dataKey, sortDirection: SortDirection.ASC })
              }}
              transform={`down-${sortIconOffset}`}
            />
            <StyledIconDown
              active={activeDown}
              icon={faSortDown}
              onClick={e => {
                e.stopPropagation()
                this.onSort({ sortBy: dataKey, sortDirection: SortDirection.DESC })
              }}
              transform={`up-${sortIconOffset}`}
            />
          </>
        )}
      </>
    )
  }

  onSort = ({ sortBy, sortDirection }) => {
    const { sortBy: currentSortBy, sortDirection: currentSortDirection, sortedData: data } = this.state
    if (sortBy === currentSortBy && sortDirection === currentSortDirection) {
      sortBy = null
    }
    const sortedData = this.sortData(data, sortBy, sortDirection)
    this.setState({ sortBy, sortDirection, sortedData })
  }

  sortData = (data, sortBy = this.state.sortBy, sortDirection = this.state.sortDirection) => {
    let sortedData = data
    if (sortBy) {
      const { columns } = this.props
      const col = _.find(columns, c => c.dataIndex === sortBy)
      const sorter = col.sorter
      sortedData = data.sort((a, b) => sorter(a, b, col))
      if (sortDirection === SortDirection.DESC) {
        sortedData.reverse()
      }
    }
    return sortedData
  }

  areAllSelected = () => {
    const { selectedIndices } = this.state
    const { dataSource } = this.props
    const activeCount = dataSource.filter(d => !d.disabled).length
    const checkedCount = _.values(selectedIndices).filter(c => c).length
    return checkedCount === activeCount
  }

  handleSelectAll = () => {
    let op = 'select'
    if (this.areAllSelected()) {
      op = 'deselect'
    }

    const { dataSource } = this.props
    const selected = {}
    for (let i = 0; i < dataSource.length; i++) {
      if (!dataSource[i].disabled) {
        if (op === 'select') {
          selected[i] = true
        } else {
          selected[i] = false
        }
      }
    }
    this.onRowSelect(selected)
    this.setState({ selectedIndices: selected })
  }

  renderCheckboxColumn = ({ onRowSelect, rowSelected }) => {
    const { dataSource, allowSelectAll } = this.props
    return (
      <Column
        cellDataGetter={row => row.rowData}
        cellRenderer={({ rowIndex }) => (
          <Checkbox
            onClick={e => e.stopPropagation()}
            checked={rowSelected(rowIndex)}
            onChange={e => onRowSelect({ [rowIndex]: e.target.checked })}
            disabled={dataSource?.[rowIndex]?.disabled}
            data-testid={'select-checkbox-' + rowIndex}
          />
        )}
        dataKey='checkbox'
        disableSort
        headerRenderer={() => {
          if (allowSelectAll) {
            return (
              <Checkbox
                onClick={e => e.stopPropagation()}
                checked={this.areAllSelected()}
                disabled={dataSource.length === 0}
                onChange={this.handleSelectAll}
                data-testid='select-all-checkbox'
              />
            )
          } else {
            return null
          }
        }}
        label='checkbox'
        minWidth={24}
        style={{ marginBottom: 5 }}
        width={24}
      />
    )
  }

  attachRowClassName = args => {
    const { index } = args
    const { dataSource, rowClassName: rowClassNameOverride } = this.props
    const isDisabled = dataSource?.[index]?.disabled
    const classNames = isDisabled ? `${rowClassName} disabled` : rowClassName
    if (rowClassNameOverride) {
      if (typeof rowClassNameOverride === 'function') {
        return classNames + ' ' + rowClassNameOverride(args)
      }
      if (typeof rowClassNameOverride === 'string') {
        return classNames + ' ' + rowClassNameOverride
      }
    }
    return classNames
  }

  handleRowClick = ({ event, index, rowData }) => {
    const { onRowClick } = this.props
    const { disabled } = rowData
    if (!disabled && onRowClick && _.isFunction(onRowClick)) {
      onRowClick({ event, index, rowData })
    }
  }

  render () {
    const { columns, fetching, fetchingMore, getNext, rowSelection, selectSingle, ...props } = this.props
    const { selectedIndices, sortBy, sortDirection, sortedData: data } = this.state
    return (
      <>
        {fetching && !fetchingMore && _.times(100, i => <StyledCard key={i} loading />)}
        <AutoSizer>
          {({ height, width }) => (
            <StyledTable
              headerHeight={48}
              height={height}
              onScroll={this.handleScroll}
              rowCount={data.length}
              rowGetter={({ index }) => data[index]}
              rowHeight={48}
              rowStyle={({ index }) => (index === -1 ? { background: Colors.solitude } : null)}
              sortBy={sortBy}
              sortDirection={sortDirection}
              width={width}
              {...props}
              onRowClick={this.handleRowClick}
              rowClassName={this.attachRowClassName}
            >
              {rowSelection &&
                !selectSingle &&
                this.renderCheckboxColumn({
                  onRowSelect: this.onRowSelect,
                  rowSelected: index => selectedIndices[index]
                })}
              {columns.map((c, i) => (
                <Column
                  cellRenderer={c.render}
                  dataKey={c.dataIndex}
                  disableSort={!c.sorter}
                  headerRenderer={this.headerRenderer}
                  key={i}
                  label={c.title}
                  style={c.pushRight && { marginLeft: 'auto' }}
                  width={c.width}
                />
              ))}
            </StyledTable>
          )}
        </AutoSizer>
        {getNext && (
          <FetchingMoreIndicator loading={fetchingMore}>
            <Row align='middle' justify='center' type='flex'>
              <StyledSpin />
              <Text allCaps id='fetching_more_results' inline />
            </Row>
          </FetchingMoreIndicator>
        )}
      </>
    )
  }
}

DashTable.propTypes = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  fetching: PropTypes.bool,
  fetchingMore: PropTypes.bool,
  getNext: PropTypes.func,
  onRowClick: PropTypes.func,
  rowClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowSelection: PropTypes.object,
  selectSingle: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  allowSelectAll: PropTypes.bool
}

DashTable.defaultProps = {}

export { DashTable }
