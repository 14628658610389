import { COUNTRIES } from '@nv/react-commons/src/Constants/Country'
import moment from 'moment-timezone'

export const getTimezone = c => {
  switch (c && c.toLowerCase()) {
    case COUNTRIES.SG:
    case COUNTRIES.PH:
    case COUNTRIES.MY:
      return 'Asia/Singapore'
    case COUNTRIES.MM:
      return 'Asia/Yangon'
    case COUNTRIES.MA:
      return 'Africa/Casablanca'
    default:
      return 'Asia/Jakarta'
  }
}

export const momentUTCWithTimezone = (t, c) => {
  const tz = getTimezone(c)
  return moment.utc(t).tz(tz)
}

export const addWeekdays = (date, days) => {
  date = moment(date)
  while (days > 0) {
    date = date.add(1, 'days')
    // ignore sunday
    if (date.weekday() !== 0) {
      days -= 1
    }
  }
  return date
}

export const formatHour = hour =>
  moment()
    .set({ hour, minute: 0 })
    .format('HH:mm')
