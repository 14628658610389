import { COUNTRIES } from '@nv/react-commons/src/Constants'
import Config from 'configs'

const { WEBSITE_URL } = Config
const XPRESS_WEBSITE_URL = WEBSITE_URL.replace('.ninjavan.', '.ninjaxpress.')

const BASE_WEBSITE_URL = {
  [COUNTRIES.ID]: {
    id: `${XPRESS_WEBSITE_URL}/id-id`,
    en: `${XPRESS_WEBSITE_URL}/en-id`
  },
  [COUNTRIES.VN]: {
    vi: `${WEBSITE_URL}/vi-vn`,
    en: `${WEBSITE_URL}/en-vn`
  },
  [COUNTRIES.TH]: {
    th: `${WEBSITE_URL}/th-th`,
    en: `${WEBSITE_URL}/en-th`
  },
  [COUNTRIES.SG]: {
    en: `${WEBSITE_URL}/en-sg`
  },
  [COUNTRIES.MY]: {
    en: `${WEBSITE_URL}/en-my`
  },
  [COUNTRIES.PH]: {
    en: `${WEBSITE_URL}/en-ph`
  },
  [COUNTRIES.MM]: {
    my: `${WEBSITE_URL}/my-mm`,
    en: `${WEBSITE_URL}/en-mm`
  }
}

export const getBaseWebsiteUrl = ({ country = '', language = 'en' }) => {
  const countryURLs = BASE_WEBSITE_URL[country?.toLowerCase()] || BASE_WEBSITE_URL[COUNTRIES.SG]
  const baseUrl = countryURLs[language] || countryURLs['en']
  return baseUrl
}

export const getHelpdeskLink = ({
  country,
  language = 'en',
  shipper = {},
  caseOrigin = 'Dash Web - Webform'
}: {
  country: string
  language?: string
  shipper: any
  caseOrigin?: string
}) => {
  const baseLink = getBaseWebsiteUrl({ country, language })

  const queryParams = []

  const legacyId = shipper.legacyId
  const shipperId = shipper.id
  const storeName = shipper.name
  const name = shipper.liaisonName
  const email = shipper.email

  if (legacyId) queryParams.push(`legacy_shipper_id=${legacyId}`)
  if (shipperId) queryParams.push(`shipper_id=${shipperId}`)
  if (storeName) queryParams.push(`shipper_store_name=${storeName}`)
  if (name) queryParams.push(`name=${name}`)
  if (email) queryParams.push(`email=${email}`)
  if (caseOrigin) queryParams.push(`case_origin=${caseOrigin}`)

  const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : ''

  return `${baseLink}/shipper-helpdesk${queryString}`
}

export const getWebsiteContactUsLink = (country: string) => {
  switch (country) {
    case COUNTRIES.SG:
    case COUNTRIES.PH:
    case COUNTRIES.MY:
      return `https://www.ninjavan.co/en-${country}/support/contact-us`
    case COUNTRIES.MM:
      return `https://www.ninjavan.co/en-mm/support/contact-us`
    case COUNTRIES.TH:
      return `https://www.ninjavan.co/${country}-${country}/support/contact-us`
    case COUNTRIES.ID:
      return `https://www.ninjaxpress.co/id-id/support/contact-us`
    case COUNTRIES.VN:
      return `https://www.ninjavan.co/vi-vn/support/contact-us`
    default:
      return `https://www.ninjavan.co/en-sg/support/contact-us`
  }
}

export const getTermsOfUseLink = (country: string) => {
  switch (country) {
    case COUNTRIES.PH:
      return `https://www.ninjavan.co/en-ph/terms-of-use`
    case COUNTRIES.SG:
      return `https://www.ninjavan.co/en-sg/terms-of-use-ninja-dash`
    case COUNTRIES.MY:
      return `https://www.ninjavan.co/en-my/b2b-terms-and-conditions`
    case COUNTRIES.TH:
      return `https://www.ninjavan.co/en-th/dash-mobile-index-page`
    case COUNTRIES.ID:
      return `https://ninjaxpress.zendesk.com/hc/en-us/sections/11841924443929-Terms-Policies`
    case COUNTRIES.VN:
      return `https://www.ninjavan.co/en-vn/dash-mobile-index-page`
    default:
      return `https://www.ninjavan.co/en-sg/terms-of-use`
  }
}

export const getWebsiteRegisterLink = (countryCode: string) => {
  switch (countryCode) {
    case COUNTRIES.SG:
    case COUNTRIES.PH:
    case COUNTRIES.MY:
      return `https://www.ninjavan.co/en-${countryCode}/signup`
    case COUNTRIES.MM:
      return `https://www.ninjavan.co/en-mm/support/contact-us`
    case COUNTRIES.TH:
      return `https://www.ninjavan.co/${countryCode}-${countryCode}/signup`
    case COUNTRIES.ID:
      return `https://www.ninjaxpress.co/id-id/signup`
    case COUNTRIES.VN:
      return `https://www.ninjavan.co/vi-vn/signup`
    default:
      return `https://www.ninjavan.co/en-sg/signup`
  }
}
