import { NvApi } from '@nv/react-commons/src/Services'
import { getBaseUrl } from './getBaseUrl'

type ApiConfig = {
  url?: string
  service: string
  country?: string
  timeout?: number
  transform?: boolean
  protect?: boolean
}

export const createApi = (config: ApiConfig) => {
  return NvApi.create({ ...config, url: getBaseUrl({ country: config.country, service: config.service }) }).api
}
