import React from 'react'
import { BaseHeader } from '../../components/BaseHeader'
import styled from 'styled-components'
import { HeaderLogo } from '../../components/HeaderLogo'
import { PREPAID_TERMS_URL } from './constants'
import { H1 } from '../../components/Text'
import { Button } from '../../components/Button'
import StyledIcon from '@nv/react-commons/src/Components/Icon'
import { T } from '@nv/react-commons/src/Components'
import { faPrint } from '@fortawesome/pro-light-svg-icons'
import { Colors } from 'themes'
import { media } from '../../themes'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { selectCountry } from '../Base/selectors'
import PropTypes from 'prop-types'

const StyledHeader = styled(BaseHeader)`
  && {
    padding-left: 16px;
    padding-right: 16px;
    background-color: ${props => props.theme.layoutHeaderBackground};
    position: sticky;
    justify-content: center;
  }
`

const ParentContainer = styled.div`
  && {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    width: 795px;
    margin-left: auto;
    margin-right: auto;
    ${media.max.tablet`
      width: 735px;
    `}
    ${media.max.mobile`
      margin-left: 16px;
      margin-right: 16px;
      width: auto;
    `}
  }
`

const MainContainer = styled.div`
  && {
    height: 100%;
    background-color: ${Colors.white};
  }
`

const StyledIframe = styled.iframe`
  border: ${props => (props.actionRequired ? 'solid 1px lightgrey' : 'none')};
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`
const TermsTitle = styled(H1)`
  && {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    margin: 32px 0 16px 0;
  }
`

const StyledButton = styled(Button).attrs({
  size: 'small'
})`
  && {
    width: 134px;
    height: 36px;
    margin-bottom: 16px;
    border-radius: 4px;
    border: solid 1px #bfc4c9;
  }
`

const StyledButtonText = styled(T)`
  && {
    font-size: 12px;
    color: #6e7378;
  }
`

const printTC = country => {
  let proxyIframe = document.getElementById('proxy-term-iframe')
  if (proxyIframe) {
    proxyIframe.parentElement.removeChild(proxyIframe)
  }
  proxyIframe = document.createElement('iframe')
  const body = document.getElementsByTagName('body')[0]
  body.appendChild(proxyIframe)
  proxyIframe.id = 'proxy-term-iframe'
  proxyIframe.style.display = 'none'
  proxyIframe.style.width = '100%'
  proxyIframe.style.height = 'auto'
  proxyIframe.style.overflow = 'scroll'
  proxyIframe.setAttribute('data-testid', 'proxy-term-iframe')

  const contentWindow = proxyIframe.contentWindow
  contentWindow.document.open()
  contentWindow.document.write(
    '<iframe id="real-term-iframe" src="' +
      PREPAID_TERMS_URL[country.toLowerCase()] +
      '" ' +
      'onload="print();" width="100%" height="9000" ' +
      'frameborder="0" marginheight="0" marginwidth="0">'
  )
  contentWindow.document.close()
}

const TermsAndConditions = ({ country }) => {
  return (
    <>
      <StyledHeader>
        <HeaderLogo />
      </StyledHeader>
      <MainContainer>
        <ParentContainer>
          <TermsTitle>Terms of Use</TermsTitle>
          <StyledButton onClick={() => printTC(country)}>
            <StyledIcon icon={faPrint} color='inherit' />
            <StyledButtonText id='print_tac' />
          </StyledButton>
          <StyledIframe isDesktop={false} src={PREPAID_TERMS_URL[country.toLowerCase()]} />
        </ParentContainer>
      </MainContainer>
    </>
  )
}

TermsAndConditions.propTypes = {
  country: PropTypes.string.isRequired
}

const mapStateToProps = createStructuredSelector({
  country: selectCountry()
})

const withConnect = connect(mapStateToProps)

export default withConnect(TermsAndConditions)
