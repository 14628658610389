/**
 *
 * DangerousGoodFormItem
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { T, Form, Radio } from '@nv/react-commons/src/Components'
import styled from 'styled-components'
import { FIELDS } from 'components/OCForm/constants'

const RadioGroup = Radio.Group

const StyleRadioGroup = styled(RadioGroup).attrs({})`
  margin-top: 2px !important;
`

function DangerousGoodFormItem (props) {
  const radioStyle = {
    display: 'block'
  }
  const {
    disabled,
    initialValue,
    intl,
    formItem: FormItem,
    form: { getFieldDecorator }
  } = props
  return (
    <FormItem label={<T id='is_dangerous_good' />}>
      {getFieldDecorator(FIELDS.IS_DANGEROUS_GOOD, {
        initialValue: initialValue,
        rules: []
      })(
        <StyleRadioGroup orientation='vertical' disabled={disabled}>
          <Radio style={radioStyle} value>
            {intl.formatMessage({ id: 'yes' })}
          </Radio>
          <Radio style={radioStyle} value={false}>
            {intl.formatMessage({ id: 'no' })}
          </Radio>
        </StyleRadioGroup>
      )}
    </FormItem>
  )
}

DangerousGoodFormItem.propTypes = {
  form: PropTypes.object,
  formItem: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  intl: PropTypes.object
}

DangerousGoodFormItem.defaultProps = {
  formItem: Form.Item
}

const _DangerousGoodFormItem = injectIntl(DangerousGoodFormItem)
export { _DangerousGoodFormItem as DangerousGoodFormItem }
