import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'

const { Types, Creators } = createActions(
  {
    merge: ['data'],
    getPickupRequest: [],
    cancelPickupRequest: ['payload'],
    bookPickupRequest: ['data'],
    clear: []
  },
  { prefix: 'Base/pickup/' }
)

export const pickupCreators = Creators
export const pickupTypes = Types

const INITIAL_STATE = fromJS({
  pickupId: null,
  driver: null,
  createdAt: null,
  orders: null,
  etaMinutes: null,
  cancelPickupLoading: false
})

const merge = (state, { data }) => state.merge(data)
const clear = state => state.merge(INITIAL_STATE)

export const pickupReducer = createReducer(INITIAL_STATE, {
  [Types.MERGE]: merge,
  [Types.CLEAR]: clear
})
