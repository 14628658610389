import { map } from 'lodash'
import {
  TEMPLATE_1002,
  TID_OFFSET_Y_9_10,
  TID_OFFSET_Y_11_12,
  TID_OFFSET_Y_13_14,
  TID_OFFSET_Y_15_16,
  TID_OFFSET_Y_OTHER,
  TID_OFFSET_X_9_12,
  TID_OFFSET_X_13_15,
  TID_OFFSET_X_OTHER
} from './constants'

export const getBarCodeOffsetY = tid => {
  const tidLength = tid.length
  if (tidLength >= 9 && tidLength < 11) {
    return TID_OFFSET_Y_9_10
  }
  if (tidLength >= 11 && tidLength < 13) {
    return TID_OFFSET_Y_11_12
  }
  if (tidLength >= 13 && tidLength < 15) {
    return TID_OFFSET_Y_13_14
  }
  if (tidLength >= 15 && tidLength < 17) {
    return TID_OFFSET_Y_15_16
  }
  return TID_OFFSET_Y_OTHER
}

export const getBarCodeOffsetX = tid => {
  const tidLength = tid.length
  if (tidLength >= 9 && tidLength < 13) {
    return TID_OFFSET_X_9_12
  }
  if (tidLength >= 13 && tidLength < 16) {
    return TID_OFFSET_X_13_15
  }
  return TID_OFFSET_X_OTHER
}

export const handleStartPrinting = (orders, printerSocket) => {
  const jobData = map(orders, order => {
    const tid = (order?.parcel?.requestedTrackingId ?? '--').toUpperCase()
    const orderToReturn = {
      recipient_name: (order?.receiver?.toName ?? '--').toUpperCase(),
      address1: (order?.receiver?.toAddress?.toAddress1 ?? '--').toUpperCase(),
      address2: (order?.receiver?.toAddress?.toAddress2 || '--').toUpperCase(),
      contact: order?.receiver?.toContact ?? '--',
      country: order?.receiver?.toAddress?.toCountry ?? '--',
      postcode: order?.receiver?.toAddress?.toPostCode ?? '--',
      shipper_name: (order?.shipper?.fromName ?? '--').toUpperCase(),
      tid: tid,
      barcode: tid,
      cod: order?.parcel?.cod ?? '--',
      parcel_description: order?.parcel?.parcelDescription ?? '--',
      template_string: TEMPLATE_1002,
      barcode_offset_y: getBarCodeOffsetY(tid),
      barcode_offset_x: getBarCodeOffsetX(tid),
      options: {
        orderEvent: false,
        app: 'Dash',
        module: 'International order',
        application: 'Dash'
      }
    }
    return orderToReturn
  })
  const printJob = {
    event: 'PRINTER:PRINT',
    socket_id: printerSocket && printerSocket.id,
    jobs: jobData
  }
  if (printerSocket) {
    printerSocket.emit('message', printerSocket.id, printJob)
  }
}
