import React from 'react'
import PropTypes from 'prop-types'
import { Carousel as NVCarousel } from 'antd'

const Carousel = ({ children, ...props }) => {
  return <NVCarousel {...props}>{children}</NVCarousel>
}

Carousel.propTypes = {
  children: PropTypes.node,
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  autoplay: PropTypes.bool,
  speed: PropTypes.number
}

Carousel.defaultProps = {
  children: 'Carousel',
  dots: true,
  infinite: false,
  autoplay: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
}

export { Carousel }
