/**
 *
 * Asynchronously loads the component for SignUpProfile
 *
 */

import React from 'react'
import loadable from 'utils/loadable'
import { AuthPageLoading } from '../../components/AuthPageLoading/AuthPageLoading'

export default loadable({
  loader: () => import('./index' /* webpackChunkName: "SignUpProfile" */),
  loading: <AuthPageLoading />
})
