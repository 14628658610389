import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { selectShipper } from 'containers/Base/selectors'
import { FeatureHubContext } from './context'

export const useFlag = featureFlagKey => {
  const isShipperInitialized = useIsShipperInitialized()
  const { flags, preInitFlags } = useContext(FeatureHubContext)
  const featureFlags = isShipperInitialized ? flags : preInitFlags
  return featureFlags?.[featureFlagKey] ?? false
}

export const useFlags = featureFlagsKeys => {
  const isShipperInitialized = useIsShipperInitialized()
  const { flags, preInitFlags } = useContext(FeatureHubContext)
  const featureFlags = isShipperInitialized ? flags : preInitFlags
  return featureFlagsKeys.reduce((result, key) => {
    result[key] = featureFlags?.[key] ?? false
    return result
  }, {})
}

export const useFeatureHub = () => {
  return useContext(FeatureHubContext)
}

const useIsShipperInitialized = () => {
  const shipper = useSelector(selectShipper())
  const shipperId = shipper?.id
  return Number.isFinite(shipperId)
}
