import React, { useEffect, useState } from 'react'
import { map, size, slice } from 'lodash'
import { NVModal, T } from '@nv/react-commons/src/Components'
import { StyledIcon, StyledLink } from './styles'
import { DataRow } from 'components/DataRow'
import { useIntl } from 'hooks/common'
import { RadioGroup } from 'components/RadioGroup'
import { faChevronDown } from '@fa-pro-regular/faChevronDown'
import { faChevronUp } from '@fa-pro-regular/faChevronUp'

const LIMIT = 5

interface DataListProps {
  data: any[]
  renderRow: (row: any) => JSX.Element
  selectedId: number
  onChange: (id: number) => void
  onEdit?: (id: number) => void
  onDelete?: (id: number) => void
  onPreview?: (id: number) => void
  rowType?: string
}

const transformData = (list: any[]): any[] => {
  return map(list, data => ({ data, id: data.id }))
}

export const DataList: React.FC<DataListProps> = ({
  data,
  renderRow,
  selectedId,
  onChange,
  onEdit,
  onDelete,
  rowType
}) => {
  const [state, setState] = useState({
    data: transformData(data),
    idSelection: selectedId,
    showAll: false,
    lastSelected: data.findIndex(d => d.id === selectedId) !== 0
  })

  const intl = useIntl()

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      data: transformData(data)
    }))
  }, [data, selectedId])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedChangedId = parseInt(e.target.value, 10)
    onChange(selectedChangedId)
    setState(prevState => ({ ...prevState, idSelection: selectedChangedId }))
  }

  const confirmDelete = (id: number) => {
    NVModal.confirm({
      title: intl.formatMessage({ id: 'are_you_sure' }),
      content: intl.formatMessage({ id: 'delete_reminder' }),
      okText: intl.formatMessage({ id: 'yes' }),
      cancelText: intl.formatMessage({ id: 'no' }),
      onOk: () => {
        if (onDelete) {
          onDelete(id)
        }
      },
      onCancel: () => {
        /* Handle Cancel */
      }
    })
  }

  const handleToggleShowAll = () => {
    setState(prevState => ({ ...prevState, showAll: !prevState.showAll }))
  }

  const { data: dataList, idSelection, showAll } = state
  const filteredItems = slice(dataList, 0, showAll ? undefined : LIMIT)
  return (
    <>
      <RadioGroup onChange={handleChange} value={idSelection}>
        {filteredItems.map(item => {
          const rowProps = {
            ...item.data,
            renderRow,
            rowType,
            value: item.id,
            onDelete: onDelete ? () => confirmDelete(item.id) : null,
            onEdit: onEdit ? () => onEdit(item.id) : null,
            selected: idSelection === item.id
          }
          return <DataRow key={item.id} {...rowProps} />
        })}
      </RadioGroup>
      {size(dataList) > LIMIT && (
        <StyledLink onClick={handleToggleShowAll} to='#'>
          <StyledIcon icon={showAll ? faChevronUp : faChevronDown} />
          <T id={showAll ? 'see_less' : 'see_more'} />
        </StyledLink>
      )}
    </>
  )
}
