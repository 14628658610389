import sfiApi from 'services/api/sfiApi'
import {
  ORGANIZATION_ID,
  LIVE_AGENT,
  LIVE_AGENT_CONFIG,
  SALES_FORCE_URL,
  SALES_FORCE_SERVICE_URL,
  SERVICE_NAME,
  CASE_ORIGIN
} from './SalesforceChat.constants'

export const initializeChat = (shipper: any, gslbBaseURL: string = null) => {
  if (!window.embedded_svc) {
    return
  }
  const embeddedService = window.embedded_svc

  const settings = {
    displayHelpButton: true,
    language: 'en',
    enabledFeatures: [LIVE_AGENT],
    entryFeature: LIVE_AGENT
  }
  embeddedService.settings = { ...embeddedService.settings, ...settings }
  updatePrechatFields(shipper)
  embeddedService.init(
    SALES_FORCE_URL,
    SALES_FORCE_SERVICE_URL,
    gslbBaseURL,
    ORGANIZATION_ID,
    SERVICE_NAME,
    LIVE_AGENT_CONFIG
  )
}

export const updatePrechatFields = (shipper: any) => {
  if (!window.embedded_svc) {
    return
  }
  const embeddedService = window.embedded_svc
  embeddedService.settings.prepopulatedPrechatFields = {
    /* eslint-disable camelcase */
    Global_ID__c: shipper.id?.toString(),
    Shipper_ID__c: shipper.legacyId?.toString(),
    Email: shipper.email,
    Shipper_Name__c: shipper.name,
    Country__c: shipper.country,
    Case_Origin__c: CASE_ORIGIN
  }
}

export const isCurrentTimeWithinBusinessHours = async (country: string) => {
  const response = await sfiApi.getBusinessHours({ country })

  if (!response.ok) {
    return false
  }

  const businessHours = response.data.data.find(item => item.team === null && item.systemId === country)

  if (!businessHours) {
    return false
  }

  const now = new Date()
  const day = now.toLocaleString('en-US', { weekday: 'short' }).toLowerCase() // get day in short format
  const currentTime = now.toTimeString().substring(0, 8) // get time in HH:MM:SS format

  const startKey = `${day}LocalStartTime`
  const endKey = `${day}LocalEndTime`

  if (!businessHours[startKey] || !businessHours[endKey]) {
    return false
  }

  return currentTime >= businessHours[startKey] && currentTime <= businessHours[endKey]
}
