import _ from 'lodash'
import { TRACKING_TYPES } from './constants'
import { cutPrefix } from '../TrackingNumberInput/utils'
const PACKS_PREFIX = 'NIN'

export const trimTrackingId = id => {
  if (!_.startsWith(id, 'NIN')) {
    return `${PACKS_PREFIX}${_.last(_.split(id, PACKS_PREFIX))}`
  }
  return id
}

export const getInitialSelectedPrefix = (isNinjaPackOrder, isCorpAWBOrder, isCorporateBranch, ocSettings) => {
  if (!isNinjaPackOrder && !isCorpAWBOrder) {
    return isCorporateBranch ? ocSettings?.canonicalPrefix : ocSettings?.prefix
  }
  return ''
}

export const getTrackingType = (isCorporateBranch, ocSettings) => {
  return isCorporateBranch
    ? (ocSettings?.marketplaceTrackingType || '').toLowerCase()
    : (ocSettings?.trackingType || '').toLowerCase()
}

const validatePackId = validationData => (rule, value, callback) => {
  if (value && !validationData) {
    const error = 'Invalid Ninja Pack Tracking ID!'
    callback(error)
  } else {
    callback()
  }
}

const validateCorpAwbId = validationData => (rule, value, callback) => {
  if (value && !validationData) {
    const error = 'Invalid Corporate Manual AWB Tracking ID!'
    callback(error)
  } else {
    callback()
  }
}

const validateMaxLengthWithoutPrefix = (selectedPrefix, value, trackingType, trackingSettings, intl, callback) => {
  const actualValue = cutPrefix(selectedPrefix, value)
  if (actualValue?.length > trackingSettings.max) {
    callback(intl.formatMessage({ id: 'order_ref_id_max' }, { x: trackingSettings.max }))
  } else {
    callback()
  }
}

const validateMinLengthWithoutPrefix = (selectedPrefix, value, trackingType, trackingSettings, intl, callback) => {
  const actualValue = cutPrefix(selectedPrefix, value)
  if (actualValue?.length < trackingSettings.min) {
    callback(intl.formatMessage({ id: 'order_ref_id_min' }, { x: trackingSettings.min }))
  } else {
    callback()
  }
}

const validateMinLength = (trackingType, selectedPrefix, trackingSettings, intl) => (rule, value, callback) => {
  // skip min length validation for optional fields when there is not value
  const isNotRequiredAndEmptyValue = !trackingSettings.required && !value
  if (isNotRequiredAndEmptyValue) {
    callback()
  } else if (
    [TRACKING_TYPES.MULTI_DYNAMIC, TRACKING_TYPES.MULTI_FIXED, TRACKING_TYPES.FIXED, TRACKING_TYPES.DYNAMIC].includes(
      trackingType
    )
  ) {
    validateMinLengthWithoutPrefix(selectedPrefix, value, trackingType, trackingSettings, intl, callback)
  } else if (value?.length < trackingSettings.min) {
    callback(intl.formatMessage({ id: 'order_ref_id_min' }, { x: trackingSettings.min }))
  } else {
    callback()
  }
}

const validateMaxLength = (trackingType, selectedPrefix, trackingSettings, intl, isNinjaPackOrder, isCorpAWBOrder) => (
  rule,
  value,
  callback
) => {
  // skip max length validation for optional fields when there is not value
  const isNotRequiredAndEmptyValue = !trackingSettings.required && !value
  if (isNinjaPackOrder || isCorpAWBOrder || isNotRequiredAndEmptyValue) {
    callback()
  } else if (
    [TRACKING_TYPES.MULTI_DYNAMIC, TRACKING_TYPES.MULTI_FIXED, TRACKING_TYPES.FIXED, TRACKING_TYPES.DYNAMIC].includes(
      trackingType
    )
  ) {
    validateMaxLengthWithoutPrefix(selectedPrefix, value, trackingType, trackingSettings, intl, callback)
  } else if (value?.length > trackingSettings.max) {
    callback(intl.formatMessage({ id: 'order_ref_id_max' }, { x: trackingSettings.max }))
  } else {
    callback()
  }
}

const getOrderRefRule = (
  trackingType,
  selectedPrefix,
  isNinjaPackOrder,
  isCorpAWBOrder,
  intl,
  trackingSettings,
  validationData
) => {
  if (isNinjaPackOrder) {
    return {
      validator: validatePackId(validationData),
      message: intl.formatMessage({ id: 'invalid_ninja_pack_tracking_id' })
    }
  } else if (isCorpAWBOrder) {
    return {
      validator: validateCorpAwbId(validationData),
      message: intl.formatMessage({ id: 'invalid_corp_awb_tracking_id' })
    }
  }
}

export const getValidationRules = (
  isNinjaPackOrder,
  isCorpAWBOrder,
  isCorporateBranch,
  ocSettings,
  trackingSettings,
  intl,
  validationData,
  trackingType,
  selectedPrefix
) => {
  const orderRefRule = getOrderRefRule(
    trackingType,
    selectedPrefix,
    isNinjaPackOrder,
    isCorpAWBOrder,
    intl,
    trackingSettings,
    validationData
  )

  const rules = [
    {
      required: isNinjaPackOrder || isCorpAWBOrder ? true : trackingSettings.required,
      message: intl.formatMessage({ id: 'order_ref_id_required' })
    },
    {
      validator: validateMinLength(trackingType, selectedPrefix, trackingSettings, intl)
    },
    {
      validator: validateMaxLength(
        trackingType,
        selectedPrefix,
        trackingSettings,
        intl,
        isNinjaPackOrder,
        isCorpAWBOrder
      )
    }
  ]

  if (orderRefRule) {
    rules.push(orderRefRule)
  }

  return rules
}
