import _ from 'lodash'
import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'

const { Types, Creators } = createActions(
  {
    getShipperSuccess: ['data'],
    selectShipper: ['currentId'],
    selectShipperSuccess: ['currentId'],
    getSubShippersCount: ['subShippersCount'],
    clear: [],
    updateShipper: ['data'],
    updateEkycStage: ['shipperId', 'ekycStage']
  },
  { prefix: 'Base/shipper/' }
)

export const shipperCreators = Creators
export const shipperTypes = Types

const INITIAL_STATE = fromJS({
  data: {},
  error: {},
  fetching: false,
  currentId: null,
  subShippersCount: 0
})

const getShipperSuccess = (state, { data }) => (_.isEmpty(data) ? state : state.merge({ data }))
const getSubShippersCount = (state, { subShippersCount }) =>
  subShippersCount ? state.merge({ subShippersCount }) : state
const selectShipperSuccess = (state, { currentId }) => state.merge({ currentId: parseInt(currentId) })
const clear = state => state.merge(INITIAL_STATE)
const updateShipper = (state, { data }) => state.mergeDeep({ data })
const updateEkycStage = (state, { shipperId, ekycStage }) =>
  state.mergeDeep({
    data: {
      [shipperId]: {
        ekycStage
      }
    }
  })

export const shipperReducer = createReducer(INITIAL_STATE, {
  [Types.GET_SHIPPER_SUCCESS]: getShipperSuccess,
  [Types.SELECT_SHIPPER_SUCCESS]: selectShipperSuccess,
  [Types.CLEAR]: clear,
  [Types.GET_SUB_SHIPPERS_COUNT]: getSubShippersCount,
  [Types.UPDATE_SHIPPER]: updateShipper,
  [Types.UPDATE_EKYC_STAGE]: updateEkycStage
})
