import { SERVICE_TYPE } from './constants'

const calculateResponseTime = () => {
  let startTime

  const startCounting = () => {
    startTime = new Date()
  }

  const getTime = () => {
    return new Date() - startTime
  }

  const reset = () => {
    startTime = undefined
  }

  return { startCounting, getTime, reset }
}

const getE2EServices = services => {
  if (!services?.length) return []
  return services.filter(service => service.type !== SERVICE_TYPE.MMCC)
}

export { calculateResponseTime, getE2EServices }
