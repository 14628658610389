/**
 *
 * 4PL Custom Currency
 *
 */

import React from 'react'
import { Form } from '@nv/react-commons/src/Components'
import { OCSubStep } from 'components/OCSubStep'
import PropTypes from 'prop-types'
import { faBoxUsd } from '@fortawesome/pro-light-svg-icons'
import { StyledFormItem } from 'components/OCForm/styles'
import { CUSTOM_CURRENCY_LIST } from './constants'
import { compose } from 'redux'
import { Preview } from 'components/Preview'
import { useIntl } from 'hooks/common'
import { FlexBlock, StyledSelectionFormItem } from './styles'
import { InfoTooltip } from 'containers/FPLOrderRequestList/Fields'

const renderTooltip = () => {
  return <InfoTooltip tooltipTextId='international_currency_tooltip' />
}

const CustomCurrencyForm = ({ status, form, currency, onSave, onCancel, onEdit }) => {
  const intl = useIntl()

  const renderPreview = () => {
    return (
      <Preview
        title={
          <FlexBlock align='center'>
            {currency.toUpperCase()}
            {renderTooltip()}
          </FlexBlock>
        }
      />
    )
  }

  const renderActive = () => {
    const textName = intl.formatMessage({ id: 'custom_currency' })
    const placeholder = intl.formatMessage({ id: 'select_a_currency' })
    return (
      <FlexBlock justify='initial' align='center'>
        <StyledSelectionFormItem
          placeholder={placeholder}
          initialValue={currency}
          formItem={StyledFormItem}
          form={form}
          textName={textName}
          fieldName='customCurrency'
          menus={CUSTOM_CURRENCY_LIST}
          required
        />
        {renderTooltip()}
      </FlexBlock>
    )
  }

  const handleSaveCurrency = () => {
    form.validateFields((err, values) => {
      if (!err) {
        onSave(values.customCurrency)
      }
    })
  }

  return (
    <OCSubStep
      title='custom_currency'
      status={status}
      icon={faBoxUsd}
      renderActive={renderActive}
      renderPreview={renderPreview}
      emptyTitle={!currency && 'select_a_currency'}
      onSave={handleSaveCurrency}
      onEdit={onEdit}
      onCancel={onCancel}
    />
  )
}

CustomCurrencyForm.propTypes = {
  status: PropTypes.string,
  form: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
  currency: PropTypes.string.isRequired
}

const CustomCurrency = compose(Form.create())(React.memo(CustomCurrencyForm))

export { CustomCurrency }
