import { TIMESLOT_REGEX } from 'constants/deliveryTimeslots'

/**
 * Processes a timeslot string (e.g., "0900_2200") by splitting it on '_' and converting each part to an integer
 * representing hours. This function expects the timeslot string to be in the format "HHMM_HHMM",
 * where the first part is the start time and the second part is the end time. The function converts
 * these times from a 24-hour clock format without a colon (e.g., "0900" or "2200") into hours by dividing
 * by 100, thus turning "0900" into 9 and "2200" into 22.
 *
 * @param timeslot - A string representing a timeslot in the format "HHMM_HHMM".
 * @returns A tuple [start, end] where `start` is the start hour and `end` is the end hour.
 */
export const processTimeslotString = (timeslot: string): [number, number] => {
  if (typeof timeslot !== 'string' || !timeslot.match(TIMESLOT_REGEX)) {
    return undefined
  }
  const [start, end] = timeslot.split('_').map(time => parseInt(time, 10) / 100)
  return [start, end]
}
