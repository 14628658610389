/**
 *
 * BreakpointListener
 *
 */

import _ from 'lodash'
import React, { useContext } from 'react'
import hoistNonReactStatic from 'hoist-non-react-statics'
import { sizes } from '@nv/react-commons/src/Themes'
import PropTypes from 'prop-types'

const getWidth = () => {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
}
const isDesktop = () => getWidth() > sizes.tablet
const isTablet = () => _.inRange(getWidth(), sizes.mobile + 1, sizes.tablet + 1)
const isMobile = () => getWidth() <= sizes.mobile

const SizeContext = React.createContext({
  isDesktop: isDesktop(),
  isTablet: isTablet(),
  isMobile: isMobile(),
  width: getWidth()
})

export const useSize = () => useContext(SizeContext)

export const withSize = Component => {
  const Sized = props => {
    return <SizeContext.Consumer>{mediaQueries => <Component {...props} {...mediaQueries} />}</SizeContext.Consumer>
  }
  return hoistNonReactStatic(Sized, Component)
}

// TODO: Let's move to using SizeProvider
export class BreakpointListener extends React.Component {
  state = {
    height: document.body.clientHeight,
    width: document.body.clientWidth,
    isDesktop: isDesktop(),
    isTablet: isTablet(),
    isMobile: isMobile()
  }

  onResize = resize => {
    this.setState({
      height: resize.target.innerHeight,
      width: resize.target.innerWidth,
      isDesktop: isDesktop(),
      isTablet: isTablet(),
      isMobile: isMobile()
    })
  }

  componentDidMount () {
    window.addEventListener('resize', this.onResize)
  }

  UNSAFE_componentWillUnmount () {
    window.removeEventListener('resize', this.onResize)
  }

  render () {
    return <SizeContext.Provider value={this.state}>{this.props.children}</SizeContext.Provider>
  }
}

BreakpointListener.propTypes = {
  children: PropTypes.node
}
