import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { compose } from 'redux'
import { useIntl } from 'hooks/common'
import { Form, message, T, Spin } from '@nv/react-commons/src/Components'
import { OCSubStep } from 'components/OCSubStep'
import { AddressText } from 'components/AddressText'
import { PICKUP_ADDRESS } from 'containers/FPLOrderCreate/constants'
import { debounce, max } from 'lodash'
import { Preview } from 'components/Preview'
import { SenderInfoProps } from './types'
import { SpinWrapperStyled } from 'containers/FPLOrderCreate/styles'
import { EditAddress } from './EditAdrress'
import { faWarehouseAlt } from '@fa-pro-light/faWarehouseAlt'
import { DataList } from './DataList'
import { SUB_STEP_STATUS } from './constants'
import { DataListWrapper } from './styles'

const initState = {
  visibleEditAddress: false,
  addressStatus: SUB_STEP_STATUS.CLOSE,
  addressEditData: null,
  internalSelectedAddress: null
}

const AddressSelectionForm = ({
  onCancel,
  onAddAddress,
  onEdit,
  onChangeSelectedAddress,
  onDeleteAddress,
  onEditAddress,
  status,
  title,
  form,
  country,
  selectedAddress,
  addresses,
  isLoading
}: SenderInfoProps) => {
  const [state, setState] = useState(initState)
  const { addressStatus, addressEditData, internalSelectedAddress } = state
  const intl = useIntl()

  useEffect(() => {
    setState(prevState => ({ ...prevState, internalSelectedAddress: selectedAddress }))
  }, [selectedAddress])

  const handleValidateFields = useCallback(() => {
    form.validateFields((err, values) => {
      if (!err) {
        const newState = {
          addressStatus: SUB_STEP_STATUS.IN_LIST
        }
        if (addressStatus === SUB_STEP_STATUS.IN_ADDING) {
          const adr = {
            ...values,
            address1: values.keyword,
            id: !addresses.length ? 0 : max(addresses.map(add => add.id)) + 1
          }
          onAddAddress(adr)
        }
        if (addressStatus === SUB_STEP_STATUS.IN_EDTING) {
          const adr = {
            ...values,
            id: addressEditData.id,
            address1: values.keyword
          }
          newState['addressEditData'] = null
          newState['internalSelectedAddress'] = internalSelectedAddress?.id === adr.id ? adr : internalSelectedAddress
          onEditAddress(adr)
        }
        setState(prevState => ({
          ...prevState,
          ...newState
        }))
      }
    })
  }, [addressEditData?.id, addressStatus, addresses, form, internalSelectedAddress, onAddAddress, onEditAddress])

  const handleAddAnother = () => {
    setState(prevState => ({ ...prevState, addressStatus: SUB_STEP_STATUS.IN_ADDING }))
  }

  const getSubtitle = () => {
    if (addressStatus === SUB_STEP_STATUS.IN_ADDING || !addresses.length) {
      return 'create_address'
    }
    if (addressStatus === SUB_STEP_STATUS.IN_EDTING) {
      return 'edit_address'
    }
    return 'select_address'
  }

  const handleDeleteAddress = addressId => {
    onDeleteAddress(addresses.find(add => add.id === addressId))
    // Check whether delete selected address
    if (internalSelectedAddress.id === addressId) {
      setState(prevState => ({ ...prevState, internalSelectedAddress: undefined }))
    }
  }

  const handleEditAddress = addressId => {
    setState(prevState => ({
      ...prevState,
      addressStatus: SUB_STEP_STATUS.IN_EDTING,
      addressEditData: addresses.find(add => add.id === addressId)
    }))
  }

  const handleEditStep = () => {
    setState(prevState => ({
      ...prevState,
      addressStatus: SUB_STEP_STATUS.IN_LIST
    }))
    onEdit()
  }

  const handleSaveAddress = () => {
    if (addressStatus === SUB_STEP_STATUS.IN_LIST) {
      if (!internalSelectedAddress) {
        message.error(intl.formatMessage({ id: 'select_or_create_address' }))
      } else {
        onChangeSelectedAddress(internalSelectedAddress)
      }
    }
    if (addressStatus === SUB_STEP_STATUS.IN_ADDING || addressStatus === SUB_STEP_STATUS.IN_EDTING) {
      handleValidateFields()
    }
  }

  const handleCancelAddress = () => {
    if (addressStatus === SUB_STEP_STATUS.IN_ADDING || addressStatus === SUB_STEP_STATUS.IN_EDTING) {
      setState(prevState => ({ ...prevState, addressStatus: SUB_STEP_STATUS.IN_LIST, addressEditData: null }))
    } else {
      onCancel()
    }
  }

  const handleChangeItemList = indicatedAddressId => {
    const address = addresses.find(add => add.id === indicatedAddressId)
    setState(prevState => ({ ...prevState, internalSelectedAddress: address, visibleEditAddress: false }))
  }

  const renderActive = () => {
    if (addressStatus === SUB_STEP_STATUS.IN_ADDING || addressStatus === SUB_STEP_STATUS.IN_EDTING) {
      return <EditAddress form={form} address={addressEditData} country={country} />
    }
    return (
      <DataListWrapper>
        <DataList
          renderRow={renderAddressText}
          data={addresses}
          selectedId={internalSelectedAddress?.id}
          onDelete={debounce(handleDeleteAddress, PICKUP_ADDRESS.DELAY_TIME)}
          onEdit={debounce(handleEditAddress, PICKUP_ADDRESS.DELAY_TIME)}
          onChange={handleChangeItemList}
        />
      </DataListWrapper>
    )
  }

  const renderAddressText = row => {
    return <AddressText {...row} />
  }

  const renderPreview = useMemo(
    () => () => {
      if (isLoading) {
        return (
          <SpinWrapperStyled>
            <Spin />
          </SpinWrapperStyled>
        )
      }
      return (
        <>
          {selectedAddress ? <AddressText {...selectedAddress} /> : <Preview title={<T id='no_address' />} disabled />}
        </>
      )
    },
    [isLoading, selectedAddress]
  )

  return (
    <OCSubStep
      status={status}
      title={title}
      subtitle={getSubtitle()}
      icon={faWarehouseAlt}
      renderActive={renderActive}
      renderPreview={renderPreview}
      onSave={handleSaveAddress}
      onEdit={handleEditStep}
      onCancel={handleCancelAddress}
      onAddAnother={
        addressStatus !== SUB_STEP_STATUS.IN_ADDING && addressStatus !== SUB_STEP_STATUS.IN_EDTING
          ? handleAddAnother
          : null
      }
      onChange={handleChangeItemList}
    />
  )
}

const AddressSelection = compose(Form.create())(React.memo(AddressSelectionForm))

export { AddressSelection }
