import { useSelector } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import { selectIsAuthenticated } from '../Base/selectors'
import { Navigate, useLocation } from 'react-router-dom'
import { ROUTES } from '../Base/constants'

export const SignInWrapper = ({ children, isOauth }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated())
  const { search } = useLocation()

  return isAuthenticated ? (
    <Navigate to={isOauth ? `${ROUTES.OAUTH_SELECT_SHIPPER}${search}` : ROUTES.HOME} replace />
  ) : (
    children
  )
}

SignInWrapper.propTypes = {
  children: PropTypes.node,
  isOauth: PropTypes.bool
}
