import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'

const { Creators, Types } = createActions(
  {
    ackMessage: ['shipperId', 'messageKey'],
    setMessages: ['shipperId', 'messages']
  },
  { prefix: 'Base/message/' }
)

export const MessageCreators = Creators

const INITIAL_STATE = fromJS({
  acks: {},
  messages: {}
})

export const messageReducer = createReducer(INITIAL_STATE, {
  [Types.ACK_MESSAGE]: (state, data) => {
    return state.setIn(
      ['acks', data.shipperId],
      Object.assign({}, state.getIn(['acks', data.shipperId]), { [data.messageKey]: true })
    )
  },
  [Types.SET_MESSAGES]: (state, data) => {
    return state.setIn(['messages', data.shipperId], data.messages)
  }
})
