/*
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react'
import { IntlProvider } from 'react-intl'
import IntlGlobalProvider from 'containers/IntlGlobalProvider'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import PropTypes from 'prop-types'
import { makeSelectLocale } from './selectors'

export class LanguageProvider extends React.PureComponent {
  render () {
    const { locale, messages, children } = this.props
    // set default local to avoid unnecesasry warnings
    return (
      <IntlProvider key={locale} locale={locale.substring(0, 2)} messages={messages[locale]} defaultLocale='en'>
        <IntlGlobalProvider>{React.Children.only(children)}</IntlGlobalProvider>
      </IntlProvider>
    )
  }
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.any,
  children: PropTypes.node
}

const mapStateToProps = createSelector(makeSelectLocale(), locale => ({
  locale
}))

export default connect(mapStateToProps)(LanguageProvider)
