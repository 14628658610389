import _ from 'lodash'
import { createSelector } from 'reselect'
import { SelectorUtils } from '@nv/react-commons/src/Utils'
import { STATUS } from './constants'
const { selector } = SelectorUtils

export const selectPrinterList = () => createSelector(selector('global', 'printer', 'data')(), data => _.map(data))

export const selectPrinter = () =>
  createSelector(selector('global', 'printer')(), printer => printer?.data?.[printer.id])

export const selectIsPrinterOnline = () =>
  createSelector(selector('global', 'printer')(), printer => printer?.data?.[printer.id]?.status === STATUS.ONLINE)
