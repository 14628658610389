import React from 'react'
import styled from 'styled-components'
import { LogoProps } from './AuthLayout.interface'
import NinjaVanLogoColored from 'images/ninjadash-logo-white-bg.svg'
import NinjaVanLogoWhite from 'images/ninjadash-logo-black-bg.svg'

export const Logo = ({ className, alt = 'ninja van', ...rest }: LogoProps) => {
  return (
    <Wrapper className={className}>
      <img {...rest} alt={alt} />
    </Wrapper>
  )
}

const Wrapper = styled.picture`
  display: block;
`

export const LogoDesktop = styled(Logo).attrs(() => ({
  src: NinjaVanLogoWhite,
  width: 120,
  height: 32
}))``

export const LogoMobile = styled(Logo).attrs(() => ({
  src: NinjaVanLogoColored,
  width: 100,
  height: 27
}))``
