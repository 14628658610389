import _ from 'lodash'
import { fns } from './shared'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { MessageCreators } from 'containers/Base/redux'
import { useIntl, useMemoSelector } from 'hooks/common'
import { selectCountry, selectMessageAcks, selectMessages } from 'containers/Base/selectors'

export function useFirstMessage (shipperId) {
  const dispatch = useDispatch()
  // Per shipper
  const messages = useMemoSelector(selectMessages)
  const acks = useMemoSelector(selectMessageAcks)

  const firstMessage = useMemo(() => {
    if (!_.isArray(messages)) {
      return null
    }
    const sorted = messages.sort((a, b) => {
      if (a.startDate === b.startDate) {
        return 0
      }
      return a.startDate > b.startDate ? 1 : -1
    })

    for (let i = 0; i < sorted.length; i++) {
      const key = fns.getMessageKey(sorted[i])
      if (!acks?.[key]) {
        return sorted[i]
      }
    }
    return null
  }, [messages, acks])

  return [firstMessage, msgs => dispatch(MessageCreators.setMessages(shipperId, msgs))]
}

export function useReduxHelpers () {
  const intl = useIntl()
  const dispatch = useDispatch()
  const country = useMemoSelector(selectCountry)
  return [intl, dispatch, country]
}
