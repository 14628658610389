/**
 *
 * Asynchronously loads the component for Sider
 *
 */

import React from 'react'
import loadable from 'utils/loadable'
import { Loading } from '../Loading'

export const Sider = loadable({
  loader: () => import('./index'),
  loading: <Loading />
})
