export default {
  SHOULD_DISPLAY_API_AND_WEBHOOK: 'ShouldDisplayApiAndWebhookSettings',
  COD_STATS: 'CODStats',
  OLD_COD_STATS: 'OldCODStats',
  REMITTANCE_HISTORY: 'RemittanceHistory',
  VIEW_COD_REPORT: 'CODReport',
  EBER_LOYALTY_SSO: 'EberLoyaltySSO',
  GRANULAR_ADDRESS_FIELDS: 'GranularAddressFields',
  BANK_ACCOUNT_DETAILS: 'BankAccountDetails',
  REPORT_SCHEDULES: 'ReportSchedules',
  MERGE_LITE_AND_MOBILE_FEATURES: 'MergeLiteMobileFeatures',
  ENABLE_EKYC: 'EnableEKYC',
  DISABLE_OC_FOR_EKYC_INCOMPLETE: 'DisableOCForEKYCIncomplete',
  ENABLE_HELPDESK: 'EnableHelpdesk',
  DISABLED_SAME_DAY_PICKUP: 'DisabledSameDayPickup',
  ENABLE_HELPDESK_INVOICE_DISPUTE: 'EnableHelpdeskInvoiceDispute',
  SMART_PASTE_ADDRESS: 'SmartPasteAddress',
  NINJA_BUDDIES_REFERRAL: 'NinjaBuddiesReferral',
  HIDE_YOU_TRANSFERRED: 'HideYouTransferred',
  REFERENCE_PERIOD: 'ReferencePeriod',
  BOUNCED_RETRY_PAYMENTS: 'BouncedRetryPayments',
  REFERRED_MOBILE_NUMBER: 'ReferredMobileNumber',
  AUTOMATE_DELAYED_DELIVERY_REQUESTS: 'AutomateDelayedDeliveryRequests',
  SALESFORCE_CHAT: 'SalesforceChat',
  VIEW_COD_AND_FEES: 'ViewCODAndFees',
  PORTRAIT_WAYBILL: 'PortraitWaybill'
} as const
