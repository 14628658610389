/* eslint-disable camelcase */
import React from 'react'
import styled from 'styled-components'
import { T } from '@nv/react-commons/src/Components'
import Colors from '@nv/react-commons/src/Themes/Colors'
import { Text } from 'components/Text'
import { useLocation } from 'react-router-dom'
import { RouteModal } from 'components/RouteModal'
import { FormattedMessage } from 'react-intl'
import { Formik, Form } from 'formik'
import { TextInputField } from 'components/TextInputField'
import { SubmitButton } from 'components/SubmitButton'
import { EKYC_ACCEPTED_FILE_TYPES, EKYC_MODAL_PATH, EKYC_UPLOAD_MODEL, EKYC_UPLOAD_SCHEMA } from './EKYCModal.constants'
import 'antd/lib/upload/style'
import { getIcNumberMaxLengthByCountry } from './EKYCModal.helpers'
import { useDispatch, useSelector } from 'react-redux'
import { selectCountry, selectMissingEInvoiceDetails, selectShipper } from 'containers/Base/selectors'
import { dashApi } from 'services/api'
import { useIntl, useNavigateWithState, useToast } from 'hooks'
import { ROUTES } from 'containers/Base/constants'
import { ApiHelper } from '@nv/react-commons/src/Services'
import { DraggableUploadField } from '../../components/DraggableUploadField'
import { getBase64 } from '../../components/DraggableUploadField/DraggableUploadField.helpers'
import { shipperCreators } from 'containers/Base/redux'
import { EKYC_STAGE } from 'enums/ekycStage'
import { mixpanelTrackAddedKyc } from 'components/Mixpanel/helpers'
import { getModalPath } from 'utils/getModalPath'
import { ADD_ACCOUNT_DETAILS_PATH } from 'containers/AddAccountDetailsModal/AddAccountDetailsModal.constants'
import { ACCOUNT_UPDATE_SUCCESS_PATH } from 'containers/AccountUpdateSuccessModal/AccountUpdateSuccessModal.constants'

export const EKYCModal = () => {
  const dispatch = useDispatch()
  const navigate = useNavigateWithState()
  const location = useLocation()
  const intl = useIntl()
  const country = useSelector(selectCountry())
  const shipper = useSelector(selectShipper())
  const { showError } = useToast()
  const icNumberLength = getIcNumberMaxLengthByCountry(country)
  const missingEInvoiceDetails = useSelector(selectMissingEInvoiceDetails())

  const onSubmit = async values => {
    const { name, contact, email } = shipper
    const base64File = await getBase64(values.file[0]?.originFileObj)
    const payload = {
      name,
      contactNo: contact,
      email,
      storeName: name,
      icNumber: values.icNumber,
      icImage: base64File,
      icFileName: values.file[0].name
    }

    const response = await dashApi.setup(payload)
    if (!response.ok) {
      const code = response?.data?.error?.code
      showError({ code, customErrors: { [code]: response?.data?.error?.message } })
      return
    }
    mixpanelTrackAddedKyc()
    dispatch(ApiHelper.creators.request('metadata', dashApi.getMetadata))
    // Optimistically update the ekycStage to 'Pending review' in the app state
    // due to potential server-side delays in reflecting this change.
    dispatch(shipperCreators.updateEkycStage(shipper.id, EKYC_STAGE.PendingReview))
    if (missingEInvoiceDetails) {
      navigate(`${ROUTES.HOME}/${ADD_ACCOUNT_DETAILS_PATH}`, { replace: true })
    } else {
      navigate(`${ROUTES.HOME}/${ACCOUNT_UPDATE_SUCCESS_PATH}`, { replace: true })
    }
  }

  return (
    <StyledModal path={getModalPath(location, EKYC_MODAL_PATH)} title={<T id='ic_title' />}>
      <Formik
        initialValues={EKYC_UPLOAD_MODEL}
        validationSchema={EKYC_UPLOAD_SCHEMA(country, intl)}
        onSubmit={onSubmit}
      >
        <FormWrapper>
          <StyledDescription>
            <T id='ic_description' />
          </StyledDescription>
          <TextInputField
            name='icNumber'
            label={<FormattedMessage id='ic_number' />}
            maxLength={icNumberLength[icNumberLength.length - 1]}
          />

          <DraggableUploadField
            label={<FormattedMessage id='ic_photo' />}
            name='file'
            accept={EKYC_ACCEPTED_FILE_TYPES}
            multiple={false}
          />

          <StyledConfirmButton>
            <FormattedMessage id='confirm' />
          </StyledConfirmButton>
        </FormWrapper>
      </Formik>
    </StyledModal>
  )
}

const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: var(--gutter);
`

const StyledModal = styled(RouteModal)`
  && {
    .ant-modal-content {
      border-radius: 10px;
      max-width: 420px;
      margin: auto;

      > div {
        background-color: unset;
      }
    }

    .ant-modal-title {
      border-radius: 10px;
    }

    .ant-upload-drag-container {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      justify-content: center !important;
      height: 80px;
    }

    .ant-upload-text {
      font-size: 14px !important;
    }

    .ant-upload-hint {
      font-size: 12px !important;
    }
  }
`

const StyledDescription = styled(Text).attrs({
  color: Colors.balanceTxtBold
})`
  text-align: left;
  white-space: pre-line;
  margin-bottom: 20px;
`

const StyledConfirmButton = styled(SubmitButton)`
  width: 100px;
  align-self: flex-end;
  margin-top: 30px !important;
`
