import { IDENTITY_PROVIDER } from 'constants/identityProvider'
import { createApi } from 'services/utils'
import { ApiResponse } from 'apisauce'
import { UserScopes } from 'services/api/types'

const aaaApi = createApi({ country: 'global', service: 'aaa' })

export default {
  axiosInstance: aaaApi.axiosInstance,
  resetPassword: data => {
    return aaaApi.post('1.0/tickets/reset-password', {
      ...data
    })
  },
  changePassword: data => {
    return aaaApi.post('1.0/change-password', {
      provider: IDENTITY_PROVIDER.NV_USERNAME_PASSWORD,
      ...data
    })
  },
  getAuthClientName: clientId => {
    return aaaApi.get(`1.1/oauth2-clients/client-id/${clientId}`)
  },
  getUserScopes: (): Promise<ApiResponse<UserScopes>> => {
    return aaaApi.get('/1.0/external/userscopes')
  }
}
