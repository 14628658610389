import styled from 'styled-components'
import { T, Icon } from '@nv/react-commons/src/Components'
import { Text } from 'components/Text'
import { Colors } from 'themes'
import { faShoppingBag } from '@fa-pro-regular/faShoppingBag'
import { faChevronRight } from '@fa-pro-regular/faChevronRight'
import React from 'react'
import PropTypes from 'prop-types'

const StyledLink = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding-top: 10px;
`

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`

const StyledText = styled(Text)`
  font-weight: bold;
  color: ${Colors.greyishBrownTwo};
  margin-right: 4px;
`

export function NinjaPackLink (props) {
  const { packLink } = props
  return (
    <StyledLink
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        window.open(packLink)
      }}
    >
      <StyledIcon icon={faShoppingBag} type='medium' color={Colors.cherryRed} />
      <StyledText>
        <T id='get_ninja_packs_here' />
      </StyledText>
      <StyledIcon icon={faChevronRight} color={Colors.pinkishGrey} type='small' />
    </StyledLink>
  )
}

NinjaPackLink.propTypes = {
  packLink: PropTypes.string
}
