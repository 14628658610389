import _ from 'lodash'

function mapper (value, transformCase) {
  return _.isObject(value) ? toAnyCase(value, transformCase) : value
}

function toAnyCase (data, transformCase) {
  const mapValues = value => mapper(value, transformCase)
  if (_.isArray(data)) {
    return data.map(mapValues)
  } else if (_.isObject(data) || data === null) {
    return _.mapKeys(_.mapValues(data, mapValues), (value, key) => transformCase(key))
  } else {
    return data
  }
}

function toSnakeCase (data) {
  return toAnyCase(data, str =>
    str.replace(/[A-Z](?![0-9]$)/g, letter => `_${letter.toLowerCase()}`).replace(/(\d)$/, '_$1')
  )
}

function toCamelCase (data) {
  return toAnyCase(data, str =>
    str
      .replace(/_[a-z\d]/g, match => match.toUpperCase().replace('_', ''))
      .replace(/(\d)(?=[a-zA-Z])/g, '$1')
      .replace(/^([A-Z])/, (match, p1) => p1.toLowerCase())
  )
}

export { toSnakeCase, toCamelCase }
