/**
 *
 * CenterText
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Text } from '../Text'

const StyledCenterText = styled(Text)`
  text-align: center;
`

const CenterText = props => <StyledCenterText {...props} />

CenterText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}

CenterText.defaultProps = {
  children: 'CenterText'
}

export { CenterText }
