import { translationMessages } from 'i18n.js'

export function getConfig (locale = 'en-sg') {
  const lang = locale.split('-')[0]
  return {
    locale: lang,
    messages: translationMessages[locale],
    v2: {
      siteKey: __CONFIG__.NV_RECAPTCHA_V2_SITEKEY
    },
    v3: {
      siteKey: __CONFIG__.NV_RECAPTCHA_V3_SITEKEY,
      // Please note that can not load https://www.google.com in CN.
      // That why we still keep to use https://www.recaptcha.net
      url: `https://www.recaptcha.net/recaptcha/api.js?hl=${lang}&render=`
    }
  }
}
