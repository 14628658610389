import apisauce, { ApiResponse } from 'apisauce'
import { DataUtils } from '@nv/react-commons/src/Utils'
import Config from 'configs'
import { getSafeUrl } from 'services/utils'
import { CampaignBanner } from './types'

const websiteApi = apisauce.create({
  baseURL: getSafeUrl(`${Config.WEBSITE_CDN_URL}/`),
  timeout: 10000
})

websiteApi.addResponseTransform(response => {
  response.data = DataUtils.toCamelCase(response.data)
})

const getDashAlerts = (locale: string) => websiteApi.get(`${locale}/dash-alerts.json`)
const getDashBanners = (locale: string) => websiteApi.get(`${locale}/dash-banners.json`)
const getDashCampaigns = (locale: string): Promise<ApiResponse<CampaignBanner[]>> =>
  websiteApi.get(`${locale}/dash-web-campaigns.json`)

export default {
  getDashAlerts,
  getDashBanners,
  getDashCampaigns
}
