/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { ApiHelper, ErrorHandler } from '@nv/react-commons/src/Services'
import globalReducer from 'containers/Base/reducers'
import { startupReducer, userTypes } from 'containers/Base/redux'
import { languageReducer } from 'containers/LanguageProvider/redux'
import { allOrdersHistoryReducer, orderHistoryReducer } from 'containers/OrderHistory/redux'
import { combineReducers } from 'redux-immutable'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer (injectedReducers) {
  const appReducer = combineReducers({
    startup: startupReducer,
    global: globalReducer,
    language: languageReducer,
    entity: ApiHelper.reducer,
    orderHistory: orderHistoryReducer,
    allOrdersHistory: allOrdersHistoryReducer,
    error: ErrorHandler.reducer,
    ...injectedReducers
  })

  return (state, action) => {
    if (action.type === userTypes.LOGOUT) {
      state = undefined
    }
    return appReducer(state, action)
  }
}
