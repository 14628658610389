import { COUNTRIES } from '@nv/react-commons/src/Constants'
import _ from 'lodash'
import Config from 'configs'

const NV_SUPPORTED_COUNTRIES = [
  COUNTRIES.SG,
  COUNTRIES.MY,
  COUNTRIES.ID,
  COUNTRIES.VN,
  COUNTRIES.PH,
  COUNTRIES.TH,
  COUNTRIES.MM
]

const NINJACHAT_LITE_SUPPORTED_COUNTRIES = [COUNTRIES.SG, COUNTRIES.MY, COUNTRIES.PH]
const NINJACHAT_PRO_SUPPORTED_COUNTRIES = [COUNTRIES.MY, COUNTRIES.ID, COUNTRIES.SG]
const COUNTRIES_SHOW_COD = [COUNTRIES.TH, COUNTRIES.MY, COUNTRIES.ID, COUNTRIES.SG]
const NV_SIGN_UP_LITE_COUNTRIES = [COUNTRIES.SG, COUNTRIES.MY, COUNTRIES.PH]
const NV_SIGN_UP_PRO_COUNTRIES = [
  COUNTRIES.SG,
  COUNTRIES.MY,
  COUNTRIES.ID,
  COUNTRIES.VN,
  COUNTRIES.PH,
  COUNTRIES.TH,
  COUNTRIES.MM
]

const NV_SS_SUPPORTED_COUNTRIES_SIGN_UP = [COUNTRIES.SG, COUNTRIES.MY, COUNTRIES.PH]
const NV_SS_SUPPORTED_COUNTRIES_LOGIN = [
  COUNTRIES.SG,
  COUNTRIES.MY,
  COUNTRIES.PH,
  COUNTRIES.VN,
  COUNTRIES.TH,
  COUNTRIES.ID
]

function getSystemConfig (env) {
  switch (env) {
    case 'cd':
      return {
        SUPPORTED_COUNTRIES: [COUNTRIES.MA],
        PASSWORD_COUNTRIES: [COUNTRIES.MA],
        LITE_COUNTRIES: [],
        NINJACHAT_LITE_COUNTRIES: [],
        NINJACHAT_PRO_COUNTRIES: [],
        COUNTRIES_SHOW_COD: COUNTRIES_SHOW_COD,
        NV_SIGN_UP_LITE_COUNTRIES,
        NV_SIGN_UP_PRO_COUNTRIES,
        NV_SS_SUPPORTED_COUNTRIES_SIGN_UP: [],
        NV_SS_SUPPORTED_COUNTRIES_LOGIN: []
      }
    default:
      return {
        SUPPORTED_COUNTRIES: NV_SUPPORTED_COUNTRIES,
        PASSWORD_COUNTRIES: [COUNTRIES.SG, COUNTRIES.MY, COUNTRIES.PH],
        LITE_COUNTRIES: _.without(NV_SUPPORTED_COUNTRIES, COUNTRIES.MM, COUNTRIES.TH, COUNTRIES.VN, COUNTRIES.ID),
        NINJACHAT_LITE_COUNTRIES: NINJACHAT_LITE_SUPPORTED_COUNTRIES,
        NINJACHAT_PRO_COUNTRIES: NINJACHAT_PRO_SUPPORTED_COUNTRIES,
        COUNTRIES_SHOW_COD: COUNTRIES_SHOW_COD,
        NV_SIGN_UP_LITE_COUNTRIES,
        NV_SIGN_UP_PRO_COUNTRIES,
        NV_SS_SUPPORTED_COUNTRIES_SIGN_UP,
        NV_SS_SUPPORTED_COUNTRIES_LOGIN
      }
  }
}

const env = Config.ENVIRONMENT

export default getSystemConfig(env)
