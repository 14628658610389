import { serviceLevelToSlaDays } from 'containers/DeliveryType/constants'
import _ from 'lodash'

export const getDefaultServiceLevel = serviceLevels => {
  return _.reduce(
    serviceLevels,
    (maxSlaDayService, service) => {
      const serviceDay = serviceLevelToSlaDays?.[service] ?? -1
      const maxServiceDay = serviceLevelToSlaDays?.[maxSlaDayService] ?? -1
      return serviceDay > maxServiceDay ? service : maxSlaDayService
    },
    ''
  )
}
