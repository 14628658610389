export const GA_EVENT_NAME = {
  INTERACTIONS: 'interactions'
}

export const POST_LOGIN_LABEL = 'post_login'

export const LOGIN_HEADER = {
  action: 'login_header'
}

export const LOGIN = {
  action: 'login_submit',
  label: 'login'
}

export const FORGOT_PASSWORD = {
  action: 'login_fpwd',
  label: 'forgot your password?'
}

export const LOGIN_REGISTER = {
  action: 'login_reg_button',
  label: 'register here'
}

export const PRO_REGISTER = {
  action: 'pro_',
  label: 'contact us'
}

export const PRO_ALREADY_HAVE_ACCOUNT = {
  action: 'pro_reg_already_account',
  label: 'already have an account?'
}

export const LITE_REGISTER = {
  action: 'lite_',
  label: 'register'
}

export const LITE_ALREADY_HAVE_ACCOUNT = {
  action: 'lite_reg_already_account',
  label: 'already have an account?'
}

export const SUBMIT_FORGOT_PASSWORD = {
  action: 'submit_fpwd',
  label: 'submit'
}

export const INVALID_LOGIN = {
  action: 'invalid_login',
  label: 'login'
}

export const INVALID_REGISTER = {
  action: 'invalid_register',
  label: 'register'
}

export const INVALID_CONTACT_US = {
  action: 'invalid_contact_us',
  label: 'contact us'
}

export const NEW_ORDER = {
  label: 'new order',
  isPostLogin: true
}

export const TRACK_PARCELS = {
  label: 'track parcels',
  isPostLogin: true
}

export const CREATE_ORDER = {
  label: 'create order',
  isPostLogin: true
}

export const CREATE_REGULAR_ORDER = {
  label: 'create a regular order',
  isPostLogin: true
}

export const CREATE_NINJA_PACK_ORDER = {
  label: 'create a ninja pack order',
  isPostLogin: true
}

export const CREATE_RETURN_ORDER = {
  label: 'create a return order',
  isPostLogin: true
}

export const GET_NINJA_PACKS = {
  label: 'get ninja packs',
  isPostLogin: true
}

export const NEW_PICKUP = {
  label: 'new pickup',
  isPostLogin: true
}

export const ORDER_COMPLETED = {
  label: 'new_order_completed',
  isPostLogin: true
}

export const RETURN_ORDER_COMPLETED = {
  label: 'return_order_completed',
  isPostLogin: true
}

export const ORDER_FAILED = {
  label: 'new_order_failed',
  isPostLogin: true
}

export const RETURN_ORDER_FAILED = {
  label: 'return_order_failed',
  isPostLogin: true
}

export const REDEEM_NOW = {
  label: 'Redeem Now!',
  isPostLogin: true
}

export const LOG_OUT = {
  label: 'logout',
  isPostLogin: true
}

export const INTERNATIONAL_BETA = {
  label: 'international beta',
  isPostLogin: true
}
