
import styled from 'styled-components'
import { Modal } from 'components/Modal'
import colors from 'themes/Colors'
import { Fonts } from 'themes'
import { media } from '@nv/react-commons/src/Themes/Media'

// match server side
export const MESSAGE_CONTRACT_NOTICE = 'contract_notice'
export const MESSAGE_CONTRACT = 'contract'

export const NOTICE_MODAL_WIDTH = 688
export const MessageModal = styled(Modal).attrs({ zIndex: 1000001 })`
  &&& {
    p {
      font-size: 14px;
      ${Fonts.style.regular}
      line-height: 20px;
      color: ${colors.greyishBrown};
    }

    h3 {
      font-size: 16px;
      line-height: 24px;
      color: ${colors.blueGrey};
      ${Fonts.style.bold}
      margin-bottom: 20px;
    }

    .ant-modal-footer {
      background-color: ${colors.aliceBlue};
      height: auto;

      text-align: right;
      ${media.max.mobile`
        text-align: center;
      `}

      button {
        float: none;
        span {
          letter-spacing: 1.5px;
          text-transform: uppercase;
          font-size: 12px;
          ${Fonts.style.bold}
        }
      }
    }
    .ant-modal-header {
      background-color: ${colors.aliceBlue};

      .ant-modal-title {
        color: ${colors.greyishBrownTwo};
        font-size: 18px;
        ${Fonts.style.bold}
      }
    }
  }
`

export const fns = {
  getMessageKey (message) {
    return `${message.type}_${message.version}`
  }
}
