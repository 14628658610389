import React from 'react'
import { Alert } from '@nv/react-akira'
import { useMaintenanceWarning } from 'hooks/useMaintenanceWarning'

export const TimedAlert = () => {
  const { maintenanceString, alertIsClosed, handleClose } = useMaintenanceWarning()

  if (!maintenanceString || alertIsClosed) return null

  return <Alert message={maintenanceString} onClose={handleClose} variant='info' dismissible />
}
