import React, { useCallback, useEffect, useMemo } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { ApiHelper } from '@nv/react-commons/src/Services'
import shipperAddressApi from 'services/api/shipperAddressApi'
import {
  getAddressDivisionFieldName,
  isDeliveryUnsupportedIncluded,
  isPickupUnsupportedIncluded
} from './AddressDivisions.helpers'
import { SelectorUtils } from '@nv/react-commons/src/Utils'
import {
  AddressDivisionsDispatchProps,
  AddressDivisionsOwnProps,
  AddressDivisionsProps,
  AddressDivisionsStateProps
} from './AddressDivisions.interface'
import { getSupportedLanguage } from 'utils/address'
import { Division } from './AddressDivisions.Fields'
import { AddressDivisionsContext } from './AddressDivisions.context'
import { createStructuredSelector } from 'reselect'

const { selector } = SelectorUtils

export const AddressDivisions = ({
  l1,
  l2,
  l3,
  getDivisions,
  clearDivisions,
  intl,
  country,
  form,
  source,
  selectedDivisionsInitialValue = {},
  FormItemWrapper,
  children
}: AddressDivisionsProps) => {
  const [regionFieldName, localityFieldName, neighbourhoodFieldName] = ([1, 2, 3] as const).map(level =>
    getAddressDivisionFieldName(level, source, country)
  )
  const divisions = { l1, l2, l3 }
  const formValues = form.getFieldsValue()

  const selectedDivisions = useMemo(() => {
    return {
      region: formValues[regionFieldName],
      locality: formValues[localityFieldName],
      neighbourhood: formValues[neighbourhoodFieldName]
    }
  }, [formValues, regionFieldName, localityFieldName, neighbourhoodFieldName])

  const getPreviousSelectedLevels = useCallback(
    (level: number) => {
      const { region, locality } = selectedDivisions
      switch (level) {
        case 2:
          return { region }
        case 3:
          return { region, locality }
      }
    },
    [selectedDivisions]
  )

  useEffect(() => {
    clearDivisions(1, source)
  }, [clearDivisions, source])

  return (
    <AddressDivisionsContext.Provider
      value={{
        intl,
        form,
        country,
        source,
        divisions,
        selectedDivisionsInitialValue,
        getDivisions,
        clearDivisions,
        getPreviousSelectedLevels,
        FormItemWrapper
      }}
    >
      {children}
    </AddressDivisionsContext.Provider>
  )
}

const mapStateToProps = (state, props) =>
  createStructuredSelector<any, AddressDivisionsStateProps>({
    l1: selector('entity', `${props.source}.getL1`)(state),
    l2: selector('entity', `${props.source}.getL2`)(state),
    l3: selector('entity', `${props.source}.getL3`)(state)
  })

const mapDispatchToProps = (dispatch: any): AddressDivisionsDispatchProps => ({
  getDivisions: ({ level, source, country, region, locality }) =>
    dispatch(
      ApiHelper.creators.request(`${source ?? ''}.getL${level}`, shipperAddressApi.getDivisions, [
        {
          language: getSupportedLanguage(country),
          country,
          region,
          locality,
          includeDeliveryUnsupported: isDeliveryUnsupportedIncluded(source),
          includePickupUnsupported: isPickupUnsupportedIncluded(source)
        }
      ] as Parameters<typeof shipperAddressApi.getDivisions>)
    ),
  clearDivisions: (level, source) => {
    // when clearing a higher level data, all lower levels should be cleared as well
    for (let i = level; i <= 3; i++) {
      dispatch(ApiHelper.creators.clear(`${source ?? ''}.getL${i}`))
    }
  }
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default Object.assign(compose<React.FC<AddressDivisionsOwnProps>>(injectIntl, withConnect)(AddressDivisions), {
  Division
})
