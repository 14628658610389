import styled from 'styled-components'
import { Form, Icon, NVInput } from '@nv/react-commons/src/Components'
import { Colors, media } from 'themes'
const { TextArea } = NVInput

export const StyledFormItem = styled(Form.Item).attrs({
  colon: false
})`
  && {
    ${media.min.tablet`
        margin-bottom: 8px;
        margin-right: 8px;
      `};
    ${media.max.tablet`
        margin-bottom: 16px;
      `};
    .ant-form-explain {
      font-size: 11px;
      color: ${Colors.salmon};
      min-height: 0;
    }
    .has-error .ant-select-selection {
      box-shadow: 0 0 5px 0 ${Colors.salmon};
      border: solid 1px ${Colors.pinkishGrey};
    }
    ${props =>
      props.inlineChild &&
      `
    .ant-form-item-control-wrapper {
      display: inline-block;
      vertical-align: middle;
    }`};

    ${props =>
      props.isInlineBlockTitle &&
      `
      .ant-form-item-label {
        display: inline-block;
        width: 100%;
        }
      .ant-form-item-no-colon {
        display: inline-flex;
        flex-wrap: wrap;
        white-space: normal;
        word-break: break-word;
        width: 100%;
        text-align: left;
        margin-top: 7px;
      }
    `};
  }
`
export const StyledIcon = styled(Icon).attrs({
  type: 'small'
})`
  margin-right: 0;
  color: ${Colors.warmGrey};
`

export const StyledErrorMessage = styled.div`
  margin-top: 9px;
  color: ${Colors.salmon};
`

export const StyledTextArea = styled(TextArea).attrs({})`
  margin-top: 0.5em !important;
`
