/* eslint-disable max-len */
import { endsWith, toNumber, mapValues } from 'lodash'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { CsvUtils, ExcelUtils } from '@nv/react-commons/src/Utils'
import {
  DELIVERY_FIELDS,
  ERROR_CODE_MAPPING,
  ERRORS_TRANSLATION_KEYS,
  ERRORS_TRANSLATION_KEYS_FOR_RELABEL,
  FIELD_KEYS,
  INSTRUCTION_DATA,
  INTERNATIONAL_ITEMISED_REQUEST_FIELDS,
  INTERNATIONAL_MY_ITEMISED_REQUEST_FIELDS,
  INTERNATIONAL_REQUEST_FIELDS,
  INTERNATIONAL_SG_ITEMISED_REQUEST_FIELDS,
  ITEM_FIELDS,
  ITEMISED_COUNTRY_CODES,
  MAX_CONTENT_LENGTH,
  MAX_TID_LENGTH,
  MAX_UPLOAD_ORDER_REQUEST,
  MIN_TID_LENGTH,
  MY_ITEM_FIELDS,
  NUMBER_FIELDS,
  NUMBER_OF_USELESS_LINE,
  NUMBER_OF_VALID_ROW_ORDERS,
  ORDER_CREATE_SOURCE,
  PARCEL_DETAIL_FIELDS,
  RULE_TYPES,
  SG_ITEM_FIELDS,
  START_GETTING_DATA_INDEX,
  TO_FIELDS,
  UNIT_WEIGHT,
  VALIDATION_FORM
} from './constants'
import { parseJSONString } from 'containers/FPLOrderRequestList/dataUtils'
import { TRUTHY_FALSY } from 'containers/FPLMixpanel/constants'
import {
  DELIVERY_ADDRESS_BY_COUNTRY,
  PARCEL_INFO_BY_COUNTRY,
  PARCEL_INFO_BY_COUNTRY_FOR_RELABEL,
  PARCEL_INFO_BY_COUNTRY_FOR_RELABEL_FIXED_FORM,
  RECEIPIENT_INFO_BY_COUNTRY
} from 'containers/FPLOrderRequestList/constants'
import { RELABEL_ORDER_FIELD } from 'containers/FPLRelabelOrder/constants'
import { cutPrefix } from 'components/TrackingNumberInput/utils'

// eslint-disable-next-line no-useless-escape
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
export const PHONE_REGEX = /^[+]*\d{6,32}$/
export const NUMBER_REGEX = /^\d+$/
export const FLOAT_NUMBER_REGEX = /^(?!0\d)\d*(\.\d+)?$/
export const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]*$/

const getPostcodeInfo = identifier => {
  const id = identifier && identifier.toLowerCase()
  switch (id) {
    case COUNTRIES.SG:
    case COUNTRIES.VN:
      return 6
    case COUNTRIES.ID:
    case COUNTRIES.MA:
    case COUNTRIES.MY:
    case COUNTRIES.TH:
    case COUNTRIES.MM:
      return 5
    case COUNTRIES.PH:
      return 4
    default:
      return 6
  }
}

const validationRulesByKey = (fieldKey, row, rowIndex, country, isPrefixRequired) => {
  switch (fieldKey) {
    case FIELD_KEYS.REQUESTED_TRACKING_ID:
      return {
        required: isPrefixRequired,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: MIN_TID_LENGTH },
              { type: RULE_TYPES.MAX_LENGTH, validator: MAX_TID_LENGTH }
            ]
          },
          {
            form: VALIDATION_FORM.DUPLICATE,
            group: [{ type: RULE_TYPES.DUPLICATE, validator: 1 }]
          }
        ]
      }
    case FIELD_KEYS.SOURCE_ORDER_ID:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: 255 }
            ]
          }
        ]
      }
    case FIELD_KEYS.TO_NAME:
      return {
        required: true,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: 255 }
            ]
          }
        ]
      }
    case FIELD_KEYS.TO_CONTACT_NUMBER:
      return {
        required: true,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [{ type: RULE_TYPES.REGEX, validator: PHONE_REGEX }]
          }
        ]
      }
    case FIELD_KEYS.TO_ADDRESS:
      return {
        required: true,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: 255 }
            ]
          }
        ]
      }
    case FIELD_KEYS.TO_CITY:
      return {
        required: true,
        exceptRequiredCountry: [COUNTRIES.SG, COUNTRIES.TH],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: 255 }
            ]
          }
        ]
      }
    case FIELD_KEYS.TO_STATE_PROVINCE:
      return {
        required: true,
        exceptRequiredCountry: [COUNTRIES.SG, COUNTRIES.VN],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: 255 }
            ]
          }
        ]
      }
    case FIELD_KEYS.TO_POSTCODE:
      return {
        required: true,
        exceptRequiredCountry: [COUNTRIES.VN, COUNTRIES.ID, COUNTRIES.PH],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: getPostcodeInfo(country) },
              { type: RULE_TYPES.MAX_LENGTH, validator: getPostcodeInfo(country) },
              { type: RULE_TYPES.REGEX, validator: NUMBER_REGEX }
            ]
          }
        ]
      }
    case FIELD_KEYS.TO_EMAIL:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [{ type: RULE_TYPES.REGEX, validator: EMAIL_REGEX }]
          }
        ]
      }
    case FIELD_KEYS.ITEM_DESCRIPTION:
    case FIELD_KEYS.GOOD_DESCRIPTION:
      return {
        required: true,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: MAX_CONTENT_LENGTH }
            ]
          }
        ]
      }
    case FIELD_KEYS.ITEM_VALUE:
    case FIELD_KEYS.GOOD_VALUE:
      return {
        required: true,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN, validator: 1 },
              { type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_REGEX }
            ]
          }
        ]
      }
    case FIELD_KEYS.COD:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN, validator: 0 },
              { type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_REGEX }
            ]
          }
        ]
      }
    case FIELD_KEYS.CONSIGNEE_TAX:
      return {
        required: true,
        exceptRequiredCountry: [],
        rules: []
      }
    case FIELD_KEYS.QUANTITY:
      return {
        required: true,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN, validator: 1 },
              { type: RULE_TYPES.REGEX, validator: NUMBER_REGEX }
            ]
          }
        ]
      }
    case FIELD_KEYS.GST_INCLUDED:
      return {
        required: false,
        exceptRequiredCountry: [
          COUNTRIES.ID,
          COUNTRIES.MA,
          COUNTRIES.MM,
          COUNTRIES.MY,
          COUNTRIES.PH,
          COUNTRIES.TH,
          COUNTRIES.VN
        ],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [{ type: RULE_TYPES.TRUTHY_FALSY }]
          }
        ]
      }
    case FIELD_KEYS.GST_NUMBER:
      return {
        required: row[rowIndex - 1] === TRUTHY_FALSY.YES,
        exceptRequiredCountry: [
          COUNTRIES.ID,
          COUNTRIES.MA,
          COUNTRIES.MM,
          COUNTRIES.MY,
          COUNTRIES.PH,
          COUNTRIES.TH,
          COUNTRIES.VN
        ],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: 255 },
              { type: RULE_TYPES.REGEX, validator: ALPHANUMERIC_REGEX }
            ]
          }
        ]
      }
    case FIELD_KEYS.PARCEL_WEIGHT:
    case FIELD_KEYS.INSURED_VALUE:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.EQUAL_MIN, validator: 0 },
              { type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_REGEX }
            ]
          }
        ]
      }
    case FIELD_KEYS.DELIVERY_INSTRUCTION:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: MAX_CONTENT_LENGTH }
            ]
          }
        ]
      }
    case FIELD_KEYS.LVG_NUMBER:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: 255 },
              { type: RULE_TYPES.REGEX, validator: ALPHANUMERIC_REGEX }
            ]
          }
        ]
      }
    // Relabel fields cases
    case RELABEL_ORDER_FIELD.ORIGIN_TRACKING_ID:
      return {
        required: true,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.DUPLICATE,
            group: [{ type: RULE_TYPES.DUPLICATE, validator: 1 }]
          },
          {
            form: VALIDATION_FORM.DIFFERENT_ORIGIN,
            group: [{ type: RULE_TYPES.IN }]
          }
        ]
      }
    case RELABEL_ORDER_FIELD.NEW_REQUESTED_TRACKING_ID:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              {
                type: RULE_TYPES.MIN_LENGTH,
                validator: MIN_TID_LENGTH
              },
              {
                type: RULE_TYPES.MAX_LENGTH,
                validator: MAX_TID_LENGTH
              }
            ]
          },
          {
            form: VALIDATION_FORM.DUPLICATE,
            group: [{ type: RULE_TYPES.DUPLICATE, validator: 1 }]
          }
        ]
      }
    default:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: []
      }
  }
}

export const handleValidationByType = ({ value, rules, ruleIdx, allValues, presets }) => {
  let invalidValue = null
  const { type, validator } = rules[ruleIdx]
  switch (type) {
    case RULE_TYPES.MIN_LENGTH:
      if (value?.length < validator) {
        invalidValue = ERROR_CODE_MAPPING.INVALID
      }
      break
    case RULE_TYPES.MAX_LENGTH:
      if (value?.length > validator) {
        invalidValue = ERROR_CODE_MAPPING.INVALID
      }
      break
    case RULE_TYPES.REGEX:
      if (!validator.test(value)) {
        invalidValue = ERROR_CODE_MAPPING.INVALID
      }
      break
    case RULE_TYPES.MIN:
      if (value === '' || toNumber(value) < validator) {
        invalidValue = ERROR_CODE_MAPPING.INVALID
      }
      break
    case RULE_TYPES.EQUAL_MIN:
      if (value === '' || toNumber(value) <= validator) {
        invalidValue = ERROR_CODE_MAPPING.INVALID
      }
      break
    case RULE_TYPES.DUPLICATE:
      if (allValues.filter(item => item?.trim() === value).length > validator) {
        invalidValue = ERROR_CODE_MAPPING.DUPLICATE
      }
      break
    case RULE_TYPES.TRUTHY_FALSY:
      if (!Object.values(TRUTHY_FALSY).includes(value)) {
        invalidValue = ERROR_CODE_MAPPING.INVALID
      }
      break
    case RULE_TYPES.IN:
      if (!presets?.includes(value)) {
        invalidValue = ERROR_CODE_MAPPING.DIFFERENT_ORIGIN
      }
  }
  return invalidValue
}

export const getTranslationFromKey = (key, errorCode, countryCode, intl) => {
  if (key === FIELD_KEYS.TO_STATE_PROVINCE && errorCode === ERROR_CODE_MAPPING.REQUIRED) {
    const requiredMess =
      countryCode.toLowerCase() === COUNTRIES.MY
        ? intl.formatMessage(
            { id: 'international_state_is_required_for_country' },
            { country: COUNTRIES.MY.toUpperCase() }
          )
        : intl.formatMessage(
            { id: 'international_province_is_required_for_country' },
            { country: countryCode.toUpperCase() }
          )
    return `${intl.formatMessage({ id: 'international_required_all_except_SG_and_VN' })}\n${requiredMess}`
  }

  const messageId = Object.keys(ERRORS_TRANSLATION_KEYS_FOR_RELABEL).includes(key)
    ? ERRORS_TRANSLATION_KEYS_FOR_RELABEL[key][errorCode]
    : ERRORS_TRANSLATION_KEYS[key][errorCode]
  switch (messageId) {
    case 'international_new_requested_tid_invalid_message_new':
    case 'new_requested_tracking_id_invalid_new':
      return intl.formatMessage(
        { id: messageId },
        {
          min: MIN_TID_LENGTH,
          max: MAX_TID_LENGTH
        }
      )
    default:
      return intl.formatMessage({ id: messageId })
  }
}

const getValueByColumn = (allRows, key, allHeaderFields) => {
  const columnIndex = allHeaderFields.findIndex(templateKey => templateKey === key)
  const allValues = []
  if (columnIndex > -1) {
    allRows.forEach((row, rowIndex) => {
      if (rowIndex > START_GETTING_DATA_INDEX) {
        allValues.push(row[columnIndex])
      }
    })
  }
  return allValues
}

const checkRequiredRule = ({ required, key, value, countryCode, exceptRequiredCountry, intl }) => {
  let errors = []
  const isExcept = !exceptRequiredCountry.includes(countryCode.toLowerCase())
  const isEmptyValue = !value && value !== 0
  if (required && isEmptyValue && isExcept) {
    errors = [...errors, getTranslationFromKey(key, ERROR_CODE_MAPPING.REQUIRED, countryCode, intl)]
  }
  return { errors, isExcept, isEmptyValue }
}

const checkInvalidRule = ({ rules, allRows, key, value, countryCode, isItemised, intl, originTids, isRelabel }) => {
  const allHeaderFields = isRelabel ? Object.values(RELABEL_ORDER_FIELD) : Object.keys(INSTRUCTION_DATA)
  const errorsByRule = []
  rules.map(rule => {
    let ruleIdx = 0
    let isErrorInGroup = false
    while (ruleIdx <= rule.group.length - 1 && !isErrorInGroup) {
      const allValues = rule.form !== VALIDATION_FORM.INVALID ? getValueByColumn(allRows, key, allHeaderFields) : []
      const presets = rule.form === VALIDATION_FORM.DIFFERENT_ORIGIN ? originTids : []
      const invalidRule = handleValidationByType({ value, rules: rule.group, ruleIdx, key, allValues, presets })
      if (invalidRule && !errorsByRule.includes(invalidRule)) {
        switch (invalidRule) {
          case ERROR_CODE_MAPPING.INVALID:
          case ERROR_CODE_MAPPING.DIFFERENT_ORIGIN:
            errorsByRule.push(getTranslationFromKey(key, invalidRule, countryCode, intl))
            isErrorInGroup = true
            break
          case ERROR_CODE_MAPPING.DUPLICATE:
            if (!isItemised) {
              const messageKeyByFieldKey =
                key === RELABEL_ORDER_FIELD.ORIGIN_TRACKING_ID
                  ? 'international_unique_origin_tracking_id'
                  : 'international_unique_tracking_id'
              errorsByRule.push(intl.formatMessage({ id: messageKeyByFieldKey }))
            }
            break
          default:
            break
        }
      }
      ruleIdx++
    }
  })
  return errorsByRule
}

export const validate = ({
  key,
  value,
  countryCode,
  intl,
  allRows = [],
  row,
  rowIndex,
  isItemised,
  originTids,
  isRelabel,
  isPrefixRequired
}) => {
  const trimedValue = value ? `${value}`.trim() : value
  const { required, exceptRequiredCountry, rules } = validationRulesByKey(
    key,
    row,
    rowIndex,
    countryCode,
    isPrefixRequired
  )

  // 1. Check required values by rule
  const { errors: errs, isExcept, isEmptyValue } = checkRequiredRule({
    required,
    key,
    value: trimedValue,
    countryCode,
    exceptRequiredCountry,
    intl
  })
  let errors = errs
  // 2. Check invalid values by rule
  if ((required && isExcept) || !isEmptyValue) {
    const errorsByRule = checkInvalidRule({
      rules,
      allRows,
      key,
      value: trimedValue,
      countryCode,
      isItemised,
      intl,
      originTids,
      isRelabel
    })
    errors = [...errors, ...errorsByRule]
  }
  return errors
}

export const convertRawObjectFromFields = ({
  row,
  fields,
  countryCode,
  intl,
  allRows,
  isItemised,
  isRelabel,
  originTids,
  isPrefixRequired
}) => {
  const result = {}
  fields.forEach((field, index) => {
    result[field] = {
      value: row[index],
      errors: validate({
        key: field,
        value: row[index],
        countryCode,
        intl,
        allRows,
        row,
        rowIndex: index,
        isItemised,
        originTids,
        isRelabel,
        isPrefixRequired
      })
    }
  })
  return result
}

const groupOrderByTid = (allOrdersByKey, groupByField) => {
  return Object.keys(allOrdersByKey).reduce((results, indexKey) => {
    const fieldValue = allOrdersByKey[indexKey][groupByField].value || indexKey
    results[fieldValue] = results[fieldValue] || []
    results[fieldValue].push(indexKey)
    return results
  }, {})
}

const processItemisedOrders = (allOrdersByKey, isRelabel) => {
  const groupByField = isRelabel ? RELABEL_ORDER_FIELD.ORIGIN_TRACKING_ID : FIELD_KEYS.REQUESTED_TRACKING_ID
  const groupsByRequestedTrackingId = groupOrderByTid(allOrdersByKey, groupByField)
  let dataInGroup = []
  const validItemisedOrders = {}
  const invalidItemisedOrders = {}
  let numberValidItemOfValidOrder = 0
  let numberValidItemOfInvalidOrder = 0
  let numberInvalidItemOfInvalidOrder = 0

  Object.keys(groupsByRequestedTrackingId).forEach(tid => {
    groupsByRequestedTrackingId[tid].forEach(rowIndex => {
      dataInGroup.push(Object.values(allOrdersByKey[rowIndex]).every(fieldVal => fieldVal.errors.length === 0))
    })
    const isValid = dataInGroup.every(valid => valid)
    const oneGroup = groupsByRequestedTrackingId[tid].reduce((prevRow, currentRowIdx) => {
      return {
        ...prevRow,
        [currentRowIdx]: allOrdersByKey[currentRowIdx]
      }
    }, {})
    if (isValid) {
      validItemisedOrders[tid] = oneGroup
      numberValidItemOfValidOrder += Object.keys(oneGroup).length
    } else {
      Object.keys(oneGroup).forEach(id => {
        if (Object.values(allOrdersByKey[id]).every(fieldVal => fieldVal.errors.length === 0)) {
          numberValidItemOfInvalidOrder += 1
        } else {
          numberInvalidItemOfInvalidOrder += 1
        }
      })

      invalidItemisedOrders[tid] = oneGroup
    }
    dataInGroup = []
  })
  return {
    validItemisedOrders,
    invalidItemisedOrders,
    numberValidItemOfValidOrder,
    numberValidItemOfInvalidOrder,
    numberInvalidItemOfInvalidOrder
  }
}

export const processUploadedData = ({ file, countryCode, intl, isRelabel, originTids, isPrefixRequired }) => {
  const { data } = file
  if (data.length < NUMBER_OF_VALID_ROW_ORDERS) {
    return { validOrders: {}, invalidOrders: {} }
  }
  const fields = data[0]
  const result = {
    allOrders: {},
    invalidOrders: {},
    validOrders: {},
    validItemisedOrders: {},
    invalidItemisedOrders: {},
    numberValidItemOfValidOrder: 0,
    numberValidItemOfInvalidOrder: 0,
    numberInvalidItemOfInvalidOrder: 0
  }

  // Relabel flow always follow the parcel level
  const isItemised = !isRelabel && Object.keys(ITEMISED_COUNTRY_CODES).includes(countryCode)

  data.forEach((row, rowIndex) => {
    if (rowIndex > START_GETTING_DATA_INDEX) {
      const injectedRow = convertRawObjectFromFields({
        row,
        fields,
        countryCode,
        intl,
        allRows: data,
        isItemised,
        isRelabel,
        originTids,
        isPrefixRequired
      })
      const isIncludeError = Object.values(injectedRow).find(rowItem => rowItem.errors.length > 0)

      result.allOrders[rowIndex] = injectedRow
      result[isIncludeError ? 'invalidOrders' : 'validOrders'][rowIndex] = injectedRow
    }
  })

  if (isItemised) {
    const {
      validItemisedOrders: valItemisedOrder,
      invalidItemisedOrders: invalItemisedOrder,
      numberValidItemOfValidOrder,
      numberValidItemOfInvalidOrder,
      numberInvalidItemOfInvalidOrder
    } = processItemisedOrders(result.allOrders, isRelabel)

    result.validItemisedOrders = valItemisedOrder
    result.invalidItemisedOrders = invalItemisedOrder
    result.numberValidItemOfValidOrder = numberValidItemOfValidOrder
    result.numberValidItemOfInvalidOrder = numberValidItemOfInvalidOrder
    result.numberInvalidItemOfInvalidOrder = numberInvalidItemOfInvalidOrder
  }
  return result
}

const checkWrongTemplate = (fileData, destinationCountry, targetFields) => {
  const headerFields = fileData.data[0] || []
  const instructionData = targetFields[destinationCountry]
  const parcelHeaderKeys = Object.keys(instructionData)

  const isValidHeaderLength = headerFields.length === parcelHeaderKeys.length
  const isValidHeaderName = isValidHeader(headerFields, parcelHeaderKeys)
  return !isValidHeaderLength || !isValidHeaderName
}

export const isValidHeader = (targetArray, originalArray) =>
  originalArray.every((v, index) => targetArray.indexOf(v) === index)

export const getFileDataAndErrorMessages = async (file, destinationCountry, targetFields) => {
  const fileName = file?.name
  let results = { data: [] }
  const errorMsg = []
  if (endsWith(fileName, '.csv')) {
    results = await CsvUtils.parse(file)
  }
  if (endsWith(fileName, '.xlsx') || endsWith(fileName, '.xls')) {
    results = {
      data: await ExcelUtils.parseFile(file)
    }
  }
  if (results.errors && results.errors.length !== 0) {
    errorMsg.push({ id: 'international_advanced_search_broken_file' })
  }
  if (results.data) {
    const maxinumMsg = {
      id: 'international_maximum_request',
      values: { x: results.data.length - NUMBER_OF_USELESS_LINE, y: MAX_UPLOAD_ORDER_REQUEST }
    }
    const emptyMsg = { id: 'international_empty_order' }
    const isWrongTemplate = checkWrongTemplate(results, destinationCountry, targetFields)
    if (isWrongTemplate) {
      errorMsg.push({ id: 'international_order_creation_wrong_template' })
    }

    if (results.data.length <= NUMBER_OF_USELESS_LINE) {
      errorMsg.push(emptyMsg)
    }

    if (results.data.length - NUMBER_OF_USELESS_LINE > MAX_UPLOAD_ORDER_REQUEST) {
      errorMsg.push(maxinumMsg)
    }
  }
  return {
    fileData: results,
    errorMsg,
    fileName
  }
}

export const convertObjectTo2DArray = dataSource => {
  if (!dataSource) return []

  const listKeys = Object.keys(dataSource)
  const listVals = Object.values(dataSource)
  const result = listKeys.map((key, index) => {
    return [{ value: +key + 1, errors: [] }, ...Object.values(listVals[index])]
  })

  return result
}
const parseNumber = value => Number(value)

export const buildObjectForItemLevel = (data, listKeys) => {
  const result = {}
  listKeys.forEach(currentKey => {
    const [, second, third, last] = currentKey.split('.')
    if (third) {
      result[second] = {
        ...result?.[second],
        [third]: {
          ...result?.[second]?.[third],
          [last]: last === 'is_included' ? data[currentKey] === TRUTHY_FALSY.YES : data[currentKey]
        }
      }
    } else {
      const value =
        NUMBER_FIELDS.includes(currentKey) && data[currentKey] ? parseNumber(data[currentKey]) : data[currentKey]
      result[second] = value
    }
  })
  return result
}

export const buildObjectByFields = (data, listKeys, isItemLevel) => {
  if (!isItemLevel) {
    return listKeys.reduce((prevField, currentKey) => {
      const [, last] = currentKey.split('.')
      const value =
        NUMBER_FIELDS.includes(currentKey) && data[currentKey] ? parseNumber(data[currentKey]) : data[currentKey]
      return { ...prevField, [last]: value }
    }, {})
  } else {
    return buildObjectForItemLevel(data, listKeys)
  }
}

const buildItemOrderRequest = (orderRows, originCountry, destinationCountry) => {
  const SGCode = COUNTRIES.SG.toUpperCase()
  const MYCode = COUNTRIES.MY.toUpperCase()
  let itemsedFieldByKeys = {}
  let requestFields = []
  switch (destinationCountry) {
    case SGCode:
      itemsedFieldByKeys = INTERNATIONAL_SG_ITEMISED_REQUEST_FIELDS
      requestFields = SG_ITEM_FIELDS
      break
    case MYCode:
      itemsedFieldByKeys = INTERNATIONAL_MY_ITEMISED_REQUEST_FIELDS
      requestFields = MY_ITEM_FIELDS
      break
    default:
      itemsedFieldByKeys = INTERNATIONAL_ITEMISED_REQUEST_FIELDS
      requestFields = ITEM_FIELDS
      break
  }
  return Object.values(orderRows).map(orderRow => {
    const oneRow = {}
    Object.keys(orderRow).forEach(key => {
      oneRow[itemsedFieldByKeys[key]] = orderRow[key].value
    })
    return {
      ...buildObjectByFields(oneRow, requestFields, true),
      origin_country: COUNTRIES[originCountry]?.toUpperCase(),
      unit_weight: UNIT_WEIGHT
    }
  })
}

const prepareDataRequest = ({ dataObj, service, requestFields, item, prefix = '', isPrefixRequired }) => {
  const isItemised = item?.isItemised ?? false
  const originCountry = service?.origin_country?.toUpperCase()
  const destinationCountry = service?.destination_country?.toUpperCase()
  const serviceCode = service?.code
  const orderRaw = {}
  Object.keys(dataObj).forEach(key => {
    orderRaw[requestFields[key]] = dataObj[key].value
  })
  const toValues = buildObjectByFields(orderRaw, TO_FIELDS)
  const parcelValues = buildObjectByFields(orderRaw, PARCEL_DETAIL_FIELDS)
  const delivery = buildObjectByFields(orderRaw, DELIVERY_FIELDS)
  const orderRequest = {
    source_order_id: dataObj[FIELD_KEYS.SOURCE_ORDER_ID].value ?? null,
    parcel_details: { ...parcelValues, origin_country: COUNTRIES[originCountry]?.toUpperCase() },
    to: { ...toValues, country_code: destinationCountry },
    delivery,
    service_code: serviceCode,
    source: ORDER_CREATE_SOURCE.CSV,
    requested_tracking_id: isPrefixRequired
      ? prefix + cutPrefix(prefix, dataObj[FIELD_KEYS.REQUESTED_TRACKING_ID].value)
      : dataObj[FIELD_KEYS.REQUESTED_TRACKING_ID].value
  }
  if (isItemised) {
    const itemData = item?.itemData ?? []
    const items = buildItemOrderRequest(itemData, originCountry, destinationCountry)
    return {
      ...orderRequest,
      items
    }
  }
  return orderRequest
}

export const buildOrderRequestDataFromBulkUpload = (orders, service, prefix, isPrefixRequired) => {
  const destinationCountry = service?.destination_country?.toUpperCase()
  const SGCode = COUNTRIES.SG.toUpperCase()
  const MYCode = COUNTRIES.MY.toUpperCase()
  const isItemised = Object.keys(ITEMISED_COUNTRY_CODES).includes(destinationCountry)
  let fields = INTERNATIONAL_REQUEST_FIELDS
  if (isItemised) {
    switch (destinationCountry) {
      case SGCode:
        fields = INTERNATIONAL_SG_ITEMISED_REQUEST_FIELDS
        break
      case MYCode:
        fields = INTERNATIONAL_MY_ITEMISED_REQUEST_FIELDS
        break
      default:
        fields = INTERNATIONAL_ITEMISED_REQUEST_FIELDS
        break
    }
    return Object.keys(orders).map(tid => {
      const itemParams = {
        itemData: orders[tid],
        isItemised
      }
      return prepareDataRequest({
        dataObj: Object.values(orders[tid])[0],
        service,
        requestFields: fields,
        item: itemParams,
        prefix,
        isPrefixRequired
      })
    })
  }
  return Object.values(orders).map(order => {
    return prepareDataRequest({ dataObj: order, service, requestFields: fields, prefix, isPrefixRequired })
  })
}

export const appendCurrency = (orders, customCurrency) => {
  if (!customCurrency) return orders
  return orders.map(order => ({
    ...order,
    parcel_details: {
      ...order.parcel_details,
      customs_currency: customCurrency.toUpperCase()
    }
  }))
}

export const parseReturnDataToManualData = returnData => {
  return returnData.map(data => {
    const isItemised = data.items?.length
    const metaData = parseJSONString(data.metadata || '{}')
    let manualData = {
      name: '',
      contactNumber: '',
      city: '',
      stateProvince: '',
      postCode: '',
      addressLine1: '',
      email: '',
      requestedTrackingID: '',
      customsDescription: isItemised ? null : metaData?.customs_description,
      goodsValue: isItemised ? null : metaData?.value,
      cashOnDelivery: '',
      trackingId: data.tracking_id,
      weight: metaData.shipper_submitted_weight
    }

    if (isItemised) {
      const items = data.items.map(item => {
        const itemMetaData = parseJSONString(item.metadata)
        return {
          goodsValue: itemMetaData.unit_value,
          quantityValue: itemMetaData.quantity,
          customsDescription: itemMetaData.description
        }
      })
      manualData = {
        ...manualData,
        items
      }
    }

    return manualData
  })
}

export const transformServiceDetailData = relabelOrder => {
  if (!relabelOrder) return null
  const {
    to_country_code,
    to_address_line1,
    to_address_line2,
    to_name,
    to_postcode,
    to_contact_number,
    to_contact_email
  } = relabelOrder
  return {
    toCountry: to_country_code,
    senderAddress: {
      address1: to_address_line1,
      address2: to_address_line2,
      name: to_name,
      postcode: to_postcode,
      country: to_country_code,
      contact: to_contact_number,
      email: to_contact_email
    }
  }
}

export const getFormFields = (destinationCountry, isReturnOrder, isFixedForm) => {
  let parcelFields = PARCEL_INFO_BY_COUNTRY[destinationCountry]
  if (isReturnOrder) {
    if (isFixedForm) {
      parcelFields = PARCEL_INFO_BY_COUNTRY_FOR_RELABEL_FIXED_FORM[destinationCountry]
    } else {
      parcelFields = PARCEL_INFO_BY_COUNTRY_FOR_RELABEL[destinationCountry]
    }
  }
  return {
    recipient: {
      title: 'recipient_information',
      fields: RECEIPIENT_INFO_BY_COUNTRY[destinationCountry]
    },
    delivery: {
      title: 'address_delivery_instruction',
      fields: DELIVERY_ADDRESS_BY_COUNTRY[destinationCountry]
    },
    parcel: {
      title: 'parcel_information',
      fields: parcelFields
    }
  }
}

export const friendlyErrMessage = (intl, messages) => {
  return messages.map(mes => {
    if (mes.values) {
      return intl.formatMessage(
        {
          id: mes.id
        },
        { x: mes.values.x, y: mes.values.y }
      )
    }
    return intl.formatMessage({ id: mes.id })
  })
}

export const identifyHighValueParcels = ({ service, highValues, orderRequests, isItemised, bulkUpload }) => {
  const limitationOfVariableParcel = highValues.find(d => d.country === service.destination_country)
  if (!limitationOfVariableParcel) {
    return {
      needToContinueWithHighValue: false
    }
  }
  const valuableRequestByIndex = {}
  orderRequests.forEach((orderRequest, index) => {
    const parcelGoodsValue = bulkUpload ? orderRequest.parcel_details.value : orderRequest.goodsValue
    const totalUnitValue = isItemised
      ? orderRequest.items.reduce(
          (preValue, item) => preValue + Number(bulkUpload ? item.unit_value : item.goodsValue) * Number(item.quantity),
          0
        )
      : Number(parcelGoodsValue)
    const isHigherThanMinimiseValue = totalUnitValue >= limitationOfVariableParcel?.value
    if (isHigherThanMinimiseValue) {
      valuableRequestByIndex[index] = {
        totalUnitValue,
        order: orderRequest,
        isHigherThanMinimiseValue
      }
    }
  })
  return {
    standardHighValue: limitationOfVariableParcel?.value,
    valuableRequestByIndex,
    currency: limitationOfVariableParcel?.currency,
    needToContinueWithHighValue: true
  }
}
