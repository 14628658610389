/**
 *
 * SecondaryDivider
 *
 */

import React from 'react'
import styled from 'styled-components'
import { Colors } from 'themes'

const StyledSecondaryDivider = styled.div`
  display: block;
  height: 1px;
  width: 100%;
  margin: 0;
  clear: both;
  border: solid 1px ${Colors.solitude};
`

const SecondaryDivider = props => <StyledSecondaryDivider {...props} />

SecondaryDivider.propTypes = {}

SecondaryDivider.defaultProps = {}

export { SecondaryDivider }
