/**
 *
 * Button
 *
 */

import { withSize } from 'components/BreakpointListener'
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import styled from 'styled-components'
import { Button as NVButton } from 'antd'
import { Icon } from '@nv/react-commons/src/Components'
import { Colors, Fonts } from 'themes'
import _ from 'lodash'

import { faEllipsisV } from '@fa-pro-light/faEllipsisV'
import { faChevronDown } from '@fa-pro-regular/faChevronDown'
import { faChevronUp } from '@fa-pro-regular/faChevronUp'

const fontSizes = {
  large: 13,
  medium: 12,
  small: 10
}
const sizes = {
  large: 1.6,
  medium: 1.5,
  small: 1.2
}
const heights = {
  large: 48,
  medium: 36,
  small: 32
}
const minWidths = {
  large: 128,
  medium: 96,
  small: 96
}
const types = isDesktop => ({
  default: `
    &.ant-btn,
    &.ant-btn-clicked::after {
      border-color: ${Colors.paleGrey};
    }
    &.ant-btn,
    &.ant-btn:focus {
      color: ${Colors.greyishBrown};
    }
    ${isDesktop &&
      `
    &.ant-btn:hover {
      color: ${Colors.pinkishGrey};
    }`}
    &.ant-btn:active {
      color: ${Colors.menu};
      background-color: ${Colors.aliceBlue};
    }
  `,
  primary: `
    &.ant-btn {
      color: ${Colors.white};
      background-color: ${Colors.nvPriRed};
      border-color: ${Colors.nvPriRed};
    }
    ${isDesktop &&
      `&.ant-btn:hover {
      background-color: ${Colors.salmon};
      border-color: ${Colors.salmon};
    }`}
    &.ant-btn:active {
      background-color: ${Colors.nvDarkRed};
      border-color: ${Colors.nvDarkRed};
    }
    &.ant-btn-clicked::after {
      border-color: ${Colors.solitude};
    }
  `,
  secondary: `
    &.ant-btn {
      color: ${Colors.white};
      background-color: ${Colors.greyishBrown};
      border-color: ${Colors.greyishBrown};
    }
    ${isDesktop &&
      `&.ant-btn:hover {
      background-color: ${Colors.warmGrey};
      border-color: ${Colors.warmGrey};
    }`};
    &.ant-btn:active {
      background-color: ${Colors.menu};
      border-color: ${Colors.menu};
    }
    &.ant-btn-clicked::after {
      border-color: ${Colors.greyishBrown};
    }
  `
})

const StyledButton = styled(({ icon, rightIcon, hideAnimation, isDesktop, size, ...rest }) => <NVButton {...rest} />)`
  && {
    text-transform: ${props => (props.noUpperCase ? 'none' : 'uppercase')};
    border-radius: 3px;
    padding: 0 16px;
    letter-spacing: ${props => sizes[props.size]}px;
    font-size: ${props => fontSizes[props.size]}px;
    height: ${props => heights[props.size]}px;
    min-width: ${props => minWidths[props.size]}px;
    ${Fonts.style.bold};
    ${props => types(props.isDesktop)[props.type]};
    ${props =>
      props.icon &&
      `
      padding: 0 8px;
      text-align: left;
      text-transform: capitalize;
      letter-spacing: normal;
      font-size: ${fontSizes.medium}px;
      height: ${heights.medium}px;
      min-width: ${minWidths.medium}px;
      ${Fonts.style.regular};
      ${types(props.isDesktop).default};
      & > span {
        vertical-align: top;
      }
      &.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
        margin-left: -22px;
      }
    `};
    ${props =>
      props.rightIcon &&
      `
      min-width: ${minWidths.large}px;
    `};
    ${props =>
      props.rightIcon === 'ellipsis' &&
      `
      font-size: ${fontSizes.medium}px;
      height: ${heights.medium}px;
      min-width: ${minWidths.medium}px;
      line-height: 1.68;
    `};
    ${props =>
      props.disabled &&
      `
      &&.ant-btn[disabled] {
        color: ${Colors.pinkishGrey};
        background-color: ${Colors.whiteFour};
        border-color: ${Colors.whiteFour};
      }
    `};
    ${props =>
      props.hideAnimation &&
      `
      &.ant-btn-clicked::after {
        animation: none;
      }
    `};
  }
`
const StyledIcon = styled(Icon).attrs({
  gap: 8,
  color: 'inherit'
})``
const StyledRightIcon = styled(StyledIcon)`
  margin-left: 8px;
  margin-right: 0;
  float: right;
`

const Button = buttonProps => {
  const { children, iconStyle, ...props } = buttonProps
  return (
    <StyledButton {..._.omit(props, ['isTablet', 'isMobile'])}>
      {props.icon && !props.loading && <StyledIcon icon={props.icon} style={iconStyle} />}
      {children}
      {props.rightIcon === 'dropdown' && <StyledRightIcon icon={faChevronDown} style={iconStyle} />}
      {props.rightIcon === 'ellipsis' && <StyledRightIcon icon={faEllipsisV} type='medium' style={iconStyle} />}
      {props.rightIcon === 'view_less' && <StyledRightIcon icon={faChevronUp} style={iconStyle} />}
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['default', 'primary', 'secondary']),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  rightIcon: PropTypes.oneOf(['dropdown', 'ellipsis', 'view_less']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  hideAnimation: PropTypes.bool,
  iconStyle: PropTypes.object,
  onClick: PropTypes.func
}

Button.defaultProps = {
  children: 'Button',
  type: 'default',
  size: 'large',
  loading: false,
  disabled: false,
  hideAnimation: false,
  iconStyle: {}
}

export default Button
const _Button = compose(withSize)(Button)
export { _Button as Button }
