/**
 *
 * TrackingNumberFormItem
 *
 */
import { Form, T } from '@nv/react-commons/src/Components'
import { TrackingNumberInput } from 'components/TrackingNumberInput'
import PropTypes from 'prop-types'
import React, { useState, useMemo } from 'react'
import { injectIntl } from 'react-intl'
import { getInitialSelectedPrefix, getTrackingType, getValidationRules } from './utils'
import TrackingNumberValidationMessage from './TrackingNumberValidationMessage'
import { DEFAULT_SETTINGS, TRACKING_TYPES_SETTINGS } from './constants'
import { FIELDS } from 'components/OCForm/constants'

const TrackingNumberFormItem = ({
  trackingId,
  formItem: FormItem,
  form: { getFieldDecorator, getFieldValue, getFieldError },
  intl,
  isNinjaPackOrder,
  isCorpAWBOrder,
  ocSettings,
  masterOcSettings,
  title,
  validationData,
  isValidationLoading,
  ...rest
}) => {
  const isCorporateBranch = ocSettings?.isCorporateBranch
  const trackingType = useMemo(() => getTrackingType(isCorporateBranch, ocSettings), [isCorporateBranch, ocSettings])
  const prefixes = useMemo(() => {
    let shipperPrefixes = ocSettings?.prefixes
    if (ocSettings?.isCorporateBranch || ocSettings?.isMarketplaceSeller) {
      shipperPrefixes = masterOcSettings?.prefixes
    }
    return shipperPrefixes || []
  }, [ocSettings, masterOcSettings])

  const initialPrefix = useMemo(
    () => getInitialSelectedPrefix(isNinjaPackOrder, isCorpAWBOrder, isCorporateBranch, ocSettings),
    /* eslint-disable react-hooks/exhaustive-deps */
    []
  )
  const [selectedPrefix, setSelectedPrefix] = useState(initialPrefix)

  const trackingSettings = TRACKING_TYPES_SETTINGS[trackingType] || DEFAULT_SETTINGS
  const validationRules = getValidationRules(
    isNinjaPackOrder,
    isCorpAWBOrder,
    isCorporateBranch,
    ocSettings,
    trackingSettings,
    intl,
    validationData,
    trackingType,
    selectedPrefix
  )

  const isTrackingNumberRequired = isNinjaPackOrder || isCorpAWBOrder ? true : trackingSettings.required
  const helpEl = (
    <TrackingNumberValidationMessage
      validationData={validationData}
      isValidationLoading={isValidationLoading}
      isNinjaPackOrder={isNinjaPackOrder}
      getFieldValue={getFieldValue}
      getFieldError={getFieldError}
      isCorpAWBOrder={isCorpAWBOrder}
    />
  )

  return (
    <FormItem label={<T id={title || 'order_ref_id'} />} help={helpEl}>
      {getFieldDecorator(FIELDS.REQUESTED_TRACKING_NUMBER, {
        initialValue: trackingId,
        rules: validationRules
      })(
        <TrackingNumberInput
          placeholder={isTrackingNumberRequired ? '' : intl.formatMessage({ id: 'optional' })}
          {...rest}
          id='requestedTrackingNumber'
          trackingType={trackingType}
          prefix={selectedPrefix}
          setSelectedPrefix={setSelectedPrefix}
          prefixes={prefixes}
        />
      )}
    </FormItem>
  )
}

TrackingNumberFormItem.propTypes = {
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  formItem: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  trackingId: PropTypes.string,
  title: PropTypes.string,
  ocSettings: PropTypes.object,
  masterOcSettings: PropTypes.object,
  validationData: PropTypes.any,
  isValidationLoading: PropTypes.object,
  isNinjaPackOrder: PropTypes.bool,
  isCorpAWBOrder: PropTypes.bool
}

TrackingNumberFormItem.defaultProps = {
  formItem: Form.Item,
  trackingId: '',
  title: 'order_ref_id'
}

const _TrackingNumberFormItem = injectIntl(TrackingNumberFormItem)
export { _TrackingNumberFormItem as TrackingNumberFormItem }
