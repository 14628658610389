import { PICKUP_TYPES } from 'containers/PickupType/constants'
import { VolumePicker } from 'components/VolumePicker'
import _ from 'lodash'
import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'

const { Types, Creators } = createActions(
  {
    set: ['key', 'data'],
    storeOrder: ['order'],
    clear: []
  },
  { prefix: 'Base/orderReturn/' }
)

export const orderReturnCreators = Creators
export const orderReturnTypes = Types

const INITIAL_PICKUP_STATE = {
  pickupType: PICKUP_TYPES.SCHEDULED,
  pickupAddress: null,
  reservation: null,
  volume: VolumePicker.LESS_THAN_3_PARCELS
}
const INITIAL_DELIVERY_STATE = {
  returnAddresses: null,
  returnTime: null,
  trackingId: null
}
const INITIAL_ORDER_STATE = {
  order: null,
  orders: null
}

const INITIAL_STATE = fromJS({
  ...INITIAL_PICKUP_STATE,
  ...INITIAL_DELIVERY_STATE,
  ...INITIAL_ORDER_STATE
})

const addressComponents = ['contact', 'name', 'postcode', 'country', 'email', 'address1', 'address2']
const collectWithPrefix = (order, prefix) =>
  _.reduce(
    addressComponents,
    (r, v) => {
      return { ...r, [v]: order[_.camelCase(`${prefix}_${v}`)] }
    },
    {}
  )

const set = (state, { key, data }) => state.merge({ [key]: data })
const clear = state => state.merge(INITIAL_STATE)
const storeOrder = (state, { order }) => {
  return state.merge({
    ...INITIAL_PICKUP_STATE,
    pickupAddress: collectWithPrefix(order, 'to'),
    ...INITIAL_DELIVERY_STATE,
    returnAddress: collectWithPrefix(order, 'from'),
    order
  })
}

export const orderReturnReducer = createReducer(INITIAL_STATE, {
  [Types.SET]: set,
  [Types.CLEAR]: clear,
  [Types.STORE_ORDER]: storeOrder
})
