import React, { useEffect } from 'react'

/**
 * useInterval
 *
 * Ref: https://www.joshwcomeau.com/snippets/react-hooks/use-interval/
 *
 * Example:
 *
 * ```ts
 * const [countdown, setCountdown] = useState(10) // in seconds
 * useInterval(
    () => {
      setCountdown(value => value - 1)
    },
    countdown > 0 ? 1000 : null
  )
 * ```
 */
export const useInterval = (callback: (...args: any) => void, delay: number | null) => {
  const intervalRef = React.useRef(null)
  const savedCallback = React.useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => savedCallback.current()

    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(tick, delay)

      return () => window.clearInterval(intervalRef.current)
    }
  }, [delay])

  return intervalRef
}
