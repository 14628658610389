/**
 *
 * DropZone
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import RctDropzone from 'react-dropzone'
import { Vspace } from 'components/Vspace'
import { Colors } from 'themes'
import { ACCEPT_FILE_TYPES } from 'containers/FPLAllOrders/constants'

const activeClassName = 'dropzone-active'
const hoverStyle = `
   border: dashed 2px ${Colors.secondary};
 `
const StyledDropzone = styled(({ dragging, ...rest }) => <RctDropzone {...rest} />).attrs({
  activeClassName
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ height, unitHeight }) => `${height || 80}${unitHeight || 'px'}`};
  background-color: ${props => props.backgroundColor || Colors.aliceBlue};
  border: dashed 2px ${props => props.borderColor || Colors.paleGrey};
  border-radius: 4px;
  cursor: pointer !important;
  transition: all 450ms cubic - bezier(0.23, 1, 0.32, 1);
  ${props => props.dragging && hoverStyle};

  &.${activeClassName} {
    background-color: rgba(247, 247, 247, 1);
    ${hoverStyle};
  }
`

const DropZone = ({ hint, onDrop, prompt, height, styles, excludeFileTypesAllowance }) => {
  const handleDrop = files => {
    const fileUpload = files[0]
    onDrop(fileUpload)
  }

  return (
    <StyledDropzone
      multiple={false}
      onDrop={handleDrop}
      height={height}
      accept={excludeFileTypesAllowance ? null : ACCEPT_FILE_TYPES}
      {...styles}
    >
      {hint}
      <Vspace height={4} />
      {prompt}
    </StyledDropzone>
  )
}

DropZone.propTypes = {
  hint: PropTypes.node,
  onDrop: PropTypes.func.isRequired,
  prompt: PropTypes.node,
  height: PropTypes.number,
  excludeFileTypesAllowance: PropTypes.bool,
  styles: PropTypes.shape({
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string
  }),
  unitHeight: PropTypes.string
}

DropZone.defaultProps = {
  excludeFileTypesAllowance: false
}

export { DropZone }
