import { MOBILE_CUSTOM_SCHEME } from 'utils/constants'

export function getUrlParameter (name, search = window.location.search) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = regex.exec(search)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export function generatePathWithModal (path) {
  return `${path}/*`
}

export const getMobileCustomSchemeWithCurrentLink = () => {
  return `${MOBILE_CUSTOM_SCHEME}://${window.location.host}${window.location.pathname}`
}

export const MAIN_ROUTES = {
  ROOT: '/',
  HOME: '/home',
  INTERNATIONAL: '/international',
  FULL: '/full',
  OAUTH: '/oauth',
  V2: '/v2',
  // This endpoint was added temporarily to provide access to old auth UI
  // TODO: remove once new auth UI is fully complete
  V1: '/v1',
  PUBLIC: '/public'
}

const pathGenerator = main => path => {
  return `${main}/${path}`
}

export const generateRootPath = path => `${MAIN_ROUTES.ROOT}${path}`

export const generateHomePath = pathGenerator(MAIN_ROUTES.HOME)

export const generateOauthPath = pathGenerator(MAIN_ROUTES.OAUTH)

export const generateInternationalPath = pathGenerator(MAIN_ROUTES.INTERNATIONAL)

export const generateFullPath = pathGenerator(MAIN_ROUTES.FULL)

export const generateUnificationPath = pathGenerator(MAIN_ROUTES.V2)

export const generatePublicPath = pathGenerator(MAIN_ROUTES.PUBLIC)
