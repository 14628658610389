import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'

const { Types, Creators } = createActions(
  {
    save: ['id'],
    getPrintersRequest: [],
    getPrintersSuccess: ['data'],
    getPrintersFailure: ['error'],
    printRequest: ['data'],
    updatePrinter: ['id', 'data'],
    clear: []
  },
  { prefix: 'Printers/' }
)

export const printerCreators = Creators
export const printerTypes = Types

const INITIAL_STATE = fromJS({
  id: null,
  data: {},
  loading: false,
  error: null
})

export const printerReducer = createReducer(INITIAL_STATE, {
  [Types.SAVE]: (state, { id }) => state.merge({ id }),
  [Types.GET_PRINTERS_REQUEST]: state => state.merge({ loading: true }),
  [Types.GET_PRINTERS_SUCCESS]: (state, { data }) => state.merge({ loading: false, data }),
  [Types.GET_PRINTERS_FAILURE]: (state, { error }) => state.merge({ loading: false, error }),
  [Types.UPDATE_PRINTER]: (state, { id, data }) => {
    return state.merge({ data: state.get('data').merge({ [id]: data }) })
  },
  [Types.CLEAR]: state => state.merge(INITIAL_STATE)
})
