/**
 *
 * OptionCard
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Card } from '../Card'
import { CircleIcon } from '../CircleIcon'
import { Row } from '@nv/react-commons/src/Components'
import { Text } from '../Text'
import { Colors } from 'themes'
import _ from 'lodash'

const StyledTextBlock = styled.div`
  flex: 1;
  padding-left: 16px;
`

const StyledCard = styled(Card).attrs({
  hoverable: false
})`
  && {
    margin-bottom: 12px;
    border: solid 1px ${Colors.paleGrey};
    ${props =>
      props.disabled &&
      css`
        background: ${Colors.whiteTree};
      `};
  }
`

const OrderTypeLabel = styled(Text).attrs({
  type: 'bold'
})`
  color: ${props => (props.disabled ? Colors.pinkishGrey : Colors.greyishBrown)};
`

const OrderTypeDescription = styled(Text)`
  color: ${Colors.pinkishGrey};
`

class OptionCard extends React.Component {
  render () {
    const { icon, label, description, onClick, disabled } = this.props
    return (
      <StyledCard onClick={!disabled ? onClick : undefined} disabled={disabled}>
        <Row type='flex' align='middle'>
          <CircleIcon icon={icon} size='big' disabled={disabled} />
          <StyledTextBlock>
            <OrderTypeLabel id={label} disabled={disabled} />
            {_.isString(description) ? <OrderTypeDescription id={description} /> : description}
          </StyledTextBlock>
        </Row>
      </StyledCard>
    )
  }
}

OptionCard.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func
}

OptionCard.defaultProps = {
  disabled: false
}

export { OptionCard }
