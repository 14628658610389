/**
 *
 * Address
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Radio, Row, Col } from '@nv/react-commons/src/Components'
import { faTrashAlt } from '@fa-pro-light/faTrashAlt'
import { faSpinner } from '@fa-pro-light/faSpinner'
import { faEdit } from '@fa-pro-light/faEdit'
import { faEye } from '@fa-pro-light/faEye'

import { IconButton } from '../IconButton'
import { Text } from '../Text'
import { media, Colors, Fonts } from 'themes'
import { Divider } from '../Divider'
import { OC_SERVICE_TYPES } from 'containers/Base/constants'

const StyledRow = styled(({ lastSelected, rowType, ...props }) => <Row {...props} />).attrs({ type: 'flex' })`
  && {
    flex-wrap: nowrap;
    transition: all 0.5s;
    ${props => props.lastSelected && 'padding-top: 16px;'};
    ${props =>
      props.rowType === 'compact' &&
      `
    padding-top: 0;
    &:not(first-child) {
      margin-top: -24px;
    }
    &:first-child {
      margin-top: -5px;
    }
  `};
  }
`
const StyledRadio = styled(Radio)`
  && {
    display: flex;
    flex: 1;
    min-width: 0;
    height: ${props => props.height + 'px' || 'auto'};
    line-height: 16px;
    margin-bottom: 0;
    span.ant-radio + * {
      color: ${Colors.greyishBrown};
      ${Fonts.style.bold};
      padding-left: 12px;
    }
  }
`
const StyledDiv = styled.div`
  display: flex;
  flex: 1;
  min-width: 0;
  height: ${props => props.height || 38 + 'px'};
  line-height: 20px;
  margin-bottom: 24px;
  margin-top: 12px;
  flex-direction: column;
`
const StyledTextCol = styled(Col)`
  && {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    min-width: 0;
    overflow: hidden;
  }
`
const StyledActionCol = styled(Col)`
  && {
    padding-top: 24px;
    ${media.min.tablet`margin-left: 24px;`};
    display: flex;
    flex-wrap: nowrap;
  }
`
const StyledText = styled(Text)`
  margin-top: -16px;
  margin-bottom: 4px;
`

const StyledDivider = styled(Divider).attrs({
  direction: 'vertical',
  color: Colors.solitude,
  height: 20,
  thickness: 2
})``

const DataRow = ({
  height,
  value,
  onDelete,
  onEdit,
  onPreview,
  deleting,
  renderRow,
  noRadio,
  previewIcon,
  lastSelected,
  rowType,
  rowClassName,
  actionsClassName,
  textClassName,
  ...props
}) => {
  const Item = noRadio ? StyledDiv : StyledRadio
  const icon = previewIcon || faEye
  return (
    <StyledRow rowType={rowType} lastSelected={lastSelected} className={rowClassName}>
      <StyledTextCol className={textClassName}>
        <Item rowType={rowType} value={value} height={height} disabled={props.type === OC_SERVICE_TYPES.INTERNATIONAL}>
          {lastSelected && <StyledText color={Colors.pinkishGrey} id='last_selected' size={12} type='bold' />}
          {renderRow(props)}
        </Item>
      </StyledTextCol>
      {(onEdit || onDelete || onPreview) && (
        <StyledActionCol className={actionsClassName}>
          {onDelete && (
            <IconButton
              type='medium'
              icon={deleting ? faSpinner : faTrashAlt}
              onClick={onDelete}
              spin={deleting}
              data-analyticsid='deleteDataRow'
            />
          )}
          {onDelete && onEdit && <StyledDivider />}
          {onEdit && <IconButton type='medium' icon={faEdit} onClick={onEdit} data-analyticsid='editDataRow' />}
          {((onDelete && onPreview) || (onEdit && onPreview)) && <StyledDivider />}
          {onPreview && <IconButton type='medium' icon={icon} onClick={onPreview} data-analyticsid='previewDataRow' />}
        </StyledActionCol>
      )}
    </StyledRow>
  )
}

DataRow.propTypes = {
  actionsClassName: PropTypes.string,
  deleting: PropTypes.bool,
  height: PropTypes.number,
  lastSelected: PropTypes.bool,
  noRadio: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onPreview: PropTypes.func,
  previewIcon: PropTypes.object,
  renderRow: PropTypes.func.isRequired,
  rowClassName: PropTypes.string,
  rowType: PropTypes.string,
  textClassName: PropTypes.string,
  value: PropTypes.any
}

DataRow.defaultProps = {
  deleting: false
}

export { DataRow }
