import { ASIAN_COUNTRIES, PARCEL_WEIGHT_OPTIONS_BY_KEY } from './constants'
import { xor } from 'lodash'

export const getSupportAndUnsportedCountries = (availableLanes, originCountries) => {
  const supportedCountries = []
  const estimationByCountry = {}
  availableLanes.forEach(lane => {
    lane.destinations.forEach(code => {
      if (!supportedCountries.includes(code)) {
        supportedCountries.push(code)
      }
    })
    if (lane.estimations) {
      const estimationByDestination = {}
      lane.estimations.forEach(estimation => {
        estimationByDestination[estimation.destination] = { ...estimation, origin: lane.origin }
      })
      estimationByCountry[lane.origin] = estimationByDestination
    }
  })
  const unsupportedCountries = xor([...supportedCountries, ...originCountries], Object.keys(ASIAN_COUNTRIES))
  return {
    supportedCountries,
    unsupportedCountries,
    estimationByCountry
  }
}

export const transformEstimationRates = ({ estimationByCountry, activeSupportedToCountries, weight }) => {
  const rates = []
  const { min, max } = PARCEL_WEIGHT_OPTIONS_BY_KEY[weight].range
  Object.keys(estimationByCountry).forEach(originCountry => {
    activeSupportedToCountries.forEach(destinationCode => {
      const lane = `${originCountry} to ${destinationCode}`
      const estimationByOriginAndDestinationCode = estimationByCountry[originCountry][destinationCode]
      if (estimationByOriginAndDestinationCode) {
        const { currency, price_per_parcel, sla_in_days } = estimationByOriginAndDestinationCode
        const price1 = price_per_parcel.find(pricePerPacel => pricePerPacel.weight === min)?.price
        const price2 = price_per_parcel.find(pricePerPacel => pricePerPacel.weight === max)?.price
        const fromSla = sla_in_days.from
        const toSla = sla_in_days.to
        rates.push({
          lane,
          minPrice: Math.min(price1, price2),
          maxPrice: Math.max(price1, price2),
          fromSla,
          toSla,
          currency
        })
      }
    })
  })
  return rates
}
