/**
 *
 * CollapsiblePanel
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Collapse } from '@nv/react-commons/src/Components'
import { Colors } from 'themes'
import { PanelHeader } from '../PanelHeader'
const { Panel: NVPanel } = Collapse

const StyledCollapse = styled(Collapse)`
  && {
    border: none;
    border-radius: 5px;
    background: white;
    .ant-collapse-content {
      border-top: 1px solid ${Colors.paleGrey};
    }
    .ant-collapse-item .ant-collapse-header .anticon {
      left: initial;
      right: 16px;
      margin-top: 0;
    }
    > .ant-collapse-item:not(.ant-collapse-item-disabled) > .ant-collapse-header:active {
      background-color: ${Colors.aliceBlue};
    }
    > .ant-collapse-item {
      border-bottom: 0;
      > .ant-collapse-header .arrow {
        transform: scale(0.75) rotate(90deg);
      }
      > .ant-collapse-header[aria-expanded='true'] .arrow {
        transform: scale(0.75) rotate(-90deg);
      }
      > .ant-collapse-header,
      > .ant-collapse-header[aria-expanded='true'] {
        padding: 16px;
        .arrow {
          top: 12px;
          left: auto;
          right: 16px;
          font-size: 20px;
          color: ${Colors.pinkishGrey};
        }
      }
    }
    .ant-collapse-content {
      padding: 0;
      > .ant-collapse-content-box {
        padding-top: 16px;
        padding-bottom: 24px;
      }
    }
  }
`

class CollapsiblePanel extends React.Component {
  renderTitle = () => {
    const { title, icon } = this.props
    return <PanelHeader title={title} icon={icon} />
  }

  render () {
    const { defaultIsOpen, children } = this.props
    return (
      <StyledCollapse defaultActiveKey={defaultIsOpen ? 'panel' : null}>
        <NVPanel header={this.renderTitle()} key='panel'>
          {children}
        </NVPanel>
      </StyledCollapse>
    )
  }
}

CollapsiblePanel.propTypes = {
  children: PropTypes.node,
  defaultIsOpen: PropTypes.bool,
  icon: PropTypes.object,
  title: PropTypes.string
}

CollapsiblePanel.defaultProps = {
  defaultIsOpen: false
}

export { CollapsiblePanel }
