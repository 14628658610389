export enum SALES_CHANNEL {
  SelfServe = 'Self Serve',
  FieldSales = 'Field Sales',
  CorpSales = 'Corp Sales',
  NBU = 'NBU',
  CrossBorder = 'Cross Border',
  Partnerships = 'Partnerships',
  Retail = 'Retail',
  ShipperSupport = 'Shipper Support',
  NinjaDirect = 'Ninja Direct',
  Unknown = 'Unknown'
}
