/**
 *
 * AccountEntry
 *
 */

import { Layout, message } from '@nv/react-commons/src/Components'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { NinjaChatWidgetFeatureTourModal, FEATURE_TOUR_STORAGE_KEY } from 'components/NinjaChatWidgetFeatureTourModal'
import { OnboardingProvider } from 'containers/AccountEntry/OnboardingContext'
import { SideNavProvider } from 'containers/AccountEntry/SideNavContext'
import { SelectorUtils } from '@nv/react-commons/src/Utils'
import { selectIsLiteShipper, selectIsMySgLiteShipper, selectUser } from 'containers/Base/selectors'
import Onboarding from 'containers/Onboarding/Loadable'
import ocSaga from 'containers/OrderCreate/saga'
import printerSaga from 'containers/Printers/saga'
import SocketListener from 'containers/SocketListener/Loadable'
import trackingSaga from 'containers/Tracking/saga'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'
import { Colors, media } from 'themes'
import injectSaga from 'utils/injectSaga'
import UserNotice from 'containers/UserMessage'
import { AlertFullWidth } from 'containers/AlertFullWidth'
import systemConfig from '../../configs/systemConfig'
import Config from 'configs'
import NinjaChatWidget from '@nv/react-commons/src/Components/NinjaChatWidget'
import { faTimes, faCommentAltDots } from '@fortawesome/pro-regular-svg-icons'
import { useCookies } from 'react-cookie'
import { COOKIES } from 'containers/AccountEntry/constants'
import { selectShipper } from '../Base/selectors'
import { withSize } from '../../components/BreakpointListener'
import sfiApi from '../../services/api/sfiApi'
import uuid from 'utils/uuid'
import { intl } from '../IntlGlobalProvider'
import { mixpanelTrackOpenedLiveChat } from 'components/Mixpanel/helpers'
import { useIsSalesforceChatEnabled } from 'components/SalesforceChat'
import { useFeatureHub } from 'featureHub/useFlag'
import { TimedAlert } from 'components/TimedAlert'

const { Content } = Layout
const { selector } = SelectorUtils
const { NINJACHAT_LITE_COUNTRIES, NINJACHAT_PRO_COUNTRIES } = systemConfig

const StyledContentLayout = styled(({ isDesktop: _isDesktop, ...props }) => <Layout {...props} />)`
  && {
    background: ${Colors.whiteTree};
  }
`
const StyledHeaderContentLayout = styled(StyledContentLayout)`
  && {
    ${media.min.tablet`
    overflow: hidden;
    `};
    ${media.max.tablet`
    overflow: auto;
    flex: 1 1;
    `};
  }
`
const StyledContent = styled(({ withHeader: _withHeader, ...props }) => <Content {...props} />)`
  && {
    position: relative;
    overflow: hidden;
    background-color: ${Colors.whiteTree};
    display: flex;
    flex-direction: column;
  }
`

const ChatWidget = ({ shipper, isLiteShipper, isMySgLiteShipper, accessToken }) => {
  const shipperId = shipper?.id
  const country = shipper?.country?.toLowerCase()

  if (shipperId) {
    if (
      ((isLiteShipper || isMySgLiteShipper) && NINJACHAT_LITE_COUNTRIES.includes(country)) ||
      (!isLiteShipper && !isMySgLiteShipper && NINJACHAT_PRO_COUNTRIES.includes(country))
    ) {
      const displayError = errorTypes => {
        if (errorTypes === 'unsupported_file_format') {
          message.error(
            intl.formatMessage({
              id: 'types.error.unsupported_file_format'
            }),
            8
          )
        }
        if (errorTypes === 'filesize_too_large') {
          message.error(
            intl.formatMessage({
              id: 'types.error.filesize_too_large'
            }),
            8
          )
        }
      }

      const uploadFunction = async (blobFile, fileName) => {
        const attachmentData = {
          fileName: fileName.slice(0, fileName.lastIndexOf('.')) ?? uuid(),
          fileType: blobFile.type
        }

        const response = await sfiApi.generateUploadUrl(attachmentData)
        if (response.status !== 200) {
          message.error(
            intl.formatMessage({
              id: 'types.error.upload_failure'
            }),
            8
          )
          return
        }
        const { signedUrl, httpPath } = response.data.data
        const responseUpload = await fetch(signedUrl, {
          method: 'PUT',
          body: blobFile,
          header: {
            'Content-Disposition': 'attachment',
            'Content-Type': `${blobFile.type}`
          }
        })
        if (responseUpload.status !== 200) {
          message.error(
            intl.formatMessage({
              id: 'types.error.upload_failure'
            }),
            8
          )
          return
        }

        return {
          type: blobFile.type,
          url: decodeURI(httpPath)
        }
      }

      return (
        <>
          <NinjaChatWidgetFeatureTourModal />
          <NinjaChatWidget
            token={accessToken}
            systemId={country}
            globalShipperId={shipperId}
            phoneNumber={shipper?.contact}
            shipperName={shipper?.name}
            url={
              isLiteShipper || isMySgLiteShipper
                ? Config.SNS_DASH_IO_URL?.trim() ?? ' '
                : Config.SNS_DASH_PRO_IO_URL?.trim() ?? ' '
            }
            openIcon={faCommentAltDots}
            closeIcon={faTimes}
            source={isLiteShipper || isMySgLiteShipper ? 'dash' : 'dash_pro'}
            startEvent='SHIPPER_START_CONVO_EVENT'
            triggerOnInitialLoad={window.localStorage.getItem(FEATURE_TOUR_STORAGE_KEY) !== 'false'}
            uploadFunction={uploadFunction}
            onFileUploadError={displayError}
            maxFileSize={50}
            showUploadAttachment
            handleToggle={isOpen => {
              if (isOpen) {
                mixpanelTrackOpenedLiveChat()
              }
            }}
          />
        </>
      )
    }
  }

  return null
}

ChatWidget.propTypes = {
  shipper: PropTypes.object,
  isLiteShipper: PropTypes.bool,
  isMySgLiteShipper: PropTypes.bool,
  accessToken: PropTypes.string.isRequired
}

const AccountEntry = ({ isLiteShipper, isMySgLiteShipper, shipper, user, accessToken, isDesktop, sider, header }) => {
  const [, setCookies] = useCookies(Object.keys(COOKIES))

  useEffect(() => {
    const cookieOptions = {
      path: '/',
      domain: 'ninjavan.co',
      maxAge: user.expireIn
    }
    setCookies(COOKIES.NV_ACCESS_TOKEN, user.accessToken, cookieOptions)
    setCookies(COOKIES.NV_SHIPPER_ID, user.defaultShipperId, cookieOptions)
  }, [user, setCookies])

  const { loading: featureHubLoading } = useFeatureHub()
  const isSalesforceChatEnabled = useIsSalesforceChatEnabled()

  const ParentLayout = isDesktop ? StyledContentLayout : Fragment
  const BaseContent = isDesktop ? StyledHeaderContentLayout : Fragment
  return (
    <SideNavProvider>
      <OnboardingProvider>
        <Onboarding />
        {shipper && (
          <>
            {shipper?.id && <UserNotice shipperId={shipper?.id} shipperName={shipper?.name} />}
            <ParentLayout>
              {isDesktop && sider}
              <BaseContent>
                {isDesktop && <SocketListener />}
                {header}
                <StyledContent withHeader={!!header}>
                  <AlertFullWidth />
                  <TimedAlert />
                  <ErrorBoundary key={shipper?.id}>
                    <Outlet />
                    {!featureHubLoading && !isSalesforceChatEnabled && (
                      <ChatWidget
                        {...{
                          shipper,
                          isLiteShipper,
                          isMySgLiteShipper,
                          accessToken
                        }}
                      />
                    )}
                  </ErrorBoundary>
                </StyledContent>
              </BaseContent>
            </ParentLayout>
          </>
        )}
      </OnboardingProvider>
    </SideNavProvider>
  )
}

AccountEntry.propTypes = {
  isLiteShipper: PropTypes.bool,
  isMySgLiteShipper: PropTypes.bool,
  isVerified: PropTypes.bool,
  dismissBanner: PropTypes.func.isRequired,
  shipper: PropTypes.object,
  user: PropTypes.shape({
    accessToken: PropTypes.string,
    expireIn: PropTypes.number,
    defaultShipperId: PropTypes.number
  }),
  accessToken: PropTypes.string.isRequired,
  sider: PropTypes.node,
  header: PropTypes.node,
  isDesktop: PropTypes.bool
}

const mapStateToProps = createStructuredSelector({
  user: selectUser(),
  shipper: selectShipper(),
  isLiteShipper: selectIsLiteShipper(),
  isMySgLiteShipper: selectIsMySgLiteShipper(),
  accessToken: selector('global', 'user', 'data', 'accessToken')()
})

const withConnect = connect(mapStateToProps, null)

export default compose(
  withSize,
  // Account specific sagas
  injectSaga({ key: 'orderCreate', saga: ocSaga }),
  injectSaga({ key: 'tracking', saga: trackingSaga }),
  injectSaga({ key: 'printer', saga: printerSaga }),
  withConnect
)(AccountEntry)
