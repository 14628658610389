import _ from 'lodash'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { createApi } from 'services/utils'

const lighthouseApis = _.mapValues(COUNTRIES, country => createApi({ country, service: 'lighthouse' }))

const getBlockedDates = country => {
  return lighthouseApis[country.toUpperCase()].get('/1.0/blocked-dates')
}

export default {
  lighthouseApis,
  getBlockedDates
}
