import { useState, useEffect } from 'react'

// A custom hook that loads a script and provides its load status
export const useScript = (src?: string | null) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)

  useEffect(() => {
    // Function to add the script to the DOM
    const addScriptToDOM = (onLoadCallback: () => void) => {
      if (!src) {
        return
      }
      const existingScript = document.querySelector(`script[src="${src}"]`)
      if (existingScript) {
        return
      }
      const script = document.createElement('script')
      script.setAttribute('src', src)
      script.onload = onLoadCallback
      document.body.appendChild(script)
      return script
    }

    // Add the script to the DOM
    addScriptToDOM(() => setIsScriptLoaded(true))
  }, [src])

  return isScriptLoaded
}
