export const RETURN_PARCELS_TABLE_COLUMNS_HEADERS = {
  TRACKING_ID: 'trackingId',
  CUSTOMS_DESCRIPTION: 'customsDescription',
  GOODS_VALUE: 'goodsValues',
  REMAINING_DAYS_BEFORE_AUTO_DIPOSED: 'remainingDaysBeforeAutoDisposed',
  REMAINING_FREE_STRORAGE_DAYS: 'remainingFreeStorageDays',
  PROBLEMATIC_PARCEL: 'binLabel',
  RELABEL_ATTEMPTS: 'relabelAttempts',
  STORAGE_DATE: 'putawayTimestamp',
  RETURN_REASON: 'rtsReason',
  RESOLUTION: 'pickAction',
  RELABEL_TRACKING_ID: 'relabelTid',
  REQUESTED_DATE: 'picklistUploadedTimestamp'
}

export const DISPOSED_PARCELS_TABLE_COLUMNS_HEADERS = {
  TRACKING_ID: 'trackingId',
  CUSTOMS_DESCRIPTION: 'customsDescription',
  GOODS_VALUE: 'goodsValues',
  DISPOSED_DATE: 'picklistUploadedTimestamp',
  RETURN_REASON: 'rtsReason',
  RESOLUTION: 'pickAction'
}

export const FULFILMENT_SUBMITTED_PARCELS_TABLE_COLUMNS_HEADERS = {
  TRACKING_ID: 'trackingId',
  CUSTOMS_DESCRIPTION: 'customsDescription',
  GOODS_VALUE: 'goodsValues',
  PROBLEMATIC_PARCEL: 'binLabel',
  RETURN_REASON: 'rtsReason',
  RESOLUTION: 'pickAction',
  RELABEL_TID: 'relabelTid',
  REQUESTED_DATE: 'picklistUploadedTimestamp'
}

export const RETURN_PARCELS_FULFILLMENT_ACTIONS_ID = {
  SEND_TO_NEW_RECIPIENT: 'return_parcels.send_to_new_recipient',
  RETURN_TO_ME: 'return_parcels.return_to_me',
  DISPOSE: 'return_parcels.dispose'
}

export const RETURN_PARCELS_FULFILLMENT_SUB_ACTIONS_ID = {
  [RETURN_PARCELS_FULFILLMENT_ACTIONS_ID.SEND_TO_NEW_RECIPIENT]: {
    KEYBOARD_METHOD: 'return_parcels.send_to_new_recipient.keyboard_method',
    BULK_UPLOAD_METHOD: 'return_parcels.send_to_new_recipient.bulk_upload_method'
  }
}

export const RETURN_PARCELS_FULFILLMENT_ACTIONS = [
  { id: RETURN_PARCELS_FULFILLMENT_ACTIONS_ID.SEND_TO_NEW_RECIPIENT },
  { id: RETURN_PARCELS_FULFILLMENT_ACTIONS_ID.RETURN_TO_ME },
  { id: RETURN_PARCELS_FULFILLMENT_ACTIONS_ID.DISPOSE }
]

export const RETURN_PARCELS_FULFILLMENT_SUB_ACTIONS = [
  {
    id:
      RETURN_PARCELS_FULFILLMENT_SUB_ACTIONS_ID[RETURN_PARCELS_FULFILLMENT_ACTIONS_ID.SEND_TO_NEW_RECIPIENT]
        .BULK_UPLOAD_METHOD
  },
  {
    id:
      RETURN_PARCELS_FULFILLMENT_SUB_ACTIONS_ID[RETURN_PARCELS_FULFILLMENT_ACTIONS_ID.SEND_TO_NEW_RECIPIENT]
        .KEYBOARD_METHOD
  }
]

export const FULFILLMENT_RESOLUTIONS = ['SEND_TO_NEW_RECIPIENT', 'RETURN_TO_ME', 'DISPOSE']

export const RESOLUTIONS_PICK_ACTIONS_MAPPING = {
  SEND_TO_NEW_RECIPIENT: {
    pickAction: 'RELABEL',
    label: 'return_parcels.send_to_new_recipient'
  },
  RETURN_TO_ME: {
    pickAction: 'BULK_RESHIP',
    label: 'return_parcels.return_to_me'
  },
  DISPOSE: {
    pickAction: 'DISPOSE',
    label: 'return_parcels.dispose'
  }
}

export const RETURN_PARCELS_DEFAULT_LIMIT = 20

export const UNRESOLVED_RETURN_PARCELS_STATUS = {
  IN_WAREHOUSE: 'IN_WAREHOUSE',
  INTERNAL_MOVE: 'INTERNAL_MOVE'
}

export const FULFILMENT_SUBMITTED_RETURN_PARCELS_PICK_ACTION = {
  RELABEL: 'RELABEL',
  BULK_RESHIP: 'BULK_RESHIP',
  DISPOSE: 'DISPOSE'
}

export const FULFILMENT_SUBMITTED_RETURN_PARCELS_RESOLUTION_PICK_ACTION_MAPPING = {
  RELABEL: 'return_parcels.send_to_new_recipient',
  BULK_RESHIP: 'return_parcels.return_to_me',
  DISPOSE: 'return_parcels.dispose'
}

export const UNRESOLVED_TABLE_SORT_BY_MAPPING = {
  [RETURN_PARCELS_TABLE_COLUMNS_HEADERS.TRACKING_ID]: 'tid',
  [RETURN_PARCELS_TABLE_COLUMNS_HEADERS.PROBLEMATIC_PARCEL]: 'bin_label',
  [RETURN_PARCELS_TABLE_COLUMNS_HEADERS.STORAGE_DATE]: 'putaway_timestamp',
  [RETURN_PARCELS_TABLE_COLUMNS_HEADERS.REMAINING_DAYS_BEFORE_AUTO_DIPOSED]: 'auto_dispose_timestamp',
  [RETURN_PARCELS_TABLE_COLUMNS_HEADERS.REMAINING_FREE_STRORAGE_DAYS]: 'remaining_free_storage_days'
}

export const DISPOSED_TABLE_SORT_BY_MAPPING = {
  [DISPOSED_PARCELS_TABLE_COLUMNS_HEADERS.TRACKING_ID]: 'tid',
  [DISPOSED_PARCELS_TABLE_COLUMNS_HEADERS.DISPOSED_DATE]: 'picklist_uploaded_timestamp'
}

export const FULFILMENT_SUBMITTED_TABLE_SORT_BY_MAPPING = {
  [FULFILMENT_SUBMITTED_PARCELS_TABLE_COLUMNS_HEADERS.TRACKING_ID]: 'tid',
  [FULFILMENT_SUBMITTED_PARCELS_TABLE_COLUMNS_HEADERS.PROBLEMATIC_PARCEL]: 'bin_label',
  [FULFILMENT_SUBMITTED_PARCELS_TABLE_COLUMNS_HEADERS.REQUESTED_DATE]: 'picklist_uploaded_timestamp'
}

export const DATE_RANGE_TYPES = {
  TODAY: 'TODAY',
  SEVEN_DAYS: 'SEVEN_DAYS',
  THIRTY_DAYS: 'THIRTY_DAYS',
  OTHERS: 'OTHERS',
  DATE_RANGE: 'DATE_RANGE',
  OTHERS_ANYTIME: 'OTHERS_ANYTIME',
  OTHERS_YESTERDAY: 'OTHERS_YESTERDAY',
  OTHERS_THIS_WEEK: 'OTHERS_THIS_WEEK',
  OTHERS_LAST_WEEK: 'OTHERS_LAST_WEEK',
  OTHERS_THIS_MONTH: 'OTHERS_THIS_MONTH',
  OTHERS_LAST_MONTH: 'OTHERS_LAST_MONTH',
  OTHERS_LAST_SIX_MONTHS: 'OTHERS_LAST_SIX_MONTHS'
}

export const DATE_FORMAT = 'YYYY/MM/DD'

export const DOWNLOAD_CSV_FILE_NAME = 'unresolved-return-parcels-list.csv'
export const SUBMITTED_FULFILLMENT_DOWNLOAD_CSV_FILE_NAME = 'submitted-for-fulfillment-parcels-list.csv'

export const DISPOSED_PARCELS_DOWNLOAD_CSV_FILE_NAME = 'disposed-of-by-system-parcels-list.csv'

export const DOWNLOAD_CSV_FILE_COLUMNS = {
  TRACKING_ID: 'tracking_id',
  CUSTOMS_DESCRIPTION: 'customs_description',
  GOODS_VALUE: 'goods_value',
  REMAINING_DAYS_BEFORE_AUTO_DIPOSED: 'return_parcels.remaining_days_before_auto_disposed',
  REMAINING_FREE_STRORAGE_DAYS: 'return_parcels.remaining_free_storage_days',
  PROBLEMATIC_PARCEL: 'return_parcels.problematic_parcel',
  RELABEL_ATTEMPTS: 'return_parcels.relabel_attempts_header_title',
  STORAGE_DATE: 'return_parcels.storage_date',
  RETURN_REASON: 'return_parcels.return_reason'
}

export const DISPOSED_DOWNLOAD_CSV_FILE_COLUMNS = {
  TRACKING_ID: 'tracking_id',
  CUSTOMS_DESCRIPTION: 'customs_description',
  GOODS_VALUE: 'goods_value',
  DISPOSED_DATE: 'return_parcels.disposal_date',
  RETURN_REASON: 'return_parcels.return_reason'
}

export const SUBMITTED_DOWNLOAD_CSV_FILE_COLUMNS = {
  TRACKING_ID: 'tracking_id',
  CUSTOMS_DESCRIPTION: 'customs_description',
  GOODS_VALUE: 'goods_value',
  RETURN_REASON: 'return_parcels.return_reason',
  RESOLUTION: 'return_parcels.resolution',
  RELABEL_TRACKING_ID: 'return_parcels.relabel_tracking_id',
  REQUESTED_DATE: 'return_parcels.requested_date'
}

export const RETURN_PARCELS_TAB_KEY = {
  UNRESOLVED: 'unresolved',
  SUBMITTED_FOR_FULFILLMENT: 'submitted_for_fulfillment',
  DIPOSE_BY_SYSTEM: 'dipose_by_system'
}

export const RETURN_PARCEL_PATHNAME = '/home/return-parcels/'

export const PROBLEMATIC_LABEL = 'Problematic'

export const TIMEZONE_BY_COUNTRY = {
  SG: 8,
  MY: 8,
  ID: 7,
  VN: 7,
  PH: 8,
  TH: 7,
  MM: 7,
  MA: 1
}
