/* eslint-disable max-len */
import { FULFILMENT_SUBMITTED_PARCELS_TABLE_COLUMNS_HEADERS } from 'containers/ReturnParcels/constants'

export const ORIGIN_TRACKING_ID = 'Original Tracking ID'

export const RELABEL_ORDER_FIELD = {
  ORIGIN_TRACKING_ID: 'Original Tracking ID',
  NEW_REQUESTED_TRACKING_ID: 'New Requested Tracking ID',
  SOURCE_ORDER_ID: 'Source Order ID (Optional)',
  TO_NAME: 'To Name',
  TO_ADDRESS: 'To Address',
  TO_CITY: 'To City',
  TO_STATE_PROVINCE: 'To State/Province',
  TO_POSTCODE: 'To Postcode',
  TO_EMAIL: 'To Email (Optional)',
  TO_CONTACT_NUMBER: 'To Contact Number',
  GOOD_DESCRIPTION: 'Goods Description',
  GOOD_VALUE: 'Goods Value',
  COD: 'COD (Optional)',
  CONSIGNEE_TAX: 'Consignee Tax Number',
  QUANTITY: 'Item Quantity',
  ITEM_DESCRIPTION: 'Item Description',
  ITEM_VALUE: 'Item Value',
  PARCEL_WEIGHT: 'Parcel Weight (Optional)',
  DELIVERY_INSTRUCTION: 'Delivery Instruction (Optional)',
  INSURED_VALUE: 'Insured Value (Optional)'
}

const EXTEND_INSTRUCTION_COMMON_DATA = {
  [RELABEL_ORDER_FIELD.INSURED_VALUE]:
    'Intruction:\nInsured value of goods must be a number greater than 0 and in destination currency',
  [RELABEL_ORDER_FIELD.SOURCE_ORDER_ID]: 'Instructions:\nTracking number for your internal referrence',
  [RELABEL_ORDER_FIELD.DELIVERY_INSTRUCTION]: 'Instruction:\nDelivery instruction may contain up to 1000 characters'
}

export const RELABEL_INSTRUCTION_FIELDS = {
  [RELABEL_ORDER_FIELD.TO_CITY]: 'Instruction: City is required and must be less than 255 characters long.',
  [RELABEL_ORDER_FIELD.TO_EMAIL]:
    'Instruction: Email address is optional. If entered, it must be of the valid format.For example: user@example.com.',
  [RELABEL_ORDER_FIELD.TO_CONTACT_NUMBER]:
    'Instruction:\nContact number is required. The number must be 6-32 characters long, the first character can be a plus (+), and all following characters must be digits between 0 and 9.',
  [RELABEL_ORDER_FIELD.QUANTITY]: 'Instruction: Quantity is required and must be a number greater than 0.',
  [RELABEL_ORDER_FIELD.ITEM_DESCRIPTION]:
    'Instruction: Item description is required and may contain up to 1000 characters.',
  [RELABEL_ORDER_FIELD.ITEM_VALUE]: 'Instruction: Item value is required and must be a number greater than 1.',
  [RELABEL_ORDER_FIELD.GOOD_DESCRIPTION]:
    'Instruction: Goods description is required and may contain up to 1000 characters.',
  [RELABEL_ORDER_FIELD.GOOD_VALUE]: 'Instruction: Goods value is required and must be a number greater than 1.',
  [RELABEL_ORDER_FIELD.COD]: 'Instruction: COD value must be a number.',
  ...EXTEND_INSTRUCTION_COMMON_DATA
}

const COMMON_FIELDS = {
  [RELABEL_ORDER_FIELD.ORIGIN_TRACKING_ID]: 'Instruction: Do not update information in this row',
  [RELABEL_ORDER_FIELD.NEW_REQUESTED_TRACKING_ID]:
    'Instruction:\nRequested tracking ID must be at least 9 characters long',
  [RELABEL_ORDER_FIELD.TO_NAME]: 'Instruction: Name is required and must be less than 255 characters long.',
  [RELABEL_ORDER_FIELD.TO_ADDRESS]: 'Instruction: Address is required and must be less than 255 characters long.'
}

export const RELABEL_FIELDS_BY_COUNTRY = {
  SG: {
    ...COMMON_FIELDS,
    [RELABEL_ORDER_FIELD.TO_POSTCODE]:
      'Instruction: Postcode is required for Indonesia and optional for Vietnam. For Indonesia, it must be a number consisting of 5 digits.',
    [RELABEL_ORDER_FIELD.TO_EMAIL]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.TO_EMAIL],
    [RELABEL_ORDER_FIELD.TO_CONTACT_NUMBER]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.TO_CONTACT_NUMBER],
    [RELABEL_ORDER_FIELD.COD]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.COD],
    [RELABEL_ORDER_FIELD.GOOD_DESCRIPTION]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.GOOD_DESCRIPTION],
    ...EXTEND_INSTRUCTION_COMMON_DATA
  },
  MY: {
    ...COMMON_FIELDS,
    [RELABEL_ORDER_FIELD.TO_CITY]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.TO_CITY],
    [RELABEL_ORDER_FIELD.TO_STATE_PROVINCE]:
      'Instruction: Province is required for Indonesia and Vietnam, and must be less than 255 characters long.',
    [RELABEL_ORDER_FIELD.TO_POSTCODE]:
      'Instruction: Postcode is required for Indonesia and optional for Vietnam. For Indonesia, it must be a number consisting of 5 digits.',
    [RELABEL_ORDER_FIELD.TO_EMAIL]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.TO_EMAIL],
    [RELABEL_ORDER_FIELD.TO_CONTACT_NUMBER]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.TO_CONTACT_NUMBER],
    [RELABEL_ORDER_FIELD.COD]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.COD],
    [RELABEL_ORDER_FIELD.GOOD_DESCRIPTION]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.GOOD_DESCRIPTION],
    ...EXTEND_INSTRUCTION_COMMON_DATA
  },
  VN: {
    ...COMMON_FIELDS,
    [RELABEL_ORDER_FIELD.TO_CITY]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.TO_CITY],
    [RELABEL_ORDER_FIELD.TO_STATE_PROVINCE]:
      'Instruction: Province is required for Indonesia and Vietnam, and must be less than 255 characters long.',
    [RELABEL_ORDER_FIELD.TO_POSTCODE]:
      'Instruction: Postcode is required for Indonesia and optional for Vietnam. For Indonesia, it must be a number consisting of 5 digits.',
    [RELABEL_ORDER_FIELD.TO_EMAIL]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.TO_EMAIL],
    [RELABEL_ORDER_FIELD.TO_CONTACT_NUMBER]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.TO_CONTACT_NUMBER],
    [RELABEL_ORDER_FIELD.CONSIGNEE_TAX]:
      'Instruction:\nConsignee tax number is required if shipping to ID and VN to get a tax discount, and must be a number.',
    [RELABEL_ORDER_FIELD.COD]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.COD],
    [RELABEL_ORDER_FIELD.GOOD_DESCRIPTION]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.GOOD_DESCRIPTION],
    ...EXTEND_INSTRUCTION_COMMON_DATA
  },
  ID: {
    ...COMMON_FIELDS,
    [RELABEL_ORDER_FIELD.TO_CITY]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.TO_CITY],
    [RELABEL_ORDER_FIELD.TO_STATE_PROVINCE]:
      'Instruction: Province is required for Indonesia and Vietnam, and must be less than 255 characters long.',
    [RELABEL_ORDER_FIELD.TO_POSTCODE]:
      'Instruction: Postcode is required for Indonesia and optional for Vietnam. For Indonesia, it must be a number consisting of 5 digits.',
    [RELABEL_ORDER_FIELD.TO_EMAIL]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.TO_EMAIL],
    [RELABEL_ORDER_FIELD.TO_CONTACT_NUMBER]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.TO_CONTACT_NUMBER],
    [RELABEL_ORDER_FIELD.CONSIGNEE_TAX]:
      'Instruction:\nConsignee tax number is required if shipping to ID and VN to get a tax discount, and must be a number.',
    [RELABEL_ORDER_FIELD.COD]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.COD],
    [RELABEL_ORDER_FIELD.GOOD_DESCRIPTION]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.GOOD_DESCRIPTION],
    ...EXTEND_INSTRUCTION_COMMON_DATA
  },
  PH: {
    ...COMMON_FIELDS,
    [RELABEL_ORDER_FIELD.TO_CITY]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.TO_CITY],
    [RELABEL_ORDER_FIELD.TO_STATE_PROVINCE]:
      'Instruction: State is required for Malaysia, province is required for Philippines and Thailand.\nMust be less than 255 characters long.',
    [RELABEL_ORDER_FIELD.TO_POSTCODE]:
      'Instruction:\nPostcode is required and must be a number of the valid format:\n- Malaysia, Thailand: 5 digits\n- Philippines: 4 digits',
    [RELABEL_ORDER_FIELD.TO_EMAIL]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.TO_EMAIL],
    [RELABEL_ORDER_FIELD.TO_CONTACT_NUMBER]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.TO_CONTACT_NUMBER],
    [RELABEL_ORDER_FIELD.COD]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.COD],
    [RELABEL_ORDER_FIELD.GOOD_DESCRIPTION]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.GOOD_DESCRIPTION],
    ...EXTEND_INSTRUCTION_COMMON_DATA
  },
  TH: {
    ...COMMON_FIELDS,
    [RELABEL_ORDER_FIELD.TO_STATE_PROVINCE]:
      'Instruction: State is required for Malaysia, province is required for Philippines and Thailand.\nMust be less than 255 characters long.',
    [RELABEL_ORDER_FIELD.TO_POSTCODE]:
      'Instruction:\nPostcode is required and must be a number of the valid format:\n- Malaysia, Thailand: 5 digits\n- Philippines: 4 digits',
    [RELABEL_ORDER_FIELD.TO_EMAIL]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.TO_EMAIL],
    [RELABEL_ORDER_FIELD.TO_CONTACT_NUMBER]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.TO_CONTACT_NUMBER],
    [RELABEL_ORDER_FIELD.COD]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.COD],
    [RELABEL_ORDER_FIELD.GOOD_DESCRIPTION]: RELABEL_INSTRUCTION_FIELDS[RELABEL_ORDER_FIELD.GOOD_DESCRIPTION],
    ...EXTEND_INSTRUCTION_COMMON_DATA
  }
}

const COMMON_REQUEST_FIELDS = {
  [RELABEL_ORDER_FIELD.ORIGIN_TRACKING_ID]: 'source_reference_id',
  [RELABEL_ORDER_FIELD.TO_NAME]: 'to.name',
  [RELABEL_ORDER_FIELD.TO_ADDRESS]: 'to.address_line1',
  [RELABEL_ORDER_FIELD.TO_CITY]: 'to.city',
  [RELABEL_ORDER_FIELD.TO_STATE_PROVINCE]: 'to.state_province',
  [RELABEL_ORDER_FIELD.TO_POSTCODE]: 'to.post_code',
  [RELABEL_ORDER_FIELD.TO_EMAIL]: 'to.contact_email',
  [RELABEL_ORDER_FIELD.TO_CONTACT_NUMBER]: 'to.contact_number'
}

const EXTEND_COMMON_REQUEST_FIELDS = {
  [RELABEL_ORDER_FIELD.INSURED_VALUE]: 'delivery.insured_value',
  [RELABEL_ORDER_FIELD.PARCEL_WEIGHT]: 'parcel_details.weight',
  [RELABEL_ORDER_FIELD.CONSIGNEE_TAX]: 'parcel_details.tax_id',
  [RELABEL_ORDER_FIELD.SOURCE_ORDER_ID]: 'source_order_id',
  [RELABEL_ORDER_FIELD.DELIVERY_INSTRUCTION]: 'delivery.delivery_instructions'
}

export const RELABEL_REQUEST_FIELDS = {
  ...COMMON_REQUEST_FIELDS,
  [RELABEL_ORDER_FIELD.GOOD_DESCRIPTION]: 'parcel_details.customs_description',
  [RELABEL_ORDER_FIELD.GOOD_VALUE]: 'parcel_details.value',
  [RELABEL_ORDER_FIELD.COD]: 'delivery.cash_on_delivery',
  ...EXTEND_COMMON_REQUEST_FIELDS
}

export const RELABEL_HEADER_FIELDS_BY_COUNTRY = {
  SG: ['Row#', ...Object.keys(RELABEL_FIELDS_BY_COUNTRY.SG)],
  MY: ['Row#', ...Object.keys(RELABEL_FIELDS_BY_COUNTRY.MY)],
  VN: ['Row#', ...Object.keys(RELABEL_FIELDS_BY_COUNTRY.VN)],
  ID: ['Row#', ...Object.keys(RELABEL_FIELDS_BY_COUNTRY.ID)],
  PH: ['Row#', ...Object.keys(RELABEL_FIELDS_BY_COUNTRY.PH)],
  TH: ['Row#', ...Object.keys(RELABEL_FIELDS_BY_COUNTRY.TH)]
}

export const DEFAULT_SORT = {
  sortBy: FULFILMENT_SUBMITTED_PARCELS_TABLE_COLUMNS_HEADERS.REQUESTED_DATE,
  sortDirection: 'desc'
}

export const DEFAULT_WMS_RESOLUTIONS = ['SEND_TO_NEW_RECIPIENT']

export const DELAY_TIME = 3000

export const RELABEL_METHODS = {
  BULK: 'Bulk Relabel',
  MANUAL: 'Manual Relabel'
}
