import { getDomainSuffix } from './getDomainSuffix'

export const getSafeUrl = (url: string) => {
  let newUrl = url

  // If current domain suffix is 'cn', make sure TLD of base url is also 'cn'
  if (getDomainSuffix(window.location.host) === 'cn') {
    newUrl = url.replace(`.${getDomainSuffix(new URL(url).host)}`, '.cn')
  }

  return newUrl
}
