/**
 *
 * TrackingNumberInput
 *
 */

import _ from 'lodash'
import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { NVInput, NVSelect } from '@nv/react-commons/src/Components'
import { cutPrefix } from './utils'
import { TRACKING_TYPES } from '../TrackingNumberFormItem/constants'
const Option = NVSelect.Option

const TrackingNumberInput = ({
  id,
  placeholder,
  value,
  onChange,
  prefix,
  prefixes,
  trackingType,
  setSelectedPrefix
}) => {
  const [inputValue, setInputValue] = useState(value)

  const renderPrefix = () => {
    return (
      prefix && (
        <Wrapper>
          <NVSelect
            defaultValue={prefix}
            onChange={p => {
              setSelectedPrefix(p)
              onChange(`${p}${inputValue}`)
            }}
            disabled={_.isEmpty(prefixes)}
          >
            {prefixes.map(p => (
              <Option key={p} value={p}>
                {p}
              </Option>
            ))}
          </NVSelect>
        </Wrapper>
      )
    )
  }

  const handleChange = e => {
    const _value = e.target.value
    setInputValue(_value)

    // Send Prefix with value for Multi-Dynamic & Multi-Fixed only
    if ([TRACKING_TYPES.MULTI_DYNAMIC, TRACKING_TYPES.MULTI_FIXED].includes(trackingType)) {
      onChange(`${prefix}${_value}`.trim())
    } else {
      onChange(_value.trim())
    }
  }

  return (
    <NVInput
      data-testId='trackingId'
      id={id || null}
      value={cutPrefix(prefix, inputValue)}
      placeholder={placeholder}
      addonBefore={renderPrefix()}
      onChange={handleChange}
    />
  )
}

const Wrapper = styled.div`
  .ant-select-dropdown {
    min-width: fit-content;
  }
`

TrackingNumberInput.propTypes = {
  id: PropTypes.any,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  prefixes: PropTypes.array,
  trackingType: PropTypes.string,
  setSelectedPrefix: PropTypes.func
}

export { TrackingNumberInput }
