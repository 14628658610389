/**
 *
 * CircleIcon
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { faFontAwesome } from '@fa-free-brands/faFontAwesome'
import { Col, Icon } from '@nv/react-commons/src/Components'
import { Colors } from 'themes'

const INFO = 'info'
const ERROR = 'error'
const WARNING = 'warning'
const SUCCESS = 'success'
const INFO_SECONDARY = 'infoSecondary'
const BIG = 'big'
const MEDIUM = 'medium'
const SMALL = 'small'
const MS = 'ms'
const XS = 'xs'

const COLORS = {
  [INFO]: {
    background: Colors.aliceBlue,
    icon: Colors.greyishBrown
  },
  [ERROR]: {
    background: Colors.salmon,
    icon: Colors.white
  },
  [WARNING]: {
    background: Colors.apricot,
    icon: Colors.white
  },
  [SUCCESS]: {
    background: Colors.emerald,
    icon: Colors.white
  },
  [INFO_SECONDARY]: {
    background: Colors.greyishBrown,
    icon: Colors.white
  }
}

const ICONSIZES = {
  [BIG]: {
    width: 60,
    height: 60,
    iconFontSize: 28
  },
  [MEDIUM]: {
    width: 48,
    height: 48,
    iconFontSize: 22
  },
  [SMALL]: {
    width: 32,
    height: 32,
    iconFontSize: 14
  },
  [MS]: {
    width: 20,
    height: 20,
    iconFontSize: 8
  },
  [XS]: {
    width: 16,
    height: 16,
    iconFontSize: 8
  }
}

const getColor = (type, attr) => COLORS[type || [INFO]][attr]
const getSize = (size, attr) => ICONSIZES[size || [MEDIUM]][attr]

const StyledIcon = styled(Icon)`
  color: ${props => getColor(props.type, 'icon')};
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  font-size: ${props => getSize(props.size, 'iconFontSize')}px;
  flex: 1;
  margin: 0 auto;
`
const StyledCircle = styled(({ type, size, disabled, ...props }) => <Col {...props} />)`
  background-color: ${props => getColor(props.type, 'background')};
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  height: ${props => getSize(props.size, 'height')}px;
  width: ${props => getSize(props.size, 'width')}px;
  min-width: ${props => getSize(props.size, 'height')}px;
  align-items: center;
  display: flex;
  border-radius: 50%;
`

class CircleIcon extends React.PureComponent {
  render () {
    const { type, size, className, iconClassName, circleStyle, disabled, ...props } = this.props
    return (
      <StyledCircle type={type} size={size} className={className} style={circleStyle} disabled={disabled}>
        <StyledIcon type={type} size={size} className={iconClassName} disabled={disabled} {...props} />
      </StyledCircle>
    )
  }
}

CircleIcon.propTypes = {
  circleStyle: PropTypes.object,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  type: PropTypes.oneOf(['info', 'error', 'warning', 'success']),
  size: PropTypes.oneOf(['big', 'medium', 'small', 'xs']),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  disabled: PropTypes.bool
}

CircleIcon.defaultProps = {
  icon: faFontAwesome,
  type: 'info',
  size: 'medium',
  disabled: false
}

export default CircleIcon
export { CircleIcon }
