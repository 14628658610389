/**
 *
 * EditReservation
 *
 */

import { faCalendarAlt } from '@fa-pro-regular/faCalendarAlt'
import { Alert, Col, DatePicker, Form, Icon, NVInput, Radio, Row, T } from '@nv/react-commons/src/Components'
import { CountryUtils } from '@nv/react-commons/src/Utils'
import { PICKUP_TYPES } from 'containers/PickupType/constants'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import { Colors, media } from 'themes'
import { getPickupTimeWindow, isDateDisabled } from 'utils/reservation'
import { ExistingRsvn } from '../ExistingRsvn'
import { PickupWarningAlert } from '../PickupWarningAlert'
import { Text } from '../Text'
import { TimeSlotSelector } from '../TimeSlotSelector'
import { VolumePicker } from '../VolumePicker'
import { withFlags } from 'featureHub/withFlags'
import featureFlags from '../../featureHub/featureKeys'
import { createStructuredSelector } from 'reselect'
import { selectIsSS } from 'containers/Base/selectors'
import { connect } from 'react-redux'

const RadioGroup = Radio.Group
const { TextArea } = NVInput
const FormItem = Form.Item

const StyledTimeSlotSelector = styled(TimeSlotSelector)`
  width: 200px;
`
const StyledWrapper = styled.div`
  width: 100%;
`
const StyledFormItem = styled(Form.Item)`
  flex: 1;
  margin-bottom: 0;
  &:last-of-type {
    margin-right: 0;
  }
`
const StyledForm = styled(Form)`
  margin-top: 16px;
  margin-bottom: 8px;
`
const StyledDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${Colors.apricot};
  border-radius: 4px;
  margin: 18px 5px 18px 0;
`

const StyledWrapperDot = styled.div`
  font-size: 14px;
  font-style: italic;
  display: flex;
  line-height: 42px;
  color: ${Colors.warmGrey};
`
const StyledDatePicker = styled(({ className, ...props }) => <DatePicker containerClassname={className} {...props} />)`
  && {
    .ant-calendar {
      ${media.max.tablet`
        width: auto;`};
    }
    ${props =>
      props.allRsvnDates
        .filter(reservation => moment(reservation.date, 'MM-DD-YYYY').isSameOrAfter(moment(), 'day'))
        .map(r => {
          return `
        td[title="${moment(r.date, 'MM-DD-YYYY').format('MMMM DD, YYYY')}"] {
        &::after {
        content: '';
        width: 8px;
        height: 8px;
        background-color: ${Colors.apricot};
        border-radius: 4px;
        display: block;
        margin-left: 17px;
        margin-top: 3px;
      }}`
        })
        .join('\n')};

    .ant-calendar-picker-container {
      &[style] {
        top: 0 !important;
        left: 0 !important;
      }
    }
  }
`
const StyledIcon = styled(Icon).attrs({
  type: 'small'
})`
  margin-right: 0;
  color: ${Colors.warmGrey};
`
const StyledWarning = styled(Text)`
  float: right;
  line-height: 20px;
  font-size: 11px;
`
const StyledRow = styled(Row).attrs({
  type: 'flex'
})`
  && {
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
const StyledCol = styled(Col)`
  && {
    margin-bottom: 8px;
  }
`
const StyledAlert = styled(Alert)`
  && {
    margin-bottom: 16px;
    padding-top: 5px;
  }
`
const StyledRadioGroup = styled(RadioGroup)`
  && {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -16px;
  }
`
const StyledRadio = styled(Radio)`
  && {
    margin-bottom: 16px;
    line-height: normal;
    flex: 1;
  }
`

class EditReservation extends React.Component {
  state = {
    allRsvnDates: []
  }

  static getDatePickupFooter = () => (
    <StyledWrapperDot>
      <StyledDot />
      <T id='upcoming_pickup_desc' />
    </StyledWrapperDot>
  )

  static getDerivedStateFromProps (nextProps, prevState) {
    const { rsvn, reservations } = nextProps
    const firstRsvn = _.head(reservations)
    const isDraft = firstRsvn && !firstRsvn.id

    let allRsvnDates = []
    if (nextProps?.reservations?.map) {
      allRsvnDates = nextProps.reservations.map(data => {
        const date = data.id ? moment(data.readyDatetime).format('MM/DD/Y') : false
        return { data, date }
      })
    }
    return {
      ...prevState,
      allRsvnDates,
      rsvn: rsvn || (!rsvn && isDraft && firstRsvn)
    }
  }

  disabledDate = date => {
    const {
      country,
      dateToRsvn,
      sameDayPickupCutoffTime: [hours, minutes],
      allowPickUpSunday,
      blockedDatesMap,
      flags
    } = this.props
    if (!date) {
      return false
    } else {
      return isDateDisabled(
        { country, dateToRsvn, sameDayPickupCutoffTime: [hours, minutes], allowPickUpSunday, blockedDatesMap, flags },
        { date }
      )
    }
  }

  getInitialDate = () => {
    const { date } = this.props
    const { rsvn } = this.state
    const readyDatetime = rsvn?.readyDatetime
    if (date) {
      return moment(date)
    } else if (readyDatetime) {
      return moment(readyDatetime)
    }
    return null
  }

  getDate = () => {
    return this.props.form.getFieldValue('date') || this.getInitialDate()
  }

  isSameday = () => {
    const date = this.props.form.getFieldValue('date')
    return moment().isSame(date, 'days')
  }

  onDateChange = (...args) => {
    this.props.onDateChange && this.props.onDateChange(...args)
    this.props.form.resetFields(['timeWindow'])
  }

  renderPickupType = () => {
    const {
      form: { getFieldDecorator }
    } = this.props
    return getFieldDecorator('pickupType', { initialValue: PICKUP_TYPES.ON_DEMAND })(
      <StyledRadioGroup>
        <StyledRadio value={PICKUP_TYPES.SCHEDULED}>
          <T id='scheduled_pickup' />
        </StyledRadio>
        <StyledRadio value={PICKUP_TYPES.ON_DEMAND}>
          <T id='on_demand_pickup' />
          <div style={{ color: Colors.pinkishGrey, paddingLeft: 24 }}>(90 minutes)</div>
        </StyledRadio>
      </StyledRadioGroup>
    )
  }

  renderPickupDate = () => {
    const { allRsvnDates, rsvn } = this.state
    const {
      form: { getFieldDecorator },
      intl,
      isEditing,
      showUpcomingIndicator,
      date2
    } = this.props
    return (
      <StyledFormItem label={<T id={date2 ? 'start_date' : 'pickup_date'} />}>
        {getFieldDecorator('date', {
          initialValue: this.getInitialDate(),
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: date2 ? 'pickup_start_date_required' : 'pickup_date_required'
              })
            }
          ],
          onChange: this.onDateChange
        })(
          <StyledDatePicker
            allRsvnDates={allRsvnDates}
            disabled={isEditing || (rsvn && !!rsvn.id)}
            renderExtraFooter={showUpcomingIndicator && EditReservation.getDatePickupFooter}
            disabledDate={this.disabledDate}
            placeholder={intl.formatMessage({ id: 'pick_date' })}
            pickerWidth='100%'
            suffixIcon={<StyledIcon icon={faCalendarAlt} />}
          />
        )}
      </StyledFormItem>
    )
  }

  renderEndPickupDate = () => {
    const {
      form: { getFieldDecorator },
      intl,
      isEditing,
      showUpcomingIndicator,
      date2
    } = this.props
    const { allRsvnDates, rsvn } = this.state

    return (
      <StyledFormItem label={<T id='end_date' />}>
        {getFieldDecorator('date2', {
          initialValue: moment(date2),
          rules: [
            {
              required: true,
              message: intl.formatMessage({ id: 'pickup_end_date_required' })
            }
          ],
          onChange: this.onDateChange
        })(
          <StyledDatePicker
            allRsvnDates={allRsvnDates}
            disabled={isEditing || (rsvn && !!rsvn.id)}
            renderExtraFooter={showUpcomingIndicator && EditReservation.getDatePickupFooter}
            disabledDate={this.disabledDate}
            placeholder={intl.formatMessage({ id: 'pick_date' })}
            pickerWidth='100%'
            suffixIcon={<StyledIcon icon={faCalendarAlt} />}
          />
        )}
      </StyledFormItem>
    )
  }

  renderPickupTimeslot = existingTimeSlots => {
    const {
      form: { getFieldDecorator, setFieldsValue },
      intl,
      country,
      isEditing,
      isPremium,
      date2,
      timeWindow,
      isSS
    } = this.props
    const { rsvn } = this.state
    const readyDatetime = rsvn?.readyDatetime
    const latestDatetime = rsvn?.latestDatetime
    return (
      <StyledFormItem label={intl.formatMessage({ id: 'pickup_time' })}>
        {getFieldDecorator('timeWindow', {
          initialValue: readyDatetime ? getPickupTimeWindow(readyDatetime, latestDatetime, country) : timeWindow,
          rules: [
            {
              required: true,
              message: intl.formatMessage({ id: 'time_window_required' })
            }
          ]
        })(
          <StyledTimeSlotSelector
            isPremium={isPremium}
            isEditing={isEditing}
            initialValue={timeWindow}
            noBlocking={date2}
            rsvns={existingTimeSlots}
            country={country}
            isSameday={this.isSameday()}
            isLastSelection={isSS}
            disabled={isSS}
            setFieldsValue={setFieldsValue}
          />
        )}
      </StyledFormItem>
    )
  }

  render () {
    const {
      form,
      intl,
      country,
      isEditing,
      onUseExisting,
      onEditExisting,
      rsvn: editRsvn,
      slots = _.map(CountryUtils.getPickupTimeslotInfo(country), 'range'),
      showExisting,
      showVolume,
      inPickUpPage,
      dailyPickUpLimit,
      onSetSelectedDate,
      date2,
      pickupVolume,
      comments,
      showPickupType,
      isSS
    } = this.props
    const { allRsvnDates, rsvn } = this.state
    const { getFieldDecorator, getFieldValue } = form
    const d = this.getDate()
    const existingRsvns = d ? _.filter(allRsvnDates, ({ date }) => date === d.format('MM/DD/Y')) : false
    const existingTimeSlots = existingRsvns
      ? _.map(existingRsvns, rvn => getPickupTimeWindow(rvn.data.readyDatetime, rvn.data.latestDatetime, country))
      : false
    const displayExistingRsvnInOC = !rsvn?.id && existingRsvns.length > 0
    const showAlert = moment().isSame(d, 'days')
    const isMax =
      !isEditing &&
      // is all-day time window
      (_.includes(existingTimeSlots, _.toString(slots.length - 1)) ||
        // virtually is all-day time window by booking all the timeslots
        existingTimeSlots.length >= slots.length - 1 ||
        existingRsvns.length >= dailyPickUpLimit)
    const isExisting = !isEditing && existingRsvns.length > 0

    return (
      <StyledWrapper>
        {showAlert && <StyledAlert showIcon type='info' message={<T id='pickup_tips' />} />}
        {editRsvn && editRsvn.id && !isSS && (
          <StyledAlert showIcon type='warning' message={<T id='edit_existing_pickup_alert' />} />
        )}
        <StyledForm onSubmit={this.props.handleSubmit}>
          <StyledRow gutter={8}>
            {showPickupType && <StyledCol span={24}>{this.renderPickupType()}</StyledCol>}
            <>
              <StyledCol xs={24} sm={24} md={24} lg={12}>
                {this.renderPickupDate()}
              </StyledCol>
              {date2 && (
                <StyledCol xs={24} sm={24} md={24} lg={12}>
                  {this.renderEndPickupDate()}
                </StyledCol>
              )}
              {!isMax && (
                <StyledCol xs={24} sm={24} md={24} lg={12}>
                  {this.renderPickupTimeslot(existingTimeSlots)}
                </StyledCol>
              )}
              {(isMax || isExisting) && inPickUpPage && (
                <PickupWarningAlert onSetSelectedDate={onSetSelectedDate} isMax={isMax} />
              )}
              {displayExistingRsvnInOC && showExisting && (
                <StyledCol span={24}>
                  <ExistingRsvn
                    country={country}
                    isMax={isMax}
                    timeslots={existingTimeSlots}
                    rsvns={existingRsvns}
                    onUseExisting={onUseExisting}
                    onEditExisting={onEditExisting}
                  />
                </StyledCol>
              )}
            </>
            {!isMax && showVolume && (
              <StyledCol span={24}>
                <FormItem label={intl.formatMessage({ id: 'pickup_volume' })}>
                  {getFieldDecorator('pickupVolume', {
                    initialValue: rsvn?.approxVolume || pickupVolume || VolumePicker.LESS_THAN_3_PARCELS,
                    rules: [{ required: true, message: intl.formatMessage({ id: 'pickup_volume_required' }) }]
                  })(<VolumePicker />)}
                </FormItem>
              </StyledCol>
            )}
            {!isMax && (
              <StyledCol span={24}>
                <StyledFormItem label={<T id='instruction_to_driver' />}>
                  {getFieldDecorator('comments', {
                    initialValue: rsvn?.comments || comments || '',
                    rules: [
                      {
                        max: 255,
                        message: intl.formatMessage({ id: 'comments_to_driver_max_length' }, { max: 255 })
                      }
                    ]
                  })(<TextArea autosize={{ minRows: 3 }} placeholder={intl.formatMessage({ id: 'optional' })} />)}
                  <StyledWarning id='x_out_of_max_characters' values={{ x: getFieldValue('comments').length }} />
                </StyledFormItem>
              </StyledCol>
            )}
          </StyledRow>
        </StyledForm>
      </StyledWrapper>
    )
  }
}

EditReservation.propTypes = {
  allowPickUpSunday: PropTypes.bool,
  blockedDatesMap: PropTypes.object,
  comments: PropTypes.string,
  country: PropTypes.string,
  dailyPickUpLimit: PropTypes.number,
  date: PropTypes.string,
  date2: PropTypes.string,
  dateToRsvn: PropTypes.object,
  form: PropTypes.object,
  onSetSelectedDate: PropTypes.func,
  handleSubmit: PropTypes.func,
  inPickUpPage: PropTypes.bool,
  intl: PropTypes.object,
  isEditing: PropTypes.bool,
  isPremium: PropTypes.bool,
  onDateChange: PropTypes.func,
  onEditExisting: PropTypes.func,
  onUseExisting: PropTypes.func,
  pickupVolume: PropTypes.string,
  reservations: PropTypes.array,
  rsvn: PropTypes.any,
  sameDayPickupCutoffTime: PropTypes.arrayOf(PropTypes.number),
  showExisting: PropTypes.bool,
  showPickupType: PropTypes.any,
  showUpcomingIndicator: PropTypes.bool,
  showVolume: PropTypes.bool,
  slots: PropTypes.array,
  timeWindow: PropTypes.number,
  isSS: PropTypes.bool
}

EditReservation.defaultProps = {
  blockedDatesMap: {},
  inPickUpPage: false,
  isEditing: false,
  sameDayPickupCutoffTime: [23, 59],
  showAlert: false,
  showExisting: true,
  showUpcomingIndicator: true
}

const mapStatetoProps = createStructuredSelector({
  isSS: selectIsSS()
})

const withConnect = connect(mapStatetoProps)

const _EditReservation = compose(
  withConnect,
  injectIntl,
  withFlags([featureFlags.DISABLED_SAME_DAY_PICKUP])
)(EditReservation)
export { _EditReservation as EditReservation }
