import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { ALL_DELIVERY_TIMESLOTS, DEFAULT_TIMESLOT_ID } from 'constants/deliveryTimeslots'
import { SUPPORTED_SERVICE_LEVELS } from 'containers/DeliveryType/constants'
import { ServiceLevel } from 'containers/DeliveryType/DeliveryType.interface'
import { processTimeslotString } from './processTimeslotString'

export type Timeslot = { id: string; range: [number, number] }

export const getDeliveryTimeslots = (
  country = '',
  allowedDeliveryTimeslots: string[] = [],
  serviceLevel?: ServiceLevel
): Timeslot[] => {
  const shouldUseAllowedDeliveryTimeslots =
    country.toLowerCase() === COUNTRIES.SG && serviceLevel === SUPPORTED_SERVICE_LEVELS.NEXTDAY

  let timeslotsToProcess: Set<string>
  if (country.toLowerCase() === COUNTRIES.MM) {
    timeslotsToProcess = new Set<string>(ALL_DELIVERY_TIMESLOTS)
  } else {
    timeslotsToProcess = new Set<string>(shouldUseAllowedDeliveryTimeslots ? allowedDeliveryTimeslots : [])
  }

  // Ensure the default timeslot ID is always included
  timeslotsToProcess.add(DEFAULT_TIMESLOT_ID)

  const timeslots = Array.from(timeslotsToProcess).map(timeslot => {
    const range = processTimeslotString(timeslot)
    return {
      id: timeslot,
      range: range
    }
  })

  return timeslots
}
