import { getNumberOfSupportedLevels, getPostcodeType } from 'utils/address'
import { NVInput, T, Col, Row } from '@nv/react-commons/src/Components'
import { CountryUtils, ValidatorUtils } from '@nv/react-commons/src/Utils'
import { range } from 'lodash'
import { DeliveryInstructionsFormItem } from 'components/DeliveryInstructionsFormItem'
import React from 'react'
import { StyledFormItem } from 'components/OCForm/styles'
import AddressDivisions from 'components/AddressDivisions'
import {
  OrderDeliveryAddressDetailsOwnProps,
  OrderDeliveryAddressDetailsProps
} from './OrderDeliveryAddressDetails.interface'
import { ADDRESS_LEVEL_NAMES } from 'constants/address'
import { compose } from 'redux'
import styled from 'styled-components'
import { Colors } from '@nv/react-commons/src/Themes'
import { FIELDS } from 'components/OCForm/constants'
import { media } from 'themes/media'

export const OrderDeliveryAddressDetails = ({
  intl,
  country,
  form,
  source,
  deliveryAddressInitialValue,
  allowEmptyAddress,
  FormItemWrapper = StyledFormItem
}: OrderDeliveryAddressDetailsProps) => {
  const { getFieldDecorator } = form
  const isEditOrder = source === 'editOrder'

  const postcodeField = getPostcodeType(country) === 'none' ? 0 : 1
  const numOfGranularAddressFields = postcodeField + getNumberOfSupportedLevels(country, false)
  // size of these fields will be between 6 and 12 in OC forms
  let sizeOfGranularAddressFields = Math.min(Math.max(24 / numOfGranularAddressFields, 6), 12)
  // size of these fields will be between 8 and 12 in OC forms
  let sizeOfAddressLineFields = numOfGranularAddressFields > 1 ? 8 : 12

  // these fields will take full width in Edit Order modal
  if (isEditOrder) {
    sizeOfGranularAddressFields = 24
    sizeOfAddressLineFields = 24
  }

  const Wrapper = isEditOrder ? EditOrderWrapper : OCWrapper

  return (
    <Wrapper>
      {getPostcodeType(country) === 'text' && (
        <Col md={24} lg={sizeOfGranularAddressFields}>
          <FormItemWrapper label={<T id='postcode' />}>
            {getFieldDecorator('postcode', {
              initialValue: deliveryAddressInitialValue?.postcode,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'postcode_required' })
                },
                {
                  len: CountryUtils.getPostcodeInfo(country).length,
                  message: intl.formatMessage(
                    { id: 'postcode_restriction' },
                    { x: CountryUtils.getPostcodeInfo(country).length }
                  )
                },
                {
                  validator: ValidatorUtils.postCode,
                  message: intl.formatMessage({ id: 'postcode_format' })
                }
              ]
            })(<NVInput />)}
          </FormItemWrapper>
        </Col>
      )}
      <AddressDivisions
        country={country}
        form={form}
        FormItemWrapper={FormItemWrapper}
        source='delivery'
        selectedDivisionsInitialValue={deliveryAddressInitialValue}
      >
        {range(1, getNumberOfSupportedLevels(country, false) + 1).map((level: keyof typeof ADDRESS_LEVEL_NAMES) => (
          <Col key={level} md={24} lg={sizeOfGranularAddressFields}>
            <AddressDivisions.Division level={level} allowEmpty={allowEmptyAddress} />
          </Col>
        ))}
      </AddressDivisions>
      <Col md={24} lg={sizeOfAddressLineFields}>
        <FormItemWrapper label={<T id='address_line_1' />}>
          {getFieldDecorator(FIELDS.ADDRESS1, {
            initialValue: deliveryAddressInitialValue?.address1,
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'address_required' })
              },
              {
                max: 255,
                message: intl.formatMessage({ id: 'address_restriction' })
              }
            ]
          })(<NVInput placeholder={intl.formatMessage({ id: 'street_name_unit_number' })} />)}
        </FormItemWrapper>
      </Col>
      <Col md={24} lg={sizeOfAddressLineFields}>
        <FormItemWrapper label={<T id='address_line_2' />}>
          {getFieldDecorator(FIELDS.ADDRESS2, {
            initialValue: deliveryAddressInitialValue?.address2,
            rules: [
              {
                max: 255,
                message: intl.formatMessage({ id: 'address_restriction' })
              }
            ]
          })(<NVInput placeholder={intl.formatMessage({ id: 'apartment_suite_building_floor_etc' })} />)}
        </FormItemWrapper>
      </Col>
      {!isEditOrder && (
        <Col md={24} lg={sizeOfAddressLineFields}>
          <DeliveryInstructionsFormItem
            form={form}
            formItem={FormItemWrapper}
            initialValue={deliveryAddressInitialValue?.deliveryInstructions}
          />
        </Col>
      )}
    </Wrapper>
  )
}

const OCWrapper = styled(Row).attrs({
  type: 'flex'
})`
  && {
    margin: 8px 0 12px 0;
    padding: 12px 16px 6px 24px;
    margin-left: -24px;
    width: calc(100% + 40px);
    background-color: ${Colors.aliceBlue};

    && {
      ${media.max.tablet`
      display: block;
    `};
    }
  }
`

const EditOrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`

export default compose<React.FunctionComponent<OrderDeliveryAddressDetailsOwnProps>>()(OrderDeliveryAddressDetails)
