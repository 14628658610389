import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const OnboardingContext = createContext()

export const OnboardingProvider = ({ children }) => {
  const [startOnboarding, setStartOnboarding] = useState(false)
  const [hasStartedTour, setHasStartedTour] = useState(false)
  return (
    <OnboardingContext.Provider value={{ startOnboarding, setStartOnboarding, hasStartedTour, setHasStartedTour }}>
      {children}
    </OnboardingContext.Provider>
  )
}

OnboardingProvider.propTypes = {
  children: PropTypes.node
}
