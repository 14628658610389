import styled, { css } from 'styled-components'

// TODO: remove once complete integrate with Akira design system
export const AkiraWrapper = styled.div`
  background: #fff;

  button,
  input,
  select,
  textarea {
    border-style: inherit;
    margin: 0;
  }

  label,
  legend {
    font-size: inherit;
    line-height: inherit;
  }
  fieldset label,
  fieldset legend {
    color: var(--akira-grey-2);
  }
  .chip span span {
    max-width: 40rem;
  }
  .chip span input {
    display: none;
  }
  .my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .col-span-2 {
    grid-column: span 2 / span 2;
  }
  .text-grey-2 {
    color: var(--akira-grey-2);
  }
  .pt-4 {
    padding-top: 1rem;
  }
  .akira-radio-group {
    font-size: 14px;
  }
  .ant-input {
    width: 100%;
    height: 29px;
    background: transparent;
    font-size: 14px;
    padding: 0;

    border-style: none;
    border-radius: 0;
    border-bottom-style: solid;
    border-bottom-color: var(--akira-grey-4) !important;
    border-bottom-width: 1px;
    box-shadow: none !important;
    &:hover {
      border-bottom-color: var(--akira-red-3) !important;
      background: transparent;
    }
  }

  & {
    * {
      font-family: 'Noto Sans', 'Noto Sans Thai', 'Kanit', 'Noto Sans SC', sans-serif;
    }

    *:lang(en) {
      font-family: 'Noto Sans';
    }

    *:lang(ti) {
      font-family: 'Noto Sans Thai', 'Kanit';
    }

    *:lang(vi) {
      font-family: 'Noto Sans';
    }

    *:lang(zh-Hans) {
      font-family: 'Noto Sans SC', sans-serif;
    }
  }
`

// TODO: remove it once Akira support date range picker
export const akiraAntdDateRangePicker = css`
  width: 100%;
  height: 29px;
  background: transparent;
  font-size: 14px;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-style: none;
  border-radius: 0;
  border-bottom-style: solid;
  border-bottom-color: var(--akira-grey-4) !important;
  border-bottom-width: 1px;
  &:hover {
    border-bottom-color: var(--akira-red-3) !important;
    background: transparent;
  }
`

// TODO: remove once integration with Akira design system is complete
export const AkiraReset = css`
  * {
    font-family: 'Noto Sans', 'Noto Sans Thai', 'Kanit', 'Noto Sans SC', sans-serif;
  }

  *:lang(en) {
    font-family: 'Noto Sans', sans-serif;
  }

  *:lang(ti) {
    font-family: 'Noto Sans Thai', 'Kanit', sans-serif;
  }

  *:lang(vi) {
    font-family: 'Noto Sans', sans-serif;
  }

  *:lang(zh-Hans) {
    font-family: 'Noto Sans SC', sans-serif;
  }

  label {
    font-size: inherit;
    line-height: inherit;
  }

  button {
    border-style: inherit;
  }

  h1,
  h2,
  h3 {
    color: inherit;
    margin: 0;
  }

  h1 {
    font-weight: 700;
  }

  svg {
    fill: revert;
  }

  ol {
    margin: 0;
  }
`
