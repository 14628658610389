import { OC_SERVICE_TYPES } from 'containers/Base/constants'
import _ from 'lodash'
import { CountryUtils } from '@nv/react-commons/src/Utils'
import { camelCaseKey } from 'utils/misc'
import {
  getAllMakeFields,
  makeAddress1,
  makeAddress2,
  makeBatteryPackaging,
  makeBatteryType,
  makeCOD,
  makeConsigneeCompany,
  makeCountry,
  makeCustomsDescription,
  makePackageType,
  makeDeliveryStartDate,
  makeDeliveryTimeslot,
  makeDeliveryType,
  makeEmail,
  makeGoodsCurrency,
  makeGoodsValue,
  makeHeight,
  makeHsCode,
  makeInstructions,
  makeInsuredValue,
  makeLength,
  makeLocality,
  makeLockerPickup,
  makeMadeInCountry,
  makeName,
  makeNeighborhood,
  makeNinjaPackID,
  makeCorpAwbID,
  makePhoneNumber,
  makePortation,
  makePostcode,
  makeRegion,
  makeRequestedTrackingNumber,
  makeShipperOrderNumber,
  makeSize,
  makeTradeTerms,
  makeVolume,
  makeWeekendDelivery,
  makeWeight,
  makeWidth,
  makeIsDangerousGood,
  makeParcelDescription,
  makeIsGstIncludedInGoodsValue,
  makeGstRegistrationNumber,
  makeNoOfCartons,
  makeRdoRequired,
  makeGrnRequired,
  makeRequestedPieceTrackingNumbers,
  makeTemperatureControlRequired
} from 'utils/csvMappingFields'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { get } from 'utils/get'
import { DEFAULT_TIMESLOT_ID } from 'constants/deliveryTimeslots'

const { getAddressInfo, getPostcodeInfo } = CountryUtils

export const SOURCE_IDS = {
  MANUAL: 7,
  UPLOAD: 8
}

export const getSourceId = (isBulkUpload, isReturnOrder) => {
  return isReturnOrder || !isBulkUpload ? SOURCE_IDS.MANUAL : SOURCE_IDS.UPLOAD
}

/**
 * Determines whether to display the delivery timeslot option based on allowed timeslots and country.
 *
 * For SG, the function checks if the `allowedDeliveryTimeslots` contains any timeslot other than the default.
 * If it contains only the default timeslot, it will not display unless there are additional timeslots.
 * This check is only applied for SG; for other countries excpet MM,
 * this function currently returns false, indicating no display.
 *
 * @param {string[]} allowedDeliveryTimeslots - The list of timeslots allowed for delivery.
 * @param {string} country - The country code, used to apply specific logic for SG.
 * @returns {boolean} - True if the delivery timeslot should be displayed based on the criteria; false otherwise.
 */
const isDisplayDeliveryTimeslot = (allowedDeliveryTimeslots, country) => {
  if (country?.toLowerCase() === COUNTRIES.SG) {
    const containDefaultDeliveryTimeslot = allowedDeliveryTimeslots?.includes(DEFAULT_TIMESLOT_ID)
    return containDefaultDeliveryTimeslot ? allowedDeliveryTimeslots?.length > 1 : allowedDeliveryTimeslots?.length > 0
  }

  return country?.toLowerCase() === COUNTRIES.MM
}

/**
 * There are 2 ways to input order data:
 * 1. Manual OC (OCForm)
 * 2. File upload
 *
 * Similar to OCForm, CSV Mapping has a notion of "input fields." This function returns
 * all the fields that a shipper must/can provide for his orders.
 *
 * @param {string} serviceType
 * @param {object,optional} shipper
 * @param {object,optional} orderCreate
 * @param {boolean,optional} isTrackingNumberRequired
 * @param {boolean} isCodSupported
 * @param {boolean} isPrePaid
 */
const getOrderFields = (
  {
    serviceType,
    shipper,
    orderCreate,
    isTrackingNumberRequired,
    isCodSupported,
    isAllowedToUseAccountBalance,
    isB2BBundleSupported,
    allowedDeliveryTimeslots,
    isEnabledTemperatureRanges
  } = {
    isCodSupported: true,
    isEnabledTemperatureRanges: false
  }
) => {
  // TODO: @LS Remove isCodSupported and put everything in feature configs
  const country = shipper?.country
  const rsvn = orderCreate?.reservation
  const deliveryType = orderCreate?.deliveryType
  const pickupType = orderCreate?.pickupType
  const addressInfo = getAddressInfo(country)
  const shouldDisplayDeliveryTimeslot = isDisplayDeliveryTimeslot(allowedDeliveryTimeslots, country)

  const makeField = ({ makeFunc, compulsory }) => ({
    ...makeFunc({ country, rsvn, deliveryType, pickupType, addressInfo, serviceType, shouldDisplayDeliveryTimeslot }),
    compulsory
  })

  const isWeightCompulsory = country.toLowerCase() === COUNTRIES.MY && isAllowedToUseAccountBalance
  const isParcelDescriptionRequired = country.toLowerCase() === COUNTRIES.VN
  const b2bBundleFields = isB2BBundleSupported
    ? [
        { makeFunc: makeNoOfCartons },
        { makeFunc: makeGrnRequired },
        { makeFunc: makeRdoRequired },
        { makeFunc: makeRequestedPieceTrackingNumbers }
      ]
    : []
  const temperatureFields = isEnabledTemperatureRanges ? [{ makeFunc: makeTemperatureControlRequired }] : []

  let fields
  switch (serviceType) {
    case OC_SERVICE_TYPES.PARCEL: // domestic
    case OC_SERVICE_TYPES.DOCUMENT: // domestic
    case OC_SERVICE_TYPES.B2B_BUNDLE:
    case OC_SERVICE_TYPES.CORPORATE_B2B_BUNDLE:
      fields = [
        { makeFunc: makeRequestedTrackingNumber, compulsory: !!isTrackingNumberRequired },
        { makeFunc: makeName, compulsory: true },
        { makeFunc: makeAddress1, compulsory: true },
        {
          makeFunc: () => makePackageType({ serviceType })
        },
        { makeFunc: makeAddress2 },
        ...(_.isEmpty(addressInfo)
          ? []
          : [{ makeFunc: makeNeighborhood }, { makeFunc: makeLocality }, { makeFunc: makeRegion }]),
        { makeFunc: makeEmail },
        { makeFunc: makePhoneNumber, compulsory: true },
        { makeFunc: makePostcode, compulsory: !!getPostcodeInfo(country).required },
        { makeFunc: makeDeliveryStartDate },
        shouldDisplayDeliveryTimeslot && { makeFunc: makeDeliveryTimeslot },
        { makeFunc: makeSize },
        { makeFunc: makeWeight, compulsory: isWeightCompulsory },
        { makeFunc: makeDeliveryType },
        { makeFunc: makeShipperOrderNumber },
        { makeFunc: makeInstructions },
        { makeFunc: makeWeekendDelivery },
        { makeFunc: makeParcelDescription, compulsory: isParcelDescriptionRequired },
        { makeFunc: makeIsDangerousGood },
        ...(isCodSupported ? [{ makeFunc: makeCOD }] : []),
        { makeFunc: makeInsuredValue },
        { makeFunc: makeVolume },
        { makeFunc: makeLength },
        { makeFunc: makeWidth },
        { makeFunc: makeHeight },
        ...b2bBundleFields,
        ...temperatureFields
      ]
      return fields.filter(Boolean).map(makeField)
    case OC_SERVICE_TYPES.INTERNATIONAL:
      return [
        { makeFunc: makeRequestedTrackingNumber, compulsory: !!isTrackingNumberRequired },
        { makeFunc: makeName, compulsory: true },
        { makeFunc: makeAddress1, compulsory: true },
        { makeFunc: makeAddress2 },
        ...(_.isEmpty(addressInfo)
          ? []
          : [{ makeFunc: makeNeighborhood }, { makeFunc: makeLocality, compulsory: true }, { makeFunc: makeRegion }]),
        { makeFunc: makeEmail },
        { makeFunc: makePhoneNumber, compulsory: true },
        { makeFunc: makePostcode, compulsory: !!getPostcodeInfo(country).required },
        { makeFunc: makeCountry, compulsory: true },
        { makeFunc: makeDeliveryStartDate },
        shouldDisplayDeliveryTimeslot && { makeFunc: makeDeliveryTimeslot },
        { makeFunc: makeSize },
        { makeFunc: makeWeight, compulsory: isWeightCompulsory },
        { makeFunc: makeDeliveryType },
        { makeFunc: makeShipperOrderNumber },
        { makeFunc: makeInstructions },
        { makeFunc: makeWeekendDelivery },
        ...(isCodSupported ? [{ makeFunc: makeCOD }] : []),
        { makeFunc: makeInsuredValue },
        { makeFunc: makeVolume },
        { makeFunc: makeLength },
        { makeFunc: makeWidth },
        { makeFunc: makeHeight },
        { makeFunc: makePortation, compulsory: true },
        { makeFunc: makeCustomsDescription },
        { makeFunc: makeGoodsCurrency },
        { makeFunc: makeGoodsValue },
        { makeFunc: makeConsigneeCompany },
        { makeFunc: makeBatteryType },
        { makeFunc: makeBatteryPackaging },
        { makeFunc: makeHsCode },
        { makeFunc: makeMadeInCountry },
        { makeFunc: makeTradeTerms },
        { makeFunc: makeLockerPickup },
        { makeFunc: makeParcelDescription, compulsory: isParcelDescriptionRequired },
        { makeFunc: makeIsDangerousGood },
        { makeFunc: makeIsGstIncludedInGoodsValue },
        { makeFunc: makeGstRegistrationNumber }
      ]
        .filter(i => Boolean(i))
        .map(makeField)
    case OC_SERVICE_TYPES.NINJA_PACKS:
      return [
        { makeFunc: makeNinjaPackID, compulsory: true },
        { makeFunc: makeName, compulsory: true },
        { makeFunc: makeEmail },
        { makeFunc: makeInstructions },
        { makeFunc: makePhoneNumber, compulsory: true },
        { makeFunc: makeAddress1, compulsory: true },
        { makeFunc: makeAddress2 },
        { makeFunc: makeParcelDescription, compulsory: isParcelDescriptionRequired },
        { makeFunc: makeIsDangerousGood },
        { makeFunc: makePostcode, compulsory: !!getPostcodeInfo(country).required },
        ...(_.isEmpty(addressInfo)
          ? []
          : [{ makeFunc: makeNeighborhood }, { makeFunc: makeLocality }, { makeFunc: makeRegion }]),
        ...(isCodSupported ? [{ makeFunc: makeCOD }] : [])
      ]
        .filter(i => Boolean(i))
        .map(makeField)
    case OC_SERVICE_TYPES.CORPORATE_AWB:
      return [
        { makeFunc: makeCorpAwbID, compulsory: true },
        { makeFunc: makeName, compulsory: true },
        { makeFunc: makeEmail },
        { makeFunc: makeInstructions },
        { makeFunc: makePhoneNumber, compulsory: true },
        { makeFunc: makeAddress1, compulsory: true },
        { makeFunc: makeAddress2 },
        { makeFunc: makeParcelDescription, compulsory: isParcelDescriptionRequired },
        { makeFunc: makeIsDangerousGood },
        { makeFunc: makePostcode, compulsory: !!getPostcodeInfo(country).required },
        { makeFunc: makeWeight, compulsory: true },
        ...(_.isEmpty(addressInfo)
          ? []
          : [{ makeFunc: makeNeighborhood }, { makeFunc: makeLocality }, { makeFunc: makeRegion }])
      ]
        .filter(i => Boolean(i))
        .map(makeField)
    default:
      return []
  }
}

const getAllOrderFields = (
  shipper,
  orderCreate,
  serviceType,
  isB2BBundleSupported,
  allowedDeliveryTimeslots,
  isEnabledTemperatureRanges = false
) => {
  // TODO: (ZF) Write integration tests to ensure the correct way of retrieving all these variables below
  const country = shipper?.country
  const rsvn = orderCreate?.reservation
  const deliveryType = orderCreate?.deliveryType
  const pickupType = orderCreate?.pickupType
  const orderSource = orderCreate?.orderSource
  const addressInfo = getAddressInfo(country)
  const shouldDisplayDeliveryTimeslot = isDisplayDeliveryTimeslot(allowedDeliveryTimeslots, country)

  const makeFields = getAllMakeFields(
    addressInfo,
    orderSource,
    country,
    isB2BBundleSupported,
    isEnabledTemperatureRanges
  )
  const fields = []
  for (const makeField of makeFields) {
    fields.push(
      makeField({
        country,
        rsvn,
        deliveryType,
        pickupType,
        addressInfo,
        serviceType,
        shouldDisplayDeliveryTimeslot
      })
    )
  }
  return fields
}

const makeLabelToKeyMap = fields => fields.reduce((result, { key, label }) => ({ ...result, [label]: key }), {})

const makeLabelToDataMap = fields => fields.reduce((result, { data, label }) => ({ ...result, [label]: data }), {})

/**
 * @param {Array} orderFields - fields obtained from getOrderFields(serviceType, ...)
 * @param {Array} identifiedHeader - an array that determine how each column in the CSV file should map to
 *                                   the order fields.
 *                                   Note: this is a positional mapping.
 *                                   Eg: identifiedHeader = [null,null,"name","to_address_1","to_contact",...]
 *                                       means 0th column is unidentified
 *                                             1st column is unidentified
 *                                             2nd column maps to the order field "name"
 *                                             and so on and so forth...
 * @returns {Object} fieldsConfig - eg: {
 *                                        requested_tracking_number: {compulsory: true, position: -1},
 *                                        to_name: {compulsory: true, position: 3}
 *                                      }
 */
const identifyFieldsPosition = (orderFields, identifiedHeader) =>
  orderFields
    .map(({ key, compulsory }) => ({
      key,
      compulsory,
      position: _.findIndex(identifiedHeader, columnKey => columnKey === key)
    }))
    .reduce((result, { key, compulsory, position }) => ({ ...result, [key]: { compulsory, position } }), {})

/**
 * This method mutates payload to add the order information. Additionally, it returns error from fields validation
 * @param orderFields
 * @param order
 * @param orderPayload
 */
export const mapOrderToPayload = (orderFields, order, orderPayload) => {
  const payload = _.cloneDeep(orderPayload)
  const defaultCountry = _.find(orderFields, field => /country/.test(field.key)).defaultValue
  const country = order?.country || defaultCountry
  const errors = []
  const getFieldValue = field => {
    const key = camelCaseKey(field.key)
    const value = get(order, key)
      ? get(order, key)
      : field.mappedTo
      ? get(payload, field.mappedTo) || field.defaultValue
      : undefined
    const mappedValue = _.isFunction(field.mappingFunction) ? field.mappingFunction(value, country) : value
    return { value, mappedValue }
  }
  orderFields.forEach(field => {
    const { value, mappedValue: result } = getFieldValue(field)
    let requiredFieldValue
    if (field.requiredField) {
      const requiredField = orderFields.find(orderField => orderField.key === field.requiredField)
      if (requiredField) {
        requiredFieldValue = getFieldValue(requiredField).value
      }
    }
    const error = _.isFunction(field.validate) ? field.validate(value, requiredFieldValue) : null
    if (error) {
      errors.push(error)
    }
    const trimmedResult = _.isString(result) ? _.trim(result) : result
    if (!_.isUndefined(result) && !_.isUndefined(field.mappedTo)) {
      _.set(payload, field.mappedTo, trimmedResult)
    }
  })
  return { errors: errors[0], payload }
}

export default {
  getOrderFields,
  getAllOrderFields,
  makeLabelToDataMap,
  makeLabelToKeyMap,
  identifyFieldsPosition
}
