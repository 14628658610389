/**
 *
 * 4PL TimeSlot
 *
 */

import React, { useState, useEffect, useCallback } from 'react'
import { compose } from 'redux'
import { useIntl } from 'hooks/common'
import { Form, message, T } from '@nv/react-commons/src/Components'
import { OCSubStep } from 'components/OCSubStep'
import { CountryUtils } from '@nv/react-commons/src/Utils'
import { momentWithTimezone } from 'utils/reservation'
import { EditReservation } from 'components/EditReservation'
import { faClock } from '@fa-pro-light/faClock'
import { debounce, max } from 'lodash'
import { DEBOUNCE_CLICK } from 'containers/FPLOrderCreate/constants'
import { DataList } from './DataList'
import { SUB_STEP_STATUS } from './constants'
import { momentUTCWithTimezone } from 'utils/time'
import { Preview } from 'components/Preview'
import { DataListWrapper } from './styles'

const initState = {
  formEdit: null,
  timeslotStatus: SUB_STEP_STATUS.CLOSE,
  internalSelectedTimeslot: null
}

const TimeSlotForm = ({
  onCancel,
  onEdit,
  status,
  form,
  country,
  selectedTimeslot,
  timeslots = [],
  onChangeTimeslot,
  onAddTimeslot,
  onDeleteTimeslot,
  onEditTimeslot
}) => {
  const [state, setState] = useState(initState)
  const { formEdit, timeslotStatus, internalSelectedTimeslot } = state
  const intl = useIntl()

  useEffect(() => {
    setState(prevState => ({ ...prevState, internalSelectedTimeslot: selectedTimeslot }))
  }, [selectedTimeslot])

  const handleValidateFields = useCallback(() => {
    form.validateFields((err, values) => {
      if (!err) {
        const d = values.date.format('YYYY-MM-DD HH:mm')
        const t = parseInt(values.timeWindow)
        const tw = CountryUtils.getPickupTimeslotInfo(country)[t].range
        const readyDatetime = momentWithTimezone(d, country).set({
          hour: tw[0],
          minute: 0
        })
        const latestDatetime = momentWithTimezone(d, country).set({
          hour: tw[1],
          minute: 0
        })
        let rsvn = {
          readyDatetime: readyDatetime.utc().format('YYYY-MM-DD HH:mm'),
          latestDatetime: latestDatetime.utc().format('YYYY-MM-DD HH:mm'),
          pickupDate: readyDatetime.utc().format('YYYY-MM-DD'),
          comments: values.comments,
          timeWindow: values.timeWindow,
          id: !timeslots.length ? 0 : max(timeslots.map(slot => slot.id)) + 1
        }

        if (timeslotStatus === SUB_STEP_STATUS.IN_ADDING) {
          onAddTimeslot(rsvn)
          setState(prevState => ({ ...prevState, timeslotStatus: SUB_STEP_STATUS.IN_LIST }))
        }
        if (timeslotStatus === SUB_STEP_STATUS.IN_EDTING) {
          rsvn = {
            ...rsvn,
            id: formEdit.id
          }
          onEditTimeslot(rsvn)
          setState(prevState => ({
            ...prevState,
            timeslotStatus: SUB_STEP_STATUS.IN_LIST,
            formEdit: undefined,
            internalSelectedTimeslot: internalSelectedTimeslot?.id === rsvn.id ? rsvn : internalSelectedTimeslot
          }))
        }
      }
    })
  }, [country, form, formEdit?.id, internalSelectedTimeslot, onAddTimeslot, onEditTimeslot, timeslotStatus, timeslots])

  const handleSaveTimeSlot = () => {
    if (timeslotStatus === SUB_STEP_STATUS.IN_LIST) {
      if (!internalSelectedTimeslot) {
        message.error(intl.formatMessage({ id: 'please_select_timeslot' }))
      } else {
        onChangeTimeslot(internalSelectedTimeslot)
      }
    }
    if (timeslotStatus === SUB_STEP_STATUS.IN_ADDING || timeslotStatus === SUB_STEP_STATUS.IN_EDTING) {
      handleValidateFields()
    }
  }

  const handleUseExisting = () => {
    return
  }

  const handleEditExisting = () => {
    return
  }

  const handleDeleteTimeslot = timeslotId => {
    onDeleteTimeslot(timeslots.find(add => add.id === timeslotId))
    // Check whether delete selected timeslot
    if (internalSelectedTimeslot.id === timeslotId) {
      setState(prevState => ({ ...prevState, internalSelectedTimeslot: undefined }))
    }
  }

  const handleEditTimeslot = timeslotId => {
    setState(prevState => ({
      ...prevState,
      timeslotStatus: SUB_STEP_STATUS.IN_EDTING,
      formEdit: timeslots.find(slot => slot.id === timeslotId)
    }))
  }

  const handleCancelTimeSlot = () => {
    if (timeslotStatus === SUB_STEP_STATUS.IN_ADDING || timeslotStatus === SUB_STEP_STATUS.IN_EDTING) {
      setState(prevState => ({ ...prevState, timeslotStatus: SUB_STEP_STATUS.IN_LIST, formEdit: null }))
    } else {
      onCancel()
    }
  }

  const handleChangeItemList = indicatedTimeslot => {
    const address = timeslots.find(slot => slot.id === indicatedTimeslot)
    setState(prevState => ({ ...prevState, internalSelectedTimeslot: address }))
  }

  const handleAddAnother = () => {
    setState(prevState => ({ ...prevState, timeslotStatus: SUB_STEP_STATUS.IN_ADDING, formEdit: null }))
  }

  const handleEditStep = () => {
    setState(prevState => ({
      ...prevState,
      timeslotStatus: SUB_STEP_STATUS.IN_LIST
    }))
    onEdit()
  }

  const renderActive = () => {
    if (timeslotStatus === SUB_STEP_STATUS.IN_ADDING || timeslotStatus === SUB_STEP_STATUS.IN_EDTING) {
      return (
        <EditReservation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          sameDayPickupCutoffTime={[14, 0]}
          country={country}
          form={form}
          rsvn={formEdit}
          isMax={false}
          isEditing={false}
          reservations={timeslots}
          isPremium={false}
          onUseExisting={handleUseExisting}
          onEditExisting={handleEditExisting}
          allowPickUpSunday={false}
          dailyPickUpLimit={5}
          showUpcomingIndicator={false}
        />
      )
    }
    return (
      <DataListWrapper>
        <DataList
          renderRow={renderTimeSlotRow}
          data={timeslots}
          selectedId={internalSelectedTimeslot?.id}
          onChange={handleChangeItemList}
          onDelete={debounce(handleDeleteTimeslot, DEBOUNCE_CLICK)}
          onEdit={debounce(handleEditTimeslot, DEBOUNCE_CLICK)}
        />
      </DataListWrapper>
    )
  }

  const renderTimeSlotRow = row => {
    const startTime = momentUTCWithTimezone(row?.readyDatetime, country)
    const endTime = momentUTCWithTimezone(row?.latestDatetime, country)

    const start = startTime.format('D MMM (dddd), HH:mm')
    const end = endTime.format('HH:mm')

    const timeslot = `${start} - ${end}`
    return <Preview title={timeslot} description={row?.comments} />
  }

  const renderPreview = () => {
    const startTime = momentUTCWithTimezone(internalSelectedTimeslot?.readyDatetime, country)
    const endTime = momentUTCWithTimezone(internalSelectedTimeslot?.latestDatetime, country)

    const start = startTime.format('D MMM (dddd), HH:mm')
    const end = endTime.format('HH:mm')

    const timeslot = `${start} - ${end}`

    return (
      <>
        {selectedTimeslot ? (
          <Preview title={timeslot} description={internalSelectedTimeslot?.comments} />
        ) : (
          <Preview title={<T id='no_timeslot' />} disabled />
        )}
      </>
    )
  }

  const getSubtitle = () => {
    if (timeslotStatus === SUB_STEP_STATUS.IN_ADDING || !timeslots.length) {
      return 'create_timeslot'
    }
    if (timeslotStatus === SUB_STEP_STATUS.IN_EDTING) {
      return 'edit_timeslot'
    }
    return 'select_timeslot'
  }

  return (
    <OCSubStep
      status={status}
      title={intl.formatMessage({ id: 'time' })}
      subtitle={getSubtitle()}
      icon={faClock}
      renderActive={renderActive}
      renderPreview={renderPreview}
      onSave={handleSaveTimeSlot}
      onEdit={handleEditStep}
      onCancel={handleCancelTimeSlot}
      onAddAnother={
        timeslotStatus !== SUB_STEP_STATUS.IN_ADDING && timeslotStatus !== SUB_STEP_STATUS.IN_EDTING
          ? handleAddAnother
          : null
      }
      onChange={handleChangeItemList}
    />
  )
}

const TimeSlot = compose(Form.create())(React.memo(TimeSlotForm))

export { TimeSlot }
