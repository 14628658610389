import _ from 'lodash'
import moment from 'moment'
import 'moment-timezone'
import { DATE_TIME_FORMAT, INTERNAL_STATUS, INTERNAL_STATUS_LABEL_IMPLICIT, STEPS, TYPE } from './constants'
import { parseJSONString } from '../FPLOrderRequestList/dataUtils'
import { TRUTHY_FALSY } from 'containers/FPLMixpanel/constants'
import { FULFILLMENT_METADATA, FULFILLMENT_STATUS_LABEL } from 'containers/FPLAllOrders/constants'

export const mapStatusToStepsCurrent = status => {
  switch (status) {
    case INTERNAL_STATUS.STAGING:
    case INTERNAL_STATUS.PENDING_PICKUP:
    case INTERNAL_STATUS.VAN_EN_ROUTE_TO_PICKUP:
    case INTERNAL_STATUS.PENDING_PICKUP_AT_DISTRIBUTION_POINT:
    case INTERNAL_STATUS.PICKUP_FAIL:
      return STEPS.SENDER
    case INTERNAL_STATUS.EN_ROUTE_TO_SORTING_HUB:
    case INTERNAL_STATUS.ARRIVED_AT_SORTING_HUB:
    case INTERNAL_STATUS.ARRIVED_AT_ORIGIN_HUB:
    case INTERNAL_STATUS.ON_VEHICLE_FOR_DELIVERY:
    case INTERNAL_STATUS.PENDING_RESCHEDULE:
    case INTERNAL_STATUS.RETURNED_TO_SENDER:
    case INTERNAL_STATUS.ARRIVED_AT_DISTRIBUTION_POINT:
    case INTERNAL_STATUS.CROSS_BORDER_TRANSIT:
    case INTERNAL_STATUS.CUSTOMS_CLEARED:
    case INTERNAL_STATUS.CUSTOMS_HELD:
    case INTERNAL_STATUS.ARRIVED_AT_ORIGIN_FACILITY:
    case INTERNAL_STATUS.PROCESSED_AT_ORIGIN_FACILITY:
    case INTERNAL_STATUS.LINEHAUL_DEPARTED:
    case INTERNAL_STATUS.LINEHAUL_ARRIVED:
    case INTERNAL_STATUS.HANDED_OVER_TO_LAST_MILE:
    case INTERNAL_STATUS.RTS_TRIGGERED:
    case INTERNAL_STATUS.EN_ROUTE_TO_ORIGIN_FACILITY:
    case INTERNAL_STATUS.RTS_AT_ORIGIN_FACILITY:
    case INTERNAL_STATUS.HANDED_OVER_TO_LINEHAUL:
      return STEPS.COMPANY
    case INTERNAL_STATUS.COMPLETED:
      return STEPS.RECIPIENT
    default:
      return STEPS.SENDER
  }
}

export function mapStatusToStepsStatus(status) {
  const map = {
    [INTERNAL_STATUS.PICKUP_FAIL]: 'error'
  }

  return map[status] || 'finished'
}

export const formatParcelDetail = data => {
  const items =
    data.items?.map(item => ({
      ...item,
      metadata: parseJSONString(item.metadata)
    })) || []
  const parcels = data.parcels?.map(parcel => ({
    ...parcel,
    metadata: parseJSONString(parcel.metadata)
  }))
  return {
    ...data,
    items,
    parcels,
    metadata: parseJSONString(data.metadata),
    deliveryInfo: parseJSONString(data.delivery_info),
    pickupInfo: parseJSONString(data.pickup_info)
  }
}
export const formatTimezoneDate = (date, timezone, format = DATE_TIME_FORMAT.SG_FULL_DAY_TIME) =>
  moment.tz(date, timezone).format(format)
export const getToAddress = parcel => {
  return (
    parcel &&
    _.compact([
      parcel.toAddress1,
      parcel.toAddress2,
      parcel.toAddress3,
      parcel.toAddress4,
      parcel.toCity,
      parcel.toState,
      parcel.toCountry
    ]).join(', ')
  )
}

export const formatNumber = (value, separate) => value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separate ?? ',')

export const transformItemOfParcel = items => {
  return items?.map(item => {
    const gstNumber = `${item.metadata?.taxes?.gst?.is_included ? TRUTHY_FALSY.YES : TRUTHY_FALSY.NO} ${item.metadata
      ?.taxes?.gst?.number || ''}`.trim()
    return {
      ...item.metadata,
      gstNumber,
      lvgNumber: item.metadata?.taxes?.lvg?.number
    }
  })
}

export const getTitleKeyForEvent = (event, internalStatusId) => {
  let titleKey =
    INTERNAL_STATUS_LABEL_IMPLICIT[internalStatusId] || INTERNAL_STATUS_LABEL_IMPLICIT[INTERNAL_STATUS.PENDING]

  if (event && internalStatusId) {
    const fulfillmentStatusWithMetadataList = [
      INTERNAL_STATUS.FULFILLMENT_REQUEST_SUBMITTED,
      INTERNAL_STATUS.COMPLETED,
      INTERNAL_STATUS.RETURNED_TO_SENDER
    ]
    const isMetadataOfFulfillment = Object.values(FULFILLMENT_METADATA).includes(event.metadata)
    const isStatusOfFulfillment = fulfillmentStatusWithMetadataList.includes(internalStatusId)
    if (isMetadataOfFulfillment && isStatusOfFulfillment) {
      titleKey = FULFILLMENT_STATUS_LABEL[internalStatusId][event.metadata]
    }
  }
  return titleKey
}

export const isMMCCBag = t => {
  return t === TYPE.BOX_B2C || t === TYPE.BOX_B2C || t === TYPE.BOX_B2C_V2
}
