import { isEmpty, uniq } from 'lodash'

export const requestedPieceTrackingNumbersValidator = (pieceTrackingNumbers, cartons) => {
  if (isEmpty(pieceTrackingNumbers)) {
    return null
  }

  const noOfCartons = parseInt(cartons, 10)
  const pieceTids = pieceTrackingNumbers.split(',').map(item => item.trim())

  if (Number.isNaN(noOfCartons) || pieceTids.length !== noOfCartons) {
    return 'invalid_piece_tids_not_match_no_of_cartons'
  }

  if (pieceTids.some(item => !item)) {
    return 'invalid_piece_tids_empty_undefined_value'
  }

  if (uniq(pieceTids).length !== pieceTids.length) {
    return 'invalid_piece_tids_duplicated'
  }

  return null
}
