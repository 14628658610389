import _ from 'lodash'

/**
 * Array parameters will be formatted as k=v1&k=v2&k=v2
 * @param {object} data: non-nested Object
 * @returns {string}
 */
export const formatQueryString = data => {
  if (_.isEmpty(data)) {
    return ''
  }
  const queryString = _.map(data, (v, k) => {
    if (_.isArray(v)) {
      return _.map(v, value => `${k}=${value}`).join('&')
    }
    return `${k}=${v}`
  }).join('&')
  return `?${queryString}`
}
