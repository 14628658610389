/**
 *
 * Panel
 *
 */

import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Colors, media } from 'themes'
import { withSize } from '../BreakpointListener'
import { Card } from '../Card'
import { CollapsiblePanel } from '../CollapsiblePanel'
import { PanelHeader } from '../PanelHeader'

const StyledCard = styled(({ fullWidth, maxWidth, ...props }) => <Card {...props} />)`
  && {
    .ant-card-head {
      padding-left: 0;
      border-bottom: solid 1px ${Colors.solitude};
      padding-bottom: 16px;
      height: auto;
    }
    .ant-card-body {
      padding: ${props => (props.fullWidth ? 0 : 20)}px 0;
    }
    ${props =>
      props.fullWidth
        ? 'margin: 16px 0;'
        : `
      margin: 32px auto;
      max-width: ${props.maxWidth || 740}px;
    `};
    ${media.max.tablet`margin: 16px`};
    align-self: center;
    padding: 16px 24px;
    .ant-card-head-title {
      display: flex;
    }
  }
`

class Panel extends React.Component {
  renderTitle = () => {
    const { title, icon } = this.props
    return <PanelHeader title={title} icon={icon} />
  }

  render () {
    const { children, isDesktop, fullWidth, maxWidth, title, icon, extra } = this.props
    return isDesktop ? (
      <StyledCard title={this.renderTitle()} fullWidth={fullWidth} maxWidth={maxWidth} extra={extra}>
        {children}
      </StyledCard>
    ) : (
      <CollapsiblePanel title={title} icon={icon}>
        {children}
      </CollapsiblePanel>
    )
  }
}

Panel.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  icon: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  maxWidth: PropTypes.number,
  title: PropTypes.string.isRequired,
  extra: PropTypes.object
}

const _Panel = withSize(Panel)
export { _Panel as Panel }
