export const getBase64 = file => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      // FileReader.result contains a base64-encoded string with a data-type prefix.
      // We need to tell TypeScript that reader.result is a string, not an ArrayBuffer
      const result = reader.result as string

      // We split the string on comma, which separates the data-type prefix from the actual data
      const base64Data = result.split(',')[1]

      // For example, if result is "data:image/png;base64,iVBORw0K...",
      // result.split(',')[1] would be "iVBORw0K..."

      // We resolve the promise with the base64 data, excluding the data-type prefix
      resolve(base64Data)
    }
    reader.onerror = error => reject(error)
    reader.readAsDataURL(file)
  })
}
