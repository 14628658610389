import { selectCountry, selectIsAuthenticated, selectShipper } from 'containers/Base/selectors'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useScript } from 'hooks'
import { useFlag } from 'featureHub/useFlag'
import featureKeys from 'featureHub/featureKeys'
import { CHAT_SCRIPT_URL } from './SalesforceChat.constants'
import { initializeChat, isCurrentTimeWithinBusinessHours, updatePrechatFields } from './SalesforceChat.helpers'

export const useIsSalesforceChatEnabled = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated())
  const isFeatureFlagEnabled = useFlag(featureKeys.SALESFORCE_CHAT)
  return isAuthenticated && isFeatureFlagEnabled
}

export const useSalesforceChat = () => {
  const shipper = useSelector(selectShipper())
  const isEnabled = useIsSalesforceChatEnabled()
  const isScriptLoaded = useScript(isEnabled ? CHAT_SCRIPT_URL : null)
  const isServiceInitialized = useRef(false)
  const country = useSelector(selectCountry())
  const [isWithinBusinessHours, setIsWithinBusinessHours] = useState(true)
  const [isAgentAvailable, setIsAgentAvailable] = useState(true)

  const getBusinessHours = useCallback(async () => {
    if (isEnabled) {
      const result = await isCurrentTimeWithinBusinessHours(country?.toLowerCase())
      setIsWithinBusinessHours(result)
    }
  }, [isEnabled, country])

  useEffect(() => {
    if (shipper.id) {
      getBusinessHours()
    }
  }, [isAgentAvailable, shipper.id, getBusinessHours])

  useEffect(() => {
    if (isScriptLoaded && window.embedded_svc) {
      window.embedded_svc.addEventHandler('afterMaximize', () => {
        getBusinessHours()
      })
      window.embedded_svc.addEventHandler('onAvailability', data => {
        setIsAgentAvailable(data.isAgentAvailable)
      })
    }
  }, [isScriptLoaded, getBusinessHours])

  // initialize the chat service when the script is loaded
  // update shipper info when the shipper changes
  useEffect(() => {
    if (isServiceInitialized.current && shipper) {
      updatePrechatFields(shipper)
      return
    }

    if (isScriptLoaded && shipper && !isServiceInitialized.current) {
      initializeChat(shipper)
      isServiceInitialized.current = true
    }
  }, [isScriptLoaded, shipper])

  // if the chat becomes disabled, for exmaple, on logout, end the chat
  useEffect(() => {
    if (!isEnabled && window.embedded_svc) {
      window.embedded_svc.liveAgentAPI?.endChat()
    }
  }, [isEnabled])

  return { isWithinBusinessHours, isEnabled, getBusinessHours, isAgentAvailable }
}
