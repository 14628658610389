import { COUNTRIES } from '@nv/react-commons/src/Constants'
// eslint-disable-next-line max-len
import { StrategyAttributeCountryName } from 'featurehub-javascript-client-sdk/dist/models/models/strategy-attribute-country-name'

const COUNTRIES_MAP = {
  [COUNTRIES.SG]: StrategyAttributeCountryName.Singapore,
  [COUNTRIES.MY]: StrategyAttributeCountryName.Malaysia,
  [COUNTRIES.ID]: StrategyAttributeCountryName.Indonesia,
  [COUNTRIES.VN]: StrategyAttributeCountryName.Vietnam,
  [COUNTRIES.PH]: StrategyAttributeCountryName.Philippines,
  [COUNTRIES.TH]: StrategyAttributeCountryName.Thailand,
  [COUNTRIES.MM]: StrategyAttributeCountryName.Myanmar
}

export const mapShipperCountryToFeatureHubCountry = (shipperCountry = '') => {
  return COUNTRIES_MAP[shipperCountry.toLowerCase()]
}
