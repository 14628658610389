const buildErrorMessage = error => {
  if (error?.validation_errors) {
    return Object.keys(error.validation_errors)
      .map(errorKey => error.validation_errors[errorKey])
      .join('; ')
  }
  return error?.message
}

export const buildFullErrorInfo = apiRes => {
  if (apiRes) {
    const { data, status, config } = apiRes
    const { url, baseURL, method } = config
    return {
      apiUrl: `${method} - ${baseURL}${url}`,
      message: buildErrorMessage(data?.error),
      status
    }
  }
  return {
    apiUrl: '',
    message: '',
    status
  }
}
