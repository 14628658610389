/**
 *
 * DeliveryInstructionsFormItem
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { T, Form, NVInput } from '@nv/react-commons/src/Components'
import { FIELDS } from 'components/OCForm/constants'

class DeliveryInstructionsFormItem extends React.PureComponent {
  render () {
    const {
      disabled,
      initialValue,
      intl,
      formItem: FormItem,
      form: { getFieldDecorator }
    } = this.props
    return (
      <FormItem label={<T id='delivery_instructions' />}>
        {getFieldDecorator(FIELDS.INSTRUCTIONS, {
          initialValue,
          rules: [
            {
              max: 255,
              message: intl.formatMessage({ id: 'instructions_max_length' }, { max: 255 })
            }
          ]
        })(<NVInput placeholder={intl.formatMessage({ id: 'optional' })} disabled={disabled} />)}
      </FormItem>
    )
  }
}

DeliveryInstructionsFormItem.propTypes = {
  form: PropTypes.object,
  formItem: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  intl: PropTypes.object
}

DeliveryInstructionsFormItem.defaultProps = {
  formItem: Form.Item
}

const _DeliveryInstructionsFormItem = injectIntl(DeliveryInstructionsFormItem)
export { _DeliveryInstructionsFormItem as DeliveryInstructionsFormItem }
