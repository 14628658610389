import React, { useEffect, useState } from 'react'
import { Text } from 'components/Text'
import { T } from '@nv/react-commons/src/Components'
import { StyledButton, StyledButtonGroup, StyledButtonWrapper, StyledCenterCard } from './styles'
import { Vspace } from 'components/Vspace'
import successSrc from 'images/ryo-onboarding-success.png'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'containers/Base/constants'

export const RegistrationSuccess = () => {
  const navigate = useNavigate()
  const [seconds, setSeconds] = useState(5)
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  if (seconds === 0) {
    navigate(ROUTES.FPL_OC)
  }

  const handleStartShipping = () => {
    navigate(ROUTES.FPL_OC)
  }

  return (
    <>
      <Vspace height={60} />
      <StyledCenterCard>
        <img width={332} src={successSrc} />
        <Vspace height={20} />
        <Text size={20}>
          <T id='international_thank_you' />
        </Text>
        <Vspace height={20} />
        <Text size={14}>
          <T id='international_registration_thankout_line1' />
        </Text>
        <Vspace height={6} />
        <Text size={14}>
          <T id='international_thank_you_and_redirection' values={{ x: seconds }} />
        </Text>
      </StyledCenterCard>
      <Vspace height={32} />
      <StyledButtonGroup>
        <StyledButtonWrapper>
          <StyledButton onClick={handleStartShipping} type='primary' size='medium'>
            <T id='international_go_oc_page_label' />
          </StyledButton>
        </StyledButtonWrapper>
      </StyledButtonGroup>
    </>
  )
}
