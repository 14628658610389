import { useCallback, useEffect, useState } from 'react'

export const useNvFetch = <T extends (...args: any[]) => any>(
  api: T,
  options: { args?: Parameters<T>; autoFetch: boolean } = { autoFetch: false }
) => {
  const [response, setResponse] = useState({ loading: false, error: null, data: null })

  const fetch = useCallback(
    async (...args: Parameters<T>): Promise<ReturnType<T>> => {
      setResponse({ loading: true, error: null, data: null })

      const resp = await api(...args)
      if (!resp || !resp.ok) {
        setResponse({ loading: false, error: resp.data?.error || {}, data: null })
        return resp
      }

      setResponse({ loading: false, error: null, data: resp.data?.data || resp.data })
      return resp
    },
    [api]
  )

  useEffect(() => {
    if (options.autoFetch) {
      const args: any = options.args ?? []
      fetch(...args)
    }
    // This only needs to be run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { fetch, ...response }
}
