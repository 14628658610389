import Config from 'configs'

export const SALES_FORCE_URL = Config.SALES_FORCE_URL
export const SALES_FORCE_SERVICE_URL = Config.SALES_FORCE_SERVICE_URL
export const CHAT_SCRIPT_URL = Config.SALES_FORCE_CHAT_SCRIPT_URL
export const ORGANIZATION_ID = Config.SALES_FORCE_ORGANIZATION_ID
export const SERVICE_NAME = Config.SALES_FORCE_SERVICE_NAME
export const ESW_LIVE_AGENT_DEV_NAME = Config.SALES_FORCE_ESW_LIVE_AGENT_DEV_NAME
export const LIVE_AGENT = 'LiveAgent'
export const CASE_ORIGIN = 'Dash Web Chat'

export const LIVE_AGENT_CONFIG = {
  baseLiveAgentURL: Config.SALES_FORCE_BASE_LIVE_AGENT_URL,
  baseLiveAgentContentURL: Config.SALES_FORCE_BASE_LIVE_AGENT_CONTENT_URL,
  deploymentId: Config.SALES_FORCE_DEPLOYMENT_ID,
  buttonId: Config.SALES_FORCE_BUTTON_ID,
  eswLiveAgentDevName: ESW_LIVE_AGENT_DEV_NAME,
  isOfflineSupportEnabled: false
}
