import React from 'react'
import { Carousel } from 'components/Carousel'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row, Col } from '@nv/react-commons/src/Components'
import { Text } from 'components/Text'
import { Colors } from 'themes'

const StyledItem = styled.div`
  height: auto;
  padding: 8px;
`
const CarouselItemTitle = styled(Text)`
  color: ${Colors.nvPriRed};
  font-size: 32px;
`

const CarouselWrapper = styled(Carousel)`
  && {
    .slick-dots {
      bottom: 0;
      left: 0;
      transform: translate(-15%, 0);
    }

    .slick-dots li {
      margin: 0 4px;
    }

    .slick-dots li button {
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background: ${Colors.blueGrey};
    }

    .slick-dots li.slick-active button {
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background: red;
    }
  }
`

const ContentCarousel = ({ list }) => {
  return (
    <CarouselWrapper swipeToSlide draggable infinite autoplay autoplaySpeed={8000}>
      {list.map(item => (
        <div key={item.title || ''}>
          <StyledItem>
            <Row type='flex' align='top' gutter={[24, 0]}>
              <Col md={{ span: 6 }}>{item.img || ''}</Col>
              <Col md={{ span: 12 }}>
                <CarouselItemTitle id={item.title} />
                <Text id={item.desc} size={18} />
              </Col>
            </Row>
          </StyledItem>
        </div>
      ))}
    </CarouselWrapper>
  )
}

ContentCarousel.propTypes = {
  list: PropTypes.array
}

ContentCarousel.defaultProps = {
  list: []
}

export { ContentCarousel }
